define("sdk/components/sdk-scripts/component", ["exports", "sdk/core/component", "sdk/configuration/index", "sdk/components/sdk-scripts/template", "sdk/api/utils/runloop", "sdk/api/utils/word-count-delay", "sdk/api/utils/parse-html", "sdk/utils/speak", "sdk/mixins/persisted-scroll-position", "ember-uuid", "sdk/api/utils/rich-widgets"], function (_exports, _component, _index, _template, _runloop, _wordCountDelay, _parseHtml, _speak, _persistedScrollPosition, _emberUuid, _richWidgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Script tree & group dropdowns with list of script lines
   *
   * Displays script tree dropdown & script group dropdown
   * Below the dropdowns are a list of the active script group's scripts
   *
   * @class Components.Scripts
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend(_persistedScrollPosition.default, {
    layout: _template.default,
    classNames: 'layout-column',
    attributeBindings: ['title'],
    componentName: 'scripts',
    enableMoreSuggestionIndicator: false,
    persistScrollElementSelectors: Object.freeze(['#scripts-content']),
    scrollPositions: Ember.computed.alias('ui.scrollPositions'),
    scriptTrees: Ember.computed('api.agent.scriptTrees', 'settings.siteId', function () {
      var siteId = this.get('settings.siteId');
      var scriptTrees = this.get('api.agent.scriptTrees');
      return siteId ? scriptTrees && scriptTrees.filterBy('siteId', siteId) : scriptTrees;
    }),
    scriptGroups: Ember.computed.alias('api.agent.scriptGroups'),
    scriptTabs: Ember.computed('isAgentCoachEnabled', 'customScriptsEnabled', function () {
      var scriptTabs = ['system-scripts'];
      if (this.get('isAgentCoachEnabled')) {
        scriptTabs.unshiftObjects(['agent-coach']);
      }
      if (this.get('customScriptsEnabled')) {
        scriptTabs.pushObject('custom-scripts');
      }
      return scriptTabs;
    }),
    selectedScriptTree: Ember.computed.alias('ui.selectedScriptTree'),
    scriptTreeData: Ember.computed.alias('selectedScriptTree.__data__.scriptGroupsAndFolders'),
    selectedScriptGroup: Ember.computed.alias('ui.selectedScriptGroup'),
    selectedScriptGroupsOrFolders: Ember.computed.alias('ui.selectedScriptGroupsOrFolders'),
    collapseScriptHeadingsSetting: Ember.computed.alias('api.agent.settings.collapseScriptHeadingsByDefault'),
    conversationChannel: Ember.computed.alias('settings.conversationChannel'),
    customScriptsEnabled: Ember.computed.alias('api.settings.enableCustomScript'),
    intl: Ember.inject.service(),
    selectedScriptTreeGroupIds: Ember.computed.mapBy('selectedScriptTree.groups', 'id'),
    disableScriptTreeSelector: Ember.computed.alias('settings.disabledScriptCategoriesSelection'),
    hasErrorInSystemScripts: Ember.computed.alias('api.agent.hasErrorInSystemScripts'),
    isAgentCoachEnabled: Ember.computed.bool('engagement.agentCoach'),
    allScriptGroups: Ember.computed('scriptTrees', function () {
      var scriptTrees = this.get('scriptTrees');
      var allScriptGroups = [];
      scriptTrees.forEach(function (scriptTree) {
        allScriptGroups.pushObjects(scriptTree.get('groups'));
        var folders = scriptTree.get('folders');
        if (folders && folders.length > 0) {
          folders.forEach(function (scriptFolder) {
            allScriptGroups.pushObjects(scriptFolder.get('groups'));
          });
        }
      });
      return allScriptGroups;
    }),
    scriptGroupSectionsReset: function scriptGroupSectionsReset() {
      var _this = this;
      var collapseScriptHeadingsSetting = this.get('collapseScriptHeadingsSetting');
      this.get('ui.systemScriptSectionsCollapsed') && this.get('ui.systemScriptSectionsCollapsed').clear();
      this.get('selectedScriptGroup.sections').forEach(function (section) {
        if (!section.name) return;
        if (!section.id) section.set('id', (0, _emberUuid.v4)());
        if (collapseScriptHeadingsSetting) {
          _this.get('ui.systemScriptSectionsCollapsed').pushObject(section.get('id'));
        }
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // set initial selected script tab
      if (!this.scriptView) {
        this.set('scriptView', this.get('scriptTabs.firstObject'));
      }
      this.set('scriptTabSelectedIndex', this.get('scriptTabs').indexOf(this.scriptView));
      if (this.get('selectedScriptTree') || this.get('selectedScriptGroup')) return;
      if (this.get('api.agent.scriptTrees')) {
        this.setInitialScripts();
      } else {
        this.get('api').on('scriptsLoaded', this, this.setInitialScripts);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('api').off('scriptsLoaded', this, this.setInitialScripts);
    },
    /**
     * The string used to search through scripts.
     * @property {String} filter
     * @public
     */
    filter: Ember.computed.alias('ui.scriptSearchFilter'),
    /**
     * Determines whether or not to display the filtered list of scripts.
     * @property {Boolean} showFilteredScripts
     * @public
     */
    showFilteredScripts: Ember.computed.alias('ui.showFilteredScripts'),
    defaultActiveScriptGroup: Ember.computed('selectedScriptTree', function () {
      var tree = this.get('selectedScriptTree');
      if (!tree) return;
      var defaultScriptGroup = tree.get('allScriptGroups')[0];
      return defaultScriptGroup;
    }),
    activeScriptGroup: Ember.computed('selectedScriptTree', function () {
      return this.get('defaultActiveScriptGroup');
    }),
    /**
     * The agent's custom scripts
     * @property {Array} customScripts
     */
    customScriptLines: Ember.computed.alias('api.agent.customScripts.scriptLines'),
    customScriptSections: Ember.computed.reads('api.agent.customScripts.scriptSections'),
    didCustomScriptsLoadFail: Ember.computed.alias('api.agent.didCustomScriptsLoadFail'),
    setInitialScripts: function setInitialScripts() {
      if (!this.get('scriptTrees.length')) return;
      var scriptTreeId = this.get('settings.scriptTreeId');
      var defaultScriptTree = this.get('scriptTrees').findBy('id', scriptTreeId) || this.get('scriptTrees')[0];
      this._selectScriptTree(defaultScriptTree);
    },
    isScriptTreeEmpty: Ember.computed('selectedScriptTree', function () {
      return this.get('selectedScriptTree').get('__data__.scriptGroupsAndFolders').length === 0;
    }),
    actions: {
      selectScriptTabIndex: function selectScriptTabIndex(index) {
        this.send('clearFilter');
        this.setProperties({
          scriptTabSelectedIndex: index,
          scriptView: this.get('scriptTabs').objectAt(index)
        });
      },
      openCustomScriptsEditor: function openCustomScriptsEditor() {
        var openCustomScriptsEditor = this.get('openCustomScriptsEditor');
        openCustomScriptsEditor && openCustomScriptsEditor();
      },
      previewRichWidget: function previewRichWidget(richWidget) {
        // shows "Cancel", "Load", and "Send" buttons below preview
        Ember.set(richWidget, 'ui', {
          hasActionFooter: true
        });
        this.set('ui.previewRichWidget', richWidget);
      },
      reloadScripts: function reloadScripts() {
        this.get('api.agent').loadSystemScripts();
      },
      onScriptTreeSelected: function onScriptTreeSelected(scriptTree) {
        this._selectScriptTree(scriptTree);
      },
      /**
      * Sets the selected script group
      * Sets the active script group to display script group's script lines
      * @method onScriptGroupSelected
      * @param selectedScriptGroupsOrFolders - the selected script group and folders
      * @private;
      */
      onScriptGroupSelected: function onScriptGroupSelected(selectedScriptGroupsOrFolders) {
        var selectedScriptGroup = selectedScriptGroupsOrFolders.get('lastObject');
        this.set('selectedScriptGroup', selectedScriptGroup);
        this.scriptGroupSectionsReset();
        this.set('selectedScriptGroupsOrFolders', selectedScriptGroupsOrFolders);
      },
      /**
       * Sends the selected script's text as
       * a message to the user.
       * @method sendScript
       * @param script
       */
      sendScript: function sendScript(script) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        if (!this.get('sendMsg')) return;
        var toAgentInput = options.toAgentInput;
        var nonEditable = script.nonEditable;
        var onAlert = this.get('alert');
        if (nonEditable && toAgentInput) {
          return onAlert && onAlert('sdk-scripts.script-cannot-be-modified');
        }
        var type = script.get('type');
        if (type === 'command') {
          return this._sendCommandScript(script, options);
        } else if (type === 'scriptcommand' && options.toAgentInput) {
          // TODO: HTMLAI-801
          return onAlert && onAlert('sdk-scripts.commands-cannot-be-copied');
        }
        return this._sendTextScript(script, options);
      },
      /**
       * Resets the active hotkey.
       * @method resetActiveHotkey
       * @public
       */
      resetActiveHotkey: function resetActiveHotkey() {
        var _this2 = this;
        Ember.run.later(function () {
          _this2.resetHotkey();
        }, _index.default.DOUBLE_CLICK_THRESHOLD);
      },
      /**
       * If searching, grabs the list of scripts which match
       * the search criteria.
       * @method getFilteredScripts
       * @param {String} filter the search string
       * @param {Object} event the keyUp event
       * @public
       */
      getFilteredScripts: function getFilteredScripts(filter, event) {
        var ESCAPE = 27;
        if (event.keyCode === ESCAPE) return;
        filter = filter.toLowerCase().trim();
        if (this.get('currentFilter') === filter) return;
        this.set('currentFilter', filter);
        this.set('filteredScripts', []);
        this.set('nextSearchBatch', 0);
        if (filter) {
          this.set('isSearching', true);
          (0, _runloop.debounce)(this, this._getFilteredScripts, 500);
        }
        this.set('showFilteredScripts', !!filter);
      },
      /**
       * Resets the search.
       * @method clearFilter
       * @public
       */
      clearFilter: function clearFilter() {
        this.set('filteredScripts', []);
        this.set('currentFilter', '');
        this.set('filter', '');
        this.set('showFilteredScripts', false);
        this._removeScrollBottomListener();
      },
      collapseSection: function collapseSection(collapsedSectionIds, id) {
        collapsedSectionIds.pushObject(id);
      },
      expandSection: function expandSection(collapsedSectionIds, id) {
        collapsedSectionIds.removeObject(id);
      }
    },
    /**
     * Adds an event listener triggered when #scripts-content element
     * is scrolled to bottom. Triggers _getFilteredScripts.
     * @method _addScrollBottomListener
     * @private
     */
    _addScrollBottomListener: function _addScrollBottomListener() {
      var _this3 = this;
      var $scriptsContent = this.$('#scripts-content');
      $scriptsContent.off('scroll.filter-search');
      $scriptsContent.on('scroll.filter-search', function () {
        var scrollHeight = $scriptsContent.height();
        var scrollTop = $scriptsContent.scrollTop();
        var innerHeight = $scriptsContent.prop('scrollHeight');
        var scrollPosition = scrollHeight + scrollTop;
        if (innerHeight - scrollPosition < 50) {
          _this3._getFilteredScripts();
        }
      });
    },
    /**
     * Removes event listener set by _addScrollBottomListener.
     * @method _addScrollBottomListener
     * @private
     */
    _removeScrollBottomListener: function _removeScrollBottomListener() {
      this.$('#scripts-content').off('scroll.filter-search');
    },
    /**
     * Sets the selected script tree
     * Sets the selected script group to the first script group in passed in script tree
     * @method onScriptTreeSelected
     * @param scriptTree - the selected script tree
     * @private
     */
    _selectScriptTree: function _selectScriptTree(scriptTree) {
      this.set('selectedScriptTree', scriptTree);
      if (this.get('isScriptTreeEmpty')) {
        return;
      }
      var isFirstItemFolder = !!this.get('scriptTreeData.firstObject.scriptGroupsAndFolders');
      var defaultScriptGroup = scriptTree.get('groups.firstObject');
      var defaultScriptFolder = scriptTree.get('folders')[0];
      var selectedScriptGroupsOrFolders = isFirstItemFolder ? this._getFirstScriptGroupPath(defaultScriptFolder) : null;
      this.set('selectedScriptGroup', isFirstItemFolder ? selectedScriptGroupsOrFolders.get('lastObject') : defaultScriptGroup);
      this.scriptGroupSectionsReset();
      this.set('selectedScriptGroupsOrFolders', isFirstItemFolder ? selectedScriptGroupsOrFolders : [defaultScriptGroup]);
    },
    renderedSections: Ember.computed('selectedScriptGroup', function () {
      var scriptGroup = this.get('selectedScriptGroup');
      var filteredSections = this._removeSuppressedSections(scriptGroup.sections);
      return filteredSections;
    }),
    /**
     * Removes suppressed scripts and sections, section is removed only if there
     * no script associated with it.
     * @param sections - sections from selectedScriptTree
     * @return - filtered sections
    */
    _removeSuppressedSections: function _removeSuppressedSections() {
      var _this4 = this;
      var sections = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var filteredSections = [];
      sections.forEach(function (section) {
        var scripts = section.scripts;
        var filteredScripts = scripts.filter(function (script) {
          return _this4._shouldShowScript(script);
        });
        if (!filteredScripts.length && !!scripts.length) return;
        section.set('filteredScripts', filteredScripts);
        filteredSections.pushObject(section);
      });
      return filteredSections;
    },
    /**
     * Removes suppressed scripts from sections
     * @param {script} - script within sections
     */
    _shouldShowScript: function _shouldShowScript(script) {
      var _script$devices = script.devices,
        suppressedDevices = _script$devices === void 0 ? [] : _script$devices,
        _script$supportedChan = script.supportedChannels,
        supportedChannels = _script$supportedChan === void 0 ? [] : _script$supportedChan;
      var isCurrentChannelSupported = !supportedChannels.length || supportedChannels.includes(this.get('conversationChannel'));
      var isCurrentDeviceTypeSuppressed = suppressedDevices.includes(this.get('deviceType'));
      return isCurrentChannelSupported && !isCurrentDeviceTypeSuppressed;
    },
    _getScriptMessageText: function _getScriptMessageText(script) {
      if (script.get('isRichWidget')) {
        return script.get('richWidget.agentMessage') || '';
      }
      var hasInteractiveData = script.valueJSON && script.valueJSON.interactiveData;
      return hasInteractiveData && !this.get('engagement.shouldSendPlainText') ? script.valueJSON.agentMessage || 'Rich Widget' : script.value;
    },
    _getScriptMessageData: function _getScriptMessageData(script) {
      if (script.get('isRichWidget')) {
        return (0, _richWidgets.getMessageDataFromRichWidget)(script.get('richWidget'));
      }
      var hasInteractiveData = script.valueJSON && script.valueJSON.interactiveData;
      return hasInteractiveData && !this.get('engagement.shouldSendPlainText') ? JSON.stringify(script.valueJSON) : null;
    },
    _sendTextScript: function _sendTextScript(script) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (options.toAgentInput) {
        this._sendToAgentInput(script);
      } else {
        options.timeDelay = this._getWordCountDelay(script);
        return script.get('isCustom') ? this._sendCustomScript(script, options) : this._sendSystemScript(script, options);
      }
    },
    /**
     * Sends the custom script to the customer.
     * @method _sendCustomScript
     * @param {Object} script the script line model
     * @param {Object} options the options for sending the script
     * @private
     */
    _sendCustomScript: function _sendCustomScript(script, options) {
      var message = {
        customScript: true,
        messageText: this._getScriptMessageText(script),
        originalScriptValue: script.get('value')
      };
      var messageData = this._getScriptMessageData(script);
      if (messageData) message.messageData = messageData;
      return this.get('sendMsg')(message, options);
    },
    _sendSystemScript: function _sendSystemScript(script, options) {
      var messageText = this._getScriptMessageText(script);
      var message = {
        originalScriptValue: script.get('value'),
        messageText: messageText,
        messageType: 'script',
        scriptType: 'text',
        scriptTreeId: script.get('scriptTreeId')
      };
      if (messageText !== script.get('originalScriptText')) {
        message.scriptTemplate = script.get('originalScriptText');
      }
      var messageData = this._getScriptMessageData(script);
      if (messageData) message.messageData = messageData;
      return this.get('sendMsg')(message, options);
    },
    _sendCommandScript: function _sendCommandScript(scriptLine, options) {
      if (options.toAgentInput) {
        var onAlert = this.get('alert');
        onAlert && onAlert('sdk-scripts.commands-cannot-be-copied');
      } else {
        return this.get('sendMsg')({
          messageText: scriptLine.get('value'),
          messageType: 'script',
          scriptType: 'command',
          command: scriptLine.get('command'),
          scriptTreeId: scriptLine.get('scriptTreeId')
        }, options);
      }
    },
    /**
     * A helper method for sending script text
     * to the agent input.
     * @method _sendToAgentInput
     * @param {Script} script
     * @private
     */
    _sendToAgentInput: function _sendToAgentInput(script) {
      var messageText = this._getScriptMessageText(script);
      if (script.get('isAgentPushed')) {
        messageText = (0, _parseHtml.encodeCarets)(messageText);
      }
      messageText = this._stripScriptText(messageText);
      messageText = messageText.replace(/ {2,}/g, ' ').replace(/<br\s*\/?>/g, '\n');
      this.set('engagement.ui.agentInputScript', {
        scriptText: messageText,
        scriptType: script.get('isCustom') ? 'custom' : 'system'
      });
      this.get('engagement').sendToAgentInput(messageText, {
        richWidget: script.get('richWidget')
      });
      this.get('api').trigger('focusAgentInput');
    },
    _stripScriptText: function _stripScriptText(scriptText) {
      // strip input delimeters from input scripts
      var inputDelimeter;
      while ((inputDelimeter = /{{\*{2}(.*?)\|{2}(\d+)\*{2}}}/g.exec(scriptText)) !== null) {
        scriptText = scriptText.replace(inputDelimeter[0], '');
      }
      return scriptText;
    },
    /**
     * Returns the delay for sending a given script in milliseconds.
     * @method _getWordCountDelay
     * @private
     * @param {Script} script
     * @returns {Number} the delay in milliseconds
     */
    _getWordCountDelay: function _getWordCountDelay(script) {
      var isAgentPushed = script.get('isAgentPushed');
      var enableScriptDelay = this.get('settings.enableScriptDelay');
      var isTimeDelayed = enableScriptDelay && !isAgentPushed;
      return isTimeDelayed ? (0, _wordCountDelay.default)(script.getAgentMessageText()) : 0;
    },
    /**
     * Returns all scripts from every script tree in a single,
     * flat array.
     * @method allScripts
     * @public
     * @return {Array} all the scripts
     */
    allScripts: Ember.computed(function () {
      var scriptTrees = this.get('scriptTrees');
      var allScriptsFromTrees = scriptTrees.map(function (scriptTree) {
        return scriptTree.get('allScripts');
      });
      var allScripts = [].concat.apply([], allScriptsFromTrees);
      return allScripts;
    }),
    showHotkeys: Ember.computed.alias('ui.shouldShowHotkeys'),
    activeHotkey: Ember.computed.alias('api.agent.activeHotkey'),
    /**
     * The last string entered in the search input.
     * @property {String} cachedFilter
     * @public
     */
    cachedFilter: Ember.computed.alias('ui.filter'),
    /**
     * The array of scripts that contain the search
     * string.
     * @property {Array} filteredScripts
     * @public
     */
    filteredScripts: Ember.computed.alias('ui.filteredScripts'),
    /**
     * Searches through allScripts or customScripts and pushes matches
     * to filteredScripts array
     * @method _getFilteredScripts
     * @private
     */
    _getFilteredScripts: function _getFilteredScripts() {
      var _this5 = this;
      var filter = this.get('currentFilter');
      if (!filter) return;
      var allScripts;
      if (this.get('scriptView') === 'custom-scripts') {
        allScripts = this.get('customScriptLines') || [];
      } else {
        allScripts = this.get('renderedSearchSystemScripts');
      }
      var scriptsFound = 0;
      var headerFound = false;
      var batchSize = 200;
      var scriptBatchCount = allScripts.length / batchSize;
      var startBatch = this.get('nextSearchBatch') || 0;
      var _loop = function _loop() {
        _this5.set('nextSearchBatch', batch + 1);
        var batchStart = batch * batchSize;
        var batchEnd = batchStart + batchSize;
        var currentBatch = allScripts.slice(batchStart, batchEnd);
        var batchFiltered = [];
        var prevHeader = null;
        var isHeaderType = false;
        currentBatch.forEach(function (script) {
          isHeaderType = script.get('scriptType') === 'header';
          if (isHeaderType) {
            prevHeader = script;
            headerFound = false; //reset
          }

          if (!isHeaderType && headerFound) {
            //if header was considered earlier, then include all the scripts under that.
            batchFiltered.push(script);
          } else {
            // grabs only text even if there's nested elements and should save elements in <<>>
            var text = script.get('scriptValue').replace(/<\/?[^>]+>/g, function (match) {
              return match.startsWith('<<') ? match : '';
            }).toLowerCase();
            var keywords = script.get('keywords');
            var found = text.indexOf(filter) > -1;
            if (keywords) {
              keywords = keywords.split('_');
              found = found || !!keywords.find(function (keyword) {
                return keyword.toLowerCase().indexOf(filter) > -1;
              });
            }
            if (found) {
              if (isHeaderType) {
                headerFound = true;
              } else if (prevHeader) {
                //include the header for other script types
                batchFiltered.push(prevHeader);
                prevHeader = null;
              }
              batchFiltered.push(script);
            }
          }
        });
        _this5.get('filteredScripts').pushObjects(batchFiltered);
        scriptsFound = scriptsFound + batchFiltered.length;
        if (scriptsFound >= 50) {
          _this5._addScrollBottomListener();
          return 1; // break
        }
      };
      for (var batch = startBatch; batch <= scriptBatchCount; batch++) {
        if (_loop()) break;
      }
      if (scriptsFound > 0) {
        (0, _speak.default)("".concat(scriptsFound, " results were found. Press tab to navigate to search results"));
      }
      this.set('isSearching', false);
    },
    /**
     * Provides list of scripts for search
     */
    renderedSearchSystemScripts: Ember.computed('engagement.deviceType', function () {
      var _this6 = this;
      var allScriptGroups = this.get('selectedScriptTree.allScriptGroups');
      var allFilteredSections = [];
      var allFilteredScripts = [];
      allScriptGroups.forEach(function (group) {
        var sections = group.sections;
        var filteredSections = _this6._removeSuppressedSections(sections);
        filteredSections.map(function (section) {
          return allFilteredSections.push(section);
        });
      });
      allFilteredSections.map(function (section) {
        if (section.get('headerScript')) {
          allFilteredScripts.push(section.get('headerScript'));
        }
        section.get('filteredScripts').forEach(function (script) {
          allFilteredScripts.push(script);
        });
      });
      return allFilteredScripts;
    }),
    /**
     * Returns whether or not the given hotkey contains
     * the active hotkey. Does a check from left to right.
     * E.g., "101" will return true if activeHotkey is "10"
     * but will return false if activeHotkey is "11".
     * @method _containsActiveHotkey
     * @param {String} scriptHotkey the hotkey to check
     * @private
     * @return {Boolean} true if scriptHotkey contains activeHotkey
     */
    _containsActiveHotkey: function _containsActiveHotkey(scriptHotkey) {
      var activeHotkey = this.get('activeHotkey');
      if (activeHotkey.length > scriptHotkey.length) return false;
      var containsHotkey = true;
      for (var i = 0; i < activeHotkey.length; i++) {
        if (scriptHotkey[i] !== activeHotkey[i]) containsHotkey = false;
      }
      return containsHotkey;
    },
    /**
     * Returns an array of hotkeys (strings) which could match
     * activeHotkey.
     * @property {Array} possibleHotkeys
     * @public
     */
    possibleHotkeys: Ember.computed('matchingHotkeyScripts', function () {
      var activeHotkey = this.get('activeHotkey');
      if (!activeHotkey) return [];
      return this.get('matchingHotkeyScripts').map(function (script) {
        return script.get('hotKey');
      });
    }),
    matchingHotkeyScripts: Ember.computed('activeHotkey', function () {
      var _this7 = this;
      var activeHotkey = this.get('activeHotkey');
      if (!activeHotkey) return [];

      // matching hotkeys for the selected script only
      var allScripts = this.get('selectedScriptTree.allScripts');
      var allScriptsWithHotkeys = allScripts.reduce(function (prev, curr) {
        if (curr.get('hotKey') !== undefined) return prev.concat(curr);
        return prev;
      }, []);
      if (allScriptsWithHotkeys.length) {
        var result = [];
        allScriptsWithHotkeys.forEach(function (script) {
          var hotkey = script.get('hotKey');
          if (_this7._containsActiveHotkey(hotkey)) {
            result.push(script);
          }
        });
        return result;
      }
      return [];
    }),
    /**
     * Returns an array containing the folder objects which lead to the first
     * script group within the given folder. The first script group will always
     * be the last object in the array.
     * @method _getFirstScriptGroupPath
     * @param {Object} folder the folder object
     * @returns an array with the path of folder objects leading to and including the first script group
     * @private
     */
    _getFirstScriptGroupPath: function _getFirstScriptGroupPath(folder) {
      if (folder.scriptGroupsAndFolders) {
        var isFirstItemFolder = !!folder.scriptGroupsAndFolders.get('firstObject.scriptGroupsAndFolders');
        if (!isFirstItemFolder) {
          if (folder.get('groups.length')) return [folder, folder.get('groups.firstObject')];
        }
      } else {
        if (folder.get('groups.length')) return [folder, folder.get('groups.firstObject')];
      }
      return [folder].concat(this._getFirstScriptGroupPath(folder.get('folders.firstObject')));
    }
  });
});