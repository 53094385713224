define("sdk/site/follow-up/route", ["exports", "sdk/api/utils/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    refreshInterval: 1000 * 60,
    // one minute

    model: function model() {
      if (!window.runningTests) {
        this.pollForFollowUpConversations();
      }
      return this.get('api.agent.followUpConversations');
    },
    pollForFollowUpConversations: function pollForFollowUpConversations() {
      var _this = this;
      var interval = this.get('refreshInterval');
      this.set('interval', (0, _runloop.later)(function () {
        _this.get('api.agent').getFollowUpConversations().then(function () {
          _this.pollForFollowUpConversations();
        });
      }, interval));
    },
    actions: {
      willTransition: function willTransition(transition) {
        // eslint-disable-line no-unused-vars
        this.controller.set('selectedFollowUpId', null);
        if (this.get('interval')) {
          Ember.run.cancel(this.get('interval'));
        }
      }
    }
  });
});