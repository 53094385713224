define("sdk/components/popup-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "u5DOZ0VQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[26,\"concat\",[[22,[\"elementId\"]],\"-wormhole\"],null]]],{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"id\",[26,\"concat\",[[22,[\"elementId\"]],\"-popup\"],null],null],[11,\"class\",[27,[\"popup-window \",[20,\"popupClass\"]]]],[10,\"role\",\"dialog\"],[11,\"aria-labelledby\",[20,\"ariaLabelledBy\"],null],[11,\"aria-describedby\",[20,\"ariaDescribedBy\"],null],[11,\"style\",[20,\"windowZIndex\"],null],[11,\"data-test-popup\",[27,[[20,\"id\"]]]],[3,\"action\",[[21,0,[]],\"onFocusIn\"],[[\"on\",\"preventDefault\"],[\"focusIn\",false]]],[3,\"action\",[[21,0,[]],\"onKeyDown\"],[[\"on\",\"allowedKeys\",\"preventDefault\"],[\"keyDown\",\"shift\",false]]],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"popup-window__dialog\"],[10,\"role\",\"document\"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[10,\"class\",\"popup-window__content\"],[8],[0,\"\\n\\n        \"],[13,1,[[26,\"hash\",null,[[\"header\"],[[26,\"component\",[\"popup-window/window-header\"],[[\"onClose\",\"hasCloseButton\",\"title\",\"classNames\"],[[22,[\"onClose\"]],[22,[\"hasCloseButton\"]],[22,[\"title\"]],\"popup-window__header\"]]]]]]]],[0,\"\\n\\n        \"],[13,1,[[26,\"hash\",null,[[\"body\"],[[26,\"component\",[\"popup-window/window-default\"],[[\"classNames\"],[\"popup-window__body\"]]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"hasFooter\"]]],null,{\"statements\":[[0,\"          \"],[13,1,[[26,\"hash\",null,[[\"footer\"],[[26,\"component\",[\"popup-window/window-default\"],[[\"classNames\"],[\"popup-window__footer\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/popup-window/template.hbs"
    }
  });
});