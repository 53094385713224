define("sdk/components/transcript-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0sRNibvI",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"list-render\",null,[[\"class\",\"items\",\"options\",\"scrollToBottomIncrement\",\"getItemElement\",\"willRenderNewList\",\"onItemElementRemoved\",\"onCellsUpdated\"],[\"transcript-window__messages\",[22,[\"messages\"]],[22,[\"listRenderOptions\"]],[22,[\"scrollToBottomIncrement\"]],[26,\"action\",[[21,0,[]],\"getTranscriptLineElement\"],null],[26,\"action\",[[21,0,[]],\"onNewListRender\"],null],[26,\"action\",[[21,0,[]],\"destroyTranscriptLineComponentInstance\"],null],[26,\"action\",[[21,0,[]],\"onTranscriptLineCellsUpdated\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[26,\"and\",[[22,[\"engagement\",\"customer\",\"connected\"]],[22,[\"showTypingBubble\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[26,\"transcript-window/customer-typing-bubble\",null,[[\"isTyping\",\"isTypingMessage\",\"stopsTypingMessage\"],[[22,[\"engagement\",\"customer\",\"isTyping\"]],[22,[\"customerIsTypingMessage\"]],[22,[\"customerStopsTypingMessage\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"button\"],[10,\"id\",\"node-after-list-render\"],[10,\"class\",\"a11y-hidden\"],[10,\"role\",\"application\"],[3,\"action\",[[21,0,[]],\"focusListRender\"],[[\"on\"],[\"focusIn\"]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/transcript-window/template.hbs"
    }
  });
});