define("sdk/components/nested-dropdown/menu/option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0tBb2Edv",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,[\"option\",\"name\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/nested-dropdown/menu/option/template.hbs"
    }
  });
});