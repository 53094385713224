define("sdk/templates/layouts/-nina-coach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/rcK7gcI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"resizable-column\",null,[[\"minWidth\",\"width\",\"resizeEvent\",\"data-test-column\"],[320,[22,[\"engagement\",\"ui\",\"layout\",\"column0Width\"]],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"columnResizeEvent\"],null],\"1\"]],{\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[22,[\"api\",\"agent\",\"features\",\"transcriptV3\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"react-TranscriptWindow\",null,[[\"engagementId\",\"isConnected\",\"onClose\"],[[22,[\"engagement\",\"id\"]],[22,[\"engagement\",\"connected\"]],[26,\"action\",[[21,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[14,\"modules/active-transcript\",[]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"resizable-column\",null,[[\"minWidth\",\"tileNames\",\"resizeEvent\",\"data-test-column\"],[300,[26,\"array\",[\"Nina Coach\"],null],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"columnResizeEvent\"],null],\"2\"]],{\"statements\":[[0,\"\\n  \"],[1,[26,\"nina-coach\",[[22,[\"engagement\"]]],null],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":true}",
    "meta": {
      "moduleName": "sdk/templates/layouts/-nina-coach.hbs"
    }
  });
});