define("sdk/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.plusOne = plusOne;
  function plusOne(params /*, hash*/) {
    return parseInt(params[0]) + 1;
  }
  var _default = _exports.default = Ember.Helper.helper(plusOne);
});