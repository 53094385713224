define("sdk/api/utils/transcript-search", ["exports", "fuse", "sdk/api/utils/parse-html"], function (_exports, _fuse, _parseHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var fuzzySearch = function fuzzySearch() {
    var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var searchValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _options$searchKeys = options.searchKeys,
      searchKeys = _options$searchKeys === void 0 ? [] : _options$searchKeys;
    var fuseOptions = {
      ignoreLocation: true,
      includeMatches: true,
      keys: _toConsumableArray(searchKeys),
      minMatchCharLength: 1,
      sortFn: function sortFn(a, b) {
        return b.refIndex - a.refIndex;
      },
      threshold: 0.1,
      tokenize: true
    };
    var fuse = new _fuse.default(list, fuseOptions);
    return fuse.search(searchValue);
  };
  var searchMessages = function searchMessages() {
    var messages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var searchValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var messagesToSearch = _toConsumableArray(messages).map(function (message) {
      return {
        messageHTML: (0, _parseHtml.removeHtmlTags)(message.messageHTML),
        prefix: message.hidePrefix ? '' : message.prefix,
        uuid: message.uuid
      };
    });
    return fuzzySearch(messagesToSearch, searchValue, {
      searchKeys: ['messageHTML', 'prefix']
    });
  };
  var _default = _exports.default = searchMessages;
});