define("sdk/plugins/last-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vmPJfQ0t",
    "block": "{\"symbols\":[\"response\"],\"statements\":[[6,\"div\"],[10,\"class\",\"container\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row header\"],[8],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"col-9\"],[8],[0,\"Question\"],[9],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"col-3\"],[8],[0,\"Answer\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"body\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"data\",\"responses\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-9\"],[8],[1,[21,1,[\"question\"]],false],[9],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"col-3\"],[8],[1,[21,1,[\"answer\"]],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/plugins/last-survey/template.hbs"
    }
  });
});