define("sdk/components/transcript-window-active/component", ["exports", "sdk/components/transcript-window/component", "sdk/api/utils/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    messages: Ember.computed('engagement', 'engagement.messages.[]', 'previewMessages.[]', 'hideDetails', function () {
      var messages = this.get('engagement.messages').compact().filterBy('isInternalConversation', false);
      var previewMessages = this.get('previewMessages');
      return this.hideDetails ? messages.rejectBy('isDetailMessage').concat(previewMessages) : messages.concat(previewMessages);
    }),
    previewMessages: Ember.computed.alias('engagement.previewMessages'),
    init: function init() {
      this._super.apply(this, arguments);
      this.api.on('messageAdded', this, this._debounceScrollToBottom);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this._runDebounceScrollToBottom);
      this.api.off('messageAdded', this, this._debounceScrollToBottom);
    },
    _debounceScrollToBottom: function _debounceScrollToBottom(engagement, _, line) {
      if (engagement && engagement.get('id') !== this.get('engagement.id')) return;
      var shouldScrollToBottom = !line || line.isInternalSystem || !line.isInternalConversation;
      if (!shouldScrollToBottom) {
        this.set('scrollToBottomIncrement', 0);
        return;
      }
      this._runDebounceScrollToBottom = (0, _runloop.debounce)(this, this.incrementProperty, 'scrollToBottomIncrement', 100);
    }
  });
});