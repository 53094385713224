define("sdk/templates/modals/video-room-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XGcXvsdS",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"modalClass\",\"isBackDropCloseEnabled\",\"isKeyboardEnabled\",\"data-test-error-modal\",\"containerElement\"],[\"error-modal\",false,true,\"\",\"digital-voice-and-video-container\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"fa-icon\",[\"exclamation-circle\"],null],false],[0,\" \"],[1,[26,\"t\",[\"app-error-modal.title\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"top-error-container\"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[10,\"data-test-error-header\",\"\"],[10,\"class\",\"error-header\"],[8],[0,\"\\n\\n      \"],[6,\"p\"],[10,\"data-test-error-message\",\"\"],[8],[1,[26,\"t\",[\"video-chat.failed-to-create-video-room\"],null],false],[9],[0,\"\\n\\n      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"a11y-button\",null,[[\"data-test-error-modal-end-call-btn\",\"onSubmit\"],[\"\",[26,\"action\",[[21,0,[]],\"onEndCall\"],null]]],{\"statements\":[[0,\"      \"],[1,[26,\"t\",[\"cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"a11y-button\",null,[[\"data-test-error-modal-retry-btn\",\"onSubmit\"],[\"\",[26,\"action\",[[21,0,[]],\"onAcceptCall\"],null]]],{\"statements\":[[0,\"      \"],[1,[26,\"t\",[\"video-chat.retry\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/video-room-error.hbs"
    }
  });
});