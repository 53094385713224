define("sdk/components/child-modal/component", ["exports", "sdk/core/component", "ember-keyboard", "sdk/components/child-modal/template"], function (_exports, _component, _emberKeyboard, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    engagementFocusManager: Ember.inject.service(),
    hasFooter: true,
    modalRole: 'dialog',
    wormholeId: Ember.computed(function () {
      return "".concat(this.get('elementId'), "-wormhole");
    }),
    modalId: Ember.computed(function () {
      return "".concat(this.get('elementId'), "-modal");
    }),
    onTabWindow: Ember.on((0, _emberKeyboard.keyDown)('Tab'), function (event) {
      this._windowKeydownListener(event);
    }),
    onShiftTabWindow: Ember.on((0, _emberKeyboard.keyDown)('shift+Tab'), function (event) {
      this._windowKeydownListener(event);
    }),
    onKeyEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.onClose();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('lastActiveElement', document.activeElement);
      this.set('keyboardActivated', true);
      this._setBackdrop();
      Ember.$('#ember-wormhole').append("<div id=\"".concat(this.get('wormholeId'), "\"></div>"));
      this._windowFocusInListener = this._windowFocusInListener.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setInitialFocus();
      this._addWindowFocusInListener();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(window).off('focusin', this._windowFocusInListener);
      Ember.$("#".concat(this.get('wormholeId'))).remove();
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      var onHiddenFocusSelector = this.get('onHiddenFocusSelector');
      if (!onHiddenFocusSelector) {
        onHiddenFocusSelector = this.get('lastActiveElement');
      }
      Ember.$(onHiddenFocusSelector).focus();
    },
    /**
     * If focus is forced outside of the modal (i.e. using the screen reader),
     * move the focus back to the first actionable item.
     * @method _windowFocusInListener
     * @private
     */
    _windowFocusInListener: function _windowFocusInListener() {
      var _this = this;
      // Disable focus transfer if modal is focused
      var isModalFocused = false;
      ['child-modal__header', 'child-modal__body', 'child-modal__footer'].forEach(function (className) {
        if (Ember.$(document.activeElement).hasClass(className)) {
          isModalFocused = true;
        }
      });
      if (isModalFocused) return;

      // In the #ember-wormhole node, find the child index of this modal instance.
      // If there is a modal on top of this modal, do not focus this modal.
      var $children = Ember.$('#ember-wormhole').children();
      var childIndex = 0;
      Ember.$.each($children, function (index, child) {
        if (Ember.$(child).attr('id') === _this.get('wormholeId')) {
          childIndex = index;
        }
      });

      // If this instance isn't the last modal opened, do not refocus this modal.
      if (childIndex !== $children.length - 1) return;

      // safeguard against infinite loop
      Ember.$(window).off('focusin', this._windowFocusInListener);

      // If the focused node isn't part of the modal, focus the first actionable item.
      if (this._getFocusableModalNodes().index(document.activeElement) === -1) {
        this._setInitialFocus();
      }
      this._addWindowFocusInListener();
    },
    _addWindowFocusInListener: function _addWindowFocusInListener() {
      Ember.$(window).on('focusin', this._windowFocusInListener);
    },
    _setInitialFocus: function _setInitialFocus() {
      var initialFocusSelector = this.get('initialFocusSelector');
      var modalId = this.get('modalId');
      var initialFocusElement = initialFocusSelector && document.querySelector("#".concat(modalId, " ").concat(initialFocusSelector));
      this.get('engagementFocusManager').setInitialFocus(initialFocusElement || document.querySelector("#".concat(modalId)));
    },
    actions: {
      onFocusIn: function onFocusIn() {
        var $nodes = this._getFocusableModalNodes();
        if (event && event.target === $nodes[$nodes.length - 1]) {
          this.set('lastFocus', 'lastItem');
        } else if (event && event.target === $nodes[0]) {
          this.set('lastFocus', 'firstItem');
        } else {
          this.set('lastFocus', null);
        }
      },
      onKeyDown: function onKeyDown() {
        if (event.keyCode !== 9) return; // Tab key

        var isShiftKey = event.shiftKey;
        var lastFocus = this.get('lastFocus');
        if (!isShiftKey && lastFocus === 'lastItem') {
          event.preventDefault();
          this._getFocusableModalNodes().first().focus();
        } else if (isShiftKey && lastFocus === 'firstItem') {
          event.preventDefault();
          this._getFocusableModalNodes().last().focus();
        } else if (isShiftKey && this._getFocusableModalNodes().length <= 1) {
          event.preventDefault();
        }
      },
      onClickOutside: function onClickOutside() {
        var isBackDrop = event.target === Ember.$("#".concat(this.get('modalId')))[0];
        var isBackDropCloseEnabled = this.get('isBackDropCloseEnabled');
        if (isBackDrop && isBackDropCloseEnabled) {
          this.get('onClose')();
        }
      }
    },
    /**
     * Prevent default tab, shift+tab behavior when focus is on body or modal.
     * Manage the focus so focus doesn't move underneath modal.
     * @method _addWindowKeydownListener
     * @private
     */
    _windowKeydownListener: function _windowKeydownListener(event) {
      var isBodyFocused = event.target === window.document.body;
      var isOuterModalFocused = event.target === this.$('.child-modal')[0];
      var isOuterFocused = isBodyFocused || isOuterModalFocused;
      if (event.shiftKey && isOuterFocused) {
        event.preventDefault();
        this._getFocusableModalNodes().last().focus();
      } else if (isOuterFocused) {
        event.preventDefault();
        this._getFocusableModalNodes().first().focus();
      }
    },
    /**
     * Returns nodes that are part of the modal tab order.
     * @method _getFocusableModalNodes
     * @private
     */
    _getFocusableModalNodes: function _getFocusableModalNodes() {
      return this.get('containerElement') ? this.getFocusableNodes(".".concat(this.get('containerElement'), " .child-modal__content"), true) : this.getFocusableNodes("#".concat(this.get('wormholeId'), " .child-modal__content"), true);
    },
    /**
     * Counts the number of modals currently active and applies the appropriate
     * z-index to layer the modals on top of each other.
     * @method _setBackdrop
     * @private
     */
    _setBackdrop: function _setBackdrop() {
      var numberOfModals = Ember.$('.child-modal').length + Ember.$('.popup-window').length + 1;
      var backdropZIndex = numberOfModals * 1000;
      var modalZIndex = numberOfModals * 1001;
      this.set('modalZIndex', Ember.String.htmlSafe("z-index: ".concat(modalZIndex)));
      this.set('backdropZIndex', Ember.String.htmlSafe("z-index: ".concat(backdropZIndex)));
    }
  });
});