define("sdk/components/x-tabs/content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "aswHcKGm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"tab-content\"],[10,\"data-test-tab-content\",\"\"],[8],[0,\"\\n  \"],[13,1,[[22,[\"selectedTabData\"]]]],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/x-tabs/content/template.hbs"
    }
  });
});