define("sdk/components/logout-button/component", ["exports", "sdk/core/component", "sdk/components/logout-button/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'logout-button',
    session: Ember.inject.service(),
    tagName: 'button',
    attributeBindings: ['title', 'isDisabled:disabled'],
    /**
     * Hover title text
     * @property title
     * @type string
     * @private
     */
    title: Ember.computed('isDisabled', function () {
      if (this.get('isDisabled')) {
        return 'All engagements must be closed';
      } else {
        return 'Logout of the AI';
      }
    }),
    /**
     * Logout is disabled if there is an active engagement
     * @property isDisabled
     * @type boolean
     * @private
     */
    isDisabled: Ember.computed.alias('api.agent.hasEngagement'),
    /**
     * Invalidates the current login session
     * @method click
     */
    click: function click() {
      return this.get('session').invalidate();
    }
  });
});