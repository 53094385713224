define("sdk/services/context-menu", ["exports", "ember-context-menu/services/context-menu"], function (_exports, _contextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contextMenu.default;
    }
  });
});