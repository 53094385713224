define("sdk/api/adapters/auth-adapter", ["exports", "ember-ajax/request", "moment", "ember-ajax/raw", "sdk/api/adapters/api-adapter", "sdk/api/credentials", "sdk/api/utils/api-error"], function (_exports, _request, _moment, _raw, _apiAdapter, _credentials, _apiError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * @class AuthenticationAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    EXPIRES_IN_THRESHOLD: 500,
    //seconds

    REFRESH_UPPER_THRESHOLD: 70,
    // seconds

    TOKEN_REQUEST_TIMEOUT: 20 * 1000,
    TOKEN_REFRESH_REATTEMPT_TIMEOUT: 90,
    // seconds

    refreshThreshold: 35,
    // seconds

    hasAccessToken: Ember.computed.bool('api.authorization.access_token'),
    hasRefreshToken: Ember.computed.bool('api.authorization.refresh_token'),
    hasAuthTokens: Ember.computed.and('hasAccessToken', 'hasRefreshToken'),
    hasNoAuthTokens: Ember.computed.not('hasAuthTokens'),
    accessToken: Ember.computed.reads('api.authorization.access_token'),
    tokenType: Ember.computed.reads('api.authorization.token_type'),
    authorizationHeader: Ember.computed('accessToken', 'tokenType', function () {
      return "".concat(this.get('tokenType'), " ").concat(this.get('accessToken'));
    }),
    name: 'AUTH',
    init: function init() {
      this._super.apply(this, arguments);
      var authorization = sessionStorage.getItem('authorization');
      if (authorization) {
        this.api.set('authorization', JSON.parse(authorization));
      }
    },
    authenticate: function authenticate() {
      var _this = this;
      var credentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var username = credentials.username,
        password = credentials.password,
        transientToken = credentials.transientToken;
      var refreshToken = Ember.get(this, 'api.authorization.refresh_token');
      var body = {};
      if (username && password) {
        body.username = username;
        body.password = password;
        body.grant_type = 'password';
      } else if (transientToken) {
        body.auth_token = transientToken;
        body.grant_type = 'transient_token';
      } else if (refreshToken) {
        body.grant_type = 'refresh_token';
        body.refresh_token = refreshToken;
      } else {
        return Ember.RSVP.reject(new Error('Invalid authentication request'));
      }
      this.set('pendingAuth', {
        attempts: 0
      });
      var pendingAuthPromise = this._attemptAuthentication(body).then(function (response) {
        _this.resetRefreshToken5xxReattemptTimer();
        _this.api.trigger('connectionRestored');
        if (response.expires_in > _this.EXPIRES_IN_THRESHOLD) {
          //increase the refresh threshold depending on the expire_in value
          _this.set('refreshThreshold', _this.REFRESH_UPPER_THRESHOLD);
        }
        response.isSSO = body.grant_type === 'transient_token';

        // authentication was successful, agent has internet connection
        _this.set('_lostInternetConnection', null);
        _this.set('api.authorization', response);
        _this.set('api.lastAuthenticated', Date.now());
        sessionStorage.setItem('authorization', JSON.stringify(response));
        _this.get('api.logging').sendLogItemsToServerFromStorage();
        return response;
      }).catch(function (error) {
        var payload = Ember.get(error, 'payload');
        var responseBody = _typeof(payload) === 'object' ? JSON.stringify(payload) : payload;
        if (error.message === 'The ajax operation was aborted') {
          error.message = 'Application error';
          error.service = 'Auth';
        }
        if (error.refreshTokenAttemptsExceeded) {
          _this.sessionRevoked = true;
          return _this.api.trigger('invalidateSession', _objectSpread(_objectSpread({}, error), {}, {
            loginTranslationPath: 'default-invalidation-error'
          }));
        }
        return _this.api.get('router').checkOnlineStatus().then(function (isOnline) {
          // only invalidate session if we have one
          if (isOnline && _this.get('api.authorization.access_token')) {
            var invalidateError = {};
            var status = Ember.get(error, 'xhr.status');
            if (status === 401) {
              _this.sessionRevoked = true;
              invalidateError.status = status;
              invalidateError.detail = "".concat(Ember.get(error, 'options.method').toUpperCase(), " ").concat(Ember.get(error, 'url'), " - Response: ").concat(responseBody);
            }

            // 1. agent goes offline, 2. internet connection is restored, 3. GET messages request starts,
            // 4. auth check occurs but token is expired (due to no internet connection)
            if (_this.get('_lostInternetConnection')) {
              sessionStorage.setItem('internet_restored', true);
            }
            _this.api.trigger('invalidateSession', invalidateError);

            // we must continue to throw the error with ignore flag because application behaves incorrectly if we
            // return or reject this promise
            error.ignore = true;
          } else {
            _this.set('_lostInternetConnection', true);
          }
          throw error;
        });
      }).finally(function () {
        _this.set('pendingAuth', null);
      });
      this.set('pendingAuth.promise', pendingAuthPromise);
      return pendingAuthPromise;
    },
    resetRefreshToken5xxReattemptTimer: function resetRefreshToken5xxReattemptTimer() {
      clearTimeout(this.get('tokenRefreshReattemptTimer'));
      this.set('tokenRefreshReattemptTimer', undefined);
    },
    /**
     * Calls _ajaxAuth. Retries 10 times incase of timeout.
     * @method _attemptAuthentication
     * @param {Object} body request body to be forwareded to _ajaxAuth
     * @return {Promise} _ajaxAuth
    */
    _attemptAuthentication: function _attemptAuthentication(body) {
      var _this2 = this;
      this.incrementProperty('pendingAuth.attempts');
      var request = this._ajaxAuth('token', body);
      this.set('ajaxStalledTimer', setTimeout(function () {
        request.xhr.abort();
      }, this.TOKEN_REQUEST_TIMEOUT));
      return request.catch(function (error) {
        var hasAttemptsRemaining = _this2.get('pendingAuth.attempts') < 2;
        var isTokenRefresh = body.grant_type === 'refresh_token';
        var isRequestAborted = Ember.get(request, 'xhr.statusText') === 'abort';
        if (error && error.payload) {
          var status = error.payload.status;

          /**
           * If the refresh token API fails with the status code 5xx then,
           * 1. Retry to refresh the access token for 1 minute and 30 seconds.
           * 2. If it returns the status code 4xx (except 429) within 1 minute and 30 seconds, logout the user.
           * 3. If it returns 200, re-establish the connection
           */
          if (isTokenRefresh) {
            if (!_this2.get('tokenRefreshReattemptTimer') && (0, _apiError.isServerError)(status) && !_this2.get('refreshTokenAttemptsExceeded')) {
              _this2.api.trigger('connectionLost');
              _this2.set('tokenRefreshReattemptTimer', setTimeout(function () {
                _this2.set('refreshTokenAttemptsExceeded', true);
                _this2.resetRefreshToken5xxReattemptTimer();
              }, _this2.TOKEN_REFRESH_REATTEMPT_TIMEOUT * 1000));
              _this2.decrementProperty('pendingAuth.attempts');
              return _this2._attemptAuthentication(body);
            }
            if (_this2.get('refreshTokenAttemptsExceeded')) {
              _this2.set('refreshTokenAttemptsExceeded', false);
              return Promise.reject(_objectSpread(_objectSpread({}, error), {}, {
                refreshTokenAttemptsExceeded: true
              }));
            }
            if (_this2.get('tokenRefreshReattemptTimer')) {
              if (status === 429 || (0, _apiError.isServerError)(status)) {
                _this2.decrementProperty('pendingAuth.attempts');
                return _this2._attemptAuthentication(body);
              }
              if ((0, _apiError.isClientError)(status)) {
                _this2.resetRefreshToken5xxReattemptTimer();
                return Promise.reject(_objectSpread(_objectSpread({}, error), {}, {
                  refreshTokenAttemptsExceeded: true
                }));
              }
            }
          }
        }
        var shouldReAttemptAuthentication = hasAttemptsRemaining && isTokenRefresh && isRequestAborted;
        if (shouldReAttemptAuthentication) {
          return _this2._attemptAuthentication(body);
        }
        throw error;
      }).finally(function () {
        clearTimeout(_this2.get('ajaxStalledTimer'));
      });
    },
    /**
     * Returns a promise that ensures an active auth token is available
     * @method ensureValidAuthToken
     */
    ensureValidAuthToken: function ensureValidAuthToken() {
      if (this.get('pendingAuth')) return this.get('pendingAuth.promise');
      if (this.sessionRevoked) return Ember.RSVP.reject();
      var tokenTimeRemaining = this._getAccessTokenTimeRemaining();
      var needToAuthenticate = tokenTimeRemaining <= this.refreshThreshold;
      return needToAuthenticate ? this.authenticate() : Ember.RSVP.resolve();
    },
    /**
    * Get the amount of seconds remaining left for the authorization token
    * @method _getAccessTokenTimeRemaining
    */
    _getAccessTokenTimeRemaining: function _getAccessTokenTimeRemaining() {
      var lastAuthenticatedTime = this.get('api.lastAuthenticated');
      var authenticatedLength = (0, _moment.default)(Date.now()).diff(lastAuthenticatedTime);
      var secondsSinceAuthenticated = _moment.default.duration(authenticatedLength).asSeconds();
      var expiresIn = this.get('api.authorization.expires_in') || 0;
      var timeRemaining = expiresIn - secondsSinceAuthenticated;
      var isNegative = timeRemaining < 0;
      return isNegative ? 0 : timeRemaining;
    },
    redirectToIdp: function redirectToIdp() {
      var _this3 = this;
      this.clearAuthStorage();
      var url = this.api.urls.AUTHENTICATION_API + 'token';

      // prevent users from sharing authenticated links by storing a generated id "state" in sessionStorage
      // to be used only by device that requested SSO
      var timestampUid = Date.now();
      sessionStorage.setItem('ssoState', timestampUid);
      var body = {
        client_id: 'aeapiClientId',
        grant_type: 'password'
      };
      var options = this.getAuthRequestOptions({
        data: body,
        dataType: 'text'
      }, {
        'X-CSRF-Token': timestampUid,
        'x-referrer': window.location.href
      });
      return this.sendRawRequest(url, options).then(function (result) {
        var jqXHR = result.jqXHR;
        var SSO_SUCCESS_STATE = 278;
        var shouldRedirectForSSO = jqXHR && jqXHR.status === SSO_SUCCESS_STATE;
        if (!shouldRedirectForSSO) {
          throw new Error('Invalid SSO Request');
        }
        var redirectLocation = jqXHR.getResponseHeader('x-sso-location');
        if (!redirectLocation || typeof redirectLocation !== 'string') {
          throw new Error('Missing SSO Redirect URL');
        }
        _this3.redirectToUrl(redirectLocation);
      });
    },
    /**
     * Redirects user to a new url
     * @param {String} url
     */
    redirectToUrl: function redirectToUrl(url) {
      window.location.replace(url);
    },
    revoke: function revoke() {
      var _this4 = this;
      var body = {
        grant_type: 'access_token',
        access_token: Ember.get(this, 'api.authorization.access_token')
      };
      if (this.get('hasNoAuthTokens')) return Ember.RSVP.resolve('there are no auth tokens to revoke');
      return this._ajaxAuth('logout', body).then(function () {
        _this4.clearAuthStorage();
      }).catch(function () {});
    },
    /**
     * Sends an ajax raw request
     * @param {String} url
     * @param {Options} options
     */
    sendRawRequest: function sendRawRequest(url, options) {
      return (0, _raw.default)(url, options);
    },
    /**
    * Create an auth request to auth.touchcommerce.com
    * @method _ajaxAuth
    * @private
    * @param {String} url url endpoint of https://auth.touchcommerce.com/oauth-server/oauth/<url>
    * @param {Object} body data body of request
    * @return {Promise} auth request
    */
    _ajaxAuth: function _ajaxAuth() {
      var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'token';
      var body = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      url = this.api.urls.AUTHENTICATION_API + url;
      var options = this.getAuthRequestOptions({
        data: body,
        dataType: url.includes('/logout') ? 'text' : 'json'
      });
      var sentRequest = (0, _request.default)(url, options);
      return sentRequest.then(function (response) {
        return response;
      }).catch(function (err) {
        var xhr = sentRequest.xhr;
        Object.assign(err, {
          url: url,
          options: options,
          isNetworkError: true,
          xhr: xhr
        });
        throw err;
      });
    },
    /**
     * Creates an options object for auth requests
     * @method getAuthRequestOptions
     * @param {Object} additionalOptions additional request options
     * @param {Object} headerOptions additional header options
     * @return {Object} auth request options
     */
    getAuthRequestOptions: function getAuthRequestOptions(additionalOptions) {
      var headerOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var id = _credentials.default.id,
        secret = _credentials.default.secret;
      var headers = Object.assign({
        Authorization: "Basic ".concat(btoa("".concat(id, ":").concat(secret)))
      }, headerOptions);
      return Object.assign({
        method: 'post',
        crossDomain: true,
        headers: headers,
        contentType: 'application/x-www-form-urlencoded',
        xhrFields: {
          withCredentials: false
        }
      }, additionalOptions);
    },
    /**
     * Removes auth data from storage.
     * @method clearAuthStorage
     * @public
     */
    clearAuthStorage: function clearAuthStorage() {
      this.set('api.authorization', {});
      sessionStorage.setItem('authorization', JSON.stringify({}));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('ajaxStalledTimer'));
    }
  });
});