define("sdk/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin", "sdk/api/utils/standardize-error", "sdk/api/data/ignored-errors"], function (_exports, _applicationRouteMixin, _standardizeError, _ignoredErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'site.summary',
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    url: Ember.inject.service(),
    /**
     * This error gets thrown when the renderer breaks.
     * We check for it so that we do not get an infinite
     * rendering call stack.
     * @property {String} glimmerError
     */
    glimmerError: 'a glimmer transaction was begun, but one already exists. You may have a nested transaction',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      this.get('intl').setLocale('en');
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.onerror = this.handleError.bind(this);
      this.errors = [];
      this.api.on('authenticated', this, this.onAuthenticated);
      this.api.on('agentWasLoggedOff', this, this.onAgentWasLoggedOff);
    },
    onAuthenticated: function onAuthenticated() {
      this.set('ignore401', false);
    },
    onAgentWasLoggedOff: function onAgentWasLoggedOff() {
      this.set('ignore401', true);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.onerror = null;
      this.api.off('authenticated', this, this.onAuthenticated);
      this.api.off('agentWasLoggedOff', this, this.onAgentWasLoggedOff);
    },
    /**
     * Handles uncaught errors in the application.
     * Displays errors in a modal to the agent.
     * If the hide errors checkbox is checked,
     * no modal will be presented but errors will still
     * be swallowed to prevent the app from crashing.
     * @method handleError
     * @public
     * @param {Object} error the error to handle
     * @param {Boolean} error.alreadyThrown if the error was already thrown, don't handle it a second time
     */
    handleError: function handleError(error) {
      if (error && error.alreadyThrown) return;
      error.alreadyThrown = true;

      // do not display errors with "ignore" flag
      if (error && error.ignore) return;
      if (error && _ignoredErrors.default.includes(error.message)) return;
      if (error && error.remove) return this._removeError(error);

      // display error in console since error modal is only enabled in "debug" mode (HTMLAI-1430)
      console.error(error); // eslint-disable-line no-console

      var standardError = (0, _standardizeError.default)(error);
      if (!standardError) return;
      if (standardError.statusCode === 401) {
        var url = error.url,
          payload = error.payload;
        var isAEAPILogin = url && url.includes('/agent/login');
        var isAEAPILogout = url && url.includes('/agent/logout');
        var isAEAPIVerifySession = url && url.includes('/agent/verifySession');
        var isDCAPILogEvent = url && url.includes('/dataCollection/logEvent');
        if (this.get('ignore401') || isAEAPILogin || isAEAPILogout || isAEAPIVerifySession || isDCAPILogEvent) return;

        // to help with debugging add url to payload which will be displayed in details of sdk-login-form
        if (payload) {
          payload.url = url;
        }
        return this.get('api').trigger('invalidateSession', Ember.get(error, 'payload'));
      }

      // disabled for urgent removal of logs which are exacerbating AEAPI load on UK1 DC
      // this.get('api.logging').sendLogItemToServer(standardError);

      if (standardError.stack) {
        this.get('api.logging').sendLogItemToServer(standardError);
      }
      var errorMessageHtml = this._getErrorMessageHtml(standardError);
      this.get('errors').pushObject(errorMessageHtml);
      this._updateSiteControllerErrors();
    },
    model: function model() {
      var data = Ember.Object.create();
      this.set('data', data);
      return data;
    },
    /**
     * Processes errors into error messages.
     * @method _getErrorMessageHtml
     * @private
     * @param {Object} error the error to process
     * @return {String} the error message to display
     */
    _getErrorMessageHtml: function _getErrorMessageHtml(standardError) {
      var errorToDisplay;
      if (!standardError) return;
      if (standardError.title === 'Network Error Occurred') {
        errorToDisplay = '<div class="error-title">' + standardError.title + '</div>';
        errorToDisplay += "<div class=\"error-status-code\"><label>Status Code:</label> ".concat(standardError.statusCode, "</div>");
        if (standardError.url) {
          errorToDisplay += "<div class=\"error-url\"><label>URL:</label> ".concat(standardError.url, "</div>");
        }
        if (standardError.detail) {
          errorToDisplay += "<div class=\"error-detail\"><label>Details:</label> ".concat(standardError.detail, "</div>");
        }
        errorToDisplay += "<div class=\"error-time\"><label>Time:</label> ".concat(standardError.time, "</div>");
      } else {
        errorToDisplay = "<div class=\"error ".concat(standardError.errorType, "\">");
        errorToDisplay += "<div class=\"error-title\">".concat(standardError.title, "</div>");
        if (standardError.detail) errorToDisplay += "<div class=\"error-message\">".concat(standardError.detail, "</div>");
        errorToDisplay += "<div class=\"error-time\"><label>Time:</label> ".concat(standardError.time, "</div>");
        if (standardError.stack) {
          errorToDisplay += "<div class=\"error-stack\"><label>Stack Trace:</label><div>".concat(standardError.stack, "</div></div>");
        }
        errorToDisplay += '</div>';
      }
      return Ember.String.htmlSafe(errorToDisplay);
    },
    /**
     * Removes an error from the errors array given the error's message
     * property.
     * @method _removeError
     * @private
     * @property {Object} error the error to remove
     * @property {String} error.message the message to search for
     */
    _removeError: function _removeError(error) {
      var message = error.message;
      if (!message) return;
      this.set('errors', this.get('errors').reject(function (error) {
        return error.string.includes(message);
      }));
      this._updateSiteControllerErrors();
    },
    /**
     * Updates the number of errors displayed in the pill box
     * modal in the site header.
     * @method _updateSiteControllerErrors
     * @private
     */
    _updateSiteControllerErrors: function _updateSiteControllerErrors() {
      var siteController = this.controllerFor('site');
      if (!siteController) return;
      siteController.set('errors', this.get('errors'));
    },
    sessionInvalidated: function sessionInvalidated() {
      // remove page reload listener from site route
      Ember.$(window).off('beforeunload');
      var hrefNoQueryParams = this.get('url.hrefNoQueryParams');
      var _this$get$getQueryPar = this.get('url').getQueryParams(),
        SSO = _this$get$getQueryPar.SSO;
      window.location = hrefNoQueryParams + (SSO ? '?SSO=logout' : '');
    },
    actions: {
      /**
       * Hides the error modal and resets the modal state.
       * @method hideErrorModal
       * @public
       */
      hideErrorModal: function hideErrorModal() {
        this.set('data.showErrorModal', false);
        this.set('data.showErrors', false);
      },
      /**
       * Displays the error modal.
       * @method showErrorModal
       * @public
       */
      showErrorModal: function showErrorModal() {
        this.set('data.errors', this.get('errors').reverseObjects());
        this.set('data.showErrorModal', true);
      },
      /**
       * Persist the previous route and current route name whenever there is a route change transition
       * previousRoute is used to transition back to previous route if agent is locked out
       * @method willTransition
       */
      willTransition: function willTransition(transition) {
        var currentRoute = transition.targetName;
        var previousRoute = this.get('router.currentRouteName');
        var storage = this.get('api.storage');
        storage.store.currentRoute = currentRoute;
        storage.store.previousRoute = previousRoute;
        Ember.run.scheduleOnce('afterRender', function () {
          storage.persist();
        });
      }
    }
  });
});