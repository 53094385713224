define("sdk/api/utils/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(textToCopy) {
    var $tmpInput = Ember.$('<input/>');
    Ember.$('body').append($tmpInput);
    $tmpInput.val(textToCopy).select();
    document.execCommand('copy');
    $tmpInput.remove();
  }
});