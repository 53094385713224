define("sdk/templates/modals/-async-cannot-resolve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ab4WJpOM",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"id\",\"modalClass\",\"modalRole\",\"hasCloseButton\",\"modalAriaLabelledBy\",\"modalAriaDescribedBy\",\"onClose\"],[\"show-async-cannot-resolve-modal\",\"async-cannot-resolve-modal\",\"alertdialog\",true,\"show-async-modal-header\",\"show-async-modal-body\",[26,\"action\",[[21,0,[]],\"closeAsyncCannotResolveModal\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"show-async-modal-header\"],[10,\"data-test-show-async-modal-header\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"async.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"show-async-modal-body\"],[10,\"data-test-show-async-modal-body\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"async.cannot-resolve\"],[[\"conversationId\"],[[22,[\"conversationId\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"button\"],[10,\"class\",\"btn btn-primary\"],[10,\"data-test-async-cannot-resolve-ok-btn\",\"\"],[11,\"disabled\",[22,[\"engagement\",\"isLocked\"]],null],[3,\"action\",[[21,0,[]],\"closeAsyncCannotResolveModal\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"async.okay\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-async-cannot-resolve.hbs"
    }
  });
});