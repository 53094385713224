define("sdk/components/request-additional-chat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WfSR+y4N",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"toggle\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"toggle-circle\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/request-additional-chat/template.hbs"
    }
  });
});