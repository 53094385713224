define("sdk/api/router", ["exports", "moment", "sdk/api/engagement", "sdk/api/utils/agent-availability-serializer"], function (_exports, _moment, _engagement, _agentAvailabilitySerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var messagesRequestCounter = 0;

  /**
   * @class AIAPI.Router
   */
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    /**
     * Interval time it takes to attempt to restore internet connection
     * @property {Number} RECONNECT_INTERVAL
     */
    RECONNECT_INTERVAL: 4000,
    // 4 seconds

    /**
     * Online status of agent, current values can be "connected" or "disconnected"
     * Value is updated when there's a change when checkOnlineStatus() is called
     * @property {String} onlineStatus
     */
    onlineStatus: 'connected',
    /**
     * Agent is connected to the internet
     * @property {Boolean} isOnline
     */
    isOnline: Ember.computed.equal('onlineStatus', 'connected'),
    /**
     * How often the application will try to reconnect with verifySession.
     * @property {Number} SESSION_RECONNECT_INTERVAL
     */
    SESSION_RECONNECT_INTERVAL: 4000,
    // 4 seconds

    /**
     * The maximum duration for attempted session reconnect attempts.
     * @property {Number} MAX_SESSION_RECONNECT_DURATION
     */
    MAX_SESSION_RECONNECT_DURATION: 7 * 60 * 1000,
    // 7 minutes

    /**
     * Contains all incoming engagements which have not yet
     * been accepted. Used to only process additional incoming
     * messages before such engagements are accepted.
     * @property {Array} incomingEngagements
     * @public
     */

    /**
     * Whether the router should stop polling GET /messages requests
     * @property {Boolean} _closed
     */
    _closed: true,
    init: function init() {
      var _this = this;
      this.set('messages', []);
      this.set('incomingEngagements', []);
      this.agent = this.api.get('agent');
      this.api.on('authenticated', function () {
        _this.open();
      });
    },
    restoreEngagements: function restoreEngagements() {
      var _this2 = this;
      var _this$api$storage$sto = this.api.storage.store,
        engagements = _this$api$storage$sto.engagements,
        registeredId = _this$api$storage$sto.registeredId;
      this.api.agent.engagements.clear();
      Object.keys(engagements).forEach(function (id) {
        var data = engagements[id];
        if (data.registeredId !== registeredId) {
          _this2.api.storage.clearEngagement(id);
          return;
        }
        data = Ember.assign({}, data);
        data.api = _this2.api;
        var engagement = _engagement.default.create(data);
        _this2.api.agent.addEngagement(engagement);
      });
    },
    open: function open() {
      (false && !(this.get('api.agent.isLoggedIn')) && Ember.assert('Agent must login before opening the router', this.get('api.agent.isLoggedIn')));
      if (!this.get('_closed')) return;
      this.set('_closed', false);
      this._getMessages();
      this._monitorGetMessages();
      this._keepSessionAlive();
    },
    close: function close() {
      this.set('_closed', true);
      clearInterval(this.get('getMessagesMonitorInterval'));
      clearInterval(this.get('sessionReconnectTimeout'));
      clearTimeout(this.get('keepSessionAliveTimeout'));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('_reconnectTimeout'));
      clearInterval(this.get('getMessagesMonitorInterval'));
      clearInterval(this.get('sessionReconnectTimeout'));
      clearTimeout(this.get('keepSessionAliveTimeout'));
    },
    'data-test-setMaxRequests': function dataTestSetMaxRequests(count) {
      this._maxRequestCount = count;
    },
    _getMessages: function _getMessages() {
      var _this3 = this;
      if (this.get('pendingMessagesRequest')) return this.get('pendingMessagesRequest');
      messagesRequestCounter++;
      var _Ember$RSVP$defer = Ember.RSVP.defer(messagesRequestCounter + ') received last incoming message'),
        resolve = _Ember$RSVP$defer.resolve,
        promise = _Ember$RSVP$defer.promise;
      this.set('pendingMessagesRequest', promise);
      var getMessagesRequest = this.get('api.adapter').getMessages();
      getMessagesRequest.then(function (data) {
        _this3.set('pendingMessagesRequest', null);

        //HTMLAI-5137 added new logs to debug the vodafone issue

        if (data && data.engagements) {
          var engagementIds = data.engagements.map(function (_ref) {
            var id = _ref.id;
            return id;
          });
          _this3.get('api.logging').sendLog('info', {
            event_group: 'engagement',
            event: 'new-message',
            engagementIds: engagementIds.join(',')
          });
        }

        // set the time of the last successful GET messages request
        _this3.set('_lastSuccessfulMessageTime', Date.now());
        data = data || {};
        var processMessagesPromise = _this3._processMessages(data);
        if (typeof _this3._maxRequestCount === 'number') {
          if (_this3._maxRequestCount) {
            --_this3._maxRequestCount;
          } else {
            return processMessagesPromise.then(resolve);
          }
        }
        var messagesReceived = !!Ember.get(data, 'engagements.length');
        var keepPolling = window.runningTests ? messagesReceived : true;
        if (!keepPolling) {
          return processMessagesPromise.then(resolve);
        }

        // Run in next event loop to avoid long threaded processes
        // and difficult error tracking
        Ember.run.next(function () {
          if (_this3.get('checkForMessages')) {
            _this3._getMessages().then(resolve);
          }
        });
      }).catch(function (error) {
        if (_this3.get('isDestroyed') || _this3.get('isDestroying')) return;
        _this3.set('pendingMessagesRequest', null);

        // will attempt to reconnect if failed network request is not 401 or 503
        // application route will invalidate session on 401 or 503
        var status = Ember.get(error, 'payload.status');
        var shouldReconnect = !(_this3.get('_closed') || status === 401 || status === 503);
        if (shouldReconnect) {
          error.ignore = true;
          return _this3._reconnect();
        }
        throw error;
      });
      return promise;
    },
    /**
     * Start interval to call getMessages every 15 seconds
     * @method _monitorGetMessages
     */
    _monitorGetMessages: function _monitorGetMessages() {
      var _this4 = this;
      var getMessagesMonitorInterval = this.get('getMessagesMonitorInterval');
      clearInterval(getMessagesMonitorInterval);
      getMessagesMonitorInterval = setInterval(function () {
        _this4._getMessages();
      }, 15 * 1000);
      this.set('getMessagesMonitorInterval', getMessagesMonitorInterval);
    },
    /**
    * This is a temporary workaround to handle the side effects
    * caused by chrome's new feature that throttles JS Timers.
    * This function calls itself every 40 seconds to keep the server session
    * (heart-beat) alive.
    * If a request is not sent within a minute of the last response, then the
    * server kicks out the agent from agent registry.
    * @method _keepSessionAlive
    */
    _keepSessionAlive: function _keepSessionAlive() {
      var _this5 = this;
      clearTimeout(this.get('keepSessionAliveTimeout'));
      this.get('api.adapter').summaryDataViaFetch().then(function (summaryData) {
        var _serializeAgentAvaila = (0, _agentAvailabilitySerializer.default)(summaryData),
          agentSummary = _serializeAgentAvaila.agentSummary,
          hasPreferredQueue = _serializeAgentAvaila.hasPreferredQueue;
        _this5.api.agent.state.set({
          agentSummary: agentSummary,
          hasPreferredQueue: hasPreferredQueue
        });
      }).finally(function () {
        _this5.set('keepSessionAliveTimeout', setTimeout(function () {
          _this5._keepSessionAlive();
        }, 40 * 1000));
      });
    },
    /**
     * Attempt to reconnect the agent by pinging the messages route
     * up to a max time limit (1min)
     * @method _reconnect
     */
    _reconnect: function _reconnect(failedRequest) {
      var _this6 = this;
      this.set('_reconnectTimeout', setTimeout(function () {
        _this6.checkOnlineStatus().then(function (isOnline) {
          // once reconnected, resume getting getMessages
          if (isOnline) {
            failedRequest && _this6._reconnectSession(failedRequest);
            return _this6._getMessages();
          }
          // otherwise keep polling for internet connection
          _this6._reconnect(failedRequest);
        });
      }, this.RECONNECT_INTERVAL));
    },
    /**
     * Checks if there's an internet connection
     * true: has internet connection
     * false: no internet connection
     * @method checkOnlineStatus
     */
    checkOnlineStatus: function checkOnlineStatus() {
      var _this7 = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var previouslyOnline = _this7.get('isOnline');
        if (!navigator.onLine) {
          if (previouslyOnline) _this7._connectionLost();
          resolve(false);
        }
        var img = new Image();
        img.src = "assets/images/blank.gif?t=".concat(Date.now());
        img.onerror = function () {
          if (previouslyOnline) _this7._connectionLost();
          resolve(false);
        };
        img.onload = function () {
          if (!previouslyOnline) _this7._connectionRestored();
          resolve(true);
        };
      });
    },
    _connectionLost: function _connectionLost() {
      this.api.trigger('connectionLost');

      // clear interval started in this._monitorGetMessages
      clearInterval(this.get('getMessagesMonitorInterval'));

      // clear interval started in _keepSessionAlive()
      clearInterval(this.get('keepSessionAliveTimeout'));
      this.set('onlineStatus', 'disconnected');
    },
    _connectionRestored: function _connectionRestored() {
      this.api.trigger('connectionRestored');
      this._monitorGetMessages();
      this._keepSessionAlive();
      this.set('onlineStatus', 'connected');
    },
    checkForMessages: Ember.computed('agent.engagements.[]', '_closed', function () {
      return this.get('agent.engagements.length') > 0 || !this.get('_closed');
    }),
    /**
     * Will set the application into the reconnect flow.
     * @method reconnectSession
     * @public
     * @param {Object} failedRequest the original request which caused the reconnect flow
     */
    reconnectSession: function reconnectSession(failedRequest) {
      var api = this.api;
      var ajaxQueueManager = api.ajaxQueueManager;
      this.set('initialSessionReconnectTimestamp', this.get('api.currentTime'));
      this.set('_sessionConnected', false);
      api.trigger('connectionLost');

      // halt will skip re-adding this request because we re-add it later
      ajaxQueueManager.haltAEAPI(failedRequest);
      if (!navigator.onLine) {
        this._reconnect(failedRequest);
      } else {
        this._reconnectSession(failedRequest);
      }
    },
    /**
     * Poll verifySession until success or 401 or time spent is >= MAX_SESSION_RECONNECT_DURATION.
     * - If success: retry failedRequest if it is a GET request
     * - If failure: repeat _reconnectSession
     * - If 401: hit /login and attempt to join all pending engagements
     * @method _reconnectSession
     * @private
     */
    _reconnectSession: function _reconnectSession(failedRequest) {
      var _this8 = this;
      var currentTime = this.get('api.currentTime');
      var initialSessionReconnectTimestamp = this.get('initialSessionReconnectTimestamp');
      var timeElapsed = (0, _moment.default)(currentTime).diff(initialSessionReconnectTimestamp);
      var millisSinceReconnectStarted = _moment.default.duration(timeElapsed).asMilliseconds();
      var api = this.api;
      if (millisSinceReconnectStarted >= this.MAX_SESSION_RECONNECT_DURATION) {
        return api.trigger('invalidateSession');
      }
      var agent = api.agent,
        storage = api.storage;
      var userId = agent.userId;
      var registeredId = storage.store.registeredId;
      var pendingVerifySession = this.api.adapter.verifySession(userId, registeredId);
      pendingVerifySession.then(function () {
        clearTimeout(_this8.get('sessionReconnectTimeout'));
        api.trigger('connectionRestored');
        _this8._addFailedRequestToQueue(failedRequest);
      }).catch(function (error) {
        if (error) {
          error.ignore = true;
        }
        var shouldNotReLogin = !error || error.xhr && error.xhr.status !== 401;
        if (shouldNotReLogin) return;
        clearTimeout(_this8.get('sessionReconnectTimeout'));
        var options = {
          reLogin: true,
          state: agent.get('status.value'),
          reason: agent.get('status.label')
        };
        var agentGroupIds = _this8.get('api.agent.agentGroupIds');
        if (agentGroupIds) {
          options.agentGroupIds = agentGroupIds;
        }
        return _this8.api.adapter.login(userId, options).then(function (data) {
          try {
            // catch any errors in here to prevent any accidental session invalidation
            var _registeredId = data.registeredId,
              pendingChats = data.pendingChats;
            agent.set('registeredId', _registeredId);
            api.trigger('connectionRestored');
            api.trigger('agentRelogin', {
              registeredId: _registeredId
            });
            _this8.get('api.logging').sendLog('warn', {
              event_group: 'aeapi',
              event: 'new-aeapi-session'
            });
            if (!pendingChats) {
              _this8._addFailedRequestToQueue(failedRequest, _registeredId);
              return;
            }
            var remainingEngagements = agent.get('engagements').filter(function (engagement) {
              return pendingChats.includes(engagement.id);
            });
            agent.set('engagements', remainingEngagements);
            pendingChats.forEach(function (engagementId, index, remainingEngagements) {
              api.adapter.joinEngagement(engagementId).then(function () {
                var successfullyJoinedEngagement = agent.get('engagements').findBy('id', engagementId);
                api.trigger('updateRegisteredId', successfullyJoinedEngagement);
              }).catch(function () {
                agent.set('engagements', agent.get('engagements').rejectBy('id', engagementId));
                api.trigger('failedToJoinEngagement', engagementId);
              }).finally(function () {
                //The failed request needs to be processed only after all the engagements are joined
                if (index === remainingEngagements.length - 1) {
                  // the last iteration
                  _this8._addFailedRequestToQueue(failedRequest, _registeredId);
                }
              });
            });
          } catch (e) {} // eslint-disable-line no-empty
        }).catch(function (error) {
          if (!error || error.xhr && error.xhr.status !== 401) return;
          var _ref2 = Ember.get(error, 'payload') || {},
            detail = _ref2.detail;
          if (detail && detail.includes('Agent is force logged out by Supervisor')) {
            api.trigger('invalidateSession', {
              title: detail
            });
          } else {
            api.trigger('invalidateSession');
          }
        }).finally(function () {
          _this8.api.ajaxQueueManager.continue();
        });
      });
      this.set('sessionReconnectTimeout', setTimeout(function () {
        var xhr = pendingVerifySession.xhr;
        xhr && xhr.abort();
        _this8._reconnectSession(failedRequest);
      }, this.SESSION_RECONNECT_INTERVAL));
    },
    /**
    * Add the failed request to the Ajax-Queue-Manager and
    * trigger the agentRelogin if new regiesteredId is received.
    * @method _addFailedRequestToQueue
    * @private
    * @param {Object} failedRequest the original request which caused the reconnect flow
    * @param {String} registeredId the new registeredId received after the agent re-logins
    */
    _addFailedRequestToQueue: function _addFailedRequestToQueue(failedRequest, registeredId) {
      var api = this.api;
      var ajaxQueueManager = api.ajaxQueueManager;
      var ajaxOptions = failedRequest.ajaxOptions;
      var shouldReAddRequest = ajaxOptions && ((ajaxOptions.method || '').toLowerCase() === 'get' || ajaxOptions.shouldReAdd);
      if (shouldReAddRequest) ajaxQueueManager.add(failedRequest, {
        unshift: true
      });

      // agentRelogin trigger updates the new registeredId on all the requests in the queue
      if (registeredId) api.trigger('agentRelogin', {
        registeredId: registeredId
      });
      ajaxQueueManager.continue();
    },
    _processMessages: function _processMessages(data) {
      var _this9 = this;
      var systemMessages = Ember.get(data, 'messages');
      if (systemMessages) {
        this._processSystemMessages(systemMessages);
      }
      var messagesReceived = !!Ember.get(data, 'engagements.length');
      if (!messagesReceived) return Ember.RSVP.resolve();
      var api = this.get('api');
      var agent = this.get('agent');
      return Ember.RSVP.all(data.engagements.map(function (data) {
        var engagementId = data.id,
          engagementSettings = data.settings;
        var engagements = agent.get('engagements');
        var engagement = engagements.findBy('id', engagementId) || _this9.get('incomingEngagements').findBy('id', engagementId);
        var shouldSearchForLoadedEngagement = !engagement && engagements.length && engagementSettings;
        if (shouldSearchForLoadedEngagement) {
          engagement = _this9._findEngagementByConversationId(engagementSettings.conversationId);
          if (engagement && engagement.get('manuallyLoaded')) {
            engagement.setId(engagementId);
            api.trigger('followUpInitiatedSuccessfully', engagement);
          } else {
            // if new engagement has same conversationId as an existing engagement but isn't manuallyLoaded,
            // create new engagement by setting to null
            engagement = null;
          }
        }
        if (engagement) {
          data.queueMessages = systemMessages;
          engagement._loadMessages(data);
          _this9.trigger('newMessage', engagement, data);
        } else if (_this9._isNewRequest(data)) {
          engagement = _engagement.default.create({
            api: api,
            id: data.id
          });
          engagement._loadMessages(data);
          _this9.get('incomingEngagements').pushObject(engagement);
          return engagement.accept().then(function () {
            _this9.trigger('newMessage', engagement, data);
          }).finally(function () {
            _this9.get('incomingEngagements').removeObject(engagement);
          });
        } else {
          console.warn('Unexpected engagement messages', data); // eslint-disable-line no-console
        }
      }));
    },
    /**
     * Processes incoming system messages which are not found in
     * any engagements' messages array.
     * @method _processSystemMessages
     * @private
     * @param {Array} messages incoming system messages
     */
    _processSystemMessages: function _processSystemMessages(messages) {
      var _this10 = this;
      messages.forEach(function (message) {
        var state = message.state,
          userId = message.userId,
          messageText = message.messageText;
        var agentWasLoggedOff = state === 'agentLostConnection' && userId === _this10.get('api.agent.userId') && (messageText === 'Agent is force logged out' || messageText === 'Agent re-logins');
        if (agentWasLoggedOff) {
          _this10.api.trigger('agentWasLoggedOff');
        }
      });
    },
    _isNewRequest: function _isNewRequest(data) {
      if (!data || !data.messages) return;
      return data.messages.filter(function (message) {
        return message.messageType === 'newRequest';
      }).length;
    },
    escalateEngagement: function escalateEngagement(engagement, reason) {
      engagement.state.set({
        'isLocked': true
      });
      return this.get('api.adapter').escalateEngagement(engagement.id, reason).then(function () {
        engagement.state.set({
          escalatedNotes: reason,
          isEscalated: true
        });
      }).finally(function () {
        engagement.state.set({
          'isLocked': false
        });
      });
    },
    getTransferConferenceOptions: function getTransferConferenceOptions(options) {
      return this.get('api.adapter').transferConferenceOptions(options);
    },
    conferenceEngagement: function conferenceEngagement(engagement, options) {
      Object.assign(options, {
        engagementId: engagement.id,
        initAgentGroupName: engagement.get('settings.agentGroupName'),
        initBusinessUnitName: engagement.get('settings.businessUnitName')
      });
      return this.get('api.adapter').conferenceEngagement(options);
    },
    transferEngagement: function transferEngagement(engagement, options) {
      Object.assign(options, {
        engagementId: engagement.id,
        initAgentGroupName: engagement.get('settings.agentGroupName'),
        initBusinessUnitName: engagement.get('settings.businessUnitName')
      });
      return this.get('api.adapter').transferEngagement(options).then(function (response) {
        engagement.set('isVisibleTransferWindow', false);
        engagement.set('isVisibleDispositionsWindow', true);
        return response;
      });
    },
    autoTransferEngagement: function autoTransferEngagement(engagement) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var transferOptions = Object.assign({
        agentNotes: 'auto transferred because agent did not respond within SLA',
        engagementId: engagement.id,
        businessUnitId: engagement.get('settings.businessUnitId'),
        agentGroupId: engagement.get('settings.agentGroupId'),
        initAgentGroupName: engagement.get('settings.agentGroupName'),
        initBusinessUnitName: engagement.get('settings.businessUnitName')
      }, options);
      return this.get('api.adapter').autoTransferEngagement(transferOptions);
    },
    transferOwnership: function transferOwnership(engagement) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      Object.assign(options, {
        engagementId: engagement.id
      });
      return this.get('api.adapter').transferOwnership(options);
    },
    _findEngagementByConversationId: function _findEngagementByConversationId() {
      var conversationId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return this.get('api.agent.engagements').find(function (engagement) {
        return engagement.get('settings.conversationId') === conversationId;
      });
    }
  });
});