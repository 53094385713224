define("sdk/templates/modules/-static-transcript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "E5s/G2s9",
    "block": "{\"symbols\":[\"tile\",\"api\"],\"statements\":[[4,\"if\",[[26,\"not\",[[22,[\"engagement\",\"uiData\",\"isStaticTranscriptPanelHidden\"]]],null]],null,{\"statements\":[[4,\"collapsable-tile\",null,[[\"aria-label\",\"data-test-static-transcript-tile\",\"singleTile\",\"onTileToggle\",\"tileName\",\"iconAriaLabel\",\"ui\",\"height\",\"isCollapsed\",\"resizable\",\"resizableEnabled\"],[[26,\"t\",[\"static-transcript.static-transcript\"],null],\"\",false,[26,\"action\",[[21,0,[]],\"onTileToggle\"],null],\"static-transcript\",[26,\"t\",[\"static-transcript.static-transcript\"],null],[22,[\"ui\"]],[22,[\"engagement\",\"ui\",\"layout\",\"staticTranscriptWindowHeight\"]],[22,[\"engagement\",\"ui\",\"layout\",\"staticTranscriptWindowCollapsed\"]],[22,[\"isChatRightLayout\"]],[22,[\"isChatRightLayout\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"static-transcript-window\",null,[[\"data-test-static-transcript-window\",\"engagement\",\"class\",\"data-test-full-transcript-container\"],[\"\",[22,[\"engagement\"]],\"full-transcript-container flex-none\",\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n        \"],[6,\"h3\"],[10,\"data-test-static-transcript-heading\",\"\"],[8],[1,[26,\"t\",[\"static-transcript.static-transcript\"],null],false],[9],[0,\"\\n\\n        \"],[1,[21,2,[\"search\"]],false],[0,\"\\n\\n\"],[4,\"unless\",[[21,2,[\"filter\"]]],null,{\"statements\":[[0,\"          \"],[1,[21,2,[\"history\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"content\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"component\",[[21,2,[\"transcript\"]]],[[\"class\"],[\"static-transcript\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modules/-static-transcript.hbs"
    }
  });
});