define("sdk/components/nested-dropdown/component", ["exports", "sdk/core/component", "sdk/components/nested-dropdown/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Script group dropdown selector
   *
   * Displays a list of script groups and script folders
   * Script group folders contain nested script groups
   * Passes the selected script group up to the parent component
   *
   * @class Scripts.ScriptGroupSelect
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'dropdown-nested',
    /**
     * @property isMenuVisible
     * @type boolean
     */
    isMenuVisible: false,
    actions: {
      /**
       * Toggles whether or not the menu is visible
       * @method toggleMenu
       */
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isMenuVisible');
      },
      /**
       * Sets the selected option
       * Passes selected option with onSelected action
       * @method onSelected
       * @param {*} option
       * @private
       */
      onSelected: function onSelected(option) {
        this.set('selectedOption', option);
        var onSelected = this.get('onSelected');
        onSelected && onSelected(option);
      }
    },
    /**
     * Menu will hide when mouse has exited the menu
     * @method mouseLeave
     * @private
     */
    mouseLeave: function mouseLeave() {
      this.set('isMenuVisible', false);
    }
  }).reopenClass({
    positionalParams: ['options', 'selectedOption']
  });
});