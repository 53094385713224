define("sdk/api/models/core/select-list", ["exports", "sdk/api/models/core/select-item", "sdk/api/models/core/array-proxy"], function (_exports, _selectItem, _arrayProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /**
   * Array-like class for selecting zero or more items within
   * an order list.  Constraints for the selections can be enforced
   *
   * @class SelectList
   * @extends ArrayProxy
   * @private
   */
  var _default = _exports.default = _arrayProxy.default.extend(Ember.Evented, {
    /**
     * If true, allow selection of only one item.
     * Inverse of `multiple`
     * @property single
     * @type {Boolean}
     */
    single: false,
    /**
     * If true, allow selection of multiple items.
     * Inverse of `single`
     * @property multiple
     * @type {Boolean}
     */
    multiple: true,
    max: Infinity,
    min: 0,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      ['toggleAll', 'selectAll', 'deselectAll'].forEach(function (fn) {
        return _this[fn] = _this['_' + fn].bind(_this);
      });
      if (this.hasOwnProperty('multiple') && this.hasOwnProperty('single') && !!this.multiple === !!this.single) {
        throw new Error('`multiple` and `single` must not be the same');
      } else if (this.hasOwnProperty('multiple') && this.multiple) {
        this.single = false;
      } else if (this.hasOwnProperty('single') && this.single) {
        this.setProperties({
          max: 1,
          min: 1,
          multiple: false
        });
      }
      if (this.min > this.max) {
        throw new Error('`min` must be less than or equal to `max`');
      }
      var content = this.content || [];
      this.content = [];
      this.selectedItems = _arrayProxy.default.create();
      content.forEach(function (item) {
        return _this.addItem(item);
      });
      this.addObserver('selectedItems.length', this._onSelectedItemsLengthChange);
    },
    /**
     * When selected items length changes and there are more selected than max,
     * deselect first item until selected = max count
     * @method _onSelectedItemsLengthChange
     */
    _onSelectedItemsLengthChange: function _onSelectedItemsLengthChange() {
      while (this.get('selectedItems.length') > this.get('max')) {
        this.get('selectedItems.firstObject').deselect();
      }
    },
    willDestroy: function willDestroy() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.removeObserver('selectedItems.length', this._onSelectedItemsLengthChange);
      this.content.forEach(function (item) {
        item.off('selected', _this2, _this2._onItemSelected);
        item.off('deselected', _this2.selectedItems, 'removeObject');
      });
    },
    addItem: function addItem(obj) {
      if (_typeof(obj) !== 'object') {
        throw new Error('SelectList requires each content item to be an object. Received: ' + obj);
      }
      var item = _selectItem.default.create({
        content: obj
      });
      this.content.pushObject(item);
      item.on('selected', this, this._onItemSelected);
      item.on('deselected', this.selectedItems, 'removeObject');
    },
    _onItemSelected: function _onItemSelected(item) {
      // ember-radio-button doesn't fire changed event for other
      // radio buttons in the group so we have to manually
      // toggle them off which will also remove them from selected items
      if (!this.get('multiple')) {
        this.selectedItems.forEach(function (item) {
          return item.toggle();
        });
      }
      this.selectedItems.addObject(item);
    },
    itemAt: function itemAt() {
      return this.objectAt.apply(this, arguments);
    },
    /**
     * True if expected number of items have been selected.
     * Inverse of `invalid`
     * @property valid
     * @type {Boolean}
     */
    valid: Ember.computed('selectedItems.length', function () {
      var length = this.get('selectedItems.length');
      return length >= this.min && length <= this.max;
    }),
    /**
     * True if the expected number of items have not been
     * selected. Inverse of `valid`
     * @property invalid
     * @type {Boolean}
     */
    invalid: Ember.computed.not('valid'),
    selectedItem: Ember.computed('selectedItems.firstObject', function () {
      return this.get('selectedItems.firstObject');
    }),
    selected: Ember.computed('selectedItems.[]', function () {
      var selected = this.multiple ? this.get('selectedItems') : this.get('selectedItem');
      return selected;
    }),
    _selectAll: function _selectAll() {
      this.forEach(function (item) {
        return item.select();
      });
    },
    _deselectAll: function _deselectAll() {
      this.forEach(function (item) {
        return item.deselect();
      });
    },
    _toggleAll: function _toggleAll() {
      var allSelected = this.get('allSelected');
      if (allSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },
    allSelected: Ember.computed('selectedItems.length', function () {
      var totalSelected = this.get('selectedItems.length');
      return totalSelected === this.get('length');
    })
  });
});