define("sdk/api/utils/agent-availability-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = function _default() {
    var summaryData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _summaryData$business = summaryData.businessUnits,
      businessUnits = _summaryData$business === void 0 ? [] : _summaryData$business;
    var preferredQueueCount = businessUnits.reduce(function (businessUnitQueueCount) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$agentGroups = _ref.agentGroups,
        agentGroups = _ref$agentGroups === void 0 ? [] : _ref$agentGroups;
      return businessUnitQueueCount + agentGroups.reduce(function (agentGroupPreferredQueueCount) {
        var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          engagementsInPreferredAgentQueue = _ref2.engagementsInPreferredAgentQueue;
        return agentGroupPreferredQueueCount + engagementsInPreferredAgentQueue;
      }, 0);
    }, 0);
    return {
      agentSummary: summaryData,
      hasPreferredQueue: !!preferredQueueCount
    };
  };
});