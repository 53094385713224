define("sdk/utils/dom-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findElementByText = findElementByText;
  _exports.getStyleValue = getStyleValue;
  function findElementByText() {
    var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var text = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var _options$index = options.index,
      index = _options$index === void 0 ? 0 : _options$index;
    return Array.from(document.querySelectorAll(selector)).filter(function (element) {
      return element.textContent.includes(text);
    })[index];
  }
  function getStyleValue(element, styleName) {
    return window.getComputedStyle(element, null).getPropertyValue(styleName);
  }
});