define("sdk/components/collapsable-tile/content/component", ["exports", "sdk/core/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    componentName: 'collapsable-tile-content',
    classNameBindings: ['isCollapsed:hide:show'],
    attributeBindings: ['tabindex'],
    'data-test-collapsable-tile-content': '',
    onCollapsed: Ember.observer('isCollapsed', function () {
      if (this.get('isCollapsed')) {
        this.get('onHidden')();
      } else {
        this.get('onShown')();
      }
    })
  });
});