define("sdk/services/window-size", ["exports", "sdk/api/utils/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this._setDimensions();
      Ember.$(window).on('resize.window-resize', this._onWindowResize.bind(this));
    },
    _onWindowResize: function _onWindowResize() {
      (0, _runloop.debounce)(this, this._setDimensions, 100);
    },
    _setDimensions: function _setDimensions() {
      var _window = window,
        innerWidth = _window.innerWidth,
        innerHeight = _window.innerHeight;
      this.setProperties({
        width: innerWidth,
        height: innerHeight
      });
      this.trigger('resize');
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.$(window).off('.window-resize');
    }
  });
});