define("sdk/components/menu-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WFNhCnQG",
    "block": "{\"symbols\":[\"option\",\"selected\",\"&default\"],\"statements\":[[4,\"if\",[[22,[\"ariaLabelledBy\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[11,\"id\",[26,\"concat\",[[22,[\"elementId\"]],\"-a11y\"],null],null],[10,\"class\",\"a11y-hidden\"],[8],[0,\"\\n    \"],[1,[26,\"or\",[[22,[\"selected\",\"label\"]],[22,[\"selected\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"menu-select/trigger\",null,[[\"aria-controls\",\"aria-labelledby\",\"selected\",\"selectedItemComponent\",\"click\",\"hasMenuArrow\",\"isExpanded\",\"title\"],[[26,\"concat\",[[22,[\"elementId\"]],\"-options\"],null],[26,\"if\",[[22,[\"ariaLabelledBy\"]],[26,\"concat\",[[22,[\"ariaLabelledBy\"]],\" \",[22,[\"elementId\"]],\"-a11y\"],null]],null],[22,[\"selected\"]],[22,[\"selectedItemComponent\"]],[26,\"if\",[[26,\"not\",[[22,[\"disabled\"]]],null],[26,\"action\",[[21,0,[]],[22,[\"toggleProperty\"]],\"isExpanded\",true],null]],null],[22,[\"hasMenuArrow\"]],[22,[\"isExpanded\"]],[22,[\"selected\",\"label\"]]]],{\"statements\":[[0,\"  \"],[13,3,[[21,2,[]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"isExpanded\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"click-outside\",null,[[\"action\",\"tagName\"],[[26,\"action\",[[21,0,[]],[22,[\"set\"]],\"isExpanded\",false],null],\"\"]],{\"statements\":[[0,\"\\n\"],[4,\"menu-select/options\",null,[[\"id\",\"class\",\"selected\",\"options\",\"onOptionSelected\",\"ariaLabelPrefix\"],[[26,\"concat\",[[22,[\"elementId\"]],\"-options\"],null],[22,[\"dropdownClass\"]],[22,[\"selected\"]],[22,[\"options\"]],[26,\"action\",[[21,0,[]],\"onOptionSelect\"],null],[22,[\"menuItemAriaLabelPrefix\"]]]],{\"statements\":[[0,\"      \"],[13,3,[[21,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/menu-select/template.hbs"
    }
  });
});