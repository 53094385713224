define("sdk/components/engagement-tabs/tab/component", ["exports", "sdk/components/engagement-tabs/tab/template", "sdk/core/component", "moment", "sdk/utils/speak"], function (_exports, _template, _component, _moment, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'engagement-tab',
    routing: Ember.inject.service('-routing'),
    /**
     * Moment object that is the end time that the countdown timer will end on 0
     * @property {String} countdownEndTime
     */
    countdownEndTime: Ember.computed.alias('engagement.countdownEndTime'),
    /**
     * Display countdown timer instead of engagement duration time
     * @property {Boolean} isCountdown
     */
    isCountdown: Ember.computed.and('countdownEndTime'),
    timeStarted: Ember.computed.alias('engagement.timeStarted'),
    timeLastResponded: Ember.computed.alias('engagement.timeLastResponded'),
    unrespondedMessageTimer: Ember.computed.alias('engagement.unrespondedMessageTimer'),
    enableDisplayCustomerNameInChatTab: Ember.computed.reads('api.settings.enableDisplayCustomerNameInChatTab'),
    countdownTime: Ember.observer('engagement.countdownTime', function () {
      var countdownTimer = this.get('engagement.countdownTime');
      if (countdownTimer % 15 === 0) {
        (0, _speak.default)("".concat(countdownTimer, " seconds remaining"));
      }
    }),
    customerName: Ember.computed('engagement.customerName', function () {
      var customerName = this.get('engagement.customerName');
      var customerID = this.get('engagement.customer.id');
      if (!customerName) {
        return customerID ? customerID.substr(customerID.length - 6) : '';
      }
      return customerName;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var engagement = this.get('engagement');
      this.elementId = 'engagement-tab-' + engagement.get('id');
      this._unrespondedMessageIndicatorCache = this.unrespondedMessageIndicator;
      var currentTime = this.get('api.currentTime');
      var _engagement$getProper = engagement.getProperties(['timeLastResponded', 'timeStarted']),
        timeLastResponded = _engagement$getProper.timeLastResponded,
        timeStarted = _engagement$getProper.timeStarted;
      this.set('timeLastResponded', timeLastResponded || currentTime);
      if (!this.get('engagement.manuallyLoaded')) {
        this.set('timeStarted', timeStarted || currentTime);
        this.get('api.storage').persistEngagement(this.get('engagement'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var unrespondedMessageIndicator = this.get('unrespondedMessageIndicator');
      if (unrespondedMessageIndicator !== this._unrespondedMessageIndicatorCache) {
        this._unrespondedMessageIndicatorCache = unrespondedMessageIndicator;
        var chatType = this.get('engagement.chatType');
        var chatIndex = this.get('chatIndex');
        var indicatorMessage = "".concat(chatType, " chat. ").concat(_moment.default.localeData().ordinal(chatIndex), " tab. Initial response time");
        if (unrespondedMessageIndicator === 'yellow') {
          (0, _speak.default)("".concat(indicatorMessage, " approaching."));
        } else if (unrespondedMessageIndicator === 'red') {
          (0, _speak.default)("".concat(indicatorMessage, " exceeded."));
        }
      }
    },
    classNameBindings: ['currentlyActive', 'needsAttention', 'flashing', 'isCountdown:countdown'],
    numberUnrespondedMessages: Ember.computed.alias('engagement.numberUnrespondedMessages'),
    unrespondedMessagesCount: Ember.computed('numberUnrespondedMessages', function () {
      var count = this.get('numberUnrespondedMessages');
      if (!count) return null;
      return count > 99 ? '99+' : '' + count;
    }),
    speakUnrespondedMessages: Ember.observer('numberUnrespondedMessages', function () {
      var unrespondedMessages = this.get('numberUnrespondedMessages');
      var deviceType = this.get('deviceType');
      var unrespondedMessageTimer = this.get('unrespondedMessageTimer');
      var chatIndex = this.get('chatIndex');
      if (unrespondedMessages === 1) {
        unrespondedMessageTimer = 0;
      }
      if (!this.get('currentlyActive')) {
        (0, _speak.default)(this.get('intl').t('engagement-tab.chat-has-new-messages', {
          deviceType: deviceType,
          chatIndex: chatIndex,
          unrespondedMessageTimer: unrespondedMessageTimer,
          unrespondedMessages: unrespondedMessages
        }));
      } else {
        (0, _speak.default)(this.get('intl').t('engagement-tab.current-chat-has-new-messages', {
          unrespondedMessages: unrespondedMessages
        }));
      }
    }),
    engagementActive: Ember.computed.alias('engagement.connected'),
    // todo: remove reference to currentURL
    currentlyActive: Ember.computed('routing.router.currentURL', function () {
      var currentURL = this.get('routing.router.currentURL');
      return currentURL ? currentURL.endsWith(this.get('engagement.id')) : false;
    }),
    /**
     * Whether or not the engagement needs attention from the agent.
     * @property {Boolean} needsAttention
     * @public
     */
    needsAttention: Ember.computed('numberUnrespondedMessages', 'engagement.{wasTransferred,agentResponded}', function () {
      var numberUnrespondedMessages = this.get('numberUnrespondedMessages');
      if (numberUnrespondedMessages > 0) return true;
      var wasTransferred = this.get('engagement.wasTransferred');
      var isConferenceMode = this.get('engagement.isConferenceMode');
      var agentResponded = this.get('engagement.agentResponded');
      return (isConferenceMode || wasTransferred) && !agentResponded;
    }),
    lastSenderStatus: Ember.computed('lastSender', function () {
      return this.get('lastSender') === 'agent' ? 'green' : 'red';
    }),
    lastSender: Ember.computed.alias('engagement.lastMessage.sender'),
    deviceType: Ember.computed.alias('engagement.customer.deviceType'),
    engagementLengthStatus: Ember.computed('api.currentTime', function () {
      var timeStarted = this.get('timeStarted');
      var engagementLength = (0, _moment.default)(this.get('api.currentTime')).diff(timeStarted);
      var secondsElapsed = _moment.default.duration(engagementLength).asSeconds();
      var durationMidThreshold = this.get('engagement.settings.durationMidThresholdSec') || 60;
      var durationMaxThreshold = this.get('engagement.settings.durationMaxThresholdSec') || 120;
      if (secondsElapsed < durationMidThreshold) return null;
      if (secondsElapsed >= durationMidThreshold && secondsElapsed < durationMaxThreshold) return 'yellow';
      return 'red';
    }),
    flashing: Ember.computed('api.currentTime', function () {
      var flashEnabled = this.get('api.settings.visualIndicatorFlashing') !== 'NONE' && this.get('api.agent.settings.showAlerts');
      var needsAttention = this.get('needsAttention');
      var chatActive = this.get('engagementActive');
      if (!flashEnabled || !needsAttention || !chatActive) return;
      var flashNewEngagement = this.get('api.settings.visualIndicatorFlashing') === 'NEW';
      if (flashNewEngagement && this.get('engagement.agentResponded')) return;
      var seconds = this.get('api.currentTime').seconds();
      var isEven = seconds % 2 == 0;
      return isEven;
    }),
    engagementTimer: Ember.computed('api.currentTime', function () {
      if (!this.get('timeStarted')) return '00:00:00';
      var timeStarted = this.get('timeStarted');
      var timeStartedDiff = (0, _moment.default)(this.get('api.currentTime')).diff(timeStarted);
      var timeStartedDuration = _moment.default.duration(timeStartedDiff);
      return _moment.default.utc(timeStartedDuration.as('milliseconds')).format('HH:mm:ss');
    })
  });
});