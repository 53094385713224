define("sdk/api/models/filtered-transcript-line", ["exports", "sdk/api/utils/parse-html"], function (_exports, _parseHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    hasSearchMatch: Ember.computed('searchedMessageAttributes', 'searchedMessageHTML', 'searchedPrefix', function () {
      return this.searchedMessageHTML && this.searchedMessageHTML !== this.get('messageHTML') || this.searchedPrefix && this.searchedPrefix !== this.prefix || this.searchedMessageAttributes && this.searchedMessageAttributes !== this.get('messageAttributesText');
    }),
    searchAndReplace: function searchAndReplace() {
      var searchText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var replaceRegex = arguments.length > 1 ? arguments[1] : undefined;
      if (searchText === '') {
        this.setProperties({
          searchedMessageAttributes: '',
          searchedMessageHTML: '',
          searchedPrefix: '',
          searchMatchCount: 0
        });
        return false;
      }
      var searchOptions = {
        includeDetails: true,
        encoded: true
      };
      var _searchAndReplaceNonH = (0, _parseHtml.searchAndReplaceNonHtml)(this.get('messageHTML'), searchText, replaceRegex, searchOptions),
        replacedMessageHTML = _searchAndReplaceNonH.replacedText,
        replaceCountMessageHTML = _searchAndReplaceNonH.replaceCount;
      var _searchAndReplaceNonH2 = (0, _parseHtml.searchAndReplaceNonHtml)(this.get('prefix'), searchText, replaceRegex, searchOptions),
        replacedPrefix = _searchAndReplaceNonH2.replacedText,
        replaceCountPrefix = _searchAndReplaceNonH2.replaceCount;
      var _searchAndReplaceNonH3 = (0, _parseHtml.searchAndReplaceNonHtml)(this.get('messageAttributesText'), searchText, replaceRegex, searchOptions),
        replacedMessageAttributes = _searchAndReplaceNonH3.replacedText,
        replaceCountMessageAttributes = _searchAndReplaceNonH3.replaceCount;
      this.setProperties({
        searchedMessageAttributes: replacedMessageAttributes,
        searchedMessageHTML: replacedMessageHTML,
        searchedPrefix: replacedPrefix,
        searchMatchCount: replaceCountMessageHTML + replaceCountPrefix + replaceCountMessageAttributes
      });
      return this.get('hasSearchMatch');
    }
  });
});