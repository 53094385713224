define("sdk/utils/grammar/conjunctions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Coordinating Conjunctions */
  var coordinatingConjunctions = ['and', 'but', 'for', 'nor', 'or', 'so', 'yet'];

  /* Correlative Conjunctions */
  var correlativeConjunctions = ['both', 'either', 'neither', 'not only', 'whether'];

  /* Subordinating Conjunctions */
  var subordinatingConjunctions = ['after', 'although', 'as if', 'as long as', 'as much as', 'as soon as', 'as though', 'because', 'before', 'by the time', 'even if', 'even though', 'in order that', 'in case', 'in the event that', 'lest', 'now that', 'once', 'only if', 'only', 'provided that', 'since', 'supposing', 'that', 'than', 'though', 'till', 'unless', 'until', 'whenever', 'where', 'whereas', 'wherever', 'whether or not', 'while', 'as', 'if', 'so', 'when'];
  var _default = _exports.default = subordinatingConjunctions.concat(correlativeConjunctions, coordinatingConjunctions);
});