define("sdk/templates/modules/-active-transcript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bEXQaquX",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"active-transcript-window\",null,[[\"data-test-active-transcript-window\",\"engagement\",\"height\",\"resizable\",\"resizeHandles\",\"resizableDisableFlex\",\"handleKeyDown\",\"resetHotkey\",\"handleTabKey\",\"onFollowUp\",\"onResolve\",\"onClose\"],[\"\",[22,[\"engagement\"]],[22,[\"engagement\",\"ui\",\"layout\",\"activeTranscriptWindowHeight\"]],[26,\"not\",[[26,\"or\",[[22,[\"isNinaCoach\"]],[22,[\"isChatRightLayout\"]],[22,[\"engagement\",\"ui\",\"layout\",\"staticTranscriptWindowCollapsed\"]],[22,[\"engagement\",\"uiData\",\"isStaticTranscriptPanelHidden\"]]],null]],null],[26,\"if\",[[26,\"eq\",[[22,[\"layout\"]],\"chat-right\"],null],\"n\",\"s\"],null],[22,[\"tileStateIsExpanded\",\"static-transcript\"]],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"handleKeyDown\"],null],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"resetHotkey\"],null],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"handleTabKey\"],null],[26,\"action\",[[21,0,[]],\"showFollowUpModalOrPlaceBackIntoFollowUp\"],null],[26,\"action\",[[21,0,[]],\"resolve\"],null],[26,\"action\",[[21,0,[]],\"close\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modules/-active-transcript.hbs"
    }
  });
});