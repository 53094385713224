define("sdk/helpers/engagement-aria-label", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.engagementAriaLabel = engagementAriaLabel;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Returns aria-label for engagement tab
   * @method engagementAriaLabel
   * @param {Object} engagement
   * @param {Number} chatIndex
   * @param {Object} currentTime used for regular label update, especially when seconds since last response needed
   * @return {String}
   * */
  function engagementAriaLabel(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
      engagement = _ref2[0],
      chatIndex = _ref2[1],
      currentTime = _ref2[2];
    // eslint-disable-line no-unused-vars
    var _engagement$getProper = engagement.getProperties(['chatType', 'customerName', 'agentSentFirstMessage', 'unrespondedMessageTimer']),
      chatType = _engagement$getProper.chatType,
      customerName = _engagement$getProper.customerName,
      agentSentFirstMessage = _engagement$getProper.agentSentFirstMessage,
      unrespondedMessageTimer = _engagement$getProper.unrespondedMessageTimer;
    var enableInitialResponseTimeAlert = engagement.get('settings.enableInitialResponseTimeAlert');
    var ordinalChatIndex = _moment.default.localeData().ordinal(chatIndex);
    var ariaLabel = "".concat(chatType, " chat");
    if (customerName) {
      ariaLabel = "".concat(ariaLabel, " with ").concat(customerName);
    }
    if (agentSentFirstMessage) {
      ariaLabel = "".concat(ariaLabel, ". ").concat(unrespondedMessageTimer, " seconds since last response");
    } else if (enableInitialResponseTimeAlert) {
      var initialResponseTime = engagement.get('settings.initialResponseTime');
      var action = unrespondedMessageTimer < initialResponseTime ? 'Approaching' : 'Exceeded';
      ariaLabel = "".concat(ariaLabel, ". ").concat(action, " initial response time. ").concat(initialResponseTime, " seconds total");
    }
    ariaLabel = "".concat(ariaLabel, ". ").concat(ordinalChatIndex);
    return ariaLabel;
  }
  var _default = _exports.default = Ember.Helper.helper(engagementAriaLabel);
});