define("sdk/components/notification-modal/component", ["exports", "sdk/core/component", "sdk/components/notification-modal/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'notification-modal',
    classNameBindings: ['lockdown:restricted:unrestricted', 'isMinimized:minimized:maximized', 'isVisible::hidden'],
    isVisible: Ember.computed.and('notification'),
    type: Ember.computed.alias('notification.type'),
    icon: Ember.computed.alias('notification.icon'),
    lockdown: Ember.computed.equal('type', 'lockdown'),
    isMinimized: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('isMinimized', false);
    },
    actions: {
      minimize: function minimize() {
        this.set('isMinimized', true);
      },
      restore: function restore() {
        this.set('isMinimized', false);
      }
    }
  });
});