define("sdk/components/text-field-multiline/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.TextArea.extend({
    attributeBindings: ['aria-describedby', 'aria-labelledby', 'aria-label', 'aria-required', 'textDirection:dir'],
    textDirection: 'auto'
  });
});