define("sdk/components/custom-scripts/script-line/component", ["exports", "sdk/core/component", "sdk/components/custom-scripts/script-line/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Displays a script line with a delete button.
   *
   * @class Components.CustomScripts.ScriptLine
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'custom-script-line',
    classNameBindings: ['willDelete', 'needHighlight:marked-as-invalid', 'scriptTypeClass'],
    'data-test-custom-script': '',
    willDelete: Ember.computed.alias('script.delete'),
    needHighlight: Ember.computed.alias('script.needHighlight'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('isHeader', this.script.type === 'header');
      this.set('script.delete', false);
      this.set('scriptTypeClass', "custom-script--".concat(this.script.type));
    },
    didInsertElement: function didInsertElement() {
      if (this.get('script.editMode')) {
        this.$('textarea').focus();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('focusTextareaTimeout'));
    },
    actions: {
      /**
       * Calls the onDelete action to remove this from
       * the custom scripts.
       * @method delete
       */
      toggleDeleteState: function toggleDeleteState() {
        var _this = this;
        this.toggleProperty('script.delete');
        this.get('setDirty')();
        Ember.run.scheduleOnce('afterRender', function () {
          _this.$('.action-button').focus();
        });
      },
      toggleEditMode: function toggleEditMode() {
        var _this2 = this;
        this.toggleProperty('script.editMode');
        this.get('setDirty')();
        this.set('focusTextareaTimeout', setTimeout(function () {
          _this2.$('textarea').focus();
        }, 50));
      },
      cancelDelete: function cancelDelete() {
        this.set('script.delete', false);
        this.get('setDirty')();
      }
    }
  });
});