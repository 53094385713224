define("sdk/resolver", ["exports", "ember-resolver", "sdk/components/react-wrapper/component"], function (_exports, _emberResolver, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberResolver.default.extend({
    // `resolveComponent` is triggered when rendering a component in template.
    // For example, having `{{foo-bar}}` in a template will trigger `resolveComponent`
    // with the name full name of `component:foo-bar`.
    resolveComponent: function resolveComponent(_ref) {
      var fullNameWithoutType = _ref.fullNameWithoutType;
      if (!fullNameWithoutType.startsWith('react-')) {
        return;
      }
      var componentName = Ember.String.classify(fullNameWithoutType.replace(/react-/g, ''));
      var component = ComponentLibrary.default[componentName];
      if (!component) {
        return;
      }
      return _component.default.extend({
        reactComponent: componentName
      });
    }
  });
});