define("sdk/utils/get-first-focusable-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(element) {
    var focusableSelector = 'input,textarea,select,button:not([disabled]):not([tabindex="-1"]),[tabindex],[contenteditable="true"]';
    return element && (element.matches(focusableSelector) ? element : element.querySelector(focusableSelector));
  }
});