define("sdk/components/react-wrapper/component", ["exports", "sdk/core/component", "sdk/components/react-wrapper/get-mutable-attrs", "sdk/config/environment"], function (_exports, _component, _getMutableAttrs, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line no-unused-vars
  var _ComponentLibrary$def = ComponentLibrary.default,
    React = _ComponentLibrary$def.React,
    ReactDOM = _ComponentLibrary$def.ReactDOM,
    ReduxWrapper = _ComponentLibrary$def.ReduxWrapper,
    ErrorBoundary = _ComponentLibrary$def.ErrorBoundary,
    ThemeProvider = _ComponentLibrary$def.ThemeProvider;

  /**
   * Ember component renders a React component. If providers are specified,
   * wrap the provider around the React component. Attribute updates passed into
   * this component will automatically call ReactDOM.render, which passes in
   * new props to the React tree.
   */
  var _default = _exports.default = _component.default.extend({
    intl: Ember.inject.service(),
    reactIntl: Ember.inject.service(),
    attributeBindings: ['style', 'role'],
    classNameBindings: ['componentClassName'],
    //this will remove the semantic meaning from this wrapper element
    role: 'presentation',
    style: Ember.computed(function () {
      return Ember.String.htmlSafe("display:".concat(this.get("display") ? this.get("display") : "inline"));
    }),
    componentClassName: Ember.computed(function () {
      return "react-".concat(Ember.String.dasherize(this.reactComponent));
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('intl').on('localeChanged', this, this._rerenderComponent);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.renderComponent();
    },
    renderComponent: function renderComponent() {
      var _this = this;
      var reactComponent = ComponentLibrary.default[this.get('reactComponent')];
      var attributes = (0, _getMutableAttrs.default)(Ember.get(this, 'attrs'));
      var IntlWrapper = this.get('reactIntl').get('Wrapper');
      if (!reactComponent) {
        throw new Error("react component \"".concat(reactComponent, "\" was not found."));
      }
      var reactElement = React.createElement(reactComponent, attributes);
      reactElement = React.createElement(ComponentLibrary.default['ReduxWrapper'], {
        children: reactElement
      });
      reactElement = React.createElement(ComponentLibrary.default['ErrorBoundary'], {
        onError: function onError(error) {
          var eventData = {
            event: 'UI-Error',
            event_group: 'react-error',
            stack: error.stack
          };
          _this.api.get('logging').sendErrorEvent(eventData);
        },
        children: reactElement
      });
      if (this.get('requireEnvConfig')) {
        reactElement = React.createElement(ComponentLibrary.default['EnvConfigProvider'], {
          value: _environment.default,
          children: reactElement
        });
      }
      reactElement = React.createElement(IntlWrapper, {
        children: reactElement
      });
      reactElement = React.createElement(ThemeProvider, {
        children: reactElement
      });
      ReactDOM.render(reactElement, Ember.get(this, 'element'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      ReactDOM.unmountComponentAtNode(Ember.get(this, 'element'));
      this.get('intl').off('localeChanged', this, this._rerenderComponent);
    },
    _rerenderComponent: function _rerenderComponent() {
      ReactDOM.unmountComponentAtNode(Ember.get(this, 'element'));
      this.renderComponent();
    }
  });
});