define("sdk/components/transcript-agent-input/component", ["exports", "sdk/core/resizable-component", "sdk/components/transcript-agent-input/template", "sdk/api/utils/word-count-delay", "sdk/api/utils/parse-html"], function (_exports, _resizableComponent, _template, _wordCountDelay, _parseHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _exports.default = _resizableComponent.default.extend({
    layout: _template.default,
    componentName: 'transcript-agent-input',
    isTyping: false,
    stopTypingDelayTime: 2.4,
    resizeHandles: 'n',
    resizeMinHeight: 65,
    containment: null,
    // required for resizing from the top

    quillInstanceManager: Ember.inject.service(),
    isPublicTwitterEngagement: Ember.computed.alias('engagement.isPublicTwitter'),
    maxLength: Ember.computed('isPublicTwitterEngagement', function () {
      if (this.get('isPublicTwitterEngagement')) return 140;
    }),
    /**
     * Public (global tweet) or Private (direct message)
     * @property {String} twitterScope
     */
    twitterScope: Ember.computed.alias('engagement.twitter.scope'),
    /**
     * Allows agent to send a private twitter message without a confirmation modal
     * @property {Boolean} allowPrivateTwitterMessage
     */
    allowPrivateTwitterMessage: false,
    /**
     * Displays a Twitter confirmation modal when sending a private message
     * @property {Boolean} showTwitterPrivateReplyConfirmationModal
     */
    showTwitterPrivateReplyConfirmationModal: false,
    /**
     * Whether or not rich text formatting is enabled for this engagement.
     * @property {Boolean} hasRichText
     */
    hasRichText: Ember.computed('engagement.settings.textFormatting', 'isInternal', function () {
      return this.get('engagement.settings.textFormatting') && !this.get('isInternal');
    }),
    onResizeStart: function onResizeStart(event, $element) {
      //set maxHeight to 30% of parent's height
      $element.resizable('option', 'maxHeight', this.getMaxHeight($element));
    },
    onResize: function onResize() {
      // Always scroll to bottom of transcript window
      var $transcriptWindow = Ember.$('.nuance-transcript-window');
      $transcriptWindow.scrollTop($transcriptWindow.prop('scrollHeight'));
    },
    getMaxHeight: function getMaxHeight($element) {
      return $element.parent().height() * 0.3;
    },
    agentInput: Ember.computed('agentInput', 'engagement.ui.agentInput', {
      get: function get() {
        var agentInput = this.get('isInternal') ? this.get('engagement.ui.agentInternalInput') : this.get('engagement.ui.agentInput');
        agentInput = agentInput && !agentInput.startsWith('<p>') ? "<p>".concat(agentInput, "</p>") : agentInput;
        return agentInput;
      },
      set: function set(key, value) {
        if (!this.get('isInternal')) {
          this.set('engagement.ui.agentInput', value);
        } else {
          this.set('engagement.ui.agentInternalInput', value);
        }
        return value;
      }
    }),
    agentInputLength: Ember.computed('agentInput', function () {
      if (this.get('agentInput') === '<p><br></p>') return 0;
      return this.get('agentInput') ? (0, _parseHtml.formatTextToCountLength)(this.get('agentInput')).length : 0;
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('stoppedTypingTimer'));
    },
    runningTests: Ember.computed(function () {
      return window.runningTests;
    }),
    isSendDisabled: Ember.computed.or('engagement.isLocked', 'engagement.uiData.isSendingMessage'),
    actions: {
      sendMessage: function sendMessage() {
        var _this = this;
        if (this.get('isSendDisabled')) return;
        var displayTwitterPrivateConfirmationModal = this.get('twitterScope') === 'private' && !this.get('allowPrivateTwitterMessage');
        this.set('allowPrivateTwitterMessage', false);
        if (displayTwitterPrivateConfirmationModal) {
          this.set('showTwitterPrivateReplyConfirmationModal', true);
          return;
        }
        var engagement = this.get('engagement');
        var isInternal = this.get('isInternal');
        var messageText = this.get('agentInput');
        var originalMessageText = messageText;
        var messageData = this.richWidget && this.richWidget.richContent && {
          richWidget: this.richWidget.richContent
        };
        var isAgentInputEmpty = this.get('agentInputLength') === 0 || !messageText || this.get('quillInstanceManager').hasEmptyText(isInternal);
        if (isAgentInputEmpty && !messageData) return;

        // TODO remove runningTests reference
        if (!window.runningTests) {
          // used to handle sibling and nested elements
          // in custom scripts
          var htmlToSend = Ember.$(messageText).length > 1 ? Ember.$('<p>').html(messageText).html() : Ember.$(messageText).html();
          var isAppleBusinessChatData = messageText && messageText.includes('interactiveData');
          messageText = isAppleBusinessChatData ? Ember.$(messageText).text() : htmlToSend;
          if (!messageText && !messageData) return;
        }
        if (messageText) {
          // decode entities when sending message
          messageText = (0, _parseHtml.decodeHtmlEntities)(messageText);

          // strip out escaped spaces to prevent them from being rendered in ABC chats
          messageText = messageText.replace(/&nbsp;/ig, ' ').trim();

          // remove extra whitespace
          messageText = messageText.replace(/\s{2,}/g, ' ');
          // can't send a message with only spaces so perform a quick check before sending
          if (!messageText && !messageData) return;
        }
        this.set('isTyping', false);
        clearTimeout(this.get('stoppedTypingTimer'));
        var messageScriptType = engagement.get('ui.agentInputScript.scriptType');
        var originalScriptText = engagement.get('ui.agentInputScript.scriptText');
        var editedManual = originalScriptText && originalScriptText !== (0, _parseHtml.replacePAndBrTagsWithLineBreaks)(messageText);
        var editedScript = !!messageScriptType;
        var customScript = messageScriptType === 'custom';
        var options = {
          editedManual: editedManual,
          editedScript: editedScript,
          customScript: customScript,
          source: 'agent-input' // agent-typed messages are encoded when displayed in transcript window
        };

        if (this.get('isInternal')) {
          options.chatLineReceiverType = 'internal';
        } else if (engagement.get('hasMessageQueue')) {
          // add time delay if there is a message queue (has time-delayed scripts)
          options.timeDelay = (0, _wordCountDelay.default)(messageText);
        }
        if (messageData) {
          options.messageData = messageData;
        }
        if (engagement.get('agentCoach')) {
          options.recommendations = _toConsumableArray(engagement.get('ui.agentInputRecommendations'));
        }
        return Ember.RSVP.resolve().then(function () {
          engagement.setUIData({
            isSendingMessage: true
          });
          return engagement.send(messageText, options);
        }).then(function () {
          if (engagement.get('isDestroying')) return;
          if (!_this.get('isInternal')) engagement.set('ui.agentInputScript', {});
          _this._clearAllInput();
        }).catch(function (error) {
          _this._clearAllInput();
          _this.set('agentInput', originalMessageText);
          throw error;
        }).finally(function () {
          engagement.setUIData({
            isSendingMessage: false
          });
        });
      },
      onInputFocusOut: function onInputFocusOut() {
        this.set('isTyping', false);
        this._sendAgentStoppedTyping();
      },
      clearAllInput: function clearAllInput() {
        this._clearAllInput();
        this.get('quillInstanceManager').focus();
      },
      /**
       * Allows the agent to send a private Twitter message
       * after selecting "Okay" in the confirmation modal
       * @method sendPrivateTwitterMessage
       */
      sendPrivateTwitterMessage: function sendPrivateTwitterMessage() {
        this.set('showTwitterPrivateReplyConfirmationModal', false);
        this.set('allowPrivateTwitterMessage', true);
        this.send('sendMessage');
      },
      onQuillKeyDown: function onQuillKeyDown(event) {
        var ctrlKey = event.ctrlKey,
          shiftKey = event.shiftKey,
          key = event.key,
          keyCode = event.keyCode;
        var isShiftOrTabKey = key === 'Shift' || key === 'Tab';
        var isCtrlEnterToSubmit = this.get('api.agent.settings.ctrlEnterToSubmit');
        if (event.target.textContent === '') {
          this.set('engagement.ui.agentInputScript', {});
          if (this.get('engagement.agentCoach')) {
            this.get('engagement.ui.agentInputRecommendations').clear();
          }
        }
        if (!this.get('isInternal') && !isShiftOrTabKey) this._startAgentTypingTimers();
        if (ctrlKey && (key === 'b' || key === 'i' || key === 'u')) {
          this.get('onKeyPressRichText')();
        } else if (key === 'Tab' && !shiftKey) {
          this.$('.send-message-button').focus();
        } else if (key === 'Enter' && !shiftKey && (isCtrlEnterToSubmit ? ctrlKey : true)) {
          this.send('sendMessage');
        } else if (keyCode === 8 && this.get('agentInputLength') === 0) {
          // Backspace
          this._clearAllInput();
        }
      },
      onQuillTextChange: function onQuillTextChange() {
        var _this$get;
        (_this$get = this.get('quillInstanceManager')).trigger.apply(_this$get, ['textChange'].concat(Array.prototype.slice.call(arguments)));
      },
      previewRichWidget: function previewRichWidget(richWidget) {
        Ember.set(richWidget, 'ui', {
          hasActionFooter: false
        });
        this.set('engagement.ui.previewRichWidget', richWidget);
      }
    },
    /**
     * The keyPress event is triggered on specific keys only. Excluded keys
     * include ctrl and backspace. We don't reset hotkeys on all keydown events.
     * @method keyPress
     */
    keyPress: function keyPress() {
      if (!this.get('isInternal')) {
        this.set('hotkey', []);
        this.get('resetHotkey')();
      }
    },
    _clearAllInput: function _clearAllInput() {
      // clear the input (newline required for IE11 to reset Quill properly)
      this.set('agentInput', '\n');
      this.set('richWidget', undefined);
    },
    /**
     * When an agent starts typing a message, send "Agent is typing..." message
     * when agent stops typing, send "Agent stopped typing" message
     * @method _startAgentTypingTimers
     */
    _startAgentTypingTimers: function _startAgentTypingTimers() {
      var _this2 = this;
      var engagement = this.get('engagement');
      engagement.trigger('agentInteracted');
      if (!this.get('isTyping')) {
        engagement.sendAgentIsTyping();
        engagement.trigger('agentStartTyping');
      }
      this.set('isTyping', true);
      clearTimeout(this.get('stoppedTypingTimer'));
      this.set('stoppedTypingTimer', setTimeout(function () {
        if (_this2.get('isTyping')) {
          _this2.set('isTyping', false);
          _this2._sendAgentStoppedTyping();
        }
      }, this.get('stopTypingDelayTime') * 1000));
    },
    /**
     * Pass any possible hotkey combo events
     * to the route.
     * TODO: refactor - this event should
     * bubble up to the route automatically
     * @method keyDown
     * @param {Object} event
     * @public
     */
    keyDown: function keyDown(event) {
      var handleKeyDown = this.get('handleKeyDown');
      handleKeyDown && handleKeyDown(event);
    },
    _sendAgentStoppedTyping: function _sendAgentStoppedTyping() {
      return this.get('engagement').sendAgentStoppedTyping();
    }
  });
});