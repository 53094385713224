define("sdk/utils/dynamic-operations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Performs a dynamic operation on the provided operand(s).
   *
   * Supported operations: [+, -, ++, --].
   */
  var operate = {
    '+': function _(_ref) {
      var operand1 = _ref.operand1,
        operand2 = _ref.operand2;
      return operand1 + operand2;
    },
    '-': function _(_ref2) {
      var operand1 = _ref2.operand1,
        operand2 = _ref2.operand2;
      return operand1 - operand2;
    },
    '++': function _(_ref3) {
      var operand1 = _ref3.operand1;
      return operand1 + 1;
    },
    '--': function _(_ref4) {
      var operand1 = _ref4.operand1;
      return operand1 - 1;
    }
  };
  var _default = _exports.default = operate;
});