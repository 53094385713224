define("sdk/api/utils/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getJSON = getJSON;
  _exports.getKeyValues = getKeyValues;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function getJSON(text) {
    if (!text) return;
    try {
      var parsedText = JSON.parse(text);
      return _typeof(parsedText) === 'object' ? parsedText : null;
    } catch (error) {
      return;
    }
  }
  function getKeyValues() {
    var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return Object.keys(object).map(function (key) {
      return {
        key: [key],
        value: object[key]
      };
    });
  }
});