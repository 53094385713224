define("sdk/api/mixins/engagement/end-chat-automaton", ["exports", "ember-ajax/request", "sdk/api/utils/format-dispositions", "sdk/api/models/automaton"], function (_exports, _request, _formatDispositions, _automaton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * Sends a end automaton request if the engagement is ending and has setting "endChatAutomatonUrl"
     * @method handleEndAutomatonRequest
     */
    handleEndAutomatonRequest: function handleEndAutomatonRequest() {
      var endChatAutomatonUrl = this.get('settings.endChatAutomatonUrl');
      if (endChatAutomatonUrl && this.get('ownedByCurrentAgent')) {
        this._sendEndAutomatonRequest(endChatAutomatonUrl);
      }
    },
    /**
     * Sends POST request to end automaton url with data related to ending the engagement
     * @method _sendEndAutomatonRequest
     */
    _sendEndAutomatonRequest: function _sendEndAutomatonRequest(automatonUrl) {
      var dispositions = (0, _formatDispositions.default)(this) || {};
      var _dispositions$categor = dispositions.categoryReasons,
        categoryReasons = _dispositions$categor === void 0 ? [] : _dispositions$categor,
        message = dispositions.message;
      var categoryIds = [];
      var categoryLabels = [];
      var reasonIds = [];
      var reasonLabels = [];
      categoryReasons.forEach(function (_ref) {
        var category = _ref.category,
          reasons = _ref.reasons;
        categoryIds.push(category.id);
        categoryLabels.push(category.name);
        reasons.forEach(function (_ref2) {
          var id = _ref2.id,
            name = _ref2.name;
          reasonIds.push(id);
          reasonLabels.push(name);
        });
      });
      var postData = {
        customerExitTime: this.get('customerExitTimestamp') ? this.get('customerExitTimestamp') : undefined,
        chatInteractive: this.get('agentResponded') ? 'Y' : 'N',
        disposition: categoryIds.length ? categoryIds.toString() + ':' + reasonIds.toString() : '',
        notes: message ? message : '',
        categoryLabels: categoryLabels.length ? categoryLabels.toString() : '',
        reasonLabels: reasonLabels.length ? reasonLabels.toString() : ''
      };
      automatonUrl += "&customerID=".concat(this.get('settings.clientId'));
      var automaton = _automaton.default.create({
        url: automatonUrl,
        engagement: this
      });
      Object.assign(postData, automaton.get('params'));
      return (0, _request.default)(automatonUrl, {
        method: 'POST',
        crossDomain: true,
        contentType: 'application/x-www-form-urlencoded',
        dataType: 'text',
        data: postData
      }).catch(function (error) {
        // To be removed after RTDEV-27462
        console.error(error); // eslint-disable-line no-console
      });
    }
  });
});