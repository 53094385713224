define("sdk/helpers/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.array = array;
  _exports.default = void 0;
  function array(params) {
    return params;
  }
  var _default = _exports.default = Ember.Helper.helper(array);
});