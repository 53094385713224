define("sdk/services/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    /**
     * Array of query param objects with { key: value } pairs
     * Ex: www.website.com?foo=bar
     * Result: [{ key: 'foo', value: 'bar' }]
     * @property {Array} queryParams
     */
    queryParams: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('queryParams', []);
      window.location.href.replace(/[?&]([^=]+)=([^&]+)/g, function (match, key, value) {
        _this.get('queryParams').addObject({
          key: key,
          value: value
        });
      });
    },
    hasQueryParams: Ember.computed.notEmpty('queryParams'),
    hrefNoQueryParams: Ember.computed('window.location', function () {
      var _window$location = window.location,
        protocol = _window$location.protocol,
        hostname = _window$location.hostname,
        _window$location$path = _window$location.pathname,
        pathname = _window$location$path === void 0 ? '' : _window$location$path,
        port = _window$location.port;
      return protocol + '//' + hostname + (port ? ':' + port : '') + pathname;
    }),
    /**
     * Retrieve query params as an object
     * @method getQueryParams
     * @return {Object} object of query params
     * Example: { SSO: 'true', token: '123456' }
     */
    getQueryParams: function getQueryParams() {
      var queryParamObj = {};
      this.get('queryParams').forEach(function (_ref) {
        var key = _ref.key,
          value = _ref.value;
        queryParamObj[key] = value;
      });
      return queryParamObj;
    },
    /**
     * Set (replace value) or add a new query param to url
     * @method setQueryParam
     * @param {String} key query param key
     * @param {String} value query param value
     * @param {Object} options
     * @param {Boolean} options.pushState push state to window.history rather than replace
     */
    setQueryParam: function setQueryParam(key, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var pushState = options.pushState;
      var existingQueryParam = this.get('queryParams').findBy('key', key);
      if (existingQueryParam) {
        existingQueryParam.value = value;
      } else {
        this.get('queryParams').addObject({
          key: key,
          value: value
        });
      }
      this._updateUrl(pushState);
    },
    /**
     * Remove a specific query param from url
     * @method removeQueryParam
     * @param {String} key query param key
     */
    removeQueryParam: function removeQueryParam(key) {
      this.set('queryParams', this.get('queryParams').rejectBy('key', key));
      this._updateUrl();
    },
    /**
     * Removes multiple query params from url
     * @method removeQueryParams
     * @param {Array} keys query param keys
     */
    removeQueryParams: function removeQueryParams() {
      var keys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var queryParams = this.get('queryParams');
      keys.forEach(function (key) {
        queryParams = queryParams.rejectBy('key', key);
      });
      this.set('queryParams', queryParams);
      this._updateUrl();
    },
    /**
     * Remove all query params in the url
     * @method removeAllQueryParams
     */
    removeAllQueryParams: function removeAllQueryParams() {
      this.set('queryParams', []);
      this._updateUrl();
    },
    /**
     * Update the url without reloading the page
     * @method _updateUrl
     * @param {Boolean} pushState whether or not to push or replace window history
     * @private
     */
    _updateUrl: function _updateUrl() {
      var pushState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var url = this.get('hrefNoQueryParams');
      if (this.get('hasQueryParams')) {
        var queryParams = this.get('queryParams');
        var queryParamsString = queryParams.map(function (_ref2) {
          var key = _ref2.key,
            value = _ref2.value;
          return "".concat(key, "=").concat(value);
        }).join('&');
        url += '?' + queryParamsString;
      }
      if (window.runningTests) {
        console.log('url updated to: ', url); // eslint-disable-line
      } else if (pushState) {
        window.history.pushState({
          path: url
        }, '', url);
      } else {
        window.history.replaceState({
          path: url
        }, '', url);
      }
    }
  });
});