define("sdk/components/menu-power-select/menu-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eFAlOgit",
    "block": "{\"symbols\":[\"option\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[22,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[11,\"id\",[27,[[20,\"elementId\"],\"-\",[21,2,[]]]]],[10,\"class\",\"ember-power-select-option\"],[11,\"data-active\",[27,[[26,\"ember-power-select-is-selected\",[[21,1,[]],[22,[\"select\",\"selected\"]]],null]]]],[11,\"aria-disabled\",[26,\"ember-power-select-true-string-if-present\",[[21,1,[\"disabled\"]]],null],null],[11,\"aria-current\",[27,[[26,\"eq\",[[21,1,[]],[22,[\"select\",\"highlighted\"]]],null]]]],[11,\"aria-label\",[26,\"if\",[[22,[\"ariaLabelPrefix\"]],[26,\"concat\",[[22,[\"ariaLabelPrefix\"]],[21,1,[\"label\"]]],null]],null],null],[11,\"data-option-index\",[27,[[21,2,[]]]]],[11,\"data-test-option-index\",[27,[[21,2,[]]]]],[11,\"role\",[26,\"if\",[[22,[\"searchEnabled\"]],\"option\",\"menuitem\"],null],null],[8],[0,\"\\n    \"],[13,3,[[21,1,[]],[22,[\"select\"]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/menu-power-select/menu-options/template.hbs"
    }
  });
});