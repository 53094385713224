define("sdk/components/list-render/mnstr-cell-navigation-mixin", ["exports", "sdk/utils/speak"], function (_exports, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    currentFocusCellIndex: -1,
    initialCellFocusIndex: -1,
    hasFocus: false,
    getInitialCellIndex: function getInitialCellIndex() {
      var isDownDirection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var initialCellFocusIndex = this.initialCellFocusIndex;
      var itemsLastIndex = this.get('items.length') - 1;
      if (initialCellFocusIndex !== -1) {
        return initialCellFocusIndex;
      }
      return isDownDirection ? 0 : itemsLastIndex;
    },
    focusIn: function focusIn() {
      var activeElement = document.activeElement;

      // reset indexes if transcript window receives focus and it's cell had focus before
      // e.g. focus transcript -> focus cell -> press shift+tab -> focus transcript + reset indexes
      if (activeElement && activeElement.classList.contains('mnstr') && this.hasFocus) {
        this._resetIndexes();
      } else if (activeElement && activeElement.classList.contains('mnstr-item')) {
        // agent clicked on cell
        var dataset = activeElement.parentElement.parentElement.parentElement.dataset;
        var currentFocusCellIndex = Number(dataset['cellIndex']);
        this.focusCellIndex(currentFocusCellIndex);
      }
      if (!this.hasFocus) {
        this.set('hasFocus', true);
        (0, _speak.default)(this.get('intl').t('a11y.transcript-navigation-instruction'), {
          ariaLiveValue: 'assertive',
          role: 'alert'
        });
      }
      var timeoutOnBlur = this.get('timeoutOnBlur');
      timeoutOnBlur && clearTimeout(timeoutOnBlur);
    },
    focusOut: function focusOut() {
      var timeoutOnBlur = setTimeout(this._onFocusOut.bind(this));
      this.set('timeoutOnBlur', timeoutOnBlur);
    },
    keyDown: function keyDown(event) {
      this._super.apply(this, arguments);
      var activeElement = document.activeElement;
      switch (event.keyCode) {
        case 9:
          {
            // Tab
            var shiftKey = event.shiftKey;
            if (!shiftKey) {
              event.preventDefault();
              var nodes = this.getFocusableNodes('body', true);
              var nodeIndex = nodes.index(activeElement);
              for (var i = nodeIndex + 1; i < nodes.length; i++) {
                if (!nodes[i].classList.contains(this.itemClassName) && nodes[i].id !== 'node-after-list-render') {
                  nodes[i].focus();
                  break;
                }
              }
            } else if (activeElement && activeElement.classList.contains(this.itemClassName)) {
              this.element.querySelector('.mnstr').focus();
              event.preventDefault();
            }
            break;
          }
        case 32:
          {
            // Space
            if (activeElement && activeElement.classList.contains(this.itemClassName)) {
              event.preventDefault();
            }
            break;
          }
        case 35:
          {
            // End
            event.preventDefault();
            this.focusCellIndex(this.get('items.length') - 1);
            break;
          }
        case 36:
          {
            // Home
            event.preventDefault();
            this.focusCellIndex(0);
            break;
          }
        case 38:
          {
            // Up Arrow
            event.preventDefault();
            var nextFocusCellIndex = this.currentFocusCellIndex === -1 ? this.getInitialCellIndex() : this.currentFocusCellIndex - 1;
            this.focusCellIndex(nextFocusCellIndex);
            break;
          }
        case 40:
          {
            // Down Arrow
            event.preventDefault();
            var _nextFocusCellIndex = this.currentFocusCellIndex === -1 ? this.getInitialCellIndex(true) : this.currentFocusCellIndex + 1;
            this.focusCellIndex(_nextFocusCellIndex);
            break;
          }
      }
    },
    /**
     * Put focus on element
     * If we were waiting for it to render
     * @method focusUpdatedCell
     */
    focusUpdatedCell: function focusUpdatedCell() {
      var focusCell = this._getCellFocusElementByIndex(this.currentFocusCellIndex);
      this._focusCell(focusCell);
    },
    /**
     * Put focus on next element
     * If element is not rendered - scroll to it and focus later
     * @method focusCellIndex
     * @param {Number} [cellIndex] index of element to be focused
     */
    focusCellIndex: function focusCellIndex() {
      var cellIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var itemsLastIndex = this.get('items.length') - 1;
      var nextFocusCellIndex = cellIndex < 0 ? itemsLastIndex : cellIndex > itemsLastIndex ? 0 : cellIndex;
      var nextFocusCell = this._getCellFocusElementByIndex(nextFocusCellIndex);
      if (nextFocusCell) {
        this._focusCell(nextFocusCell);
      } else {
        this._mnstr.scrollToElement(this.get("items.".concat(nextFocusCellIndex)));
      }
      this.set('currentFocusCellIndex', nextFocusCellIndex);
    },
    _focusCell: function _focusCell(cellElement) {
      cellElement && cellElement.focus();
    },
    _getCellFocusElementByIndex: function _getCellFocusElementByIndex(cellIndex) {
      var cellFocusElementSelector = "[data-cell-index=\"".concat(cellIndex, "\"]").concat(this.itemClassName ? ' .' + this.itemClassName : '');
      return this.element.querySelector(cellFocusElementSelector);
    },
    _onFocusOut: function _onFocusOut() {
      this.set('hasFocus', false);
      this._resetIndexes();
    },
    _resetIndexes: function _resetIndexes() {
      if (this.get('shouldSaveLastIndex') && this.currentFocusCellIndex !== -1) {
        this.set('initialCellFocusIndex', this.currentFocusCellIndex);
      }
      this.set('currentFocusCellIndex', -1);
    }
  });
});