define("sdk/components/engagement-icon/component", ["exports", "sdk/core/component", "sdk/components/engagement-icon/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'engagement-icon',
    classNameBindings: ['deviceTypeName'],
    'data-test-device-type': Ember.computed.reads('chatType'),
    deviceTypeName: Ember.computed('chatType', function () {
      return "device-type--".concat(this.get('chatType'));
    }),
    dvvIconName: Ember.computed('dvvType', function () {
      switch (this.dvvType) {
        case 'video':
          return 'VideocamOutlinedIcon';
        case 'voice':
          return 'MicIcon';
      }
    }),
    muiIconName: Ember.computed('chatType', function () {
      switch (this.chatType) {
        case 'app':
        case 'eapi':
        case 'nina-web':
          return 'Api';
        case 'desktop':
          return 'DesktopMacOutlined';
        case 'mobile':
          return 'SmartphoneOutlined';
        case 'nina-coach':
          return 'LiveHelpOutlined';
        case 'sms':
          return 'SmsOutlined';
        case 'tablet':
          return 'TabletOutlined';
      }
    }),
    hasSVG: Ember.computed('chatType', function () {
      return ['apple', 'facebook', 'google', 'twitter', 'whatsapp', 'line', 'viber', 'instagram', 'telegram'].includes(this.get('chatType'));
    })
  }).reopenClass({
    positionalParams: ['chatType']
  });
});