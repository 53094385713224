define("sdk/api/mixins/engagement/cobrowse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * Message type for cobrowse status messages
     * @property {String} MESSAGE_TYPE_COBROWSE
     * @public
     */
    MESSAGE_TYPE_COBROWSE: 'cobrowse',
    /**
     * Send agent cobrowse end ETL event to api service.
     * @return void
     */
    sendAgentEndCobrowseEtlEvent: function sendAgentEndCobrowseEtlEvent() {
      this._sendMessage({
        messageText: 'Agent ends cobrowse',
        // message text is ignored during ETL event generation (see ETL event codes: 52 [AI message] and 521 [CI message])
        messageType: this.MESSAGE_TYPE_COBROWSE,
        cobrowseEvent: 'agentEndedCobrowse'
      });
    },
    /**
     * Validate cobrowse invitations and eligibility test.
     * @param {Object} message
     * @return void
     */
    validateCobrowseMessage: function validateCobrowseMessage(message) {
      if (this._isPerformanceEligibilityTest(message.messageText)) {
        message.messageType = this.MESSAGE_TYPE_COBROWSE;
        message.cobrowseEvent = 'agentSentCobrowseTest';
      } else if (this._isNoShareControlMode(message.messageText)) {
        message.messageType = this.MESSAGE_TYPE_COBROWSE;
        message.cobrowseEvent = 'agentSentCobrowseInvite';
        // cobrowse no share mode is already started
        if (this.get('cobrowseAccepted')) {
          this.get('api').trigger('alert', 'alerts.cobrowse-already-active');
          return false;
        }
      } else if (this._isShareControlMode(message.messageText)) {
        message.messageType = this.MESSAGE_TYPE_COBROWSE;
        message.cobrowseEvent = 'agentSentSharedControlInvite';
        // cobrowse share mode is already started
        if (this.get('cobrowseSharedAccepted')) {
          this.get('api').trigger('alert', 'alerts.cobrowse-already-active');
          return false;
        }
      }
      return true;
    },
    /**
     * Check eligibility test in the message text
     * @param {String} messageText message text
     * @private
     * @return {boolean} true, if eligibility test was found in the message text
     */
    _isPerformanceEligibilityTest: function _isPerformanceEligibilityTest(messageText) {
      // cobrowse eligibility test marker
      var STATUS_OFFER_COBROWSE_TEST = 'coboff="test"';
      return new RegExp(STATUS_OFFER_COBROWSE_TEST).test(messageText);
    },
    /**
     * Check "No Share" cobrowse invitation in the message text
     * @param {String} messageText message text
     * @private
     * @return {boolean} true, if "No Share" cobrowse invitation was found in the message text
     */
    _isNoShareControlMode: function _isNoShareControlMode(messageText) {
      // no share cobrowse invitation marker
      var STATUS_OFFER_COBROWSE = 'coboff';
      var COBROWSE_ACCEPT_LINK = 'com.inq.flash.client.chatskins.CoBrowseMgr.acceptCobInv';
      var COBROWSE_DECLINE_LINK = 'com.inq.flash.client.chatskins.CoBrowseMgr.declineCobInv';
      // It's used for ATT and Verizon invitation
      var COBROWSE_ACCEPT_LINK_FLASHPEER = 'FlashPeer.ciAcceptCobInv';
      var COBROWSE_DECLINE_LINK_FLASHPEER = 'FlashPeer.ciDeclineCobInv';
      var noSharedInvitations = [STATUS_OFFER_COBROWSE, COBROWSE_ACCEPT_LINK, COBROWSE_DECLINE_LINK, COBROWSE_ACCEPT_LINK_FLASHPEER, COBROWSE_DECLINE_LINK_FLASHPEER];
      return new RegExp(noSharedInvitations.join('|')).test(messageText);
    },
    /**
     * Check "Share" cobrowse invitation in the message text
     * @param {String} messageText message text
     * @private
     * @return {boolean} true, if "Share" cobrowse invitation was found in the message text
     */
    _isShareControlMode: function _isShareControlMode(messageText) {
      // no share cobrowse invitation marker
      var STATUS_OFFER_SHARED = 'cobshoff';
      var COBROWSE_SHARED_ACCEPT_LINK = 'com.inq.flash.client.chatskins.CoBrowseMgr.acceptCobShareInv';
      var COBROWSE_SHARED_DECLINE_LINK = 'com.inq.flash.client.chatskins.CoBrowseMgr.declineCobShareInv';
      // It's used for ATT and Verizon invitation
      var COBROWSE_SHARED_ACCEPT_LINK_FLASHPEER = 'FlashPeer.ciAcceptCobShareInv';
      var COBROWSE_SHARED_DECLINE_LINK_FLASHPEER = 'FlashPeer.ciDeclineCobShareInv';
      var sharedInvitations = [STATUS_OFFER_SHARED, COBROWSE_SHARED_ACCEPT_LINK, COBROWSE_SHARED_DECLINE_LINK, COBROWSE_SHARED_ACCEPT_LINK_FLASHPEER, COBROWSE_SHARED_DECLINE_LINK_FLASHPEER];
      return new RegExp(sharedInvitations.join('|')).test(messageText);
    }
  });
});