define("sdk/api/plugins/plugin-manager", ["exports", "sdk/api/plugins/plugin"], function (_exports, _plugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.registry = Ember.Object.create();
    },
    /**
     * Plugins can be registered with the HTMLAI JS API via
     * `api.plugins.register(MyPluginSubClass);` Plugins should
     * be registered during initialization of the Agent Desktop.
     * See sdk/app/services/api.js for usage.
     * @method register
     * @public
     */
    register: function register(pluginProto) {
      var isPluginSubClass = pluginProto instanceof _plugin.default.constructor;
      var PluginSubClass;
      if (isPluginSubClass) {
        PluginSubClass = pluginProto;
      } else {
        var _name = pluginProto.name,
          run = pluginProto.run;
        if (typeof _name !== 'string') {
          throw new Error('Plugins must contain a `name` property');
        }
        if (typeof run !== 'function') {
          throw new Error('Plugins must contain a `run` function');
        }
        PluginSubClass = _plugin.default.extend(pluginProto);
      }
      var name = PluginSubClass.proto().name;
      this.registry.set(name, PluginSubClass);
    },
    /**
     * processMessge is called by the Engagement class when we receive
     * datapass messages that contain a property named `plugin`. If
     * a plugin by the same name has already been registered during
     * initialization of the application, then the datapass will be
     * sent to the plugin instance to be further processed.
     * @method processMessage
     * @public
     * @param {Array} datapass - Array of key/value pairs. See plugin.js for further details.
     * @param {Engagement} engagement - The engagement instance that received the plugin datapass.
     * @param {Boolean} isReInit - Specifies whether we are re-initializing a plugin instance.
     *                             This occurs when the agent refreshes the browser.
     * @returns {Object} - The object has 2 properties: 1) error, and 2) plugin.
     *                     1) `error` - is null unless the PluginManager cannot find a Plugin
     *                        registered with the same name (as specifed in the datapass).
     *                     2) `plugin` - The instance of the plugin specified in the datapass.
     */
    processMessage: function processMessage(datapass, engagement, isReInit) {
      var options = {};
      var name;
      datapass.forEach(function (item) {
        if (item.name === 'plugin') {
          name = item.data;
        } else {
          options[item.name] = item.data;
        }
      });
      try {
        var params = JSON.parse(options.pluginParams);
        if (params && _typeof(params) === 'object') {
          options.pluginParams = Ember.Object.create(params);
        }
      } catch (e) {/*noop*/}
      var pluginInstance = engagement.plugins.get(name);
      if (!pluginInstance) {
        var PluginClass = this._lookup(name);
        if (!PluginClass) {
          return {
            error: "Unrecognized plugin \"".concat(name, "\" received"),
            plugin: null
          };
        }
        delete options.plugin;
        options._engagement = engagement;
        pluginInstance = PluginClass.create(options);
        if (pluginInstance.longLived) {
          engagement.plugins.set(name, pluginInstance);
        }
        engagement.trigger('pluginInit', pluginInstance);
      }
      if (pluginInstance.runAt === 'immediate' && !isReInit) {
        pluginInstance.run();
        return {};
      }
      return {
        error: null,
        plugin: pluginInstance
      };
    },
    _lookup: function _lookup(name) {
      return this.registry.get(name);
    }
  });
});