define("sdk/components/digital-voice-and-video/component", ["exports", "sdk/core/component", "sdk/components/digital-voice-and-video/template", "sdk/api/utils/media-devices"], function (_exports, _component, _template, _mediaDevices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    classNames: 'digital-voice-and-video',
    isUnsupported: Ember.computed(function () {
      return (0, _mediaDevices.isMediaUnsupported)();
    }),
    enabledCustomerCamera: Ember.computed.reads('engagement.settings.enabledCustomerCamera'),
    enabledVideoCalling: Ember.computed.reads('engagement.settings.enabledVideoCalling'),
    enabledVoiceCalling: Ember.computed.reads('engagement.settings.enabledVoiceCalling'),
    showEndCallModal: Ember.computed.alias('engagement.showEndCallModal'),
    'data-test-digital-voice-and-video-container': '',
    actions: {
      sendVideoCallInvitation: function sendVideoCallInvitation() {
        this.engagement.sendCallInvitation(true);
      },
      sendVoiceCallInvitation: function sendVoiceCallInvitation() {
        this.engagement.sendCallInvitation();
      },
      endCall: function endCall() {
        this.set('showEndCallModal', true);
      }
    }
  });
});