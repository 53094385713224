define("sdk/api/utils/rt-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  // This module is taken from RT project
  // http://stash.touchcommerce.com/projects/RT/repos/rt/browse/rulesgen/src/main/web/WEB-INF/rulesengine/js/MixIns.js

  /* eslint-disable */

  /**
   * Document JSON MixIn here
   * @constructor
   * @class JSON
   * @name JSON
   * @field
   */
  var JSON = function () {
    function f(n) {
      return n < 10 ? '0' + n : n;
    }
    if (typeof Date.prototype.toJSON !== 'function') {
      /** @ignore */
      Date.prototype.toJSON = function (key) {
        return this.getTime();
      };
      /** @ignore */
      String.prototype.toJSON = Number.prototype.toJSON = Boolean.prototype.toJSON = function (key) {
        return this.valueOf();
      };
    }
    var cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      escapeable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
      gap,
      indent,
      meta = {
        '\b': '\\b',
        '\t': '\\t',
        '\n': '\\n',
        '\f': '\\f',
        '\r': '\\r',
        '"': '\\"',
        '\\': '\\\\'
      },
      rep;
    function quote(string) {
      escapeable.lastIndex = 0;
      return escapeable.test(string) ? '"' + string.replace(escapeable, function (a) {
        var c = meta[a];
        if (typeof c === 'string') {
          return c;
        }
        return "\\u" + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
      }) + '"' : '"' + string + '"';
    }
    function str(key, holder) {
      var i,
        k,
        v,
        length,
        mind = gap,
        partial,
        value = holder[key];
      if (value && _typeof(value) === 'object' && typeof value.toJSON === 'function') {
        value = value.toJSON(key);
      }
      if (typeof rep === 'function') {
        value = rep.call(holder, key, value);
      }
      switch (_typeof(value)) {
        case 'string':
          return quote(value);
        case 'number':
          return isFinite(value) ? String(value) : 'null';
        case 'boolean':
        case 'null':
          return String(value);
        case 'object':
          if (!value) {
            return 'null';
          }
          gap += indent;
          partial = [];
          if (typeof value.length === 'number' && !value.propertyIsEnumerable('length')) {
            length = value.length;
            for (i = 0; i < length; i += 1) {
              partial[i] = str(i, value) || 'null';
            }
            v = partial.length === 0 ? '[]' : gap ? '[\n' + gap + partial.join(',\n' + gap) + '\n' + mind + ']' : '[' + partial.join(',') + ']';
            gap = mind;
            return v;
          }
          if (rep && _typeof(rep) === 'object') {
            length = rep.length;
            for (i = 0; i < length; i += 1) {
              k = rep[i];
              if (typeof k === 'string') {
                v = str(k, value);
                if (v) {
                  partial.push(k + (gap ? ': ' : ':') + v);
                }
              }
            }
          } else {
            for (k in value) {
              if (k != "") {
                try {
                  if (Object.hasOwnProperty.call(value, k)) {
                    v = str(k, value);
                    if (v) {
                      partial.push(k + (gap ? ': ' : ':') + v);
                    }
                  }
                } catch (e) {
                  var eMsg = 'Exception at Object.hasOwnProperty.call(' + value + ', ' + k + ') ';
                  eMsg += catchFormatter(e) + ' in JSON.str(' + key + ', ' + holder + ')';
                  ROM.post(urls.loggingURL, {
                    level: 'WARN',
                    line: eMsg
                  });
                }
              }
            }
          }
          v = partial.length === 0 ? '{}' : gap ? '{\n' + gap + partial.join(',\n' + gap) + '\n' + mind + '}' : '{' + partial.join(',') + '}';
          gap = mind;
          return v;
      }
    }
    function clone(object) {
      return parse(stringify(object));
    }
    function stringify(value, replacer, space) {
      if (typeof value == "undefined" || value == null) {
        return null;
      }
      var i;
      gap = '';
      indent = '';
      if (typeof space === 'number') {
        for (i = 0; i < space; i += 1) {
          indent += ' ';
        }
      } else if (typeof space === 'string') {
        indent = space;
      }
      rep = replacer;
      if (replacer && typeof replacer !== 'function' && (_typeof(replacer) !== 'object' || typeof replacer.length !== 'number')) {
        throw new Error('JSON.stringify');
      }
      return str('', {
        '': value
      });
    }
    function parse(text, reviver) {
      if (!text) return null;
      var j;
      function walk(holder, key) {
        var k,
          v,
          value = holder[key];
        if (value && _typeof(value) === 'object') {
          for (k in value) {
            if (Object.hasOwnProperty.call(value, k)) {
              v = walk(value, k);
              if (v !== undefined) {
                value[k] = v;
              } else {
                delete value[k];
              }
            }
          }
        }
        return reviver.call(holder, key, value);
      }
      cx.lastIndex = 0;
      if (cx.test(text)) {
        text = text.replace(cx, function (a) {
          return "\\u" + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
        });
      }
      //if(/^[\],:{}\s]*$/.test(text.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g,'@').replace(/(?:[\w-])+|"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,']').replace(/(?:^|:|,)(?:\s*\[)+/g,''))){
      try {
        j = eval('(' + text + ')');
      } catch (e) {
        e.message = "Error while parsing JSON: " + e.message + ". Input JSON: " + text;
        throw e;
      }
      return typeof reviver === 'function' ? walk({
        '': j
      }, '') : j;
      //}
      //throw new SyntaxError('JSON.parse');
    }

    var retval =
    /**
     * This is an API that can be mixed into other objects
     * @lends JSON#
     */
    {
      /**
       * converts a given Object into a new object instance
       * @function
       * @param object, object to be cloned
       * @return new object
       * @throws {Error} When JS object is not "stringifiable"
       */
      clone: clone,
      /**
       *  converts a given object to JSON string
       *  @function
       *  @param value val
       *  @param replacer replacer
       *  @param space space
       *  @throws {SyntaxError} When invalid JSON string is parsed
       */
      stringify: stringify,
      /**
       * converts a given JSON string to an object
       * @function
       * @param text text to be parsed into an object. Must be valid JSON string to succeed
       * @param reviver
       * @throws {Error} When JS object is not "stringifiable"
       */
      parse: parse
    };
    return retval;
  }();
  var _default = _exports.default = JSON;
});