define("sdk/site/engagements/engagement/controller", ["exports", "sdk/api/utils/media-devices"], function (_exports, _mediaDevices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    devices: Ember.inject.service(),
    intl: Ember.inject.service(),
    windowSize: Ember.inject.service(),
    webToolManager: Ember.inject.service(),
    engagementFocusManager: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('customerDisconnect', this, this._onCustomerDisconnect);
      this.set('tileStateIsExpanded', {});
    },
    engagement: Ember.computed.alias('model'),
    conversationId: Ember.computed.alias('engagement.settings.conversationId'),
    ui: Ember.computed.alias('engagement.ui'),
    expandedTile: Ember.computed.alias('ui.expandedTile'),
    expandedCallTileToFullScreen: Ember.computed.equal('engagement.ui.layout.name', 'full-screen-call'),
    shouldFocusExpandButton: Ember.computed.alias('ui.shouldFocusExpandButton'),
    shouldFocusCompressButton: Ember.computed.alias('ui.shouldFocusCompressButton'),
    engagementClosed: Ember.computed.not('engagement.connected'),
    isDispositionsWindowCollapsed: Ember.computed.alias('engagement.ui.layout.dispositionsWindowCollapsed'),
    isWebToolsWindowCollapsed: Ember.computed.alias('engagement.ui.layout.webToolsWindowCollapsed'),
    isVisitorInfoResizable: Ember.computed('isDispositionsWindowCollapsed', 'isWebToolsWindowCollapsed', function () {
      if (this.get('engagement.uiData.isDispositionsPanelHidden') && this.get('engagement.uiData.isWebToolsPanelHidden')) return false;
      if (this.get('engagement.ui.hasDispositions')) {
        return !this.get('isDispositionsWindowCollapsed') || !this.get('isWebToolsWindowCollapsed');
      }
      return !this.get('isWebToolsWindowCollapsed');
    }),
    isNinaCoach: Ember.computed.alias('engagement.isNinaCoach'),
    isVideoChat: Ember.computed.alias('engagement.isVideoChat'),
    partialName: Ember.computed('engagement.ui.layout.name', 'isNinaCoach', function () {
      var isNinaCoach = this.get('isNinaCoach');
      if (isNinaCoach) return 'nina-coach';

      // if "custom" layout is selected, render partial of custom's "prototype" property
      var layoutPartialName = this.get('engagement.ui.layout.name');
      return layoutPartialName === 'custom' ? this.get('api.agent.settings.layoutCustom.prototype') : layoutPartialName;
    }),
    isTwoColumns: Ember.computed.equal('engagement.ui.layout.name', 'chat-left-two-columns'),
    isCustom: Ember.computed.equal('engagement.ui.layout.name', 'custom'),
    isPrototypeTwoColumns: Ember.computed.equal('engagement.ui.layout.prototype', 'chat-left-two-columns'),
    isCustomFromTwoColumns: Ember.computed.and('isCustom', 'isPrototypeTwoColumns'),
    isChatRightLayout: Ember.computed.equal('partialName', 'chat-right'),
    isLowResolution: Ember.computed.lte('windowSize.width', 1024),
    /**
     *  Removes expanded tile if layout is two-columns or
     *  if window resolution is resized to less than 1024px width
     */
    isTwoColumnsOrLowResolutionObserver: Ember.observer('isTwoColumns', 'isLowResolution', function () {
      if (this.get('isTwoColumns') || this.get('isLowResolution')) this.set('expandedTile', null);
    }),
    /**
     * Sets up the timer for automatically rejecting ownership of a conference
     * whenever the ownership modal is displayed.
     */
    setupAutoOwnershipRejectTimer: Ember.observer('ui.showOwnershipModal', function () {
      var _this = this;
      var showOwnershipModal = this.get('ui.showOwnershipModal');
      var autoOwnershipRejectTimer = this.get('autoOwnershipRejectTimer');
      if (!showOwnershipModal && autoOwnershipRejectTimer) {
        clearTimeout(autoOwnershipRejectTimer);
        this.set('autoOwnershipRejectTimer', null);
      }
      if (showOwnershipModal && !autoOwnershipRejectTimer) {
        var autoOwnershipRejectDelay = this.get('engagement.autoOwnershipRejectDelay');
        this.set('autoOwnershipRejectTimer', setTimeout(function () {
          _this.set('ui.showOwnershipModal', false);
          _this.get('api.agent').refuseOwnership(_this.get('engagement.id'), 'rejected automatically by timeout');
        }, autoOwnershipRejectDelay));
      }
    }),
    /**
     * The list of agents an agent can select when placing a follow up engagement whose SLA expired
     * back into follow up.
     */
    followUpAgents: Ember.computed('engagement.settings.followUpAgent', function () {
      if (!this.get('engagement.settings.followUpAgent')) return;
      return ['Myself', this.get('engagement.settings.followUpAgent')];
    }),
    /**
     * Web tools such as plugins, cobrowse, and agent automatons to be displayed in Web Tools module
     */
    webTools: Ember.computed('api.settings.cobrowseEnabled', 'engagement.automatons', 'ui.webTools', function () {
      var webTools = [];

      // Plugins
      var otherWebTools = this.get('ui.webTools'); // currently only plugins are "other webtools"
      if (otherWebTools) {
        otherWebTools.forEach(function (webTool) {
          webTools.addObject(webTool);
        });
      }

      // Cobrowse
      if (this.get('api.settings.cobrowseEnabled')) {
        webTools.addObject({
          titleTranslationKey: 'co-browse',
          component: 'co-browse',
          data: this.get('engagement'),
          title: 'cobrowse'
        });
      }

      // Agent Automatons
      var automatons = this.get('engagement.automatons');
      if (automatons) {
        automatons.forEach(function (automaton) {
          var automatonWebTool = {
            title: automaton.name,
            // using embeded html because this is too minor for a component
            content: "<div id='".concat(automaton.get('placeholderId'), "' class='layout-fill' />"),
            data: automaton
          };
          if (automaton.isWebTool) {
            // "web-tools" are selected by default and moved to beginning
            webTools.insertAt(0, automatonWebTool);
          } else {
            webTools.addObject(automatonWebTool);
          }
        });
      }
      return webTools;
    }),
    /**
     * Blur if disconnected engagement is the active engagement and display dispositions window if
     * customer disconnects and "autoLaunchDispositionWindow" is enabled
     * @param {Object} engagement
     * @method _onCustomerDisconnect
     */
    _onCustomerDisconnect: function _onCustomerDisconnect(engagement) {
      this.get('engagementFocusManager').removeFocusFromScript();
      var shouldLaunchDispositions = engagement.get('settings.autoLaunchDispositionWindow') && engagement.get('ui.hasDispositions') && !engagement.get('skipDispositionsOnAsyncClose');
      if (shouldLaunchDispositions) {
        engagement.showDispositions();
      }
      if (engagement.isVideoChat || engagement.isVoiceChat) {
        engagement.endCall();
      }
    },
    actions: {
      focusActiveFloatingElement: function focusActiveFloatingElement() {
        var iframe = Ember.$('.nuance-floating-element.is-active iframe')[0];

        // setTimeout is required for IE and FF to set focus on the iframe.
        setTimeout(function () {
          iframe && iframe.contentWindow && iframe.contentWindow.focus();
        }, 100);
      },
      clickEvent: function clickEvent() {
        this.get('engagement').trigger('agentInteracted');
      },
      /**
       * Hides the escalate engagement window
       * @method closeEscalateWindow
       */
      closeEscalateWindow: function closeEscalateWindow() {
        this.engagement.setUIData({
          isEscalateModalOpen: false
        });
      },
      closeTransferPopup: function closeTransferPopup() {
        this.engagement.setStateTransferring(false);
      },
      /**
       * Will store the engagement action before
       * attempting to close the engagement.
       * @method close
       * @param {Object} options options for this method
       * @param {String} options.required specified if the close is not reversible
       * @public
       */
      close: function close() {
        var displayConferenceCloseModal = this.get('engagement.isConferenceMode') && this.get('engagement.ownedByCurrentAgent') && this.get('engagement.isOtherAgentConnected') && !this.get('ui.isClosingConference');
        var enablePromptOnChatClose = this.get('engagement.enablePromptOnChatClose');
        var engagementClosed = this.get('engagementClosed');
        var engagement = this.get('engagement');
        if (displayConferenceCloseModal) {
          this.set('ui.isClosingConference', true);
        } else {
          this.set('ui.showCloseChatModal', !this.get('ui.isClosingConference') && enablePromptOnChatClose && !engagementClosed);
          this.set('ui.isClosingConference', false);
          this.set('ui.endEngagementAction', 'close');
          engagement.showDispositionsOrEndEngagement({
            skipDispositions: engagement.get('skipDispositionsOnAsyncClose')
          });
        }
      },
      /**
       * Will store the engagement action before
       * attempting to resolve the engagement.
       * @method resolve
       * @public
       */
      resolve: function resolve() {
        this.set('ui.showCloseChatModal', this.get('engagement.enablePromptOnChatClose') && !this.get('engagementClosed'));
        var shouldDisplayConferenceResolveModal = this.get('engagement.isConferenceMode') && this.get('engagement.ownedByCurrentAgent') && this.get('engagement.isOtherAgentConnected') && !this.get('ui.showConferenceResolveModal');
        if (shouldDisplayConferenceResolveModal) {
          this.set('ui.showConferenceResolveModal', true);
          return;
        }
        this.set('ui.endEngagementAction', 'resolve');
        this.get('engagement').showDispositionsOrEndEngagement();
        this.set('ui.showConferenceResolveModal', false);
      },
      /**
       * Will store the engagement action before
       * attempting to close the engagement with follow up data.
       * @method followUp
       * @public
       */
      followUp: function followUp() {
        var options = this._getFollowUpOptions();
        this.set('ui.showFollowUpModal', false);
        this.set('ui.endEngagementAction', 'followUp');
        this.get('engagement').showDispositionsOrEndEngagement(options);
      },
      closeChatAfterModal: function closeChatAfterModal() {
        this.set('ui.showCloseChatModal', false);
        var engagement = this.get('engagement');
        var options = {
          skipDispositions: engagement.get('skipDispositionsOnAsyncClose')
        };
        engagement.showDispositionsOrEndEngagement(options);
      },
      /**
       * Places the engagement back into the follow up list if it hasn't
       * been initiated yet. Otherwise, it displays the follow up modal.
       * @method showFollowUpModalOrPlaceBackIntoFollowUp
       * @public
       */
      showFollowUpModalOrPlaceBackIntoFollowUp: function showFollowUpModalOrPlaceBackIntoFollowUp() {
        if (this.get('engagement.manuallyLoaded')) {
          var engagement = this.get('engagement');
          this.get('api.agent').placeEngagementBackIntoFollowUp(engagement);
        } else {
          this.set('ui.showFollowUpModal', true);
        }
      },
      downloadFiles: function downloadFiles(url) {
        if (url) {
          this.get('api.adapter').getUploadedFiles(url);
        }
      },
      /**
       * Close dispositions window
       * @method closeDispositions
       */
      closeDispositions: function closeDispositions() {
        if (!this.get('ui.dispositionModalSettings.required')) {
          this.set('ui.showDispositionsModal', false);
          this.get('api').trigger('dispositionsClosed', this.get('engagement'));
        }
      },
      /**
       * Listener of the collapsing of the Static Transcript tile
       * @method onStaticTileToggle
       * @param {Boolean} isExtended static transcript is expanded or collapsed
       */
      onStaticTileToggle: function onStaticTileToggle(isCollapsed) {
        this.set('maximizeActiveTranscript', isCollapsed);
        this.send('onTileToggle');
      },
      onTileToggle: function onTileToggle(isCollapsed, tileName) {
        this._updateUI();
        this.set("tileStateIsExpanded.".concat(tileName), !isCollapsed);
      },
      expandTileToFullScreen: function expandTileToFullScreen() {
        this.set('engagement.ui.layout.beforeFullScreen', this.get('partialName'));
        this.set('engagement.ui.layout.name', 'full-screen-call');
        this.set('ui.shouldFocusCompressButton', true);
        this._updateUI();
      },
      expandTile: function expandTile(tileName) {
        this.set('ui.expandedTile', tileName);
        this._updateUI();
      },
      compressTile: function compressTile() {
        var layoutBeforeFullScreen = this.get('engagement.ui.layout.beforeFullScreen');
        layoutBeforeFullScreen && this.set('engagement.ui.layout.name', layoutBeforeFullScreen);
        this.set('ui.expandedTile', null);
        this.set('ui.shouldFocusExpandButton', true);
        this._updateUI();
      },
      onRenderWebtoolTab: function onRenderWebtoolTab() {
        this._updateUI();
      },
      onRenderVideoChat: function onRenderVideoChat() {
        this._updateUI();
      },
      onEndCall: function onEndCall() {
        this.set('engagement.showEndCallModal', false);
        this.set('engagement.didVideoRoomCreationFail', false);
        this.get('devices').checkoutVideoDevice().then(function (stream) {
          return (0, _mediaDevices.stopStream)(stream);
        });
        this.get('engagement').endCall();
      },
      onAcceptCall: function onAcceptCall() {
        var _this2 = this;
        this.set('engagement.didVideoRoomCreationFail', false);
        this.engagement.acceptCall().catch(function () {
          _this2.set('engagement.didVideoRoomCreationFail', true);
          _this2.get('engagement').sendWebRTCEvent({
            name: 'createRoomFailed'
          });
        });
      },
      onResizeWebTool: function onResizeWebTool() {
        this.get('webToolManager').toggleIsResizing();
      },
      /**
       * Called when the small resolution layout tiles are dragged.
       * @method onTileDrag
       * @public
       */
      onTileDrag: function onTileDrag() {
        this._updateUI();
      },
      /**
       * Called when the small resolution slideout menu is expanded
       * or collapsed.
       * @method onSlideoutExpandOrCollapse
       * @public
       */
      onSlideoutExpandOrCollapse: function onSlideoutExpandOrCollapse() {
        this._updateUI();
      },
      onKeyboardColumnResize: function onKeyboardColumnResize() {
        this._updateUI();
      },
      /**
       * Ends an engagement when submitting dispositions.
       * Will either resolve or close the engagement depending
       * on the engagement action which was pressed before launching
       * the dispositions modal. Also handles follow up data.
       * @method endEngagementWithDispositions
       * @public
       */
      endEngagementWithDispositions: function endEngagementWithDispositions() {
        var engagement = this.get('engagement');
        var endEngagementAction = this.get('ui.endEngagementAction');
        var dispositionModalSettings = this.get('ui.dispositionModalSettings');
        if (endEngagementAction === 'resolve') return engagement.resolve();
        if (endEngagementAction === 'followUp') {
          var options = this._getFollowUpOptions();
          return engagement.close(options);
        }

        // need to submit these settings in case we transferred the chat
        return engagement.close(dispositionModalSettings);
      },
      showAlert: function showAlert(message) {
        this.get('api').trigger('alert', message);
      },
      acceptOwnership: function acceptOwnership() {
        var _this3 = this;
        this.set('ui.showOwnershipModal', false);
        var engagementId = this.get('engagement.id');
        return this.get('api.agent').acceptOwnership(engagementId).then(function () {
          _this3.get('engagement').state.set({
            ownedByCurrentAgent: true
          });
        });
      },
      refuseOwnership: function refuseOwnership() {
        this.set('ui.showOwnershipModal', false);
        var engagementId = this.get('engagement.id');
        return this.get('api.agent').refuseOwnership(engagementId);
      },
      closeAsyncCannotResolveModal: function closeAsyncCannotResolveModal() {
        this.get('engagement').remove();
        this.set('ui.showAsyncCannotResolveModal', false);
      },
      logWebtoolUsage: function logWebtoolUsage(index) {
        var engagement = this.get('engagement');
        var webtool = this.get('webTools').objectAt(index);
        var options = {
          siteID: engagement.settings.siteId,
          flexibleResponse: true,
          // returns 204 status if response is empty
          params: {
            agentID: engagement.api.agent.userId,
            webtoolID: webtool.title,
            customerID: engagement.customer.id,
            chatID: engagement.id
          }
        };
        this.get('api.dcapiAdapter').sendEventLog(options, {
          domain: 'chat',
          event: 'webtoolUsed'
        });
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('autoOwnershipRejectTimer'));
      this.get('api').off('customerDisconnect', this, this._onCustomerDisconnect);
    },
    /**
     * Returns an options object to pass to the engagement's close method
     * when marking an engagement as follow up.
     * @method _getFollowUpOptions
     * @private
     * @returns {Object}
     */
    _getFollowUpOptions: function _getFollowUpOptions() {
      var followUpNotes = this.get('ui.followUpNotes');
      var followUpAgent = this.get('ui.followUpAgent');
      if (followUpAgent === 'Myself') {
        followUpAgent = this.get('api.agent.userId');
      }
      return {
        followUpNotes: followUpNotes,
        followUpAgent: followUpAgent,
        followUp: true
      };
    },
    /**
     * Triggers methods on the site route so that the floating elements
     * are updated correctly.
     * @method _updateUI
     * @private
     */
    _updateUI: function _updateUI() {
      var _this4 = this;
      this.send('willUpdateEngagementUI');
      Ember.run.schedule('afterRender', function () {
        var engagementId = _this4.get('model.id');
        _this4.send('didUpdateEngagementUI', engagementId);
      });
    }
  });
});