define("sdk/api/data/agent-default-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultStatusList = void 0;
  var defaultStatusList = _exports.defaultStatusList = Object.freeze([{
    label: 'available',
    value: 'available'
  }, {
    label: 'busy',
    value: 'busy'
  }]);
});