define("sdk/components/quill-editor/rich/component", ["exports", "sdk/components/quill-editor/component", "quill"], function (_exports, _component, _quill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Components.QuillEditorRich
   * @extends QuillEditor.Component
   */
  var _default = _exports.default = _component.default.extend({
    'data-test-agent-input': 'rich',
    quillOptions: Object.freeze({
      toolbar: {
        container: '#input-toolbar',
        toolbarOptions: [['bold', 'italic', 'underline'], [{
          background: ['#FFFF00', '#C4FF0E', '#7EE6E2', '#FFAEC8', '#FFCA18', '#C3C3C3']
        }]]
      },
      formats: ['bold', 'italic', 'link', 'underline', 'background']
    }),
    didInsertElement: function didInsertElement() {
      // custom DOM for highlight color select icon
      var icons = _quill.default.import('ui/icons');
      icons['background'] = '<i class="bg-picker" aria-label="color picker"></i><span class="active-bg-color"></span>';
      this._super.apply(this, arguments);

      // add icon class names to clear bg color button
      Ember.$('.ql-picker-options span:not([data-value])').addClass('fa fa-times');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('editor').off('editor-change');
    }
  });
});