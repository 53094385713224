define("sdk/api/models/script-tree", ["exports", "sdk/api/models/script-group", "sdk/utils/array"], function (_exports, _scriptGroup, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      var data = this.__data__;
      this.id = data.id;
      this.name = data.name;
      this.siteId = data.siteId;
    },
    folders: Ember.computed(function () {
      var folders = this.get('__data__.scriptGroupsAndFolders') || [];
      return this._createFolders(folders);
    }),
    groups: Ember.computed(function () {
      var _this = this;
      var scriptGroupsAndFolders = this.get('__data__.scriptGroupsAndFolders') || [];
      var updatedGroups = [];
      var groups = this._addIndexToScriptGroupsAndFolders(scriptGroupsAndFolders);
      groups.forEach(function (data) {
        if (data.scriptGroup || data.errorMsg) {
          data.scriptTreeId = _this.id;
          data.tree = _this;
          updatedGroups.push(_scriptGroup.default.create(data));
        }
      });
      return updatedGroups;
    }),
    /**
     * Returns a flat array of every script in this
     * script tree.
     * @property {Array} allScripts
     * @public
     */
    allScripts: Ember.computed(function () {
      var allScriptGroups = this.get('allScriptGroups');
      return (0, _array.flatten)(allScriptGroups.map(function (group) {
        return group.get('scripts');
      }));
    }),
    allScriptGroups: Ember.computed(function () {
      var groups = this.get('groups');
      var folders = this.get('folders');
      if (folders) {
        return groups.concat(getGroupsFromFolders(folders));
      }
      return groups;
    }),
    _addIndexToScriptGroupsAndFolders: function _addIndexToScriptGroupsAndFolders(scriptGroupsAndFolders) {
      var i = 0;
      return scriptGroupsAndFolders.map(function (group) {
        group.index = i;
        i++;
        return group;
      });
    },
    /**
     * Generates all the folder objects including nested folders.
     * Also generates the script groups within.
     * @param {Array} folders the raw data for the folders
     * @return {Array} an array of all the folder objects
     */
    _createFolders: function _createFolders(folders) {
      var _this2 = this;
      var updatedFolders = [];
      folders.forEach(function (folder) {
        var folderGroups = [];
        var scriptGroupsAndFolders = folder.scriptGroupsAndFolders;
        if (scriptGroupsAndFolders) {
          var groups = _this2._addIndexToScriptGroupsAndFolders(scriptGroupsAndFolders);
          groups.forEach(function (group) {
            if (group.scriptGroup || group.errorMsg) {
              group.tree = _this2;
              group.scriptTreeId = _this2.id;
              folderGroups.push(_scriptGroup.default.create(group));
            }
          });
          folder.groups = folderGroups;
          folder.folders = _this2._createFolders(scriptGroupsAndFolders);
          updatedFolders.push(Ember.Object.create(folder));
        }
      });
      return updatedFolders;
    }
  });
  /**
   * Recursively searches all folders and sub-folders to return
   * an array with all the script groups found within.
   * @param {Array} folders the list of folders
   * @return {Array} an array of all the groups from all the folders
   */
  function getGroupsFromFolders(folders) {
    var result = [];
    folders.forEach(function (folder) {
      var groups = folder.groups || [];
      if (folder.folders) {
        result.pushObjects(groups.concat(getGroupsFromFolders(folder.folders)));
      } else {
        result.pushObjects(groups);
      }
    });
    return result;
  }
});