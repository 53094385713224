define("sdk/components/nested-dropdown/menu/component", ["exports", "sdk/core/component", "sdk/components/nested-dropdown/menu/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nested-dropdown-menu',
    actions: {
      /**
       * Passes the selected option up to the parent component
       * @param option - option selected
       */
      onSelected: function onSelected(option) {
        var onSelected = this.get('onSelected');
        onSelected && onSelected(option);
      }
    }
  }).reopenClass({
    positionalParams: ['options']
  });
});