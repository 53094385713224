define("sdk/mixins/component-instance-manager", ["exports", "sdk/utils/ember-component"], function (_exports, _emberComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this._componentInstances = [];
      this._componentFactories = {};
      this._componentLayouts = {};
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.destroyAllComponentInstances();
    },
    createComponentInstanceElement: function createComponentInstanceElement(componentPath) {
      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var componentFactory = this.getComponentFactory(componentPath);
      var componentInstance = componentFactory.create(Object.assign({
        layout: this.getComponentLayout(componentPath)
      }, attributes));
      this._componentInstances.pushObject(componentInstance);
      return getElementFromComponentInstance(componentInstance);
    },
    destroyAllComponentInstances: function destroyAllComponentInstances() {
      this._componentInstances.forEach(function (componentInstance) {
        if (componentInstance.isDestroying || componentInstance.isDestroyed) return;
        componentInstance.destroy();
      });
      this._componentInstances = [];
    },
    destroyComponentInstanceByElement: function destroyComponentInstanceByElement(componentElementWithParentDiv) {
      var componentElement = componentElementWithParentDiv.firstChild;
      if (componentElement && componentElement.id) {
        var componentInstance = this._componentInstances.findBy('elementId', componentElement.id);
        if (componentInstance) componentInstance.destroy();
      }
    },
    getComponentFactory: function getComponentFactory(componentPath) {
      if (!this.get("_componentFactories.".concat(componentPath))) {
        this.set("_componentFactories.".concat(componentPath), (0, _emberComponent.getComponentFactory)(this, componentPath));
      }
      return this.get("_componentFactories.".concat(componentPath));
    },
    getComponentLayout: function getComponentLayout(componentPath) {
      if (!this.get("_componentLayouts.".concat(componentPath))) {
        this.set("_componentLayouts.".concat(componentPath), (0, _emberComponent.getComponentLayout)(this, componentPath));
      }
      return this.get("_componentLayouts.".concat(componentPath));
    }
  }); // component element must be wrapped in a parent element (<div>)
  // https://github.com/yapplabs/ember-tether/issues/47
  function getElementFromComponentInstance(componentInstance) {
    var parentElement = document.createElement('div');
    componentInstance.appendTo(parentElement);
    return parentElement;
  }
});