define("sdk/api/models/core/object-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.ObjectProxy.extend(Ember.Evented, {
    clone: function clone() {
      return Ember.assign({}, this.get('content'));
    },
    toJSON: function toJSON() {
      var content = this.get('content');
      if (content.toJSON) {
        content = content.toJSON();
      }
      return content;
    }
  }).reopenClass({
    create: function create() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (options.content instanceof this) {
        options = options.content;
      }
      return this._super(options);
    }
  });
});