define("sdk/api/utils/api-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isServerError = _exports.isClientError = void 0;
  var isClientError = _exports.isClientError = function isClientError(status) {
    return status >= 400 && status <= 499;
  };
  var isServerError = _exports.isServerError = function isServerError(status) {
    return status >= 500 && status <= 599;
  };
});