define("sdk/api/utils/agent", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOtherAgentsAttributes = getOtherAgentsAttributes;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function getOtherAgentsAttributes() {
    var agents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var _ref = arguments.length > 1 ? arguments[1] : undefined,
      currentAgentId = _ref.currentAgentId,
      filterAttributes = _ref.filterAttributes;
    return agents.filter(function (agent) {
      return agent.agentID !== currentAgentId;
    }).map(function (agent) {
      var lowercaseAttributes = _lodash.default.mapKeys(agent.agentAttribute, function (value, key) {
        return key.toLowerCase();
      });
      var filteredAttributes = filterAttributes ? _lodash.default.pick(lowercaseAttributes, filterAttributes) : lowercaseAttributes;
      var attributes = _lodash.default.mapValues(filteredAttributes, function (value) {
        return value.join(', ');
      });
      return _objectSpread({
        agentId: agent.agentID
      }, attributes);
    });
  }
});