define("sdk/components/static-transcript-window/line/component", ["exports", "sdk/components/transcript-window/transcript-line/component", "sdk/components/static-transcript-window/line/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'static-transcript-line',
    'data-test-static-transcript-line': '',
    messageAttributesText: Ember.computed('line.{messageAttributesText,hasSearchMatch}', function () {
      return this.get('line.hasSearchMatch') ? Ember.String.htmlSafe(this.get('line.searchedMessageAttributes')) : this.get('line.messageAttributesText');
    }),
    filteredText: Ember.computed('line.{messageHTML,hasSearchMatch}', function () {
      return this.get('line.hasSearchMatch') ? Ember.String.htmlSafe(this.get('line.searchedMessageHTML')) : this.get('line.messageHTML');
    }),
    prefix: Ember.computed('line.{prefix,hasSearchMatch}', function () {
      var prefix = this.get('line.prefix');
      if (prefix && this.get('line.hasSearchMatch')) {
        return Ember.String.htmlSafe(this.get('line.searchedPrefix'));
      }
      return prefix ? Ember.String.htmlSafe(prefix) : null;
    })
  });
});