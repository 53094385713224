define("sdk/api/utils/linkify", ["exports", "sdk/api/utils/parse-html", "dompurify"], function (_exports, _parseHtml, _dompurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.encodeUrl = encodeUrl;
  _exports.linkify = linkify;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Takes a string and if it contains a URL it will return the string
   * with the URL as an anchor tag.
   * @method linkify
   * @param {String} text the text to search through
   * @param {Object} options options object
   * @param {Boolean} options.noQuotes removes quotes from text
   * @return {String} the text string with any URLs as anchor tags
   * @private
   */
  function linkify() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var noQuotes = options.noQuotes;
    text = replaceTextUrlsWithAnchorTags(text);
    text = linkifyAnchorElements(text, options);
    if (noQuotes) return text.replace(/"/g, '');
    return text;
  }
  function replaceTextUrlsWithAnchorTags() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (0, _parseHtml.walkTextNodes)(text, createAnchorNodesFromTextNode);
  }
  function linkifyAnchorElements() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _options$emptyTextPla = options.emptyTextPlaceholder,
      emptyTextPlaceholder = _options$emptyTextPla === void 0 ? 'Link' : _options$emptyTextPla,
      newTagName = options.tagName,
      disableFocus = options.disableFocus;
    var linkIndex = 0;
    return (0, _parseHtml.walkElementNodes)(text, function (elementNode) {
      var tagName = elementNode.tagName;
      if (tagName === 'A') {
        elementNode.classList.add('linkified');
        elementNode.setAttribute('data-test-link', linkIndex);
        linkIndex++;
        if (!elementNode.childNodes.length) {
          elementNode.textContent = emptyTextPlaceholder;
        }
        _dompurify.default.sanitize(elementNode, {
          IN_PLACE: true
        });
        var href = elementNode.getAttribute('href');
        if (href && href !== '#') {
          var encodedHref = encodeUrl(href);
          elementNode.setAttribute('target', '_blank');
          elementNode.setAttribute('href', encodedHref);
          elementNode.setAttribute('title', encodedHref);
        } else {
          elementNode.setAttribute('href', '#');
        }
        if (disableFocus) elementNode.setAttribute('tabindex', '-1');
        if (newTagName) {
          var newElementNode = swapAnchorTag(elementNode, newTagName);
          elementNode.parentNode.replaceChild(newElementNode, elementNode);
          return newElementNode;
        }
      }
    });
  }
  function encodeUrl(url) {
    try {
      //decode url to avoid duplicate encoding
      url = decodeURI(url);
    } catch (e) {/* noop */}
    return encodeURI(url);
  }
  function createAnchorNodesFromTextNode(textNode) {
    var textContent = textNode.textContent,
      parentNode = textNode.parentNode;
    var urlMatch = textContent.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9()@:%_+.,~#?&\\/=]*[-a-zA-Z0-9()%_+~#?&\\/=]+)*/);
    var hasAnchorParent = parentNode.tagName === 'A';
    if (urlMatch && !hasAnchorParent) {
      var url = urlMatch[0];
      var index = urlMatch.index;
      var beforeUrlTextNode = document.createTextNode(textContent.substring(0, index));
      parentNode.insertBefore(beforeUrlTextNode, textNode);
      var anchorNode = document.createElement('a');
      anchorNode.href = url;
      anchorNode.textContent = url;
      parentNode.insertBefore(anchorNode, textNode);
      var afterUrlTextNode = document.createTextNode(textContent.substring(index + url.length));
      parentNode.insertBefore(afterUrlTextNode, textNode);

      // recursively create anchor nodes on text found after first url
      // for cases where there are multiple urls in one textNode
      createAnchorNodesFromTextNode(afterUrlTextNode);
      parentNode.removeChild(textNode);
    }
  }
  function swapAnchorTag(anchorNode) {
    var tagName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'span';
    var newElementNode = document.createElement(tagName);
    newElementNode.innerHTML = anchorNode.innerHTML;
    var _iterator = _createForOfIteratorHelper(anchorNode.attributes),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = _step.value,
          name = _step$value.name,
          value = _step$value.value;
        if (['href', 'target'].includes(name)) continue;
        newElementNode.setAttribute(name, value);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return newElementNode;
  }
});