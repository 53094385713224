define("sdk/components/collapsable-tile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XkL41ij3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[13,1,[[26,\"hash\",null,[[\"header\",\"content\",\"hasOverlay\"],[[26,\"component\",[\"collapsable-tile/header\"],[[\"class\",\"onToggle\",\"expandable\",\"fullScreenExpandable\",\"shouldFocusExpandButton\",\"shouldFocusCompressButton\",\"isExpanded\",\"isCollapsed\",\"hasBeenDragged\",\"onSendBackToStartingPosition\",\"onExpanded\",\"onExpandedToFullScreen\",\"onCompressed\",\"iconAriaLabel\",\"collapseMenuId\"],[\"header\",[26,\"action\",[[21,0,[]],\"toggle\"],null],[22,[\"expandable\"]],[22,[\"fullScreenExpandable\"]],[22,[\"shouldFocusExpandButton\"]],[22,[\"shouldFocusCompressButton\"]],[22,[\"isExpanded\"]],[22,[\"isCollapsed\"]],[22,[\"hasBeenDragged\"]],[26,\"action\",[[21,0,[]],\"sendBackToStartingPosition\"],null],[26,\"action\",[[21,0,[]],\"expand\"],null],[26,\"action\",[[21,0,[]],\"expandTileToFullScreen\"],null],[26,\"action\",[[21,0,[]],\"compress\"],null],[22,[\"iconAriaLabel\"]],[22,[\"collapseMenuId\"]]]]],[26,\"component\",[\"collapsable-tile/content\"],[[\"id\",\"class\",\"isCollapsed\",\"onHidden\",\"onShown\"],[[22,[\"collapseMenuId\"]],[22,[\"bsCollapseClasses\"]],[22,[\"isCollapsed\"]],[26,\"action\",[[21,0,[]],\"onHidden\"],null],[26,\"action\",[[21,0,[]],\"onShown\"],null]]]],[22,[\"hasOverlay\"]]]]]]],[0,\"\\n\\n\"],[6,\"div\"],[11,\"class\",[27,[\"cover \",[26,\"if\",[[22,[\"hasOverlay\"]],\"show\"],null]]]],[10,\"data-test-cover\",\"\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/collapsable-tile/template.hbs"
    }
  });
});