define("sdk/components/transcript-window/transcript-line/component", ["exports", "sdk/core/component", "sdk/components/transcript-window/transcript-line/template", "sdk/components/transcript-window/transcript-line/context-menu", "sdk/mixins/link-navigation", "sdk/utils/speak", "sdk/api/utils/truncate", "sdk/api/classes/rich-widget"], function (_exports, _component, _template, _contextMenu, _linkNavigation, _speak, _truncate2, _richWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_contextMenu.default, _linkNavigation.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    componentName: 'transcript-line',
    'data-test-transcript-line': '',
    'data-message-category': Ember.computed.alias('lineType'),
    attributeBindings: ['aria-label', 'data-message-category', 'tabindex'],
    tabindex: '0',
    isRichWidget: Ember.computed.bool('messageDataJSON.richWidget'),
    classNameBindings: ['notSent', 'isResent:resent', 'lineTypeClass', 'stateChangeClass', 'line.isStatusMessage:status-line', 'line.isInternalConversation', 'ninaCoachUserAnswer'],
    transcriptLine: Ember.computed.alias('line'),
    // transcriptLineContextMenuMixin expects transcriptLine property

    notSent: Ember.computed.and('line.isInterrupted', 'active'),
    isResent: Ember.computed.alias('line.isResent'),
    isACIFPrechatDatapass: Ember.computed.alias('line.isACIFPrechatDatapass'),
    isConferenceAgentMessage: Ember.computed.alias('line.isConferenceAgentMessage'),
    isXformDatapass: Ember.computed.alias('line.isXformDatapass'),
    lineText: Ember.computed.alias('line.messageHTML'),
    lineTextTruncated: Ember.computed('lineText', function () {
      var _truncate = (0, _truncate2.default)(this.get('lineText'), 1000),
        hasTruncatedText = _truncate.hasTruncatedText,
        truncatedText = _truncate.truncatedText;
      return hasTruncatedText ? "".concat(truncatedText, "...") : undefined;
    }),
    ninaCoachUserAnswer: Ember.computed.alias('line.ninaCoachUserAnswer'),
    originalMessageText: Ember.computed.alias('line.originalMessageText'),
    disableAgentMaskingAbility: Ember.computed.reads('engagement.settings.disableAgentMaskingAbility'),
    shouldShowWidgetImage: Ember.computed('isCustomerMessage', 'line.{isPopUpWindow,isRichWidget}', function () {
      return this.get('line.isRichWidget') && !this.get('line.isPopUpWindow') && !this.get('isCustomerMessage');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (Ember.get(this, 'line.isCustomAction')) {
        this.set('messageText', Ember.String.htmlSafe(this.get('messageText')));
      }
      if (this.isRichWidget) {
        var messageDataJSON = this.get('messageDataJSON');
        messageDataJSON.agentMessage = this.get('messageText');
        this.previewRichWidget = new _richWidget.default(messageDataJSON);
        this.previewRichWidget.messageText = this.get('messageText');
        this.previewRichWidget.ui = {
          hasActionFooter: false
        };
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setAriaLabel();
      if (this.get('ninaCoachUserAnswer')) {
        var prefix = this.get('prefix');
        var content = this.get('messageHTML') || '';
        (0, _speak.default)("".concat(this.get('intl').t('nina-coach.alert'), ", ").concat(prefix, ", ").concat(content));
      }
    },
    setAriaLabel: function setAriaLabel() {
      var messageDataJSON = this.get('messageDataJSON');
      if (messageDataJSON) {
        var prefix = this.get('prefix');
        var formattedTimestamp = this.get('line.formattedTimestamp');
        var messageText = this.get('messageText');
        var widgetLabel = this.get('line.widgetLabel') || 'Rich Widget';
        var timestamp = this.active ? this.get('showTimestamp') ? formattedTimestamp : '' : formattedTimestamp;
        var ariaLabel = "".concat(timestamp ? timestamp + '. ' : '') + prefix + ". " + "".concat(messageText ? messageText + '. ' : '') + "".concat(this.active ? 'Widget: ' + widgetLabel : this.get('intl').t('static-transcript.initiated-widget', {
          label: widgetLabel
        }));
        if (this.active) {
          this.set('aria-label', this.get('intl').t('a11y.widget-navigation-instruction', {
            content: ariaLabel
          }));
        } else {
          this.set('aria-label', ariaLabel);
        }
      }
    },
    click: function click(e) {
      this._super.apply(this, arguments);
      var $target = Ember.$(e.target);
      var $trigger = $target.closest('[data-plugin-trigger]');
      if ($trigger.length) {
        this.line.plugin.run();
      }
      if (this.get('isFileUploadLink')) {
        var href = $target.attr('href');
        this.get('api.adapter').getUploadedFiles(href);
        return false;
      }
    },
    keyDown: function keyDown(event) {
      this._super.apply(this, arguments);
      if (event.keyCode === 37 || event.keyCode === 39) {
        event.stopPropagation();
        event.preventDefault();
        var widgetPreviewButton = this.element.querySelector('.nuance-rich-widget-preview-button');
        if (widgetPreviewButton) {
          if (widgetPreviewButton.getAttribute('tabindex') === '0') {
            widgetPreviewButton.setAttribute('tabindex', '-1');
            widgetPreviewButton.parentElement.focus();
          } else {
            widgetPreviewButton.setAttribute('tabindex', '0');
            widgetPreviewButton.focus();
          }
        }
      }
    },
    actions: {
      resend: function resend() {
        this.set('line.isResent', true);
        var recommendationRanks = this.get('line.recommendationRanks');
        var isAgentCoach = !!recommendationRanks;
        var message = {
          messageText: this.get('messageText'),
          messageType: 'chatLine'
        };
        if (isAgentCoach) {
          message.agentCoachLogging = this.get('line.agentCoachLogging');
          message.recommendationRanks = recommendationRanks;
        }
        return this.get('engagement').send(message);
      },
      resendSms: function resendSms() {
        return this._sendSmsCommand('RESEND');
      },
      skipSms: function skipSms() {
        return this._sendSmsCommand('SKIP');
      },
      onPreviewWidget: function onPreviewWidget() {
        this.set('engagement.ui.previewRichWidget', this.get('previewRichWidget'));
      },
      handleRenderUpdate: function handleRenderUpdate() {
        this.onRenderUpdate && this.onRenderUpdate();
      }
    },
    isCustomerMessage: Ember.computed.alias('line.isCustomerMessage'),
    isNinaMessage: Ember.computed.alias('line.isNinaMessage'),
    lineType: Ember.computed(function () {
      var type = this.get('line.type');
      if (this.get('line.isConversation')) {
        type = this.get('line.sender');
      }
      if (type === 'customerData' || type === 'stateChange') {
        type = 'system';
      }
      type = type || 'system';
      return Ember.String.dasherize(type);
    }),
    lineTypeClass: Ember.computed('lineType', function () {
      return this.get('lineType') + '-line';
    }),
    stateChange: Ember.computed(function () {
      if (this.get('line.isStateChange')) {
        var state = this.get('line.name') || this.get('line.state') || 'unknown';
        return Ember.String.dasherize(state);
      }
    }),
    stateChangeClass: Ember.computed('stateChange', function () {
      if (this.get('line.isStateChange')) {
        return this.get('stateChange') + '-state-change';
      }
    }),
    prefix: Ember.computed('line.prefix', function () {
      var prefix = this.get('line.prefix');
      if (prefix === 'Xform') return this.get('intl').t('transcript-window.transcript-line.xform');
      return prefix ? Ember.String.htmlSafe(prefix) : prefix;
    }),
    showTimestamp: Ember.computed.alias('engagement.settings.transcriptTimestamps'),
    maskedText: Ember.computed.alias('line.maskedText'),
    messageText: Ember.computed.alias('line.messageText'),
    messageDataJSON: Ember.computed.alias('line.messageDataJSON'),
    isFileUploadLink: Ember.computed.alias('line.isFileUploadLink'),
    enableContextMenu: Ember.computed('isCustomerMessage', 'isConferenceAgentMessage', 'isACIFPrechatDatapass', 'isXformDatapass', 'active', function () {
      var selectedTranscriptId = this.get('engagement.ui.lastSelectedTranscriptID');
      var isDatapass = this.get('isACIFPrechatDatapass') || this.get('isXformDatapass');
      var isActiveTranscript = this.get('active');
      var isCurrentTranscript = selectedTranscriptId === this.get('engagement.id');
      var isMaskable = (this.get('isCustomerMessage') && (isActiveTranscript || isCurrentTranscript) || this.get('isConferenceAgentMessage')) && !isDatapass;
      return isMaskable;
    }),
    enableContextMenuMasking: Ember.computed.not('disableAgentMaskingAbility'),
    /**
     * Send smsCommand request when agent resends or skips failed sms message
     * @param {String} command sms command to send
     */
    _sendSmsCommand: function _sendSmsCommand(command) {
      return this.get('api.adapter').smsCommand({
        command: command,
        engagementId: this.get('engagement.id'),
        lineId: this.get('line.lineId')
      });
    }
  }).reopenClass({
    positionalParams: ['line']
  });
});