define("sdk/api/utils/word-count-delay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getWordCountDelay;
  /**
   * Returns the delay for sending a given script in milliseconds.
   * @method getWordCountDelay
   * @private
   * @param {String} text the text of the script to send
   * @returns {Number} the delay in milliseconds
   */
  function getWordCountDelay(text) {
    if (!text) return 0;
    var NUM_MILLISECONDS_PER_WORD = 300;
    var NUM_WORDS_IN_TEXT = text.split(' ').length;
    return NUM_WORDS_IN_TEXT * NUM_MILLISECONDS_PER_WORD;
  }
});