define("sdk/components/rich-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UFZ8aidR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"rich-widget__error-message\"],[10,\"data-test-rich-widget-error-message\",\"\"],[8],[1,[20,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"rich-widget__container\"],[10,\"data-test-rich-widget-container\",\"\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/rich-widget/template.hbs"
    }
  });
});