define("sdk/components/rich-widget-preview-popup/component", ["exports", "sdk/core/component", "sdk/components/rich-widget-preview-popup/template", "sdk/api/models/transcript-line", "sdk/api/utils/rich-widgets"], function (_exports, _component, _template, _transcriptLine, _richWidgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'rich-widget-preview-popup',
    previewTitle: Ember.computed('richWidget.title', function () {
      var previewTitle = this.get('richWidget.title');
      return previewTitle || this.intl.t('rich-widget.default-label');
    }),
    agentTranscriptLine: Ember.computed('richWidget', function () {
      var richWidget = this.get('richWidget');
      if (!richWidget) return;
      return _transcriptLine.default.create({
        __data__: {
          messageText: richWidget.agentMessage,
          messageData: JSON.stringify((0, _richWidgets.getMessageDataFromRichWidget)(richWidget)),
          messageType: 'script',
          sender: 'agent'
        },
        agentId: this.get('api.agent.userId'),
        engagement: this.engagement,
        isPopUpWindow: true
      });
    }),
    isSendDisabled: Ember.computed('richWidget', 'engagement', 'engagement.ui.lastSentMessage', function () {
      var richWidget = this.richWidget;
      var _ref = this.get('engagement.ui.lastSentMessage') || {},
        lastSentMessageData = _ref.messageData;
      return richWidget && JSON.stringify((0, _richWidgets.getMessageDataFromRichWidget)(richWidget)) === JSON.stringify(lastSentMessageData);
    }),
    actions: {
      close: function close() {
        this.closePopup();
      },
      sendRichWidget: function sendRichWidget() {
        var _this = this;
        return this.engagement.send(this.richWidget.agentMessage, {
          messageData: (0, _richWidgets.getMessageDataFromRichWidget)(this.richWidget)
        }).then(function () {
          return _this.closePopup();
        });
      },
      sendRichWidgetToAgentInput: function sendRichWidgetToAgentInput() {
        this.engagement.sendToAgentInput(this.richWidget.agentMessage, {
          richWidget: this.richWidget
        });
        this.closePopup();
      }
    },
    closePopup: function closePopup() {
      this.set('richWidget', undefined);
    }
  });
});