define("sdk/components/notification-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1gwmCRKW",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[11,\"class\",[27,[\"notification-banner \",[20,\"type\"]]]],[8],[0,\"\\n\\n\"],[4,\"unless\",[[26,\"or\",[[22,[\"lockdown\"]],[22,[\"isMinimized\"]]],null]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"actions\"],[8],[0,\"\\n      \"],[6,\"button\"],[10,\"data-test-notification-minimize\",\"\"],[3,\"action\",[[21,0,[]],\"minimize\"]],[8],[0,\"\\n        \"],[1,[26,\"fa-icon\",[\"window-minimize\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"content\"],[10,\"data-test-notification-content\",\"\"],[3,\"action\",[[21,0,[]],\"restore\"]],[8],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"icon\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"icon\"],[8],[0,\"\\n        \"],[1,[26,\"fa-icon\",[[22,[\"icon\"]]],[[\"pulse\"],[[26,\"if\",[[22,[\"notification\",\"iconPulse\"]],true,false],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"text\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"notification\",\"title\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"class\",\"title\"],[10,\"data-test-notification-title\",\"\"],[8],[0,\"\\n          \"],[1,[22,[\"notification\",\"title\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[22,[\"isMinimized\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"data-test-notification-message\",\"\"],[8],[0,\"\\n          \"],[1,[22,[\"notification\",\"message\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/notification-modal/template.hbs"
    }
  });
});