define("sdk/components/static-transcript-window/transcript/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8vurNxe2",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"list-render\",null,[[\"class\",\"items\",\"initialScrollToItem\",\"options\",\"scrollToItem\",\"shouldSaveLastIndex\",\"initialCellFocusIndex\",\"getItemElement\",\"onCellsUpdated\",\"onItemElementRemoved\",\"willRenderNewList\"],[\"static-transcript-window__messages\",[22,[\"messages\"]],\"top\",[22,[\"engagement\",\"ui\",\"staticTranscriptListRenderOptions\"]],[22,[\"scrollToItem\"]],true,[22,[\"initialTranscriptLineFocusIndex\"]],[26,\"action\",[[21,0,[]],\"getStaticTranscriptLineElement\"],null],[26,\"action\",[[21,0,[]],\"onTranscriptLineCellsUpdated\"],null],[26,\"action\",[[21,0,[]],\"destroyStaticTranscriptLineComponentInstance\"],null],[26,\"action\",[[21,0,[]],\"destroyAllStaticTranscriptLineComponentInstances\"],null]]]],false],[0,\"\\n\\n\"],[6,\"button\"],[10,\"id\",\"node-after-list-render\"],[10,\"class\",\"a11y-hidden\"],[10,\"role\",\"application\"],[3,\"action\",[[21,0,[]],\"focusListRender\"],[[\"on\"],[\"focusIn\"]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/static-transcript-window/transcript/template.hbs"
    }
  });
});