define("sdk/api/logging", ["exports", "ember-uuid", "moment", "sdk/api/utils/runloop", "lodash/defaults"], function (_exports, _emberUuid, _moment, _runloop, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Responsible for persisting the logObject to local storage and then sending it to
   * the server logs after verifying the authorization and the network status.
   * @class Logging
   * @public
   */
  var _default = _exports.default = Ember.Object.extend({
    LOG_KEY: 'logsToSend',
    /**
     * sends a log
     * @method sendLog
     * @public
     *
     * @param {String} level can be "info", "warn", or "error"
     * @param {Object} props any additional key value paris to pass in log
     */
    sendLog: function sendLog() {
      var level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'info';
      var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _getPstTime = getPstTime(),
        dateTime = _getPstTime.dateTime,
        timestamp = _getPstTime.timestamp;
      var detailObject = (0, _defaults.default)({
        event: undefined,
        event_group: undefined
      }, props, {
        agent_id: this.get('api.agent.userId'),
        registered_id: this.get('api.agent.registeredId'),
        htmlai_session_id: this.get('api.storage.store.sessionId'),
        client_date_time: dateTime,
        client_timestamp: timestamp
      });
      var detailString = stringifyObject(detailObject);

      // don't log while testing because it will make the test results confusing
      if (!window.runningTests && !window.runningPlaywright) {
        console.log("[".concat(level.toUpperCase(), "] ").concat(detailString)); //eslint-disable-line no-console
      }

      this.sendLogItemToServer({
        level: level,
        detail: detailString
      });
    },
    /**
     * This first persists the log object in the local storage and then sends out the logs from the storage while throttling.
     * Throttling is required because if there is an error occurring several times, then the number of occurrences is captured
     * and then the error info with the occurrence count is sent in one request at the end of the throttle wait interval.
     * @method sendLogItemToServer
     * @public
     *
     * @param {Object} logObject
     */
    sendLogItemToServer: function sendLogItemToServer(logObject) {
      if (!logObject) return;
      this.persistLogItemToStorage(logObject);
      if (window.runningTests || window.runningPlaywright) {
        this._logToServerAndRemoveFromStorage(logObject);
      } else {
        (0, _runloop.throttle)(this, this.sendLogItemsToServerFromStorage, 10 * 1000, false); // false: triggers the function on the trailing edge of the wait interval.
      }
    },
    /**
     * This retrieves the agent logs from the Local Storage and sends it one by one to the server.
     * @method sendLogItemsToServerFromStorage
     * @public
     *
     * @param {Object} logObject
     */
    sendLogItemsToServerFromStorage: function sendLogItemsToServerFromStorage() {
      var _this = this;
      var logsArray = this.getLogItemsFromStorage();
      if (!logsArray) return;
      logsArray.forEach(function (logObj) {
        _this._logToServerAndRemoveFromStorage(logObj);
      });
    },
    /**
     * Sending the formatted logObject info to the server logs
     * after verifying the authorization and the network status.
     * If successful, then removes the logObject from local storage
     * @method _logToServerAndRemoveFromStorage
     * @private
     *
     * @param {Object} logObject
     */
    _logToServerAndRemoveFromStorage: function _logToServerAndRemoveFromStorage(logObject) {
      var _this2 = this;
      var authorization = sessionStorage.getItem('authorization');
      if (!authorization || Object.keys(JSON.parse(authorization)).length < 1) {
        return; //if not authorized, then don't try to send it.
      }

      this.get('api.router').checkOnlineStatus().then(function (isOnline) {
        if (isOnline) {
          _this2.get('api.adapter').agentLogging(_this2._formatForEndPoint(logObject)).then(function () {
            _this2.removeLogItemFromStorage(logObject);
          }).catch(function (error) {
            //TODO: Handle this appropriately after RTDEV-28447 is implemented

            _this2.removeLogItemFromStorage(logObject);
            if (error) error.alreadyThrown = true;
          });
        }
      });
    },
    /**
    * This persists the received logObject to the Local Storage
    * It persists it only if the logObject does not exist (by comparing the id) in the array
    * @method _formatForEndPoint
    * @private
    *
    * @param {Object} logObject
    * @returns {Object} endPointData
    */
    _formatForEndPoint: function _formatForEndPoint(logObject) {
      //Cloning the object so that we can later delete the keys that need not be sent to the server
      var cloneLogObj = Object.assign({}, logObject);
      var endPointData = {
        level: logObject.level || 'error',
        detail: logObject.detail || {
          metaData: {
            occurrences: cloneLogObj.occurrences || 1
          },
          log: cloneLogObj
        }
      };
      var agent = this.api.get('agent');
      if (agent) {
        endPointData.agentId = agent.userId;
        if (endPointData.detail.metaData) {
          endPointData.detail.metaData.registeredId = agent.registeredId;
        }
      }

      //Delete the keys that should not be sent to the server.
      if (cloneLogObj.id) delete cloneLogObj.id;
      if (cloneLogObj.index) delete cloneLogObj.index;
      if (cloneLogObj.level) delete cloneLogObj.level;
      if (cloneLogObj.occurrences) delete cloneLogObj.occurrences;
      return endPointData;
    },
    /**
    * This persists the received logObject to the Local Storage
    * It persists it only if the logObject does not exist (by comparing the id) in the array.
    * Also, it increases the occurrence count if it's the same error info (by comparing the index)
    * @method persistLogItemToStorage
    * @public
    *
    * @param {Object} logObject
    */
    persistLogItemToStorage: function persistLogItemToStorage(logObject) {
      logObject.id = (0, _emberUuid.v4)();
      logObject.occurrences = 1;
      var logsArray = this.api.localStorage.getItem(this.LOG_KEY);
      if (logsArray) {
        //As a safety, lets not persist more than 500 log objects. We don't want to spam the server log and local storage.
        if (logsArray.length >= 500) return;

        //Add it only if the logObject has not been already added (by comparing the id)
        var logExists = false;
        logsArray.forEach(function (eachArray) {
          if (eachArray.id === logObject.id) {
            logExists = true;
            return;
          } else if (logObject.index && logObject.index === eachArray.index) {
            eachArray.occurrences = eachArray.occurrences + 1;
            logExists = true;
            return;
          }
        });
        if (!logExists) {
          logsArray.push(logObject);
        }
      } else {
        logsArray = [logObject];
      }
      this.api.localStorage.setItem(this.LOG_KEY, logsArray);
    },
    /**
     * This retrieves the LOG_KEY array from the Local Storage and returns it
     * @method getLogItemsFromStorage
     * @public
     *
     * @return {Array}
     */
    getLogItemsFromStorage: function getLogItemsFromStorage() {
      return this.api.localStorage.getItem(this.LOG_KEY);
    },
    /**
     * This removes the received logObject from Local Storage and then persists it back.
     * @method removeLogItemFromStorage
     * @public
     *
     * @param {Object} logObject
     */
    removeLogItemFromStorage: function removeLogItemFromStorage(logObject) {
      var logsArray = this.api.localStorage.getItem(this.LOG_KEY);
      if (logsArray) {
        //remove the received logObject from the array if it exists by comparing the id
        logsArray = logsArray.filter(function (eachLogObj) {
          return eachLogObj.id !== logObject.id;
        });
        this.api.localStorage.setItem(this.LOG_KEY, logsArray);
      }
    },
    sendLimitedErrorEvent: function sendLimitedErrorEvent() {
      var eventData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var apiErrorIncrement = this.get("api.storage.store.apiErrorIncrements.".concat(eventData.event));
      if (apiErrorIncrement >= 20) return;
      this.sendErrorEvent(eventData);
    },
    sendErrorEvent: function sendErrorEvent() {
      var _this3 = this;
      var eventData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _getPstTime2 = getPstTime(),
        dateTime = _getPstTime2.dateTime,
        timestamp = _getPstTime2.timestamp;
      Object.assign(eventData, {
        client_date_time: dateTime,
        client_timestamp: timestamp
      });
      var detailString = stringifyObject(eventData);
      var logObject = {
        level: 'error',
        detail: detailString,
        agentId: this.api.get('agent').userId
      };
      return this.get('api.adapter').agentLogging(logObject).catch(function (error) {
        //TODO: Handle this appropriately after RTDEV-28447 is implemented
        if (error) error.alreadyThrown = true;
      }).finally(function () {
        if (!_this3.get("api.storage.store.apiErrorIncrements.".concat(eventData.event))) {
          _this3.set("api.storage.store.apiErrorIncrements.".concat(eventData.event), 1);
        } else {
          _this3.incrementProperty("api.storage.store.apiErrorIncrements.".concat(eventData.event));
        }
        _this3.get('api.storage').persist();
      });
    }
  });
  function stringifyObject(object) {
    return Object.keys(object).map(function (key) {
      return "".concat(key, "=").concat(object[key]);
    }).join(';; ') + ';;';
  }
  function getPstTime() {
    var pstDate = (0, _moment.default)().tz('America/Los_Angeles');
    return {
      dateTime: pstDate.format('YYYY-MM-DD HH:mm:ss.SSS ZZ'),
      timestamp: pstDate.valueOf()
    };
  }
});