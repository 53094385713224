define("sdk/components/child-modal/modal-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UAshakG4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"h5\"],[10,\"class\",\"child-modal__title\"],[8],[13,1],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"hasCloseButton\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[10,\"class\",\"child-modal__close-button\"],[11,\"aria-label\",[26,\"t\",[\"modal.close\"],null],null],[10,\"data-test-close-button\",\"\"],[10,\"type\",\"button\"],[3,\"action\",[[21,0,[]],\"onClickCloseButton\"]],[8],[0,\"\\n\\n    \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[\"CloseOutlined\"]]],false],[0,\"\\n\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/child-modal/modal-header/template.hbs"
    }
  });
});