define("sdk/components/sdk-scripts/script/component", ["exports", "sdk/core/component", "sdk/components/sdk-scripts/script/template", "sdk/api/utils/rich-widgets"], function (_exports, _component, _template, _richWidgets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'script',
    classNameBindings: ['disabled', 'script.type'],
    attributeBindings: ['tooltip:title', 'unselectable'],
    'data-test-script': '',
    unselectable: 'on',
    intl: Ember.inject.service(),
    currentScriptGroupId: Ember.computed.alias('scriptGroup.id'),
    disabled: Ember.computed('script.{value,command}', 'lastSentMessage', 'lastSentMessageFailed', 'screeningMode', function () {
      if (this.get('screeningMode')) return true;
      if (this.get('lastSentMessageFailed')) return false;
      var _this$get = this.get('script'),
        command = _this$get.command,
        value = _this$get.value,
        richWidget = _this$get.richWidget;
      var _ref = this.get('lastSentMessage') || {},
        lastSentCommand = _ref.command,
        lastSentMessageText = _ref.messageText,
        lastSentMessageData = _ref.messageData;
      var isLastSentRichWidget = richWidget && JSON.stringify((0, _richWidgets.getMessageDataFromRichWidget)(richWidget)) === JSON.stringify(lastSentMessageData);
      return isLastSentRichWidget || value === lastSentMessageText && command === lastSentCommand;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // IE: add unselectable attribute to all children elements since unselectable is not inherited
      var isIE = /MSIE\s|Trident\//.test(navigator.userAgent); // MSIE: IE < 11, Trident: IE 11
      if (isIE) {
        this.$('*').attr('unselectable', 'on');
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var showHotkey = this.get('showHotkey');
      if (showHotkey) {
        this.set('script.showHotkey', true);
      } else {
        this.set('script.showHotkey', false);
      }
    },
    tooltip: Ember.computed('disabled', 'suppressed', 'script.valueJSON', function () {
      if (this.get('disabled') || this.get('suppressed')) {
        return this.get('intl').t('sdk-scripts.unavailable');
      }
      return;
    }),
    actions: {
      /**
      * When a script line is selected
      * Input - Opens input-script component
      * Text - Sends message
      * Command - Sends command
      *
      * various events are considered when a script is selected:
      * Single Click Setting
      * - single click: send as message
      * - shift + click: send to agent input
      *
      * Double Click Setting
      * - single click: send to agent input
      * - double click: send as message
      * @method selectScript
      */
      selectScript: function selectScript(script, event) {
        if (this.get('suppressed') || this.get('unclickable')) return;
        var shiftKey = event.shiftKey,
          customType = event.customType;
        var eventType = event.type;
        if (customType) eventType = customType;
        var clickSetting = this.get('api.agent.settings.clicking');
        if (clickSetting === 'doubleClick' && eventType === 'click' && shiftKey) return;
        var originalScriptText = script.get('scriptValue');
        this.set('originalScriptText', originalScriptText);

        // shift key or single-click (when click method is set to double-click)
        var isGoingToAgentInputClick = eventType === 'click' && (clickSetting === 'singleClick' && shiftKey || clickSetting === 'doubleClick');
        // shift+space or shift+enter for non input script
        var isGoingToAgentInputKeydown = shiftKey && eventType === 'keydown' && !script.get('hasInput');
        var isGoingToAgentInput = isGoingToAgentInputClick || isGoingToAgentInputKeydown;
        if (isGoingToAgentInput) {
          this.set('toAgentInput', true);
        } else if (this.get('disabled')) return;
        if (script.get('hasInput') && !isGoingToAgentInput) {
          script.toggleProperty('isVisibleInputScriptWindow');
        } else {
          this.passScript(script);
        }
      },
      sendInputScript: function sendInputScript(inputScript) {
        var _event = event,
          shiftKey = _event.shiftKey;
        shiftKey && this.set('toAgentInput', true);
        this.passScript(inputScript);
      },
      setFocusToFirstScriptLine: function setFocusToFirstScriptLine() {
        var _this = this;
        Ember.run.next(this, function () {
          return _this.$('.nuance-a11y-button-script').focus();
        });
      }
    },
    /**
     * Sends script to customer
     * @event passScript
     * @param script
     */
    passScript: function passScript(script) {
      var options = this.getProperties('toAgentInput');
      this.setProperties({
        toAgentInput: false
      });
      script.set('originalScriptText', this.get('originalScriptText'));
      var onSendScript = this.get('sendScript');
      onSendScript && onSendScript(script, options);
      Ember.$('.ql-editor').attr('aria-label', "script sent, ".concat(script.scriptValue, ", type your message"));
    }
  });
});