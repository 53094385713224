define("sdk/lockout/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      authenticate: function authenticate(username, password) {
        return this.authenticate({
          username: username,
          password: password
        });
      }
    },
    authenticate: function authenticate() {
      var _this = this;
      var credentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var username = credentials.username,
        password = credentials.password;
      return this.get('api.authAdapter').authenticate({
        username: username,
        password: password
      }).then(function (authResponse) {
        return _this.get('session').authenticate('authenticator:tc', {
          username: username,
          authResponse: authResponse
        }, {
          shouldRestoreSession: true
        });
      }).then(function () {
        _this.get('api.agent').unlock();
        var routeBeforeLockout = _this.get('api.storage.store.previousRoute') || 'site.summary';
        var isEngagementRoute = routeBeforeLockout === 'site.engagements.engagement';
        if (isEngagementRoute) {
          var activeEngagementId = _this.get('api.storage.store.activeEngagementId');
          _this.transitionToRoute(routeBeforeLockout, activeEngagementId);
        } else {
          _this.transitionToRoute(routeBeforeLockout);
        }
      });
    }
  });
});