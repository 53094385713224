define("sdk/components/checkbox-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Yh8FjS6w",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[1,[26,\"react-Checkbox\",null,[[\"label\",\"checked\",\"shouldSkipFocus\",\"testId\",\"onChange\"],[[22,[\"label\"]],[22,[\"checked\"]],[22,[\"shouldSkipFocus\"]],\"checkbox-select\",[26,\"action\",[[21,0,[]],\"toggleCheckbox\"],null]]]],false],[0,\"\\n\\n\"],[4,\"menu-power-select\",null,[[\"options\",\"selected\",\"class\",\"onchange\",\"tagName\",\"shouldSkipFocus\",\"searchEnabled\",\"renderInPlace\"],[[22,[\"options\"]],[22,[\"selectedOption\"]],\"options-select\",[26,\"action\",[[21,0,[]],\"selectOption\"],null],\"div\",[22,[\"shouldSkipFocus\"]],false,true]],{\"statements\":[[0,\"\\n  \"],[1,[21,1,[]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/checkbox-select/template.hbs"
    }
  });
});