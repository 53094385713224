define("sdk/components/engagement-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BEa1LPQr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"muiIconName\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[[22,[\"muiIconName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,[\"hasSVG\"]]],null,{\"statements\":[[0,\"  \"],[6,\"img\"],[11,\"src\",[27,[[26,\"concat\",[\"assets/images/svg/signal-\",[22,[\"chatType\"]],\".svg\"],null]]]],[10,\"class\",\"engagement-icon__svg\"],[10,\"data-test-engagement-icon\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[\"ChatBubbleOutlined\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[22,[\"dvvIconName\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"engagement-icon--dvv\"],[8],[0,\"\\n    \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[[22,[\"dvvIconName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/engagement-icon/template.hbs"
    }
  });
});