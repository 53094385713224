define("sdk/components/internal-chat/component", ["exports", "sdk/core/component", "sdk/components/internal-chat/template", "ember-keyboard", "sdk/utils/speak"], function (_exports, _component, _template, _emberKeyboard, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'internal-chat',
    keyboardActivated: true,
    isMinimized: Ember.computed.alias('engagement.ui.internalChatIsMinimized'),
    expandedPosition: Ember.computed.alias('engagement.ui.internalChatPosition'),
    minimizedPosition: Ember.computed.alias('engagement.ui.internalChatMinimizedPosition'),
    readInternalMessageCount: Ember.computed.alias('engagement.readInternalMessageCount'),
    windowSize: Ember.inject.service(),
    //window height before resize
    oldHeightWindow: Ember.computed.alias('engagement.oldHeightWindow'),
    //window width before resize
    oldWidthWindow: Ember.computed.alias('engagement.oldWidthWindow'),
    isConnected: Ember.computed('engagement.{connected,isOtherAgentConnected,isSupervisorConnected}', function () {
      return this.get('engagement.connected') && (this.get('engagement.isOtherAgentConnected') || this.get('engagement.isSupervisorConnected'));
    }),
    internalMessages: Ember.computed.filterBy('engagement.messages', 'isInternal', true),
    classNameBindings: ['isMinimized'],
    onAccessKeyPress: Ember.on((0, _emberKeyboard.keyDown)('shift+ctrl+KeyX'), function () {
      if (this.get('isMinimized')) {
        this.send('toggleMinimized');
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('oldHeightWindow', Ember.$(window).height());
      this.set('oldWidthWindow', Ember.$(window).width());
      this.get('windowSize').on('resize', this, this.updatePosition);
      (0, _speak.default)("".concat(this.get('intl').t('internal-chat.how-to-close')));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.get('engagement.ui.internalTranscriptListRenderOptions')) {
        this.set('engagement.ui.internalTranscriptListRenderOptions', Ember.Object.create());
      }
      this.set('readInternalMessageCount', this.getInternalMessageCount());
      Ember.run.schedule('afterRender', function () {
        if (_this.get('isMinimized')) {
          _this._restoreMinimized();
        } else {
          // initial position
          if (!_this.get('expandedPosition')) {
            _this.set('expandedPosition', _this._getBottomRightPosition());
          } else {
            var windowHeight = Ember.$(window).height();
            var windowWidth = Ember.$(window).width();
            var shiftTop = windowHeight - _this.get('oldHeightWindow');
            var shiftLeft = windowWidth - _this.get('oldWidthWindow');
            _this.set('minimizedPosition', {
              top: _this.get('minimizedPosition.top') + shiftTop,
              left: shiftLeft + _this.get('minimizedPosition.left')
            });
            _this.set('expandedPosition', {
              top: shiftTop + _this.get('expandedPosition.top'),
              left: shiftLeft + _this.get('expandedPosition.left')
            });
          }
          _this.set('oldHeightWindow', Ember.$(window).height());
          _this.set('oldWidthWindow', Ember.$(window).width());
          _this._restoreExpanded();
        }
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.$().draggable({
        handle: '.handle',
        containment: 'parent',
        cancel: 'input,textarea,button:not(.handle),select,option',
        drag: function drag(event, ui) {
          if (_this2.get('isMinimized')) {
            _this2.set('minimizedPosition', ui.position);
          } else {
            _this2.set('expandedPosition', ui.position);
          }
        }
      });
    },
    unreadInternalMessageCount: Ember.computed('internalMessages', 'readInternalMessageCount', function () {
      if (!this.get('isMinimized')) return;
      return this.getInternalMessageCount() - this.get('readInternalMessageCount');
    }),
    unreadInternalMessageCountObserver: Ember.observer('unreadInternalMessageCount', function () {
      var unreadMessageCount = this.get('unreadInternalMessageCount');
      if (unreadMessageCount > 0) {
        var internalChat = this.get('intl').t('internal-chat.internal-chat');
        var expandInternalChat = this.get('intl').t('internal-chat.expand-internal-chat');
        (0, _speak.default)("".concat(internalChat, ", ").concat(unreadMessageCount, " ").concat(expandInternalChat));
      }
    }),
    getInternalMessageCount: function getInternalMessageCount() {
      return this.get('internalMessages.length');
    },
    /**
     * Updates internal-chat position if window was resized.
     * @method updatePosition
     */
    updatePosition: function updatePosition() {
      var windowHeight = Ember.$(window).height();
      var windowWidth = Ember.$(window).width();
      var navigationHeight = Ember.$('#app-header__1').height() + Ember.$('#app-header__2').height();
      var shiftTop = windowHeight - this.get('oldHeightWindow');
      var shiftLeft = windowWidth - this.get('oldWidthWindow');
      var minimizedTop = this.get('minimizedPosition.top');
      var expandedTop = this.get('expandedPosition.top');
      this.set('minimizedPosition', {
        top: minimizedTop === navigationHeight ? minimizedTop : minimizedTop + shiftTop,
        left: shiftLeft + this.get('minimizedPosition.left')
      });
      this.set('expandedPosition', {
        top: expandedTop === navigationHeight ? expandedTop : shiftTop + expandedTop,
        left: shiftLeft + this.get('expandedPosition.left')
      });
      if (this.get('isMinimized')) {
        this._restoreMinimized();
      } else {
        this._restoreExpanded();
      }
      this.set('oldHeightWindow', windowHeight);
      this.set('oldWidthWindow', windowWidth);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('windowSize').off('resize', this, this.updatePosition);
      if (this.$().draggable('instance')) {
        this.$().draggable('destroy');
      }
    },
    actions: {
      toggleMinimized: function toggleMinimized() {
        var _this3 = this;
        this.toggleProperty('isMinimized');
        if (this.get('isMinimized')) {
          // snap to bottom right on minmize
          Ember.run.schedule('afterRender', function () {
            _this3.set('minimizedPosition', _this3._getBottomRightPosition());
            _this3._restoreMinimized();
          });
        } else {
          this._restoreExpanded();
          Ember.run.scheduleOnce('afterRender', function () {
            _this3.get('api').trigger('focusInternalChatInput');
          });
        }
        this.set('readInternalMessageCount', this.getInternalMessageCount());
      },
      close: function close() {
        this.get('engagement').state.set({
          isConferenceMode: false
        });
        this.get('api').trigger('internalClosed', this.get('engagement'));
      }
    },
    _restoreExpanded: function _restoreExpanded() {
      this._setPosition(this.get('expandedPosition'));
      var hasNewMessages = !!this.get('unreadInternalMessageCount');
      if (hasNewMessages) {
        this.incrementProperty('scrollToBottomIncrement');
      }
    },
    _restoreMinimized: function _restoreMinimized() {
      var _this4 = this;
      this._setPosition(this.get('minimizedPosition'));
      Ember.run.scheduleOnce('afterRender', function () {
        _this4.$('#internal-chat-minimized-button').focus();
      });
    },
    _getBottomRightPosition: function _getBottomRightPosition() {
      return {
        top: this.$(window).height() - this.$().height(),
        left: this.$(window).width() - this.$().width()
      };
    },
    _setPosition: function _setPosition(position) {
      var top = position.top,
        left = position.left;
      if (top < 0) top = 0;
      if (left < 0) left = 0;
      this.$().css({
        top: top,
        left: left
      });
    }
  });
});