define("sdk/services/web-tool-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    isResizing: false,
    toggleIsResizing: function toggleIsResizing() {
      this.toggleProperty('isResizing');
    }
  });
});