define("sdk/sso/logout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xCY6nqN/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"logoutErrorMessage\"]]],null,{\"statements\":[[0,\"  \"],[6,\"p\"],[8],[1,[20,\"logoutErrorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"p\"],[8],[1,[26,\"t\",[\"sso.logout-message\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"link-to\",[\"sso.login\"],[[\"tagName\"],[\"button\"]],{\"statements\":[[0,\"  \"],[1,[26,\"t\",[\"sso.relogin\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/sso/logout/template.hbs"
    }
  });
});