define("sdk/utils/get-focusable-nodes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getFocusableNodes;
  /**
   * Allows the screenreader to register text that needs to be read to the user.
   * @method speak
   * @param {String} selector css selector
   * @param {String} [ariaLiveValue="polite"] aria live value
   */
  function getFocusableNodes() {
    var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var isGlobal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var focusableSelector = 'input:not([tabindex="-1"]),textarea:not([tabindex="-1"]),select:not([tabindex="-1"]),button:not([disabled]):not([tabindex="-1"]),[tabindex="0"],[contenteditable="true"]';
    if (isGlobal) {
      return Ember.$(selector).find(focusableSelector);
    }
    if (this.$(selector)) {
      return this.$(selector).find(focusableSelector);
    }
    return Ember.$();
  }
});