define("sdk/api/models/customer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storageProperties = ['browser', 'browserVersion', 'browsingHistory', 'connected', 'isRepeatChatter', 'isTyping', 'locale', 'operatingSystemType', 'os', 'pageMarker'];
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    id: Ember.computed.alias('engagement.settings.clientId'),
    name: Ember.computed.alias('engagement.settings.clientName'),
    deviceType: Ember.computed.alias('engagement.settings.deviceType'),
    onDisconnect: Ember.observer('connected', function () {
      if (this.get('connected')) return;
      this.trigger('disconnected');
    }),
    setBrowsingHistory: function setBrowsingHistory() {
      if (!this.get('_browsingHistory').length) return;
      var browsingHistory = this.get('_browsingHistory');
      var lastSeen = browsingHistory.get('firstObject');
      this.set('browsingHistory', browsingHistory.filter(function (curr, i) {
        if (i === 0) return curr;
        if (curr === lastSeen) return;
        lastSeen = curr;
        return curr;
      }));
    },
    _setState: function _setState(name, value) {
      var aliases = {
        OS: 'os',
        'Page Markers': 'browsingHistory'
      };
      if (name) {
        name = aliases[name] || Ember.String.camelize(name);
      }
      if (name === 'browsingHistory') {
        value = value.split(/,\s+/).reverseObjects();
      } else if (name === 'disconnectReason') {
        value = Ember.String.dasherize(value);
      } else if (String(value).match(/^(true|false)$/i)) {
        value = String(value).toLowerCase() === 'true';
      }
      if (name === 'browsingHistory') {
        this.get('_browsingHistory').pushObjects(value);
        this.setBrowsingHistory();
      } else if (name === 'pageMarker') {
        this.get('_browsingHistory').pushObject(value);
        this.setBrowsingHistory();
      } else if (name) {
        this.set(name, value);
      }
      this.engagement && this.engagement.state.set({
        customerData: this.getProperties(storageProperties)
      });
    },
    toJSON: function toJSON() {
      var copy = Ember.assign({}, this);
      delete copy.engagement;
      copy.rule = this.rule.toJSON();
      return copy;
    }
  });
});