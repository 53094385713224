define("sdk/components/static-transcript-window/history/component", ["exports", "sdk/core/component", "sdk/components/static-transcript-window/history/template", "moment"], function (_exports, _component, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'static-transcript-window-history',
    selectedTranscriptOption: Ember.computed('transcriptOptions', function () {
      var _this = this;
      var transcriptOptions = this.get('transcriptOptions');
      return transcriptOptions && transcriptOptions.find(function (transcriptOption) {
        return transcriptOption.transcript.id === _this.get('activeTranscript.id');
      });
    }),
    transcriptOptions: Ember.computed('engagement.transcripts', 'intl.locale', function () {
      var currentTranscriptOption = {
        engagementID: this.get('engagement.id'),
        label: this.get('intl').t('static-transcript.current'),
        transcript: this.get('engagement')
      };
      var previousTranscriptOptions = this.get('engagement.transcripts').map(function (transcript) {
        var engagementID = transcript.engagementID,
          startDate = transcript.startDate;
        return {
          engagementID: engagementID,
          label: (0, _moment.default)(startDate.iso).format('D-MMM-YYYY, h:mm A'),
          transcript: transcript
        };
      });
      return [currentTranscriptOption].concat(previousTranscriptOptions);
    }),
    intl: Ember.inject.service(),
    ui: Ember.computed.alias('engagement.ui'),
    actions: {
      /**
       * update transcript in window
       * @method selectTranscript
       */
      selectTranscript: function selectTranscript(transcriptOption) {
        var transcript = transcriptOption.transcript;
        var engagementId = transcript.id || transcript.engagementID;
        this.engagement.loadChatTranscript(transcript);
        this.set('activeTranscript', transcript);
        this.set('selectedTranscriptOption', transcriptOption);
        this.set('ui.lastSelectedTranscriptID', engagementId);
      }
    }
  });
});