define("sdk/api/models/core/select-item", ["exports", "sdk/api/models/core/object-proxy"], function (_exports, _objectProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class SelectItem
   * @extends Ember.ObjectProxy
   * @uses Ember.Evented
   * @public
   */
  var _default = _exports.default = _objectProxy.default.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      ['toggle', 'select', 'deselect'].forEach(function (fn) {
        return _this[fn] = _this['_' + fn].bind(_this);
      });
      this._itemMeta = this._itemMeta || Ember.Object.create({
        selected: this._defaultSelectedState || false
      });
      this.addObserver('selected', function () {
        var eventName = _this.get('selected') ? 'selected' : 'deselected';
        _this.trigger(eventName, _this);
      });
    },
    /**
     * @property _defaultSelectedState
     * @private
     * @type {Boolean}
     */
    _defaultSelectedState: false,
    /**
     * The current selected state for the select item
     * @property selected
     * @type {Boolean}
     * @public
     */
    selected: Ember.computed.alias('_itemMeta.selected'),
    /**
     * Puts item in a selected state and triggers `selected`
     * event if item state not already selected.
     * @method select
     * @public
     */
    _select: function _select() {
      this.set('selected', true);
    },
    /**
     * Puts item in a deselected state and triggers `deselected`
     * event if item state not already deselected.
     * @method deselect
     * @public
     */
    _deselect: function _deselect() {
      this.set('selected', false);
    },
    /**
     * Toggles the selected state, and fires either the `selected`
     * or `deselected` event
     * @method toggle
     * @public
     */
    _toggle: function _toggle() {
      this.toggleProperty('selected');
    },
    /**
     * Resets the item to the default selection state. Default state
     * is normally `false` unless item created with:
     * ```js
     * const item = SelectItem.create({selected: false});
     * ```
     * Triggers the `reset` event
     * @method reset
     * @public
     */
    reset: function reset() {
      this.set('selected', this._defaultSelectedState);
      this.trigger('reset');
    }
    /**
     * @event selected
     * @public
     */
    /**
    * @event deselected
    * @public
    */
    /**
     * Triggered when item is reset to the default selection state
     * @event reset
     * @public
     */
  }).reopenClass({
    create: function create() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (options.hasOwnProperty('selected')) {
        options._defaultSelectedState = options.selected;
        delete options.selected;
      }
      return this._super(options);
    }
  });
});