define("sdk/components/x-tabs/content/component", ["exports", "sdk/core/component", "sdk/components/x-tabs/content/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'x-tabs-content',
    attributeBindings: ['role'],
    role: 'application',
    keyDown: function keyDown() {
      var isMKey = event.keyCode === 77;
      var onKeyDownA11yHotKey = this.get('onKeyDownA11yHotKey');
      if (event.ctrlKey && event.altKey && isMKey && onKeyDownA11yHotKey) {
        onKeyDownA11yHotKey();
      }
    }
  });
});