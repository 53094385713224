define("sdk/components/engagement-tabs/component", ["exports", "sdk/components/engagement-tabs/template", "sdk/core/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'engagement-tabs',
    routing: Ember.inject.service('-routing'),
    'data-test-engagement-tabs': '',
    classNameBindings: ['isEngagementRoute:focused-On-Engagement'],
    freezeChatTabPositionByDefault: Ember.computed.reads('agent.settings.freezeChatTabPositionByDefault'),
    engagements: Ember.computed.reads('agent.engagements'),
    isEngagementRoute: Ember.computed.equal('routing.router.currentRouteName', 'site.engagements.engagement'),
    positionedEngagementIds: Ember.computed.alias('agent.ui.positionedEngagementIds'),
    positionedEngagements: Ember.computed('engagements.[]', 'positionedEngagementIds.@each', function () {
      var _this = this;
      var engagementIds = this.get('engagements').mapBy('id');
      var positionedEngagementIds = this.get('positionedEngagementIds');
      positionedEngagementIds.forEach(function (positionedEngagementId, index) {
        if (!positionedEngagementId) return;
        if (!engagementIds.any(function (engagementId) {
          return engagementId === positionedEngagementId;
        })) {
          return positionedEngagementIds.replace(index, 1, [null]);
        }
      });

      // get new engagement ids
      var newEngagementIds = engagementIds.filter(function (engagementId) {
        return !positionedEngagementIds.includes(engagementId);
      });

      // add new engagement ids to positionedEngagementIds
      newEngagementIds.forEach(function (newEngagementId) {
        var indexOfFirstNullPosition = positionedEngagementIds.indexOf(null);
        if (indexOfFirstNullPosition !== -1) {
          positionedEngagementIds.replace(indexOfFirstNullPosition, 1, [newEngagementId]);
        } else {
          positionedEngagementIds.pushObject(newEngagementId);
        }
      });
      return positionedEngagementIds.map(function (engagementId) {
        return engagementId ? _this.get('engagements').findBy('id', engagementId) : null;
      });
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.get('agent').persist();
    }
  });
});