define("sdk/utils/regex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.escapeRegex = escapeRegex;
  _exports.namedEntityPlaceholderRegEx = void 0;
  /**
   * Escapes a regex string
   * Ex: "(" => "\("
   * @method escapeRegex
   * @param {String} string regex string to escape
   */
  function escapeRegex(string) {
    return string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  }
  var namedEntityPlaceholderRegEx = _exports.namedEntityPlaceholderRegEx = /__([a-zA-Z]+(_[a-zA-Z]+)+)__/g;
});