define("sdk/api/adapters/web-rtc-adapter", ["exports", "sdk/api/adapters/api-adapter"], function (_exports, _apiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class WebRTCAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    name: 'webRTC',
    addAgent: function addAgent() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var customerId = options.customerId,
        endpointUrl = options.endpointUrl,
        engagementId = options.engagementId,
        roomName = options.roomName,
        roomPin = options.roomPin,
        siteId = options.siteId,
        url = options.url;
      return this.ajax({
        url: "".concat(url, "/room/addagent"),
        method: 'post',
        data: {
          agentId: this.get('api.agent.userId'),
          customerId: customerId,
          endpointUrl: endpointUrl,
          engagementId: engagementId,
          platformName: 'janus',
          roomName: roomName,
          roomPin: roomPin,
          siteId: siteId
        }
      }).then(function (roomDetails) {
        if (!roomDetails) return;
        roomDetails.opaqueId = "".concat(roomName, ",").concat(roomDetails.agentName);
        return roomDetails;
      });
    },
    /**
     * @method createWebRTCCredentials
     * @param options
     * @public
     */
    createWebRTCCredentials: function createWebRTCCredentials() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var agentGroupId = options.agentGroupId,
        businessUnitId = options.businessUnitId,
        customerId = options.customerId,
        engagementId = options.engagementId,
        siteId = options.siteId,
        url = options.url;
      return this.ajax({
        url: "".concat(url, "/room/create"),
        method: 'post',
        data: {
          platformName: 'janus',
          agentId: this.get('api.agent.userId'),
          agentGroupId: agentGroupId,
          businessUnitId: businessUnitId,
          customerId: customerId,
          engagementId: engagementId,
          siteId: siteId
        }
      }).then(function (roomDetails) {
        if (!roomDetails) return;
        roomDetails.opaqueId = "".concat(roomDetails.roomName, ",").concat(roomDetails.agentName);
        return roomDetails;
      });
    }
  });
});