define("sdk/services/devices", ["exports", "sdk/api/utils/media-devices"], function (_exports, _mediaDevices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),
    showDeviceSettings: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('settingsLoaded', this, this.setupDevices);
    },
    setupDevices: function setupDevices() {
      var _this = this;
      var isVideoCallEnabled = this.get('api.settings.videoCallEnabled');
      var isVoiceCallEnabled = this.get('api.settings.voiceCallEnabled');
      if (!isVideoCallEnabled && !isVoiceCallEnabled || (0, _mediaDevices.isMediaUnsupported)()) return;
      var _this$get = this.get('api.localStorage.store'),
        audioDeviceId = _this$get.audioDeviceId,
        micDeviceId = _this$get.micDeviceId,
        videoDeviceId = _this$get.videoDeviceId;
      return (0, _mediaDevices.initializePermissions)({
        audioDeviceId: audioDeviceId,
        micDeviceId: micDeviceId,
        videoDeviceId: videoDeviceId
      }).then(function (stream) {
        _this.set('api.agent.isDevicePermissionGranted', true);
        var defaultMicDeviceId = (0, _mediaDevices.getMicrophoneDeviceIdFromStream)(stream);
        var defaultVideoDeviceId = (0, _mediaDevices.getVideoDeviceIdFromStream)(stream);
        _this.get('api.localStorage').setItem('micDeviceId', defaultMicDeviceId);
        _this.get('api.localStorage').setItem('videoDeviceId', defaultVideoDeviceId);
        return Ember.RSVP.hash({
          devicesAudio: (0, _mediaDevices.getDevicesByType)('audiooutput'),
          devicesMic: (0, _mediaDevices.getDevicesByType)('audioinput'),
          devicesVideo: (0, _mediaDevices.getDevicesByType)('videoinput'),
          selectedAudioDevice: (0, _mediaDevices.getDeviceById)('audiooutput', audioDeviceId),
          selectedMicDevice: (0, _mediaDevices.getDeviceById)('audioinput', defaultMicDeviceId),
          selectedVideoDevice: (0, _mediaDevices.getDeviceById)('videoinput', defaultVideoDeviceId)
        }).then(function (hashResult) {
          Object.entries(hashResult).forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
              key = _ref2[0],
              value = _ref2[1];
            _this.get('api.agent').set(key, value);
          });
          (0, _mediaDevices.stopStream)(stream);
          _this.get('api').trigger('devicesSetup');
        });
      });
    },
    checkoutVideoDevice: function checkoutVideoDevice() {
      var deviceId = this.get('api.agent.selectedVideoDevice.deviceId');
      return (0, _mediaDevices.getVideoStreamById)(deviceId);
    },
    setShowDeviceSettings: function setShowDeviceSettings(showDeviceSettings) {
      this.set('showDeviceSettings', showDeviceSettings);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api').off('settingsLoaded', this, this.setupDevices);
    }
  });
});