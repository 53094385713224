define("sdk/site/follow-up/controller", ["exports", "sdk/utils/speak"], function (_exports, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    followUpConversations: Ember.computed.alias('model'),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api.agent').on('retrievedFollowUps', this, this._onRetrievedFollowUps);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api.agent').off('retrievedFollowUps', this, this._onRetrievedFollowUps);
    },
    actions: {
      onFollowUpSelected: function onFollowUpSelected(id) {
        var selectedFollowUpId = this.get('selectedFollowUpId');
        var newSelectedFollowUpId = selectedFollowUpId !== id ? id : null;
        this.set('selectedFollowUpId', newSelectedFollowUpId);
      },
      /**
       * Used to load an engagement to follow up.
       * @method startFollowUpEngagement
       * @public
       * @param {String} conversationId the conversation id to load (if empty, uses selectedFollowUpId)
       */
      startFollowUpEngagement: function startFollowUpEngagement(conversationId) {
        var selectedFollowUpId = this.get('selectedFollowUpId');
        if (!conversationId && !selectedFollowUpId) return;
        if (!conversationId) {
          conversationId = selectedFollowUpId;
        }
        this.get('api.agent').startFollowUpEngagement(conversationId);
        this.set('selectedFollowUpId', null);
      }
    },
    _onRetrievedFollowUps: function _onRetrievedFollowUps(conversations) {
      // If the previous and current follow up conversations are different, update
      // the follow up conversations. This reduces the frequency of reconstructed nodes.
      var previousConversationIds = this.get('followUpConversations').mapBy('conversationId');
      var conversationIds = conversations.mapBy('conversationId');

      // If there are no previous conversations or the array lengths are different,
      // update follow up conversations.
      var shouldUpdateModel = !previousConversationIds || previousConversationIds.length !== conversationIds.length;

      // Verify the previous and current conversation IDs are the same. If not,
      // update follow up conversations.
      if (!shouldUpdateModel) {
        shouldUpdateModel = !!previousConversationIds.find(function (previousConversationId) {
          return !conversationIds.includes(previousConversationId);
        });
      }
      if (shouldUpdateModel) {
        // If the focus is set on a conversation in the follow up table, move
        // the focus to the table element.
        var followUpTableSelector = '#follow-up-engagement-table';
        var conversationIndex = Ember.$("".concat(followUpTableSelector, " button")).index(document.activeElement);
        if (conversationIndex > -1) {
          Ember.$(followUpTableSelector).focus();
        }
        this.set('followUpConversations', conversations);
        (0, _speak.default)("".concat(this.get('intl').t('follow-up.updated')));
      }
      var selectedFollowUpId = this.get('selectedFollowUpId');
      if (conversations.length && selectedFollowUpId) {
        var conversation = conversations.findBy('conversationId', selectedFollowUpId);
        if (!conversation) {
          this.set('selectedFollowUpId', null);
        }
      }
    }
  });
});