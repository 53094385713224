define("sdk/components/a11y-button/component", ["exports", "sdk/core/component", "sdk/components/a11y-button/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'a11y-button',
    tagName: 'button',
    classNames: ['a11y-button'],
    attributeBindings: ['disabled', 'aria-label', 'role', 'tabindex', 'title', 'type'],
    classNameBindings: ['onSubmit::a11y-button--no-submit', 'unelevated:a11y-button--unelevated'],
    'data-test-a11y-button': '',
    keyDown: function keyDown(event) {
      var keyCode = event.keyCode;
      switch (keyCode) {
        case 13: // Enter
        case 32:
          // Space
          this._submit(event);
          event.preventDefault();
      }
    },
    keyUp: function keyUp(event) {
      // FF: prevent additional 'click' event
      event.preventDefault();
    },
    click: function click(event) {
      this._submit(event);
    },
    /**
     * Button submit occurs on...
     * - click event
     * - Space keydown
     * - Enter keydown
     * Note: submit does not occur if CTRL is pressed
     * @method _submit
     * @param {*} e event
     */
    _submit: function _submit(e) {
      e = e || event;
      var _event = event,
        ctrlKey = _event.ctrlKey;
      if (ctrlKey) return;
      var onSubmit = this.get('onSubmit');
      onSubmit && onSubmit(e);
    }
  });
});