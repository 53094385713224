define("sdk/components/static-transcript-window/component", ["exports", "sdk/core/component", "sdk/components/static-transcript-window/template", "sdk/utils/is-element-visible", "sdk/api/utils/runloop", "sdk/utils/speak", "sdk/utils/math"], function (_exports, _component, _template, _isElementVisible, _runloop, _speak, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'static-transcript-window',
    classNames: ['transcript-window'],
    activeTranscriptMessages: Ember.computed('activeTranscript.messages.@each.hasFailedToSend', function () {
      var messages = this.get('activeTranscript.messages') || [];
      return messages.rejectBy('hasFailedToSend');
    }),
    activeTranscriptId: Ember.computed('activeTranscript', function () {
      return this.get('activeTranscript.id') || this.get('activeTranscript.engagementID');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.selectActiveMatch = this.selectActiveMatch.bind(this);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('activeTranscript', this._getLastSelectedTranscript());
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      var activeMatch = this.get('activeMatch');

      // update numberOfMatches
      this.set('isActiveFilter', true);
      this.notifyPropertyChange('filter');
      (0, _runloop.later)(function () {
        if (!_this.get('isDestroyed') || !_this.get('isDestroying')) {
          _this.setActiveMatch(activeMatch);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.get('speakFilterDebounceId'));
      Ember.run.cancel(this.get('speakLineDebounceId'));
    },
    filter: Ember.computed.alias('engagement.ui.staticTranscriptFilter'),
    activeMatch: Ember.computed.alias('engagement.ui.activeMatch'),
    lastSelectedTranscriptID: Ember.computed.alias('engagement.ui.lastSelectedTranscriptID'),
    filterTranscript: Ember.observer('filter', 'activeTranscriptMessages.@each.maskedText', function () {
      var _this2 = this;
      // prevent filtering transcript until selected activeTranscript is not loaded
      if (this.get('activeTranscriptId') !== this.get('lastSelectedTranscriptID')) return;
      var filter = Ember.get(this, 'filter') || '';
      if (!filter) {
        // if filter is empty but it was not empty before - remove wrapping <span class="match...> for each line
        if (!this.get('isActiveFilter')) return;
        this.set('isActiveFilter', false);
      } else {
        this.set('isActiveFilter', true);
      }
      var staticTranscriptLines = Ember.get(this, 'activeTranscriptMessages');
      var matchIndex = 0;
      staticTranscriptLines.forEach(function (transcriptLine) {
        var hasSearchMatch = transcriptLine.searchAndReplace(filter, "<span class=\"match\" data-match-index=\"0\">$&</span>");
        if (hasSearchMatch) {
          var _transcriptLine$getPr = transcriptLine.getProperties('searchedPrefix', 'searchedMessageAttributes', 'searchedMessageHTML'),
            searchedPrefix = _transcriptLine$getPr.searchedPrefix,
            searchedMessageAttributes = _transcriptLine$getPr.searchedMessageAttributes,
            searchedMessageHTML = _transcriptLine$getPr.searchedMessageHTML;
          // update data-match-index according to current matchIndex. It is needed for cases when there are > 1 matches in line
          var replacedTranscriptLinePrefix = searchedPrefix.replace(/data-match-index="0"/g, function () {
            return "data-match-index=\"".concat(matchIndex++, "\"");
          });
          var replacedTranscriptLine = searchedMessageHTML.replace(/data-match-index="0"/g, function () {
            return "data-match-index=\"".concat(matchIndex++, "\"");
          });
          var replacedTranscriptLineAttributes = searchedMessageAttributes.replace(/data-match-index="0"/g, function () {
            return "data-match-index=\"".concat(matchIndex++, "\"");
          });
          transcriptLine.setProperties({
            searchedPrefix: replacedTranscriptLinePrefix,
            searchedMessageAttributes: replacedTranscriptLineAttributes,
            searchedMessageHTML: replacedTranscriptLine
          });
        }
      });
      var searchMatchCount = (0, _math.sum)(this.get('activeTranscriptMessages').mapBy('searchMatchCount'));
      this.setNumberOfMatches(searchMatchCount);
      Ember.run.schedule('afterRender', function () {
        if (_this2.get('numberOfMatches') === 0) {
          _this2.setActiveMatch(0);
        } else {
          _this2.setActiveMatch(1);
          _this2.selectActiveMatch();
        }
      });
    }),
    setNumberOfMatches: function setNumberOfMatches(value) {
      this.set('numberOfMatches', value);
      this.set('speakFilterDebounceId', (0, _runloop.debounce)(this, this._speakFilter, 500));
    },
    setActiveMatch: function setActiveMatch(value) {
      this.set('activeMatch', value);
      this.set('speakFilterDebounceId', (0, _runloop.debounce)(this, this._speakFilter, 500));
    },
    selectActiveMatch: function selectActiveMatch() {
      var _this3 = this;
      (0, _runloop.later)(function () {
        var index = _this3.get('activeMatch') - 1;
        _this3.$('.active.match').removeClass('active');
        var $active = _this3.$("[data-match-index=\"".concat(index, "\"]"));
        if (!$active.length) {
          var matchedLines = _this3.get('activeTranscriptMessages').filterBy('searchMatchCount');
          var countIndex = -1;
          var scrollToItem = matchedLines.find(function (matchedLine) {
            var searchCount = matchedLine.get('searchMatchCount');

            // search for message where match with such index is present
            if (index > countIndex && index <= countIndex + searchCount) {
              return true;
            }
            countIndex += searchCount;
          });

          // render element and scroll to it if it didn't exist
          _this3.set('scrollToItem', scrollToItem);
        } else {
          var activeEl = $active[0];
          if (!activeEl) return;
          $active.addClass('active');
          if (!(0, _isElementVisible.isElementVisible)(activeEl)) {
            activeEl.scrollIntoView();
          }
          _this3.set('speakLineDebounceId', (0, _runloop.debounce)(_this3, _this3._speakLine, 1250));
        }
      });
    },
    _getLastSelectedTranscript: function _getLastSelectedTranscript() {
      var lastSelectedTranscriptID = this.get('engagement.ui.lastSelectedTranscriptID');
      if (lastSelectedTranscriptID === this.get('engagement.id')) {
        return this.get('engagement');
      }
      var foundTranscript = this.get('engagement.transcripts').findBy('engagementID', lastSelectedTranscriptID);
      return foundTranscript || this.get('engagement');
    },
    _speakFilter: function _speakFilter() {
      (0, _speak.default)("".concat(this.get('activeMatch'), " of ").concat(this.get('numberOfMatches')));
    },
    _speakLine: function _speakLine() {
      (0, _speak.default)(this.$('.active.match').parents('.line').text());
    },
    actions: {
      onTranscriptLineRendered: function onTranscriptLineRendered() {
        if (!this.get('isActiveFilter')) return;
        var index = this.get('activeMatch') - 1;
        var $active = this.$("[data-match-index=\"".concat(index, "\"]"));
        if ($active.length) {
          var activeEl = $active[0];
          var scrollToItem = this.get('scrollToItem');
          this.set('scrollToItem', null);
          $active.addClass('active');
          if (!(0, _isElementVisible.isElementVisible)(activeEl) && scrollToItem) {
            activeEl.scrollIntoView();
          }
          this.set('speakLineDebounceId', (0, _runloop.debounce)(this, this._speakLine, 1250));
        }
      }
    }
  });
});