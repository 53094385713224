define("sdk/api/models/nina-coach-intent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Nina Coach Intent Object
   * @class Object.Intent
   */
  var _default = _exports.default = Ember.Object.extend({
    availableConcepts: Ember.computed('concepts', function () {
      return this.get('concepts').filter(function (concept) {
        return concept.values.length;
      }).sort(function (a, b) {
        return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1;
      });
    }),
    hasAvailableConcepts: Ember.computed.notEmpty('availableConcepts'),
    checkedConcepts: Ember.computed.filterBy('availableConcepts', 'checked'),
    /**
     * map selected concepts to only use "name" and "value"
     * used as a parameter for the ninaCoachEscalationResolution route
     * @property selectedConcepts
     */
    selectedConcepts: Ember.computed('checkedConcepts', function () {
      return this.get('checkedConcepts').map(function (_ref) {
        var name = _ref.name,
          selected = _ref.selected;
        return {
          name: name,
          value: selected
        };
      });
    }),
    /**
     * Nina Coach response text that would be sent over to the customer
     * @property responseText
     */
    responseText: Ember.computed('answer', 'checkedConcepts.@each.selected', function () {
      var answer = this.get('answer');
      var checkedConcepts = this.get('checkedConcepts');
      var alternateResponses = this.get('alternateResponses');
      if (!alternateResponses) return answer;

      // find alternate response if there is any
      var selectedResponses = checkedConcepts.map(function (_ref2) {
        var selected = _ref2.selected,
          name = _ref2.name;
        return alternateResponses.find(function (_ref3) {
          var concepts = _ref3.concepts;
          return concepts.find(function (_ref4) {
            var conceptName = _ref4.name,
              conceptValue = _ref4.value;
            return conceptName === name && conceptValue === selected;
          });
        });
      });

      // use alternate response if selected concepts value contains only one unique "agentResponse"
      var selectedAgentResponse = selectedResponses.uniqBy('agentResponse');
      if (selectedAgentResponse.length === 1) return selectedAgentResponse.get('firstObject.agentResponse');
      return answer;
    }),
    /**
     * Deselects all concepts
     * Used when intent is updated in concepts-select component
     * @method deselectAllConcepts
     */
    deselectAllConcepts: function deselectAllConcepts() {
      this.get('availableConcepts').setEach('checked', false);
    }
  });
});