define("sdk/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('alert', this, this._showAlert);
      this.get('api').on('connectionLost', this, this.onConnectionLost);
      this.get('api').on('connectionRestored', this, this.onConnectionRestored);
    },
    onConnectionLost: function onConnectionLost() {
      this.set('model.isOnline', false);
    },
    onConnectionRestored: function onConnectionRestored() {
      if (this.get('model.isOnline')) this.set('model.isOnline', true);
    },
    actions: {
      handleApplicationClick: function handleApplicationClick() {
        this.get('notifications').resumeAudioContext();
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api').off('alert', this, this._showAlert);
      this.get('api').off('connectionLost', this, this.onConnectionLost);
      this.get('api').off('connectionRestored', this, this.onConnectionRestored);
    },
    _showAlert: function _showAlert(alert) {
      var type = alert.type,
        word = alert.word;
      var model = this.get('model');
      if (type === 'illegal-word') {
        model.set('alertMessage', "'".concat(word, "' ").concat(this.get('intl').t('illegal-word')));
      } else if (typeof alert === 'string') {
        model.set('alertMessage', this.get('intl').t(alert));
      } else {
        model.set('alertMessage', alert);
      }
    }
  });
});