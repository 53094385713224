define("sdk/components/video-preview/component", ["exports", "sdk/core/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: 'video',
    attributeBindings: ['autoplay', 'stream:srcObject', 'muted'],
    autoplay: 'autoplay',
    classNames: ['video-preview'],
    classNameBindings: ['hidden'],
    didRender: function didRender() {
      if (this.get('selectedAudioOutputId')) {
        this.element && this.element.setSinkId(this.get('selectedAudioOutputId'));
      }
    }
  });
});