define("sdk/api/utils/encode", ["exports", "sdk/api/utils/parse-html", "sdk/api/utils/html-tags"], function (_exports, _parseHtml, _htmlTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.allowedSystemHTMLTags = _exports.allowedCustomerHTMLTags = _exports.allowedAgentHTMLTags = void 0;
  _exports.encodeAgentText = encodeAgentText;
  /**
   * Encode html tags except for tags to be rendered for the agent
   * Rendered tags include rich text tags (b, i, span) and other non-harmful tags (a, b, br, etc.)
   * @method encodeAgentText
   * @param {String} text
   */
  function encodeAgentText() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return (0, _parseHtml.encodeHtmlTags)(text, {
      whitelist: allowedAgentHTMLTags
    });
  }
  var allowedAgentHTMLTags = _exports.allowedAgentHTMLTags = ['a', 'b', 'br', 'dd', 'div', 'dl', 'dt', 'em', 'font', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'img', 'li', 'ol', 'p', 'q', 'small', 'span', 'strong', 'sub', 'sup', 'u', 'ul'];
  var allowedCustomerHTMLTags = _exports.allowedCustomerHTMLTags = ['span'];
  var allowedSystemHTMLTags = _exports.allowedSystemHTMLTags = _htmlTags.default.filter(function (tag) {
    var blockedTags = ['audio', 'video', 'map', 'track', 'embed', 'script', 'canvas'];
    return !blockedTags.includes(tag);
  });
});