define("sdk/components/react-wrapper/get-mutable-attrs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMutableAttributes;
  var getMutValue;
  var libPath = 'ember-views/compat/attrs';
  var _Ember$__loader$requi = Ember.__loader.require(libPath),
    MUTABLE_CELL = _Ember$__loader$requi.MUTABLE_CELL;
  getMutValue = function getMutValue(value) {
    if (value && value[MUTABLE_CELL]) {
      return value.value;
    } else {
      return value;
    }
  };
  function getMutableAttributes(attrs) {
    return Object.keys(attrs).reduce(function (acc, attr) {
      acc[attr] = getMutValue(attrs[attr]);
      return acc;
    }, {});
  }
});