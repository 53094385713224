define("sdk/api/agent", ["exports", "moment", "ember-get-config", "ember-uuid", "lodash", "sdk/api/engagement", "sdk/api/models/script-tree", "sdk/utils/array", "sdk/utils/user-agent-parser", "sdk/api/utils/runloop", "sdk/api/utils/agent-availability-serializer", "sdk/api/utils/custom-scripts", "sdk/api/utils/url", "sdk/api/data/agent-default-statuses"], function (_exports, _moment, _emberGetConfig, _emberUuid, _lodash, _engagement, _scriptTree, _array, _userAgentParser, _runloop, _agentAvailabilitySerializer, _customScripts, _url, _agentDefaultStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * @class AIAPI.Agent
   */
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    isLoggedIn: false,
    isAvailable: Ember.computed.equal('status.value', 'available'),
    /**
     * Represents the current hotkey being entered.
     * @property {String} activeHotkey
     * @public
     */
    activeHotkey: '',
    setStatusLastChangedTimestamp: function setStatusLastChangedTimestamp() {
      this.state.set({
        statusLastChangedTimestamp: this.get('api.currentTimeISO')
      });
    },
    isRequestingAdditionalChats: false,
    isRequestAdditionalChatsDisabled: Ember.computed.or('isBusy', 'chatSlotsNotFull', 'extraChatSlotsFull'),
    chatCount: Ember.computed.alias('engagements.length'),
    maxForcedChats: Ember.computed.alias('api.settings.maxForcedChats'),
    scriptGroupIds: Ember.computed('scriptTrees', function () {
      return (0, _array.flatten)(this.get('scriptTrees').mapBy('groups')).mapBy('id');
    }),
    chatSlotsNotFull: Ember.computed('chatCount', function () {
      return this.get('chatCount') < this.get('api.settings.maxChats');
    }),
    extraChatSlotsFull: Ember.computed('chatCount', function () {
      return this.get('chatCount') === this.get('maxForcedChats') + this.get('api.settings.maxExtraSlots');
    }),
    /**
     * Time in minutes agent will go to lock screen due to inactivity
     * @property {Number} minutesBeforeLockout
     */
    minutesBeforeLockout: Ember.computed.alias('api.settings.minutesBeforeLock'),
    /**
     * Time in minutes agent will logout due to inactivity
     * @property {Number} minutesBeforeLogout
     */
    minutesBeforeLogout: Ember.computed.alias('api.settings.minutesBeforeLogout'),
    hasFollowUpConversations: Ember.computed.gt('followUpConversations.length', 0),
    /**
     * Used to generate ids for fake engagements.
     * @property {Number} numFakeEngagementsCreated
     * @public
     */
    numFakeEngagementsCreated: 0,
    /**
     * Delay before making request to retrieve the list
     * of follow up engagements.
     * See RTDEV-24673 or HTMLAI-917
     * @property {Number} followUpDelay
     * @public
     */
    followUpDelay: 1000 * 5,
    // 5 seconds
    /**
     * The current pending follow up request.
     * @property {Promise} deferredFollowUp
     * @public
     */
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.state = this.api.stateManager.createItem({
        context: this,
        key: 'AGENT',
        publicProperties: ['agentSummary', 'alias', 'apiSettings', 'features', 'followupConversations', 'fullScriptTree', 'hasEngagement', 'hasPreferredQueue', 'isAutoLockOrLogoutEnabled', 'isBusy', 'isUsingFirefox', 'settings', 'status', 'statusLastChangedTimestamp', 'statusNames', 'userId'],
        publicMethods: ['getSummaryData', 'getToken', 'login', 'logout', 'setStatus']
      });
      this._restore();
      if (this.api.autoboot !== false) {
        this.api.one('authenticated', function () {
          _this.loadSystemScripts();
          _this.loadCustomScripts();
          _this.getFollowUpConversations();
          _this._setInitialStatus();
          _this.updateSettings();
        });
      }
      this.api.on('settingsLoaded', this, this._initializeInactivityTimers);
      this.api.on('agentInteracted', this, this._initializeInactivityTimers);
      this.api.on('newEngagement', function (engagement) {
        var loadedConversationIds = _this.loadedFollowUpConversations.map(function (conversation) {
          return conversation.conversationId;
        });
        var visibleConversations = _this.followUpConversations.reject(function (conversation) {
          var conversationId = conversation.conversationId;
          return loadedConversationIds.includes(conversationId) || engagement.settings.conversationId === conversationId;
        });
        _this.set('followUpConversations', visibleConversations);
        _this.getFollowUpConversations();
      });
      this.api.on('engagementClosed', this, this.getFollowUpConversations);
      this.api.on('followUpInitiatedSuccessfully', function (engagement) {
        // this engagement could have been previously loaded
        // or it was kept in follow up but the SLA expired
        _this.set('loadedFollowUpConversations', _this.loadedFollowUpConversations.rejectBy('conversationId', engagement.settings.conversationId));
        _this.set('followUpConversations', _this.followUpConversations.rejectBy('conversationId', engagement.settings.conversationId));
      });
      var followUpConversations = this.api.get('storage.store.agent.followUpConversations');
      this.engagements = [];
      this.followUpConversations = followUpConversations || [];
      this.loadedFollowUpConversations = [];
      this.settings = Ember.Object.create({
        filterAsIType: true,
        fontSize: 1,
        language: 'en_US',
        layout: 'chat-left',
        showAlerts: true,
        playSounds: true,
        clicking: 'singleClick',
        loginState: 'loginAsBusy'
      });
      this.ui = Ember.Object.create({
        positionedEngagementIds: []
      });
      var storeAgentUi = this.api.get('storage.store.agent.ui') || {};
      this.ui.setProperties(storeAgentUi);
      var _parseUserAgent = (0, _userAgentParser.default)(navigator.userAgent),
        browser = _parseUserAgent.browser;
      this.state.set({
        isUsingFirefox: browser.includes('Firefox')
      });
    },
    acceptOwnership: function acceptOwnership(engagementId, reason) {
      var engagement = this.get('engagements').findBy('id', engagementId);
      var userId = this.get('api.agent.userId');
      engagement.state.set({
        isLocked: true
      });
      return this.get('api.adapter').acceptOwnership(engagementId, reason).then(function () {
        var ownershipChangedMessage = "Ownership of this chat has been transferred to ".concat(userId, " with reason:  ").concat(reason || '(none)');
        engagement._processMessage({
          messageType: 'stateChange',
          messageText: ownershipChangedMessage,
          state: 'ownershipChange',
          status: 'accepted',
          userId: userId
        });
      }).finally(function () {
        engagement.state.set({
          isLocked: false
        });
      });
    },
    refuseOwnership: function refuseOwnership(engagementId, reason) {
      var engagement = this.get('engagements').findBy('id', engagementId);
      engagement.state.set({
        isLocked: true
      });
      return this.get('api.adapter').refuseOwnership(engagementId, reason).finally(function () {
        engagement.state.set({
          isLocked: false
        });
      });
    },
    changePassword: function changePassword(currentPassword, newPassword) {
      return this.get('api.adapter').changePassword(currentPassword, newPassword);
    },
    requestAdditionalChats: function requestAdditionalChats(isEnabled) {
      this.set('isRequestingAdditionalChats', isEnabled);
      return this.get('api.adapter').extraChat(isEnabled);
    },
    setCustomScripts: function setCustomScripts(scripts) {
      var _this2 = this;
      return this.get('api.adapter').setCustomScripts(scripts).then(function () {
        return _this2._loadCustomScripts(scripts);
      });
    },
    _loadCustomScripts: function _loadCustomScripts() {
      var customScripts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      if ((0, _customScripts.hasValidCustomScripts)(customScripts)) {
        this.setProperties({
          didCustomScriptsLoadFail: false,
          customScripts: (0, _customScripts.serializeCustomScripts)(customScripts)
        });
      } else {
        this.set('didCustomScriptsLoadFail', true);
      }
    },
    getSummaryData: function getSummaryData() {
      var _this3 = this;
      return this.get('api.adapter').summaryData().then(function (data) {
        var _serializeAgentAvaila = (0, _agentAvailabilitySerializer.default)(data),
          agentSummary = _serializeAgentAvaila.agentSummary,
          hasPreferredQueue = _serializeAgentAvaila.hasPreferredQueue;
        _this3.state.set({
          agentSummary: agentSummary,
          hasPreferredQueue: hasPreferredQueue
        });
      });
    },
    getToken: function getToken(credentials) {
      return this.api.authAdapter.authenticate(credentials);
    },
    login: function login(username, options) {
      var _this4 = this;
      var adapter = this.get('api.adapter');
      this.set('userId', username);

      // used to calculate "login_response_duration" in agent login log
      this.set('_loginStartTime', (0, _moment.default)());
      return adapter.login(username, options).then(function (data) {
        var url = window.location.href;
        var queryParams = (0, _url.getQueryParams)(url);
        var isV3Preview = queryParams[Object.keys(queryParams).find(function (key) {
          return key.toLowerCase() === 'v3preview';
        })];
        isV3Preview ? _this4.state.set({
          features: {
            "transcriptV3": true
          }
        }) : _this4.state.set({
          features: {
            "transcriptV3": false
          }
        });
        if (queryParams.features) {
          var features = queryParams.features.split(',');
          var featuresObj = features.reduce(function (acc, elem) {
            acc[elem] = true;
            return acc;
          }, {});
          _this4.state.set({
            features: featuresObj
          });
        }
        var timestamp = new Date().toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });
        data.loginTime = timestamp;
        data.isLoggedIn = true;
        delete data.status;
        _this4.setProperties(data);
        var storage = _this4.get('api.storage');
        storage.set('store.sessionId', (0, _emberUuid.v4)());
        storage.set('store.apiErrorIncrements', {});
        storage.persist();
        _this4.set('_loginResponseDuration', Number((0, _moment.default)().diff(_this4.get('_loginStartTime'))));
        return _this4._loadSiteSettings().then(_this4._loadCustomSettings.bind(_this4)).then(function () {
          _this4._sendLoginLog();
          _this4.api.trigger('authenticated', data);
        });
      });
    },
    logout: function logout() {
      var _this5 = this;
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var force = options.force,
        auto = options.auto;
      var hasEngagement = this.get('api.agent.hasEngagement');
      if (hasEngagement && !force) {
        return Ember.RSVP.reject({
          title: 'Agent has active engagements',
          code: 'agent-has-active-engagements',
          details: 'Agent cannot logout because they are assigned to at least one active engagement'
        });
      }

      // abort and block requests to assure logout request is sent immediately
      this.get('api').abortAndBlockAllRequests();
      this.get('api.router').close();
      return this.get('api.adapter').logout({
        auto: auto
      }).then(function () {
        _this5.set('isLoggedIn', false);
      }).finally(function () {
        _this5.get('api.storage').clear();
      });
    },
    setStatus: function setStatus(status) {
      var _this6 = this;
      var statusLabel = status.label,
        statusValue = status.value;
      return this.api.adapter.agentStatus(statusValue, statusLabel).then(function () {
        // Prevent busy timer from being reset if a different busy status is selected
        if (statusValue === 'busy' && _this6.get('status.value') !== 'busy') {
          _this6.setStatusLastChangedTimestamp();
        }
        _this6.state.set({
          isBusy: statusValue !== 'available',
          status: status
        });
        _this6.api.trigger('statusChanged', status);
      }).catch(function () {
        (0, _runloop.later)(function () {
          _this6.set('showAgentStatusWarn', true);
        });
      });
    },
    /**
     * Sets the agents status to "busy-auto"
     * @method setBusyAuto
     */
    setBusyAuto: function setBusyAuto() {
      return this.setStatus({
        label: 'busy-auto',
        value: 'busy',
        isDefault: true
      });
    },
    updateSetting: function updateSetting(key, value) {
      if (value && value.hasOwnProperty('value')) {
        value = value.value;
      }
      this.updateSettings(_defineProperty({}, key, value));
      // save it in localstorage as don't need to save on server
      if (key === 'showPagination') {
        this.api.localStorage.setItem("".concat(key), value ? "true" : "false");
      }
      var settingObj = {};
      settingObj[key] = value;
      return this.api.adapter.setCustomSettings(settingObj);
    },
    updateLayoutSetting: function updateLayoutSetting(key, value) {
      var layoutSettings;
      try {
        layoutSettings = JSON.parse(this.get('settings.layoutSettings'));
      } catch (e) {
        layoutSettings = {
          schema: '1.1',
          settings: {}
        };
      }
      var updatedSettingsObj = _typeof(key) === 'object' ? key : null;
      if (updatedSettingsObj) {
        Ember.setProperties(layoutSettings.settings, updatedSettingsObj);
      } else {
        Ember.set(layoutSettings.settings, key, value);
      }
      this.updateSettings(layoutSettings.settings);
      return this.updateSetting('layoutSettings', JSON.stringify(layoutSettings));
    },
    updateSettings: function updateSettings() {
      var newSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.state.set({
        settings: _objectSpread(_objectSpread({}, this.state.settings || this.get('settings')), newSettings)
      });
    },
    verifySession: function verifySession() {
      var _this7 = this;
      var _this$api$storage$sto = this.api.storage.store,
        userId = _this$api$storage$sto.userId,
        registeredId = _this$api$storage$sto.registeredId,
        publicUserId = _this$api$storage$sto.publicUserId,
        loginTime = _this$api$storage$sto.loginTime;
      if (!userId || !registeredId) return Ember.RSVP.reject();
      return this._sendVerifySession().then(function () {
        var agentData = {
          userId: userId,
          registeredId: registeredId,
          publicUserId: publicUserId,
          loginTime: loginTime,
          isLoggedIn: true
        };
        _this7.setProperties(agentData);
        return _this7._loadSiteSettings().then(_this7._loadCustomSettings.bind(_this7)).then(function () {
          _this7.api.trigger('authenticated', agentData);
          _this7.get('api.router').restoreEngagements();
        });
      });
    },
    /**
     * Sends verifySession request to AEAPI endpoint
     * @method _sendVerifySession
     */
    _sendVerifySession: function _sendVerifySession() {
      var _this$api$storage$sto2 = this.api.storage.store,
        userId = _this$api$storage$sto2.userId,
        registeredId = _this$api$storage$sto2.registeredId;
      return this.api.adapter.verifySession(userId, registeredId);
    },
    addEngagement: function addEngagement(engagement) {
      this.engagements.pushObject(engagement);
      this.state.set({
        hasEngagement: !!this.engagements.length
      });
    },
    removeEngagement: function removeEngagement(engagement) {
      this.engagements.removeObject(engagement);
      this.state.set({
        hasEngagement: !!this.engagements.length
      });
    },
    /**
     * Retrieves the agent's follow up conversations.
     * Will filter out conversations which are currently in their "loaded" state
     * so we can "hide" them in the list of conversations to load.
     * @method getFollowUpConversations
     * @public
     * @return {Promise}
     */
    getFollowUpConversations: function getFollowUpConversations() {
      var _this8 = this;
      var deferredFollowUp = this.get('deferredFollowUp');
      if (deferredFollowUp) return deferredFollowUp.promise;
      var deferred = new Ember.RSVP.defer();
      this.set('deferredFollowUp', deferred);
      this.set('followUpTimeout', setTimeout(function () {
        // ignore follow up requests if there are no auth tokens (agent is locked out)
        if (_this8.api.get('authAdapter.hasNoAuthTokens')) return deferred.resolve();
        _this8.api.adapter.followUpConversations().then(function (response) {
          if (!response) return;
          var conversations = Ember.get(response, 'conversations') || [];
          var loadedConversationIds = _this8.loadedFollowUpConversations.map(function (conversation) {
            return conversation.conversationId;
          });
          var visibleConversations = conversations.reject(function (conversation) {
            return loadedConversationIds.includes(conversation.conversationId);
          });
          _this8.set('followUpConversations', visibleConversations);
          _this8.trigger('retrievedFollowUps', visibleConversations);
          _this8.state.set({
            followupConversations: visibleConversations
          });
          _this8._persistFollowUpConversations(conversations);
          return visibleConversations;
        }).then(deferred.resolve).finally(function () {
          if (!_this8.get('isDestroyed')) {
            _this8.set('deferredFollowUp', null);
          }
        });
      }, this.get('followUpDelay')));
      return deferred.promise;
    },
    /**
     * Sends the chat details to data warehouse when
     * an engagement is focused in by the agent
     * @method logChatFocusGained
     * @public
     * @param engagement
     */
    logChatFocusGained: function logChatFocusGained(engagement) {
      var eventData = {
        flexibleResponse: true,
        // returns 204 status if response is empty
        siteID: engagement.settings.siteId,
        params: {
          chatID: engagement.id,
          conversationID: engagement.settings.conversationId,
          customerID: engagement.customer.id,
          numActiveChats: this.chatCount
        }
      };
      return this.get('api.dcapiAdapter').sendEventLog(eventData, {
        domain: 'chat',
        event: 'focusGained'
      });
    },
    /**
     * Sends the chat details to data warehouse when
     * an engagement is focused out by the agent
     * @method logChatFocusLost
     * @public
     * @param engagement
     */
    logChatFocusLost: function logChatFocusLost(engagement) {
      var eventData = {
        flexibleResponse: true,
        // returns 204 status if response is empty
        siteID: engagement.settings.siteId,
        params: {
          chatID: engagement.id,
          conversationID: engagement.settings.conversationId,
          customerID: engagement.customer.id,
          numActiveChats: this.chatCount
        }
      };
      return this.get('api.dcapiAdapter').sendEventLog(eventData, {
        domain: 'chat',
        event: 'focusLost'
      });
    },
    /**
     * persist follow up conversations to sessionStorage
     * @method _persistFollowUpConversations
     * @param {Array} conversations
     */
    _persistFollowUpConversations: function _persistFollowUpConversations(conversations) {
      var storage = this.api.get('storage');
      storage.set('store.agent.followUpConverstations', conversations);
      storage.persist();
    },
    /**
     * Loads a given conversation to be followed up.
     * Creates a "fake" engagement.
     * @method startFollowUpEngagement
     * @public
     * @param {String} conversationId
     * @return {Promise}
     */
    startFollowUpEngagement: function startFollowUpEngagement(conversationId) {
      var conversation = this.get('followUpConversations').findBy('conversationId', conversationId);
      this.get('loadedFollowUpConversations').addObject(conversation);
      this.get('followUpConversations').removeObject(conversation);
      this.incrementProperty('numFakeEngagementsCreated');
      var id = parseInt(this.numFakeEngagementsCreated).toString();
      var settings = this._generateFakeEngagementSettings(conversation);
      var manuallyLoaded = true;
      var fakeEngagement = _engagement.default.create({
        id: id,
        api: this.api,
        manuallyLoaded: manuallyLoaded
      });
      this.addEngagement(fakeEngagement);
      var data = {
        messages: [],
        settings: settings
      };
      fakeEngagement.state.set({
        id: id,
        manuallyLoaded: manuallyLoaded
      });
      fakeEngagement._loadMessages(data);
      this.trigger('loadedFollowUp', fakeEngagement);
      this.getFollowUpConversations();
    },
    /**
     * Used to place an uninitialized-but-loaded follow up conversation
     * back into the follow up list.
     * @method placeEngagementBackIntoFollowUp
     * @public
     * @param {Object} engagement
     */
    placeEngagementBackIntoFollowUp: function placeEngagementBackIntoFollowUp(engagement) {
      var conversationId = engagement.settings.conversationId;
      var conversation = this.get('loadedFollowUpConversations').findBy('conversationId', conversationId);
      this.removeEngagement(engagement);
      this.get('followUpConversations').addObject(conversation);
      this.get('loadedFollowUpConversations').removeObject(conversation);
      this.trigger('placedEngagementBackIntoFollowUp');
    },
    willDestroy: function willDestroy() {
      if (this.get('engagements.length')) {
        this.get('engagements').forEach(function (engagement) {
          return engagement.destroy();
        });
        this.get('engagements').clear();
      }
      this._clearInactivityTimers();
      this.stopVerifySessionPolling();
      clearTimeout(this.get('followUpTimeout'));
      this.api.off('agentInteracted', this, this._initializeInactivityTimers);
      this.api.off('settingsLoaded', this, this._initializeInactivityTimers);
    },
    /**
     * Generates a settings object for a fake engagement.
     * @method _generateFakeEngagementSettings
     * @private
     * @param {Object} conversation the data for the follow up conversation
     * @return {Object} the settings object
     */
    _generateFakeEngagementSettings: function _generateFakeEngagementSettings(conversation) {
      conversation.siteId = conversation.siteId.toString();
      conversation.businessRuleId = conversation.businessRuleId.toString();
      conversation.businessUnitId = conversation.businessUnitId.toString();
      conversation.agentGroupId = conversation.agentGroupId && conversation.agentGroupId.toString();
      conversation.siteId = conversation.siteId.toString();
      conversation.scriptTreeId = conversation.scriptTreeId.toString();
      return Object.assign(conversation, {
        takenOutOfFollowUp: true,
        asyncChat: true,
        followUpEnabled: true
      });
    },
    loadSystemScripts: function loadSystemScripts() {
      var _this9 = this;
      this.set('hasErrorInSystemScripts', false);
      this.api.adapter.fullScriptTree().then(function (scriptTrees) {
        _this9.state.set({
          fullScriptTree: _lodash.default.cloneDeep(scriptTrees)
        });
        scriptTrees = scriptTrees.map(function (tree) {
          return _scriptTree.default.create({
            __data__: tree,
            api: _this9.api
          });
        });
        _this9.set('scriptTrees', scriptTrees);
        _this9.api.trigger('scriptsLoaded', scriptTrees);
        var siteScriptSettings = scriptTrees.reduce(function (sitesWithFileUpload, scriptTree) {
          var siteId = scriptTree.siteId;
          if (sitesWithFileUpload.find(function (siteWithFileUpload) {
            return siteWithFileUpload.siteId === siteId;
          })) return sitesWithFileUpload;
          var scriptWithFileUpload = scriptTree.allScripts.find(function (script) {
            return script.command === 'xform to grow.fileUpload' && script.scriptType === 'command';
          });
          var siteScriptSetting = {};
          if (scriptWithFileUpload) {
            Object.assign(siteScriptSetting, {
              fileUpload: !!scriptWithFileUpload,
              siteId: siteId,
              scriptTreeId: scriptWithFileUpload.scriptTreeId
            });
            sitesWithFileUpload.push(siteScriptSetting);
          }
          return sitesWithFileUpload;
        }, []);
        _this9.state.set({
          apiSettings: _objectSpread(_objectSpread({}, _this9.apiSettings), {}, {
            siteScriptSettings: siteScriptSettings
          })
        });
      }).catch(function () {
        return _this9.set('hasErrorInSystemScripts', true);
      });
    },
    loadCustomScripts: function loadCustomScripts() {
      var _this10 = this;
      this.api.adapter.getCustomScripts().then(function (customScriptsResult) {
        var customScripts = customScriptsResult && customScriptsResult.customScripts && customScriptsResult.customScripts.scripts;
        _this10._loadCustomScripts(customScripts);
        _this10.set('didCustomScriptsLoadFail', false);
      }).catch(function () {
        return _this10.set('didCustomScriptsLoadFail', true);
      });
    },
    /**
     * Posts dynamic attributes
     * @method _postDynamicAttributes
     * @private
     * @param {Object} agentEngagementSettings
     */
    _postDynamicAttributes: function _postDynamicAttributes(agentEngagementSettings) {
      var _agentData$agentDynam;
      if (!agentEngagementSettings) return;
      var agentData = {
        agentDynamicAttributes: [],
        agentId: this.get('api.agent.userId')
      };
      var isVoiceCallEnabled = agentEngagementSettings.some(function (setting) {
        return setting.extendedSettings.webRTC && setting.extendedSettings.webRTC.enabledVoiceCalling;
      });
      var isVideoCallEnabled = agentEngagementSettings.some(function (setting) {
        return setting.extendedSettings.webRTC && setting.extendedSettings.webRTC.enabledVideoCalling;
      });
      if (!isVoiceCallEnabled && !isVideoCallEnabled) return;
      (_agentData$agentDynam = agentData.agentDynamicAttributes).push.apply(_agentData$agentDynam, _toConsumableArray(isVideoCallEnabled ? [{
        name: 'isAvailableForVideo',
        value: 'true'
      }] : []).concat(_toConsumableArray(isVoiceCallEnabled ? [{
        name: 'isAvailableForVoice',
        value: 'true'
      }] : [])));
      this.get('api.adapter').postDynamicAttribute(agentData);
    },
    _loadSiteSettings: function _loadSiteSettings() {
      var _this11 = this;
      return this.api.adapter.settings().then(function (settings) {
        var _ref = settings || {},
          agentEngagementSettings = _ref.agentEngagementSettings,
          minutesBeforeLock = _ref.minutesBeforeLock,
          minutesBeforeLogout = _ref.minutesBeforeLogout;
        _this11.api.set('settings', settings);
        _this11.state.set({
          isAutoLockOrLogoutEnabled: !!(minutesBeforeLock || minutesBeforeLogout)
        });
        _this11.state.set({
          apiSettings: settings
        });
        _this11.api.trigger('settingsLoaded');
        _this11._postDynamicAttributes(agentEngagementSettings);
      });
    },
    _loadCustomSettings: function _loadCustomSettings() {
      var _this12 = this;
      var adapter = this.api.adapter;
      return adapter.getCustomSettings().then(function (customSettings) {
        if (customSettings) {
          var defaultCustomSettings = Object.assign({
            showAlerts: true,
            showPagination: _this12.get('api').localStorage.getItem('showPagination') === "true" // fetch from localstorage
          }, customSettings);
          try {
            var _JSON$parse = JSON.parse(customSettings.layoutSettings),
              layoutSettings = _JSON$parse.settings;
            Ember.setProperties(customSettings, layoutSettings);
          } catch (error) {} // eslint-disable-line no-empty

          // set default custom settings
          customSettings = Object.assign(defaultCustomSettings, customSettings);
          Ember.setProperties(_this12.get('settings'), customSettings);
        } else {
          // persist default custom settings to server
          adapter.setCustomSettings(_this12.get('settings'));
        }
        _this12.api.trigger('customSettingsLoaded');
      });
    },
    /**
     * Creates inactivity timeouts for settings
     * "minutesBeforeLock" & "minutesBeforeLogout"
     * on lockout, agent is transitioned to lock screen
     * on logout, agent is logged off
     * @method initializeInactivityTimers
     */
    _initializeInactivityTimers: function _initializeInactivityTimers() {
      var _this13 = this;
      this._clearInactivityTimers();
      var _this$getProperties = this.getProperties('minutesBeforeLockout', 'minutesBeforeLogout'),
        minutesBeforeLockout = _this$getProperties.minutesBeforeLockout,
        minutesBeforeLogout = _this$getProperties.minutesBeforeLogout;
      if (minutesBeforeLockout && !this.get('isLockedOut')) {
        this.set('_lockoutTimer', setTimeout(function () {
          _this13.lockout();
        }, minutesBeforeLockout * 60 * 1000));
      }
      if (minutesBeforeLogout) {
        this.set('_logoutTimer', setTimeout(function () {
          var routeBeforeLockout = _this13.get('api.storage.store.currentRoute') || 'site.summary';
          var isEngagementRoute = routeBeforeLockout === 'site.engagements.engagement';
          if (isEngagementRoute) {
            var activeEngagementId = _this13.get('api.storage.store.activeEngagementId');
            var previousEngagement = _this13.get('api.agent.engagements').findBy('id', activeEngagementId);
            _this13.logChatFocusLost(previousEngagement).finally(function () {
              return _this13._invalidateSessionForInactivity();
            });
          } else {
            _this13._invalidateSessionForInactivity();
          }
        }, minutesBeforeLogout * 60 * 1000));
      }
    },
    _invalidateSessionForInactivity: function _invalidateSessionForInactivity() {
      this.invalidateSession({
        loginTranslationPath: 'logout-inactivity',
        auto: true
      });
    },
    /*
     * Clear inactivity timers
     * @method _clearInactivityTimers
     */
    _clearInactivityTimers: function _clearInactivityTimers() {
      clearTimeout(this.get('_lockoutTimer'));
      clearTimeout(this.get('_logoutTimer'));
    },
    disableInactivityTimers: function disableInactivityTimers() {
      this._clearInactivityTimers();
      this.api.off('agentInteracted', this, this._initializeInactivityTimers);
    },
    enableInactivityTimers: function enableInactivityTimers() {
      this.api.on('agentInteracted', this, this._initializeInactivityTimers);
      this._initializeInactivityTimers();
    },
    /**
     * Sets agent in lockout state
     * Clears authorization tokens and stops GET /messages polling
     * @method lockout
     */
    lockout: function lockout() {
      var _this14 = this;
      return this.setBusyAuto().finally(function () {
        // prevent GET messages polling and abort all active requests
        _this14.get('api.router').close();
        _this14.get('api.metrics').stopActivePerformancePolling();
        _this14.get('api.metrics').stopRealtimePolling();
        _this14.get('api.ajaxQueueManager').abortActiveRequests();
        _this14.set('isLockedOut', true);

        // auto-transfer engagements
        var autoTransferRequests = _this14.get('engagements').filterBy('hasAutoTransfer').map(function (engagement) {
          return engagement.autoTransfer();
        });
        return Ember.RSVP.all(autoTransferRequests).finally(function () {
          // remove auth token
          _this14.get('api.authAdapter').revoke();
          _this14.api.trigger('agentLockout');
        });
      });
    },
    /**
     * Unlocks the agent from the locked state
     * @method unlock
     */
    unlock: function unlock() {
      this.set('isLockedOut', false);
      this.api.trigger('authenticated');
      this.stopVerifySessionPolling();
      this._initializeInactivityTimers();
    },
    /**
     * Triggers api invalidateSession with error title message
     * @method invalidateSession
     * @param {Object} options options object to pass to invalidateSession
     */
    invalidateSession: function invalidateSession(options) {
      this.api.trigger('invalidateSession', options);
    },
    /**
     * Polls verifySession endpoint once every 30s to keep agent session alive when locked out
     * @method startVerifySessionPolling
     */
    startVerifySessionPolling: function startVerifySessionPolling() {
      var _this15 = this;
      return this._sendVerifySession().then(function () {
        _this15.set('verifySessionTimeout', setTimeout(function () {
          return _this15.startVerifySessionPolling();
        }, 30 * 1000)); // 30 seconds
      });
    },
    /**
     * Stops another verifySession request from being made
     * @method stopVerifySessionPolling
     */
    stopVerifySessionPolling: function stopVerifySessionPolling() {
      clearTimeout(this.get('verifySessionTimeout'));
    },
    /**
     * observe properties to persist
     * @method persistObserver
     */
    persistObserver: Ember.observer('userId', 'isLoggedIn', 'status', 'statusLastChangedTimestamp', 'ui.layout', function () {
      this.persist();
    }),
    /**
     * persist agent properties
     * @method persist
     */
    persist: function persist() {
      var propertiesToPersist = this.getProperties('userId', 'isLoggedIn', 'status', 'statusLastChangedTimestamp', 'ui');
      var storage = this.get('api.storage');
      storage.set('store.agent', propertiesToPersist);
      storage.persist();
    },
    /**
     * restore session variables back into agent instance
     * @method _restore
     */
    _restore: function _restore() {
      var storage = this.get('api.storage');
      var persistedAgentProperties = Ember.get(storage, 'store.agent');
      if (!persistedAgentProperties) return;
      this.setProperties(persistedAgentProperties);
    },
    /**
     * Returns all scripts from every script tree in a single,
     * flat array.
     * @method allScripts
     * @public
     * @return {Array} all the scripts
     */
    allScripts: Ember.computed('scriptTrees', function () {
      var scriptTrees = this.get('scriptTrees');
      if (!scriptTrees) return [];
      var allScriptsFromTrees = scriptTrees.map(function (scriptTree) {
        return scriptTree.get('allScripts');
      });
      var allScripts = [].concat.apply([], allScriptsFromTrees);
      return allScripts;
    }),
    /**
     * Send a agent login log when the agent first logs in
     * @method _sendLoginLog
     */
    _sendLoginLog: function _sendLoginLog() {
      var _this$get = this.get('api.settings'),
        siteSettings = _this$get.siteSettings;
      var _ref2 = siteSettings || {},
        sites = _ref2.sites;
      var siteIds = [];
      var agentGroupIds = [];
      var businessUnitIds = [];
      sites.forEach(function (site) {
        var siteId = site.id,
          agentGroups = site.agentGroups,
          businessUnits = site.businessUnits;
        siteIds.addObject(siteId);
        if (agentGroups) {
          agentGroupIds.addObjects(agentGroups.mapBy('id'));
        }
        if (businessUnits) {
          businessUnitIds.addObjects(businessUnits.mapBy('id'));
        }
      });
      var loginDetailsObject = {
        event_group: 'login',
        event: 'logged_in',
        login_response_duration: this.get('_loginResponseDuration') || 0,
        site_id: siteIds.join(','),
        business_unit_id: businessUnitIds.join(','),
        agent_group_id: agentGroupIds.join(','),
        agent_id: this.get('userId'),
        public_user_id: this.get('publicUserId'),
        browser_resolution: "".concat(window.outerWidth, "x").concat(window.outerHeight),
        screen_resolution: "".concat(screen.width, "x").concat(screen.height),
        htmlai_version: _emberGetConfig.default.version,
        features: Object.keys(this.features || {}).toString()
      };

      // add user-agent metadata i.e browser, device, os
      Object.assign(loginDetailsObject, (0, _userAgentParser.default)(navigator.userAgent));
      return this.get('api.logging').sendLog('info', loginDetailsObject);
    },
    /**
    * Sets initial agent status
    * Depending Portal Settings or Agent Custom Settings,
    * the agent can either login as busy or available
    * @method _setInitialStatus
    */
    _setInitialStatus: function _setInitialStatus() {
      var _defaultStatusList = _slicedToArray(_agentDefaultStatuses.defaultStatusList, 2),
        defaultAvailableStatus = _defaultStatusList[0],
        defaultBusyStatus = _defaultStatusList[1];
      var _ref3 = this.get('api.storage.store.agent') || {},
        status = _ref3.status,
        statusLastChangedTimestamp = _ref3.statusLastChangedTimestamp;
      if (!statusLastChangedTimestamp) {
        statusLastChangedTimestamp = this.get('api.currentTimeISO');
      }
      if (!status) {
        var loginAsAvailable = this.get('loginAsAvailable');
        status = loginAsAvailable ? defaultAvailableStatus : defaultBusyStatus;
        this.state.set({
          statusLastChangedTimestamp: statusLastChangedTimestamp
        });
      } else if (status.value === 'busy') {
        this.state.set({
          statusLastChangedTimestamp: statusLastChangedTimestamp
        });
      }
      var busyStatuses = (this.get('api.settings.busyStatuses.status') || []).map(function (statusLabel) {
        return {
          label: statusLabel,
          value: 'busy'
        };
      }).uniqBy('label');
      var defaultStatuses = _agentDefaultStatuses.defaultStatusList.map(function (status) {
        return _objectSpread(_objectSpread({}, status), {}, {
          isDefault: true
        });
      });
      var sortedStatus = busyStatuses.sort(function (a, b) {
        return a.label.localeCompare(b.label);
      });
      var statusNames = defaultStatuses.concat(sortedStatus).uniqBy('label');
      status.isDefault = true;
      this.state.set({
        isBusy: status.value === 'busy',
        status: {
          label: status.label,
          value: status.value,
          isDefault: !!status.isDefault
        },
        statusNames: statusNames
      });
    }
  });
});