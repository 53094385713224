define("sdk/components/static-transcript-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mBPxreNw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"  \"],[13,1,[[26,\"hash\",null,[[\"search\",\"history\",\"transcript\",\"filter\"],[[26,\"component\",[\"static-transcript-window/search\"],[[\"filter\",\"selectActiveMatch\",\"activeMatch\",\"numberOfMatches\"],[[22,[\"filter\"]],[22,[\"selectActiveMatch\"]],[22,[\"activeMatch\"]],[22,[\"numberOfMatches\"]]]]],[26,\"component\",[\"static-transcript-window/history\"],[[\"engagement\",\"activeTranscript\"],[[22,[\"engagement\"]],[22,[\"activeTranscript\"]]]]],[26,\"component\",[\"static-transcript-window/transcript\"],[[\"engagement\",\"messages\",\"filter\",\"scrollToItem\",\"onTranscriptLinesRendered\"],[[22,[\"engagement\"]],[22,[\"activeTranscriptMessages\"]],[22,[\"filter\"]],[22,[\"scrollToItem\"]],[26,\"action\",[[21,0,[]],\"onTranscriptLineRendered\"],null]]]],[22,[\"filter\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"static-transcript-window/search\",null,[[\"filter\",\"selectActiveMatch\",\"activeMatch\",\"numberOfMatches\"],[[22,[\"filter\"]],[22,[\"selectActiveMatch\"]],[22,[\"activeMatch\"]],[22,[\"numberOfMatches\"]]]]],false],[0,\"\\n  \"],[1,[26,\"static-transcript-window/history\",null,[[\"engagement\",\"activeTranscript\"],[[22,[\"engagement\"]],[22,[\"activeTranscript\"]]]]],false],[0,\"\\n  \"],[1,[26,\"static-transcript-window/transcript\",null,[[\"engagement\",\"messages\",\"filter\",\"scrollToItem\",\"onTranscriptLinesRendered\"],[[22,[\"engagement\"]],[22,[\"activeTranscriptMessages\"]],[22,[\"filter\"]],[22,[\"scrollToItem\"]],[26,\"action\",[[21,0,[]],\"onTranscriptLineRendered\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/static-transcript-window/template.hbs"
    }
  });
});