define("sdk/services/quill-instance-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    setEditorInstance: function setEditorInstance(editor) {
      this.set('currentInstance', editor);
    },
    setInternalInstance: function setInternalInstance(editor) {
      this.set('internalInstance', editor);
    },
    insertText: function insertText(index, text) {
      var _this = this;
      var quillInstance = this.get('currentInstance');
      var length = quillInstance.selection.savedRange.length;
      quillInstance.deleteText(index, length);
      quillInstance.insertText(index, text);
      Ember.run.scheduleOnce('afterRender', function () {
        var newIndex = index + text.length;
        Ember.$('.ql-editor').focus();
        _this.updateFocusPosition(newIndex);
      });
    },
    focus: function focus() {
      if (!this.get('currentInstance')) return;
      this.updateFocusPosition(this.get('currentInstance').getLength());
    },
    updateFocusPosition: function updateFocusPosition(index) {
      this.get('currentInstance').setSelection(index);
    },
    hasEmptyText: function hasEmptyText() {
      var isInternal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var instance = isInternal ? this.get('internalInstance') : this.get('currentInstance');
      if (instance) {
        var text = instance.getText();
        return !text.trim();
      }
    }
  });
});