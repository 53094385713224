define("sdk/components/slideout-menu/component", ["exports", "sdk/core/component", "sdk/components/slideout-menu/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'slideout-menu',
    classNameBindings: ['expanded', 'position'],
    'data-test-slideout-menu': '',
    position: 'right',
    actions: {
      /**
       * Collapses/expands the slideout menu
       * and triggers the willUpdateEngagementUI/didUpdateEngagementUI
       * methods on the site route to make sure the floating elements are
       * updated correctly.
       * @method toggleExpand
       * @public
       */
      toggleExpand: function toggleExpand() {
        this.toggleProperty('expanded');
        var onSlideoutExpandOrCollapse = this.get('onSlideoutExpandOrCollapse');
        onSlideoutExpandOrCollapse && onSlideoutExpandOrCollapse();
      }
    }
  });
});