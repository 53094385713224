define("sdk/templates/modals/-transfer-conference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lXE8zAWn",
    "block": "{\"symbols\":[\"window\"],\"statements\":[[4,\"popup-window\",null,[[\"id\",\"popupClass\",\"ariaLabelledBy\",\"isKeyboardEnabled\",\"hasCloseButton\",\"onClose\",\"onHiddenFocusSelector\",\"defaultLastActiveElement\"],[\"transfer-window\",\"transfer-modal\",\"transfer-window-title\",true,true,[26,\"action\",[[21,0,[]],\"closeTransferPopup\"],null],\"#active-transcript-action__menu\",\"#active-transcript-action__menu\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"transfer-window-title\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"transfer-window.transfer-and-conference\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"transfer-engagement\",null,[[\"engagement\"],[[22,[\"engagement\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-transfer-conference.hbs"
    }
  });
});