define("sdk/components/sortable-group-item/component", ["exports", "ember-sortable/components/sortable-item"], function (_exports, _sortableItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _sortableItem.default.extend({
    attributeBindings: ['aria-labeledby']
  });
});