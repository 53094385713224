define("sdk/sso/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "stVU7H5v",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"layout-row layout-align-center-center\"],[8],[0,\"\\n    \"],[1,[26,\"fa-icon\",[\"spinner\"],[[\"pulse\",\"size\"],[true,3]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"sso__error-message\"],[10,\"aria-live\",\"polite\"],[8],[0,\"\\n\\n    \"],[6,\"p\"],[10,\"data-test-sso-error-title\",\"\"],[8],[1,[26,\"t\",[\"sso.error-message\"],null],false],[9],[0,\"\\n\\n    \"],[6,\"p\"],[10,\"class\",\"sso__error-description\"],[10,\"data-test-sso-error-description\",\"\"],[8],[1,[26,\"t\",[\"sso.error-contact\"],null],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"isShowingDetails\"]]],null,{\"statements\":[[0,\"      \"],[6,\"hr\"],[8],[9],[0,\"\\n      \"],[6,\"p\"],[10,\"class\",\"sso__error-description\"],[10,\"data-test-sso-error-details\",\"\"],[10,\"id\",\"error-description\"],[8],[0,\"\\n        \"],[6,\"span\"],[10,\"class\",\"details\"],[8],[1,[26,\"t\",[\"login-form.details\"],null],false],[0,\":\"],[9],[0,\" \"],[1,[20,\"errorMessage\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[6,\"button\"],[10,\"aria-controls\",\"error-description\"],[11,\"aria-expanded\",[27,[[20,\"isShowingDetails\"]]]],[10,\"class\",\"sso__details-link\"],[10,\"data-test-sso-error-show-details\",\"\"],[3,\"action\",[[21,0,[]],[22,[\"toggleProperty\"]],\"isShowingDetails\"]],[8],[0,\"\\n      \"],[1,[20,\"detailButtonText\"],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"button\"],[10,\"class\",\"sso__error-button\"],[10,\"data-test-sso-error-okay\",\"\"],[3,\"action\",[[21,0,[]],\"transitionToLogin\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"sso.error-okay\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/sso/login/template.hbs"
    }
  });
});