define("sdk/sso/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    url: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this.get('url').setQueryParam('SSO', 'logout');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var error = sessionStorage.getItem('error_invalidate');
      if (!error) return;
      try {
        error = JSON.parse(error);
        // display reason for logging out message
        controller.set('logoutErrorMessage', error.title);
      } catch (e) {} // eslint-disable-line no-empty
    }
  });
});