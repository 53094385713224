define("sdk/components/nested-dropdown/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5gkN3fSX",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[22,[\"options\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[21,1,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"nested-dropdown/menu/nested-option\",[[21,1,[]]],[[\"onSelected\"],[\"onSelected\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[26,\"nested-dropdown/menu/option\",[[21,1,[]]],[[\"onSelected\"],[\"onSelected\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/nested-dropdown/menu/template.hbs"
    }
  });
});