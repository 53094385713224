define("sdk/api/utils/illegal-words-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeIllegalWords = normalizeIllegalWords;
  function normalizeIllegalWords(response) {
    var settings = response.settings,
      groups = response.groups;
    var structure = [];
    settings.forEach(function (agentGroupBusinessUnit) {
      var id = agentGroupBusinessUnit.id,
        groupIds = agentGroupBusinessUnit.groups,
        type = agentGroupBusinessUnit.type;
      var _getWordsAndExpressio = _getWordsAndExpressionsByGroupId(groups, groupIds),
        words = _getWordsAndExpressio.words,
        expressions = _getWordsAndExpressio.expressions,
        disabledExpressions = _getWordsAndExpressio.disabledExpressions;

      // display application error if response contains invalid javascript regex
      if (disabledExpressions.length) {
        var _agentGroupBusinessUnit = type === 'agentGroup' ? 'Agent Group' : 'Business Unit';
        var errorMessage = "Invalid illegal word expression(s) found (".concat(_agentGroupBusinessUnit, "=").concat(id, ")");
        errorMessage += '<ul>' + disabledExpressions.map(function (_ref) {
          var expression = _ref.expression,
            detail = _ref.detail;
          return "<li class=\"illegal-expression\"><span class=\"expression\">".concat(expression, ":</span><span>").concat(detail, "</span></li>");
        }).join('') + '</ul>';
        if (Ember.onerror) {
          Ember.onerror({
            message: errorMessage,
            type: 'warning'
          });
        }
      }
      structure.addObject({
        id: id,
        words: words,
        expressions: expressions
      });
    });
    return structure;
  }
  function _getWordsAndExpressionsByGroupId(groups, groupIds) {
    var enabledWords = [];
    var enabledExpressions = [];
    var disabledExpressions = [];
    groupIds.forEach(function (groupId) {
      var _groups$findBy = groups.findBy('id', groupId.toString()),
        words = _groups$findBy.words,
        expressions = _groups$findBy.expressions;
      enabledWords.addObjects(words);
      var validExpressions = [];
      var invalidExpressions = [];
      expressions.forEach(function (expression) {
        try {
          new RegExp(expression);
          validExpressions.push(expression);
        } catch (error) {
          invalidExpressions.pushObject({
            expression: expression,
            detail: error
          });
        }
      });
      if (invalidExpressions.length) {
        disabledExpressions.addObjects(invalidExpressions);
      }
      enabledExpressions.addObjects(validExpressions);
    });
    return {
      words: enabledWords,
      expressions: enabledExpressions,
      disabledExpressions: disabledExpressions
    };
  }
});