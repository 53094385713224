define("sdk/components/active-transcript-window/component", ["exports", "sdk/core/resizable-component", "sdk/components/active-transcript-window/template", "sdk/utils/speak"], function (_exports, _resizableComponent, _template, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _resizableComponent.default.extend({
    layout: _template.default,
    componentName: 'active-transcript',
    intl: Ember.inject.service(),
    engagementLayoutManager: Ember.inject.service(),
    quillInstanceManager: Ember.inject.service(),
    classNames: ['active-engagement-container', 'transcript-window'],
    classNameBindings: ['isAsync:engagement--asynchronous:engagement--synchronous', 'isNinaCoach:engagement--nina-coach'],
    attributeBindings: ['role', 'aria-label'],
    resizeMinHeight: 250,
    resizeHandles: 's',
    role: 'region',
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    onResize: function onResize(ui) {
      var resizeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var $transcriptWindow = Ember.$('.nuance-transcript-window .mnstr');
      var $scrollTop = $transcriptWindow.scrollTop();
      var $scrollHeight = $transcriptWindow.prop('scrollHeight');
      var $height = $transcriptWindow.outerHeight(true);
      var heightChange = resizeOptions.heightChange;
      var resizeChange = Math.abs(heightChange < 0 ? heightChange : 10);
      var isTranscriptScrolledToBottom = $scrollTop + $height + resizeChange >= $scrollHeight - 5;
      if (isTranscriptScrolledToBottom) {
        this.incrementProperty('scrollToBottomIncrement');
      }
    },
    'aria-label': Ember.computed.alias('chatHeaderText'),
    ui: Ember.computed.alias('engagement.ui'),
    isAsync: Ember.computed.alias('engagement.settings.asyncChat'),
    isNinaCoach: Ember.computed.alias('engagement.isNinaCoach'),
    chatHeaderText: Ember.computed('engagement.chatTypeInfo', 'intl.locale', function () {
      var _this$get = this.get('engagement.chatTypeInfo'),
        _this$get$translateKe = _this$get.translateKey,
        translateKey = _this$get$translateKe === void 0 ? '' : _this$get$translateKe;
      var engagementConversationText = this.get('isAsync') ? this.get('intl').t('active-engagement.conversation') : this.get('intl').t('active-engagement.engagement');
      return this.get('intl').t(translateKey, {
        chat: engagementConversationText
      });
    }),
    /**
     * Whether or not to display the join button.
     * @property {Boolean} shouldDisplayJoinButton
     * @public
     */
    shouldDisplayJoinButton: Ember.computed('engagement.{isConferenceMode,joinIsRequired,joinedConference,isNinaCoach}', function () {
      var _this$get$getProperti = this.get('engagement').getProperties('isConferenceMode', 'joinIsRequired', 'joinedConference', 'isNinaCoach'),
        isConferenceMode = _this$get$getProperti.isConferenceMode,
        joinIsRequired = _this$get$getProperti.joinIsRequired,
        joinedConference = _this$get$getProperti.joinedConference,
        isNinaCoach = _this$get$getProperti.isNinaCoach;
      return isConferenceMode && joinIsRequired && !(joinedConference || isNinaCoach);
    }),
    /**
     * Whether or not to hide the agent input.
     * @property {Boolean} shouldHideTranscriptInput
     * @public
     */
    shouldHideTranscriptInput: Ember.computed.or('shouldDisplayJoinButton', 'engagement.screeningMode', 'isNinaCoach'),
    shouldShowTranscriptInput: Ember.computed.not('shouldHideTranscriptInput'),
    /**
     * Whether or not to display the emoji toolbar
     * @property {Boolean} isEnabledEmojis
     * @public
     */
    isEnabledEmojis: Ember.computed.and('shouldShowTranscriptInput', 'engagement.settings.enableEmojis'),
    /**
     * Whether or not to display the quick file upload
     * @property {Boolean} shouldShowFileUpload
     * @public
     */
    shouldShowFileUpload: Ember.computed.and('shouldShowTranscriptInput', 'engagement.settingWithFileUpload'),
    /**
     * Whether or not to display the rich text formatting toolbar
     * @property {Boolean} displayFormattingToolbar
     * @public
     */
    displayFormattingToolbar: Ember.computed('shouldHideTranscriptInput', 'engagement.settings.textFormatting', function () {
      var hasRichTextFormatting = this.get('engagement.settings.textFormatting');
      if (hasRichTextFormatting) return !this.get('shouldHideTranscriptInput');
      return false;
    }),
    /**
     * The current engagement's id
     * @property {String} currentEngagementId
     * @public
     */
    currentEngagementId: Ember.computed.alias('engagement.id'),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('engagementLayoutManager').on('staticTranscriptExpand', this, this._onStaticTranscriptExpand);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this._engagementCache !== this.engagement) {
        this._engagementCache = this.engagement;
        this._setScrollOptions();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('api').on('agentMessage', this, this._updateAriaPressedValues);
    },
    toast: function toast(text) {
      var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
      this.$('#active-engagement-toast').text(text).fadeIn(400).delay(duration).fadeOut(400);
      (0, _speak.default)(text);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('api').off('agentMessage', this, this._updateAriaPressedValues);
      this.get('engagementLayoutManager').off('staticTranscriptExpand', this, this._onStaticTranscriptExpand);
      this.$('.ql-picker-label, .ql-picker-options').off();
      this.$('.ql-editor').off();
    },
    actions: {
      handleKeyDown: function handleKeyDown(event) {
        var handleKeyDown = this.get('handleKeyDown');
        handleKeyDown && handleKeyDown(event);
      },
      handleTabKey: function handleTabKey(event) {
        var handleTabKey = this.get('handleTabKey');
        handleTabKey && handleTabKey(event);
      },
      resetHotkey: function resetHotkey() {
        var resetHotkey = this.get('resetHotkey');
        resetHotkey && resetHotkey(arguments);
      },
      followUp: function followUp() {
        var onFollowUp = this.get('onFollowUp');
        onFollowUp && onFollowUp(arguments);
      },
      resolve: function resolve() {
        var onResolve = this.get('onResolve');
        onResolve && onResolve(arguments);
      },
      close: function close() {
        var onClose = this.get('onClose');
        onClose && onClose(arguments);
      },
      onKeyPressRichText: function onKeyPressRichText() {
        this._updateAriaPressedValues();
        this._speakRichFormatting();
      },
      onKeyPressFormats: function onKeyPressFormats() {
        this._enableAdaCompliantColorPicker();
      },
      /**
       * Will join a conference
       * @method joinConference
       */
      joinConference: function joinConference() {
        this.get('engagement').joinConference();
      },
      copyMaskedTranscript: function copyMaskedTranscript(textTranscript) {
        this.get('api').trigger('transcriptCopied', textTranscript);
        var copiedNotificationText = this.get('intl').t('active-engagement.copy-transcript-notification');
        this.toast(copiedNotificationText);
      },
      /**
       * When agent resizes the agent-input, we must notify the scroll-render component to
       * display newly visible messages
       * @method onAgentInputResize
       */
      onAgentInputResize: function onAgentInputResize(ui) {
        var resizeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var heightChange = resizeOptions.heightChange;
        this.set('transcriptHeightChange', heightChange);
      },
      handleEmojiSelect: function handleEmojiSelect(emojiValue) {
        var savedRange = this.get('quillInstanceManager.currentInstance').selection.savedRange;
        var index = savedRange.index;
        this.get('quillInstanceManager').insertText(index, emojiValue);
      },
      handleFileUpload: function handleFileUpload(show) {
        var _this$get2 = this.get('engagement'),
          engagementId = _this$get2.id,
          send = _this$get2.send,
          settingWithFileUpload = _this$get2.settingWithFileUpload;
        send({
          agentId: this.get('api.agent.userId'),
          command: show ? 'xform to grow.fileUpload' : 'xform to hide.fileUpload',
          engagementId: engagementId,
          messageText: show ? this.get('intl').t('active-engagement.show-file-upload') : this.get('intl').t('active-engagement.hide-file-upload'),
          messageType: 'script',
          scriptTreeId: settingWithFileUpload.scriptTreeId,
          scriptType: 'command'
        });
      }
    },
    /**
     * Scrolls to the bottom of the transcript window if a customer message was received
     * while in view of another engagement
     * @method _setScrollOptions
     * @private
     */
    _setScrollOptions: function _setScrollOptions() {
      var hasListRenderOptions = !!this.engagement.ui.activeTranscriptListRenderOptions;
      if (!hasListRenderOptions) {
        this.set('engagement.ui.activeTranscriptListRenderOptions', Ember.Object.create());
      }
      var shouldScrollToBottom = this.engagement.ui.activeTranscriptHasNewMessage || !hasListRenderOptions;
      if (shouldScrollToBottom) {
        this.incrementProperty('scrollToBottomIncrement');
      } else {
        this.set('scrollToBottomIncrement', 0);
      }
      this.set('shouldScrollToBottom', shouldScrollToBottom);
    },
    /**
     * Enable accessibility for color picker
     * @method _enableAdaCompliantColorPicker
     * @private
     */
    _enableAdaCompliantColorPicker: function _enableAdaCompliantColorPicker() {
      var _this = this;
      var hasTriggeredMouseDown = false;
      this.$('.ql-picker-label').on('mousedown', function () {
        hasTriggeredMouseDown = true;
      });

      // Screen reader fires a click event for Space/Enter keys rather
      // than a keydown event. Quill does not handle this event.
      this.$('.ql-picker-label').on('click', function () {
        // Prevent toggle from being called twice.
        if (hasTriggeredMouseDown) {
          hasTriggeredMouseDown = false;
          return;
        }
        this.dispatchEvent(new CustomEvent('mousedown'));
      });
      this.$('.ql-picker-label').on('keydown', function (event) {
        if (event.keyCode === 32) {
          this.dispatchEvent(new CustomEvent('mousedown'));
        }
      });
      this._colorNamePicker();
      this.$('.ql-picker-options').on('keydown', function (event) {
        if (event.keyCode === 32) {
          event.preventDefault();
          event.target.dispatchEvent(new CustomEvent('click'));
        }
      });

      // prevent svg tab on IE
      this.$('.ql-toolbar svg').attr('focusable', 'false');
      this.$('.ql-picker-options').click(function () {
        _this._updateColorPickerAttributes();
      });
      this.$('.ql-editor').focus(function () {
        _this._updateAriaPressedValues();
        _this._updateColorPickerAttributes();
        Ember.run.next(_this, function () {
          _this._speakRichFormatting();
        });
      });
    },
    /**
     * Will select assign color name to title and aria-label
     * @method _colorNamePicker
     */
    _colorNamePicker: function _colorNamePicker() {
      var scope = this;
      var closeTooltip = this.get('intl').t('color.no-color');
      this.$('.ql-picker-options').children().each(function (index) {
        var dataValue = Ember.$(this).attr('data-value');
        var colorName = scope._tooltipColorSelection(dataValue);
        Ember.$(this).attr('aria-pressed', index === 0 ? 'true' : 'false');
        var highlightValue = index === 0 ? closeTooltip : colorName;
        Ember.$(this).attr('title', highlightValue).attr('aria-label', highlightValue);
      });
    },
    /**
     * Will select color name based on hexcode value
     * @method _colorNamePicker
     */
    _tooltipColorSelection: function _tooltipColorSelection(hexColor) {
      switch (hexColor) {
        case '#FFFF00':
          return this.get('intl').t('color.yellow');
        case '#C4FF0E':
          return this.get('intl').t('color.green');
        case '#7EE6E2':
          return this.get('intl').t('color.blue');
        case '#FFAEC8':
          return this.get('intl').t('color.pink');
        case '#FFCA18':
          return this.get('intl').t('color.orange');
        case '#C3C3C3':
          return this.get('intl').t('color.gray');
        default:
          return 'no-error';
      }
    },
    _speakRichFormatting: function _speakRichFormatting() {
      var screenReaderText = '';
      var richFormatting = {
        bold: this.get('isBold'),
        italic: this.get('isItalic'),
        underlined: this.get('isUnderlined')
      };
      Object.keys(richFormatting).forEach(function (key) {
        if (richFormatting[key]) {
          screenReaderText += "".concat(key, " is active. ");
        }
      });
      var colorPickerHighlight = this.get('colorPickerHighlight');
      if (colorPickerHighlight && colorPickerHighlight !== 'highlight off') {
        screenReaderText += this.get('colorPickerHighlight');
      }
      (0, _speak.default)(screenReaderText);
    },
    _updateColorPickerAttributes: function _updateColorPickerAttributes() {
      this.$('.ql-picker-options').children().each(function () {
        Ember.$(this).attr('aria-pressed', 'false');
      });
      var dataValue = this.$('.ql-picker-label').attr('data-value');
      var $selectedNode = this.$(".ql-picker-options [data-value=\"".concat(dataValue, "\"]"));
      var titleValue = $selectedNode.attr('title') || 'highlight off';
      this.set('colorPickerHighlight', titleValue);
      if ($selectedNode.length > 0) {
        $selectedNode.attr('aria-pressed', 'true');
      } else {
        this.$('.ql-picker-item').first().attr('aria-pressed', 'true');
      }
      this.$('.ql-picker-label').attr('aria-label', "color picker, ".concat(titleValue, " selected"));
    },
    _updateAriaPressedValues: function _updateAriaPressedValues() {
      var _this2 = this;
      var toggleButtons = {
        isBold: '.ql-bold',
        isItalic: '.ql-italic',
        isUnderlined: '.ql-underline'
      };
      Object.keys(toggleButtons).forEach(function (key) {
        _this2.set(key, _this2.$(toggleButtons[key]).hasClass('ql-active'));
      });
    },
    _onStaticTranscriptExpand: function _onStaticTranscriptExpand() {
      var activeTranscriptMnstrNode = this.element.querySelector('.active-engagement-container .mnstr');
      var isActiveTranscriptScrolledToBottom = Math.abs(activeTranscriptMnstrNode.scrollTop + activeTranscriptMnstrNode.clientHeight - activeTranscriptMnstrNode.scrollHeight) <= 1;
      if (isActiveTranscriptScrolledToBottom) {
        this.incrementProperty('scrollToBottomIncrement');
      }
    }
  });
});