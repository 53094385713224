define("sdk/components/sdk-scripts/script-tree-select/component", ["exports", "sdk/core/component", "sdk/components/sdk-scripts/script-tree-select/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Script tree dropdown selector
   *
   * Displays a list of script trees and
   * passes the selected script tree up to the parent component
   *
   * @class Scripts.ScriptTreeSelect
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'script-tree-select',
    sortedScriptTrees: Ember.computed.sort('scriptTrees', 'sortDefinition'),
    sortDefinition: Object.freeze(['name']),
    actions: {
      /**
       * Passes the selected script tree up to the parent component
       * @method onScriptTreeSelected
       * @param scriptTree - script tree selected
       * @private
       */
      onScriptTreeSelected: function onScriptTreeSelected(scriptTree) {
        this.set('selected', scriptTree);
        var onScriptTreeSelected = this.get('onScriptTreeSelected');
        onScriptTreeSelected && onScriptTreeSelected(scriptTree);
      }
    }
  });
});