define("sdk/site/summary/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sdk/api/utils/runloop"], function (_exports, _authenticatedRouteMixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this.get('api.metrics').startRealtimePolling();
    },
    afterModel: function afterModel() {
      // will not start polling if true
      if (!window.runningTests) {
        var interval = 1000 * 10;
        this.set('interval', (0, _runloop.later)(this, function () {
          this.afterModel();
        }, interval));
      }
      var agent = this.get('api.agent');
      agent.getSummaryData();
    },
    actions: {
      willTransition: function willTransition(transition) {
        // eslint-disable-line no-unused-vars
        if (this.get('interval')) {
          Ember.run.cancel(this.get('interval'));
        }
        this.get('api.metrics').stopRealtimePolling();
        return true;
      }
    }
  });
});