define("sdk/services/engagement-focus-manager", ["exports", "sdk/utils/is-element-tabbable", "sdk/utils/get-first-focusable-node", "sdk/utils/get-highest-focus-index-node"], function (_exports, _isElementTabbable, _getFirstFocusableNode, _getHighestFocusIndexNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    quillInstanceManager: Ember.inject.service(),
    hasFocus: function hasFocus() {
      var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return document.activeElement.matches(selector);
    },
    focusAgentInput: function focusAgentInput() {
      this.get('quillInstanceManager').focus();
    },
    focusFirstRecommendation: function focusFirstRecommendation() {
      var firstRecommendationElement = document.querySelector('.agent-coach__recommendations button:not([disabled])');
      if (!firstRecommendationElement) return;
      (0, _isElementTabbable.default)(firstRecommendationElement) && this._setFocus(firstRecommendationElement);
    },
    setInitialFocus: function setInitialFocus(element) {
      var highestFocusIndexElement = (0, _getHighestFocusIndexNode.default)(element);
      var firstFocusableElement = (0, _getFirstFocusableNode.default)(highestFocusIndexElement || element);
      var isAgentInput = firstFocusableElement && firstFocusableElement.classList.contains('ql-editor');
      isAgentInput ? this.focusAgentInput() : this._setFocus(firstFocusableElement);
    },
    removeFocusFromScript: function removeFocusFromScript() {
      var shouldBlurActiveElement = document.activeElement && document.querySelector('.scripts-container') && document.querySelector('.scripts-container').contains(document.activeElement);
      if (shouldBlurActiveElement) {
        document.activeElement.blur();
      }
    },
    _setFocus: function _setFocus(element) {
      element && element.focus();
    }
  });
});