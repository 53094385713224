define("sdk/sso/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    url: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      this._super.apply(this, arguments);
      var url = this.get('url');

      // SSO Query Params
      var _url$getQueryParams = url.getQueryParams(),
        SSO = _url$getQueryParams.SSO,
        state = _url$getQueryParams.state,
        transientToken = _url$getQueryParams.authToken;

      // SSO state: login or relogin (transitioned from sso.logout or sso.lockout)
      var isRelogin = ['logout', 'lockout'].includes(SSO);
      if (isRelogin) {
        url.setQueryParam('SSO', "".concat(SSO, "-relogin"), {
          pushState: true
        });
      } else {
        url.setQueryParam('SSO', 'login');
      }

      // Check to see if sessionStorage state matches state passed from query parameter for additional security
      var hasLocalState = state && state === sessionStorage.getItem('ssoState');
      var controller = this.controllerFor(this.get('routeName'));
      if (!transientToken) {
        return this.get('api.authAdapter').redirectToIdp().catch(function (errorResponse) {
          controller.set('errorMessage', getErrorMessage(errorResponse));
        });
      } else if (hasLocalState) {
        // remove "state" and "authToken" queryParams passed from IDP when logging in via SSO
        url.removeQueryParams(['state', 'authToken']);
        var shouldRestoreSession = SSO === 'lockout-relogin';
        return this.get('api.authAdapter').authenticate({
          transientToken: transientToken
        }).then(function (authResponse) {
          return _this.get('session').authenticate('authenticator:tc', {
            authResponse: authResponse
          }, {
            shouldRestoreSession: shouldRestoreSession
          }).catch(function (errorResponse) {
            if (errorResponse.isAdvancedAgentConfigurationRequired) {
              _this.transitionTo('agent-group-filter');
              return;
            }
            throw errorResponse;
          });
        }).catch(function (errorResponse) {
          controller.set('errorMessage', getErrorMessage(errorResponse));
        });
      } else {
        controller.set('errorMessage', 'Unrecognized SSO url');
      }
    }
  });
  function getErrorMessage(errorResponse) {
    var payload = errorResponse.payload,
      _errorResponse$respon = errorResponse.response,
      response = _errorResponse$respon === void 0 ? {} : _errorResponse$respon;
    if (payload) return payload.error_description || payload.detail;
    return response.message || 'Unknown';
  }
});