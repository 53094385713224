define("sdk/api/utils/nina-proxy-message-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
  * Parses Nina Proxy message object into a normalized message
  */
  function _default(message) {
    var maskedText = message.maskedText,
      messageText = message.messageText,
      timestamp = message.timestamp;
    if (maskedText) {
      var _parseNinaProxyText = parseNinaProxyText(maskedText),
        parsedMaskedText = _parseNinaProxyText.parsedText;
      maskedText = parsedMaskedText;
    }
    var _parseNinaProxyText2 = parseNinaProxyText(messageText),
      parsedMessageText = _parseNinaProxyText2.parsedText,
      parsedTimestamp = _parseNinaProxyText2.timestamp;
    messageText = parsedMessageText;
    timestamp = parsedTimestamp || timestamp;
    return Object.assign(message, {
      maskedText: maskedText,
      messageText: messageText,
      timestamp: timestamp
    });
  }

  /**
   * Attempts to parse Nina Proxy message and return
   * @method parseNinaProxyText
   * @param {String} text
   * @return {Object} text: parsed UserText value, timestamp: parsed timestamp
   */
  function parseNinaProxyText() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var parsedText = text;
    var timestamp;
    try {
      var ninaProxyObj = text.match(/{.*}/)[0];
      var isTalkAgentRequest = text.startsWith('TalkAgentRequest=');
      var isTalkAgentResponse = text.includes('"TalkAgentResponse":{');
      if (isTalkAgentRequest) {
        var _JSON$parse = JSON.parse(ninaProxyObj),
          _JSON$parse$TalkAgent = _JSON$parse.TalkAgentRequest,
          proxyUserText = _JSON$parse$TalkAgent.UserText,
          proxyTimestamp = _JSON$parse$TalkAgent['@TimeStamp'];
        parsedText = proxyUserText || text;
        timestamp = proxyTimestamp;
      } else if (isTalkAgentResponse) {
        var _JSON$parse2 = JSON.parse(ninaProxyObj),
          _JSON$parse2$TalkAgen = _JSON$parse2.TalkAgentResponse,
          _proxyUserText = _JSON$parse2$TalkAgen.Display.OutText['#text'],
          _proxyTimestamp = _JSON$parse2$TalkAgen['@TimeStamp'];
        parsedText = _proxyUserText || text;
        timestamp = _proxyTimestamp;
      }
      return {
        parsedText: parsedText,
        timestamp: timestamp
      };
    } catch (error) {
      // display error if nina proxy message cannot be parsed
      error.message = "Unable to parse Nina proxy message: \"".concat(text, "\" \nExperienced error: ").concat(error.message);
      Ember.onerror(error);
    }
  }
});