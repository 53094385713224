define("sdk/api/illegal-words", ["exports", "sdk/api/utils/parse-html"], function (_exports, _parseHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Illegal Words
   * @public
   */
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('settingsLoaded', this, this._onIllegalWordsSettingsLoaded);
    },
    /**
     * When api settings are loaded, retrieve illegal words
     * @method _onIllegalWordsSettingsLoaded
     */
    _onIllegalWordsSettingsLoaded: function _onIllegalWordsSettingsLoaded() {
      var _this = this;
      var api = this.get('api');
      return api.illegalWordsAdapter.getList().then(function (illegalWords) {
        _this.set('illegalWords', illegalWords);
        api.trigger('illegalWordsLoaded', illegalWords);
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api').off('settingsLoaded', this, this._onIllegalWordsSettingsLoaded);
    },
    /**
     * finds first illegal word in text if any
     * @method findIllegal
     * @param {Number} agentGroupOrBusinessUnitId agent group or business unit Id to retrieve list from
     * @param {String} text text to check if illegal words are contained in
     */
    findIllegal: function findIllegal(agentGroupOrBusinessUnitId, text) {
      try {
        var list = this.getList(agentGroupOrBusinessUnitId);
        var words = list.words,
          expressions = list.expressions;
        return findWord(words, text) || findExpression(expressions, text);
      } catch (e) {
        // display the error and return null
        // so the message gets sent anyway
        Ember.onerror(e);
        return null;
      }
    },
    getList: function getList(agentGroupOrBusinessUnitId) {
      var illegalWords = this.get('illegalWords');
      if (!illegalWords) {
        throw new Error('illegal words are not loaded');
      }
      var list = illegalWords.findBy('id', agentGroupOrBusinessUnitId);
      if (!list) {
        throw new Error("no list found for ".concat(agentGroupOrBusinessUnitId));
      }
      return list;
    }
  });
  function findWord(words, text) {
    text = _parseHtml.default.replacePAndBrTagsWithLineBreaks(text);
    text = _parseHtml.default.removeHtmlTags(text);
    var textArr = text.toLowerCase().split(/[^0-9a-zâàèîôêñáéíóúü]/);
    for (var i in words) {
      if (typeof words[i] === 'string' && textArr.includes(words[i].toLowerCase())) {
        return words[i];
      }
    }
    return null;
  }
  function findExpression(expressions, text) {
    text = _parseHtml.default.removeHtmlTags(text);
    var collectiveExpression = new RegExp(expressions.join('|'), 'i');
    var results = collectiveExpression.exec(text);
    return results ? results.join(', ') : null;
  }
});