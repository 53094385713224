define("sdk/api/adapters/rtapi-adapter", ["exports", "sdk/api/adapters/api-adapter", "sdk/api/utils/transcript-serializer", "sdk/api/models/transcript-line-historic", "sdk/api/utils/agent", "sdk/api/utils/message", "moment"], function (_exports, _apiAdapter, _transcriptSerializer, _transcriptLineHistoric, _agent, _message, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalizeMessages = normalizeMessages;
  /**
   * @class RealtimeTranscriptAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    basicAuth: true,
    name: 'RTAPI',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.REALTIME_TRANSCRIPT_API;
    },
    /**
     * Retrieves a list of engagements from the API
     * filtered by customer id
     * @method getTranscriptListByCustomerId
     * @public
     * @param {String} filter the search filter
     * @param {String} siteId the site ID
     */
    getTranscriptListByFilter: function getTranscriptListByFilter(filter, siteId) {
      var query = {
        site: siteId,
        filter: filter,
        returnFields: 'engagementID,customerID,startDate,siteID'
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        if (response.engagements) {
          response.engagements = response.engagements.map(function (e) {
            e.source = 'realtime';
            e.startTime = e.startDate.timestamp;
            return Ember.Object.create(e);
          });
        }
        return response;
      });
    },
    getEngagements: function getEngagements() {
      var _this = this;
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var engagementId = options.engagementId,
        filter = options.filter,
        _options$filterAttrib = options.filterAttributes,
        filterAttributes = _options$filterAttrib === void 0 ? [] : _options$filterAttrib,
        siteId = options.siteId;
      var query = {
        site: siteId,
        filter: filter,
        returnFields: 'agents,conversationID,customerID,engagementID,siteID,startDate,transcript'
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        var _response$engagements = response.engagements,
          engagements = _response$engagements === void 0 ? [] : _response$engagements;
        return engagements.map(function (_ref) {
          var agents = _ref.agents,
            conversationID = _ref.conversationID,
            engagementID = _ref.engagementID,
            startDate = _ref.startDate,
            transcript = _ref.transcript;
          return {
            agents: agents,
            conversationId: conversationID,
            id: engagementID,
            source: 'realtime',
            startTime: Number(startDate.timestamp),
            messages: normalizeMessages(transcript, {
              agents: agents,
              currentAgentId: _this.get('api.agent.userId'),
              engagementId: engagementId,
              filterAttributes: filterAttributes,
              source: 'realtime'
            })
          };
        });
      });
    },
    /**
     * Retrieves agents associated with the engagement
     * @method getEngagementAgents
     * @public
     * @param {String} engagementId value is applied to api search filter
     * @param {Object} options additional optional params
     * @param {String} options.siteId the site ID
     */
    getEngagementAgents: function getEngagementAgents(engagementId) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _options$siteId = options.siteId,
        siteId = _options$siteId === void 0 ? '' : _options$siteId;
      var query = {
        site: siteId,
        filter: "engagementID=\"".concat(engagementId, "\""),
        returnFields: 'agents'
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        var _response$engagements2 = response.engagements,
          engagements = _response$engagements2 === void 0 ? [] : _response$engagements2;
        var agents = engagements[0] && engagements[0].agents;
        return agents;
      });
    },
    /**
     * Retrieves an engagement transcript from the API by id
     * @method getEngagement
     * @public
     * @param {String} engagementId value is applied to api search filter
     * @param {Object} options additional optional params
     * @param {String} options.site the site ID
     */
    getEngagement: function getEngagement(engagementId) {
      var _this2 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _options$filterAttrib2 = options.filterAttributes,
        filterAttributes = _options$filterAttrib2 === void 0 ? [] : _options$filterAttrib2,
        _options$site = options.site,
        site = _options$site === void 0 ? '' : _options$site;
      var query = {
        site: site,
        filter: "engagementID=\"".concat(engagementId, "\""),
        returnFields: 'transcript,agents'
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        var _response$engagements3 = response.engagements,
          engagements = _response$engagements3 === void 0 ? [] : _response$engagements3;
        var _ref2 = engagements[0] || {},
          agents = _ref2.agents,
          _ref2$transcript = _ref2.transcript,
          transcript = _ref2$transcript === void 0 ? [] : _ref2$transcript;
        return {
          agents: agents,
          messages: normalizeMessages(transcript, {
            agents: agents,
            currentAgentId: _this2.get('api.agent.userId'),
            filterAttributes: filterAttributes,
            source: 'realtime'
          })
        };
      });
    }
  }); // TODO replace this function in favor of a universal messages normalizer i.e transcript-serializer/normalizeMessages
  function normalizeMessages(messages) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var agents = options.agents,
      currentAgentId = options.currentAgentId,
      engagementId = options.engagementId,
      _options$filterAttrib3 = options.filterAttributes,
      filterAttributes = _options$filterAttrib3 === void 0 ? [] : _options$filterAttrib3,
      source = options.source;
    var normalizedHistoricTranscriptLines = [];
    messages.forEach(function (line) {
      var messageText = line.content || line.systemInfo || line.datapass || line.pageMarker;
      var sender;
      var type;
      var prefix;
      var formattedTimestamp;
      var message = {};
      var isAgentJoinsChat = ['AGENT_ENTERS_CHAT_DUE_TRANSFER_IN_FULL_MODE', 'AGENT_ENTERS_CONFERENCE_FULL_MODE', 'AGENT_ENTERS_CONFERENCE_SCREENING_MODE', 'AGENT_JOINS_CHAT', 'chat.agentEnterChat'].includes(line.type);
      var hasTransferOrConferenceNotes = line.content && line.type && (0, _message.isTransferOrConferenceNotes)(line.content, line.type);
      if (line.timestamp && messageText) {
        formattedTimestamp = (0, _moment.default)(line.timestamp).format('HH:mm:ss');
      }
      if (line.senderId === '_opener_') {
        type = 'agent';
        prefix = line.senderName;
        sender = 'agent';
      } else if (hasTransferOrConferenceNotes) {
        type = 'transfer-conference-notes';
      } else if (line.type === 'AGENT_INTERNAL_MESSAGE' || line.type === 'chat.agentChatlineSent' && messageText.startsWith('(internal only) ')) {
        prefix = line.senderAlias || line.senderId || line.senderName;
        sender = 'agent-internal';
      } else if (isAgentJoinsChat) {
        type = 'system';
        prefix = line.type;
        if (filterAttributes.length) {
          var otherAgentsAttributes = (0, _agent.getOtherAgentsAttributes)(agents, {
            currentAgentId: currentAgentId,
            filterAttributes: filterAttributes
          });
          message.messageAttributes = otherAgentsAttributes.findBy('agentId', line.senderId);
        }
      } else if (line.type === 'AGENT_MESSAGE') {
        type = 'agent';
        prefix = line.senderName || line.senderId;
        sender = 'agent';
      } else if (line.type === 'CLIENT_MESSAGE') {
        type = 'customer';
        prefix = line.senderName;
        sender = 'customer';
      } else if (line.type === 'MARKER_PAGE') {
        prefix = 'Page Marker';
        type = 'system';
      } else if (line.type === 'chat.clickstream' && line.pageMarker && line.pageURL && !line.datapass) {
        prefix = line.content ? 'System' : 'Page Marker';
        type = 'system';
      } else if ((line.type === 'CLICKSTREAM' || line.type === 'chat.clickstream') && messageText.includes(':')) {
        var _getPrefixAndMessageT = (0, _transcriptSerializer.getPrefixAndMessageText)(messageText);
        prefix = _getPrefixAndMessageT.prefix;
        messageText = _getPrefixAndMessageT.messageText;
        if (prefix === 'SAML.Datapass') {
          message.isSAMLDatapass = true;
        }
        type = 'system';
      } else if (line.type === 'UTILITY_MESSAGE' || line.type === 'chat.supervisorMessageSent') {
        prefix = line.senderId;
        sender = 'supervisor';
      } else if ((line.senderName || line.senderId) && messageText) {
        if (line.senderName === 'supervisor') {
          type = 'system';
          prefix = line.senderAlias || line.senderId || '';
        } else if (['agent', 'customer', 'system', 'opener'].includes(line.senderName)) {
          type = line.senderName;
          prefix = line.senderAlias || line.senderId || line.senderName || '';
        } else if (messageText) {
          type = 'system';
          prefix = line.type;
        }
      } else if (messageText) {
        return;
      } else if (!(messageText || type || prefix)) {
        // doesn't add empty message
        return;
      }
      message = Object.assign(message, {
        engagementId: engagementId,
        formattedTimestamp: formattedTimestamp,
        messageText: messageText,
        prefix: prefix,
        source: source,
        type: type
      });
      if (sender) message.sender = sender;
      normalizedHistoricTranscriptLines.pushObject(_transcriptLineHistoric.default.create(message));
    });
    return normalizedHistoricTranscriptLines;
  }
});