define("sdk/services/api", ["exports", "sdk/api", "sdk/plugins/open-page/plugin", "sdk/plugins/last-survey/plugin", "sdk/plugins/link-request/plugin"], function (_exports, _api, _plugin, _plugin2, _plugin3) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ComponentLibrary$def = ComponentLibrary.default,
    store = _ComponentLibrary$def.store,
    APIActions = _ComponentLibrary$def.APIActions;
  function updateStore(key, payload) {
    switch (key) {
      case 'AGENT_STATE':
        store.dispatch(APIActions.updateAgent(payload));
        break;
      case 'AGENT_METHODS':
        store.dispatch(APIActions.updateAgentMethods(payload));
        break;
      case 'ENGAGEMENTS_STATE':
        payload.willRemove ? (store.dispatch(APIActions.removeEngagement(payload)), store.dispatch(APIActions.removeEngagementMethods(payload))) : store.dispatch(APIActions.updateEngagements(payload));
        break;
      case 'ENGAGEMENTS_METHODS':
        store.dispatch(APIActions.addEngagementMethods(payload));
        break;
      case 'CURRENT_TIME':
        store.dispatch(APIActions.updateCurrentTime(payload));
        break;
      case 'METRICS_STATE':
        store.dispatch(APIActions.updateMetrics(payload));
        break;
      case 'RESET_API':
        store.dispatch(APIActions.reset());
        break;
    }
  }
  var _default = _exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var api = _api.default.create();
      api.stateManager.setupStore(updateStore);
      this.set('__api__', api);
      Object.keys(api).forEach(function (key) {
        _this[key] = Ember.computed.alias("__api__.".concat(key));
      });
      this.on = api.on.bind(api);
      this.off = api.off.bind(api);
      this.one = api.one.bind(api);
      this.trigger = api.trigger.bind(api);
      this.unload = api.unload.bind(api);
      this.abortAndBlockAllRequests = api.abortAndBlockAllRequests.bind(api);
      api.plugins.register(_plugin.default);
      api.plugins.register(_plugin2.default);
      api.plugins.register(_plugin3.default);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.__api__.off('updateStore', updateStore);
    }
  });
});