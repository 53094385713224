define("sdk/api/utils/custom-scripts", ["exports", "sdk/api/models/script-section", "sdk/api/models/script-line", "sdk/utils/grammar"], function (_exports, _scriptSection, _scriptLine, _grammar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasValidCustomScripts = hasValidCustomScripts;
  _exports.serializeCustomScripts = serializeCustomScripts;
  function hasValidCustomScripts() {
    var customScripts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var hasInvalidScripts = customScripts.some(function (script) {
      return (0, _grammar.hasControlCharacter)(script.scriptValue);
    });
    return !hasInvalidScripts;
  }
  function serializeCustomScripts() {
    var customScripts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var scriptLines = [];
    var scriptSections = [];
    var currentSection;
    customScripts.forEach(function (script) {
      var scriptLine = _scriptLine.default.create(Object.assign({
        isCustom: true
      }, script));
      var scriptValue = script.scriptValue,
        type = script.type;
      if (type === 'header') {
        currentSection = _scriptSection.default.create();
        currentSection.set('name', scriptValue);
        scriptSections.push(currentSection);
      } else {
        if (!currentSection) {
          currentSection = _scriptSection.default.create();
          scriptSections.push(currentSection);
        }
        currentSection.scripts.pushObject(scriptLine);
      }
      scriptLines.pushObject(scriptLine);
    });
    return {
      scriptLines: scriptLines,
      scriptSections: scriptSections
    };
  }
});