define("sdk/components/nested-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Uqi3ZQsc",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"selected-option\"],[11,\"onClick\",[26,\"action\",[[21,0,[]],\"toggleMenu\"],null],null],[8],[0,\"\\n  \"],[1,[22,[\"selectedOption\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"isMenuVisible\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[26,\"nested-dropdown/menu\",[[22,[\"options\"]]],[[\"onSelected\"],[\"onSelected\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/nested-dropdown/template.hbs"
    }
  });
});