define("sdk/components/web-tool/component", ["exports", "sdk/core/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    webToolsApi: Ember.inject.service(),
    componentName: 'web-tool',
    tagName: 'iframe',
    attributeBindings: ['name', 'src', 'frameborder'],
    name: Ember.computed.alias('webTool.name'),
    src: Ember.computed.alias('webTool.url'),
    frameborder: '0',
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);

      // attach JS API webToolsApi service when iframe is loaded
      var iframe = this.$()[0];
      this.set('webToolIframe', {
        engagementId: this.get('webTool.engagement.id'),
        iframeElement: iframe
      });
      iframe.onload = function () {
        _this.get('webToolsApi').addIframe(_this.get('webToolIframe'));
      };
    }
  }).reopenClass({
    positionalParams: ['webTool']
  });
});