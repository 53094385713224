define("sdk/api/adapters/api-adapter", ["exports", "ember-ajax/request", "sdk/api/adapters/ajax-request"], function (_exports, _request, _ajaxRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var ajaxDebugOptions;

  /**
   * Base class for API adapters.
   * Provides generic ajax request functionality.
   * @class API-Adapter
   * @public
   */
  var _default = _exports.default = Ember.Object.extend({
    /**
     * Default max time of request
     * @property {Number} AJAX_DEFAULT_TIMEOUT
     */
    AJAX_DEFAULT_TIMEOUT: 30 * 1000,
    init: function init() {
      this._super.apply(this, arguments);
      this._requestCount = 0;
      this._pendingRequests = [];
    },
    /**
     * HTTP Request Algorithm:
     * 1. Check if auth token needs refreshing (unless making the auth token refresh ajax call):
     * - will return a promise
     * 2. After auth token is retrieved, perform the AJAX request immediately.
     * @method ajax
     * @param {Object} options
     * @return {Promise} the auth token promise (followed by the ajax request or deferred promise if queued)
     * @public
     */
    ajax: function ajax(options) {
      var url = options.BASE_URL ? options.BASE_URL : (this.BASE_URL || '') + (options.url || '');
      delete options.url;
      delete options.BASE_URL;
      var request = _ajaxRequest.default.create({
        url: url,
        ajaxOptions: options,
        adapter: this
      });
      this.api.ajaxQueueManager.add(request);
      this._processQueue();
      return request.promise;
    },
    /**
     * Retrieves prioritized items from ajaxQueueManager (pop)
     * then sends the ajax item requests
     * @method _processQueue
     */
    _processQueue: function _processQueue() {
      var _this = this;
      var deferredRequests = this.api.ajaxQueueManager.pop();
      deferredRequests.forEach(function (deferredRequest) {
        var adapter = deferredRequest.adapter,
          url = deferredRequest.url,
          _deferredRequest$ajax = deferredRequest.ajaxOptions,
          ajaxOptions = _deferredRequest$ajax === void 0 ? {} : _deferredRequest$ajax,
          resolve = deferredRequest.resolve,
          reject = deferredRequest.reject;
        var authPromise = ajaxOptions.bypassAuthCheck ? Ember.RSVP.resolve() : _this.api.authAdapter.ensureValidAuthToken();
        authPromise.then(function () {
          deferredRequest.incrementProperty('attempts');
          var outgoingRequest = adapter._ajax(url, ajaxOptions, deferredRequest);

          // attach XHR to the deferredRequest in case we need to abort them
          deferredRequest.set('activeXHR', outgoingRequest.xhr);
          return outgoingRequest;
        }).then(resolve).catch(reject).finally(function () {
          return _this._processQueue();
        });
      });
    },
    _ajax: function _ajax(url, options, requestItem) {
      var _this2 = this;
      if (this.api.get('unloaded')) return Ember.RSVP.reject();
      var requestId = ++this._requestCount + ' - ' + url;
      this._pendingRequests.pushObject(requestId);
      options.method = (options.method || 'GET').toUpperCase();
      if (options.method === 'POST') {
        options.data = JSON.stringify(options.data);
      }
      if (options.query) {
        url += '?' + Ember.$.param(options.query);
        delete options.query;
      }
      var headers = {
        accept: 'application/json'
      };
      var requiresAuth = !url.includes('changePassword') && this.api.authorization;
      if (requiresAuth) {
        headers.Authorization = this.get('api.authAdapter.authorizationHeader');
      }
      if (options.headers) {
        headers = Ember.assign(headers, options.headers);
        delete options.headers;
      }
      options = Ember.assign({
        contentType: 'application/json',
        crossDomain: true,
        headers: headers,
        timeout: this.AJAX_DEFAULT_TIMEOUT,
        xhrFields: {
          withCredentials: false
        }
      }, options);

      // The success callback is called before the ember-data AJAX
      // promise is resolved/rejected.  When we update the status code
      // in this success callback, it will impact whether the promise
      // is resolved or rejected.  Use this together with the call to
      // `window.HTMLAI.debugAjax()`.
      if (shouldDebug(url, options)) options.success = modifyAjaxResponse;
      var sentRequest = (0, _request.default)(url, options);
      return sentRequest.then(function (response) {
        return response;
      }).catch(function (err) {
        if (shouldDebug(url, options)) {
          var _ajaxDebugOptions = ajaxDebugOptions,
            status = _ajaxDebugOptions.status,
            response = _ajaxDebugOptions.response;
          err.payload = err.payload || {};
          if (status) err.payload.status = status;
          if (response) err.payload.detail = response;
        }
        var xhr = sentRequest.xhr;
        Object.assign(err, {
          url: url,
          options: options,
          isNetworkError: true,
          xhr: xhr
        });
        var reconnectStatuses = [0, 401, 503, 504];
        var isReconnectStatus = reconnectStatuses.some(function (status) {
          return xhr.status === status;
        });
        var skippedEndpoints = ['/login', '/logout', '/verifySession'];
        var shouldURLTriggerReconnect = skippedEndpoints.every(function (endpoint) {
          return !url.includes(endpoint);
        });
        var isAEAPI = _this2.get('name') === 'AEAPI';
        var wasTimedOut = xhr.statusText === 'timeout';
        var shouldReconnect = isReconnectStatus && !wasTimedOut && isAEAPI && !xhr.halted && !xhr.manualAbort && shouldURLTriggerReconnect && requestItem.attempts === 1;
        if (shouldReconnect) {
          var ajaxOptions = requestItem.ajaxOptions;
          Object.assign(ajaxOptions, requestItem.get('originalRequestDetails'));
          _this2.get('api.router').reconnectSession(requestItem);
          logError.call(_this2, url, options, err);
          // Failed POST requests aren't readded by default - this causes the requestItem
          // promise to never fulfill. Throw an error to reject and fulfill the promise.
          if (ajaxOptions.method === 'POST' && !ajaxOptions.shouldReAdd) {
            throw err;
          }
          return requestItem.promise;
        } else if (xhr.halted) {
          if (options.ignoreHalt) {
            return requestItem.promise;
          }

          // swallow the rejected promise errors
          // for requests aborted in the relogin flow
          // (they will be re-tried)
          return;
        }
        logError.call(_this2, url, options, err);
        throw err;
      }).finally(function () {
        _this2._pendingRequests.removeObject(requestId);
      });
    }
  });
  window.HTMLAI = {
    /**
     * Simulates different ajax responses
     * (helpful for error validaton testing)
     * @method debugAjax
     * @param {Object} options
     * @param {Number} options.status HTTP status code to override
     * @param {String} [options.url] URL pattern
     * @param {Object} [options.response] Response object
     */
    debugAjax: function debugAjax(options) {
      if (!options) {
        ajaxDebugOptions = null;
        return;
      }
      if (_typeof(options) === 'object') {
        ajaxDebugOptions = options;
      }
    }
  };
  function shouldDebug(originalUrl, options) {
    if (!ajaxDebugOptions) return false;
    var _ajaxDebugOptions2 = ajaxDebugOptions,
      _ajaxDebugOptions2$ur = _ajaxDebugOptions2.url,
      url = _ajaxDebugOptions2$ur === void 0 ? '' : _ajaxDebugOptions2$ur,
      _ajaxDebugOptions2$me = _ajaxDebugOptions2.method,
      method = _ajaxDebugOptions2$me === void 0 ? '' : _ajaxDebugOptions2$me;
    if (url && !originalUrl.match(url)) return false;
    if (method && !options.method.toUpperCase().match(method)) return false;
    return true;
  }
  function modifyAjaxResponse(data, textStatus, jqXHR) {
    if (!ajaxDebugOptions) return;
    var _ajaxDebugOptions3 = ajaxDebugOptions,
      status = _ajaxDebugOptions3.status,
      response = _ajaxDebugOptions3.response;
    if (status) {
      jqXHR.status = status;
    }
    if (_typeof(response) === 'object') {
      jqXHR.responseJSON = response;
      jqXHR.responseText = JSON.stringify(response);
    }
  }
  function logError(url, requestOptions, err) {
    var isMessageRequest = url.includes('/agent/messages');
    var isPostMessagesRequest = isMessageRequest && requestOptions.method === 'POST';
    var isTimedOutGetMessagesRequest = isMessageRequest && requestOptions.method === 'GET' && err.xhr.statusText === 'timeout';
    var shouldLimitSendErrorEvent = !isTimedOutGetMessagesRequest && !isPostMessagesRequest;
    var isAgentLoggingRequest = url.includes('logging/agent');
    if (isAgentLoggingRequest) return;
    var eventData = {
      event: "".concat(this.get('name') && this.get('name').toLowerCase(), "-service-failure"),
      event_group: 'api-error',
      request_url: url,
      message: err && err.message,
      status_code: err && err.xhr && err.xhr.status,
      status_text: err && err.xhr && err.xhr.statusText,
      method: requestOptions.method
    };
    if (shouldLimitSendErrorEvent) {
      this.api.logging.sendLimitedErrorEvent(eventData);
    } else {
      this.api.logging.sendErrorEvent(eventData);
    }
  }
});