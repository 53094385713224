define("sdk/utils/grammar/index", ["exports", "sdk/utils/grammar/conjunctions", "sdk/utils/regex", "sdk/utils/control-characters-list"], function (_exports, _conjunctions, _regex, _controlCharactersList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getWordCount = getWordCount;
  _exports.getWords = getWords;
  _exports.hasControlCharacter = hasControlCharacter;
  _exports.hasWholeWord = hasWholeWord;
  _exports.removeConjunctions = removeConjunctions;
  /**
   * Returns an array of all the words in a string
   * @param {String} sentence
   * @return {Array} whole words in the string
   */
  function getWords() {
    var sentence = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    // reject by to remove empty words ex: "credit card  " <-- extra 2 spaces would result in ['credit', 'card', '']
    return sentence.split(/\s+/).rejectBy('length', 0);
  }

  /**
   * Get the amount of words in a sentence
   * @param {String} sentence
   * @return {Number} amount of words in the sentence
   */
  function getWordCount() {
    var sentence = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var sentenceMatch = sentence.match(/[^\s]+/g);
    return sentenceMatch ? sentenceMatch.length : 0;
  }

  /**
   * Replace all conjunctions with an empty space
   * @method removeConjunctions
   * @param {String} text
   * @return the text passed in with all conjunctions removed
   */
  function removeConjunctions(text) {
    return replaceWholeWords(text, _conjunctions.default, '');
  }

  /**
   * Returns true if the text contains the whole search word (surrounded by white space)
   * @method hasWholeWord
   * @param {String} text text to search through
   * @param {String} wholeWord whole word to search for
   */
  function hasWholeWord(text, wholeWord) {
    var wholeWordRegex = new RegExp('(^|\\s)' + (0, _regex.escapeRegex)(wholeWord) + '($|\\s)');
    return wholeWordRegex.test(text);
  }

  /**
   * Replaces only whole words in passed in text
   * Example:
   * - text = "concept"
   * - wholeWords = ["once"]
   *
   * the word "once" is found in "concept" but it won't be replaced since "once" is not found as a whole word
   * @method replaceWholeWords
   * @param {String} text
   * @param {Array} wholeWords
   * @param {String|RegEx} replaceWith
   * @return the text passed in with any matched whole words removed
   */
  function replaceWholeWords() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var wholeWords = arguments.length > 1 ? arguments[1] : undefined;
    var replaceWith = arguments.length > 2 ? arguments[2] : undefined;
    // a word is considered a whole word when:
    // 1. it is either the first word and has a space after
    // 2. it is the last word and has a space before
    // 3. it is between two spaces
    var wholeWordRegexString = '(?=^|\\s)' + wholeWords.join('(?=$|\\s)|(^|\\s)') + '(?=$|\\s)';
    var wholeWordRegex = new RegExp(wholeWordRegexString, 'gi');
    return text.replace(wholeWordRegex, replaceWith).trim();
  }

  /**
   * @param {String} text
   * @method hasControlCharacter
   */
  function hasControlCharacter(text) {
    var controlCharacters = (0, _controlCharactersList.getControlCharactersList)();
    var controlCharacterExistsInText = controlCharacters.some(function (character) {
      return text.includes(character);
    });

    // eslint-disable-next-line no-control-regex
    var controlCharRegexp = /[\x00-\x09]|[\x0b-\x1F]/g;
    var el = document.createElement('div');
    el.innerHTML = text;
    var textToValidate = el.innerHTML;
    return controlCharacterExistsInText || !!textToValidate.match(controlCharRegexp);
  }
});