define("sdk/components/tab-group/component", ["exports", "sdk/core/component", "ember-keyboard"], function (_exports, _component, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Adds aria attributes and keyboard handling to a
   * group of children where [class=".active"] is
   * programatically set.
   *
   * @class Components.TabGroup
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    attributeBindings: ['role', 'aria-label'],
    classNames: ['tab-container-group'],
    role: 'tablist',
    keyboardActivated: true,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var $tabItems = this.$('[role="tab"]');
      var currentActiveTabIndex = $tabItems.index($tabItems.filter('.active').one());
      if (currentActiveTabIndex > -1) {
        this._changeActiveTabToIndex(currentActiveTabIndex);
      }
    },
    didRender: function didRender() {
      var $tabItems = this.$('[role="tab"]');
      var currentActiveTabIndex = $tabItems.index($tabItems.filter('.active').one());
      if (currentActiveTabIndex === -1) {
        $tabItems.attr('aria-selected', 'false');
        $tabItems.attr('tabindex', '-1').first().attr('tabindex', '0');
        this.set('highlightedTabIndex', 0);
      } else if (this.get('activeTabIndex') !== currentActiveTabIndex) {
        this._changeActiveTabToIndex(currentActiveTabIndex);
      }
      this.set('activeTabIndex', currentActiveTabIndex);
    },
    handleLeftArrowKey: Ember.on((0, _emberKeyboard.keyDown)('ArrowLeft'), function (event) {
      if (!this._isTabItem(event)) return;
      if (this.get('highlightedTabIndex') <= 0) {
        this._changeTabFocusToIndex(this.$('[role="tab"]').length - 1);
      } else {
        this._changeTabFocusToIndex(this.get('highlightedTabIndex') - 1);
      }
    }),
    handleRightArrowKey: Ember.on((0, _emberKeyboard.keyDown)('ArrowRight'), function (event) {
      if (!this._isTabItem(event)) return;
      if (this.get('highlightedTabIndex') >= this.$('[role="tab"]').length - 1) {
        this._changeTabFocusToIndex(0);
      } else {
        this._changeTabFocusToIndex(this.get('highlightedTabIndex') + 1);
      }
    }),
    handleEnterKey: Ember.on((0, _emberKeyboard.keyDown)('Enter'), function (event) {
      this._handleSpaceAndEnterKey(event);
    }),
    handleSpaceKey: Ember.on((0, _emberKeyboard.keyDown)('Space'), function (event) {
      this._handleSpaceAndEnterKey(event);
    }),
    handleTabKey: Ember.on((0, _emberKeyboard.keyDown)('Tab'), function (event) {
      this._handleShiftAndTabKey(event);
    }),
    handleShiftTabKey: Ember.on((0, _emberKeyboard.keyDown)('shift+Tab'), function (event) {
      this._handleShiftAndTabKey(event);
    }),
    _changeTabFocusToIndex: function _changeTabFocusToIndex(index) {
      this.set('highlightedTabIndex', index);
      var $tabItems = this.$('[role="tab"]');
      var nextIndex = this.get('highlightedTabIndex');
      var $nextChildHighlight = $tabItems.eq(nextIndex);
      $nextChildHighlight.focus();
    },
    _changeActiveTabToIndex: function _changeActiveTabToIndex(index) {
      var $tabItems = this.$('[role="tab"]');
      var $nextActiveChild = $tabItems.eq(index);
      this.set('activeTabIndex', index);
      this.set('highlightedTabIndex', index);
      $tabItems.attr('aria-selected', false).attr('tabindex', '-1');
      $nextActiveChild.attr('aria-selected', true).attr('tabindex', '0');
    },
    _handleSpaceAndEnterKey: function _handleSpaceAndEnterKey(event) {
      if (!this._isTabItem(event)) return;
      var $tabItems = this.$('[role="tab"]');
      var $highlightedChild = $tabItems.eq(this.get('highlightedTabIndex'));
      if ($highlightedChild.attr('aria-disabled')) {
        return;
      }
      this._changeActiveTabToIndex($tabItems.index($highlightedChild));
    },
    _handleShiftAndTabKey: function _handleShiftAndTabKey() {
      if (!this._isTabItem(event)) return;
      if (this.get('activeTabIndex') === -1) {
        this.set('highlightedTabIndex', 0);
      } else if (this.get('highlightedTabIndex') !== this.get('activeTabIndex')) {
        this.set('highlightedTabIndex', this.get('activeTabIndex'));
      }
    },
    _isTabItem: function _isTabItem(event) {
      return this.$('[role="tab"]').index(event.target) > -1;
    }
  });
});