define("sdk/authenticators/tc", ["exports", "ember-simple-auth/authenticators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    restore: function restore(data) {
      // There is a bug with ember-simple-auth causing restore to be called twice,
      // which causes issues downstream.  Thus, we always will return the result
      // of the first call to restore();
      if (this.verificationPromise) return this.verificationPromise;
      var authAdapter = this.get('api.authAdapter');
      if (authAdapter.get('hasNoAuthTokens')) return Ember.RSVP.reject();
      var verificationPromise = authAdapter.authenticate();
      this.verificationPromise = verificationPromise;
      return verificationPromise.then(this.restoreSession.bind(this, data)).catch(function (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      });
    },
    restoreSession: function restoreSession(data) {
      var _this = this;
      return this.get('api.agent').verifySession().then(function () {
        _this.set('routerOpenTimeout', setTimeout(function () {
          _this.get('api.router').open();
        }, 100));
        return data;
      }).catch(function (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      });
    },
    authenticate: function authenticate(_ref) {
      var _ref$authResponse = _ref.authResponse,
        authResponse = _ref$authResponse === void 0 ? {} : _ref$authResponse,
        username = _ref.username,
        agentLoginOptions = _ref.agentLoginOptions;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var credentials = {
        username: username
      };
      if (authResponse.isSSO) {
        var userData = parseJwt(authResponse.access_token);
        credentials.username = userData.user_name;
        credentials.password = 'NA';
      }
      if (options.shouldRestoreSession) {
        return this.restoreSession();
      }
      if (authResponse.advancedAgent) {
        this.set('api.agent.userId', credentials.username);
        return Ember.RSVP.reject({
          isAdvancedAgentConfigurationRequired: true
        });
      }
      return this.get('api.agent').login(credentials.username, agentLoginOptions).then(function (data) {
        return data;
      });
    },
    invalidate: function invalidate() {
      return this.get('api.authAdapter').revoke();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('routerOpenTimeout'));
    }
  }); // https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
});