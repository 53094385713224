define("sdk/api/adapters/metrics-api-adapter", ["exports", "sdk/api/adapters/api-adapter"], function (_exports, _apiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class MetricsAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    basicAuth: true,
    name: 'METRICS-API',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.REALTIME_METRICS_API;
    },
    /**
     * Retrieves data from the API using two
     * query parameters.
     * @method getData
     * @public
     * @param {String} category the metric's category
     * @param {String} dimension the metric's dimension
     * @param {Object} options additional optional params
     * @param {String} options.site the site ID
     * @param {Number} options.ag the agent group ID
     * @param {String} options.returnFields whether or not to retrieve all fields possible
     */
    getData: function getData(category, dimension) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
        site: '10003715'
      };
      return this.ajax({
        query: this._getQueryParams(category, dimension, options)
      });
    },
    _getQueryParams: function _getQueryParams(category, dimension) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return Object.assign({
        category: category,
        dimension: dimension,
        filter: "agentID='".concat(this.get('api.agent.userId'), "'")
      }, options);
    }
  });
});