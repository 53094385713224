define("sdk/api/data/ignored-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [
  /**
   * This error gets thrown when the renderer breaks.
   * We check for it so that we do not get an infinite
   * rendering call stack.
   */
  'a glimmer transaction was begun, but one already exists. You may have a nested transaction',
  /**
   * This error gets thrown when an agent spams the tab key
   * combined with another key. This causes the ember-cli-tinymce
   * addon's contentChanged event listener to retrieve the editor's
   * contents when the editor is not rendered.
   */
  'Cannot read property \'body\' of null'];
});