define("sdk/api/plugins/plugin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    /**
     * The name of the plugin.  Plugins are invoked
     * by name via the client's business rules. The
     * name property correspond's to the `plugin` property
     * in the datapass performed in  the business rules.
     * @property name
     * @type {String}
     */
    name: null,
    /**
     * This option specified when the plugin's behavior should
     * be activated.  The default value is "transcript-click",
     * which will ensure the behavior is only activated after
     * the agent clicks a specific link within the transcript
     * area of the Agent Desktop.  The other possible value is
     * "immediate", which will cause the Agent Desktop to trigger
     * the behavior as soon a the datapass is received.  Note
     * that for the "open-page" plugin, when runAt is set to
     * immediate, any windows opened by the Agent Desktop will
     * normally be blocked by popup blockers (as popup blockers
     * block popups that were not initiated by a "click" event
     * from the user).
     *
     * @property runAt
     * @type {String}
     */
    runAt: 'transcript-click',
    /**
     * The text to be displayed as the prefix for the transcript
     * message that displays the datapass.
     * @property messagePrefix
     * @type {String}
     */
    messagePrefix: 'Action',
    /**
     * The main text to be displayed for the transcript message
     * that displays the datapass.  This option accept an HTML
     * string.  To make a certain part of the text clickable -
     * and open the web page for the client's CMS/server - just
     * wrap the data-plugin-trigger HTML attribute around an
     * element.  For example:
     * `<span data-plugin-trigger>This is clickable now</span>`
     * @property messageText
     * @property {String}
     */
    messageText: null,
    /**
     * This option accepts a JSON string containing any options
     * specific to the plugin.
     * @property pluginParams
     * @type {String}
     */
    pluginParams: null,
    init: function init() {
      this.pluginParams = this.pluginParams || {};
      if (this.runAt === 'transcript-click' && !this.messageText) {
        this.messageText = "<span data-plugin-trigger=\"".concat(this.name, "\">Click here</span> to trigger \"").concat(this.name, "\" plugin");
        this.messagePrefix = 'Plugin';
      } else if (this.runAt === 'immediate') {
        this.run();
      }
    },
    /**
     * The `run` method should be defined by subclasses of the
     * Plugin class.  This method is invoked based on the value
     * of the `runAt` property (i.e., `transcript-click` or `immediate`)
     * @method runAt
     * @public
     */
    run: function run() {}
  });
});