define("sdk/components/draggable-collapsable-tile/component", ["exports", "sdk/components/collapsable-tile/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNameBindings: ['hasBeenDragged:dragged', 'draggable', 'isDragging:dragging'],
    resizable: Ember.computed('singleTile', 'hasBeenDraggedOnce', function () {
      if (!this.get('resizableEnabled')) {
        return this.get('hasBeenDraggedOnce');
      }
      return !this.get('singleTile');
    }),
    resizeHandles: Ember.computed('hasBeenDraggedOnce', function () {
      return this.get('hasBeenDraggedOnce') ? 's,e' : 's';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.get('draggable')) return;
      Ember.run.schedule('afterRender', function () {
        var topToBe;
        var leftToBe;
        _this.$().draggable({
          handle: '.header',
          stack: '.nuance-collapsable-tile',
          start: function start(event, ui) {
            // eslint-disable-line no-unused-vars
            var element = _this.$()[0];
            var $window = _this.$(window);
            var top = element.getBoundingClientRect().top;
            var left = element.getBoundingClientRect().left;
            var windowTop = $window.scrollTop();
            var windowLeft = $window.scrollLeft();

            // the moment a tile is dragged for the first time,
            // its position property is changed causing it to appear
            // in the top left corner of the screen. here we offset
            // its position so that when an agent initially drags a tile
            // it moves from its resting position as expected
            topToBe = top - windowTop;
            leftToBe = left - windowLeft;
            _this.set('hasBeenDragged', true);
            _this.set('isDragging', true);
          },
          drag: function drag(event, ui) {
            var onTileDrag = _this.get('onTileDrag');
            onTileDrag && onTileDrag();
            if (_this.get('hasBeenDragged') && _this.get('hasBeenDraggedOnce')) {
              ui.position.left = _this._getContainedLeftPosition(ui.position.left);
              ui.position.top = _this._getContainedTopPosition(ui.position.top);
              return;
            }
            ui.position.left = _this._getContainedLeftPosition(ui.position.left + leftToBe);
            ui.position.top = _this._getContainedTopPosition(ui.position.top + topToBe);
          },
          stop: function stop(event, ui) {
            // eslint-disable-line no-unused-vars
            _this.set('hasBeenDraggedOnce', true);
            _this.set('isDragging', false);
            _this.updateResizable(true);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('draggable')) {
        this.$().draggable('destroy');
      }
    },
    actions: {
      /**
       * Resets the tile to the slideout menu.
       * @method sendBackToStartingPosition
       * @public
       */
      sendBackToStartingPosition: function sendBackToStartingPosition() {
        this._super.apply(this, arguments);
        this.set('hasBeenDragged', false);
        this.set('hasBeenDraggedOnce', false);
        this.$().css('top', 0);
        this.$().css('left', 0);
        this.$().css('height', '');
        this.$().css('width', '');

        // reset web tools positioning
        var onTileDrag = this.get('onTileDrag');
        onTileDrag && onTileDrag();
        this.updateResizable(true);
      }
    },
    _getContainedLeftPosition: function _getContainedLeftPosition(position) {
      return Math.max(0, Math.min(position, this.get('windowSize.width') - this.$().width()));
    },
    _getContainedTopPosition: function _getContainedTopPosition(position) {
      var marginTop = parseInt(this.$().css('margin-top'), 10);
      return Math.max(-1 * marginTop, Math.min(position, this.get('windowSize.height') - this.$().height() - marginTop));
    }
  });
});