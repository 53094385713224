define("sdk/api/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this._restore();
      //This will create (if it does not exist already) an empty entry in the local storage with the name assigned to "key"
      this._persist();
    },
    key: Ember.computed(function () {
      var key = 'nuance-ai';
      if (window.runningTests) {
        key += '-test';
      }
      return key;
    }),
    clear: function clear() {
      localStorage.removeItem(this.get('key'));
    },
    /**
     * This retrieves the object with the received key/name from the store and returns it
     * @method getItem
     * @public
     *
     * @param {String} name
     * @return {Object}
     */
    getItem: function getItem(name) {
      if (name) {
        return this.get('store')[name];
      }
    },
    /**
     * This sets the received name value to the store and then persists it to the local storage.
     * @method setItem
     * @public
     *
     * @param {String} name
     * @param {String} item
     */
    setItem: function setItem(name, item) {
      if (name && item) {
        this.get('store')[name] = item;
        this._persist();
      }
    },
    /**
     * Persists current store object to localStorage after stringify it
     * @method _persist
     * @private
     */
    _persist: function _persist() {
      var key = this.get('key');
      localStorage.setItem(key, JSON.stringify(this.store));
    },
    /**
     * Constructs a local store object for 'key' by retrieving form localStorage
     * If there nothing in the local storage, then creates an empty store object
     * @method _restore
     * @private
     */
    _restore: function _restore() {
      var key = this.get('key');
      var store = localStorage.getItem(key);
      this.store = store ? JSON.parse(store) : {};
    }
  });
});