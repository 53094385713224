define("sdk/utils/visible-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLastVisibleElementIndex = getLastVisibleElementIndex;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
   * Get index of last visible element
   * @param {String} containerSelector
   * @param {Object} options
   */
  function getLastVisibleElementIndex(_ref) {
    var _ref$containerSelecto = _ref.containerSelector,
      containerSelector = _ref$containerSelecto === void 0 ? '' : _ref$containerSelecto,
      _ref$options = _ref.options,
      options = _ref$options === void 0 ? {} : _ref$options;
    return _getVisibleElementIndexes(containerSelector, options).get('lastObject');
  }

  /**
   * Get an array of visible element indexes of children within "containerSelector"
   * @method _getVisibleElementIndexes
   * @param {String} containerSelector
   * @param {Object} options
   * @param {String} options.selector A selector to query on the containerSelector element.
   * @param {Number} options.visibleThreshold percentage (0 - 1.0) of how much of the element's height is visible in the viewport
   * @returns {Array} array of visible element indexes
   */
  function _getVisibleElementIndexes() {
    var containerSelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var containerElement = document.querySelector(containerSelector);
    if (!containerElement || !containerElement.hasChildNodes()) return [];
    var _options$visibleThres = options.visibleThreshold,
      visibleThreshold = _options$visibleThres === void 0 ? 1 : _options$visibleThres,
      _options$selector = options.selector,
      selector = _options$selector === void 0 ? '' : _options$selector;
    var _containerElement$get = containerElement.getBoundingClientRect(),
      containerOffsetTop = _containerElement$get.top,
      containerOffsetBottom = _containerElement$get.bottom;
    var visibleElementIndexes = [];
    var childElements = selector ? containerElement.querySelectorAll(selector) : _toConsumableArray(containerElement.childNodes).filterBy("nodeType", Node.ELEMENT_NODE);
    for (var index = 0; index < childElements.length; index++) {
      var _childElements$index$ = childElements[index].getBoundingClientRect(),
        height = _childElements$index$.height,
        top = _childElements$index$.top,
        bottom = _childElements$index$.bottom;
      var visibleTop = top < containerOffsetTop ? containerOffsetTop : top;
      var visibleBottom = bottom > containerOffsetBottom ? containerOffsetBottom : bottom;
      var visibleHeight = visibleBottom - visibleTop;
      var isVisible = visibleHeight >= visibleThreshold * height;
      if (isVisible) visibleElementIndexes.pushObject(index);

      // stop iterating if either visible array has an index and we iterate over a non-visible element
      if (!isVisible && visibleElementIndexes.length) break;
    }
    return visibleElementIndexes;
  }
});