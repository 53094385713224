define("sdk/components/floating-element/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YIenA2sO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/floating-element/template.hbs"
    }
  });
});