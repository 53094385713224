define("sdk/services/cobrowse", ["exports", "sdk/services/cobrowse/iframe"], function (_exports, _iframe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    api: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    isEnabled: Ember.computed.alias('api.settings.cobrowseEnabled'),
    cobrowseUrls: Ember.computed.alias('api.settings.cobrowseURLs'),
    uniqIframes: Ember.computed.uniqBy('cobrowseUrls', 'url'),
    iframes: Ember.computed('uniqIframes', 'api.agent.settings.language', function () {
      var _this = this;
      var localeParameter = "".concat((this.get('api.agent.settings.language') || '').replace('_', '-'));
      var activeEngagementId = this.get('api.storage.store.activeEngagementId');
      return this.get('uniqIframes').map(function (iframe, index) {
        var cobrowseOptions = Object.assign(iframe, {
          index: index,
          agentId: _this.get('api.agent.userId'),
          activeEngagementId: activeEngagementId,
          locale: localeParameter
        });
        return _iframe.default.create(cobrowseOptions);
      });
    }),
    /**
     * Whether or not any cobrowse iframes are disconnected.
     * @property {Boolean} disconnected
     * @public
     */
    disconnected: false,
    activeIframe: null,
    agentConnected: true,
    storage: Ember.computed.alias('api.storage'),
    init: function init() {
      this._super.apply(this, arguments);
      this._addWindowMessageListener();
      Ember.run.once(this, this.restoreConnectionState);
      this.get('api').on('newEngagement', this, this.joinCobrowseSession);
      this.get('api').on('restoreCobrowse', this, this._onRestoreCobrowse);
      this.get('api').on('engagementClosed', this, this.endCobrowseSession);
      this.get('api').on('connectionLost', this, this._onCobrowseConnectionLost);
      this.get('api').on('connectionRestored', this, this._onCobrowseConnectionRestored);
    },
    willDestroy: function willDestroy() {
      var api = this.get('api');
      api.off('newEngagement', this, this.joinCobrowseSession);
      api.off('restoreCobrowse', this, this._onRestoreCobrowse);
      api.off('engagementClosed', this, this.endCobrowseSession);
      api.off('connectionLost', this, this._onCobrowseConnectionLost);
      api.off('connectionRestored', this, this._onCobrowseConnectionRestored);
      Ember.$(window).off('.cobrowse', this.oniFrameMessage);
      this.oniFrameMessage = null;
    },
    /**
     * When Cobrowse session is restored
     * @param {Object} engagement
     * @method _onRestoreCobrowse
     */
    _onRestoreCobrowse: function _onRestoreCobrowse(engagement) {
      if (this.get('storage.store.activeEngagementId') === engagement.id) {
        this.joinCobrowseSession(engagement);
        this.sendHighlightSetting(engagement);
      }
    },
    /**
     * When connection is lost, set agentConnected to false
     * and display cobrowse connection error
     * @method _onCobrowseConnectionLost
     */
    _onCobrowseConnectionLost: function _onCobrowseConnectionLost() {
      this.set('agentConnected', false);
      var message = this.get('intl').t('cobrowse.error').toString();
      Ember.onerror({
        remove: true,
        message: message
      });
    },
    /**
     * When connection is restored, set agentConnected to true
     * @method _onCobrowseConnectionRestored
     */
    _onCobrowseConnectionRestored: function _onCobrowseConnectionRestored() {
      this.set('agentConnected', true);
    },
    joinCobrowseSession: function joinCobrowseSession(engagement) {
      this.sendCommand(engagement, {
        command: 'joinCobrowse'
      });
    },
    endCobrowseSession: function endCobrowseSession(engagement) {
      this.sendCommand(engagement, {
        command: 'endCobrowse',
        exitChat: true
      });
    },
    sendHighlightSetting: function sendHighlightSetting(engagement) {
      this.sendCommand(engagement, {
        command: 'setHighlightEnabled',
        highlight: engagement.get('cobrowse.highlight')
      });
    },
    sendCommand: function sendCommand(engagement, command) {
      if (!this.get('isEnabled')) return;
      var _engagement$getProper = engagement.getProperties('id', 'settings'),
        engagementId = _engagement$getProper.id,
        settings = _engagement$getProper.settings;
      var siteId = settings.siteId;
      var iframe = this.getIframeBySiteId(siteId);
      command.engagementId = engagementId;
      iframe.sendCommand(command);
    },
    /**
     * Adds an event listener to the messages event.
     * Used for reacting to cobrowse connectivity events.
     * @method _addWindowMessageListener
     * @private
     */
    _addWindowMessageListener: function _addWindowMessageListener() {
      this.oniFrameMessage = this._processIframeMessage.bind(this);
      Ember.$(window).on('message.cobrowse', this.oniFrameMessage);
    },
    /**
     * If the event received is a cobrowse connecitivity
     * event, pass the event for processing.
     * @method _processIframeMessage
     * @private
     * @param {Object} messageEvent the event to process
     */
    _processIframeMessage: function _processIframeMessage(messageEvent) {
      var _messageEvent$origina = messageEvent.originalEvent,
        url = _messageEvent$origina.origin,
        data = _messageEvent$origina.data;
      var event = data.event;
      var isConnectionEvent = ['connected', 'connecting', 'disconnected'].includes(event);
      if (isConnectionEvent) {
        this._processConnectionEvent({
          url: url,
          event: event
        });
      }
    },
    /**
     * If there is a cobrowse iframe for the given url,
     * a notification will be displayed if this event signals
     * a disconnect. Otherwise, if there is already a
     * notification displayed and all iframes are now connected,
     * the notification will be hidden.
     * @method _processConnectionEvent
     * @private
     * @param {String} url the cobrowse URL
     * @param {String} event the connectivity event
     */
    _processConnectionEvent: function _processConnectionEvent(_ref) {
      var url = _ref.url,
        event = _ref.event;
      var iframe = this.get('iframes').findBy('url', url);
      if (!iframe) return;
      iframe.set('connected', event === 'connected');
      var areAllIframesConnected = this.get('iframes').isEvery('connected', true);
      var shouldDisplayCobrowseNotification = event === 'disconnected' && !this.get('disconnected');
      var shouldHideCobrowseNotification = this.get('disconnected') && areAllIframesConnected;
      var message = this.get('intl').t('cobrowse.error').toString();
      if (shouldDisplayCobrowseNotification) {
        this.set('disconnected', true);
        if (this.get('agentConnected')) {
          Ember.onerror({
            message: message
          });
        }
        this.persistConnectionState();
      } else if (shouldHideCobrowseNotification) {
        this.set('disconnected', false);
        Ember.onerror({
          remove: true,
          message: message
        });
      }
      if (event === 'connected') {
        this.removeConnectionState();
      }
    },
    persistConnectionState: function persistConnectionState() {
      var store = this.get('storage.store');
      store.cobrowse = {
        disconnected: this.get('disconnected')
      };
      this.get('storage').persist();
    },
    removeConnectionState: function removeConnectionState() {
      var store = this.get('storage.store');
      delete store.cobrowse;
      this.get('storage').persist();
    },
    restoreConnectionState: function restoreConnectionState() {
      var store = this.get('storage.store');
      if (store.cobrowse) {
        this.set('disconnected', store.cobrowse.disconnected);
        var message = this.get('intl').t('cobrowse.error').toString();
        Ember.onerror({
          message: message
        });
      }
    },
    getIframeBySiteId: function getIframeBySiteId(siteId) {
      var _this$get$findBy = this.get('cobrowseUrls').findBy('siteId', siteId),
        url = _this$get$findBy.url;
      return this.get('iframes').findBy('url', url);
    }
  });
});