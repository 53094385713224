define("sdk/components/error-modal/component", ["exports", "sdk/core/component", "sdk/components/error-modal/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'error-modal',
    showErrors: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.errors = this.errors || [];
    },
    errorsLength: Ember.computed.alias('errors.length'),
    actions: {
      hide: function hide() {
        this.get('onHidden')();
      }
    },
    onHidden: function onHidden() {}
  });
});