define("sdk/utils/ember-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getComponentFactory = getComponentFactory;
  _exports.getComponentLayout = getComponentLayout;
  function getComponentFactory(context, componentPath) {
    return Ember.getOwner(context).factoryFor("component:".concat(componentPath));
  }
  function getComponentLayout(context, componentPath) {
    return Ember.getOwner(context).lookup("template:components/".concat(componentPath));
  }
});