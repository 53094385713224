define("sdk/components/static-transcript-window/history/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "SVaN6S8V",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,\"div\"],[10,\"data-test-transcripts-history\",\"\"],[8],[0,\"\\n\"],[4,\"menu-select\",null,[[\"options\",\"selected\",\"selectedItemComponent\",\"onchange\",\"class\",\"horizontalPosition\"],[[22,[\"transcriptOptions\"]],[22,[\"selectedTranscriptOption\"]],\"static-transcript-window/history/selected-item\",[26,\"action\",[[21,0,[]],\"selectTranscript\"],null],\"transcripts-dropdown\",\"right\"]],{\"statements\":[[0,\"    \"],[1,[21,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/static-transcript-window/history/template.hbs"
    }
  });
});