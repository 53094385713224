define("sdk/api/utils/nina-coach-intents-serializer", ["exports", "sdk/api/models/nina-coach-intent"], function (_exports, _ninaCoachIntent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNormalizedIntents = getNormalizedIntents;
  /**
   * @method getNormalizedIntents
   * @public
   * @param {Object} contentMetaInfoResponse response object containing concepts and hierarchies array
   * @return {Array} an array of intent objects containing properties "type" and modified "concepts" array
   */
  function getNormalizedIntents(contentMetaInfoResponse) {
    var contentMetaInfo = contentMetaInfoResponse.contentMetaInfo;
    var data = {
      concepts: [],
      intents: []
    };
    if (!contentMetaInfo) return data;
    var conceptsLookup = contentMetaInfo.concepts,
      hierarchies = contentMetaInfo.hierarchies,
      responseCode = contentMetaInfo.responseCode,
      responseMessage = contentMetaInfo.responseMessage;
    var hasError = responseCode === 'Error';
    if (hasError) {
      throw {
        code: 'nina-coach-error',
        detail: responseMessage
      };
    }
    data.concepts = conceptsLookup;
    hierarchies.forEach(function (_ref) {
      var name = _ref.name,
        topics = _ref.topics;
      var type = name;
      var intents = getAllIntentsFromTopics(topics);
      var intentObjects = createIntentObjects(intents, conceptsLookup, type);
      data.intents.pushObjects(intentObjects);
    });
    return data;
  }
  function getAllIntentsFromTopics(topics) {
    var prevIntents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    topics.forEach(function (_ref2) {
      var intents = _ref2.intents,
        nestedTopics = _ref2.topics;
      prevIntents.addObjects(intents);
      return getAllIntentsFromTopics(nestedTopics, prevIntents);
    });
    return prevIntents;
  }
  function createIntentObjects(intents, conceptsLookup, type) {
    return intents.map(function (intent) {
      var concepts = intent.concepts;
      concepts.forEach(function (_ref3, index) {
        var name = _ref3.name;
        var concept = conceptsLookup.findBy('name', name);
        intent.concepts[index] = concept || "N/A: ".concat(name);
      });
      intent._concepts = concepts;
      intent.userQuestion = (intent.userQuestion || '').trim();
      intent.type = type;
      return _ninaCoachIntent.default.create(intent);
    });
  }
});