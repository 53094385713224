define("sdk/api/models/dispositions-list", ["exports", "sdk/api/models/core/select-list"], function (_exports, _selectList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class DispositionList
   * @extends SelectList
   */
  var _default = _exports.default = _selectList.default.extend({
    min: 1,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.categories = this.categories.map(function (category) {
        category = Ember.Object.create(category);
        category.reasons = category.reasons.sortBy('label');
        var reasons = _selectList.default.create(category.reasons);
        reasons.forEach(function (reason) {
          return _this.addItem(reason);
        });
        category.reasons = reasons;
        return category;
      }).sortBy('label');
    },
    selected: Ember.computed('selectedItems.[]', function () {
      var categories = [];
      this.categories.forEach(function (category) {
        if (!category.reasons.get('selectedItems.length')) return;
        var reasons = category.reasons.get('selectedItems').toJSON();
        category = Ember.assign({}, category);
        category.reasons = reasons.sortBy('value');
        categories.push(category);
      });
      return categories;
    }),
    toJSON: function toJSON() {
      return {
        categories: this.categories.map(function (cat) {
          cat = Object.assign({}, cat);
          cat.reasons = cat.reasons.toJSON();
          return cat;
        }),
        multiple: this.multiple
      };
    }
  });
});