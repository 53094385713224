define("sdk/api/metrics", ["exports", "sdk/api/utils/runloop", "moment"], function (_exports, _runloop, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Responsible for retrieving and computing any metrics from the
   * Realtime Metrics API. Refreshes data on engagement accept/close
   * and refreshes by polling the API every minute.
   * @class Metrics
   * @public
   */
  var _default = _exports.default = Ember.Object.extend({
    /**
     * The current agent user ID.
     * @property {String} agentID the current agent user ID
     * @public
     */
    agentID: Ember.computed.alias('api.agent.userId'),
    /**
     * The number of closed engagements in the current agent's session.
     * @property {Number} closedEngagements the number of closed engagements
     * @public
     */
    closedEngagements: Ember.computed.alias('agent.closedEngagements'),
    ninaCoachEngagementsCompleted: Ember.computed('agent.ninaCoachEngagementsCompleted', function () {
      return this.get('agent.ninaCoachEngagementsCompleted') || 0;
    }),
    siteSettings: Ember.computed.alias('api.settings.siteSettings.sites'),
    /**
     * Array of all site objects with agent group or business unit metric information
     * @property {Array} sitesWithPerformanceMetrics
     */
    sitesWithPerformanceMetrics: Ember.computed('activePerformanceMetrics', function () {
      return this._getSitesWithPerformanceMetrics(this.get('activePerformanceMetrics'));
    }),
    /**
     * Array of site objects that have metric data
     * @property {Array} sitesWithMetricData
     */
    sitesWithMetricData: Ember.computed.filterBy('sitesWithPerformanceMetrics', 'hasMetricData'),
    /**
     * An array containing all the site names the current agent belongs to.
     * Only contains names of sites with metric data.
     * @property {Array} siteNamesWithMetricData the array of site names the current agent belongs to
     * @public
     */
    siteNamesWithMetricData: Ember.computed.mapBy('sitesWithMetricData', 'name'),
    /**
     * Agent metric data
     * @property {Object} agent
     */
    agent: null,
    /**
     * Currently selected metrics site
     * @property {Object} selectedSite
     */
    selectedSite: null,
    selectedSiteName: Ember.computed.alias('selectedSite.name'),
    /**
     * Whether or not any sites have metric data.
     * @property {Boolean} hasMetrics
     * @public
     */
    hasMetrics: Ember.computed.notEmpty('sitesWithMetricData'),
    /**
     * Array of available metric names
     * @property {Array} availableMetricNames
     */
    availableMetricNames: Ember.computed.mapBy('availableMetrics', 'name'),
    /**
     * Unique array of available metric names
     * @property {Array} uniqAvailableMetricNames
     */
    uniqAvailableMetricNames: Ember.computed.uniq('availableMetricNames'),
    /**
     * All currently available metrics under the logged in time category.
     * @property {Array} loggedInTimeMetrics
     * @public
     */
    loggedInTimeMetrics: Ember.computed.filterBy('availableMetrics', 'metricType', 'loggedInTime'),
    /**
     * All currently available metrics under the utilization category.
     * @property {Array} utilizationMetrics
     * @public
     */
    utilizationMetrics: Ember.computed.filterBy('availableMetrics', 'metricType', 'utilization'),
    /**
     * All currently available metrics under the volume category.
     * @property {Array} volumeMetrics
     * @public
     */
    volumeMetrics: Ember.computed.filterBy('availableMetrics', 'metricType', 'volume'),
    /**
     * All currently available metrics under the efficiency category.
     * @property {Array} efficiencyMetrics
     * @public
     */
    efficiencyMetrics: Ember.computed.filterBy('availableMetrics', 'metricType', 'efficiency'),
    /**
     * Whether or not realtime polling is active
     * @property {Boolean} isRealtimePolling
     */
    isRealtimePolling: false,
    /**
     * The time interval (in ms) where active performance metrics are retrieved
     * @property {Number} ACTIVE_PERFORMANCE_INTERVAL
     */
    ACTIVE_PERFORMANCE_INTERVAL: 20 * 60 * 1000,
    // 20 minutes

    /**
     * Time it takes to update realtime metrics
     * @property {Number} REALTIME_REFRESH_TIMEOUT
     */
    REALTIME_REFRESH_TIMEOUT: 2 * 60 * 1000,
    // 2 mins
    init: function init() {
      this._super.apply(this, arguments);
      this.state = this.api.stateManager.createItem({
        context: this,
        key: 'METRICS',
        publicProperties: ['loggedInTime', 'utilization', 'volume', 'efficiency']
      });

      // restore "Chats Completed" metric after page refresh
      var storage = this.get('api.storage');
      var _ref = Ember.get(storage, 'store.metrics') || {},
        closedEngagements = _ref.closedEngagements,
        closedEngagementsField = _ref.closedEngagementsField;
      this.set('agent', Ember.Object.create({
        closedEngagements: closedEngagements || 0,
        ninaCoachEngagementsCompleted: 0
      }));
      closedEngagementsField && this.set('closedEngagementsField', closedEngagementsField);
      var api = this.get('api');
      api.on('engagementClosed', this, this._onEngagementClosed);
      api.on('ninaCoachTakeover', this, this._onNinaCoachTakeover);
      api.on('settingsLoaded', this, this._resumeActivePerformanceMetricsPolling);
    },
    _onEngagementClosed: function _onEngagementClosed(engagement) {
      if (!this.get('api.agent.isLockedOut')) {
        var siteId = engagement && engagement.get('settings.siteId');
        this.set('refreshHeaderMetricsDebounce', (0, _runloop.debounce)(this, this._refreshHeaderMetrics, siteId, 100));
      }
    },
    _onNinaCoachTakeover: function _onNinaCoachTakeover(engagement) {
      var siteId = engagement && engagement.get('settings.siteId');
      this.set('refreshHeaderMetricsDebounce', (0, _runloop.debounce)(this, this._refreshHeaderMetrics, siteId, 100));
    },
    /**
    * Use cached active performance metrics and start timeout/ interval based on last timestamp
    * @method _resumeActivePerformanceMetricsPolling
    */
    _resumeActivePerformanceMetricsPolling: function _resumeActivePerformanceMetricsPolling() {
      var _this = this;
      var cachedActivePerformanceMetrics = this.api.get('storage.store.activePerformanceMetrics');
      if (cachedActivePerformanceMetrics) {
        this.set('activePerformanceMetrics', cachedActivePerformanceMetrics);
        var timestamp = this.get('api.storage.store.activePerformanceMetricsTimestamp');
        var timeDifference = (0, _moment.default)(this.get('api.currentTime')).diff(timestamp);
        var timeRemaining = this.get('ACTIVE_PERFORMANCE_INTERVAL') - timeDifference;
        this.set('activePerformanceTimeout', setTimeout(function () {
          _this.startActivePerformancePolling();
        }, timeRemaining));
      } else {
        this.startActivePerformancePolling();
      }
    },
    /**
     * Starts polling for the activePerformanceMetrics route
     * fetches new data every 20 minutes
     * @method startActivePerformancePolling
     */
    startActivePerformancePolling: function startActivePerformancePolling() {
      var _this2 = this;
      this.set('activePerformanceInterval', setInterval(function () {
        _this2._getActivePerformanceMetrics();
      }, this.get('ACTIVE_PERFORMANCE_INTERVAL')));
      return this._getActivePerformanceMetrics().then(function () {
        if (_this2.get('_startRealtimeOnActivePerformanceLoad')) {
          _this2.startRealtimePolling();
        }
      });
    },
    /**
     * Clears timeouts and intervals related to active performance polling
     * @method stopActivePerformancePolling
     */
    stopActivePerformancePolling: function stopActivePerformancePolling() {
      clearTimeout(this.get('activePerformanceTimeout'));
      clearInterval(this.get('activePerformanceInterval'));
    },
    /**
     * Retrieve activePerformanceMetrics and update performance metrics for agent sites
     * @method _getActivePerformanceMetrics
     */
    _getActivePerformanceMetrics: function _getActivePerformanceMetrics() {
      var _this3 = this;
      var requiredSettingIds = getRequiredSettingIds(this.get('siteSettings'));
      return this.get('api.adapter').getActivePerformanceMetrics(requiredSettingIds).then(function (performanceMetrics) {
        // if back-end components fail (chatrouter etc.) there's a chance performanceMetrics results may be undefined
        if (!performanceMetrics) return;

        // persist active performance metrics in storage
        _this3.get('api.storage').persistActivePerformanceMetrics(performanceMetrics);
        _this3.set('activePerformanceMetrics', performanceMetrics);

        // if selected site no longer has performance metrics, switch selected site to next site with metric data
        var selectedSiteHasPerformanceMetrics = _this3.get('siteNamesWithMetricData').includes(_this3.get('selectedSiteName'));
        if (!selectedSiteHasPerformanceMetrics) {
          var nextDefaultSiteName = _this3.get('siteNamesWithMetricData.firstObject');
          _this3.setSelectedSite(nextDefaultSiteName);
        }
      });
    },
    /**
     * Retrieve realtime metric data
     * @method startRealtimePolling
     */
    startRealtimePolling: function startRealtimePolling() {
      if (!this.get('activePerformanceMetrics')) {
        return this.set('_startRealtimeOnActivePerformanceLoad', true);
      }
      if (this.get('isRealtimePolling')) return;
      this.set('isRealtimePolling', true);
      var selectedSite = this.get('selectedSite') || this.get('sitesWithMetricData.firstObject');
      if (!selectedSite) return; // only if no sites have metric data

      this.setSelectedSite(selectedSite.name);
    },
    /**
     * Stops the polling for fetching realtime data
     * @method stopRealtimePolling
     */
    stopRealtimePolling: function stopRealtimePolling() {
      this.set('isRealtimePolling', false);
      clearTimeout(this.get('realtimeTimeout'));
      Ember.run.cancel(this.get('refreshHeaderMetricsDebounce'));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api').off('engagementClosed', this, this._onEngagementClosed);
      this.get('api').off('ninaCoachTakeover', this, this._onNinaCoachTakeover);
      this.get('api').off('settingsLoaded', this, this._resumeActivePerformanceMetricsPolling);
      this.stopActivePerformancePolling();
      this.stopRealtimePolling();
    },
    /**
     * Sets the selected site filter
     * @method setSelectedSite
     * @param {String} siteName
     * @return {Promise} request for newly selected site metric data
     */
    setSelectedSite: function setSelectedSite(siteName) {
      if (!siteName) return; // only if no sites have metric data

      var site = this.get('sitesWithMetricData').findBy('name', siteName);
      this.set('selectedSite', site);
      var agentGroupOrBusinessUnitFilterOptions = this.get('agentGroupOrBusinessUnitFilterOptions');
      // default to first filter option
      var filterName = agentGroupOrBusinessUnitFilterOptions.get('firstObject');
      this.set('selectedFilter', filterName);
      if (filterName === 'Summary') {
        return this._getRealtimeMetrics(site.id);
      } else {
        this.setSelectedFilter(filterName);
      }
    },
    /**
     * Sets the selected filter agent group, business unit, or summary
     * @method setSelectedFilter
     * @param {String} filterName
     * @return {Promise} request with new metric filter
     */
    setSelectedFilter: function setSelectedFilter(filterName) {
      var _this$get = this.get('selectedSite'),
        siteId = _this$get.id,
        agentGroups = _this$get.agentGroups,
        businessUnits = _this$get.businessUnits;
      this.set('selectedFilter', filterName);
      if (filterName === 'Summary') return this._getRealtimeMetrics(siteId);
      if (agentGroups) {
        var _ref2 = agentGroups.findBy('name', filterName) || {},
          agentGroupId = _ref2.id;
        return this._getRealtimeMetrics(siteId, {
          ag: agentGroupId
        });
      }
      var _ref3 = businessUnits.findBy('name', filterName) || {},
        businessUnitId = _ref3.id;
      return this._getRealtimeMetrics(siteId, {
        bu: businessUnitId
      });
    },
    /**
     * An array containing all the agent groups OR business units for the selected site.
     * If a site has both agent groups and business units, agent groups will be displayed.
     * @property {Array} agentGroupOrBusinessUnitFilterOptions the array of agent groups or business units for the selected site
     * @public
     */
    agentGroupOrBusinessUnitFilterOptions: Ember.computed('selectedSite', function () {
      var selectedSite = this.get('selectedSite');
      if (!selectedSite) return [];
      var _selectedSite$getProp = selectedSite.getProperties('hasSummaryData', 'agentGroups', 'businessUnits'),
        hasSummaryData = _selectedSite$getProp.hasSummaryData,
        agentGroups = _selectedSite$getProp.agentGroups,
        businessUnits = _selectedSite$getProp.businessUnits;
      var result = hasSummaryData ? ['Summary'] : [];
      if (agentGroups) {
        var agentGroupsMetric = agentGroups.filterBy('hasMetricData').mapBy('name');
        return result.concat(agentGroupsMetric);
      }
      var businessUnitsMetric = businessUnits.filterBy('hasMetricData').mapBy('name');
      return result.concat(businessUnitsMetric);
    }),
    /**
     * Retrieves data from the realtime metrics API.
     * Will poll the API every 2 mins only when pollingEnabled is true.
     * @method _getRealtimeMetrics
     * @param {String} site the site ID of the desired metrics
     * @param {Object} options additional query parameters
     * @param {Number} options.ag the agent group ID
     * @private
     */
    _getRealtimeMetrics: function _getRealtimeMetrics(site) {
      var _this4 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      clearTimeout(this.get('realtimeTimeout'));
      this.set('realtimeTimeout', setTimeout(function () {
        _this4._getRealtimeMetrics(site, requestOptions);
      }, this.get('REALTIME_REFRESH_TIMEOUT')));
      var metricsAdapter = this.get('api.metricsAdapter');
      var requestOptions = Object.assign({
        returnFields: 'ALL',
        site: site
      }, options);
      return metricsAdapter.getData('agent', 'agents', requestOptions).then(function (result) {
        if (result && result.agents) {
          _this4._updateAgentMetrics(result.agents);
          _this4._refreshHeaderMetrics();
          _this4._createListOfAvailableMetricNames();
        }
      });
    },
    /**
     * Sets the list of available metric names which is referenced
     * to create the list of displayed metrics.
     * @method _createListOfAvailableMetricNames
     * @private
     */
    _createListOfAvailableMetricNames: function _createListOfAvailableMetricNames() {
      var _this5 = this;
      var settingIdsToFind = this._getSettingIdsToFind();
      var availableMetricData = this.get('selectedFilter') === 'Summary' ? this._getListOfSummaryMetricData(settingIdsToFind) : this._getListOfAgentGroupOrBusinessUnitMetricData(settingIdsToFind[0]);
      var agent = this.get('agent');
      var availableMetrics = [];
      var metricNames = availableMetricData.mapBy('metricName');
      metricNames.forEach(function (name) {
        var value = agent[name];
        if (value === undefined) return;
        var translatedName = "performance-metrics.".concat(name);
        var options = {
          name: name
        };
        var usesSeconds = _this5._metricsInSeconds.includes(name);
        if (usesSeconds) {
          options.type = 'seconds';
          value = _this5._formatData(value, options);
        }
        var usesPercent = _this5._metricsInPercent.includes(name);
        if (usesPercent) {
          options.type = 'percent';
          if (name !== 'agentUtilization' && name !== 'availableUtilization') {
            options.decimals = true;
          }
          value = _this5._formatData(value, options);
        }
        var usesDecimal = _this5._metricsWithDecimals.includes(name);
        if (usesDecimal) {
          options.decimals = true;
          value = _this5._formatData(value, options);
        }
        var _availableMetricData$ = availableMetricData.findBy('metricName', name),
          metricType = _availableMetricData$.metricType;
        availableMetrics.pushObject(Ember.Object.create({
          name: translatedName,
          value: value,
          metricType: metricType
        }));
      });
      this.set('availableMetrics', availableMetrics);
      this.state.set({
        loggedInTime: this.get('loggedInTimeMetrics'),
        utilization: this.get('utilizationMetrics'),
        volume: this.get('volumeMetrics'),
        efficiency: this.get('efficiencyMetrics')
      });
    },
    /**
     * Returns an array of all setting ids needed to build
     * the list of available metric names by searching for them
     * in the returned list of active performance metrics.
     * @method _getSettingIdsToFind
     * @private
     * @return {Array} a list of all setting ids required to build the available metric names list
     */
    _getSettingIdsToFind: function _getSettingIdsToFind() {
      var _this$get$getProperti = this.get('selectedSite').getProperties('agentGroups', 'businessUnits'),
        agentGroups = _this$get$getProperti.agentGroups,
        businessUnits = _this$get$getProperti.businessUnits;
      var selectedFilter = this.get('selectedFilter');
      if (selectedFilter === 'Summary') {
        if (agentGroups) {
          return agentGroups.map(function (agentGroup) {
            return agentGroup.id.toString();
          });
        }
        return businessUnits.map(function (businessUnit) {
          return businessUnit.settingId.toString();
        });
      }
      if (agentGroups) {
        var agentGroup = agentGroups.findBy('name', selectedFilter);
        return [agentGroup.id.toString()];
      }
      var businessUnit = businessUnits.findBy('name', selectedFilter);
      return [businessUnit.settingId.toString()];
    },
    /**
     * Returns the list of available metric data if the agent group
     * or business unit filter is set to 'Summary'. This list is equal
     * to the sum of all the agent group / business unit metrics in the
     * Logged in Time and Volume metric types.
     * @method _getListOfSummaryMetricData
     * @private
     * @param {Array} settingIdsToFind an array of setting ids for all agent groups or business units of the current site
     * @return {Array} a list of all summary metric data
     */
    _getListOfSummaryMetricData: function _getListOfSummaryMetricData() {
      var settingIdsToFind = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var performanceMetrics = this.get('activePerformanceMetrics');
      var availableMetricData = [];
      settingIdsToFind.forEach(function (settingIdToFind) {
        var summaryMetrics = performanceMetrics.findBy('settingId', parseInt(settingIdToFind)) || {};
        var _summaryMetrics$setti = summaryMetrics.settingMetrics,
          settingMetrics = _summaryMetrics$setti === void 0 ? [] : _summaryMetrics$setti;
        settingMetrics.forEach(function (settingMetric) {
          var metricType = settingMetric.metricType;

          // Summary only displays metrics with types "loggedInTime" or "volume"
          var isSummaryMetric = ['loggedInTime', 'volume'].includes(metricType);
          if (isSummaryMetric) {
            availableMetricData.addObject(settingMetric);
          }
        });
      });
      return availableMetricData.uniqBy('metricName');
    },
    /**
     * Returns the list of available metric data for a given agent group
     * or business unit setting id.
     * @method _getListOfAgentGroupOrBusinessUnitMetricData
     * @private
     * @param {String} settingIdToFind the setting id for the agent group or business unit
     * @return {Array} a list of metric data for an agent group or business unit
     */
    _getListOfAgentGroupOrBusinessUnitMetricData: function _getListOfAgentGroupOrBusinessUnitMetricData(settingIdToFind) {
      var performanceMetrics = this.get('activePerformanceMetrics');
      var agentGroupMetricData = performanceMetrics.findBy('settingId', parseInt(settingIdToFind));
      return agentGroupMetricData.settingMetrics;
    },
    /**
     * Takes data from the API and returns a formatted string.
     * @method _formatData
     * @param {*} data data to format
     * @param {Object} options to format the data in a specific way
     * @param {String} options.type the type the data should be formatted in (e.g., seconds or percent)
     * @param {Boolean} options.decimal whether or not the output needs 2 decimals
     * @private
     */
    _formatData: function _formatData(data) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      if (options.type === 'seconds') {
        var duration = _moment.default.duration(data);
        if (duration._milliseconds >= 1000 * 60 * 60) {
          return _moment.default.utc(duration.as('milliseconds')).format('HH:mm:ss');
        } else {
          return _moment.default.utc(duration.as('milliseconds')).format('mm:ss');
        }
      } else if (options.type === 'percent') {
        var percent = Math.round(Number(data) * 100) / 100;
        if (options.decimals) {
          percent = parseFloat(percent).toFixed(2);
        }
        return percent + '%';
      } else if (options.decimals) {
        return parseFloat(data).toFixed(2);
      }
    },
    /**
     * Retrieves data from the realtime metrics API.
     * Only retrieves the data to be displayed in the site header.
     * @private
     */
    _refreshHeaderMetrics: function _refreshHeaderMetrics() {
      var _this6 = this;
      return this._getClosedEngagements().then(function (closedEngagements) {
        if (!_this6.get('isDestroyed')) {
          _this6.set('agent.closedEngagements', closedEngagements);
        }
      });
    },
    _getClosedEngagements: function _getClosedEngagements() {
      var _this7 = this;
      var closedEngagementsField = this.get('closedEngagementsField');
      if (!closedEngagementsField) {
        return this._identifyClosedEngagementsField().then(function (closedEngagementsField) {
          return _this7._getClosedEngagementsByField(closedEngagementsField);
        });
      }
      return this._getClosedEngagementsByField(closedEngagementsField);
    },
    _getClosedEngagementsByField: function _getClosedEngagementsByField(closedEngagementsField) {
      var _this8 = this;
      return Ember.RSVP.all(this.get('siteSettings').mapBy('id').map(function (siteId) {
        return _this8.get('api.metricsAdapter').getData('agent', 'agents', {
          site: siteId,
          returnFields: "agentID,".concat(closedEngagementsField, ",ninaCoachEngagementsCompleted")
        }).then(function (_ref4) {
          var agents = _ref4.agents;
          var agentMetrics = agents.findBy('agentID', _this8.get('agentID'));
          if (!agentMetrics) return 0;
          _this8.set('agent.ninaCoachEngagementsCompleted', agentMetrics.ninaCoachEngagementsCompleted);
          return agentMetrics[closedEngagementsField] || 0;
        });
      })).then(function (closedEngagementsBySite) {
        var sumOfClosedEngagements = closedEngagementsBySite.reduce(function (sumNumClosedEngagement, currentNumClosedEngagements) {
          return sumNumClosedEngagement + currentNumClosedEngagements;
        });
        return sumOfClosedEngagements;
      });
    },
    _identifyClosedEngagementsField: function _identifyClosedEngagementsField() {
      var _this9 = this;
      var siteId = this.get('siteSettings.firstObject.id');
      var closedEngagementsField = 'totalClosedEngagements';
      return this.get('api.metricsAdapter').getData('agent', 'agents', {
        site: siteId,
        returnFields: 'agentID,totalClosedEngagements'
      }).then(function () {
        return closedEngagementsField;
      }).catch(function () {
        closedEngagementsField = 'closedEngagements';
        return closedEngagementsField;
      }).finally(function () {
        if (!_this9.isDestroyed) _this9.set('closedEngagementsField', closedEngagementsField);
      });
    },
    /**
     * Update agent metrics
     * @method _updateAgentMetrics
     * @param {Array} agentsData result.agents of response
     */
    _updateAgentMetrics: function _updateAgentMetrics() {
      var agentsData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var agentMetrics = agentsData.findBy('agentID', this.get('agentID'));

      //set property closedEngagements only in _refreshHeaderMetrics to prevent counter blinking
      if (agentMetrics) {
        delete agentMetrics.closedEngagements;
      }
      this.get('agent').setProperties(agentMetrics);
    },
    /**
     * A list of setting ids for all agent groups or business
     * units for each site the agent belongs to.
     * @property {Array} requiredSettingIds
     */
    requiredSettingIds: Ember.computed('siteSettings', function () {
      var siteSettings = this.get('siteSettings');
      if (!siteSettings) return [];
      var requiredSettingIds = [];
      siteSettings.forEach(function (_ref5) {
        var agentGroups = _ref5.agentGroups,
          businessUnits = _ref5.businessUnits;
        if (agentGroups) {
          var agentGroupsIds = agentGroups.mapBy('id');
          requiredSettingIds.pushObjects(agentGroupsIds);
        } else if (businessUnits) {
          var businessUnitSettingIds = businessUnits.mapBy('settingId');
          requiredSettingIds.pushObjects(businessUnitSettingIds);
        }
      });
      return requiredSettingIds;
    }),
    /**
     * Updates metric sites with additional metric-related properties
     * @method _getSitesWithPerformanceMetrics
     */
    _getSitesWithPerformanceMetrics: function _getSitesWithPerformanceMetrics(performanceMetrics) {
      if (!performanceMetrics) return [];
      return this.get('siteSettings').map(function (site) {
        var agentGroups = site.agentGroups,
          businessUnits = site.businessUnits;
        var availableMetricNames = [];
        if (agentGroups) {
          agentGroups.forEach(function (agentGroup) {
            var settingMetrics = getSettingMetricsById(agentGroup.id);
            agentGroup.hasMetricData = !!settingMetrics.length;
            availableMetricNames.addObjects(settingMetrics);
          });
        } else {
          businessUnits.forEach(function (businessUnit) {
            var settingMetrics = getSettingMetricsById(businessUnit.settingId);
            businessUnit.hasMetricData = !!settingMetrics.length;
            availableMetricNames.addObjects(settingMetrics);
          });
        }
        site.hasMetricData = !!availableMetricNames.length;
        var uniqSummaryMetrics = availableMetricNames.filter(function (_ref6) {
          var metricType = _ref6.metricType;
          return ['loggedInTime', 'volume'].includes(metricType);
        }).mapBy('metricName').uniq();
        site.hasSummaryData = !!uniqSummaryMetrics.length;
        return Ember.Object.create(site);
      });
      function getSettingMetricsById(settingId) {
        var _ref7 = performanceMetrics.findBy('settingId', parseInt(settingId)) || {},
          settingMetrics = _ref7.settingMetrics;
        return settingMetrics;
      }
    },
    /**
     * persist metric properties
     * @method peristObserver
     */
    peristObserver: Ember.observer('closedEngagements', 'closedEngagementsField', function () {
      var propertiesToPersist = this.getProperties('closedEngagements', 'closedEngagementsField');
      var storage = this.get('api.storage');
      storage.set('store.metrics', propertiesToPersist);
      storage.persist();
    }),
    /**
     * A list of all metric names not returned by the
     * returnFields=ALL query. These need to be grabbed from
     * the API specifically. (e.g., returnFields=assignedCount,...).
     * @property {Array} _missingMetricNames list of missing metric names
     * @private
     */
    _missingMetricNames: Object.freeze(['avgEngagementHandleTime', 'avgEngagementHandleTimeSLA', 'agentUtilization', 'availableUtilization', 'assignedCount', 'conversionProductQuantity', 'avgConversionProductQuantity']),
    /**
     * A list of metrics that need to be formatted as HH:mm.
     * @property {Array} _metricsInSeconds
     * @private
     */
    _metricsInSeconds: Object.freeze(['avgDispositionTime', 'avgInitialAgentResponseTime', 'avgEngagementHandleTime', 'totalBusyTime', 'loginDuration']),
    /**
     * A list of metrics that need to be formatted as a percent
     * with 2 decimals.
     * @property {Array} _metricsInPercent
     * @private
     */
    _metricsInPercent: Object.freeze(['percentEndedByCustomer', 'percentAssisted', 'avgEngagementHandleTimeSLA', 'percentConversion', 'cumulativeLaborUtilization', 'initialResponseWithinSLA', 'agentUtilization', 'availableUtilization']),
    /**
     * A list of metrics that are not percents but need to have
     * 2 decimals.
     * @property {Array} _metricsWithDecimals
     * @private
     */
    _metricsWithDecimals: Object.freeze(['chatsPerLaborHour', 'salesPerLaborHour', 'unitsPerConversion', 'avgOrderValue', 'avgConversionProductQuantity'])
  });
  function getRequiredSettingIds() {
    var siteSettings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var requiredSettingIds = [];
    siteSettings.forEach(function (_ref8) {
      var agentGroups = _ref8.agentGroups,
        businessUnits = _ref8.businessUnits;
      if (agentGroups) {
        var agentGroupsIds = agentGroups.mapBy('id');
        requiredSettingIds = requiredSettingIds.concat(agentGroupsIds);
      } else if (businessUnits) {
        var businessUnitSettingIds = businessUnits.mapBy('settingId');
        requiredSettingIds = requiredSettingIds.concat(businessUnitSettingIds);
      }
    });
    return requiredSettingIds;
  }
});