define("sdk/services/react-intl", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Create a Service that constructs a React Wrapper component
   * which listens to changes in intl.locale. When the locale
   * changes in settings, the Wrapper component updates the Provider
   * value.
   */
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.createProviderWrapper();
    },
    convertLocale: function convertLocale(locale) {
      var str = locale[0];
      return ['en', 'es', 'ru', 'de', 'fr', 'pt', 'ja', 'it'].includes(str) ? str : 'en';
    },
    createProviderWrapper: function createProviderWrapper() {
      var _this = this;
      var _ComponentLibrary$def = ComponentLibrary.default,
        IntlProvider = _ComponentLibrary$def.IntlProvider,
        React = _ComponentLibrary$def.React;
      var translations = this.generateTranslations();

      /**
       * On componentDidMount, add an observer to watch the 'intl.locale'
       * property. When this property updates, update the IntlProvider
       * value with the new state.
       * @param {ReactChildren} props.children React children
       */
      var IntlWrapper = function IntlWrapper(_ref) {
        var children = _ref.children;
        var locale = _this.convertLocale(_this.get('intl.locale'));
        var state = {
          locale: locale,
          messages: translations[locale],
          onError: _this.customErrorHandler
        };
        return React.createElement(IntlProvider, state, children);
      };
      this.set('Wrapper', IntlWrapper);
    },
    customErrorHandler: function customErrorHandler(message) {
      if (_emberGetConfig.default.environment !== 'production') {
        // eslint-disable-next-line no-console
        console && console.warn(message);
      }
    },
    generateTranslations: function generateTranslations() {
      var _this2 = this;
      var translations = {};
      ['en', 'es', 'ru', 'de', 'fr', 'pt', 'ja', 'it'].forEach(function (lang) {
        var translationsForLang = _this2.get('intl').translationsFor(lang);
        if (!translationsForLang) throw new Error("Unable to find translations for \"".concat(lang, "\""));
        var langTranslations = Object.fromEntries(translationsForLang.translations);
        translations[lang] = lang === 'en' ? langTranslations : Object.assign({}, translations['en'], langTranslations);
      });
      return translations;
    }
  });
});