define("sdk/templates/modals/-conference-resolve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XwHjJLVz",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"id\",\"modalClass\",\"data-test-close-conference-modal\",\"isKeyboardEnabled\",\"modalAriaLabelledBy\",\"modalAriaDescribedBy\"],[\"conference-resolve-modal\",\"conference-close-warning-modal\",\"\",false,\"conference-modal-title\",\"conference-modal-body\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"conference-modal-title\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.close-modal.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"section\"],[10,\"id\",\"conference-modal-body\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.close-modal.warning\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"button\"],[10,\"data-test-close-conference-confirm\",\"\"],[3,\"action\",[[21,0,[]],\"resolve\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.close-modal.yes\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"button\"],[10,\"data-test-close-conference-cancel\",\"\"],[3,\"action\",[[21,0,[]],[22,[\"toggleProperty\"]],\"ui.showConferenceResolveModal\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.close-modal.no\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-conference-resolve.hbs"
    }
  });
});