define("sdk/site/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "sdk/api/utils/runloop"], function (_exports, _authenticatedRouteMixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'login',
    api: Ember.inject.service(),
    notifications: Ember.inject.service(),
    devices: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    url: Ember.inject.service(),
    performance: Ember.inject.service(),
    videoCallEnabled: Ember.computed.alias('api.settings.videoCallEnabled'),
    customScriptsEnabled: Ember.computed.alias('api.settings.enableCustomScript'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var url = this.get('url');
      var hasAuthTokens = this.get('api.authAdapter.hasAuthTokens');
      // remove SSO query params after relogging in from lockout
      if (hasAuthTokens) {
        url.removeQueryParams(['state', 'authToken']);
      }
      var _url$getQueryParams = url.getQueryParams(),
        lockout = _url$getQueryParams.lockout;

      // handle refresh while on lockout route
      if (lockout) return this.transitionTo('lockout');
    },
    init: function init() {
      this.get('notifications');
      this.get('performance').createMemoryCheckInterval();
      this.get('devices');
      this.get('api').on('authenticated', this, this._onAuthenticated);
      this.get('api').on('newEngagement', this, this._onNewEngagement);
      this.get('api').on('engagementClosed', this, this._transitionToNextEngagementOrSummary);
      this.get('api').on('failedToJoinEngagement', this, this._transitionToNextEngagementOrSummary);
      this.get('api').on('agentWasLoggedOff', this, this._invalidateKickedOffAgent);
      this.get('api.agent').on('loadedFollowUp', this, this._transitionToEngagement);
      this.get('api.agent').on('placedEngagementBackIntoFollowUp', this, this._transitionToNextEngagementOrSummary);
      this.addKeyboardShortcuts();
      return this._super.apply(this, arguments);
    },
    /**
     * When agent has reloaded the page and is authenticated, transition to last active engagement route
     * @method _onAuthenticated
     */
    _onAuthenticated: function _onAuthenticated() {
      var _this = this;
      var activeEngagementId = this.get('api.storage.store.activeEngagementId');
      if (activeEngagementId) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.transitionTo('site.engagements.engagement', activeEngagementId);
        });
      }
    },
    /**
     * When there is a new engagement...
     * - if agent doesn't have any engagements already, transition to new engagement
     * - if new engagement is only follow up conversation, transition to new engagement
     * @param {Object} engagement
     * @method _onNewEngagement
     */
    _onNewEngagement: function _onNewEngagement(engagement) {
      this.loadUISettings(engagement);
      var isFirstEngagement = this.get('api.agent.engagements.firstObject') === engagement;
      var conversationId = engagement.settings.conversationId;
      if (isFirstEngagement) {
        this.transitionTo('site.engagements.engagement', engagement.id);
      } else if (conversationId) {
        var followUpConversations = this.get('api.agent.followUpConversations');
        if (followUpConversations.length === 1) {
          var conversation = followUpConversations.findBy('conversationId', conversationId);
          if (conversation) {
            this.transitionTo('site.engagements.engagement', engagement.id);
          }
        }
      }
    },
    /**
     * transition to an engagement route
     * @param {Object} engagement
     * @method _transitionToEngagement
     */
    _transitionToEngagement: function _transitionToEngagement(engagement) {
      this.transitionTo('site.engagements.engagement', engagement.id);
    },
    /**
     * Takes the agent to the next available engagement tab
     * or to the summary tab if no engagement tabs exist.
     * @method _transitionToNextEngagementOrSummary
     * @private
     */
    _transitionToNextEngagementOrSummary: function _transitionToNextEngagementOrSummary() {
      var isLockedOut = this.get('api.agent.isLockedOut');
      if (isLockedOut) return;
      var agent = this.get('api.agent');
      if (agent.get('hasEngagement')) {
        var positionedEngagementIds = this.get('api.agent.ui.positionedEngagementIds');
        var freezeTabPositionEnabled = this.get('api.agent.settings.freezeChatTabPositionByDefault');
        positionedEngagementIds.forEach(function (positionedEngagementId, index) {
          if (!positionedEngagementId) return;
          if (!agent.engagements.findBy('id', positionedEngagementId)) {
            return positionedEngagementIds.replace(index, 1, [null]);
          }
        });
        var engagementIds = freezeTabPositionEnabled ? positionedEngagementIds : agent.engagements.mapBy('id');
        var nextEngagementId = engagementIds.compact().get('firstObject');
        this.transitionTo('site.engagements.engagement', nextEngagementId);
      } else {
        this.transitionTo('site.summary');
      }
    },
    /**
     * Invalidates the agent's session and stores
     * a flag to present the agent with a message
     * when routed to login.
     * @method _invalidateKickedOffAgent
     * @private
     */
    _invalidateKickedOffAgent: function _invalidateKickedOffAgent() {
      var _this2 = this;
      sessionStorage.setItem('agent_kicked_off', true);
      var engagements = this.get('api.agent.engagements');
      engagements.forEach(function (engagement) {
        return _this2.api.trigger('removeEngagement', engagement);
      });
      if (this.get('api.storage')) {
        this.get('api.storage').clear();
      }
      this.get('session').invalidate();
    },
    addKeyboardShortcuts: function addKeyboardShortcuts() {
      var _this3 = this;
      this.handleWindowKeyDown = function (event) {
        if (event.altKey) {
          switch (event.key) {
            case 'ArrowLeft':
              // alt+ArrowLeft
              _this3.focusEngagement(event, -1);
              break;
            case 'ArrowRight':
              // alt+ArrowRight
              _this3.focusEngagement(event, 1);
              break;
          }
        } else if (event.ctrlKey) {
          switch (event.key) {
            case '`':
              // ctrl+`
              _this3.focusEngagement(event, 1);
              break;
          }
        }
      };
      window.addEventListener('keydown', this.handleWindowKeyDown);
    },
    /**
     * Determines strategy to focus based on whether any engagement already has focus
     * @method focusEngagement
     * @param {Object} event the tab keydown event
     * @param {Integer} direction - specifies direction to traverse engagements array ( 1 or -1 )
     * @public
     */
    focusEngagement: function focusEngagement(event, direction) {
      (false && !(direction === -1 || direction === 1) && Ember.assert('"direction" must be either 1 or -1', direction === -1 || direction === 1));
      var currentRouteName = this.get('router.currentRouteName');
      var isEngagementPath = currentRouteName === 'site.engagements.engagement';
      if (isEngagementPath) {
        this.focusNextEngagement(direction);
      } else {
        this.navigateToEngagements(direction === 1 ? 'first' : 'last');
      }
      event.preventDefault();
      event.stopPropagation();
    },
    /**
     * Used to focus the first or last engagement, if any. Called
     * when the user presses engagement focus key combination from a non-engagement route.
     * @method navigateToEngagements
     * @public
     * @param {String} position -  position of engagement on which to focus
     */
    navigateToEngagements: function navigateToEngagements(position) {
      (false && !(position === 'first' || position === 'last') && Ember.assert('"position" must be equal "first" or "last"', position === 'first' || position === 'last'));
      var siteController = this.controllerFor('site');
      var showSettings = siteController.get('showSettings');
      var showDeviceSettings = siteController.get('showDeviceSettings');
      var showCustomScriptsEditor = siteController.get('showCustomScriptsEditor');
      if (showSettings || showDeviceSettings || showCustomScriptsEditor) return;
      var engagements = this.get('api.agent.engagements');
      var positionedEngagementIds = this.get('api.agent.ui.positionedEngagementIds');
      var freezeTabPositionEnabled = this.get('api.agent.settings.freezeChatTabPositionByDefault');
      var engagementIds = freezeTabPositionEnabled ? positionedEngagementIds : engagements.mapBy('id');
      if (engagementIds.length >= 1) {
        var nextEngagementID = engagementIds.get("".concat(position, "Object"));
        this.transitionTo('site.engagements.engagement', nextEngagementID);
      }
    },
    /**
     * Used to switch focus from one engagement route to another.
     * Called when the user presses tab while in an engagement route.
     * @method focusNextEngagement
     * @public
     * @param {Integer} direction - specifies direction to traverse engagements array ( 1 or -1 )
     */
    focusNextEngagement: function focusNextEngagement(direction) {
      (false && !(direction === -1 || direction === 1) && Ember.assert('"direction" must be either 1 or -1', direction === -1 || direction === 1));
      var siteController = this.controllerFor('site');
      var showSettings = siteController.get('showSettings');
      var showDeviceSettings = siteController.get('showDeviceSettings');
      var showCustomScriptsEditor = siteController.get('showCustomScriptsEditor');
      if (showSettings || showDeviceSettings || showCustomScriptsEditor) return;
      var engagements = this.get('api.agent.engagements');
      var positionedEngagementIds = this.get('api.agent.ui.positionedEngagementIds');
      var freezeTabPositionEnabled = this.get('api.agent.settings.freezeChatTabPositionByDefault');
      var engagementIds = freezeTabPositionEnabled ? positionedEngagementIds : engagements.mapBy('id');
      var compactedEngagementIds = engagementIds.compact();
      if (compactedEngagementIds.length > 1) {
        var activeEngagementId = this.get('api.storage.store.activeEngagementId');
        var currentIndex = compactedEngagementIds.indexOf(activeEngagementId);
        var nextEngagementPosition = currentIndex + direction;
        if (nextEngagementPosition < 0 || nextEngagementPosition >= compactedEngagementIds.length) {
          nextEngagementPosition = direction === 1 ? 0 : compactedEngagementIds.length - 1;
        }
        var nextEngagementId = compactedEngagementIds[nextEngagementPosition];
        this.transitionTo('site.engagements.engagement', nextEngagementId);
      }
    },
    afterModel: function afterModel() {
      var _this4 = this;
      Ember.$(window).on('resize', function () {
        Ember.run(function () {
          _this4.send('didUpdateEngagementUI');
        });
      });

      // clear previous session errors
      sessionStorage.removeItem('error_invalidate');
      this._registerInteractionHandlers();

      // prevent page refresh in production
      var isLocalhost = /localhost|local\.nuance\.com/i.test(window.location.href);
      if (!isLocalhost && !window.runningTests) {
        Ember.$(window).on('beforeunload', function (event) {
          var confirmationMessage = _this4.get('intl').t('misc.prevent-refresh-message');
          event.returnValue = confirmationMessage;
          return confirmationMessage;
        });
      }
    },
    loadUISettings: function loadUISettings(engagement) {
      var pluginApi = Ember.Object.create({
        addPanel: function addPanel(sectionName, obj) {
          var sections = {
            tools: 'webTools'
          };
          var section = sections[sectionName];
          if (!section) {
            throw new Error("".concat(sectionName, " is not a valid panel section"));
          }
          engagement.ui[section].addObject(obj);
        }
      });
      engagement.on('pluginInit', function (plugin) {
        plugin.set('ui', pluginApi);
      });
    },
    /**
     * Creates handlers for mouse move and click events to trigger "agentInteracted" event
     * @method _registerInteractionHandlers
     */
    _registerInteractionHandlers: function _registerInteractionHandlers() {
      var _this5 = this;
      Ember.$('body').on('click.inactivity keydown.inactivity mousemove.inactivity', function () {
        (0, _runloop.throttle)(_this5, _this5._triggerAgentInteracted, 300);
      });
    },
    /**
     * Trigger agent iteracted event
     * @method _triggerAgentInteracted
     */
    _triggerAgentInteracted: function _triggerAgentInteracted() {
      // due to an unexplained bug in IE where a mouse event is triggered when transitioning to a new engagement
      // we are going to ignore the first event
      if (this.get('shouldIgnoreEvent')) {
        this.set('shouldIgnoreEvent', false);
        return;
      }
      this.api.trigger('agentInteracted');
    },
    actions: {
      didTransition: function didTransition() {
        var _this6 = this;
        this.set('shouldIgnoreEvent', true);
        Ember.run.schedule('afterRender', function () {
          _this6.send('didUpdateEngagementUI');
        });
      },
      openCustomScriptsEditor: function openCustomScriptsEditor() {
        if (!this.get('customScriptsEnabled')) return;
        this.controller.set('showCustomScriptsEditor', true);
        this.controller.set('showMenu', false);
      },
      willUpdateEngagementUI: function willUpdateEngagementUI() {
        this.controller.set('currentEngagementId', null);
      },
      didUpdateEngagementUI: function didUpdateEngagementUI(engagementId) {
        // currentEngagementId passed into the automaton frame component
        // when this property is modified the component will recalculate
        // which automaton to show and it's position.
        if (!engagementId) {
          this.controller.notifyPropertyChange('currentEngagementId');
        } else {
          this.controller.set('currentEngagementId', null);
          this.controller.set('currentEngagementId', engagementId);
        }
      },
      columnResizeEvent: function columnResizeEvent(isResizing) {
        this.controller.set('shouldOverlayIFrame', isResizing);
        this.get('api.storage').persist();
      }
    },
    willDestroy: function willDestroy() {
      this.get('api').off('authenticated', this, this._onAuthenticated);
      this.get('api').off('newEngagement', this, this._onNewEngagement);
      this.get('api').off('engagementClosed', this, this._transitionToNextEngagementOrSummary);
      this.get('api').off('failedToJoinEngagement', this, this._transitionToNextEngagementOrSummary);
      this.get('api').off('agentWasLoggedOff', this, this._invalidateKickedOffAgent);
      this.get('api.agent').off('loadedFollowUp', this, this._transitionToEngagement);
      this.get('api.agent').off('placedEngagementBackIntoFollowUp', this, this._transitionToNextEngagementOrSummary);
      Ember.$(window).off('resize');
      Ember.$('body').off('.inactivity');
      window.removeEventListener('keydown', this.handleWindowKeyDown);
    }
  });
});