define("sdk/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: 'site.summary',
    url: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this$get$getQueryPar = this.get('url').getQueryParams(),
        _this$get$getQueryPar2 = _this$get$getQueryPar.SSO,
        SSO = _this$get$getQueryPar2 === void 0 ? '' : _this$get$getQueryPar2,
        lockout = _this$get$getQueryPar.lockout,
        authToken = _this$get$getQueryPar.authToken,
        state = _this$get$getQueryPar.state;
      var isSSORelogin = SSO.includes('relogin');
      var isSSOReloginHasAuth = isSSORelogin && (authToken || state);

      // prevent storage clear when SSO agent relogging in after being locked out
      var shouldClearStorage = !isSSOReloginHasAuth;
      if (shouldClearStorage) {
        this.get('api.storage').clear();
      }
      var isLockout = lockout === 'true';
      if (isLockout) return this.transitionTo('lockout');

      // if relogging in and agent clicks back button on IDP, redirect to sso.logout
      var isSSOReloginNoAuth = isSSORelogin && !(authToken || state);
      var isSSOLogout = SSO === 'logout';
      if (isSSOLogout || isSSOReloginNoAuth) return this.transitionTo('sso.logout');
      var isSSOLogin = !!SSO;
      if (isSSOLogin) return this.transitionTo('sso.login');
    }
  });
});