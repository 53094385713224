define("sdk/api/utils/message", ["exports", "sdk/api/utils/parse-saml-datapass", "sdk/api/utils/parse-html", "dompurify", "moment", "sdk/api/utils/linkify", "sdk/api/utils/encode"], function (_exports, _parseSamlDatapass, _parseHtml, _dompurify, _moment, _linkify, _encode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._addMaskingStyle = _addMaskingStyle;
  _exports._parseAppleBusinessChat = _parseAppleBusinessChat;
  _exports.getSMSStatusMessageParts = getSMSStatusMessageParts;
  _exports.isFileUploadLink = isFileUploadLink;
  _exports.isTransferOrConferenceNotes = isTransferOrConferenceNotes;
  _exports.renderedHTML = renderedHTML;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * Rendered HTML of messageText
   * @method renderedHTML
   * @returns String
   */
  function renderedHTML(line) {
    var messageText = line.get('messageText');

    // Async Chat ID
    var isAsyncChatId = line.get('messageType') === 'ChatID';
    var isAsyncConversationId = line.get('messageType') === 'ConversationID';
    if (isAsyncChatId || isAsyncConversationId) {
      var _messageText$split = messageText.split(' '),
        _messageText$split2 = _slicedToArray(_messageText$split, 2),
        chatID = _messageText$split2[0],
        timestamp = _messageText$split2[1];
      return "".concat(timestamp, "<span class=\"vertical-separator\"></span>Engagement ID ").concat(chatID);
    }

    // SAML Datapass
    if (line.get('isSAMLDatapass')) {
      return (0, _parseSamlDatapass.default)(messageText);
    }

    // Masking
    var maskedText = line.get('maskedText');
    var hasMaskedText = !!maskedText;
    if (hasMaskedText) {
      var isEnabledBlockMaskedSelection = line.get('engagement.settings.isEnabledBlockMaskedSelection');
      if (line.get('isFileUploadLink')) maskedText = _replaceMaskedTextInUrl(messageText, maskedText);
      messageText = _addMaskingStyle(messageText, maskedText, isEnabledBlockMaskedSelection);
    }

    // Internal (internal only)
    var hasInternalOnly = typeof messageText === 'string' && messageText.startsWith('(internal only) ');
    if (hasInternalOnly) {
      messageText = messageText.replace('(internal only) ', '');
    }
    var allowedTags = (line.get('isAgentMessage') || line.get('isConferenceAgentMessage')) && _encode.allowedAgentHTMLTags || line.get('isCustomerMessage') && _encode.allowedCustomerHTMLTags || _encode.allowedSystemHTMLTags;

    // & is encoded now so that it will be double encoded as this helps to render encoded entities for customer messages
    messageText = line.get('isCustomerMessage') ? messageText.replaceAll('&', '&amp;') : messageText;
    messageText = (0, _parseHtml.encodeHtml)(messageText, {
      allowedTags: allowedTags
    }).trim();

    // File Upload Link
    if (line.get('isFileUploadLink')) return (0, _linkify.linkify)(messageText, {
      disableFocus: true
    });

    // Linkify
    var isExternalMessage = line.get('isCustomerMessage') || line.get('isNinaMessage');
    var shouldLinkify = line.get('type') !== 'plugin' && !hasMaskedText && !isExternalMessage;
    if (shouldLinkify) {
      messageText = (0, _linkify.linkify)(messageText, {
        disableFocus: true
      });
    }

    // Sanitize
    var isCobrowseEvent = line.get('prefix') === 'COBROWSE_EVENT';
    if (!isCobrowseEvent) {
      messageText = _dompurify.default.sanitize(messageText, {
        ADD_ATTR: ['target']
      });
    }
    return messageText;
  }

  /**
   * Array of objects with words returned from the smsStatus response after sending an SMS message
   * If a word is wrapped in "${}" e.g "${YES}"
   * then add property isResend or isSkip to render a link rather than just text
   * @param {String} smsStatus
   * @return {Array} array of "parts" (objects) containing properties "text", "isResend", "isSkip"
   */
  function getSMSStatusMessageParts(smsStatus) {
    if (!smsStatus || smsStatus === 'Delivered') return [];
    var statusWords = smsStatus.split(' ');
    var statusMessages = statusWords.map(function (word) {
      var linkWordRegex = /\${(\w+)}/; // ${YES} or ${NO}
      var linkWordMatch = word.match(linkWordRegex);
      if (linkWordMatch) {
        var linkWord = linkWordMatch[1]; // "YES" or "NO"

        return {
          text: linkWord,
          isResend: ['YES'].includes(linkWord),
          isSkip: ['NO'].includes(linkWord)
        };
      }
      return {
        text: word
      };
    });
    return statusMessages;
  }
  function _addMaskingStyle(messageText, maskedText) {
    if (!maskedText) return messageText;

    // prevent html tags and attributes from being masked
    var stashedMaskedText = (0, _parseHtml.stashHtmlTags)(maskedText);
    var stashedMessageText = (0, _parseHtml.stashHtmlTags)(messageText);

    // wrap all sequenced 'X's with class "masked"
    var wrappedMaskedText = stashedMaskedText.replace(/X[X-\s./]*X/gi, function (match, offset) {
      var maskedMatch = stashedMessageText.substring(offset, offset + match.length);
      return match === maskedMatch ? match : "<span data-test-masked class=\"masked\">".concat(maskedMatch, "</span>");
    });
    return (0, _parseHtml.restoreHtmlTags)(wrappedMaskedText, messageText);
  }
  function _replaceMaskedTextInUrl(messageText, maskedText) {
    return maskedText.replace(/(?:https?:\/\/\S*)X[-X]*X/gi, function (match, offset) {
      return messageText.substring(offset, offset + match.length);
    });
  }
  function _parseAppleBusinessChat(text, line) {
    if (line.get('isAgentMessage')) return text;
    var json;
    try {
      json = JSON.parse(text);
    } catch (e) {
      // if JSON is malformed, display it all as a string
      return _getABCDataStringWithAnchorTag(text);
    }
    var _json = json,
      interactiveData = _json.interactiveData;
    var _ref = interactiveData || {},
      data = _ref.data;

    // if we cannot format the data because it's missing, display it all as a string
    if (!data) return _getABCDataStringWithAnchorTag(json);
    var isListPicker = !!data.listPicker;
    var isTimePicker = !!data.event;
    if (isListPicker) {
      var sections = data.listPicker.sections;
      var selected = sections[0];
      var items = selected.items;
      var formattedItems = items.map(function (item) {
        return "".concat(item.title, ": ").concat(item.subtitle);
      });
      return "[".concat(formattedItems.join(' | '), "]");
    } else if (isTimePicker) {
      var _data$event = data.event,
        title = _data$event.title,
        timezoneOffset = _data$event.timezoneOffset,
        timeslots = _data$event.timeslots;
      var formattedTimeslots = timeslots.map(function (timeslot) {
        return _moment.default.utc(timeslot.startTime).add(timezoneOffset, 'minutes').format('M/D/Y h:mma');
      });
      return "[".concat(title, ": [").concat(formattedTimeslots.join(' | '), "]]");
    } else {
      throw 'Unrecognized apple business chat data: ' + text;
    }
  }

  /**
   * Returns the incoming JSON data as a string with URLs returned as
   * anchor tags. Can take a JSON string or object.
   * Used when the data is malformed or has no interactiveData.data property.
   * @method _getABCDataStringWithAnchorTag
   * @param {Object|String} data the json data as a string or object
   * @return {String}
   * @private
   */
  function _getABCDataStringWithAnchorTag(data) {
    if (_typeof(data) === 'object') {
      _traverseAndReplaceURLWithAnchorTag(data);
      return JSON.stringify(data);
    }
    return (0, _linkify.linkify)(data, {
      disableFocus: true
    });
  }

  /**
   * Traverses the JSON data as an object and catches any URL
   * properties, converting them into anchor tags.
   * @method _traverseAndReplaceURLWithAnchorTag
   * @param {Object} object the JSON object
   * @private
   */
  function _traverseAndReplaceURLWithAnchorTag(object) {
    for (var prop in object) {
      if (prop === 'URL') {
        // no quotes around attributes since JSON.stringify will do that for us
        object[prop] = (0, _linkify.linkify)(object[prop], {
          noQuotes: true,
          disableFocus: true
        });
      } else if (object[prop] !== null && _typeof(object[prop]) == 'object') {
        _traverseAndReplaceURLWithAnchorTag(object[prop]);
      }
    }
  }

  /**
   * Checks if the messageText matches the given pattern
   * @method isFileUploadLink
   * @param {String} messageText
   * @return {Boolean}
   */
  function isFileUploadLink() {
    var messageText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    //TODO: this hardcoding of domain names will be removed after we receive 'File Transfer API Url'- HTMLAI-1909
    return !!messageText.match(/\.(rakuten-it.com|rmb-it.jp|rmb-lab.jp|nuance.com|((touchcommerce|inq)\.com\/chatfiles))/i);
  }
  function isTransferOrConferenceNotes(lineText, lineType) {
    var lineContent = (lineText || '').toLowerCase();
    var validLineType = ['TRANSFER', 'CONFERENCE', 'CHAT.STATUSDISPLAYED'].includes(lineType.toUpperCase());
    var hasTransferNotes = lineContent.includes('transfer notes:');
    var hasConferenceNotes = lineContent.includes('conference notes:');
    return validLineType && (hasTransferNotes || hasConferenceNotes);
  }
});