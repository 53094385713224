define("sdk/plugins/link-request/plugin", ["exports", "sdk/plugins/open-page/plugin"], function (_exports, _plugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Supports BofA "Synergy" links fires 2 network requests when loaded
   * 1. url: opens in a new window
   * 2. hiddenGetRequestUrl: performs a hidden GET request
   */
  var _default = _exports.default = _plugin.default.extend({
    name: 'link-request',
    run: function run() {
      var _this$pluginParams = this.pluginParams,
        url = _this$pluginParams.url,
        hiddenGetRequestUrl = _this$pluginParams.hiddenGetRequestUrl;
      if (url) {
        // use Open Page Plugin to open url in new window
        this._super.apply(this, arguments);
      }
      if (hiddenGetRequestUrl) {
        // create hidden iframe to create a hidden GET request
        this._performHiddenGetRequest(hiddenGetRequestUrl);
      }
    },
    /**
     * Create a hidden iframe to perform a hidden GET request
     * Once request is loaded, iframe will be removed
     * @method _performHiddenGetRequest
     * @param {String} url the GET request url
     */
    _performHiddenGetRequest: function _performHiddenGetRequest(url) {
      var iframe = document.createElement('iframe');
      iframe.src = url;
      iframe.style.cssText = 'display:none;';
      document.body.appendChild(iframe);
      iframe.onload = function () {
        iframe.remove();
      };
    }
  });
});