define("sdk/templates/modals/-ownership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OZ5QuRb4",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"id\",\"modalClass\",\"modalAriaLabelledBy\"],[\"ownership\",\"ownership-modal\",\"ownership-modal-title\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"ownership-modal-title\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.ownership-modal.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"button\"],[10,\"data-test-accept-ownership\",\"\"],[3,\"action\",[[21,0,[]],\"acceptOwnership\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.ownership-modal.accept\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[6,\"button\"],[10,\"data-test-refuse-ownership\",\"\"],[3,\"action\",[[21,0,[]],\"refuseOwnership\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"conference.ownership-modal.refuse\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-ownership.hbs"
    }
  });
});