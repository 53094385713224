define("sdk/api/utils/search-match-score", ["exports", "sdk/utils/grammar", "sdk/utils/regex"], function (_exports, _grammar, _regex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getMatchScore;
  _exports.removeDiacritics = removeDiacritics;
  /**
   * Gives a score based on the amount of matches the search string has in the text
   *
   * Scoring:
   *
   * - Match = 1000pts
   *  Points for whether or not the search word is found in the text
   *
   * - Whole Word = 1pt
   *  Search word is found as a whole word i.e surrounded by spaces
   *
   *    Example: search for "one two"
   *       "one two three" - +3pts for "one", "two", "three"
   *       "one twothree " - +1pt for "one"
   *
   * - Index = 1000pts - (100 * (index + 1))pts
   *  First search word has a higher score than search words towards the end.
   *
   *    Example: search for "one two"
   *        "I wish I had one" - "one" would have a bonus Index score of  1000 - 100 = 900pts
   *        "it takes two to tango" - "two" is not worth as much as "one" and has a bonus Index score of 1000 - 200 = 800pts
   *
   * - Sequential = 10pts
   *  Get bonus for each search word found in correct order
   *
   *    Example: search for "chicken farm"
   *        "this farm had a lot of chickens" - no sequential bonus scores
   *        "cows, chickens, pigs are all at this farm" - +10pts for "chicken" being before "farm"
   *
   * Example: search for "one two three"
   * - "three two one" = 1001 + 900 + 1001 + 800 + 1001 + 700 = 5403pts
   * - "one three two" = 1001 + 900 + 1001 + 800 + 10 + 1001 + 70 = 5413pts
   * - "one two three" = 1001 + 900 + 1001 + 800 + 10 + 1001 + 70 + 10 = 5423pts
   * - "two chickens, three potatoes" = 1001 + 800 + 1001 + 700 + 10 = 3512pts
   *
   * @method getMatchScore
   * @param {String} text
   * @param {String} search
   * @param {Number} startingValue starting value of match score
   * @return {Number} a match score derived from the algorithm described above
   */
  function getMatchScore() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var startingValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    text = removeDiacritics(text).toLowerCase();
    var searchWords = (0, _grammar.getWords)(search);
    var previousMatchedSearchWordIndex = null;
    return searchWords.reduce(function (score, searchWord, index) {
      searchWord = removeDiacritics(searchWord).toLowerCase();
      if (hasText(text, searchWord)) {
        // Match bonus
        var matchScore = 1000;
        score += matchScore;

        // Whole Word bonus
        var isWholeWordMatch = (0, _grammar.hasWholeWord)(text, searchWord);
        score += isWholeWordMatch ? 1 : 0;

        // Index bonus
        var indexScore = 1000 - 100 * (index + 1);
        score += indexScore;
        var indexOfMatchedWord = text.indexOf(searchWord);

        // Sequential bonus
        var isSequentialMatch = previousMatchedSearchWordIndex !== null && indexOfMatchedWord > previousMatchedSearchWordIndex;
        score += isSequentialMatch ? 10 : 0;
        previousMatchedSearchWordIndex = indexOfMatchedWord;
      } else {
        previousMatchedSearchWordIndex = null;
      }
      return score;
    }, startingValue);
  }
  function hasText() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var searchRegex = RegExp((0, _regex.escapeRegex)(search), 'ig');
    return searchRegex.test(text);
  }

  /**
   * Replace characters with accents or diacritics with their non-diacritic character version
   * Example: "Crème Brulée"
   * 1. normalize()ing to NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
   *    The è of Crème ends up expressed as e + ̀.
   * 2. U+0300 → U+036F: Combining Diacritical Marks Unicode block
   *    Remove accented marks e.g ` ' etc. after being normalized
   * @method removeDiacritics
   * @param {String} text
   */
  // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  function removeDiacritics() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
});