define("sdk/components/escalation-form/component", ["exports", "sdk/components/escalation-form/template", "sdk/core/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Can escalate an engagement.
   *
   * @class Components.EscalationForm
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'escalation-form',
    actions: {
      /**
       * Cancels the escalation of the engagement.
       * Called when the user clicks the cancel button.
       * @method cancel
       */
      cancel: function cancel() {
        var onCancel = this.get('onCancel');
        onCancel && onCancel();
      },
      /**
       * Escalates the engagement with the agent's notes, if any.
       * Called when the user clicks the submit button.
       * @method submit
       * @return {Promise} the promise returned by the router.escalateEngagement method
       */
      submit: function submit() {
        var _this = this;
        var engagement = this.get('engagement');
        var reason = this.get('notes');
        return this.get('api.router').escalateEngagement(engagement, reason).then(function () {
          var onComplete = _this.get('onComplete');
          onComplete && onComplete();
        });
      }
    }
  });
});