define("sdk/components/rich-widget-preview-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wsftkkO8",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"rich-widget-preview-button__container\"],[8],[0,\"\\n  \"],[1,[26,\"widget-image\",null,[[\"data-test-rich-widget-thumbnail\",\"handleImgLoad\",\"widget\"],[\"\",[22,[\"onImgLoad\"]],[22,[\"richWidget\"]]]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/rich-widget-preview-button/template.hbs"
    }
  });
});