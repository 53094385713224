define("sdk/components/sdk-scripts/script-section/component", ["exports", "sdk/core/component", "sdk/components/sdk-scripts/script-section/template", "ember-intl", "sdk/mixins/grid-widget"], function (_exports, _component, _template, _emberIntl, _gridWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_gridWidget.default, {
    layout: _template.default,
    attributeBindings: ['aria-label', 'role'],
    componentName: 'script-section',
    classNameBindings: ['isCollapsed:collapsed', 'isExpanded:expanded'],
    'data-test-script-section': '',
    isCollapsed: Ember.computed.reads('section.isCollapsed'),
    isExpanded: Ember.computed.not('isCollapsed'),
    activeIndex: -1,
    'aria-label': (0, _emberIntl.t)('a11y.arrow-key-navigation'),
    role: 'application',
    actions: {
      onSectionToggle: function onSectionToggle() {
        this.toggleProperty('isCollapsed');
        if (this.get('isCollapsed')) {
          this.collapseSection(this.get('section.id'));
        } else {
          this.expandSection(this.get('section.id'));
        }
      },
      sendScript: function sendScript() {
        var onSendScript = this.get('sendScript');
        onSendScript && onSendScript();
      }
    }
  }).reopenClass({
    positionalParams: ['section']
  });
});