define("sdk/components/quill-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "bR5GAmwr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"dir\",\"auto\"],[10,\"class\",\"quill-editor\"],[11,\"aria-label\",[26,\"t\",[\"active-engagement.type-your-message\"],null],null],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/quill-editor/template.hbs"
    }
  });
});