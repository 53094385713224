define("sdk/services/notifications", ["exports", "sdk/mixins/sound", "sdk/api/utils/runloop"], function (_exports, _sound, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, _sound.default, {
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    sounds: Object.freeze({
      newCustomerMessage: 'message.mp4',
      newEngagement: 'ring.mp4',
      customerDisconnect: 'door-close.mp4',
      outOfFocusNewCustomerMessage: 'message.mp4',
      loggingIn: 'silence.mp4',
      saleOccurredMessage: 'money.mp4'
    }),
    hasFocus: true,
    idleIntervalDuration: 10,
    init: function init() {
      this._super(arguments);
      this.set('activeNotifications', []);

      // Object.values() is polyfilled in dependency "es7-shim"
      var soundFiles = Object.values(this.sounds).uniq();
      this.initAudioCache(soundFiles);
      this.set('activeNotifications', []);
      this.get('api').on('settingsLoaded', this, this.initDesktopNotifications);
      this._addConnectionListeners();
      this._addSoundListeners();
      this._addWindowListeners();
    },
    initDesktopNotifications: function initDesktopNotifications() {
      if (!('Notification' in window) || Notification.permission == 'denied' || !this.get('api.settings.enableDesktopNotification')) {
        return;
      }
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(this.setDesktopNotifications());
      } else {
        this.setDesktopNotifications();
      }
    },
    setDesktopNotifications: function setDesktopNotifications() {
      var api = this.get('api');
      api.on('newEngagement', this, this._sendNewEngagementNotification);
      api.on('newCustomerMessage', this, this._sendNewCustomerMessageNotification);
      api.on('newCustomerData', this, this._sendNewCustomerMessageNotification);
    },
    _sendNewEngagementNotification: function _sendNewEngagementNotification(engagement) {
      this.sendDesktopNotification('notify-new-engagement', engagement);
    },
    _sendNewCustomerMessageNotification: function _sendNewCustomerMessageNotification(engagement) {
      (0, _runloop.throttle)(this, this.sendDesktopNotification, 'notify-new-message', engagement, 1000);
    },
    sendDesktopNotification: function sendDesktopNotification(translationPath, engagement) {
      var _this = this;
      if (this.get('hasFocus')) return;
      var message = this._intlTranslate(translationPath);
      var notification = new Notification('Nuance Agent Desktop', {
        icon: 'assets/images/favicon.png',
        body: message
      });
      if (engagement) {
        notification.onclick = function () {
          var hasEngagement = _this.get('api.agent.engagements').findBy('id', engagement.id);
          if (hasEngagement) {
            _this.get('router').transitionTo('site.engagements.engagement', engagement.id);
          }
          window.focus();
        };
      }
    },
    /**
     * Creates a notification to be displayed. Can be removed
     * via its name property.
     * @method addNotification
     * @public
     * @param {Object} options options object
     * @param {String} options.name the name of the notifications
     * @param {String} options.type the type of notification
     * @param {String} options.title the title of the notification
     * @return {Object} the notification
     */
    addNotification: function addNotification() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var activeNotifications = this.get('activeNotifications');
      if (activeNotifications.findBy('name', options.name)) return;
      options.type = options.type || 'error';
      var notification = Ember.Object.create(options);
      var lockdown = options.lockdown;
      if (lockdown) {
        notification.set('type', 'lockdown');
      }
      activeNotifications.addObject(notification);
      return notification;
    },
    /**
     * Removes a notification by the given name.
     * @method removeNotification
     * @public
     * @param {String} name the name of the notification
     */
    removeNotification: function removeNotification(name) {
      var activeNotifications = this.get('activeNotifications');
      var notificationToRemove = this.getNotification(name);
      if (!notificationToRemove) return;
      activeNotifications.removeObject(notificationToRemove);
    },
    /**
     * Get notification object by name
     * @method getNotification
     * @param {String} name notification name
     */
    getNotification: function getNotification(name) {
      return this.get('activeNotifications').findBy('name', name);
    },
    resumeAudioContext: function resumeAudioContext() {
      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume();
      }
    },
    _playSound: function _playSound(eventName) {
      var engagement = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var message = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var soundFilename = this.get("sounds.".concat(eventName));
      var disabledByAgent = !this.get('api.agent.settings.playSounds');
      if (disabledByAgent) return;
      var alwaysPlaySoundEvents = ['newEngagement', 'loggingIn', 'saleOccurredMessage'];

      // Audio Alert on New Engagement (always plays sound if agent doesn't disable all sounds)
      if (alwaysPlaySoundEvents.includes(eventName)) {
        return this.playSound(soundFilename);
      }
      var isCustomerMessage = eventName === 'newCustomerMessage';
      var ignoreFirstCustomerMessage = !!(isCustomerMessage && message.index === 1 && this.get('isPlayingSound'));
      if (ignoreFirstCustomerMessage) return;

      // Audio Alert on Customer Exit
      if (eventName === 'customerDisconnect') {
        var _engagement$settings = engagement.settings,
          settings = _engagement$settings === void 0 ? {} : _engagement$settings;
        var _settings$audibleAler = settings.audibleAlertOnExit,
          audibleAlertOnExit = _settings$audibleAler === void 0 ? true : _settings$audibleAler;
        return audibleAlertOnExit ? this.playSound(soundFilename) : null;
      }
      var soundsEnabled = this.get('api.settings.enableAudibleAlert');
      if (!soundsEnabled) return;

      /*
      Audio Alert for Customer Messages (enableAudioAlertWhenNotInFocus)
      - Always Alert: enableAudioAlertWhenNotInFocus = false
      - Alert when not in focus: enableAudioAlertWhenNotInFocus = true
      */
      var alertNotInFocus = this.get('api.settings.enableAudibleAlertWhenNotInFocus');
      if (alertNotInFocus && isCustomerMessage && this.get('hasFocus')) return;
      return this.playSound(soundFilename);
    },
    _addConnectionListeners: function _addConnectionListeners() {
      var api = this.get('api');
      api.on('connectionRestored', this, this._onConnectionRestored);
      api.on('connectionLost', this, this._onConnectionLost);
      api.on('connectionLostNoInternet', this, this._onConnectionLostNoInternet);
    },
    /**
     * When connection is restored, hide reconnecting notification
     * @method _onConnectionRestored
     */
    _onConnectionRestored: function _onConnectionRestored() {
      this.removeNotification('lost-connection');
    },
    /**
     * When connection is lost, display reconnecting notification
     * @method _onConnectionLost
     */
    _onConnectionLost: function _onConnectionLost() {
      this.addNotification({
        name: 'lost-connection',
        message: this._intlTranslate('lost-connection'),
        icon: 'spinner',
        iconPulse: true,
        type: 'lockdown'
      });
    },
    /**
     * When there is no internet connection, display no internet reconnecting notification
     * @method _onConnectionLostNoInternet
     */
    _onConnectionLostNoInternet: function _onConnectionLostNoInternet() {
      var lostConnectionNotification = this.getNotification('lost-connection');
      if (!lostConnectionNotification) return;
      lostConnectionNotification.setProperties({
        message: this._intlTranslate('lost-connection-no-internet'),
        icon: 'exclamation-triangle',
        iconPulse: false
      });
    },
    _addSoundListeners: function _addSoundListeners() {
      var api = this.get('api');
      api.on('customerDisconnect', this, this._onCustomerDisconnect);
      api.on('newCustomerMessage', this, this._onNewCustomerMessage);
      api.on('newEngagement', this, this._onNewEngagement);
      api.on('outOfFocusNewCustomerMessage', this, this._onOutOfFocusNewCustomerMessage);
      api.on('loggingIn', this, this._onLoggingIn);
      api.on('saleOccurredMessage', this, this._onSaleOccurredMessage);
      this.on('newCustomerMessage', this, this._onNewCustomerMessage);
      this.on('newEngagement', this, this._onNewEngagement);
      this.on('customerDisconnect', this, this._onCustomerDisconnect);
      this.on('outOfFocusNewCustomerMessage', this, this._onOutOfFocusNewCustomerMessage);
      this.on('loggingIn', this, this._onLoggingIn);
      this.on('saleOccurredMessage', this, this._onSaleOccurredMessage);
    },
    /**
     * When customer disconnects, clear idle interval if there are no open engagements
     * @method _onCustomerDisconnect
     */
    _onCustomerDisconnect: function _onCustomerDisconnect(engagement, message) {
      var openEngagements = this.get('api.agent.engagements').filterBy('customer.connected', true);
      if (!openEngagements.length) {
        this._clearIdleInterval();
      }
      this._playSound('customerDisconnect', engagement, message);
    },
    /**
     * On new customer message, start idle interval if application doesn't have focus
     * @method _onNewCustomerMessage
     */
    _onNewCustomerMessage: function _onNewCustomerMessage(engagement, message) {
      if (!(this.get('hasFocus') || this.get('idleInterval'))) {
        this._startIdleInterval();
      }
      this._playSound('newCustomerMessage', engagement, message);
    },
    _onNewEngagement: function _onNewEngagement(engagement, message) {
      this._playSound('newEngagement', engagement, message);
    },
    _onOutOfFocusNewCustomerMessage: function _onOutOfFocusNewCustomerMessage(engagement, message) {
      this._playSound('outOfFocusNewCustomerMessage', engagement, message);
    },
    _onLoggingIn: function _onLoggingIn(engagement, message) {
      this._playSound('loggingIn', engagement, message);
    },
    _onSaleOccurredMessage: function _onSaleOccurredMessage(engagement, message) {
      this._playSound('saleOccurredMessage', engagement, message);
    },
    _addWindowListeners: function _addWindowListeners() {
      Ember.$(window).on('focus.notifications', this._onWindowFocusIn.bind(this));
      Ember.$(window).on('blur.notifications', this._onWindowFocusOut.bind(this));
    },
    _onWindowFocusIn: function _onWindowFocusIn() {
      this.set('hasFocus', true);
      this._clearIdleInterval();
    },
    _onWindowFocusOut: function _onWindowFocusOut() {
      this.set('hasFocus', false);
    },
    _startIdleInterval: function _startIdleInterval() {
      var _this2 = this;
      this.set('idleInterval', setInterval(function () {
        _this2.trigger('outOfFocusNewCustomerMessage');
      }, this.get('idleIntervalDuration') * 1000));
    },
    _clearIdleInterval: function _clearIdleInterval() {
      if (this.get('idleInterval') && !this.get('isDestroyed')) {
        clearInterval(this.get('idleInterval'));
        this.set('idleInterval', null);
      }
    },
    willDestroy: function willDestroy() {
      this._clearIdleInterval();
      var api = this.get('api');
      api.off('connectionRestored', this, this._onConnectionRestored);
      api.off('connectionLost', this, this._onConnectionLost);
      api.off('connectionLostNoInternet', this, this._onConnectionLostNoInternet);
      api.off('customerDisconnect', this, this._onCustomerDisconnect);
      api.off('newCustomerMessage', this, this._onNewCustomerMessage);
      api.off('newEngagement', this, this._onNewEngagement);
      api.off('outOfFocusNewCustomerMessage', this, this._onOutOfFocusNewCustomerMessage);
      api.off('loggingIn', this, this._onLoggingIn);
      api.off('saleOccurredMessage', this, this._onSaleOccurredMessage);
      api.off('settingsLoaded', this, this.initDesktopNotifications);
      api.off('newEngagement', this, this._sendNewEngagementNotification);
      api.off('newCustomerMessage', this, this._sendNewCustomerMessageNotification);
      api.off('newCustomerData', this, this._sendNewCustomerMessageNotification);
      this.off('newCustomerMessage', this, this._onNewCustomerMessage);
      this.off('newEngagement', this, this._onNewEngagement);
      this.off('customerDisconnect', this, this._onCustomerDisconnect);
      this.off('outOfFocusNewCustomerMessage', this, this._onOutOfFocusNewCustomerMessage);
      this.off('loggingIn', this, this._onLoggingIn);
      this.off('saleOccurredMessage', this, this._onSaleOccurredMessage);
      Ember.$(window).off('.notifications');
    },
    /**
     * Helper method to display notifications translation text
     * @method _intlTranslate
     * @param {String} keyword intl notifications[keyword]
     */
    _intlTranslate: function _intlTranslate(keyword) {
      return this.get('intl').t("notifications.".concat(keyword));
    }
  });
});