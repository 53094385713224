define("sdk/templates/modals/-agent-status-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Xp7MsbEX",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"id\",\"modalRole\",\"hasCloseButton\",\"modalAriaLabelledBy\",\"modalAriaDescribedBy\",\"onClose\"],[\"agent-status-warn-modal\",\"alertdialog\",true,\"agent-status-warn-modal-header\",\"agent-status-warn-modal-body\",[26,\"action\",[[21,0,[]],[22,[\"toggleProperty\"]],\"api.agent.showAgentStatusWarn\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"agent-status-warn-modal-header\"],[10,\"data-test-agent-status-warn-modal-header\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"modal.warn-title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"section\"],[10,\"id\",\"agent-status-warn-modal-body\"],[10,\"data-test-agent-status-warn-modal-body\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"agent-status-toggle.warning-set-fail\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"button\"],[10,\"data-test-agent-status-warn-modal-ok-btn\",\"\"],[3,\"action\",[[21,0,[]],[22,[\"toggleProperty\"]],\"api.agent.showAgentStatusWarn\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"modal.okay\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-agent-status-warning.hbs"
    }
  });
});