define("sdk/services/named-entity-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    intl: Ember.inject.service(),
    getNamedEntityKeyTranslation: function getNamedEntityKeyTranslation(key) {
      var translatedKey = this.get('intl').t(this.getNamedEntityTranslationKey(key)).toString();
      return translatedKey.includes('agent-coach.named-entities.') ? this.capitalize(this.removeUnderscores(key)) : translatedKey;
    },
    getNamedEntityTranslationKey: function getNamedEntityTranslationKey() {
      var namedEntityKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var noUnderscoreText = this.removeUnderscores(namedEntityKey);
      return 'agent-coach.named-entities.' + Ember.String.dasherize(noUnderscoreText);
    },
    /**
     * __person_customer__ => person customer
     * @method removeUnderscores
     * @param {String} text
     */
    removeUnderscores: function removeUnderscores() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return text.replace(/_/g, ' ').trim();
    },
    capitalize: function capitalize(text) {
      return text.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  });
});