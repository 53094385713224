define("sdk/sso/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "P/bEG2sg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nuance-home\",null,null,{\"statements\":[[0,\"\\n  \"],[1,[20,\"outlet\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/sso/template.hbs"
    }
  });
});