define("sdk/site/controller", ["exports", "sdk/utils/get-focusable-nodes", "sdk/utils/browser-type", "moment"], function (_exports, _getFocusableNodes, _browserType, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    // initialize services on site load
    engagementLayoutManager: Ember.inject.service(),
    cobrowse: Ember.inject.service(),
    notifications: Ember.inject.service(),
    devices: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    url: Ember.inject.service(),
    webToolsApi: Ember.inject.service(),
    hasPreferredQueue: Ember.computed.reads('api.agent.hasPreferredQueue'),
    showAutoTransferWarningModal: Ember.computed.gt('unrespondedEngagements.length', 0),
    isNinaCoachTrainingEnabled: Ember.computed.notEmpty('api.settings.ninaCoachTrainingTabs'),
    isErrorPillEnabled: Ember.computed.and('isDebugMode', 'errors.length'),
    isOnline: true,
    showDeviceSettings: Ember.computed.reads('devices.showDeviceSettings'),
    /**
     * The available locales an agent can choose.
     * @property {Array} languages
     */
    languages: Ember.computed('intl.locale', function () {
      return [{
        label: this.get('intl').t('agent-settings.english'),
        value: 'en_US',
        intlValue: 'en'
      }, {
        label: this.get('intl').t('agent-settings.spanish'),
        value: 'es_US',
        intlValue: 'es'
      }, {
        label: this.get('intl').t('agent-settings.german'),
        value: 'de_DE',
        intlValue: 'de'
      }, {
        label: this.get('intl').t('agent-settings.french'),
        value: 'fr_FR',
        intlValue: 'fr'
      }, {
        label: this.get('intl').t('agent-settings.portuguese'),
        value: 'pt_PT',
        intlValue: 'pt'
      }, {
        label: this.get('intl').t('agent-settings.japanese'),
        value: 'ja_JP',
        intlValue: 'ja'
      }, {
        label: this.get('intl').t('agent-settings.russian'),
        value: 'ru_RU',
        intlValue: 'ru'
      }, {
        label: this.get('intl').t('agent-settings.italian'),
        value: 'it_IT',
        intlValue: 'it'
      }];
    }),
    languageKeyMap: Object.freeze({
      en_US: 'en',
      es_US: 'es',
      de_DE: 'de',
      fr_FR: 'fr',
      pt_PT: 'pt',
      ja_JP: 'ja',
      ru_RU: 'ru',
      it_IT: 'it'
    }),
    /**
     * The available font sizes an agent can choose.
     * @property {Array} fontSizes
     */
    fontSizes: Ember.computed('intl.locale', function () {
      return [{
        label: this.get('intl').t('agent-settings.small'),
        value: 0
      }, {
        label: this.get('intl').t('agent-settings.medium'),
        value: 1
      }, {
        label: this.get('intl').t('agent-settings.large'),
        value: 2
      }];
    }),
    currentEngagement: Ember.computed('currentEngagementId', function () {
      var currentEngagementId = this.get('currentEngagementId');
      if (!currentEngagementId) return null;
      return this.get('api.agent.engagements').findBy('id', currentEngagementId);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('engagementsWithAutoTransferListeners', []);
      var _this$get$getQueryPar = this.get('url').getQueryParams(),
        debug = _this$get$getQueryPar.debug;
      this.set('isDebugMode', !!debug);
      this.set('isIE', _browserType.default.isIE);
      this.set('unrespondedEngagements', []);
      this.api.on('newEngagement', this, this.addAutoTransferTimeoutListeners);
      this.api.on('engagementClosed', this, this.removeAutoTransferTimeoutListeners);
      this.api.on('customSettingsLoaded', this, this._setInitialLocale);
      this.api.on('connectionLost', this, this._onConnectionLost);
      this.api.on('connectionRestored', this, this._onConnectionRestored);
      this.api.on('agentLockout', this, this._onAgentLockout);
      this.api.on('invalidateSession', this, this._onInvalidateSession);

      // manually triggering the _setInitialLocale below because in some environments, the event is triggered before it's registered
      // For future investigation, symptoms of the issue are...
      // In a bamboo deployed build, the above listener is not registered until after the customSettingsLoaded is triggered
      // However, in a local dev build, the above is registered before the trigger
      this._setInitialLocale();
    },
    willDestroy: function willDestroy() {
      var _this = this;
      this.api.off('newEngagement', this, this.addAutoTransferTimeoutListeners);
      this.api.off('engagementClosed', this, this.removeAutoTransferTimeoutListeners);
      this.api.off('customSettingsLoaded', this, this._setInitialLocale);
      this.api.off('connectionLost', this, this._onConnectionLost);
      this.api.off('connectionRestored', this, this._onConnectionRestored);
      this.api.off('agentLockout', this, this._onAgentLockout);
      this.api.off('invalidateSession', this, this._onInvalidateSession);
      this.get('engagementsWithAutoTransferListeners').forEach(function (engagement) {
        _this.removeAutoTransferTimeoutListeners(engagement);
      });
    },
    /**
     * When connection is lost, set "isOnline" to false
     * @method _onConnectionRestored
     */
    _onConnectionLost: function _onConnectionLost() {
      this.set('isOnline', false);
    },
    /**
     * When connection is restored, set "isOnline" to true
     * @method _onConnectionRestored
     */
    _onConnectionRestored: function _onConnectionRestored() {
      this.set('isOnline', true);
    },
    /**
     * When agent is locked out, transition to lockout route
     * @method _onAgentLockout
     */
    _onAgentLockout: function _onAgentLockout() {
      this.transitionToRoute('lockout');
    },
    /**
     * @method _onInvalidateSession
     * @param error
     */
    _onInvalidateSession: function _onInvalidateSession(error) {
      this._logoutAndInvalidate(error);
    },
    /**
     * Adds an event listener to each incoming engagement
     * for the auto transfer warn event and the auto transfer event.
     * @method addAutoTransferTimeoutListeners
     * @param {Object} engagement the incoming engagement
     */
    addAutoTransferTimeoutListeners: function addAutoTransferTimeoutListeners(engagement) {
      this.get('engagementsWithAutoTransferListeners').pushObject(engagement);
      engagement.on('autoTransferWarnTimeout', this, this._onEngagementWarnAutoTransfer);
      engagement.on('autoTransferTimeout', this, this._removeUnrespondedEngagement);
    },
    /**
     * Remove auto transfer timeout listeners
     * @param {Object} engagement
     * @method removeAutoTransferTimeoutListeners
     */
    removeAutoTransferTimeoutListeners: function removeAutoTransferTimeoutListeners(engagement) {
      this.get('engagementsWithAutoTransferListeners').removeObject(engagement);
      this._removeUnrespondedEngagement(engagement);
      engagement.off('autoTransferWarnTimeout', this, this._onEngagementWarnAutoTransfer);
      engagement.off('autoTransferTimeout', this, this._removeUnrespondedEngagement);
    },
    /**
     * When engagement displays auto transfer warning, add engagement to "unrespondedEngagements"
     * @param {Object} engagement
     * @method _onEngagementWarnAutoTransfer
     */
    _onEngagementWarnAutoTransfer: function _onEngagementWarnAutoTransfer(engagement) {
      this.get('unrespondedEngagements').addObject(engagement);
    },
    /**
     * When engagement is auto transfered, remove engagement from "unrespondedEngagements"
     * @param {Object} engagement
     * @method _removeUnrespondedEngagement
     */
    _removeUnrespondedEngagement: function _removeUnrespondedEngagement(engagement) {
      this.get('unrespondedEngagements').removeObject(engagement);
    },
    /**
     * Sets the locale upon logging in to the last-saved locale.
     * @method _setInitialLocale
     * @private
     */
    _setInitialLocale: function _setInitialLocale() {
      var initialLocale = this.get('api.agent.settings.language');
      var initialLanguage = this.get('languageKeyMap')[initialLocale];
      Ember.$('html').attr('lang', initialLanguage);
      this.set('intl.locale', initialLanguage);
    },
    actions: {
      closeDeviceSettings: function closeDeviceSettings() {
        this.get('devices').setShowDeviceSettings(false);
      },
      focusWebToolsIframe: function focusWebToolsIframe() {
        Ember.$('.web-tools-container .nuance-x-tabs .tab-content').focus();
      },
      focusActiveWebToolsTab: function focusActiveWebToolsTab() {
        Ember.$('.web-tools-container .nuance-x-tabs button.active').focus();
      },
      focusNodeBeforeWebToolFrames: function focusNodeBeforeWebToolFrames() {
        var $focusableNodes = (0, _getFocusableNodes.default)('body', true);
        var $node = Ember.$('#node-before-hidden-web-tool-frames');
        var nodeIndex = $focusableNodes.index($node);
        $focusableNodes.get(nodeIndex - 1).focus();
      },
      focusNodeAfterWebToolFrames: function focusNodeAfterWebToolFrames() {
        var $focusableNodes = (0, _getFocusableNodes.default)('body', true);
        var $node = Ember.$('#node-after-hidden-web-tool-frames');
        var nodeIndex = $focusableNodes.index($node);
        $focusableNodes.get(nodeIndex + 1).focus();
      },
      /**
       * Displays the settings modal and closes the settings menu.
       * @method openSettingsModal
       */
      openSettingsModal: function openSettingsModal() {
        this.set('showSettings', true);
        this.set('showMenu', false);
      },
      /**
      * Displays the device settings modal and closes the settings menu.
      * @method openDeviceSettingsModal
      */
      openDeviceSettingsModal: function openDeviceSettingsModal() {
        this.get('devices').setShowDeviceSettings(true);
        this.set('showMenu', false);
      },
      onUpdateLangAttribute: function onUpdateLangAttribute(lang) {
        Ember.$('html').attr('lang', lang);
      },
      /**
       * Takes the oldest engagement which has yet to be responded to
       * and prevents it from automatically transferring.
       * @method preventAutoTransfer
       * @public
       */
      preventAutoTransfer: function preventAutoTransfer() {
        var engagement = this.get('unrespondedEngagements').shiftObject();
        engagement.set('agentResponded', true);
        engagement.set('hasPreventedAutoTransfer', true);
        this.transitionToRoute('site.engagements.engagement', engagement.get('id'));
      },
      logout: function logout() {
        var _this2 = this;
        this.set('showMenu', false);
        this.get('api.agent').logout().then(function () {
          _this2.get('session').invalidate();
        }).catch(function () {
          var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var code = error.code;
          var errorMessage = code ? "alerts.".concat(code) : error;
          _this2.get('api').trigger('alert', errorMessage);
        });
      },
      focusMainRegion: function focusMainRegion() {
        Ember.$('[role="main"]').focus();
      },
      handleLoadIframe: function handleLoadIframe(automaton, frame) {
        var accessToSecureTranscript = automaton.accessToSecureTranscript,
          id = automaton.engagement.id;
        var webToolIframe = {
          engagementId: id,
          iframeElement: frame,
          hasSecureTranscriptAccess: accessToSecureTranscript
        };
        this.get('webToolsApi').addIframe(webToolIframe);
      }
    },
    /**
     * Logout and invalidate the active agent
     * An error object can be passed to be displayed under login form to
     * provide reason for invalidation
     * @method _logoutAndInvalidate
     * @param {Object} options reason for invalidation
     * @param {String} options.loginTranslationPath intl login-form translation path to display in error message on login page
     * @param {Boolean} options.auto true when agent is automatically logged off (auto-logout)
     */
    _logoutAndInvalidate: function _logoutAndInvalidate() {
      var _this3 = this;
      var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var loginTranslationPath = error.loginTranslationPath,
        auto = error.auto,
        status = error.status;
      if (status === 401) {
        error.title = this.get('intl').t('login-form.default-invalidation-error').toString();
      } else if (loginTranslationPath) {
        error.title = this.get('intl').t("login-form.".concat(loginTranslationPath)).toString();
      }
      error.timestamp = (0, _moment.default)().toString();
      error.agentId = this.get('api.agent.userId');
      error.url = error && error.url;
      sessionStorage.setItem('error_invalidate', JSON.stringify(error));
      var engagements = this.get('api.agent.engagements');
      engagements.forEach(function (engagement) {
        return _this3.api.trigger('removeEngagement', engagement);
      });
      return this.get('api.agent').logout({
        force: true,
        auto: auto
      }).finally(function () {
        return _this3.get('session').invalidate();
      });
    }
  });
});