define("sdk/site/follow-up/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QqisOv9e",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page--follow-up\"],[10,\"role\",\"tabpanel\"],[10,\"aria-labelledby\",\"tab-followup\"],[10,\"data-test-follow-up-list\",\"\"],[8],[0,\"\\n\\n  \"],[6,\"h2\"],[10,\"id\",\"follow-up-table-title\"],[10,\"class\",\"page__title\"],[10,\"data-test-table-title\",\"\"],[8],[0,\"\\n    \"],[1,[26,\"t\",[\"engagements-to-follow-up\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"page__body\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"follow-up__table-container\"],[10,\"data-test-follow-up-container\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"react-FollowUp\",null,[[\"handleChatSelection\",\"handleStartFollowUpEngagement\"],[[26,\"action\",[[21,0,[]],\"onFollowUpSelected\"],null],[26,\"action\",[[21,0,[]],\"startFollowUpEngagement\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"follow-up__footer\"],[8],[0,\"\\n      \"],[6,\"button\"],[11,\"class\",[27,[[26,\"if\",[[26,\"not\",[[22,[\"selectedFollowUpId\"]]],null],\"disabled\"],null]]]],[11,\"disabled\",[26,\"not\",[[22,[\"selectedFollowUpId\"]]],null],null],[10,\"data-test-load-engagement-button\",\"\"],[3,\"action\",[[21,0,[]],\"startFollowUpEngagement\"]],[8],[1,[26,\"t\",[\"load-engagement\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/site/follow-up/template.hbs"
    }
  });
});