define("sdk/components/rich-widget-preview-button/component", ["exports", "sdk/components/a11y-button/component", "sdk/components/rich-widget-preview-button/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'rich-widget-preview-button',
    attributeBindings: ['aria-label', 'role', 'tabindex'],
    'aria-label': Ember.computed('intl.locale', function () {
      return this.intl.t('rich-widget.preview-widget-navigation', {
        widgetLabel: this.get('richWidget.title')
      });
    }),
    'data-test-rich-widget-preview-button': ''
  });
});