define("sdk/components/sdk-scripts/script-section-toggles/component", ["exports", "sdk/core/component", "sdk/components/sdk-scripts/script-section-toggles/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'script-section-toggles',
    showExpandAllButton: Ember.computed('collapsedSections.[]', 'sections.[]', function () {
      var scriptSectionsCollapsed = this.get('collapsedSections');
      var sections = this.get('sections');
      if (!scriptSectionsCollapsed || !sections) return false;
      var anyScriptSectionsCollapsed = scriptSectionsCollapsed.length > 0;
      var anySectionsHaveHeaders = this._includesSectionWithHeader(sections);
      return anyScriptSectionsCollapsed && anySectionsHaveHeaders;
    }),
    showCollapseAllButton: Ember.computed('collapsedSections.[]', 'sections.[]', function () {
      var scriptSectionsCollapsed = this.get('collapsedSections');
      var sections = this.get('sections');
      if (!scriptSectionsCollapsed || !sections) return false;
      var sectionsWithHeader = this.get('sections').filter(function (section) {
        return section.name;
      });
      var anyScriptSectionsExpanded = scriptSectionsCollapsed.length < sectionsWithHeader.length;
      var anySectionsHaveHeaders = this._includesSectionWithHeader(sections);
      return anyScriptSectionsExpanded && anySectionsHaveHeaders;
    }),
    actions: {
      expandAllSections: function expandAllSections() {
        var _this = this;
        this.get('collapsedSections') && this.get('collapsedSections').clear();
        Ember.run.schedule('afterRender', function () {
          _this.element.querySelector('button').focus();
        });
      },
      collapseAllSections: function collapseAllSections() {
        var _this2 = this;
        var scriptSectionsCollapsed = this.get('collapsedSections');
        this.get('sections').forEach(function (section) {
          if (scriptSectionsCollapsed.includes(section.id) || !section.name) return;
          scriptSectionsCollapsed.pushObject(section.id);
        });
        Ember.run.schedule('afterRender', function () {
          _this2.element.querySelector('button').focus();
        });
      }
    },
    _includesSectionWithHeader: function _includesSectionWithHeader(sections) {
      return !Ember.isEmpty(sections.filter(function (section) {
        return section.name;
      }));
    }
  });
});