define("sdk/api/utils/rich-widgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMessageDataFromRichWidget = getMessageDataFromRichWidget;
  _exports.getWidgetImagePath = getWidgetImagePath;
  function getMessageDataFromRichWidget(richWidget) {
    return {
      richWidget: richWidget.richContent
    };
  }
  function getWidgetImagePath(widget) {
    var _widget$widgetThumbna = widget.widgetThumbnail,
      widgetThumbnail = _widget$widgetThumbna === void 0 ? '' : _widget$widgetThumbna,
      _widget$widgetType = widget.widgetType,
      widgetType = _widget$widgetType === void 0 ? '' : _widget$widgetType;
    if (widgetThumbnail) {
      return widgetThumbnail.match(/data:image.*base64/) ? widgetThumbnail : "assets/images/widgets/".concat(widgetThumbnail.toLowerCase(), ".png");
    }
    return "assets/images/widgets/".concat(widgetType.toLowerCase(), ".png");
  }
});