define("sdk/api/adapters/async-adapter", ["exports", "sdk/api/adapters/api-adapter", "sdk/api/utils/transcript-serializer"], function (_exports, _apiAdapter, _transcriptSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class AsyncAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    basicAuth: true,
    name: 'ASYNC',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.ASYNC_TRANSCRIPT_API;
    },
    /**
     * Retrieves a list of engagements from the API
     * filtered by conversation id, excluding current engagementID
     * @method getAsyncConversation
     * @public
     * @param {String} conversationID value is applied to api search filter
     * @param {String} siteID the site ID
     * @param {String} engagementID the engagement ID
     */
    getAsyncConversation: function getAsyncConversation(conversationID, siteID, engagementID) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var isTranscriptV3 = options.isTranscriptV3;
      var filter = "(conversationID=".concat(conversationID, ")");
      if (engagementID) {
        filter = "(engagementID!=".concat(engagementID, " AND ").concat(filter, ")");
      }
      return this.ajax({
        url: 'conversation',
        data: {
          site: siteID,
          filter: filter,
          returnFields: 'engagementID,transcript,startDate',
          includeActive: '1'
        }
      }).then(function (response) {
        return {
          dispositions: Ember.get(response, 'engagements.firstObject.dispositions') || [],
          messages: (0, _transcriptSerializer.normalizeMessages)(response, {
            isTranscriptV3: isTranscriptV3,
            source: 'conversation'
          })
        };
      });
    }
  });
});