define("sdk/utils/promisify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = promisify;
  function promisify(fn) {
    try {
      var result = fn();
      return Ember.RSVP.resolve(result);
    } catch (err) {
      return Ember.RSVP.reject(err);
    }
  }
});