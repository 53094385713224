define("sdk/components/sdk-login-form/component", ["exports", "sdk/components/sdk-login-form/template", "sdk/utils/promisify", "sdk/core/component"], function (_exports, _template, _promisify, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Components.LoginForm
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    componentName: 'login-form',
    layout: _template.default,
    session: Ember.inject.service(),
    didReceiveAttrs: function didReceiveAttrs() {
      (false && !(typeof this.get('authenticate') === 'function') && Ember.assert('LoginForm component must be passed an `authenticate` action.', typeof this.get('authenticate') === 'function'));
      this.setProperties({
        username: this.username || this.get('api.agent.username'),
        password: this.password || this.get('api.agent.password')
      });
      var agentWasLoggedOut = sessionStorage.getItem('agent_kicked_off');
      if (agentWasLoggedOut) {
        return this.set('errorMessage', this.get('intl').t('login-form.agent-logged-off'));
      }

      // Agent was logged off due to losing internet connection
      var internetRestored = sessionStorage.getItem('internet_restored');
      if (internetRestored) {
        return this.set('errorMessage', this.get('intl').t('login-form.internet-restored'));
      }
      var error = sessionStorage.getItem('error_invalidate');
      if (!error) return;
      try {
        error = JSON.parse(error);
      } catch (e) {
        error = {
          detail: error
        };
      }
      var _error = error,
        errorDescription = _error.detail;
      if (error.status) {
        errorDescription = "Network Error ".concat(error.status, " - ").concat(error.detail);
        this.setProperties({
          errorTimestamp: error.timestamp,
          errorAgentId: error.agentId,
          errorUrl: error.url
        });
      }
      this.set('errorMessage', error.title || this.get('intl').t('login-form.application-error-generic'));
      this.set('errorDescription', errorDescription);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('errorDescription')) {
        this._focusNode('.details-link');
      } else if (this.get('passwordOnly')) {
        this._focusNode('#password');
      } else {
        this._focusNode('#username');
      }
    },
    /**
     * Username of the agent
     *
     * @property username
     * @type string
     * @public
     */
    username: null,
    /**
     * Password for the agent
     *
     * @property password
     * @type string
     * @public
     */
    password: null,
    isPasswordExpired: false,
    errorMessage: null,
    /**
     * Number of failed login attempts
     * @property {Number} invalidAttempts
     */
    invalidAttempts: 0,
    intl: Ember.inject.service(),
    /**
     * Determines when to display the login timeout modal.
     * Set here for testing purposes.
     * @property {Number} LOGIN_TIMEOUT_IN_MS
     * @public
     */
    LOGIN_TIMEOUT_IN_MS: 60000,
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('timeout'));
    },
    /**
     * @event invalid
     * @public
     */

    actions: {
      submit: function submit() {
        var _this = this;
        this.set('errorMessage', null);
        var username = (this.get('username') || '').trim();
        var password = (this.get('password') || '').trim();
        var authenticate = this.get('authenticate');
        var onInvalid = this.get('invalid');
        if (!username) {
          // a11y - next() called so screen reader will reread text live region
          Ember.run.next(function () {
            _this.set('errorMessage', _this.get('intl').t('login-form.missing-username'));
            onInvalid && onInvalid({
              type: 'missing',
              field: 'username'
            });
            _this._focusNode('#username');
          });
          return;
        } else if (!password) {
          Ember.run.next(function () {
            _this.set('errorMessage', _this.get('intl').t('login-form.missing-password'));
            onInvalid && onInvalid({
              type: 'missing',
              field: 'password'
            });
            _this._focusNode('#password');
          });
          return;
        }
        this.set('isLoggingIn', true);
        var timeoutDelay = this.get('LOGIN_TIMEOUT_IN_MS');
        this.set('timeout', setTimeout(function () {
          if (!_this.get('isLoggingIn') || _this.get('isDestroyed')) return;
          _this.set('showLoginTimeoutModal', true);
        }, timeoutDelay));
        return this.get('api.router').checkOnlineStatus().then(function (isOnline) {
          if (!isOnline) {
            _this.set('isLoggingIn', false);
            _this._focusNode('#username');
            return _this.set('errorMessage', _this.get('intl').t('login-form.no-internet'));
          }
          return (0, _promisify.default)(authenticate.bind(null, username, password)).then(function (response) {
            if (response && response.hasActiveSession) {
              _this.set('alreadyLoggedIn', true);
              _this.set('isLoggingIn', false);
            } else {
              // need to reset the modal in case the agent comes back to the login screen
              _this.set('alreadyLoggedIn', false);
              sessionStorage.removeItem('agent_kicked_off');
              sessionStorage.removeItem('internet_restored');
            }
          }).catch(function (originalError) {
            if (_this.get('isDestroyed') || _this.get('isDestroying')) return;
            var error = originalError;
            var errorTitle = Ember.get(error, 'payload.error');
            var errorMessage = Ember.get(error, 'payload.error_description') || Ember.get(error, 'message') || Ember.get(error, 'payload.detail');
            var shouldHandleOriginalError = errorTitle === 'access_denied' || errorMessage === 'Application error';
            if (!shouldHandleOriginalError) {
              error = new Error(errorMessage);
            }
            _this.set('isLoggingIn', false);
            var _error2 = error,
              message = _error2.message,
              service = _error2.service;
            var isAccessDenied = Ember.get(error, 'payload.error') === 'access_denied';
            var isAccessDeniedForIp = (Ember.get(error, 'payload.error_description') || '').includes('Access denied for remote ip');
            var errorType = Ember.String.dasherize(message);
            var shouldHaveAuthErrorDescription = errorType === 'ajax-authorization-failed' && !isAccessDeniedForIp;
            if (errorType === 'application-error') {
              _this.set('errorDescription', service + ' ' + _this.get('intl').t('login-form.service-unavailable'));
            } else if (shouldHaveAuthErrorDescription) {
              _this.set('errorDescription', _this.get('intl').t('login-form.ajax-authorization-failed'));
            } else if (errorType === 'bad-credentials') {
              var lastUsername = _this.get('_lastUsername');
              if (lastUsername !== username) {
                // last used username
                _this.set('_lastUsername', username);
                _this.set('invalidAttempts', 0);
              }
              _this.incrementProperty('invalidAttempts');
            } else {
              _this.set('errorDescription', null);
            }
            if (errorType === 'bad-credentials') {
              var isAccountLocked = _this.get('invalidAttempts') >= 5;
              _this.set('errorMessage', _this.get('intl').t('login-form.' + (isAccountLocked ? 'account-maybe-locked' : 'bad-credentials')));
            } else if (['user-account-is-locked', 'application-error'].includes(errorType)) {
              _this.set('errorMessage', _this.get('intl').t('login-form.' + errorType));
            } else if (isAccessDeniedForIp) {
              _this.set('errorMessage', errorMessage);
            } else if (shouldHaveAuthErrorDescription || errorType === 'internal-server-error') {
              _this.set('errorMessage', _this.get('intl').t('login-form.application-error'));
            } else if (isAccessDenied) {
              _this.set('errorMessage', _this.get('intl').t('login-form.access-denied'));
            } else if (errorType === 'user-is-disabled') {
              _this.set('invalidAttempts', 0);
              _this.set('errorMessage', message);
            } else {
              _this.set('errorMessage', message);
            }
          }).finally(function () {
            if (_this.get('isDestroyed') || _this.get('isDestroying')) return;
            _this.set('showLoginTimeoutModal', false);
            if (!_this.get('errorMessage')) return;
            if (_this.get('errorDescription')) {
              Ember.run.next(_this, _this._focusNode.bind(_this, '.details-link'));
            } else {
              _this._focusNode('#username');
            }
          });
        });
      }
    },
    _focusNode: function _focusNode(selector) {
      this.$(selector).focus();
    }
  });
});