define("sdk/components/transfer-engagement/component", ["exports", "sdk/core/component", "sdk/api/utils/runloop", "sdk/components/transfer-engagement/template", "sdk/utils/array", "sdk/utils/speak"], function (_exports, _component, _runloop, _template, _array, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Can transfer an engagement or create a conference engagement.
   *
   * ## Inline Usage
   * Inline usage simply expects an engagement:
   *  ```hbs
   *  \{{transfer-engagement engagement=engagement}}
   *   ```
   *
   * ## Block Usage
   * Block usage exposes the following API (using ember-power-select to create dropdowns):
   * ```hbs
   * \{{#transfer-engagement engagement=engagement as |api|}}
      {{#power-select
        options=api.businessUnits
        selected=api.selectedBusinessUnit
        onchange=(action api.onSelectBusinessUnit)
        as |BUnit|
      }}
        {{BUnit}}
      {{/power-select}}
  
      {{#power-select
        options=api.agentGroups
        selected=api.selectedAgentGroup
        onchange=(action api.onSelectAgentGroup)
        as |AGroup|
      }}
        {{AGroup}}
      {{/power-select}}
  
      <p>Available slots: {{api.availableSlots}}</p>
      <p>Transfer slots: {{api.transferSlots}}</p>
      <p>Conference slots: {{api.conferenceSlots}}</p>
  
      {{#each api.agentAttributes as |attribute index|}}
        <label data-test-attr-label={{attribute.name}}>{{attribute.name}}</label>
        {{#if attribute.hasAgent}}
          {{#power-select
            options=attribute.values
            selected=(get api.selectedAttributes attribute.name)
            onchange=(action api.onSelectAgentAttribute attribute.name)
            allowClear=true
            as |value|
          }}
            {{value}}
          {{/power-select}}
        {{else}}
          <label>No matching agents found</label>
        {{/if}}
      {{/each}}
  
      <button {{action api.reset}}>Reset</button>
  
      {{#power-select
        options=api.availableAgents
        selected=api.selectedAgent
        onchange=(action api.onSelectAgent)
        as |Agent|
      }}
        {{Agent}}
      {{/power-select}}
  
      {{textarea value=api.agentNotes}}
  
      {{input type="checkbox" checked=api.prioritizeRequest}}
  
      <button {{action api.transfer}}>Transfer</button>
      <button {{action api.conference}}>Conference</button>
   * \{{/transfer-engagement}}
   * ```
   * @class Components.TransferEngagement
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'transfer-engagement',
    businessUnits: Ember.computed.alias('transferOptions.businessUnits'),
    selectedBusinessUnit: Ember.computed.alias('transferOptions.selectedBusinessUnit'),
    agentGroups: Ember.computed.alias('transferOptions.agentGroups'),
    hasAgentGroups: Ember.computed.alias('transferOptions.hasAgentGroups'),
    selectedAgentGroup: Ember.computed.alias('transferOptions.selectedAgentGroup'),
    availableAgents: Ember.computed.alias('transferOptions.availableAgents'),
    availableAgentGroups: Ember.computed.alias('transferOptions.availableAgentGroups'),
    selectedAgent: Ember.computed.alias('transferOptions.selectedAgent'),
    agentAttributes: Ember.computed.alias('transferOptions.agentAttributes'),
    selectedAttributes: Ember.computed.alias('transferOptions.selectedAttributes'),
    selectedAttributesMap: Ember.computed.alias('transferOptions.selectedAttributesMap'),
    agentAttrs: Ember.computed.alias('transferOptions.agentAttrs'),
    availableSlots: Ember.computed.alias('transferOptions.availableSlots'),
    transferSlots: Ember.computed.alias('transferOptions.transferSlots'),
    conferenceSlots: Ember.computed.alias('transferOptions.conferenceSlots'),
    agentNotes: Ember.computed.alias('transferOptions.agentNotes'),
    prioritizeRequest: Ember.computed.alias('transferOptions.prioritizeRequest'),
    agentAttributesAvailable: Ember.computed.gt('transferAgentAttributes.length', 0),
    isLocked: Ember.computed.alias('engagement.isLocked'),
    sortedAgentGroupNames: Ember.computed.alias('transferOptions.sortedAgentGroupNames'),
    intl: Ember.inject.service(),
    availableSlotsObserver: Ember.observer('availableSlots', function () {
      this.set('availableSlotsDebounceId', (0, _runloop.debounce)(this, this._speakAvailableSlots, 250));
    }),
    prioritizeRequestLabel: Ember.computed('intl.locale', function () {
      return this.get('intl').t('transfer-engagement.prioritize-request').toString();
    }),
    transferSlotsObserver: Ember.observer('transferSlots', function () {
      this.set('transferSlotsDebounceId', (0, _runloop.debounce)(this, this._speakTransferSlots, 250));
    }),
    conferenceSlotsObserver: Ember.observer('conferenceSlots', function () {
      this.set('conferenceSlotsDebounceId', (0, _runloop.debounce)(this, this._speakConferenceSlots, 250));
    }),
    _speakAvailableSlots: function _speakAvailableSlots() {
      (0, _speak.default)("".concat(this.get('availableSlots'), " ").concat(this.get('intl').t('transfer-engagement.available-slots')));
    },
    _speakTransferSlots: function _speakTransferSlots() {
      (0, _speak.default)("".concat(this.get('transferSlots'), " ").concat(this.get('intl').t('transfer-engagement.transfer-queue-slots')));
    },
    _speakConferenceSlots: function _speakConferenceSlots() {
      (0, _speak.default)("".concat(this.get('conferenceSlots'), " ").concat(this.get('intl').t('transfer-engagement.conference-queue-slots')));
    },
    shouldDisableTransferButton: Ember.computed('isLocked', 'selectedAgentGroup', 'selectedBusinessUnit', 'hasAgentGroups', 'selectedAgent', 'selectedAttributes.[]', function () {
      return !this._transferOrConferenceAvailable('transfer');
    }),
    shouldDisableConferenceButton: Ember.computed('isLocked', 'selectedAgentGroup', 'selectedBusinessUnit', 'hasAgentGroups', 'selectedAgent', 'selectedAttributes.[]', function () {
      return !this._transferOrConferenceAvailable('conference');
    }),
    canSpecifyTargetAgent: Ember.computed.not('transferOptions.disableAgentSelection'),
    canPrioritizeRequest: Ember.computed('selectedAgentGroup', 'selectedBusinessUnit', function () {
      var selectedBusinessUnit = this.get('selectedBusinessUnit');
      if (selectedBusinessUnit) {
        var selectedBusinessUnitData = this.get('transferOptions').getBusinessUnitDataByName(selectedBusinessUnit);
        if (selectedBusinessUnitData.agentGroups) {
          var selectedAgentGroupName = this.get('selectedAgentGroup.name');
          if (selectedAgentGroupName) {
            var selectedAgentGroupData = this.get('transferOptions').getAgentGroupDataByName(selectedAgentGroupName, selectedBusinessUnit);
            return selectedAgentGroupData.canBePrioritize;
          }
        }
        return selectedBusinessUnitData.canBePrioritize;
      }
      return false;
    }),
    isAsyncChat: Ember.computed.alias('engagement.settings.asyncChat'),
    transferAgentAttributes: Ember.computed('agentAttributes', function () {
      var agentAttributes = this.get('agentAttributes');
      return agentAttributes && agentAttributes.filter(function (agentAttribute) {
        return !['isAvailableForVideo', 'isAvailableForVoice'].includes(agentAttribute.name);
      });
    }),
    /**
     * Sets the transfer options model.
     * @method didReceiveAttrs
     * @private
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this.get('engagement').getTransferOptions().then(function (transferOptions) {
        _this.set('transferOptions', transferOptions);
        var businessUnits = (0, _array.sortAlphabetically)(_this.get('businessUnits'));
        if (businessUnits && businessUnits.length && (businessUnits.length === 1 || _this.get('engagement.isConferenceMode'))) {
          var selectedBusinessUnitName = businessUnits.get('firstObject');
          _this.send('selectBusinessUnit', selectedBusinessUnitName);
          var selectedBusinessUnitData = _this.get('transferOptions').getBusinessUnitDataByName(selectedBusinessUnitName);
          var agentGroups = selectedBusinessUnitData.agentGroups;
          if (agentGroups) {
            _this.send('selectAgentGroup', agentGroups.get('firstObject'));
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.get('availableSlotsDebounceId'));
      Ember.run.cancel(this.get('transferSlotsDebounceId'));
      Ember.run.cancel(this.get('conferenceSlotsDebounceId'));
    },
    actions: {
      /**
       * Resets all form elements and selections made on the component.
       * Called when the user clicks the reset button.
       * @method reset
       * @public
       */
      reset: function reset() {
        this.get('transferOptions').reset();
        (0, _speak.default)("".concat(this.get('intl').t('transfer-engagement.field-reset')));
      },
      togglePrioritizeRequest: function togglePrioritizeRequest(event, checked) {
        this.set('prioritizeRequest', checked);
      },
      /**
       * The rest of the agent attributes will be filtered out
       * if need be and the slots will also be updated.
       * Called when the user selects an agent attribute.
       * @method onSelectAgentAttribute
       * @public
       * @param {String} name the name of the selected agent attribute
       * @param {String} displayValue the display value of the selected agent attribute (null if cleared)
       */
      selectAgentAttribute: function selectAgentAttribute(name, displayValue) {
        this._clearErrorMessage();
        this.get('transferOptions').selectAgentAttribute(name, displayValue);
      },
      /**
       * Sets the selected business unit and populates
       * the agentGroups dropdown.
       * Called when the user selects a business unit.
       * @method onSelectBusinessUnit
       * @public
       * @param {String} bUnitName the selected business unit name
       */
      selectBusinessUnit: function selectBusinessUnit(bUnitName) {
        this._clearErrorMessage();
        this.get('transferOptions').selectBusinessUnit(bUnitName);
      },
      /**
       * Sets the selected agent group, updates the number
       * of slots, and displays the agent attribute dropdowns.
       * Called when the user selects an agent group.
       * @method onSelectAgentGroup
       * @public
       * @param {String} agentGroup the selected agent group
       */
      selectAgentGroup: function selectAgentGroup(agentGroup) {
        this._clearErrorMessage();
        this.get('transferOptions').selectAgentGroup(agentGroup.name);
      },
      /**
       * Sets the selected agent name and updates the number
       * of slots. Called when the user selects an agent.
       * @method onSelectAgent
       * @public
       * @param {String} agent the selected agent
       */
      selectAgent: function selectAgent(agent) {
        this._clearErrorMessage();
        this.get('transferOptions').selectAgent(agent);
      },
      /**
       * Makes a request to the API to transfer the engagement.
       * Called when the user clicks the transfer button.
       * @method transfer
       * @public
       * @return {Promise} the return of the call to router.transferEngagement
       */
      transfer: function transfer() {
        var _this2 = this;
        this._setAgentNotes(this.get('stagedAgentNotes'));
        return this.get('engagement').transfer().catch(function (error) {
          var _ref = error.payload || {},
            _ref$detail = _ref.detail,
            detail = _ref$detail === void 0 ? '' : _ref$detail,
            status = _ref.status;
          if (status === 422) {
            error.ignore = true;
            _this2._setErrorMessage(detail);
          }
          throw error;
        });
      },
      /**
       * Makes a request to the API to transfer the engagement.
       * Called when the user clicks the conference button.
       * @method conference
       * @public
       * @return {Promise} the return of the call to router.conferenceEngagement
       */
      conference: function conference() {
        var _this3 = this;
        this._setAgentNotes(this.get('stagedAgentNotes'));
        return this.get('engagement').conference().catch(function (error) {
          var _ref2 = error.payload || {},
            _ref2$detail = _ref2.detail,
            detail = _ref2$detail === void 0 ? '' : _ref2$detail,
            status = _ref2.status;
          if (status === 422) {
            error.ignore = true;
            _this3._setErrorMessage(detail);
          }
          throw error;
        });
      }
    },
    /**
     * Rewords backend error message and displays an error message (removes "failed:")
     * @method _setErrorMessage
     * @param {String} message
     */
    _setErrorMessage: function _setErrorMessage() {
      var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var errorMessage = message.replace('failed:', '').trim();
      this.set('errorMessage', errorMessage);
    },
    /**
     * clear error message whenever a transfer option is modified
     * @method _clearErrorMessage
     */
    _clearErrorMessage: function _clearErrorMessage() {
      this.set('errorMessage', '');
    },
    /**
     * Determines whether or not transfer or conference is currently available
     * based off the current selections made. Used to determine if the transfer
     * or conference buttons should be disabled.
     * @method _transferOrConferenceAvailable
     * @param {String} action transfer or conference
     * @returns {Boolean} whether or not the given action is currently available
     * @private
     */
    _transferOrConferenceAvailable: function _transferOrConferenceAvailable(action) {
      var _this$get$getProperti = this.get('engagement').getProperties('isAsync', 'isLocked', 'isConferenceMode', 'isOtherAgentConnected', 'customerCallConnected'),
        isAsync = _this$get$getProperti.isAsync,
        isLocked = _this$get$getProperti.isLocked,
        isConferenceMode = _this$get$getProperti.isConferenceMode,
        isOtherAgentConnected = _this$get$getProperti.isOtherAgentConnected,
        customerCallConnected = _this$get$getProperti.customerCallConnected;
      if (action === 'conference' && customerCallConnected) return false;
      if (isLocked) return false;
      var isEngagementInConferenceMode = isConferenceMode && isOtherAgentConnected;
      if (isEngagementInConferenceMode) return this._transferOrConferenceAvailableInConferenceMode(action);
      var propertyToCheck = action === 'transfer' ? 'isAvailableForTransfer' : 'isAvailableForConference';
      var selectedAgentGroupName = this.get('selectedAgentGroup.name');
      var selectedBusinessUnit = this.get('selectedBusinessUnit');
      var selectedAgentName = this.get('selectedAgent.name');
      var availableSlots = this.get('availableSlots');
      var hasAgentGroups = this.get('hasAgentGroups');
      var hasFreeSlots = availableSlots || this.get(action + 'Slots') > 0;
      if (hasAgentGroups && !selectedAgentGroupName) return false;
      if (selectedAgentName) {
        var selectedAgentId = this.get('transferOptions').getAgentByNameOrId(selectedAgentName).id;
        var selectedAgentData = this.get('transferOptions').getAgentDataById(selectedAgentId);
        return selectedAgentData[propertyToCheck] && hasFreeSlots;
      }
      if (selectedBusinessUnit) {
        var selectedBusinessUnitData = this.get('transferOptions').getBusinessUnitDataByName(selectedBusinessUnit);
        var isActionTransfer = action === 'transfer';
        if (selectedBusinessUnitData.agentGroups) {
          if (selectedAgentGroupName) {
            var selectedAgentGroupData = this.get('transferOptions').getAgentGroupDataByName(selectedAgentGroupName, selectedBusinessUnit);
            var hasNoAvailableAgentsWithSelectedAttributes = this.get('selectedAttributes.length') && this.get('availableAgents.length') === 0;
            if (hasNoAvailableAgentsWithSelectedAttributes) return false;
            if (isAsync && isActionTransfer) return selectedAgentGroupData[propertyToCheck];
            return selectedAgentGroupData[propertyToCheck] && hasFreeSlots;
          }
        } else {
          return selectedBusinessUnitData[propertyToCheck] && hasFreeSlots;
        }
      }
      return false;
    },
    /**
     * Determines whether or not transfer or conference is currently available
     * based off the current selections made. Used to determine if the transfer
     * or conference buttons should be disabled while in conference mode.
     * @method _transferOrConferenceAvailableInConferenceMode
     * @param {String} action transfer or conference
     * @returns {Boolean} whether or not the given action is currently available
     * @private
     */
    _transferOrConferenceAvailableInConferenceMode: function _transferOrConferenceAvailableInConferenceMode(action) {
      if (!this.get('transferOptions')) return;

      // disable conference button for Nina Coach engagements
      if (this.get('engagement.isNinaCoach')) return action === 'transfer';
      var selectedAgentName = this.get('selectedAgent.name');
      var conferencedAgent = this.get('engagement.conferenceAgentId');
      var connectedAgentId = this.get('engagement.connectedAgentId');
      var connectedAgentIsScreening = this.get('engagement.connectedAgentIsScreening');
      var isActionTransfer = action === 'transfer';
      var isActionConference = action === 'conference';
      if (selectedAgentName) {
        var selectedAgentId = this.get('transferOptions').getAgentByNameOrId(selectedAgentName).id;
        if (selectedAgentId === connectedAgentId && connectedAgentIsScreening) {
          return false;
        }
        if (selectedAgentId === conferencedAgent) {
          return isActionTransfer;
        }
        var selectedAgentData = this.get('transferOptions').getAgentDataById(selectedAgentId);
        var isEnabledConferenceButton = isActionConference && selectedAgentData['isAvailableForConference'];
        return selectedAgentId === conferencedAgent ? isActionTransfer : isEnabledConferenceButton;
      }
      var agents = this.get('transferOptions.agents');
      if (!agents || !agents.length) return false;
      var shouldDisableBothButtons = agents.length === 1 && agents[0].id === conferencedAgent;
      if (shouldDisableBothButtons) return false;
      var hasAvailableAgent = agents.any(function (agent) {
        return agent['isAvailableForConference'] && agent.id !== connectedAgentId;
      });

      // only conference if other agents are available and no agent has been selected
      return isActionConference && hasAvailableAgent;
    },
    _setAgentNotes: function _setAgentNotes(stagedAgentNotes) {
      this.set('agentNotes', stagedAgentNotes);
    }
  });
});