define("sdk/components/nina-coach/concepts-select/component", ["exports", "sdk/core/component", "sdk/components/nina-coach/concepts-select/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nina-coach-concepts-select',
    'data-test-nina-coach-concepts-select': '',
    classNameBindings: ['hasAvailableConcepts::no-available-concepts'],
    intentText: Ember.computed.alias('intent.userQuestion'),
    responseText: Ember.computed.alias('intent.responseText'),
    availableConcepts: Ember.computed.alias('intent.availableConcepts'),
    hasAvailableConcepts: Ember.computed.alias('intent.hasAvailableConcepts'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // deselect all concepts when intent is updated
      if (this.get('intent')) {
        this.get('intent').deselectAllConcepts();
      }
    },
    actions: {
      ninaSuggested: function ninaSuggested() {
        var onNinaSuggested = this.get('onNinaSuggested');
        onNinaSuggested && onNinaSuggested();
      }
    }
  });
});