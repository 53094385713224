define("sdk/utils/is-element-visible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isElementInViewport = isElementInViewport;
  _exports.isElementVisible = isElementVisible;
  function isElementVisible(el) {
    var rect = el.getBoundingClientRect();
    var vWidth = window.innerWidth || document.documentElement.clientWidth;
    var vHeight = window.innerHeight || document.documentElement.clientHeight;
    var efp = function efp(x, y) {
      return document.elementFromPoint(x, y);
    };
    var notInViewport = rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight;
    if (notInViewport) return false;

    // Return true if any of its four corners are visible
    return el.contains(efp(rect.left, rect.top)) || el.contains(efp(rect.right, rect.top)) || el.contains(efp(rect.right, rect.bottom)) || el.contains(efp(rect.left, rect.bottom));
  }
  function isElementInViewport(el) {
    var boundingClientRect = el.getBoundingClientRect();
    return boundingClientRect.top >= 0 && boundingClientRect.left >= 0 && boundingClientRect.bottom <= window.innerHeight && boundingClientRect.right <= window.innerWidth;
  }
});