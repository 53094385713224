define("sdk/api/storage", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      var _this = this;
      this.restore();
      this.persist();
      ['newEngagement', 'dispositionsLoaded', 'newMessages', 'agentMessage', 'agentMaskedText', 'agentMessageInternal', 'dispositionsUpdated', 'ninaCoachIntentsLoaded', 'dispositionsClosed', 'dispositionsOpened', 'updateRegisteredId', 'internalClosed', 'interruptedScript'].forEach(function (eventName) {
        _this.api.on(eventName, _this, _this.persistEngagement);
      });
      this.api.on('agentRelogin', function (data) {
        Ember.assign(_this.store, data);
        _this.persist();
      });
      this.api.on('authenticated', function (data) {
        Ember.assign(_this.store, data);
        _this.persist();
      });
      this.api.on('engagementActivate', function (engagement) {
        _this.store.activeEngagementId = engagement.id;
        _this.persist();
      });
      this.api.on('engagementClosed', function (engagement) {
        _this.clearEngagement(engagement.id);
      });
      this.api.on('removeEngagement', function (engagement) {
        _this.clearEngagement(engagement.id);
      });
      this.api.on('failedToJoinEngagement', this, this.clearEngagement);
    },
    key: Ember.computed(function () {
      var key = 'nuance-ai';
      if (window.runningTests) {
        key += '-test';
      }
      return key;
    }),
    persistEngagement: function persistEngagement(engagement) {
      if (engagement.get('manuallyLoaded')) return;
      var data = engagement.toJSON();
      data.registeredId = this.store.registeredId;
      this.get('store.engagements')[engagement.id] = data;
      this.persist();
    },
    persistActivePerformanceMetrics: function persistActivePerformanceMetrics(performanceMetrics) {
      var store = this.get('store');
      store.activePerformanceMetricsTimestamp = this.get('api.currentTime'), store.activePerformanceMetrics = performanceMetrics;
      this.persist();
    },
    /**
     * Persists current store into sessionStorage
     * @method persist
     */
    persist: function persist() {
      var key = this.get('key');
      try {
        sessionStorage.setItem(key, JSON.stringify(this.store));
      } catch (error) {
        //send the log only once as it can be repetitive if the error is related to `Quota Exceeded`
        if (!this.get('sentErrorLog')) {
          this.set('sentErrorLog', true);
          this.get('api.logging').sendLog('error', _objectSpread({
            event_group: 'application',
            event: 'storage-persist-error'
          }, this.prepareErrorLog(error)));
        }
      }
    },
    restore: function restore() {
      var key = this.get('key');
      var store = sessionStorage.getItem(key);
      this.store = store ? JSON.parse(store) : {};
      if (!this.store.agent) this.store.agent = {};
      if (!this.store.engagements) this.store.engagements = {};
    },
    clearEngagement: function clearEngagement(engagementId) {
      delete this.store.engagements[engagementId];
      this.persist();
    },
    /**
     * Clear sessionStorage keys
     * @method clear
     */
    clear: function clear() {
      var _this2 = this;
      var storageKeysToRemove = ['activePerformanceMetrics', 'activePerformanceMetricsTimestamp', 'agent', 'metrics', 'previousRoute', 'currentRoute', 'apiErrorIncrements'];
      storageKeysToRemove.forEach(function (storeProperty) {
        delete _this2.store[storeProperty];
      });
      this.persist();
      this.api.stateManager.resetStorage();
    },
    /**
     * This prepares the error log related to the storage
     * @method prepareErrorLog
     */
    prepareErrorLog: function prepareErrorLog(error) {
      var logObj = {
        persist_error_msg: error.message
      };
      try {
        logObj['storage_size_mb'] = this.getSizeInMB(sessionStorage);
        logObj['storage_info'] = JSON.stringify(this.getStorageInfo(sessionStorage));
      } catch (err) {
        (function () {});
      }
      return logObj;
    },
    /**
     * Get the size of the value passed in MB
     * @method getSizeInMB
     * @param {object} value the object to find the size of
     */
    getSizeInMB: function getSizeInMB(value) {
      var size = unescape(encodeURIComponent(JSON.stringify(value))).length / (1024 * 1024);
      return (0, _lodash.round)(size, 3);
    },
    /**
     * Retrieves the storage info for reporting.
     * It only retrieves the key (not the value for security/privacy concerns) and
     * determines the size of the value for each key.
     * Example:
     *   'nuance-ai':{
     *     's':0.2,
     *     'API':{
     *        's':0.01,
     *        'Engagements':{
     *             '123':{
     *               's':0.01,
     *               'messagesCurrent':{
     *                   's':0.012, //s stands for size
     *                   'c':9 //c stands for count
     *               }
     *             }
     *         }
     *      }
     *    }
     * @method getStorageInfo
     * @param {object} storageObject the object from storage
     */
    getStorageInfo: function getStorageInfo(obj) {
      var _this3 = this;
      var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var returnObj = {
        s: this.getSizeInMB(obj)
      };
      // upto 3 level deep is good enough
      if (level > 2) returnObj;
      (0, _lodash.forOwn)(obj, function (value, key) {
        if (!value) return;
        returnObj[key] = {
          s: _this3.getSizeInMB(value)
        };

        //Since we store the value of `key` as a string, parse it first
        if (key == _this3.get('key')) value = JSON.parse(value);
        if (Array.isArray(value)) {
          returnObj[key].c = value.length;
        } else if ((0, _lodash.isObject)(value)) {
          returnObj[key] = _this3.getStorageInfo(value, level + 1);
        }
      });
      return returnObj;
    }
  });
});