define("sdk/components/list-render/component", ["exports", "sdk/core/component", "sdk/components/list-render/template", "sdk/api/utils/runloop", "sdk/components/list-render/mnstr", "sdk/components/list-render/mnstr-cell-navigation-mixin"], function (_exports, _component, _template, _runloop, _mnstr2, _mnstrCellNavigationMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_mnstrCellNavigationMixin.default, {
    layout: _template.default,
    engagementLayoutManager: Ember.inject.service(),
    windowSize: Ember.inject.service(),
    intl: Ember.inject.service(),
    componentName: 'list-render',
    'data-test-list-render': true,
    'data-test-is-rendering': Ember.computed.bool('isRendering'),
    items: Object.freeze([]),
    thresholdRatio: 0.5,
    scrollToBottomIncrement: 0,
    itemClassName: 'mnstr-item',
    init: function init() {
      this._super.apply(this, arguments);
      this.get('engagementLayoutManager').on('resizableColumnResize', this, this._onCellBoundsUpdated);
      this.get('windowSize').on('resize', this, this._onCellBoundsUpdated);
      this.get('api').on('fontSizeChange', this, this._onCellBoundsUpdated);
      this.options = this.options || {};
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this._mnstr && this.items && this._itemsCache !== this.items) {
        this._onNewRender();
        var isOneNewMessage = this._itemsCache && this._itemsCache.slice(-1)[0] === this.items.slice(-2)[0];
        if (isOneNewMessage) {
          this._mnstr.update(false, true);
        } else {
          this.scrollToBottomIncrement = 0;
          this._onNewList();
        }
        this._itemsCache = this.items;
      } else if (this.scrollToItem) {
        this._scrollToItem(this.scrollToItem);
      } else if (this.scrollToBottomIncrement) {
        this._scrollToBottom();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._onNewRender();
      this._onNewList();
      this._itemsCache = this.items;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('engagementLayoutManager').off('resizableColumnResize', this, this._onCellBoundsUpdated);
      this.get('windowSize').off('resize', this, this._onCellBoundsUpdated);
      this.get('api').off('fontSizeChange', this, this._onCellBoundsUpdated);
      Ember.run.cancel(this._debounceSaveOptions);
      this._destroyMNSTR();
    },
    /**
     * Default method for generating item elements
     * ['itemA'] => <div>itemA</div>
     * @method getItemElement
     */
    getItemElement: function getItemElement(item) {
      var node = document.createElement('div');
      node.innerHTML = item;
      return node;
    },
    /**
     * Register a waiter to wait for MNSTR cells to be rendered before continuing
     * @method _onNewRender
     */
    _onNewRender: function _onNewRender() {
      this.set('isRendering', true);
    },
    /**
     * Creates/ Recreates a MNSTR instance whenever a new list is received
     * Must fire afterRender so "getItemElement" can create ember component instances
     * @method _onNewList
     */
    _onNewList: function _onNewList() {
      this.willRenderNewList && this.willRenderNewList();
      if (this._mnstr) this._destroyMNSTR();
      this._initMNSTR();
    },
    _initMNSTR: function _initMNSTR() {
      var _this = this;
      var scrollToBottomIncrement = this.scrollToBottomIncrement;
      var restoreState = this.get('options.restoreState');
      var useRestoreState = !scrollToBottomIncrement && !!restoreState;
      var shouldScrollToTop = this.initialScrollToItem === 'top';
      var shouldScrollToBottom = scrollToBottomIncrement || !this.initialScrollToItem;
      var initialScrollToItem = shouldScrollToTop ? this.get('items.firstObject') : shouldScrollToBottom ? this.get('items.lastObject') : this.initialScrollToItem;
      var _mnstr = new _mnstr2.default({
        parentNode: this.element,
        thresholdRatio: this.thresholdRatio,
        renderOnInitialize: false,
        getData: function getData() {
          return _this.get('items');
        },
        getCellRenderer: function getCellRenderer(item, index, isExpanded, mnstr) {
          return (!_this._mnstr || mnstr === _this._mnstr) && !_this.isDestroyed ? _this.getItemElement(item, {
            classNames: _this.itemClassName
          }) : ''; // if MNSTR instance is no longer active, render empty item
        },

        initialScrollToElement: !useRestoreState && initialScrollToItem,
        initialScrollToOptions: this.initialScrollToOptions || {
          bottom: true,
          nearest: true
        }
      });
      _mnstr.addEventListener('cellsUpdated', function (cells) {
        return _this._onCellsUpdated(cells);
      });
      _mnstr.addEventListener('removedItemElement', function (itemElement) {
        return _this._onRemovedItemElement(itemElement);
      });
      useRestoreState ? _mnstr.restoreState(restoreState) : _mnstr.render();
      _mnstr.cellBoundsUpdated();
      _mnstr._scrollNode.setAttribute('tabindex', '0');
      _mnstr._scrollNode.setAttribute('role', 'application');

      // add scroll listener to save scroll options after mnstr is initialized
      this._onScroll = function () {
        return _this._debounceSaveOptions = (0, _runloop.debounce)(_this, _this._onItemBoundsChanged, 100);
      };
      _mnstr._scrollNode.addEventListener('scroll', this._onScroll);
      this.set('_mnstr', _mnstr);
    },
    _destroyMNSTR: function _destroyMNSTR() {
      Ember.run.cancel(this._debounceSaveOptions);
      if (this._mnstr) {
        this._mnstr._scrollNode.removeEventListener('scroll', this._onScroll);
        this._mnstr.destroy();
        this._mnstr = undefined;
      }

      // if a MNSTR instance already exists, destroy all elements inside component before creating a new instance
      this.element.querySelectorAll('*').forEach(function (n) {
        return n.remove();
      });
    },
    _onItemBoundsChanged: function _onItemBoundsChanged() {
      if (this.isDestroyed) return;
      var _mnstr = this._mnstr;
      if (_mnstr) {
        _mnstr.cellBoundsUpdated(); // updates cell bounds when scrolling up and down
        this.set('options.restoreState', _mnstr.exportRestoreState());
      }
    },
    /**
     * Updates cell bounds (fixes list item overlap)
     * @method _onCellBoundsUpdated
     */
    _onCellBoundsUpdated: function _onCellBoundsUpdated() {
      this._mnstr && this._mnstr.cellBoundsUpdated();
    },
    _onCellsUpdated: function _onCellsUpdated(cells) {
      if (this.isDestroyed) return;
      this.set('isRendering', false);
      this.onCellsUpdated && this.onCellsUpdated(cells);
      this.focusUpdatedCell && this.focusUpdatedCell();
    },
    _onRemovedItemElement: function _onRemovedItemElement(itemElement) {
      this.onItemElementRemoved && this.onItemElementRemoved(itemElement);
    },
    /**
     * since item heights vary, we must set scrollNode.scrollTop to scrollNode.scrollHeight
     * to scroll to the bottom of the .mnstr element
     * @method _scrollToBottom
     */
    _scrollToBottom: function _scrollToBottom() {
      var _mnstr = this._mnstr;
      if (!_mnstr) return;
      var lastItem = this.get('items.lastObject');

      // if item is already rendered, set scrollTop to scrollHeight
      var isItemCellRendered = _mnstr._cellsSorted.find(function (cell) {
        return cell.__element === lastItem;
      });
      if (isItemCellRendered) {
        _mnstr.setScrollPosition(_mnstr._scrollNode.scrollHeight);

        // otherwise, scroll to item then set scrollTop to scrollHeight to assure we're scrolled to the bottom
      } else {
        _mnstr.scrollToElement(lastItem, {
          bottom: true,
          nearest: true
        }).finally(function () {
          return _mnstr.setScrollPosition(_mnstr._scrollNode.scrollHeight);
        });
      }
    },
    /**
     * Scroll to element
     * @method _scrollToItem
     * @param {Object} item
     */
    _scrollToItem: function _scrollToItem(item) {
      var _mnstr = this._mnstr;
      if (!_mnstr) return;
      var isItemCellRendered = _mnstr._cellsSorted.find(function (cell) {
        return cell.__element === item;
      });
      if (!isItemCellRendered) {
        _mnstr.scrollToElement(item, {
          nearest: true
        });
      }
    }
  });
});