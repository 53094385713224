define("sdk/core/component", ["exports", "ember-get-config", "sdk/utils/get-focusable-nodes"], function (_exports, _emberGetConfig, _getFocusableNodes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    /**
     * The SDK API service
     * @property api
     * @public
     * @type {Ember.Service}
     */
    api: Ember.inject.service(),
    classNameBindings: ['_componentName'],
    classNames: ['nuance-component'],
    _componentName: Ember.computed('componentName', function () {
      var name = this.get('componentName');
      var prefix = 'nuance';
      if (!name) {
        return _emberGetConfig.default.environment === 'production' ? '' : "".concat(prefix, "-anonymous-component");
      }
      return "".concat(prefix, "-").concat(name);
    }),
    getFocusableNodes: _getFocusableNodes.default
  });
});