define("sdk/api/models/automaton", ["exports", "sdk/api/utils/rt-json"], function (_exports, _rtJson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('automatonDataMap', this.get('engagement.settings.automatonDataMap'));
    },
    /**
     * Server id of xforms automaton
     * @property {String} id
     * @public
     */
    id: Ember.computed(function () {
      var idMatch = this.get('url').match(/dtid=(\d+)/);
      return idMatch && idMatch[1];
    }),
    /**
     * Source url for xforms automaton
     * @property {String} src
     * @public
     */
    src: Ember.computed('params', function () {
      var automatonParams = Ember.$.param(this.get('params'));
      var automatonUrl = this.getWithDefault('url', '');
      var hasQueryParams = automatonUrl.includes('?');
      return "".concat(automatonUrl).concat(hasQueryParams ? '&' : '?').concat(automatonParams);
    }),
    /**
     * An object of general query params for automatons
     * @property {Object} params
     */
    params: Ember.computed('automatonDataMap', function () {
      var agentId = this.get('engagement.api.agent.userId');
      return {
        _chatID: this.get('engagement.id'),
        _agentGroupID: this.get('engagement.settings.agentGroupId'),
        _customerID: this.get('engagement.customer.id'),
        _businessUnitID: this.get('engagement.settings.businessUnitId'),
        _siteID: this.get('engagement.settings.siteId'),
        _agentID: agentId,
        _$location: "{ type: 'ai', id: ".concat(agentId, " }"),
        _$initiator: "{ type: 'agent', id: ".concat(agentId, " }"),
        _conversationId: this.get('engagement.settings.conversationId'),
        _initialCustId: this.get('engagement.settings.initialCustomerId'),
        _automatonDataMap: this.get('automatonDataMap'),
        agent: true
      };
    }),
    /**
     * Unique id that is created from engagement id and index of the automaton
     *
     * @property {String} placeholderId
     * @public
     */
    placeholderId: Ember.computed(function () {
      (false && !(Ember.isPresent(this.get('index'))) && Ember.assert('"index" property must exists on Automaton model', Ember.isPresent(this.get('index'))));
      return "automaton_".concat(this.get('engagement.id'), "_").concat(this.get('index'));
    }),
    /**
    * Retrieves data from the API using two
    * @method addToDataMap
    * @public
    * @param {Object} dataMap new data to be added the the current automatonDataMap string
    */
    addToDataMap: function addToDataMap(dataMap) {
      var currentAutomatonDataMap = {};
      try {
        currentAutomatonDataMap = _rtJson.default.parse(this.get('automatonDataMap')) || {};
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Unable to parse engagements automatonDataMap using RTJSON module. The value was ".concat(this.get('automatonDataMap')));
      }
      Object.assign(currentAutomatonDataMap, dataMap);
      var stringifiedDataMap = '';
      try {
        stringifiedDataMap = _rtJson.default.stringify(currentAutomatonDataMap);
        this.set('automatonDataMap', stringifiedDataMap);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Unable to stringify new automatonDataMap using RTJSON module. The value was ".concat(currentAutomatonDataMap));
      }
    }
  });
});