define("sdk/site/summary/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      /**
       * Filters metrics based on the selected site.
       * @method filterMetricsBySite
       * @public
       * @param {String} site the site ID of the metrics
       */
      filterMetricsBySite: function filterMetricsBySite(siteName) {
        this.get('api.metrics').setSelectedSite(siteName);
      },
      /**
       * Filters metrics based on the selected filter.
       * @method filterMetricsByAgentGroupOrBusinessUnit
       * @public
       * @param {String} filterName the agent group or business unit or summary of the metrics
       */
      filterMetricsByAgentGroupOrBusinessUnit: function filterMetricsByAgentGroupOrBusinessUnit(filterName) {
        this.get('api.metrics').setSelectedFilter(filterName);
      },
      columnResizeEvent: function columnResizeEvent(isResizing, $column) {
        if (isResizing) return;
        this.set('agentSummaryColumnWidth', $column.width());
      }
    }
  });
});