define("sdk/mixins/sound", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.setPlayingSound(false);
      var AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();
      this.set('soundQueue', []);
      this.set('audioCache', {});
    },
    /**
     * Caches sound filenames so the audio files won't have to load at a later time
     * @method initAudioCache
     * @param {Array} soundFilenames
     */
    initAudioCache: function initAudioCache(soundFilenames) {
      var _this = this;
      soundFilenames.forEach(function (filename) {
        _this.getAudioInstance(filename);
      });
    },
    playSound: function playSound(filename) {
      var _this2 = this;
      var volume = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.7;
      if (this.audioContext.state === 'suspended') return;
      var environment = _emberGetConfig.default.environment;
      if (environment === 'test') {
        volume = 0;
      }
      var queue = this.get('soundQueue');
      if (this.get('isPlayingSound')) {
        queue.push(filename);
        return Ember.RSVP.resolve();
      }
      this.setPlayingSound(true, {
        title: filename
      });
      var audio = this.getAudioInstance(filename);
      audio.volume = volume;
      return new Ember.RSVP.Promise(function (resolve) {
        var playPromise = audio.play();

        // Chrome, Firefox Audio.play() returns promise
        if (playPromise !== undefined) {
          playPromise.then(function () {
            Ember.run.later(_this2, function () {
              if (_this2.get('isDestroyed')) return;
              resolve(queue.length ? _this2.playSound(queue.shift()) : resolve());
            }, audio.duration);
            _this2.setPlayingSound(false);
          }).catch(function () {
            _this2.setPlayingSound(false);
          });
        } else {
          // IE, Edge does not support Audio.play() promise
          // no sound queue is utilized
          _this2.setPlayingSound(false);
          resolve(resolve());
        }
      });
    },
    setPlayingSound: function setPlayingSound(isPlaying) {
      var metadata = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // used for testing when sounds are playing or have been played
      var playCount = window.mediaSession ? window.mediaSession.playCount : 0;
      window.mediaSession = {
        playbackState: isPlaying ? 'playing' : 'none',
        lastPlayedMetadata: window.mediaSession && window.mediaSession.metadata ? window.mediaSession.metadata : {},
        metadata: metadata,
        playCount: isPlaying ? playCount + 1 : playCount
      };
      if (!(this.get('isDestroyed') || this.get('isDestroying'))) {
        this.set('isPlayingSound', isPlaying);
      }
    },
    getAudioInstance: function getAudioInstance(filename) {
      var soundCacheKey = this._getSoundCacheKey(filename);
      var cachedAudioInstance = this.get("audioCache.".concat(soundCacheKey));
      if (cachedAudioInstance) return cachedAudioInstance;
      var soundFilepath = this._getSoundFilepath(filename);
      var newAudioInstance = new Audio(soundFilepath);
      this.set("audioCache.".concat(soundCacheKey), newAudioInstance);
      return newAudioInstance;
    },
    /**
     * Generates a key to be used as a reference when looking up the cached audio file
     * @method _getSoundCacheKey
     * @param {String} filename
     */
    _getSoundCacheKey: function _getSoundCacheKey(filename) {
      return filename.replace(/[^\w]/g, '');
    },
    /**
     * Prepends the directory of all sound files
     * @method _getSoundFilePath
     * @param {String} filename
     */
    _getSoundFilepath: function _getSoundFilepath(filename) {
      return 'assets/sounds/' + filename;
    }
  });
});