define("sdk/components/widget-image/component", ["exports", "sdk/core/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    intl: Ember.inject.service(),
    componentName: 'widget-image',
    alt: Ember.computed('intl.locale', function () {
      return this.intl.t('rich-widget.default-label');
    }),
    src: Ember.computed('widget', function () {
      return this.widget.thumbnail || '';
    }),
    handleImgLoad: function handleImgLoad() {},
    _onError: function _onError() {
      this.imgElement.src = this.widget.defaultThumbnail;
    },
    _onLoad: function _onLoad() {
      this.imgElement.dataset.loaded = 'true';
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.imgElement = this.element.querySelector('img');
      this._onError = this._onError.bind(this);
      this._onLoad = this._onLoad.bind(this);
      this.imgElement.addEventListener('error', this._onError);
      this.imgElement.addEventListener('load', this._onLoad);
      this.imgElement.addEventListener('load', this.handleImgLoad);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.imgElement.removeEventListener('error', this._onError);
      this.imgElement.removeEventListener('load', this._onLoad);
      this.imgElement.removeEventListener('load', this.handleImgLoad);
      this.imgElement = null;
    }
  });
});