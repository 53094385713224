define("sdk/templates/modals/-twitter-private-reply-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WFc+zQey",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"modalClass\",\"modalAriaLabelledBy\",\"modalAriaDescribedBy\",\"isBackDropCloseEnabled\",\"isKeyboardEnabled\",\"hasCloseButton\",\"onClose\"],[\"twitter-private-reply-confirmation-modal\",\"twitter-modal-title\",\"twitter-modal-body\",true,true,true,[26,\"action\",[[21,0,[]],[22,[\"set\"]],\"showTwitterPrivateReplyConfirmationModal\",false],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"twitter-modal-title\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"twitter-private-reply-confirmation.title\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"p\"],[10,\"id\",\"twitter-modal-body\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"twitter-private-reply-confirmation.message\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"    \"],[6,\"button\"],[10,\"class\",\"btn btn-secondary\"],[10,\"data-test-twitter-private-reply-cancel-btn\",\"\"],[3,\"action\",[[21,0,[]],[22,[\"set\"]],\"showTwitterPrivateReplyConfirmationModal\",false]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"modal.cancel\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",\"btn btn-primary\"],[10,\"data-test-twitter-private-reply-okay-btn\",\"\"],[3,\"action\",[[21,0,[]],\"sendPrivateTwitterMessage\"]],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"modal.okay\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-twitter-private-reply-confirmation.hbs"
    }
  });
});