define("sdk/lockout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5UJb96Dg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nuance-home\",null,null,{\"statements\":[[0,\"\\n  \"],[6,\"div\"],[10,\"data-test-lockout-message\",\"\"],[10,\"class\",\"lockout__inactivity-message\"],[8],[1,[26,\"t\",[\"lockout.inactivity-message\"],null],false],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"isSSO\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"link-to\",[\"sso.login\"],[[\"class\",\"tagName\"],[\"lockout__relogin-button\",\"button\"]],{\"statements\":[[0,\"      \"],[1,[26,\"t\",[\"sso.relogin\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[1,[26,\"sdk-login-form\",null,[[\"username\",\"passwordOnly\",\"authenticate\"],[[22,[\"api\",\"agent\",\"userId\"]],true,[26,\"action\",[[21,0,[]],\"authenticate\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/lockout/template.hbs"
    }
  });
});