define("sdk/components/collapsable-tile/component", ["exports", "sdk/components/collapsable-tile/template", "sdk/core/resizable-component"], function (_exports, _template, _resizableComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _resizableComponent.default.extend({
    layout: _template.default,
    windowSize: Ember.inject.service(),
    attributeBindings: ['role', 'aria-label'],
    componentName: 'collapsable-tile',
    classNames: ['layout-column'],
    classNameBindings: ['containerClass', 'hide', 'isCollapsed:collapsed:open', 'isExpanded:expanded:compressed', 'flexHeight'],
    resizable: Ember.computed.not('singleTile'),
    resizeHandles: 's',
    role: 'region',
    /**
     * Name of the tile
     * Creates tile-specific class for individual styling
     * @property tileName
     */
    tileName: 'unknown',
    containerClass: Ember.computed(function () {
      return this.get('tileName') + '-container';
    }),
    isCollapsed: false,
    isExpanded: false,
    bsCollapseClasses: Ember.computed('isScrollable', function () {
      var scrollClass = this.get('isScrollable') ? 'vertical-scroll' : '';
      return "content ".concat(scrollClass);
    }),
    flexHeight: Ember.computed('isCollapsed', function () {
      return this.get('isCollapsed') ? 'flex-none' : 'flex-auto';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        var onTileToggle = _this.get('onTileToggle');
        onTileToggle && onTileToggle(_this.get('isCollapsed'), _this.get('tileName'));
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('resizeMinHeight', this.$('.nuance-collapsable-tile-header').outerHeight(true));
      this._handleOverlay();
    },
    actions: {
      /**
       * Called by ember-bootstrap's collapse component
       * right after the tile is collapsed.
       * @method onHidden
       * @public
       */
      onHidden: function onHidden() {
        this.$().addClass('cancel');
      },
      /**
       * Called by ember-bootstrap's collapse component
       * right after the tile is expanded.
       * @method onShown
       * @public
       */
      onShown: function onShown() {
        this.$().removeClass('cancel').addClass('ui-resizable');
      },
      expand: function expand() {
        this.set('isExpanded', true);
        this.set('isCollapsed', false);
        var onExpanded = this.get('onExpanded');
        onExpanded && onExpanded(this.get('tileName'));
      },
      expandTileToFullScreen: function expandTileToFullScreen() {
        this.set('isExpanded', true);
        this.set('isCollapsed', false);
        var onExpandedToFullScreen = this.get('onExpandedToFullScreen');
        onExpandedToFullScreen && onExpandedToFullScreen();
      },
      compress: function compress() {
        this.set('isExpanded', false);
        var onCompressed = this.get('onCompressed');
        onCompressed && onCompressed(this.get('tileName'));
      },
      toggle: function toggle() {
        this.toggleProperty('isCollapsed');
        this.get('api.agent').persist();
        var onTileToggle = this.get('onTileToggle');
        onTileToggle && onTileToggle(this.get('isCollapsed'), this.get('tileName'));
        if (!this.get('isCollapsed')) {
          this.get('engagementLayoutManager').onComponentExpanded(this.get('tileName'));
        }
      },
      sendBackToStartingPosition: function sendBackToStartingPosition() {
        // logic handled in draggable-collapsable-tile
      }
    },
    _handleOverlay: function _handleOverlay() {
      var hasOverlay = this.get('hasOverlay');
      if (typeof hasOverlay !== 'boolean') return;
      if (hasOverlay) {
        this._showOverlay();
      } else {
        this._hideOverlay();
      }
    },
    _showOverlay: function _showOverlay() {
      var coveredNodes = this.get('coveredNodes');
      if (coveredNodes && coveredNodes.length) {
        this._hideOverlay();
      }
      var $nodes = this.getFocusableNodes();
      var nodes = [];
      $nodes.each(function (index, node) {
        nodes.addObject({
          node: node,
          tabIndex: Ember.$(node).attr('tabindex')
        });
      });
      $nodes.attr('tabindex', '-1');
      this.set('coveredNodes', nodes);
    },
    _hideOverlay: function _hideOverlay() {
      var _this2 = this;
      var coveredNodes = this.get('coveredNodes');
      if (!coveredNodes) return;
      coveredNodes.forEach(function (coveredNode) {
        var originalTabIndex = coveredNode.tabIndex;
        var node = coveredNode.node;
        if (originalTabIndex) {
          _this2.$(node).attr('tabindex', originalTabIndex);
        } else {
          _this2.$(node).removeAttr('tabindex');
        }
      });
      this.set('coveredNodes', null);
    }
  });
});