define("sdk/components/menu-select/options/component", ["exports", "sdk/core/component", "sdk/components/menu-select/options/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'menu-select-options',
    attributeBindings: ['aria-activedescendant', 'data-test-menu-select-options', 'role', 'tabindex', 'title'],
    'data-test-menu-select-options': '',
    tagName: 'ul',
    tabindex: 0,
    role: 'listbox',
    windowSize: Ember.inject.service(),
    navigatedOption: Ember.computed('options', 'navigatedIndex', function () {
      return this.get('options')[this.get('navigatedIndex')];
    }),
    navigatedOptionElementSelector: Ember.computed('navigatedIndex', function () {
      return "".concat(this.get('elementId'), "-option-").concat(this.get('navigatedIndex'));
    }),
    'aria-activedescendant': Ember.computed.reads('navigatedOptionElementSelector'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('navigatedIndex', this.get('options').indexOf(this.get('selected')));
      this.get('windowSize').on('resize', this, this.updateStyle);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.updateStyle();
      Ember.run.scheduleOnce('afterRender', this, function () {
        _this.$().focus();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('windowSize').off('resize', this, this.updateStyle);
    },
    isElementInViewport: function isElementInViewport() {
      var boundingClientRect = this.$()[0].getBoundingClientRect();
      return boundingClientRect.top >= 0 && boundingClientRect.left >= 0 && boundingClientRect.bottom <= window.innerHeight && boundingClientRect.right <= window.innerWidth;
    },
    updateStyle: function updateStyle() {
      if (!this.isElementInViewport()) {
        var boundingClientRect = this.$()[0].getBoundingClientRect();
        var previousSibling = this.$().prev();
        var siblingBounding = previousSibling[0].getBoundingClientRect();
        if (!(boundingClientRect.bottom <= window.innerHeight)) {
          this.$().css('bottom', "".concat(siblingBounding.height, "px"));
          var newYOfElement = siblingBounding.y - boundingClientRect.height;
          var parentsOfElement = this.$().parents().toArray();
          if (this.isElementNotFullyVisible(parentsOfElement, newYOfElement)) {
            this.$().css('position', 'fixed');
            this.$().css('min-width', 'inherit');
          }
        }
        if (boundingClientRect.left < 0) {
          var left = siblingBounding.height - previousSibling.innerHeight();
          this.$().css('left', "".concat(left, "px"));
        }
      }
    },
    isElementNotFullyVisible: function isElementNotFullyVisible(parents, elementY) {
      return parents.some(function (parentElement) {
        var parentY = parentElement.getBoundingClientRect().y;
        if (parentY > elementY) {
          //if element overlaps parent
          var overflow = window.getComputedStyle(parentElement).getPropertyValue('overflow-y');
          if (overflow === 'scroll' || overflow === 'auto') return true;
        }
      });
    },
    keyDown: function keyDown() {
      var keyCode = event.keyCode;
      var navigatedIndex = this.get('navigatedIndex');
      var options = this.get('options');
      var isArrowUp = keyCode === 38;
      var isArrowDown = keyCode === 40;
      var isEnter = keyCode === 13;
      var isEscape = keyCode === 27;
      var isSpace = keyCode === 32;
      if (isArrowUp || isArrowDown || isEnter || isEscape || isSpace) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (isArrowUp && navigatedIndex > 0) {
        this.decrementProperty('navigatedIndex');
        this.scrollOptions();
      } else if (isArrowDown && navigatedIndex < options.length - 1) {
        this.incrementProperty('navigatedIndex');
        this.scrollOptions();
      } else if (isEnter) {
        this.send('onOptionSelected', this.get('navigatedOption'));
      }
    },
    /**
     * Scroll dropdown options based on active list item.
     */
    scrollOptions: function scrollOptions() {
      var menuOptionsElement = this.element;
      var navigatedOptionElement = menuOptionsElement.querySelector('#' + this.get('navigatedOptionElementSelector'));
      var menuSelectClientHeight = menuOptionsElement.clientHeight,
        menuSelectScrollHeight = menuOptionsElement.scrollHeight,
        menuSelectScrollTop = menuOptionsElement.scrollTop;
      if (menuSelectScrollHeight > menuSelectClientHeight) {
        var navigatedOptionElementOffsetHeight = navigatedOptionElement.offsetHeight,
          navigatedOptionElementOffsetTop = navigatedOptionElement.offsetTop;
        var menuSelectScrollBottom = menuSelectClientHeight + menuOptionsElement.scrollTop;
        var navigatedOptionElementBottom = navigatedOptionElementOffsetTop + navigatedOptionElementOffsetHeight;
        if (navigatedOptionElementBottom > menuSelectScrollBottom) {
          menuOptionsElement.scrollTop = navigatedOptionElementBottom - menuSelectClientHeight;
        } else if (navigatedOptionElementOffsetTop < menuSelectScrollTop) {
          menuOptionsElement.scrollTop = navigatedOptionElementOffsetTop;
        }
      }
    },
    /**
     * Cross browser issue. If set on keyDown, it toggles the trigger also.
     * @method keyUp
     */
    keyUp: function keyUp() {
      var keyCode = event.keyCode;
      var isSpaceBar = keyCode === 32;
      if (isSpaceBar) {
        event.stopPropagation();
        event.preventDefault();
        this.send('onOptionSelected', this.get('navigatedOption'));
      }
    },
    actions: {
      setnavigatedIndex: function setnavigatedIndex(index) {
        this.set('navigatedIndex', index);
      },
      onOptionSelected: function onOptionSelected(option) {
        this.get('onOptionSelected')(option);
      }
    }
  });
});