define("sdk/mixins/persisted-scroll-position", ["exports", "sdk/api/utils/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    elementSelectors: Ember.computed.alias('persistScrollElementSelectors'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('scrollPositions', this.getWithDefault('scrollPositions', []));
    },
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        var elementSelectors = _this.get('elementSelectors');
        if (!elementSelectors || elementSelectors.length === 0) return;
        _this._disableScrollListeners(elementSelectors);
        elementSelectors.forEach(function (selector) {
          var $element = _this.$(selector).length ? _this.$(selector) : Ember.$(selector);
          if (!$element || !$element.length) return;
          _this._setScrollBarToLastScrollPosition(selector);
          _this._enableScrollListener($element);
        });
      });
    },
    _enableScrollListener: function _enableScrollListener($element) {
      var _this2 = this;
      $element.on('scroll.persisted-scroll-position', function () {
        return (0, _runloop.debounce)(_this2, _this2._saveScrollPosition, 150);
      });
    },
    _saveScrollPosition: function _saveScrollPosition() {
      var _this3 = this;
      var scrollPositions = this.get('scrollPositions') || [];
      var elementSelectors = this.get('elementSelectors');
      if (!elementSelectors || elementSelectors.length === 0) return;
      elementSelectors.forEach(function (selector) {
        var $element = _this3.$(selector).length ? _this3.$(selector) : Ember.$(selector);
        scrollPositions[selector] = $element.prop('scrollTop');
      });
      this.set('scrollPositions', scrollPositions);
    },
    _disableScrollListeners: function _disableScrollListeners(elementSelectors) {
      elementSelectors.forEach(function (selector) {
        if (Ember.$(selector) && Ember.$(selector).length > 0) {
          Ember.$(selector).off('scroll.persisted-scroll-position');
        }
      });
    },
    _setScrollBarToLastScrollPosition: function _setScrollBarToLastScrollPosition(selector) {
      var scrollPosition = this.get('scrollPositions')[selector];
      Ember.$(selector).scrollTop(scrollPosition || 0);
    },
    willDestroyElement: function willDestroyElement() {
      var elementSelectors = this.get('persistScrollElementSelectors');
      if (elementSelectors && elementSelectors.length) {
        this._disableScrollListeners(elementSelectors);
      }
    }
  });
});