define("sdk/utils/index", ["exports", "sdk/utils/clone", "sdk/utils/without"], function (_exports, _clone, _without) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "clone", {
    enumerable: true,
    get: function get() {
      return _clone.default;
    }
  });
  Object.defineProperty(_exports, "without", {
    enumerable: true,
    get: function get() {
      return _without.default;
    }
  });
});