define("sdk/utils/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = Ember.Object.extend({
    height: 100,
    width: 100,
    location: false,
    resizable: true,
    status: false,
    toolbar: false,
    scrollbars: true,
    position: 'top-left',
    method: 'GET',
    target: 'popup',
    /**
     * Opens a new browser window
     * @method open
     */
    open: function open() {
      if (this.frame && !this.frame.closed) {
        return this.focus();
      }
      var method = this.method.toLowerCase();
      console.group('Open Page Plugin');
      console.log('- URL:', this.url);
      console.log('- Method:', method.toUpperCase());
      console.log('- Target:', this.target);
      console.log('- Data:', this.data);
      var frame = this.target === 'popup' ? window.open('', this.name, this.getFeatures()) : window.open('', this.name);
      this.frame = frame;
      console.groupEnd();
      if (this.target === 'popup') {
        this.setPosition();
      }
      if (method === 'post') {
        this.loadFormHtml();
        return frame;
      }
      var url = this.url + this.serializeData();

      // url is set after window has been created and moved
      // so no "Access Denied" cross-origin errors will occur
      frame.location = url;
      return frame;
    },
    /**
     * Moves the window to a set position
     * @method setPosition
     */
    setPosition: function setPosition() {
      var height = this.height,
        width = this.width;
      var position = this.position.toLowerCase().replace(/\W/g, '');
      var left;
      var _top;
      switch (position) {
        case 'topright':
        case 'tr':
          left = screen.width - width;
          _top = 0;
          break;
        case 'bottomleft':
        case 'bl':
          left = 0;
          _top = screen.height - height;
          break;
        case 'bottomright':
        case 'br':
          left = screen.width - width;
          _top = screen.height - height;
          break;
        case 'topleft':
        case 'tl':
        default:
          left = 0;
          _top = 0;
          break;
      }
      this.frame.moveTo(left, _top);
    },
    getFeatures: function getFeatures() {
      var _this = this;
      var chromeKeys = ['height', 'width', 'location', 'resizable', 'status', 'toolbar', 'scrollbars'];
      var features = {};
      var str = chromeKeys.sort().filter(function (key) {
        return _this[key] !== undefined;
      }).map(function (key) {
        var val = _this[key];
        val = typeof val === 'boolean' ? val ? 'yes' : 'no' : val;
        features[key] = _this[key];
        return "".concat(key, "=").concat(val);
      }).join(',');
      console.log('- Popup:', features);
      return str;
    },
    serializeData: function serializeData() {
      var _this2 = this;
      if (!this.data || _typeof(this.data) !== 'object') return '';
      return '?' + Object.keys(this.data).map(function (key) {
        return "".concat(key, "=").concat(_this2.data[key]);
      }).join('&');
    },
    loadFormHtml: function loadFormHtml() {
      var doc = this.frame.document;
      var form = doc.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', this.url);
      form.setAttribute('style', 'display:none;');
      for (var key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          var field = doc.createElement('input');
          field.setAttribute('name', key);
          field.setAttribute('value', this.data[key]);
          form.appendChild(field);
        }
      }
      doc.body.appendChild(form);
      form.submit();
    },
    focus: function focus() {
      if (this.frame && !this.frame.closed) {
        this.frame.focus();
      }
    },
    close: function close() {
      if (this.frame && !this.frame.closed) {
        this.frame.close();
      }
    }
  });
});