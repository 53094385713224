define("sdk/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flatten = flatten;
  _exports.sortAlphabetically = sortAlphabetically;
  function sortAlphabetically(array) {
    return array.sort(function (a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Used to flatten nested arrays.
   * https://stackoverflow.com/a/15030117/1536773
   * @param {Array} arr
   * @return {Array} the flattened array
   */
  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
    }, []);
  }
});