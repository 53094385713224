define("sdk/components/key-values/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1ugFPXv8",
    "block": "{\"symbols\":[\"keyValue\"],\"statements\":[[4,\"if\",[[22,[\"title\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"key-values__title\"],[8],[1,[20,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[22,[\"keyValues\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"key-value\"],[8],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"key-value__key\"],[8],[1,[21,1,[\"key\"]],false],[0,\":\"],[9],[0,\" \"],[6,\"span\"],[10,\"class\",\"key-value__value\"],[8],[1,[21,1,[\"value\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/key-values/template.hbs"
    }
  });
});