define("sdk/utils/is-element-tabbable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    var element = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return element.tabIndex >= 0 && !element.hasAttribute('disabled');
  }
});