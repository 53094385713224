define("sdk/components/nuance-home/component", ["exports", "sdk/core/component", "sdk/components/nuance-home/template", "sdk/utils/browser-type"], function (_exports, _component, _template, _browserType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nuance-home',
    currentYear: new Date().getFullYear(),
    environment: NUANCE_ENV.environment,
    isDevelopmentEnvironment: Ember.computed.equal('environment', 'development'),
    isIE: _browserType.default.isIE
  });
});