define("sdk/mixins/grid-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // Note: container element should have attribute 'role="application"'
    // to support arrow keys setting focus as well as navigating in JAWS

    currentCellIndex: 0,
    currentRowIndex: -1,
    keyDown: function keyDown(event) {
      var keyCode = event.keyCode;
      switch (keyCode) {
        case 35: // End
        case 36:
          {
            // Home
            event.stopPropagation();
            event.preventDefault();
            keyCode === 35 ? this.moveFocusTo(-1) : this.moveFocusTo(0);
            break;
          }
        case 37: // Left
        case 39:
          {
            // Right
            event.stopPropagation();
            event.preventDefault();
            this.focusCellIndex(this.currentCellIndex + (keyCode === 37 ? -1 : 1));
            break;
          }
        case 38: // Up
        case 40:
          {
            // Down
            event.stopPropagation();
            event.preventDefault();
            var currentRowIndex = this.get('currentRowIndex');
            keyCode === 38 ? this.moveFocusTo(currentRowIndex - 1) : this.moveFocusTo(currentRowIndex + 1);
            break;
          }
      }
    },
    moveFocusTo: function moveFocusTo(row) {
      var rowElementSelector = this.get('rowElementSelector');
      var grid = this.element.querySelectorAll(rowElementSelector ? "[role=\"row\"],".concat(rowElementSelector) : '[role="row"]');
      var moveToRowIndex = this.countRow(row, grid.length);
      var currentRowIndex = this.get('currentRowIndex');
      this.blurNode(grid[currentRowIndex]);
      this.focusNode(grid[moveToRowIndex]);
      this.setProperties({
        currentRowIndex: moveToRowIndex,
        currentCellIndex: 0
      });
    },
    focusCellIndex: function focusCellIndex(index) {
      var cellNodes = this._getCellNodes();
      if (cellNodes.length <= 1) return;
      var nextCellIndex = this.countRow(index, cellNodes.length);
      this.blurNode(cellNodes[this.currentCellIndex]);
      this.focusNode(cellNodes[nextCellIndex]);
      this.set('currentCellIndex', nextCellIndex);
    },
    countRow: function countRow(row, limit) {
      var rowLength = limit - 1;
      if (row < 0) {
        return rowLength;
      } else if (row > rowLength) {
        return 0;
      }
      return row;
    },
    focusIn: function focusIn() {
      var rowIndex = this._getRowElements().indexOf(document.activeElement);
      if (document.activeElement === this.element) {
        this.setDefaultSettings();
      } else if (rowIndex >= 0) {
        this.setDefaultSettings(rowIndex);
      }
      var timeoutOnBlur = this.get('timeoutOnBlur');
      timeoutOnBlur && clearTimeout(timeoutOnBlur);
    },
    focusOut: function focusOut() {
      var timeoutOnBlur = setTimeout(this.setDefaultSettings.bind(this));
      this.set('timeoutOnBlur', timeoutOnBlur);
    },
    setDefaultSettings: function setDefaultSettings() {
      var rowIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -1;
      if (this.isDestroyed) return;
      this.blurNode(this._getCurrentRowNode());
      this.blurNode(this._getCurrentCellNode());
      this.setProperties({
        currentCellIndex: 0,
        currentRowIndex: rowIndex
      });
    },
    focusNode: function focusNode(domNode) {
      if (!this._isInteractiveElement(domNode)) {
        domNode.setAttribute('tabindex', '0');
      }
      domNode.focus();
    },
    blurNode: function blurNode(domNode) {
      if (!this._isInteractiveElement(domNode)) {
        domNode && domNode.setAttribute('tabindex', '-1');
      }
    },
    _getCurrentRowNode: function _getCurrentRowNode() {
      var rowNodeSelector = "[role=row]".concat(this.rowElementSelector ? ',' + this.rowElementSelector : '');
      return this.element.querySelectorAll(rowNodeSelector)[this.currentRowIndex];
    },
    _getCellNodes: function _getCellNodes() {
      var currentRowNode = this._getCurrentRowNode();
      if (!currentRowNode) return [];
      return [currentRowNode].concat(Array.from(currentRowNode.querySelectorAll('[role="cell"]')));
    },
    _getCurrentCellNode: function _getCurrentCellNode() {
      return this._getCellNodes()[this.currentCellIndex];
    },
    _isInteractiveElement: function _isInteractiveElement(element) {
      var interactiveElements = ['A', 'BUTTON'];
      return element && interactiveElements.includes(element.tagName);
    },
    _getRowElements: function _getRowElements() {
      return Array.from(this.element.querySelectorAll(this.rowElementSelector ? "[role=\"row\"],".concat(this.rowElementSelector) : '[role="row"]'));
    }
  });
});