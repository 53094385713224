define("sdk/api/utils/runloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debounce = debounce;
  _exports.later = later;
  _exports.throttle = throttle;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function debounce() {
    var _Ember$run2;
    if (window.runningTests) {
      var _Ember$run;
      var debounceArgs = modifyWaitTime.apply(void 0, arguments);
      return (_Ember$run = Ember.run).debounce.apply(_Ember$run, _toConsumableArray(debounceArgs));
    }
    return (_Ember$run2 = Ember.run).debounce.apply(_Ember$run2, arguments);
  }
  function later() {
    var _Ember$run4;
    if (window.runningTests) {
      var _Ember$run3;
      var laterArgs = modifyWaitTime.apply(void 0, arguments);
      return (_Ember$run3 = Ember.run).later.apply(_Ember$run3, _toConsumableArray(laterArgs));
    }
    return (_Ember$run4 = Ember.run).later.apply(_Ember$run4, arguments);
  }
  function throttle() {
    var _Ember$run6;
    if (window.runningTests) {
      var _Ember$run5;
      var throttleArgs = modifyWaitTime.apply(void 0, arguments);
      return (_Ember$run5 = Ember.run).throttle.apply(_Ember$run5, _toConsumableArray(throttleArgs));
    }
    return (_Ember$run6 = Ember.run).throttle.apply(_Ember$run6, arguments);
  }

  /**
   * Replace last argument, waitTime, with 10ms if running tests
   * @method modifyWaitTime
   */
  function modifyWaitTime() {
    var args = new Array(arguments.length);
    for (var i = 0; i < arguments.length; i++) {
      args[i] = arguments[i];
    }
    var waitTime;
    var hasImmediateFlag = false;
    var lastParam = args[args.length - 1];

    // "immediate" flag is passed as last parameter
    if (typeof lastParam === 'boolean') {
      waitTime = args[args.length - 2];
      hasImmediateFlag = true;
    } else {
      waitTime = lastParam;
    }

    // if waitTime is not a coercable number, return same arguments
    if (!isCoercableNumber(waitTime)) return args;

    // override wait time if running tests
    var modifiedWaitTime = 10;
    args[hasImmediateFlag ? args.length - 2 : args.length - 1] = modifiedWaitTime;
    return args;
  }
  function isCoercableNumber(suspect) {
    var NUMBER = /\d+/;
    var type = _typeof(suspect);
    return type === 'number' && suspect === suspect || type === 'string' && NUMBER.test(suspect);
  }
});