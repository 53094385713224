define("sdk/api/utils/date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNiceDateTime = getNiceDateTime;
  function getNiceDateTime() {
    var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var momentDate = (0, _moment.default)(timestamp);
    return momentDate.isValid() ? momentDate.format('MMMM D, YYYY [at] h:mm a') : timestamp;
  }
});