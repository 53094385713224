define("sdk/components/menu-power-select/menu-options/component", ["exports", "ember-power-select/components/power-select/options", "sdk/components/menu-power-select/menu-options/template"], function (_exports, _options, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _options.default.extend({
    layout: _template.default,
    'data-test-menu-select-options': '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.get('setMenuAriaAttributesAndListener')();
      this.get('updateAriaActiveDescendant')();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('resetButtonTabIndex')();
    }
  });
});