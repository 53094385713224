define("sdk/utils/browser-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isIE = /*@cc_on!@*/false || !!document.documentMode;
  var isEdge = !isIE && !!window.StyleMedia; // Edge 20+;
  var isEdgeOrIE = isEdge || isIE;
  var _default = _exports.default = {
    isIE: isIE,
    isEdgeOrIE: isEdgeOrIE
  };
});