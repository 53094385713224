define("sdk/api/data/chat-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [{
    name: "app",
    iconMUI: "Api",
    translateKey: "active-engagement.app-chat"
  }, {
    name: "apple",
    iconSVG: "apple",
    translateKey: "active-engagement.apple-business-chat"
  }, {
    name: "default",
    iconMUI: "ChatBubbleOutlined",
    translateKey: "active-engagement.general-chat"
  }, {
    name: "desktop",
    iconMUI: "DesktopMacOutlined",
    translateKey: "active-engagement.desktop-chat"
  }, {
    name: "eapi",
    iconMUI: "Api",
    translateKey: "active-engagement.eapi"
  }, {
    name: "facebook",
    iconSVG: "facebook",
    translateKey: "active-engagement.facebook-chat"
  }, {
    name: "google",
    iconSVG: "google",
    translateKey: "active-engagement.googles-business-messages-chat"
  }, {
    name: "instagram",
    iconSVG: "instagram",
    translateKey: "active-engagement.instagram-chat"
  }, {
    name: "line",
    iconSVG: "line",
    translateKey: "active-engagement.line-chat"
  }, {
    name: "mobile",
    iconMUI: "SmartphoneOutlined",
    translateKey: "active-engagement.mobile-chat"
  }, {
    name: "nina-web",
    iconMUI: "Api",
    translateKey: "active-engagement.web-chat"
  }, {
    name: "nina-coach",
    iconMUI: "LiveHelpOutlined",
    translateKey: "nina-coach.title"
  }, {
    name: "sms",
    iconMUI: "SmsOutlined",
    translateKey: "active-engagement.sms-chat"
  }, {
    name: "tablet",
    iconMUI: "TabletOutlined",
    translateKey: "active-engagement.tablet-chat"
  }, {
    name: "telegram",
    iconSVG: "telegram",
    translateKey: "active-engagement.telegram-chat"
  }, {
    name: "twitter",
    iconSVG: "twitter",
    translateKey: "active-engagement.twitter-chat"
  }, {
    name: "viber",
    iconSVG: "viber",
    translateKey: "active-engagement.viber-chat"
  }, {
    name: "whatsapp",
    iconSVG: "whatsapp",
    translateKey: "active-engagement.whatsapp-chat"
  }];
});