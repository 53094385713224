define("sdk/components/nina-coach/intent-filter/component", ["exports", "sdk/core/component", "sdk/components/nina-coach/intent-filter/template", "sdk/api/utils/parse-html", "sdk/api/utils/search-match-score", "sdk/utils/grammar", "sdk/api/utils/runloop", "sdk/utils/speak"], function (_exports, _component, _template, _parseHtml, _searchMatchScore, _grammar, _runloop, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    attributeBindings: ['aria-label', 'role'],
    role: 'region',
    intl: Ember.inject.service(),
    'aria-label': Ember.computed(function () {
      return this.get('intl').t('nina-coach.intents');
    }),
    'allLabel': Ember.computed(function () {
      return this.get('intl').t('nina-coach.all').toString();
    }),
    componentName: 'nina-coach-intent-filter',
    'data-test-nina-coach-intent-filter': '',
    searchText: Ember.computed.alias('ui.ninaSearchText'),
    selectedTypes: Ember.computed.alias('ui.selectedNinaTypeFilters'),
    allIntents: Ember.computed.alias('intents'),
    isLoading: Ember.computed.not('allIntents'),
    hasNoIntents: Ember.computed.empty('allIntents'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._setFilterText();
    },
    // throttle search input by user
    searchTextObserver: Ember.observer('searchText', function () {
      (0, _runloop.debounce)(this, this._setFilterText, 300);
    }),
    _setFilterText: function _setFilterText() {
      if (this.get('isDestroyed')) return;
      var searchTextWithoutConjunctions = (0, _grammar.removeConjunctions)(this.get('searchText'));
      this.set('filterText', searchTextWithoutConjunctions);
    },
    /**
     * Filters the displayed intents by filterText or by selecting/ unselecting a type
     * If searchText exists, intents will be given a match score that will be higher
     * if the intent is a close match and have a lower score if there's only a partial match
     * @property {Array} filteredIntents array of filtered intents
     * @public
     */
    filteredIntents: Ember.computed('allIntents', 'filterText', 'selectedTypes.@each', function () {
      this.set('focusedIntentIndex', null); // eslint-disable-line ember/no-side-effects

      var allIntents = this.get('allIntents');
      var filterText = this.get('filterText');
      var selectedTypes = this.get('selectedTypes');
      var filteredIntents = filterIntentsByTypes(allIntents, selectedTypes);

      // reset all intent's match score to 0
      filteredIntents.setEach('filterMatchScore', 0);
      if (!filterText) return filteredIntents;
      var USER_QUESTION_BASE_SCORE = 3;
      var RESPONSE_TEXT_BASE_SCORE = 2;
      var CONCEPTS_BASE_SCORE = 1;
      var MIN_SCORE = 10;
      var finalFilteredIntents = filteredIntents.filter(function (intent) {
        var userQuestion = intent.userQuestion,
          answer = intent.answer,
          concepts = intent.concepts;
        var userQuestionScore = (0, _searchMatchScore.default)(userQuestion, filterText, USER_QUESTION_BASE_SCORE);
        var responseText = (0, _parseHtml.removeHtmlTags)(answer);
        var responseTextScore = (0, _searchMatchScore.default)(responseText, filterText, RESPONSE_TEXT_BASE_SCORE);
        var conceptsScore = concepts.reduce(function (conceptsHighestScore, concept) {
          var name = concept.name,
            values = concept.values;
          var conceptNameScore = (0, _searchMatchScore.default)(name, filterText, CONCEPTS_BASE_SCORE);
          if (!values || values.length === 0) return 0;
          var highestConceptValueScore = values.reduce(function (score, value) {
            var conceptValueScore = (0, _searchMatchScore.default)(value, filterText);
            return conceptValueScore > score ? conceptValueScore : score;
          }, 1);
          var highestConceptScore = Math.max(conceptNameScore, highestConceptValueScore);
          return highestConceptScore > conceptsHighestScore ? highestConceptScore : conceptsHighestScore;
        }, 0);
        var highestMatchScore = Math.max(userQuestionScore, responseTextScore, conceptsScore);
        intent.filterMatchScore = highestMatchScore;
        return highestMatchScore >= MIN_SCORE;
      });
      (0, _speak.default)("".concat(finalFilteredIntents.length, " results found"));
      return finalFilteredIntents;
    }),
    /**
     * Sorts the intents based on toggled boolean sortDirection (see template)
     * @property {Array} intentsSortings
     */
    intentsSorting: Ember.computed('sortDirection', function () {
      var sortDirection = this.get('sortDirection');
      var userQuestionDirection = sortDirection ? 'desc' : 'asc';
      var numberDirection = sortDirection ? 'asc' : 'desc';
      return ["filterMatchScore:".concat(numberDirection), "confidence:".concat(numberDirection), "userQuestion:".concat(userQuestionDirection)];
    }),
    sortedIntents: Ember.computed.sort('filteredIntents', 'intentsSorting'),
    uniqTypes: Ember.computed.uniqBy('allIntents', 'type'),
    types: Ember.computed.mapBy('uniqTypes', 'type'),
    sortedTypes: Ember.computed.sort('types', function (a, b) {
      return a > b;
    }),
    /**
     * @property {Array} typeObjects array of type objects with "value" and "selected" keys
     * value: the name of the type
     * selected: state of the type filter checkbox
     */
    typeObjects: Ember.computed('sortedTypes', 'selectedTypes', function () {
      var selectedTypes = this.get('selectedTypes');
      return this.get('sortedTypes').map(function (type) {
        return Ember.Object.create({
          selected: selectedTypes.includes(type),
          value: type
        });
      });
    }),
    actions: {
      /**
       * clears the search input
       * @method clearSearch
       */
      clearSearch: function clearSearch() {
        this.set('searchText', '');
        this.$('#intents-search-input').focus();
        (0, _speak.default)("".concat(this.get('intl').t('nina-coach.cleared-intents-query')));
      },
      openFilterMenu: function openFilterMenu() {
        this.toggleProperty('isVisibleFilterByType');
        if (this.get('isVisibleFilterByType')) {
          (0, _speak.default)("".concat(this.get('intl').t('nina-coach.press-tab-key')));
        }
      },
      /**
       * Toggles the selected type
       * If "all" is toggled then all selected types will be removed
       * @method toggleFilterType
       * @param {String} typeName name of type to be selected/unselected
       */
      toggleFilterType: function toggleFilterType(typeName, checked) {
        if (typeName === 'all') {
          (0, _speak.default)(this.get('intl').t('nina-coach.all-checkbox'));
          return this.set('selectedTypes', []);
        }
        var selectedTypes = this.get('selectedTypes');
        this.get('typeObjects').findBy('value', typeName).set('selected', checked);
        if (selectedTypes.includes(typeName)) selectedTypes.removeObject(typeName);else selectedTypes.addObject(typeName);
      },
      /**
       * sets the selected intent
       * @method selectIntent
       * @param {Object} intent selected intent
       */
      selectIntent: function selectIntent(intent) {
        this.set('focusedIntentIndex', null);
        var onSelect = this.get('onSelect');
        onSelect && onSelect(intent);
      },
      onKeyUpFilterBy: function onKeyUpFilterBy(event) {
        if (event.keyCode === 27) {
          // esc key
          this.toggleProperty('isVisibleFilterByType');
          this.$('#type-filter-btn').focus();
        }
      },
      onKeyDown: function onKeyDown(event) {
        var isTabKey = event.keyCode === 9;
        if (isTabKey) {
          this.set('focusedIntentIndex', null);
          return;
        }
        var isUpArrow = event.keyCode === 38;
        var isDownArrow = event.keyCode === 40;
        if (!(isUpArrow || isDownArrow)) return;
        event.preventDefault();
        var $activeIntentItem = this.$('.intent__item.selected');
        var focusedIntentIndex = this.get('focusedIntentIndex');
        var activeIntentIndex = Number($activeIntentItem.attr('data-index')) || 0;
        if (typeof focusedIntentIndex === 'number') {
          activeIntentIndex = focusedIntentIndex;
        }
        var sortedIntentsLength = this.get('sortedIntents').length;
        var nextFocusIndex = isUpArrow ? activeIntentIndex - 1 : activeIntentIndex + 1;
        if (nextFocusIndex < 0) {
          nextFocusIndex = 0;
        } else if (nextFocusIndex >= sortedIntentsLength) {
          nextFocusIndex = sortedIntentsLength - 1;
        }
        this.set('focusedIntentIndex', nextFocusIndex);
        this.$("[data-index=\"".concat(nextFocusIndex, "\"]")).first().focus();
      }
    },
    _setIntentsListTabIndex: function _setIntentsListTabIndex() {
      this.set('hasNoItemsSelected', !this.$('.intent__item.selected').length);
    }
  });
  /**
   * @method filterIntentsByTypes
   * @param {Array} intents array of intents
   * @param {Array} filteredTypes array of intent names
   * @return {Array} intents that include a type that matches one of the filteredTypes
   */
  function filterIntentsByTypes(intents) {
    var filteredTypes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (!filteredTypes.length) return intents;
    return intents.filter(function (_ref) {
      var type = _ref.type;
      return filteredTypes.includes(type);
    });
  }
});