define("sdk/services/performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    api: Ember.inject.service(),
    memoryThresholdMultiplier: 4,
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearInterval(this.get('intervalMemoryCheck'));
    },
    createMemoryCheckInterval: function createMemoryCheckInterval() {
      var _this = this;
      if (!performance.memory) return;
      this.set('intervalMemoryCheck', setInterval(function () {
        var usedJSHeapSize = performance.memory.usedJSHeapSize;
        if (1024 * 1024 * 10 * _this.get('memoryThresholdMultiplier') < usedJSHeapSize) {
          _this._sendMemoryLog();
        }
      }, 60 * 1000)); // 1 minute);
    },
    _sendMemoryLog: function _sendMemoryLog() {
      var _performance$memory = performance.memory,
        jsHeapSizeLimit = _performance$memory.jsHeapSizeLimit,
        totalJSHeapSize = _performance$memory.totalJSHeapSize,
        usedJSHeapSize = _performance$memory.usedJSHeapSize;
      this.get('api.logging').sendLog('info', {
        event: "memory-increase-".concat(this.get('memoryThresholdMultiplier'), "0Mb-threshold"),
        event_group: 'performance-memory',
        js_heap_size_limit: jsHeapSizeLimit,
        total_js_heap_size: totalJSHeapSize,
        used_js_heap_size: usedJSHeapSize
      });
      this.incrementProperty('memoryThresholdMultiplier');
    }
  });
});