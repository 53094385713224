define("sdk/components/engagement-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JvIdq1Xl",
    "block": "{\"symbols\":[\"tab\",\"tabData\"],\"statements\":[[4,\"if\",[[22,[\"engagements\",\"length\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"x-tabs\",null,[[\"tabs\"],[[26,\"if\",[[22,[\"freezeChatTabPositionByDefault\"]],[22,[\"positionedEngagements\"]],[22,[\"engagements\"]]],null]]],{\"statements\":[[4,\"component\",[[21,1,[\"tab\"]]],[[\"role\",\"aria-selected\"],[\"\",false]],{\"statements\":[[4,\"if\",[[21,2,[]]],null,{\"statements\":[[4,\"link-to\",[\"site.engagements.engagement\",[21,2,[\"id\"]]],[[\"role\",\"classNames\",\"tagName\",\"data-test-engagement-link\",\"data-test-engagement-id\",\"aria-label\",\"enableAriaSelected\"],[\"tab\",\"engagement-button\",\"button\",[21,1,[\"index\"]],[21,2,[\"id\"]],[26,\"engagement-aria-label\",[[21,2,[]],[26,\"plus-one\",[[21,1,[\"index\"]]],null],[22,[\"api\",\"currentTime\"]]],null],true]],{\"statements\":[[0,\"          \"],[1,[26,\"engagement-tabs/tab\",null,[[\"engagement\",\"data-test-engagement-tab\",\"chatIndex\",\"unrespondedMessageIndicator\"],[[21,2,[]],[21,1,[\"index\"]],[26,\"plus-one\",[[21,1,[\"index\"]]],null],[21,2,[\"unrespondedMessageIndicator\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[6,\"div\"],[10,\"data-test-engagement-tab-filler\",\"\"],[10,\"class\",\"engagement-tab__filler\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"engagements-tabs__no-engagements\"],[8],[1,[26,\"t\",[\"engagement-tab.no-engagements\"],null],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/engagement-tabs/template.hbs"
    }
  });
});