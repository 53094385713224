define("sdk/api/models/core/array-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.ArrayProxy.extend({
    toJSON: function toJSON() {
      return this.map(function (item) {
        return typeof item.toJSON === 'function' ? item.toJSON() : item;
      });
    }
  }).reopenClass({
    create: function create(options) {
      if (Array.isArray(options)) {
        options = {
          content: options
        };
      } else if (!options) {
        options = {
          content: []
        };
      }
      return this._super(options);
    }
  });
});