define("sdk/components/menu-power-select/component", ["exports", "sdk/core/component", "ember-keyboard", "sdk/api/utils/runloop", "sdk/components/menu-power-select/template", "sdk/utils/speak"], function (_exports, _component, _emberKeyboard, _runloop, _template, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Add missing aria attributes and focus management to make ember power-select
   * component compatible with screen readers.
   *
   * @class Components.MenuPowerSelect
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    componentName: 'menu-power-select',
    attributeBindings: ['role'],
    searchEnabled: false,
    role: Ember.computed(function () {
      if (this.get('searchEnabled')) return 'application';
    }),
    labelText: Ember.computed('selected', function () {
      var selected = this.get('selected');
      if (!selected) return '';
      if (typeof this.get('selected') === 'string') {
        return selected;
      }
      return selected.label;
    }),
    onMenuOptionsKeyDown: Ember.on((0, _emberKeyboard.keyDown)(), function (event) {
      if (this.get('searchEnabled')) {
        this._onMenuSearchEnabledKeydown(event);
      } else {
        this._onMenuKeydown(event);
      }
    }),
    keyboardPriority: 10,
    init: function init() {
      this._super.apply(this, arguments);
      this.updateAriaActiveDescendant = this.updateAriaActiveDescendant.bind(this);
      this.setMenuAriaAttributesAndListener = this.setMenuAriaAttributesAndListener.bind(this);
      this.resetButtonTabIndex = this.resetButtonTabIndex.bind(this);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('[role="button"]').attr('aria-haspopup', 'listbox');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.get('speakResultsFoundDebounce'));
      this._removeMenuAriaListener();
    },
    /**
     * On menu close, reset trigger button tabindex and focus the trigger if
     * the TAB key wasn't pressed.
     * @method resetButtonTabIndex
     * @public
     */
    resetButtonTabIndex: function resetButtonTabIndex() {
      if (this.get('searchEnabled')) return;
      var $buttonTrigger = this.$('[role="button"]');
      $buttonTrigger.attr('tabindex', '0');
      if (!this.get('hasPressedTabKey')) {
        $buttonTrigger.focus();
      } else {
        this.set('hasPressedTabKey', false);
      }
      this.set('keyboardActivated', false);
    },
    /**
     * Add aria attributes to the trigger and listbox. Redispatch keydown events.
     * @method setMenuAriaAttributesAndListener
     * @public
     */
    setMenuAriaAttributesAndListener: function setMenuAriaAttributesAndListener() {
      var $buttonTrigger = this.$('[role="button"]');
      var menuId = $buttonTrigger.attr('aria-owns');
      var menuSelector = "#".concat(menuId, " ul");
      var menuItems = "#".concat(menuId, " ul li");
      if (this.get('searchEnabled')) {
        var $searchField = this.$('input[type="search"]');
        $searchField.attr('aria-autocomplete', 'list').removeAttr('aria-controls');
        this.$(menuSelector).removeAttr('aria-controls');
      } else {
        $buttonTrigger.attr('tabindex', '-1');
        this.$(menuSelector).attr('role', 'listbox').attr('tabindex', '0').removeAttr('aria-controls').focus();
        this.$(menuItems).attr('role', 'option');
      }
      this.set('keyboardActivated', true);
    },
    _onMenuSearchEnabledKeydown: function _onMenuSearchEnabledKeydown(event) {
      if (event.keyCode === 27) {
        // esc key
        event.stopPropagation();
        var redispatchEvent = new CustomEvent('keydown');
        var $searchField = this.$('input[type="search"]');
        redispatchEvent.keyCode = event.keyCode;
        $searchField[0].dispatchEvent(redispatchEvent);
      }
    },
    _onMenuKeydown: function _onMenuKeydown(event) {
      var $buttonTrigger = this.$('[role="button"]');
      var redispatchEvent = new CustomEvent('keydown');
      redispatchEvent.keyCode = event.keyCode;
      redispatchEvent.shiftKey = event.shiftKey;
      if (event.keyCode === 32) {
        // space bar
        event.preventDefault();
      } else if (event.keyCode === 9 && !event.shiftKey) {
        // tab
        this.set('hasPressedTabKey', true);
      } else if (event.keyCode === 9) {
        // shift + tab
        $buttonTrigger.attr('tabindex', '0');
      } else if (event.keyCode === 27) {
        // esc key
        event.stopPropagation();
      }
      if (event.keyCode === 9) {
        // Ensure default browser behavior activates first
        Ember.run.next(this, function () {
          return $buttonTrigger[0].dispatchEvent(redispatchEvent);
        });
      } else {
        $buttonTrigger[0].dispatchEvent(redispatchEvent);
      }
    },
    _removeMenuAriaListener: function _removeMenuAriaListener() {
      var menuId = this.$('[role="button"]').attr('aria-owns');
      this.$("#".concat(menuId, " ul")).off();
    },
    updateAriaActiveDescendant: function updateAriaActiveDescendant() {
      var $trigger = this.$('[role="button"]');
      var menuId = $trigger.attr('aria-owns');
      var $menuItems = this.$('ul li');
      var activeItemId = $menuItems.filter('[aria-current="true"]').first().attr('id');
      if (this.get('searchEnabled')) {
        this.$("#".concat(menuId, " input[type=\"search\"]")).attr('aria-activedescendant', activeItemId);
      } else {
        this.$("#".concat(menuId, " ul")).attr('aria-activedescendant', activeItemId);
      }
    },
    actions: {
      onTriggerKeydown: function onTriggerKeydown(data, event) {
        if (event.keyCode === 38 || event.keyCode === 40) {
          // up and down arrow
          Ember.run.next(this, this.updateAriaActiveDescendant);
        }
        return this._super.apply(this, arguments);
      },
      onChange: function onChange() {
        this.get('onchange').apply(void 0, arguments);
      },
      onInput: function onInput() {
        var _this = this;
        var readResultsCount = function readResultsCount() {
          var menuId = _this.$('[role="button"]').attr('aria-owns');
          var numResults = _this.$("#".concat(menuId, " ul")).children().length;
          var menu = _this.$("#".concat(menuId, " ul li")).first().attr('class');
          if (menu && menu.includes('no-matches-message')) {
            numResults = 0;
          }
          _this.set('numberOfResultsFound', numResults);
          _this.set('speakResultsFoundDebounce', (0, _runloop.debounce)(_this, _this._speakResultsFound, 350));
        };
        Ember.run.next(this, readResultsCount);
      },
      onClose: function onClose(data, event) {
        var $trigger = this.$('[role="button"]');

        // Tab key will set focus back to trigger
        if (this.get('searchEnabled') && !event.shiftKey && $trigger.length) {
          Ember.run.next(this, function () {
            return $trigger.focus();
          });
        }
      }
    },
    _speakResultsFound: function _speakResultsFound() {
      (0, _speak.default)("".concat(this.get('numberOfResultsFound'), " results found"));
    }
  });
});