define("sdk/core/resizable-component", ["exports", "sdk/core/component", "sdk/api/utils/runloop", "sdk/utils/keyboard-resize"], function (_exports, _component, _runloop, _keyboardResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    engagementLayoutManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    classNameBindings: ['resizable', 'resizable:nuance-resizable-component', 'resizableDisableFlex', 'isResized'],
    resizable: true,
    resizeMinHeight: 100,
    resizeHandles: 's',
    containment: 'parent',
    minWidth: 0,
    isResized: Ember.computed.bool('height'),
    isHorizontal: Ember.computed('resizeHandles', function () {
      return this.get('resizeHandles').includes('w') || this.get('resizeHandles').includes('e');
    }),
    isVertical: Ember.computed('resizeHandles', function () {
      return this.get('resizeHandles').includes('s') || this.get('resizeHandles').includes('n');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        _this.updateResizable();
        var inputHeight = _this.get('height');
        _this.$().css('height', inputHeight || '');
      });
    },
    updateResizable: function updateResizable(shouldRerender) {
      var _this2 = this;
      if (!this.get('resizable') || this.get('isRendered') && !shouldRerender || this.get('singleTile')) {
        if (this.$().resizable('instance') && !this.get('resizable')) {
          var handler = this.$().find('>.ui-resizable-handle');
          if (handler.length) {
            handler.remove();
            this.set('isRendered', false);
          }
        }
        return;
      }
      var handles = this.get('resizeHandles');
      this.set('isRendered', true);
      this.$().resizable({
        containment: this.get('containment'),
        handles: handles,
        minWidth: this.get('minWidth'),
        minHeight: this.get('resizeMinHeight'),
        start: function start(event, ui) {
          var $element = Ember.$(ui.element);
          _this2.set('_lastHeightChange', 0);
          var engagement = _this2.get('engagement');
          if (engagement && engagement.trigger && typeof engagement.trigger === 'function') {
            engagement.trigger('agentInteracted');
          }
          if (_this2.isVertical) {
            var maxHeight = _this2.calculateMaxHeight();
            $element.resizable('option', 'maxHeight', _this2.isHorizontal ? null : maxHeight);
          }
          var onResizeStart = _this2.get('onResizeStart');
          if (onResizeStart) onResizeStart.call(_this2, event, $element);
        },
        resize: function resize(event, ui) {
          var $element = Ember.$(ui.element);
          if (_this2.isVertical && !_this2.isHorizontal) {
            // overwrite unnecessary resizable styling with "auto"
            $element.css({
              width: 'auto',
              top: 'auto',
              bottom: 'auto'
            });
          }

          // throttle resize event to limit amount of events passed up
          var throttledOnResize = (0, _runloop.throttle)(_this2, _this2._onResize, event, ui, 200, false);
          _this2.set('_throttledOnResize', throttledOnResize);
        },
        stop: function stop(event, ui) {
          var $element = Ember.$(ui.element);
          _this2.set('height', ui.size.height);
          var onResizeStop = _this2.get('onResizeStop');
          if (onResizeStop) onResizeStop.call(_this2, event, $element);
        }
      });
      var elementHandle = this.element.querySelector(':scope > .ui-resizable-handle');
      elementHandle.setAttribute('aria-label', this.get('intl').t('a11y.resize-horizontal'));
      this._onHandleKeyDown = this._onHandleKeyDown || this.onHandleKeyDown.bind(this);
      elementHandle.removeEventListener('keydown', this._onHandleKeyDown);
      elementHandle.addEventListener('keydown', this._onHandleKeyDown);
      this._onHandleKeyUp = this._onHandleKeyUp || this.onHandleKeyUp.bind(this);
      elementHandle.removeEventListener('keyup', this._onHandleKeyUp);
      elementHandle.addEventListener('keyup', this._onHandleKeyUp);
    },
    calculateMaxHeight: function calculateMaxHeight() {
      if (this.isVertical) {
        var $element = this.$();
        var parentHeight = $element.parent().height();
        var siblingsMinHeight = getSiblingsMinHeight($element);
        var elementHeight = $element.height();
        var elementOuterHeight = $element.outerHeight(true);
        var elementPaddingBorderMarginHeight = elementOuterHeight - elementHeight;
        return parentHeight - siblingsMinHeight - elementPaddingBorderMarginHeight;
      }
    },
    onHandleKeyDown: function onHandleKeyDown(event) {
      var isArrowKey = ['ArrowUp', 'ArrowDown'].includes(event.key);
      if (isArrowKey) {
        if (!this.get('isKeyboardResizing')) {
          var onResizeStart = this.get('onResizeStart');
          if (onResizeStart) onResizeStart.call(this, event, this.$());
          this.set('maxHeight', this.calculateMaxHeight() || this.getMaxHeight(this.$()));
        }
        this.set('isKeyboardResizing', true);
        this.$().css({
          width: 'auto',
          top: 'auto',
          bottom: 'auto'
        });
        var onResizeWithKeyboard = this.get('onResizeWithKeyboard');
        onResizeWithKeyboard && onResizeWithKeyboard();
        var minHeight = this.get('resizeMinHeight');
        if (event.key === 'ArrowDown') {
          if (this.resizeHandles === 's') {
            if (this.$().outerHeight() + _keyboardResize.default > this.get('maxHeight')) return;
            this.set('height', this.$().outerHeight() + _keyboardResize.default);
          } else if (this.resizeHandles === 'n') {
            if (this.$().outerHeight() - _keyboardResize.default < minHeight) return;
            this.set('height', this.$().outerHeight() - _keyboardResize.default);
          }
        } else if (event.key === 'ArrowUp') {
          if (this.resizeHandles === 's') {
            if (this.$().outerHeight() - _keyboardResize.default < minHeight) return;
            this.set('height', this.$().outerHeight() - _keyboardResize.default);
          } else if (this.resizeHandles === 'n') {
            if (this.$().outerHeight() + _keyboardResize.default > this.get('maxHeight')) return;
            this.set('height', this.$().outerHeight() + _keyboardResize.default);
          }
        }
      }
    },
    onHandleKeyUp: function onHandleKeyUp(event) {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        this.set('isKeyboardResizing', false);
        var onResizeStop = this.get('onResizeStop');
        if (onResizeStop) onResizeStop.call(this, event, this.$());
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var elementHandle = this.element.querySelector(':scope > .ui-resizable-handle');
      elementHandle && elementHandle.removeEventListener('keydown', this.onHandleKeyDown);
      elementHandle && elementHandle.removeEventListener('keyup', this.onHandleKeyUp);
      if (this.$().resizable('instance')) {
        this.$().resizable('destroy');
        Ember.run.cancel(this.get('_throttledOnResize'));
      }
    },
    _onResize: function _onResize(event, ui) {
      var originalSize = ui.originalSize,
        size = ui.size;
      var totalHeightChange = size.height - originalSize.height;
      var heightChange = totalHeightChange - this.get('_lastHeightChange');
      this.set('_lastHeightChange', totalHeightChange);
      var resizeOptions = {
        heightChange: heightChange,
        totalHeightChange: totalHeightChange
      };
      this._triggerLayoutResizeEvent(resizeOptions);
      var onResize = this.get('onResize');
      if (typeof onResize === 'function') {
        onResize.call(this, ui, resizeOptions);
      }
    },
    /**
     * Use engagement-layout-manager to fire a component-specific resize trigger
     * @method _triggerLayoutResizeEvent
     * @property {Object} resizeOptions
     */
    _triggerLayoutResizeEvent: function _triggerLayoutResizeEvent() {
      var resizeOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // most resizable components are collapsable-tile components
      // to make "componentTitle" unique, we prepend "tileName" property
      // ex: collapsable-tile => static-transcript-collapsable-tile
      var tileName = this.get('tileName');
      var componentTitle = (tileName ? tileName + '-' : '') + this.get('componentName');
      this.get('engagementLayoutManager').onComponentResize(componentTitle, resizeOptions);
    }
  });
  function getSiblingsMinHeight($element) {
    var $siblingsExcludingLast = $element.siblings(':not(".ui-resizable-handle")').not(':last').not('.dragged');
    var siblingsExcludingLastCurrentHeight = $siblingsExcludingLast.outerHeight(true) || 0;
    var $lastSibling = $element.siblings(':not(".ui-resizable-handle")').not('.dragged').last();
    var lastSiblingMinHeight = parseInt($lastSibling.css('min-height'), 10);
    var lastSiblingHeight = $lastSibling.height();
    var lastSiblingOuterHeight = $lastSibling.outerHeight(true);
    var lastSiblingPaddingBorderMarginHeight = lastSiblingOuterHeight - lastSiblingHeight;
    lastSiblingMinHeight += lastSiblingPaddingBorderMarginHeight;
    return siblingsExcludingLastCurrentHeight + lastSiblingMinHeight;
  }
});