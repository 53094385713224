define("sdk/components/notification-pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7Ri6MSpa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,1]],null,{\"statements\":[[0,\"\\n  \"],[13,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"icon\"],[8],[1,[26,\"fa-icon\",[[22,[\"icon\"]]],[[\"pulse\"],[[22,[\"iconPulse\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"title\"],[10,\"data-test-error-pill-title\",\"\"],[8],[1,[20,\"title\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/notification-pill/template.hbs"
    }
  });
});