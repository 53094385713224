define("sdk/components/fold-toggle-arrow/component", ["exports", "sdk/components/fold-toggle-arrow/template", "sdk/core/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    tagName: 'span'
  }).reopenClass({
    positionalParams: ['expanded']
  });
});