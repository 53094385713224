define("sdk/components/logout-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KWkVSnVe",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"t\",[\"logout-button.logout\"],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/logout-button/template.hbs"
    }
  });
});