define("sdk/components/sdk-dispositions/component", ["exports", "sdk/core/component", "sdk/components/sdk-dispositions/template", "sdk/mixins/persisted-scroll-position", "sdk/api/utils/runloop"], function (_exports, _component, _template, _persistedScrollPosition, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Form consisting of categories and reasons of why the engagement has ended
   *
   * Dispositions can be either "single" or "multiple"
   * Single allows for only one reason in one category
   * Multiple allows for as many reasons in one or many categories
   *
   * @class Components.Dispositions
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend(_persistedScrollPosition.default, {
    layout: _template.default,
    componentName: 'dispositions',
    persistScrollElementSelectors: Object.freeze(['.dispositions__scroll-container', '.disposition-notes textarea']),
    scrollPositions: Ember.computed.alias('ui.scrollPositions'),
    preselectedDispositionSelectedCount: Ember.computed('engagement.preselectedDispositions.@each.selected', function () {
      var preselectedDispositions = this.get('engagement.preselectedDispositions');
      return preselectedDispositions.filter(function (preselectedDisposition) {
        return preselectedDisposition.selected;
      }).length;
    }),
    data: undefined,
    currentPage: 1,
    itemsPerPage: 20,
    totalPages: Ember.computed('ui.{currentFilter,filteredCategories}', 'data.categories', 'itemsPerPage', function () {
      var dispositions = this.get('ui.currentFilter') ? this.get('ui.filteredCategories') : this.get('data.categories');
      var itemsPerPage = this.get('itemsPerPage');
      return Math.ceil(dispositions.length / itemsPerPage);
    }),
    isFirstPage: Ember.computed('ui.{currentFilter,filteredCategories}', 'currentPage', function () {
      return this.get('currentPage') === 1;
    }),
    isLastPage: Ember.computed('ui.{currentFilter,filteredCategories}', 'currentPage', 'data.categories', 'itemsPerPage', function () {
      var currentPage = this.get('currentPage');
      var dispositions = this.get('ui.currentFilter') ? this.get('ui.filteredCategories') : this.get('data.categories');
      var itemsPerPage = this.get('itemsPerPage');
      var start = (currentPage - 1) * itemsPerPage;
      var end = start + itemsPerPage;
      return end >= dispositions.length;
    }),
    paginationDispostions: Ember.computed('ui.{currentFilter,filteredCategories}', 'data.categories', 'currentPage', 'itemsPerPage', function () {
      var dispositions = this.get('ui.currentFilter') ? this.get('ui.filteredCategories') : this.get('data.categories');
      var currentPage = this.get('currentPage');
      var itemsPerPage = this.get('itemsPerPage');
      var start = (currentPage - 1) * itemsPerPage;
      var end = start + itemsPerPage;
      return dispositions.slice(start, end);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('isModalView')) {
        this._setDisplayedCategories();
        this._expandCategoriesWithSelectionsAndNotes();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('data', this.get('dispositions') || {
        categories: []
      });
      this._setDisplayedCategories();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.hasMultiplePreselectedDispositions = this.get('dispositions.multiple') && this.get('engagement.preselectedDispositions.length') > 1;
      this.set('engagement.hasMultiplePreselectedDispositions', this.hasMultiplePreselectedDispositions);
    },
    multiple: Ember.computed.alias('engagement.settings.multipleDispositions'),
    selectedDispositions: Ember.computed.alias('engagement.selectedDispositions'),
    isVisibleNotes: Ember.computed.alias('ui.isVisibleNotes'),
    displayedCategories: Ember.computed.alias('ui.displayedCategories'),
    /**
     * Submit is disabled if no reason has been selected
     * @property isSubmitDisabled
     * @type boolean
     * @private
     */
    isSubmitDisabled: Ember.computed.not('isReasonSelected'),
    /**
     * Searches through allCategories and pushes matches
     * to filteredCategories array
     * @method _getFilteredCategories
     * @private
     */
    _getFilteredCategories: function _getFilteredCategories(filter) {
      var _this = this;
      var filteredCategories = [];
      this.get('dispositions.categories').forEach(function (category) {
        var filteredReasons = category.reasons.filter(function (reason) {
          return reason.get('label').toLowerCase().indexOf(filter) > -1;
        });
        if (filteredReasons.length) {
          var filteredCategory = Ember.Object.create(category);
          filteredCategory.reasons = filteredReasons;
          filteredCategories.push(filteredCategory);
        }
      });
      this.set('ui.filteredCategories', filteredCategories);
      this.set('displayedCategories', Ember.Object.create());
      this.set('currentPage', 1);
      filteredCategories.forEach(function (category) {
        _this.get('displayedCategories').toggleProperty(category.value);
      });
      this.set('ui.isSearchingDispositions', false);
    },
    actions: {
      updateSettingsProperty: function updateSettingsProperty(key, selected) {
        this.get('api.agent').updateSetting(key, selected);
      },
      decrementPagination: function decrementPagination() {
        var currentPage = this.get('currentPage');
        if (currentPage === 1) return;
        this.set('currentPage', currentPage - 1);
      },
      incrementPagination: function incrementPagination() {
        var currentPage = this.get('currentPage');
        var dispositions = this.get('dispositions');
        var itemsPerPage = this.get('itemsPerPage');
        var start = (currentPage - 1) * itemsPerPage;
        var end = start + itemsPerPage;
        if (end >= dispositions.length) return;
        this.set('currentPage', currentPage + 1);
      },
      handlePreselectedDispositionClick: function handlePreselectedDispositionClick(dispositionReason) {
        if (!this.get('dispositions.multiple') && dispositionReason.get('selected')) return;
        dispositionReason.toggleProperty('selected');
        if (dispositionReason.get('selected')) {
          this.dispositions.set('selectedReason', dispositionReason.get('value'));
        }
      },
      togglePreselectedDispositions: function togglePreselectedDispositions() {
        var toggledSelection = !this.preselectedDispositionSelectedCount;
        this.get('engagement.preselectedDispositions').forEach(function (preselectedDisposition) {
          return preselectedDisposition.set('selected', toggledSelection);
        });
      },
      onCategoryKeyDown: function onCategoryKeyDown() {
        this._manageUpDownKeyDispositionFocus(event);
      },
      getFilteredCategories: function getFilteredCategories(filter, event) {
        var ESCAPE = 27;
        if (event.keyCode === ESCAPE) return;
        filter = filter.toLowerCase().trim();
        if (this.get('ui.currentFilter') === filter) return;
        this.set('ui.currentFilter', filter);
        this.set('ui.filteredCategories', []);
        if (filter) {
          this.set('ui.isSearchingDispositions', true);
          (0, _runloop.debounce)(this, this._getFilteredCategories, filter, 300);
        }
      },
      clearFilter: function clearFilter() {
        this.set('ui.filteredCategories', []);
        this.set('ui.currentFilter', '');
        this.set('ui.filter', '');
      },
      toggleCategory: function toggleCategory(category) {
        this.get('displayedCategories').toggleProperty(category.value);
      },
      /**
       * User clicks the cancel button
       * @method cancel
       */
      cancel: function cancel() {
        var onCancel = this.get('onCancel');
        onCancel && onCancel();
      },
      /**
       * User clicks the submit button and passes selected reasons
       * @method submit
       */
      submit: function submit() {
        var onSubmit = this.get('onSubmit');
        onSubmit && onSubmit();
      },
      /**
       * Fetch dispositions whe user clicks the retry button
       * @method reloadDispositions
       */
      reloadDispositions: function reloadDispositions() {
        var _this2 = this;
        this.set('isLoadingDispositions', true);
        this.get('engagement').fetchDispositions().finally(function () {
          _this2.set('isLoadingDispositions', false);
        });
      }
    },
    /**
     * Pass any possible hotkey combo events
     * to the route.
     * TODO: refactor - these events should
     * bubble up to the route automatically
     * @method keyDown
     * @param {Object} event
     * @public
     */
    keyDown: function keyDown(event) {
      var handleKeyDown = this.get('handleKeyDown');
      handleKeyDown && handleKeyDown(event);
      this._manageTabKeyDispositionFocus(event);
    },
    /**
     * Hide the hotkey dropdown if user is typing
     * in here and not entering a hotkey combo.
     * TODO: refactor - these events should
     * bubble up to the route automatically
     * @method keyPress
     * @param {Object} event
     * @public
     */
    keyPress: function keyPress() {
      var resetHotkey = this.get('resetHotkey');
      resetHotkey && resetHotkey(event);
    },
    /**
     * Checks the categories to see if any of them contain a selected
     * disposition. If so, they are expanded. If any notes have been entered,
     * the notes will be displayed as well.
     * @method __expandCategoriesWithSelectionsAndNotes
     * @private
     */
    _expandCategoriesWithSelectionsAndNotes: function _expandCategoriesWithSelectionsAndNotes() {
      var _this3 = this;
      var dispositions = this.get('dispositions');
      var displayedCategories = this.get('displayedCategories');
      Object.keys(displayedCategories).forEach(function (categoryValue) {
        var category = dispositions.categories.findBy('value', categoryValue);
        var hasSelectedItems = category.reasons && category.reasons.selectedItems && !!category.reasons.selectedItems.content.length;
        _this3.set("displayedCategories.".concat(category.value), hasSelectedItems);
      });
      if (this.get('dispositions.notes')) {
        this.set('isVisibleNotes', true);
      }
    },
    /**
     * Manage tab focus for single disposition radio buttons
     * @method _manageTabKeyDispositionFocus
     * @param {Object} event
     * @private
     */
    _manageTabKeyDispositionFocus: function _manageTabKeyDispositionFocus(event) {
      // Only enable for single disposition radio options
      if (this.get('dispositions.multiple')) return;
      var isTabKey = event.keyCode === 9;
      if (!isTabKey) return;
      var isShiftKeyActive = event.shiftKey;
      var activeElement = document.activeElement;
      var $categoryButtons = this.$('[id^="disposition-section-button-"]');
      var categoryIndex = $categoryButtons.index(activeElement);
      var buttonSelector = "[id^=\"disposition-section-button-".concat(categoryIndex, "\"]");
      var isButtonExpanded = this.$(buttonSelector).attr('aria-expanded') === 'true';

      // Prevent default browser behavior for the Tab key however ignore the following conditions:
      // 1. focus is not on a category button
      // 2. pressing Shift+Tab on the first button
      // 3. pressing Tab on the last button if it isn't expanded
      if (categoryIndex < 0 || categoryIndex === 0 && isShiftKeyActive || categoryIndex === $categoryButtons.length - 1 && !isShiftKeyActive && !isButtonExpanded) return;
      event.preventDefault();
      var nextCategoryIndex = isShiftKeyActive ? categoryIndex - 1 : categoryIndex + 1;
      var nextButtonSelector = "[id^=\"disposition-section-button-".concat(nextCategoryIndex, "\"]");
      var $nextButton = this.$(nextButtonSelector);
      var isNextButtonExpanded = $nextButton.attr('aria-expanded') === 'true';

      // if the next focus category button is collapsed, focus the button.
      if (!isButtonExpanded && !isShiftKeyActive || !isNextButtonExpanded && isShiftKeyActive) {
        $nextButton.focus();
        return;
      }
      var selector;
      if (isShiftKeyActive) {
        selector = "".concat(nextButtonSelector, " + ul li input");
      } else {
        selector = "#".concat(activeElement.id, " + ul li input");
      }
      var $checkedNode = this.$("".concat(selector, ":checked"));

      // if there is a checked node, focus that node. If not, focus the first input
      // in the category.
      if ($checkedNode.length) {
        $checkedNode.focus();
      } else {
        this.$(selector).first().focus();
      }
    },
    /**
     * Manage up and down arrow key focus for disposition category radio options. This is
     * used for keyboard access for non-screen reader users.
     * @method _manageUpDownKeyDispositionFocus
     * @param {Object} event
     * @private
     */
    _manageUpDownKeyDispositionFocus: function _manageUpDownKeyDispositionFocus(event) {
      // Only enable for single disposition radio options
      if (this.get('dispositions.multiple')) return;
      var isUpKey = event.keyCode === 38;
      var isDownKey = event.keyCode === 40;
      if (!isUpKey && !isDownKey) return;
      var $radioButtons = this.$().find('ul.show input[type="radio"]');
      var activeRadioButtonIndex = $radioButtons.index(event.target);
      var numberOfRadioButtons = $radioButtons.length;

      // Prevent browser default behavior and manage focus when focus is on a radio button.
      if (activeRadioButtonIndex === -1) return;
      event.preventDefault();

      // Conditions move focus in a circular loop through the radio options when the
      // up / down keys are pressed. The up arrow at the 0 index will move focus to the
      // last option, and vice versa.
      if (activeRadioButtonIndex === 0 && isUpKey) {
        $radioButtons.last().focus();
      } else if (isUpKey) {
        $radioButtons.eq(activeRadioButtonIndex - 1).focus();
      } else if (activeRadioButtonIndex >= numberOfRadioButtons - 1 && isDownKey) {
        $radioButtons.first().focus();
      } else if (isDownKey) {
        $radioButtons.eq(activeRadioButtonIndex + 1).focus();
      }
    },
    _setDisplayedCategories: function _setDisplayedCategories() {
      if (!this.get('displayedCategories')) {
        this.set('displayedCategories', Ember.Object.create());
      }
    }
  }).reopenClass({
    positionalParams: ['dispositions']
  });
});