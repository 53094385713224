define("sdk/utils/mask-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = maskText;
  /**
   * Get masked text value based on selectedTextOptions
   * @method _maskText
   * @param {Object} selectedTextOptions object returned by getSelectedData in text-selection mixin
   * @param {Number} index the index of the selected text in maskedText or messageText
   * @param {Number} selectedLength length of the selected text
   * @private
   */
  function maskText() {
    var selectedTextOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var index = selectedTextOptions.index;
    var previousMaskedText = selectedTextOptions.previousMaskedText,
      selectedLength = selectedTextOptions.selectedLength;

    // add left space count to index since messageText rendered is trimmed
    var leftSpaces = /^\s+/.exec(previousMaskedText);
    var leftSpaceCount = leftSpaces ? leftSpaces[0].length : 0;
    if (leftSpaceCount) {
      index += leftSpaceCount;
    }
    var maskedText = previousMaskedText.slice(0, index)
    // replace all selected text that are not spaces, parentheses or hyphens with 'X'
    + previousMaskedText.slice(index, index + selectedLength).replace(/[^\s()-]/g, 'X') + previousMaskedText.slice(index + selectedLength);
    return maskedText;
  }
});