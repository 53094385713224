define("sdk/components/fold-toggle-arrow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UNVh5RSN",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"fa-icon\",[[26,\"if\",[[22,[\"expanded\"]],\"caret-down\",\"caret-right\"],null]],null],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/fold-toggle-arrow/template.hbs"
    }
  });
});