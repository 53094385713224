define("sdk/components/static-transcript-window/transcript/component", ["exports", "sdk/core/component", "sdk/components/static-transcript-window/transcript/template", "sdk/mixins/component-instance-manager", "sdk/helpers/is-from-current-engagement"], function (_exports, _component, _template, _componentInstanceManager, _isFromCurrentEngagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_componentInstanceManager.default, {
    layout: _template.default,
    componentName: 'static-transcript-window-transcript',
    'data-test-transcript-window': 'static',
    messages: Ember.computed('engagement', 'engagement.messages.[]', function () {
      return this.get('engagement.messages').compact();
    }),
    transcriptID: Ember.computed.alias('engagement.ui.lastSelectedTranscriptID'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this._transcriptIDCache !== this.transcriptID) {
        this._transcriptIDCache = this.transcriptID;
        this.set('initialTranscriptLineFocusIndex', -1);
        var hasListRenderOptions = !!this.engagement.ui.staticTranscriptListRenderOptions;
        if (!hasListRenderOptions) {
          this.set('engagement.ui.staticTranscriptListRenderOptions', Ember.Object.create());
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.notifyPropertyChange('filter.length');
    },
    actions: {
      /**
       * Destroy all static transcript line component instances before new list is rendered
       * @method destroyAllStaticTranscriptLineComponentInstances
       */
      destroyAllStaticTranscriptLineComponentInstances: function destroyAllStaticTranscriptLineComponentInstances() {
        this.destroyAllComponentInstances();
      },
      /**
       * Destroy single static transcript line component instance
       * after the component item has been derendered or replaced
       * @method destroyStaticTranscriptLineComponentInstance
       */
      destroyStaticTranscriptLineComponentInstance: function destroyStaticTranscriptLineComponentInstance(componentElement) {
        this.destroyComponentInstanceByElement(componentElement);
      },
      focusListRender: function focusListRender() {
        this.element.querySelector('.mnstr').focus();
      },
      getStaticTranscriptLineElement: function getStaticTranscriptLineElement(transcriptLine, attributes) {
        if (!(0, _isFromCurrentEngagement.isFromCurrentEngagement)([transcriptLine, this.engagement])) return document.createElement('div');
        return this._createStaticTranscriptLineComponentElement(transcriptLine, attributes);
      },
      onMaskedTranscriptLine: function onMaskedTranscriptLine(transcriptLine) {
        this.get('api').trigger('agentMaskedText', this.get('engagement'));

        // If the text being masked is internal, we will need to prepend "(internal only) "
        // because we previously remove "(internal only) " from messageText property
        var _transcriptLine$getPr = transcriptLine.getProperties('isInternal', 'maskedText', 'originalMessageText'),
          isInternal = _transcriptLine$getPr.isInternal,
          maskedText = _transcriptLine$getPr.maskedText,
          originalMessageText = _transcriptLine$getPr.originalMessageText;
        var remoteMaskedText = isInternal ? "(internal only) ".concat(maskedText) : maskedText;
        return this.get('api.adapter').maskText(this.get('engagement.id'), originalMessageText, remoteMaskedText);
      },
      onTranscriptLineCellsUpdated: function onTranscriptLineCellsUpdated() {
        this.onTranscriptLinesRendered && this.onTranscriptLinesRendered();
      }
    },
    /**
     * Manually create an static-transcript-window/line component instance and return it's element
     * @method _createStaticTranscriptLineComponentElement
     */
    _createStaticTranscriptLineComponentElement: function _createStaticTranscriptLineComponentElement(transcriptLine, attributes) {
      var transcriptLineObject = Object.assign({
        line: transcriptLine,
        engagement: this.get('engagement'),
        enableContextMenuMasking: !this.get('engagement.settings.disableAgentMaskingAbility'),
        onMask: this.actions.onMaskedTranscriptLine.bind(this)
      }, attributes);
      return this.createComponentInstanceElement('static-transcript-window/line', transcriptLineObject);
    }
  });
});