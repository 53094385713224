define("sdk/mixins/arrow-key-navigation", ["exports", "sdk/utils/is-element-tabbable", "sdk/utils/dynamic-operations"], function (_exports, _isElementTabbable, _dynamicOperations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    // Note: container element should have attribute 'role="application"'
    // to support arrow keys setting focus as well as navigating in JAWS
    // TODO: Create a hook for this functionality while agent-coach migration ticket
    keyDown: function keyDown(event) {
      var _this = this;
      this._super.apply(this, arguments);
      switch (event.keyCode) {
        case 38:
          {
            // Up Arrow
            event.preventDefault();
            this.focusElement({
              operator: '-'
            });
            break;
          }
        case 40:
          {
            // Down Arrow
            event.preventDefault();
            this.focusElement({
              operator: '+'
            });
            break;
          }
        case 9:
          {
            // Tab
            setTimeout(function () {
              _this._lastActiveElementIndex = _this._getCurrentlyActiveElementIndex().currentlyActiveElementIndex;
            });
            break;
          }
      }
    },
    focusElement: function focusElement(_ref) {
      var operator = _ref.operator;
      var _this$_getCurrentlyAc = this._getCurrentlyActiveElementIndex(),
        currentlyActiveElementIndex = _this$_getCurrentlyAc.currentlyActiveElementIndex,
        traversableElements = _this$_getCurrentlyAc.traversableElements;
      var targetElement = this._getTargetElement({
        currentlyActiveElementIndex: currentlyActiveElementIndex,
        operator: operator,
        traversableElements: traversableElements
      });
      targetElement && targetElement.focus();
    },
    /**
     * Returns the next element to be focused.
     * @param {*} param0
     * @returns
     */
    _getTargetElement: function _getTargetElement(_ref2) {
      var currentlyActiveElementIndex = _ref2.currentlyActiveElementIndex,
        operator = _ref2.operator,
        traversableElements = _ref2.traversableElements;
      var targetElement;
      /**
       * If currentlyActiveElementIndex is -1 (e.g. user navigating internal elements using tab,
       * click, etc.), then focus the element based on previously focused element.
       */
      if (currentlyActiveElementIndex === -1) {
        var _index = _dynamicOperations.default[operator]({
          operand1: this._lastActiveElementIndex,
          operand2: 1
        });
        var targetIndex = traversableElements[_index] ? _index : 0;
        targetElement = traversableElements[targetIndex];
        this._lastActiveElementIndex = targetIndex;
        return targetElement;
      }

      /**
       * Steps to perform a forward/backward element array traversal to reach the next tabbable
       * element.
       */
      var step = 1;
      var nextElement;
      var index = 0;
      /**
       * Find next scoped traversable element which can be tabbed.
       */
      do {
        index = _dynamicOperations.default[operator]({
          operand1: currentlyActiveElementIndex,
          operand2: step
        });
        nextElement = traversableElements[index];
        ++step;
      } while (nextElement && !(0, _isElementTabbable.default)(nextElement));
      this._lastActiveElementIndex = index;
      return nextElement;
    },
    _getCurrentlyActiveElementIndex: function _getCurrentlyActiveElementIndex() {
      var arrowKeyElementSelectors = this.get('arrowKeyElementSelectors');
      var selectors = this.$(arrowKeyElementSelectors.join(','));
      var traversableElements = this.$(selectors);
      var activeElement = document.activeElement;
      var currentlyActiveElementIndex = Array.from(traversableElements).findIndex(function (element) {
        return element === activeElement;
      });
      return {
        currentlyActiveElementIndex: currentlyActiveElementIndex,
        traversableElements: traversableElements
      };
    },
    _lastActiveElementIndex: 0
  });
});