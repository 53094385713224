define("sdk/api/adapters/cheetah-adapter", ["exports", "moment", "ember-ajax/raw"], function (_exports, _moment, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class CheetahAdapter
   * @public
   */
  var _default = _exports.default = Ember.Object.extend({
    name: 'CHEETAH',
    getRecommendations: function getRecommendations(url) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      url = url || 'https://webapi-cheetah.nuance.mobi/webapi-platform/http/bofa_agentcoach/recommendation_service/get_answers';
      var siteId = options.siteId,
        businessUnitName = options.businessUnitName,
        agentGroupName = options.agentGroupName,
        engagementId = options.engagementId,
        namedEntities = options.namedEntities,
        dialog = options.dialog,
        is503Retry = options.is503Retry;

      // window.agentCoachSessionUUID is used by QA to mock recommendation service response
      var sessionUuid = window.agentCoachSessionUUID || engagementId;
      var data = {
        version: '1.0',
        sessionUuid: sessionUuid,
        clientId: siteId,
        businessUnitId: businessUnitName,
        agentGroupId: agentGroupName,
        agentId: this.get('api.agent.userId'),
        recommendation_engine: 'QuickChat',
        diagnostic_level: 'FULL',
        context: {
          localDateTime: (0, _moment.default)().format('YYYY-MM-DD HH:mm:ss'),
          locale: this.get('api.agent.settings.language').replace('_', '-'),
          namedEntities: namedEntities,
          dialog: dialog
        }
      };
      return this.ajax(url, {
        method: 'post',
        data: data,
        query: {
          tenantId: siteId
        }
      }).then(function (_ref) {
        var response = _ref.response;
        var errorMessage = response.errorMessage;
        if (errorMessage) throw errorMessage;
        return response;
      }).catch(function (error) {
        var jqXHR = error.jqXHR;

        // automatically resend request on first 503 error
        if (jqXHR && jqXHR.status === 503 && !is503Retry) {
          options.is503Retry = true;
          return _this.getRecommendations(url, options);
        }
        throw error;
      });
    },
    sendEvent: function sendEvent(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      url = url || 'https://webapi-cheetah.nuance.mobi/webapi-platform/http/bofa_agentcoach/logging_service/push_log';
      var _options$engagementID = options.engagementID,
        engagementID = _options$engagementID === void 0 ? '' : _options$engagementID,
        siteID = options.siteID;
      var eventData = {
        clientApplicationId: 'agent-desktop',
        clientApplicationVersion: '1.0.0',
        clientSessionId: engagementID,
        applicationPayload: JSON.stringify(Object.assign({
          applicationID: 'NDEP_HTMLAI',
          // Required: String
          agentID: this.get('api.agent.userId'),
          // Optional: String
          recommendationFilteringEnabled: this.get('api.agent.settings.filterAsIType') // Required: Boolean
        }, options))
      };
      return this.ajax(url, {
        method: 'post',
        query: {
          tenantId: siteID
        },
        data: eventData,
        dataType: 'text'
      });
    },
    ajax: function ajax(url) {
      var _this2 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var authPromise = this.api.authAdapter.ensureValidAuthToken();
      return authPromise.then(function () {
        return _this2._ajax(url, options);
      });
    },
    _ajax: function _ajax(url) {
      var _this3 = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options.method = (options.method || 'GET').toUpperCase();
      var siteId;
      var businessUnitName;
      var agentGroupName;
      if (options.query) {
        url += '?' + Ember.$.param(options.query);
        delete options.query;
      }
      if (options.data && options.data.applicationPayload) {
        try {
          var parsedApplicationPayload = JSON.parse(options.data.applicationPayload);
          siteId = parsedApplicationPayload.siteID;
          businessUnitName = parsedApplicationPayload.businessUnitName;
          agentGroupName = parsedApplicationPayload.agentGroupName;
        } catch (error) {/*noop*/}
      } else {
        siteId = options.data && options.data.clientId;
        businessUnitName = options.data && options.data.businessUnitId;
        agentGroupName = options.data && options.data.agentGroupId;
      }
      if (options.method === 'POST') {
        options.data = JSON.stringify(options.data);
      }
      Ember.assign(options, {
        contentType: 'application/json',
        crossDomain: true,
        headers: {
          Authorization: this.get('api.authAdapter.authorizationHeader')
        }
      });
      return (0, _raw.default)(url, options).catch(function (error) {
        var jqXHR = error.jqXHR;
        var eventData = {
          event: 'cheetah-service-failure',
          event_group: 'api-error',
          site_id: siteId,
          business_unit: businessUnitName,
          agent_group: agentGroupName,
          request_url: url,
          message: error.response && error.response.message,
          status_code: jqXHR.status,
          status_text: jqXHR && jqXHR.statusText,
          method: options.method
        };
        _this3.api.logging.sendLimitedErrorEvent(eventData);
        throw error;
      });
    }
  });
});