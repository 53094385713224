define("sdk/components/widget-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OgMa2b8h",
    "block": "{\"symbols\":[],\"statements\":[[6,\"img\"],[11,\"src\",[20,\"src\"],null],[11,\"alt\",[20,\"alt\"],null],[10,\"data-test-widget-thumbnail\",\"\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"iconName\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"widget-image__tag\"],[8],[0,\"\\n    \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[[22,[\"iconName\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/widget-image/template.hbs"
    }
  });
});