define("sdk/agent-group-filter/controller", ["exports", "sdk/utils/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    sites: Ember.computed.alias('model.sites'),
    username: Ember.computed.alias('api.agent.userId'),
    session: Ember.inject.service(),
    agentGroups: Ember.computed('sites', function () {
      var siteAgentGroups = this.get('sites').mapBy('agentGroups');
      return (0, _array.flatten)(siteAgentGroups);
    }),
    selectedAgentGroups: Ember.computed.filterBy('agentGroups', 'isSelected'),
    login: function login() {
      var _this = this;
      var selectedAgentGroupIds = this.get('selectedAgentGroups').mapBy('id');
      if (!selectedAgentGroupIds.length) {
        return;
      }
      var agentLoginOptions = {
        agentGroupIds: selectedAgentGroupIds
      };
      this.set('api.agent.agentGroupIds', selectedAgentGroupIds);
      return this.get('session').authenticate('authenticator:tc', {
        username: this.get('username'),
        agentLoginOptions: agentLoginOptions
      }).catch(function (error) {
        return _this.set('errorMessage', error.payload && error.payload.detail);
      });
    },
    updateSelectAll: function updateSelectAll() {
      var allChecked = true;
      this.get('agentGroups').forEach(function (agentGroup) {
        if (!agentGroup.isSelected) {
          allChecked = false;
          return;
        }
      });
      this.set('areAllSelected', allChecked);
    },
    actions: {
      login: function login() {
        this.login();
      },
      toggleAgentGroupIsSelected: function toggleAgentGroupIsSelected(agentGroup) {
        event.stopPropagation();
        var toggleCheckValue = !agentGroup.isSelected;
        Ember.set(agentGroup, 'isSelected', toggleCheckValue);
        this.updateSelectAll();
      },
      selectAll: function selectAll(event, allChecked) {
        this.get('sites').forEach(function (site) {
          site.agentGroups.setEach('isSelected', allChecked);
        });
        this.set('areAllSelected', allChecked);
      }
    }
  });
});