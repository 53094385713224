define("sdk/api/utils/dispositions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatPreselectedDispositionsResponse = _exports.findValidPreselectedDispositionsInAsyncSession = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var formatPreselectedDispositionsResponse = _exports.formatPreselectedDispositionsResponse = function formatPreselectedDispositionsResponse(allPreselectedDispositionsInConversation) {
    return _toConsumableArray(allPreselectedDispositionsInConversation).reverse().map(function (dispositions) {
      return dispositions.dispositions.reduce(function (dispositionMap, disposition) {
        if (dispositionMap[disposition.category]) {
          dispositionMap[disposition.category].reasons.push(disposition.reason);
        } else {
          dispositionMap[disposition.category] = {
            category: disposition.category,
            reasons: [disposition.reason]
          };
        }
        return dispositionMap;
      }, {});
    }).map(function (disposition) {
      return Object.values(disposition);
    });
  };
  var validateDispositionCategory = function validateDispositionCategory(categoryName, defaultDispositions) {
    var matchingDefaultDisposition = defaultDispositions.categories.find(function (category) {
      return category.label === categoryName;
    });
    return {
      isCategoryValid: !!matchingDefaultDisposition,
      matchingDefaultDisposition: matchingDefaultDisposition
    };
  };
  var validateDispositionReasons = function validateDispositionReasons(dispositionsReasons, matchingDefaultDispositionReasons) {
    var verifiedPreselectedDispositionReasons = dispositionsReasons.map(function (dispositionsReason) {
      return matchingDefaultDispositionReasons.find(function (matchingDefaultReason) {
        return matchingDefaultReason.get('label') === dispositionsReason;
      });
    }).filter(function (reason) {
      return reason;
    });
    return {
      isEachDispositionReasonValid: dispositionsReasons.length === verifiedPreselectedDispositionReasons.length,
      verifiedPreselectedDispositionReasons: verifiedPreselectedDispositionReasons
    };
  };
  var findValidPreselectedDispositionsInAsyncSession = _exports.findValidPreselectedDispositionsInAsyncSession = function findValidPreselectedDispositionsInAsyncSession(_ref) {
    var preselectedDispositionsInAsyncSession = _ref.preselectedDispositionsInAsyncSession,
      defaultDispositions = _ref.defaultDispositions;
    var matchingPreselectedDispositionsReasons = new Set();
    var _iterator = _createForOfIteratorHelper(preselectedDispositionsInAsyncSession),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var preselectedDisposition = _step.value;
        var _validateDispositionC = validateDispositionCategory(preselectedDisposition.category, defaultDispositions),
          isCategoryValid = _validateDispositionC.isCategoryValid,
          matchingDefaultDisposition = _validateDispositionC.matchingDefaultDisposition;
        if (!isCategoryValid) return [];
        var _validateDispositionR = validateDispositionReasons(preselectedDisposition.reasons, matchingDefaultDisposition.reasons),
          isEachDispositionReasonValid = _validateDispositionR.isEachDispositionReasonValid,
          verifiedPreselectedDispositionReasons = _validateDispositionR.verifiedPreselectedDispositionReasons;
        if (!isEachDispositionReasonValid) return [];
        matchingPreselectedDispositionsReasons = new Set([].concat(_toConsumableArray(matchingPreselectedDispositionsReasons), _toConsumableArray(verifiedPreselectedDispositionReasons)));
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    return Array.from(matchingPreselectedDispositionsReasons);
  };
});