define("sdk/templates/layouts/-chat-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K4MyU80e",
    "block": "{\"symbols\":[],\"statements\":[[1,[26,\"video-chat\",null,[[\"engagement\",\"api\",\"onDidInsertElement\",\"onClose\"],[[22,[\"engagement\"]],[22,[\"api\"]],[26,\"action\",[[21,0,[]],\"onRenderVideoChat\"],null],[26,\"action\",[[21,0,[]],\"close\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/layouts/-chat-video.hbs"
    }
  });
});