define("sdk/site/nina-coach-training/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ninaInstances: Ember.computed.alias('api.settings.ninaCoachTrainingTabs'),
    hasMultipleNinaInstances: Ember.computed.gt('ninaInstances.length', 1),
    init: function init() {
      this._super.apply(this, arguments);
      this._resetNinaInstance();
      var defaultNinaInstance = this.get('ninaInstances.firstObject');
      this.set('selectedNinaInstance', defaultNinaInstance);
      if (!defaultNinaInstance) return;
      this._retrieveNinaMetaInfo(defaultNinaInstance.automatonId);
    },
    actions: {
      selectNinaInstance: function selectNinaInstance(ninaInstance) {
        this.set('selectedNinaInstance', ninaInstance);
        var automatonId = ninaInstance.automatonId;
        return this._retrieveNinaMetaInfo(automatonId);
      }
    },
    _retrieveNinaMetaInfo: function _retrieveNinaMetaInfo(automatonId) {
      var _this = this;
      this.set('errorMessage', null);
      this._resetNinaInstance();
      return this.get('api.adapter').ninaCoachContentMetaInfo({
        automatonId: automatonId
      }).then(function (_ref) {
        var intents = _ref.intents,
          concepts = _ref.concepts;
        _this.set('ninaInstance.nina', {
          intents: intents,
          concepts: concepts
        });
      }).catch(function (error) {
        var code = error.code,
          detail = error.detail;
        if (code === 'nina-coach-error') {
          _this.set('errorMessage', detail);
        } else {
          throw error;
        }
      });
    },
    _resetNinaInstance: function _resetNinaInstance() {
      this.set('ninaInstance', Ember.Object.create({
        nina: null,
        ui: {
          selectedNinaTypeFilters: []
        }
      }));
    }
  });
});