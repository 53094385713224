define("sdk/api/models/transfer-options", ["exports", "sdk/utils/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this.set('agentAttributes', []);
      this.set('selectedAttributes', []);
      this.set('agentAttrs', []);
      this.set('availableSlots', 0);
      this.set('transferSlots', 0);
      this.set('conferenceSlots', 0);
      this.set('prioritizeRequest', false);
      var businessUnits = this.get('_transferOptions.businessUnits');
      if (!businessUnits) return;
      this.set('businessUnitData', this._normalizeBusinessUnitData(businessUnits));
      var bUnitNames = businessUnits.map(function (businessUnit) {
        var displayName = businessUnit.displayName,
          name = businessUnit.name;
        return displayName ? displayName : name;
      });
      this.set('businessUnits', bUnitNames);
      this._populateAgentAttrsArray();
      this.set('hasAgentGroups', !!businessUnits.filter(function (bUnit) {
        return bUnit.agentGroups;
      }).length);
    },
    hasAttributesSelected: Ember.computed.gt('selectedAttributes.length', 0),
    /**
     * Whether or not target agent can be specified.
     * @property {Boolean} disableAgentSelection
     */
    disableAgentSelection: Ember.computed('selectedBusinessUnit', 'selectedAgentGroup', function () {
      var selectedBusinessUnit = this.get('selectedBusinessUnit');
      var selectedAgentGroup = this.get('selectedAgentGroup.name');
      if (!selectedBusinessUnit) return true;
      if (selectedAgentGroup) {
        var selectedAgentGroupData = this.getAgentGroupDataByName(selectedAgentGroup, selectedBusinessUnit);
        return selectedAgentGroupData.disableAgentSelection;
      }
      var selectedBusinessUnitData = this.getBusinessUnitDataByName(selectedBusinessUnit);
      return selectedBusinessUnitData.disableAgentSelection;
    }),
    /**
     * A mapping of keys and values for the selected agent attributes.
     * Used for displaying selected values in the transfer-engagement component.
     * @property {Object} selectedAttributesMap
     */
    selectedAttributesMap: Ember.computed('selectedAttributes.[]', function () {
      var selectedAttributes = this.get('selectedAttributes');
      var selectedAttrsMap = Ember.Object.create();
      if (!selectedAttributes.length) return selectedAttrsMap;
      selectedAttributes.forEach(function (attribute) {
        selectedAttrsMap.set(attribute.name, attribute.displayValue);
      });
      return selectedAttrsMap;
    }),
    /**
     * Updates values, which are related to currently available agents.
     */
    availableAgentsChanged: Ember.observer('availableAgents', function () {
      this.set('selectedAgent', null);
    }),
    /**
     * Creates an array of all available agents with
     * their respective agent attributes. Also sets the
     * agents array.
     * @method _populateAgentAttrsArray
     * @private
     */
    _populateAgentAttrsArray: function _populateAgentAttrsArray() {
      var _this = this;
      var agents = [];
      var agentAttributes = [];
      var bUnits = this.get('businessUnitData');
      bUnits.forEach(function (bUnit) {
        if (bUnit.agentGroups) {
          bUnit.agentGroups.forEach(function (agentGroup) {
            if (agentGroup.agents) {
              agents.addObjects(agentGroup.agents);
            }
            if (agentGroup.agentAttributes) {
              var aGroupAttributes = _this._normalizeAttributes(agentGroup.agentAttributes);
              agentAttributes.addObjects(aGroupAttributes);
              aGroupAttributes.forEach(function (attribute) {
                if (attribute.agents) {
                  agents.addObjects(attribute.agents);
                }
              });
            }
          });
        } else {
          if (bUnit.agents) {
            agents.addObjects(bUnit.agents);
          }
          if (bUnit.agentAttributes) {
            var bUnitAttributes = _this._normalizeAttributes(bUnit.agentAttributes);
            agentAttributes.addObjects(bUnitAttributes);
            bUnitAttributes.forEach(function (attribute) {
              if (attribute.agents) {
                agents.addObjects(attribute.agents);
              }
            });
          }
        }
      });
      agents = agents.uniqBy('id');
      this.set('agents', agents);
      if (!agentAttributes.length) return;
      var agentAttrs = this.get('agentAttrs');
      agents.forEach(function (agent) {
        if (!agentAttrs.findBy('id', agent.id)) {
          agentAttrs.addObject({
            id: agent.id,
            attributes: {}
          });
        }
      });
      agentAttributes.forEach(function (attrs) {
        var name = attrs.name;
        attrs.agents.forEach(function (agent) {
          var mappedAgent = agentAttrs.findBy('id', agent.id);
          if (!mappedAgent.attributes[name]) {
            mappedAgent.attributes[name] = [attrs.value];
          } else {
            mappedAgent.attributes[name].push(attrs.value);
          }
        });
      });
    },
    /**
     * Add new property "displayValue" to agent attributes
     * ex: "Technical%20Support" => "Technical Support"
     * @method _normalizeAttributes
     * @param {Array} agentAttributes
     */
    _normalizeAttributes: function _normalizeAttributes(agentAttributes) {
      return agentAttributes.map(function (attributes) {
        var value = attributes.value;
        attributes.displayValue = decodeURIComponent(value);
        return attributes;
      });
    },
    /**
     * Returns all IDs (or full names) of agents which have the given agent attribute.
     * @method _getAvailableAgents
     * @private
     * @param {String} name name of an agent attribute
     * @return {Array} an array of all agent IDs (or full names) that have the {name} agent attribute
     */
    _getAvailableAgents: function _getAvailableAgents(name) {
      var _this2 = this;
      var hasAttributesSelected = this.get('hasAttributesSelected');
      var selectedAttributes = this.get('selectedAttributes');
      var agentAttrs = this.get('agentAttrs');
      var availableAgents = [];
      if (!hasAttributesSelected) return agentAttrs.mapBy('id').map(this.getAgentByNameOrId.bind(this));
      agentAttrs.forEach(function (agent) {
        var hasSelectedAttrs = true;
        selectedAttributes.forEach(function (attr) {
          var selectedValue = attr.value;
          var agentAttrValues = agent.attributes[attr.name];
          var agentHasSelectedValue = agentAttrValues && agentAttrValues.indexOf(selectedValue) > -1;
          if (!agentHasSelectedValue) {
            hasSelectedAttrs = false;
          }
        });
        if (hasSelectedAttrs && agent.attributes[name]) {
          var availableAgent = _this2.getAgentByNameOrId(agent.id);
          if (availableAgent) {
            availableAgents.push(availableAgent);
          }
        }
      });
      return availableAgents;
    },
    /**
     * Filters the availableAgents array to contain only
     * agents which have the selected agent attributes.
     * @method _filterAgentAttributes
     * @private
     */
    _filterAgentAttributes: function _filterAgentAttributes() {
      var _this3 = this;
      var agentAttributes = this.get('agentAttributes');
      var availableAgents = [];
      agentAttributes.forEach(function (attr) {
        var agents = _this3._getAvailableAgents(attr.name);
        agents.forEach(function (agent) {
          agent && availableAgents.push(agent);
        });
        attr.set('hasAgent', agents.length > 0);
      });
      availableAgents = availableAgents.uniqBy('id');
      this._setAvailableAgents(availableAgents);
    },
    /**
     * Sets the number of slots displayed to be equal to
     * the currently selected business unit's slots
     * only fires if site does not use agent groups
     * @method _onBUSelectedUpdateSlots
     * @private
     */
    _onBUSelectedUpdateSlots: function _onBUSelectedUpdateSlots() {
      var businessUnits = this.get('businessUnitData');
      var selectedBusinessUnitName = this.get('selectedBusinessUnit');
      var selectedBusinessUnit = this._findBusinessUnitOrAgentGroup(businessUnits, selectedBusinessUnitName);
      this.set('availableSlots', selectedBusinessUnit.availableSlots);
      this.set('transferSlots', selectedBusinessUnit.transferQueueSlots);
      this.set('conferenceSlots', selectedBusinessUnit.conferenceQueueSlots);
    },
    /**
     * Sets the number of slots displayed to be equal to
     * the currently selected agent group's slots.
     * @method _onAGSelectedUpdateSlots
     * @private
     */
    _onAGSelectedUpdateSlots: function _onAGSelectedUpdateSlots() {
      var businessUnit = this.getBusinessUnitDataByName(this.get('selectedBusinessUnit'));
      var agentGroup = this._findBusinessUnitOrAgentGroup(businessUnit.agentGroups, this.get('selectedAgentGroup.name'));
      this.set('availableSlots', agentGroup.availableSlots);
      this.set('transferSlots', agentGroup.transferQueueSlots);
      this.set('conferenceSlots', agentGroup.conferenceQueueSlots);
    },
    /**
     * Updates the number of slots according to the available agents.
     * @method _calculateAvailableAgentSlots
     * @private
     */
    _calculateAvailableAgentSlots: function _calculateAvailableAgentSlots() {
      var _this4 = this;
      var availableAgents = this.get('availableAgents');
      var availableSlots = 0;
      var transferSlots = 0;
      var conferenceSlots = 0;
      availableAgents.forEach(function (agent) {
        var agentData = _this4.getAgentByNameOrId(agent.name);
        availableSlots += agentData.availableSlots;
        transferSlots += agentData.transferQueueSlots;
        conferenceSlots += agentData.conferenceQueueSlots;
      });
      this.set('availableSlots', availableSlots);
      this.set('transferSlots', transferSlots);
      this.set('conferenceSlots', conferenceSlots);
    },
    /**
     * Sets up the agentAttributes array on the component
     * according to the given agent group.
     * @method _populateAgentAttributes
     * @private
     * @param {Object} source business unit or agent group
     */
    _populateAgentAttributes: function _populateAgentAttributes(source) {
      if (!source.agentAttributes) return;
      var attrs = source.agentAttributes.uniqBy('name').map(function (attr) {
        var attributes = source.agentAttributes.filterBy('name', attr.name);
        return Ember.Object.create({
          name: attr.name,
          displayValues: (0, _array.sortAlphabetically)(attributes.mapBy('displayValue')),
          values: attributes.mapBy('value'),
          hasAgent: true
        });
      });
      this.set('agentAttributes', attrs.sortBy('name'));
    },
    /**
     * Returns all business unit data with updated agent
     * name property and dvv availability.
     * @method _normalizeBusinessUnitData
     * @private
     * @param {Array} businessUnits transferOptions response data
     * @return {Array} business units with updated agent data and dvv availability
     */
    _normalizeBusinessUnitData: function _normalizeBusinessUnitData(businessUnits) {
      var _this5 = this;
      return businessUnits.map(function (businessUnit) {
        if (businessUnit.agentGroups) {
          var agentGroups = businessUnit.agentGroups.map(function (agentGroup) {
            var _ref = agentGroup || {},
              _ref$agentAttributes = _ref.agentAttributes,
              agentAttributes = _ref$agentAttributes === void 0 ? [] : _ref$agentAttributes,
              agents = _ref.agents;
            var isAvailableForVideoAgentAttribute = agentAttributes.find(function (attribute) {
              return attribute.name === 'isAvailableForVideo';
            });
            var isAvailableForVoiceAgentAttribute = agentAttributes.find(function (attribute) {
              return attribute.name === 'isAvailableForVoice';
            });
            return _objectSpread(_objectSpread({}, agentGroup), {}, {
              agents: agents && agents.map(function (agent) {
                return _objectSpread(_objectSpread({}, agent), {}, {
                  isAvailableForVideo: isAvailableForVideoAgentAttribute && isAvailableForVideoAgentAttribute.agents.any(function (agentAttrAgent) {
                    return agentAttrAgent.id === agent.id;
                  }),
                  isAvailableForVoice: isAvailableForVoiceAgentAttribute && isAvailableForVoiceAgentAttribute.agents.any(function (agentAttrAgent) {
                    return agentAttrAgent.id === agent.id;
                  }),
                  name: _this5._getAgentName(agent)
                });
              }),
              isAvailableForVideo: !!isAvailableForVideoAgentAttribute,
              isAvailableForVoice: !!isAvailableForVoiceAgentAttribute
            });
          });
          return _objectSpread(_objectSpread({}, businessUnit), {}, {
            agentGroups: agentGroups
          });
        } else {
          return _objectSpread(_objectSpread({}, businessUnit), {}, {
            agents: businessUnit.agents && businessUnit.agents.map(function (agent) {
              return _objectSpread(_objectSpread({}, agent), {}, {
                name: _this5._getAgentName(agent)
              });
            })
          });
        }
      });
    },
    _setAvailableAgents: function _setAvailableAgents(agents) {
      this.set('availableAgents', agents.sortBy('name'));
    },
    /**
    * Gathers all necessary data for transferring an engagement
    * or creating a conference engagement and returns it.
    * @method getDataForTransferConference
    * @private
    * @return {Object} the data needed to transfer the engagement or create a conference engagement
    */
    getDataForTransferConference: function getDataForTransferConference() {
      var _this6 = this;
      var _this$getProperties = this.getProperties('businessUnitData', 'selectedBusinessUnit', 'selectedAgentGroup', 'agentNotes', 'prioritizeRequest', 'selectedAttributes', 'hasAgentGroups'),
        businessUnitData = _this$getProperties.businessUnitData,
        selectedBusinessUnit = _this$getProperties.selectedBusinessUnit,
        selectedAgentGroup = _this$getProperties.selectedAgentGroup,
        agentNotes = _this$getProperties.agentNotes,
        prioritizeRequest = _this$getProperties.prioritizeRequest,
        selectedAttributes = _this$getProperties.selectedAttributes,
        hasAgentGroups = _this$getProperties.hasAgentGroups;
      var selectedAgentName = this.get('selectedAgent.name');
      var selectedBU = this._findBusinessUnitOrAgentGroup(businessUnitData, selectedBusinessUnit);
      var agentAttributes;
      var selectedAG;
      if (hasAgentGroups) {
        selectedAG = this._findBusinessUnitOrAgentGroup(selectedBU.agentGroups, selectedAgentGroup.name);
        agentAttributes = selectedAG.agentAttributes;
      } else {
        agentAttributes = selectedBU.agentAttributes;
      }
      var agentAttributeData = selectedAttributes.map(function (selectedAttr) {
        var matchedAttribute = agentAttributes.find(function (attr) {
          return attr.name === selectedAttr.name && attr.value === selectedAttr.value;
        });
        delete matchedAttribute.displayValue;
        return matchedAttribute;
      });
      var requestData = {};
      if (agentAttributeData.length) {
        requestData.agentAttributes = agentAttributeData;
      }
      if (agentNotes) {
        requestData.agentNotes = agentNotes;
      }
      if (prioritizeRequest) {
        requestData.prioritizeRequest = prioritizeRequest;
      }
      if (selectedAgentName) {
        var agentsToSearchThrough;
        if (selectedAG) {
          agentsToSearchThrough = selectedAG.agents;
        } else {
          agentsToSearchThrough = selectedBU.agents;
        }
        var targetAgent = agentsToSearchThrough.find(function (agent) {
          return _this6._getAgentName(agent) === selectedAgentName;
        });
        requestData.targetAgentId = targetAgent.id;
      }
      if (hasAgentGroups) {
        requestData.agentGroupId = selectedAG.id;
        requestData.agentGroupName = selectedAG.name;
      }
      requestData.businessUnitId = selectedBU.id;
      requestData.businessUnitName = selectedBU.name;
      return requestData;
    },
    /**
     * ACTIONS
     */
    /**
     * Resets all form elements and selections made on the component.
     * Called when the user clicks the reset button.
     * @method reset
     * @public
     */
    reset: function reset() {
      this.set('selectedBusinessUnit', '');
      this.set('selectedAgentGroup', '');
      this.set('availableSlots', 0);
      this.set('transferSlots', 0);
      this.set('conferenceSlots', 0);
      this.set('selectedAttributes', []);
      this.set('agentAttributes', []);
      this.set('agentGroups', []);
      this.set('selectedAgent', null);
      this.set('agentNotes', '');
      this.set('prioritizeRequest', false);
      this.set('availableAgents', []);
    },
    /**
     * If the user is adding an attribute, the attribute
     * will get added to the selectedAttributes array.
     * The rest of the agent attributes will be filtered out
     * if need be and the slots will also be updated.
     * Called when the user selects an agent attribute.
     * @method onSelectAgentAttribute
     * @public
     * @param {String} name the name of the selected agent attribute
     * @param {String} displayValue the display value of the selected agent attribute (null if cleared)
     */
    selectAgentAttribute: function selectAgentAttribute(name, displayValue) {
      // value is passed in automatically by power-select
      if (!displayValue) {
        this.set('selectedAttributes', this.get('selectedAttributes').rejectBy('name', name));
      } else {
        var value = encodeURIComponent(displayValue);
        var selectedAttributes = this.get('selectedAttributes').filter(function (selectedAttribute) {
          return selectedAttribute.name !== name;
        });
        selectedAttributes.pushObject({
          name: name,
          value: value,
          displayValue: displayValue
        });
        this.set('selectedAttributes', selectedAttributes);
      }
      this._filterAgentAttributes(name);
      var hasAttributesSelected = this.get('hasAttributesSelected');
      if (hasAttributesSelected) {
        this._calculateAvailableAgentSlots();
      } else if (this.get('selectedAgentGroup.name')) {
        this._onAGSelectedUpdateSlots();
      } else {
        this._onBUSelectedUpdateSlots();
      }
    },
    /**
     * Sets the selected business unit and populates
     * the agentGroups dropdown.
     * Called when the user selects a business unit.
     * @method onSelectBusinessUnit
     * @public
     * @param {String} bUnitName the selected business unit name
     */
    selectBusinessUnit: function selectBusinessUnit(bUnitName) {
      this.set('selectedBusinessUnit', bUnitName);
      var selectedBusinessUnit = this.getBusinessUnitDataByName(bUnitName);
      var availableAgents = [];
      var availableSlots = 0;
      var transferQueueSlots = 0;
      var conferenceQueueSlots = 0;
      var availableAgentGroups = [];
      var agentGroupNames = [];
      this.set('agentAttributes', []);
      var agentGroups = selectedBusinessUnit.agentGroups || [];
      if (agentGroups.length) {
        agentGroups.forEach(function (_ref2) {
          var _ref2$agents = _ref2.agents,
            agents = _ref2$agents === void 0 ? [] : _ref2$agents,
            agName = _ref2.name,
            displayName = _ref2.displayName,
            isAvailableForVideo = _ref2.isAvailableForVideo,
            isAvailableForVoice = _ref2.isAvailableForVoice;
          agentGroupNames.push({
            name: displayName || agName,
            isAvailableForVideo: isAvailableForVideo,
            isAvailableForVoice: isAvailableForVoice
          });
          if (agents.length) {
            availableAgents = agents;
            availableAgentGroups.push(displayName || agName);
          }
        });
        this.set('sortedAgentGroupNames', agentGroupNames.sortBy('name'));
      } else {
        var agents = selectedBusinessUnit.agents;
        availableSlots = selectedBusinessUnit.availableSlots;
        transferQueueSlots = selectedBusinessUnit.transferQueueSlots;
        conferenceQueueSlots = selectedBusinessUnit.conferenceQueueSlots;
        if (agents) {
          availableAgents = agents;
          this.set('agents', availableAgents);
        }
        this._populateAgentAttributes(selectedBusinessUnit);
      }
      this._setAvailableAgents(availableAgents);
      this.set('availableAgentGroups', availableAgentGroups);
      this.set('availableSlots', availableSlots);
      this.set('transferSlots', transferQueueSlots);
      this.set('conferenceSlots', conferenceQueueSlots);
      this.set('selectedAttributes', []);
      this.set('agentGroups', agentGroups);
      if (agentGroups.length) {
        this.selectAgentGroup(this.get('agentGroups.firstObject.name'));
      }
    },
    /**
     * Sets the selected agent group, updates the number
     * of slots, and displays the agent attribute dropdowns.
     * Called when the user selects an agent group.
     * @method onSelectAgentGroup
     * @public
     * @param {String} aGroupName the selected agent group name
     */
    selectAgentGroup: function selectAgentGroup(aGroupName) {
      var businessUnit = this.getBusinessUnitDataByName(this.get('selectedBusinessUnit'));
      var agentGroup = this._findBusinessUnitOrAgentGroup(businessUnit.agentGroups, aGroupName) || {};
      var displayName = agentGroup.displayName,
        name = agentGroup.name,
        isAvailableForVideo = agentGroup.isAvailableForVideo,
        isAvailableForVoice = agentGroup.isAvailableForVoice;
      this.set('selectedAgentGroup', {
        name: displayName || name,
        isAvailableForVideo: isAvailableForVideo,
        isAvailableForVoice: isAvailableForVoice
      });
      var agents;
      if (agentGroup.agents) {
        this.set('agents', agentGroup.agents);
        agents = agentGroup.agents;
      } else {
        agents = [];
      }
      this._setAvailableAgents(agents);
      this.set('agentAttributes', []);
      this.set('selectedAttributes', []);
      this._onAGSelectedUpdateSlots();
      this._populateAgentAttributes(agentGroup);
    },
    /**
     * Sets the selected agent name and updates the number
     * of slots. Called when the user selects an agent.
     * @method onSelectAgent
     * @public
     * @param {String} selectedAgent the selected agent
     */
    selectAgent: function selectAgent(selectedAgent) {
      var _this7 = this;
      var agentWasDeselected = selectedAgent === null;
      var hasAttributesSelected = this.get('hasAttributesSelected');
      this.set('selectedAgent', selectedAgent);
      if (agentWasDeselected && hasAttributesSelected) {
        this._calculateAvailableAgentSlots();
      } else if (agentWasDeselected && !hasAttributesSelected) {
        if (this.get('selectedAgentGroup.name')) {
          this._onAGSelectedUpdateSlots();
        } else {
          this._onBUSelectedUpdateSlots();
        }
      } else {
        var agents = this.get('agents');
        var agentData = agents.find(function (agent) {
          return _this7._getAgentName(agent) === selectedAgent.name;
        });
        this.set('availableSlots', agentData.availableSlots);
        this.set('transferSlots', agentData.transferQueueSlots);
        this.set('conferenceSlots', agentData.conferenceQueueSlots);
      }
    },
    /**
     * Returns the raw data for a given agent group.
     * @method getAgentGroupDataByName
     * @public
     * @param {String} agentGroupName the agent group name
     * @param {String} businessUnitName
     * @return {Object} the agent group's data
     */
    getAgentGroupDataByName: function getAgentGroupDataByName(agentGroupName, businessUnitName) {
      var businessUnits = this.get('businessUnitData');
      if (businessUnitName) {
        businessUnitName = this.getBusinessUnitName(businessUnitName);
        businessUnits = businessUnits.filterBy('name', businessUnitName);
      }

      // agentGroups is an array of arrays (of agentGroups)
      // e.g., [ [{name: 'agentGroupA'}, {name: 'agentGroupB'}], ...]
      var agentGroups = businessUnits.filterBy('agentGroups').map(function (businessUnit) {
        return businessUnit.agentGroups;
      });
      var agentGroupData;
      for (var i = 0; i < agentGroups.length; i++) {
        for (var j = 0; j < agentGroups[i].length; j++) {
          if (agentGroups[i][j].name === agentGroupName || agentGroups[i][j].displayName === agentGroupName) {
            agentGroupData = agentGroups[i][j];
            break;
          }
        }
      }
      return agentGroupData;
    },
    /**
     * Returns the raw data for a given business unit.
     * @method getBusinessUnitDataByName
     * @public
     * @param {String} businessUnitName the business unit name
     * @return {Object} the business unit's data
     */
    getBusinessUnitDataByName: function getBusinessUnitDataByName(businessUnitName) {
      businessUnitName = this.getBusinessUnitName(businessUnitName);
      var businessUnitData = this.get('businessUnitData');
      return businessUnitData.find(function (businessUnit) {
        return businessUnit.name === businessUnitName;
      });
    },
    /**
     * Returns the raw data for a given selected agent.
     * @method getAgentDataById
     * @public
     * @param {String} selectedAgentId
     * @return {Object} the selected agent's data
     */
    getAgentDataById: function getAgentDataById(selectedAgentId) {
      var selectedAgentData = this.get('agents');
      return selectedAgentData.findBy('id', selectedAgentId);
    },
    /**
     * Returns the raw data for a given agent.
     * @method getAgentByNameOrId
     * @public
     * @param {String} nameOrId the agent's name or id
     * @returns {Object} the agent's raw data
     */
    getAgentByNameOrId: function getAgentByNameOrId(nameOrId) {
      var _this8 = this;
      var agents = this.get('agents').map(function (agent) {
        return _objectSpread(_objectSpread({}, agent), {}, {
          name: _this8._getAgentName(agent)
        });
      });
      return agents.find(function (agent) {
        var hasFullName = agent.firstName && agent.lastName;
        if (hasFullName) {
          return agent.id === nameOrId || "".concat(agent.firstName, " ").concat(agent.lastName) === nameOrId;
        }
        return agent.id === nameOrId;
      });
    },
    /**
     * Will take an actual name OR display name for
     * a business unit and return the actual name.
     * Used when it is necessary to have a business
     * unit's actual name.
     * @method getBusinessUnitName
     * @public
     * @param {String} businessUnitName display name or actual name for a business unit
     * @return {String} the actual name of the business unit
     */
    getBusinessUnitName: function getBusinessUnitName(businessUnitName) {
      var businessUnits = this.get('businessUnitData');
      var businessUnit = businessUnits.find(function (businessUnit) {
        if (businessUnit.displayName === businessUnitName) return true;
        if (businessUnit.name === businessUnitName) return true;
        return false;
      });
      return businessUnit.name;
    },
    /**
     * @method _getAgentName
     * @private
     * @param {Object} agent the agent whose name should be returned
     * @return {String} the agent's first and last name if available, otherwise the agent's id
     */
    _getAgentName: function _getAgentName(agent) {
      var hasFullName = agent.firstName && agent.lastName;
      if (hasFullName) return "".concat(agent.firstName, " ").concat(agent.lastName);
      return agent.id;
    },
    /**
     * Returns a desired business unit or agent group
     * given the name or display name.
     * @method _findBusinessUnitOrAgentGroup
     * @private
     * @param {Array} businessUnitsOrAgentGroups an array of business units or agent groups
     * @param {String} businessUnitOrAgentGroupName the desired business unit or agent group name or display name
     * @return {Object} the desired business unit or agent group
     */
    _findBusinessUnitOrAgentGroup: function _findBusinessUnitOrAgentGroup(businessUnitsOrAgentGroups, businessUnitOrAgentGroupName) {
      return businessUnitsOrAgentGroups.find(function (businessUnitOrAgentGroup) {
        var name = businessUnitOrAgentGroup.name,
          displayName = businessUnitOrAgentGroup.displayName;
        return [name, displayName].includes(businessUnitOrAgentGroupName);
      });
    }
  });
});