define("sdk/sso/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    errorMessage: null,
    isLoading: Ember.computed.not('errorMessage'),
    url: Ember.inject.service(),
    intl: Ember.inject.service(),
    detailButtonText: Ember.computed('isShowingDetails', function () {
      if (this.get('isShowingDetails')) return this.get('intl').t('login-form.hide-details');
      return this.get('intl').t('login-form.show-details');
    }),
    actions: {
      transitionToLogin: function transitionToLogin() {
        this.set('errorMessage', null);
        var hrefNoQueryParams = this.get('url.hrefNoQueryParams');
        return window.location.replace(hrefNoQueryParams);
      }
    }
  });
});