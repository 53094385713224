define("sdk/templates/modals/-preview-transcript-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "D9++hIBM",
    "block": "{\"symbols\":[\"window\"],\"statements\":[[4,\"popup-window\",null,[[\"id\",\"popupClass\",\"ariaLabelledBy\",\"hasCloseButton\",\"onClose\",\"onHiddenFocusSelector\"],[\"preview-transcript-line\",\"preview-transcript-line-modal\",\"preview-transcript-line-modal-title\",true,[26,\"action\",[[21,0,[]],[22,[\"set\"]],\"engagement.ui.previewTranscriptLine\",null],null],[26,\"concat\",[\"#line-text__view-more--\",[22,[\"engagement\",\"ui\",\"previewTranscriptLine\",\"uuid\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"preview-transcript-line-modal-title\"],[8],[0,\"\\n      \"],[1,[22,[\"engagement\",\"ui\",\"previewTranscriptLine\",\"prefix\"]],false],[0,\"\\n      \"],[1,[26,\"if\",[[22,[\"engagement\",\"ui\",\"previewTranscriptLine\",\"isConversation\"]],[26,\"concat\",[\" - \",[22,[\"engagement\",\"ui\",\"previewTranscriptLine\",\"formattedTimestamp\"]]],null]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"section\"],[10,\"data-test-preview-transcript-line-text\",\"\"],[8],[0,\"\\n      \"],[1,[22,[\"engagement\",\"ui\",\"previewTranscriptLine\",\"messageHTML\"]],true],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-preview-transcript-line.hbs"
    }
  });
});