define("sdk/api/adapters/ajax-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class AJAXRequest
   * @public
   */
  var _default = _exports.default = Ember.Object.extend({
    /**
    * Number of attempts / tries to make an AJAX Request
    * @property {Number} attempts
    */
    attempts: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this._initializePromise();
      var options = this.get('ajaxOptions');
      this.set('originalRequestDetails', Object.freeze({
        query: options.query,
        data: options.data
      }));
    },
    /**
    * Creates deferred and sets it's properties on to this instance.
    * @method _initializePromise
    */
    _initializePromise: function _initializePromise() {
      var deferred = Ember.RSVP.defer();
      //sets promise, resolve and reject on to the instance
      this.setProperties(deferred);
    },
    /**
    * @method getActiveXHR
    * @return {Object} the active xhr object set on this instance.
    * @public
    */
    getActiveXHR: function getActiveXHR() {
      return this.getWithDefault('activeXHR', {});
    }
  });
});