define("sdk/components/sdk-scripts/script-group-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hH6OOYgk",
    "block": "{\"symbols\":[\"dropdown\",\"index\",\"groupOrFolder\"],\"statements\":[[4,\"each\",[[22,[\"scriptGroupsOrFolders\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"menu-select\",null,[[\"options\",\"selected\",\"onchange\",\"dropdownClass\",\"horizontalPosition\",\"hasOverlay\"],[[21,1,[\"options\"]],[21,1,[\"selected\"]],[26,\"action\",[[21,0,[]],\"onSelected\",[21,2,[]]],null],\"script-group-dropdown\",\"left\",[22,[\"hasOverlay\"]]]],{\"statements\":[[0,\"\\n    \"],[6,\"span\"],[11,\"title\",[27,[[21,3,[\"name\"]]]]],[8],[0,\"\\n      \"],[1,[21,3,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[21,3,[\"groups\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"fa-icon\",[\"caret-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/sdk-scripts/script-group-select/template.hbs"
    }
  });
});