define("sdk/components/nested-dropdown/menu/nested-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RUsg2ssU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[8],[0,\"\\n  \"],[1,[22,[\"option\",\"name\"]],false],[0,\" *\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"isMenuVisible\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"nested-dropdown/menu\",[[22,[\"option\",\"options\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/nested-dropdown/menu/nested-option/template.hbs"
    }
  });
});