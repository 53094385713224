define("sdk/site/training/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('trainingUI', {
        customScriptSectionsCollapsed: [],
        scriptView: 'system-scripts',
        systemScriptSectionsCollapsed: []
      });
    }
  });
});