define("sdk/api/utils/media-devices", ["exports", "sdk/utils/browser-type"], function (_exports, _browserType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.aspectRatio = void 0;
  _exports.getDeviceById = getDeviceById;
  _exports.getDevices = getDevices;
  _exports.getDevicesByType = getDevicesByType;
  _exports.getMicrophoneDeviceIdFromStream = getMicrophoneDeviceIdFromStream;
  _exports.getVideoDeviceIdFromStream = getVideoDeviceIdFromStream;
  _exports.getVideoStreamById = getVideoStreamById;
  _exports.initializePermissions = initializePermissions;
  _exports.isMediaUnsupported = isMediaUnsupported;
  _exports.stopStream = stopStream;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  /**
     * Portrait mode aspect ratio (9/16 = .5625)
     * aspectRatio is not supported by Firefox
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints/aspectRatio#Browser_compatibility
     * @const {number} aspectRatio
     * @public
     */
  var aspectRatio = _exports.aspectRatio = .5625;
  function getVideoStreamById(id) {
    if (!id) return Ember.RSVP.reject();
    var constraints = window.constraints = {
      audio: false,
      video: {
        deviceId: {
          exact: id
        },
        aspectRatio: aspectRatio
      }
    };
    if (navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        return stream;
      });
    }
  }
  function getDevicesByType(type) {
    return getDevices(type).then(function (devices) {
      if (!devices) return [];
      return devices.filter(function (device) {
        return device.kind === type && device.label !== '' && !['default', 'communications', ''].includes(device.deviceId);
      });
    });
  }
  function isMediaUnsupported() {
    return _browserType.default.isEdgeOrIE || !navigator.mediaDevices.getUserMedia;
  }
  function initializePermissions(_ref) {
    var audioDeviceId = _ref.audioDeviceId,
      micDeviceId = _ref.micDeviceId,
      videoDeviceId = _ref.videoDeviceId;
    var constraint = {
      audio: {
        deviceId: [audioDeviceId, micDeviceId]
      },
      video: {
        deviceId: videoDeviceId
      }
    };
    return navigator.mediaDevices.getUserMedia({
      audio: constraint.audio || false,
      video: constraint.video || false
    });
  }
  function getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }
  function getDeviceById(type, defaultIdOfType) {
    return getDevices().then(function (devices) {
      if (!devices) return;
      var typeDevices = devices.filter(function (device) {
        return device.kind === type;
      });
      var availableTypeDevices = typeDevices.filter(function (device) {
        return !['default', 'communications'].includes(device.deviceId);
      });
      var foundDevice;
      if (defaultIdOfType) {
        foundDevice = availableTypeDevices.find(function (device) {
          return device.deviceId === defaultIdOfType;
        });
      }
      if (!foundDevice) {
        var computerDefaultDevice = typeDevices.find(function (device) {
          return device.deviceId === 'default';
        });
        var computerCommunicationsDevice = typeDevices.find(function (device) {
          return device.deviceId === 'communications';
        });
        var prioritizedDefaultDevice = computerCommunicationsDevice || computerDefaultDevice;
        if (!prioritizedDefaultDevice) {
          foundDevice = availableTypeDevices && availableTypeDevices[0];
        } else {
          foundDevice = availableTypeDevices.find(function (device) {
            return device.groupId === prioritizedDefaultDevice.groupId;
          });
        }
      }
      return foundDevice;
    });
  }
  function stopStream(stream) {
    if (!stream) return;
    var _iterator = _createForOfIteratorHelper(stream.getTracks()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var track = _step.value;
        track.stop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    stream = null;
  }
  function getMicrophoneDeviceIdFromStream(stream) {
    if (!stream) return;
    var tracks = stream.getAudioTracks();
    if (!tracks.length) return;
    return tracks[0].getSettings().deviceId;
  }
  function getVideoDeviceIdFromStream(stream) {
    if (!stream) return;
    var tracks = stream.getVideoTracks();
    if (!tracks.length) return;
    return tracks[0].getSettings().deviceId;
  }
});