define("sdk/templates/modules/-scripts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "NH489rml",
    "block": "{\"symbols\":[\"tile\"],\"statements\":[[4,\"collapsable-tile\",null,[[\"aria-label\",\"tileName\",\"hide\",\"onTileToggle\",\"singleTile\",\"hasOverlay\",\"iconAriaLabel\",\"ui\",\"height\",\"isCollapsed\",\"data-test-scripts\"],[[26,\"t\",[\"scripts\"],null],\"scripts\",[26,\"and\",[[22,[\"ui\",\"expandedTile\"]],[26,\"not-eq\",[[22,[\"ui\",\"expandedTile\"]],\"scripts\"],null]],null],[26,\"action\",[[21,0,[]],\"onTileToggle\"],null],true,[26,\"or\",[[22,[\"engagement\",\"isSendDisabled\"]],[22,[\"engagement\",\"isLocked\"]]],null],[26,\"t\",[\"scripts\"],null],[22,[\"ui\"]],[22,[\"engagement\",\"ui\",\"layout\",\"scriptsWindowHeight\"]],[22,[\"engagement\",\"ui\",\"layout\",\"scriptsWindowCollapsed\"]],\"\"]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[6,\"h3\"],[8],[1,[26,\"t\",[\"scripts\"],null],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"component\",[[21,1,[\"content\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"sdk-scripts\",null,[[\"ui\",\"scriptView\",\"settings\",\"sendMsg\",\"deviceType\",\"screeningMode\",\"engagement\",\"alert\",\"resetHotkey\",\"openCustomScriptsEditor\",\"hasOverlay\"],[[22,[\"engagement\",\"ui\"]],[22,[\"engagement\",\"scriptView\"]],[22,[\"engagement\",\"settings\"]],[22,[\"engagement\",\"send\"]],[22,[\"engagement\",\"deviceType\"]],[22,[\"engagement\",\"screeningMode\"]],[22,[\"engagement\"]],[26,\"action\",[[21,0,[]],\"showAlert\"],null],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"resetHotkey\"],null],[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"openCustomScriptsEditor\"],null],[21,1,[\"hasOverlay\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modules/-scripts.hbs"
    }
  });
});