define("sdk/api/utils/url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addQueryParams = addQueryParams;
  _exports.getMimeTypeFromArrayBuffer = getMimeTypeFromArrayBuffer;
  _exports.getQueryParams = getQueryParams;
  function addQueryParams() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var newQueryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var queryParams = getQueryParams(url);
    Object.assign(queryParams, newQueryParams);
    var urlNoQueryParams = getUrlWithNoQueryParams(url);
    return "".concat(urlNoQueryParams).concat(getQueryParamsString(queryParams));
  }

  /**
   * @method getQueryParams
   * @param {String} url
   * @returns {Object} key value pairs of query parameters
   */
  function getQueryParams() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : window.location.href;
    var queryParamObj = {};
    url.replace(/[?&]([^=]+)(?:=([^&]+))?/g, function (match, key, value) {
      queryParamObj[key] = value || true;
    });
    return queryParamObj;
  }
  function getQueryParamsString() {
    var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var queryParamKeys = Object.keys(queryParams);
    return queryParamKeys.length > 0 ? "?".concat(queryParamKeys.map(function (key) {
      return key + '=' + queryParams[key];
    }).join('&')) : '';
  }
  function getUrlWithNoQueryParams() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var queryParamsStartIndex = url.indexOf('?');
    return queryParamsStartIndex > -1 ? url.substring(0, queryParamsStartIndex) : url;
  }
  function getMimeTypeFromArrayBuffer(arrayBuffer, url) {
    var uint8arr = new Uint8Array(arrayBuffer);
    var len = 4;
    if (uint8arr.length >= len) {
      var signatureArr = new Array(len);
      for (var i = 0; i < len; i++) signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16);
      var signature = signatureArr.join('').toUpperCase();
      switch (signature) {
        case '89504E47':
          return 'image/png';
        case '47494638':
          return 'image/gif';
        case '25504446':
          return 'application/pdf';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'image/jpeg';
        case '504B0304':
          return 'application/zip';
        case '424D8A53':
          return 'image/bmp';
        case '504B34':
          if (url && url.indexOf('.xlsx') > -1) return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'D0CF11E0':
          if (url && url.indexOf('.xls') > -1) return 'application/vnd.ms-excel';
          return 'application/msword';
        default:
          if (url && url.indexOf('.jpeg') > -1 || url.indexOf('.jpg') > -1) return 'image/jpeg';
          if (url && url.indexOf('.png') > -1) return 'image/png';
          if (url && url.indexOf('.gif') > -1) return 'image/gif';
          if (url && url.indexOf('.pdf') > -1) return 'application/pdf';
          if (url && url.indexOf('.zip') > -1) return 'application/zip';
          if (url && url.indexOf('.bmp') > -1) return 'image/bmp';
          if (url && url.indexOf('.doc') > -1) return 'application/msword';
          if (url && url.indexOf('.docx') > -1) return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          if (url && url.indexOf('.xls') > -1) return 'application/vnd.ms-excel';
          if (url && url.indexOf('.xlsx') > -1) return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          return null;
      }
    }
    return null;
  }
});