define("sdk/components/transcript-window/customer-typing-bubble/component", ["exports", "sdk/core/component", "sdk/components/transcript-window/customer-typing-bubble/template", "sdk/utils/speak"], function (_exports, _component, _template, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'customer-typing-bubble',
    classNameBindings: ['visible::hidden'],
    duration: 3000,
    visible: false,
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('isTyping')) {
        this._showBubble();
        (0, _speak.default)(this.get('isTypingMessage'));
      } else {
        this._showBubble(this.get('duration'));
        (0, _speak.default)(this.get('stopsTypingMessage'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('bubbleTimeout'));
    },
    _showBubble: function _showBubble(duration) {
      var _this = this;
      this.set('visible', true);
      if (this.get('bubbleTimeout')) {
        clearTimeout(this.get('bubbleTimeout'));
      }
      if (duration) {
        this.set('bubbleTimeout', setTimeout(function () {
          if (!_this.get('isDestroyed')) {
            _this.set('visible', false);
            clearTimeout(_this.get('bubbleTimeout'));
          }
        }, duration));
      }
    }
  });
});