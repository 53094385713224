define("sdk/components/request-additional-chat/component", ["exports", "sdk/core/component", "sdk/components/request-additional-chat/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'request-additional-chat',
    classNameBindings: ['isDisabled:disabled', 'isActive:active:inactive'],
    attributeBindings: ['title', 'aria-label', 'aria-pressed', 'tabindex'],
    tagName: 'button',
    'aria-label': Ember.computed(function () {
      return this.get('intl').t('request-additional-chat.request-more-chats');
    }),
    'aria-pressed': Ember.computed('isActive', function () {
      return "".concat(this.get('isActive'));
    }),
    tabindex: Ember.computed('isDisabled', function () {
      return this.get('isDisabled') ? '-1' : '0';
    }),
    focusIn: function focusIn() {
      this.set('aria-label', this.get('intl').t('request-additional-chat.request-more-chats'));
    },
    stopRequestAdditionalChats: function stopRequestAdditionalChats() {
      if (this.get('isActive')) {
        this.requestAdditionalChats(false);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('newEngagement', this, this.stopRequestAdditionalChats);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('api').off('newEngagement', this, this.stopRequestAdditionalChats);
    },
    agent: Ember.computed.alias('api.agent'),
    isActive: Ember.computed.alias('agent.isRequestingAdditionalChats'),
    isDisabled: Ember.computed.alias('agent.isRequestAdditionalChatsDisabled'),
    disabledObserver: Ember.observer('isDisabled', function () {
      if (this.get('isDisabled') && this.get('isActive')) {
        this.requestAdditionalChats(false);
      }
    }),
    title: Ember.computed('isDisabled', function () {
      if (this.get('agent.isBusy')) {
        return this.get('intl').t('request-additional-chat.not-available');
      } else if (this.get('agent.chatSlotsNotFull')) {
        return this.get('intl').t('request-additional-chat.chat-slots-not-full');
      } else if (this.get('agent.extraChatSlotsFull')) {
        return this.get('intl').t('request-additional-chat.extra-chat-slots-full');
      } else {
        return this.get('intl').t('request-additional-chat.default');
      }
    }),
    click: function click() {
      if (this.get('isDisabled')) return;
      this.requestAdditionalChats(!this.get('isActive'));
    },
    requestAdditionalChats: function requestAdditionalChats(enabled) {
      var _this = this;
      var translate = function translate(intlPath) {
        return _this.get('intl').t("request-additional-chat.".concat(intlPath));
      };
      var ariaLabel = (enabled ? translate('chat-slots-enabled') : translate('default')) + translate('details');
      this.set('aria-label', ariaLabel);
      return this.get('agent').requestAdditionalChats(enabled);
    }
  });
});