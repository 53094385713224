define("sdk/components/static-transcript-window/history/selected-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2Cpv7Nqe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"option\",\"transcript\",\"accepted\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"t\",[\"static-transcript.current\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[26,\"moment-format\",[[22,[\"option\",\"label\"]],\"D/MMM/YY\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/static-transcript-window/history/selected-item/template.hbs"
    }
  });
});