define("sdk/templates/modals/-escalate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DSEw/l0F",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"child-modal\",null,[[\"id\",\"modalClass\",\"modalAriaLabelledBy\",\"isKeyboardEnabled\",\"isBackDropCloseEnabled\",\"hasCloseButton\",\"onClose\",\"onHiddenFocusSelector\"],[\"escalate-modal\",\"escalate-modal\",\"escalate-modal-title\",true,true,true,[26,\"action\",[[21,0,[]],\"closeEscalateWindow\"],null],\"#active-transcript-action__menu\"]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[10,\"id\",\"escalate-modal-title\"],[10,\"data-test-escalate-modal-title\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"escalate-modal.chat-escalation\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"    \"],[6,\"section\"],[8],[0,\"\\n      \"],[1,[26,\"escalation-form\",null,[[\"engagement\",\"onCancel\",\"onComplete\"],[[22,[\"engagement\"]],[26,\"action\",[[21,0,[]],\"closeEscalateWindow\"],null],[26,\"action\",[[21,0,[]],\"closeEscalateWindow\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/modals/-escalate.hbs"
    }
  });
});