define("sdk/lockout/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    url: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      var url = this.get('url');
      url.setQueryParam('lockout', 'true');

      // keep agent session active by polling verifySession
      this.get('api.agent').startVerifySessionPolling().catch(function (error) {
        _this._handleVerifySessionError(error);
      });
      var _url$getQueryParams = url.getQueryParams(),
        SSO = _url$getQueryParams.SSO;
      if (SSO) {
        url.setQueryParam('SSO', 'lockout');
        var controller = this.controllerFor('lockout');
        controller.set('isSSO', true);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.get('url').removeQueryParam('lockout');
      }
    },
    /**
     * Transition to login route if we encounter an error while polling verifySession
     * @method _handleVerifySessionError
     * @param error server error response
     */
    _handleVerifySessionError: function _handleVerifySessionError() {
      var error = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var _ref = error || {},
        payload = _ref.payload;
      var errorObject = {};
      if (payload.error_description) {
        errorObject.title = payload.error_description;
      } else if (payload.detail) {
        errorObject.title = payload.detail;
      }
      errorObject.timestamp = (0, _moment.default)().toString();
      errorObject.agentId = this.get('api.agent.userId');
      errorObject.url = error && error.url;
      sessionStorage.setItem('error_invalidate', JSON.stringify(errorObject));
      this.transitionTo('login');
    }
  });
});