define("sdk/components/sdk-scripts/script/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "WYVqVN99",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"script\",\"isVisibleInputScriptWindow\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[26,\"sdk-scripts/script/input-script\",null,[[\"data-test-input-script\",\"inputScript\",\"isTraining\",\"onCancel\",\"submit\"],[\"\",[22,[\"script\"]],[22,[\"isTraining\"]],[26,\"action\",[[21,0,[]],\"setFocusToFirstScriptLine\"],null],[26,\"action\",[[21,0,[]],\"sendInputScript\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[26,\"sdk-scripts/script/script-line\",null,[[\"data-test-script-line\",\"script\",\"filter\",\"isTraining\",\"onEvent\",\"onPreviewRichWidget\",\"hasSection\"],[\"\",[22,[\"script\"]],[22,[\"filter\"]],[22,[\"isTraining\"]],[26,\"action\",[[21,0,[]],\"selectScript\"],null],[22,[\"onPreviewRichWidget\"]],[22,[\"hasSection\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/sdk-scripts/script/template.hbs"
    }
  });
});