define("sdk/components/sdk-scripts/script-tree-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lCzMCy/R",
    "block": "{\"symbols\":[\"scriptTree\"],\"statements\":[[4,\"menu-select\",null,[[\"options\",\"selected\",\"onchange\",\"searchEnabled\",\"renderInPlace\",\"disabled\",\"title\",\"hasOverlay\"],[[22,[\"sortedScriptTrees\"]],[22,[\"selected\"]],[26,\"action\",[[21,0,[]],\"onScriptTreeSelected\"],null],false,true,[22,[\"disabled\"]],[22,[\"title\"]],[22,[\"hasOverlay\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,[\"disabled\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"span\"],[11,\"title\",[27,[[21,1,[\"name\"]]]]],[8],[0,\"\\n      \"],[1,[21,1,[\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/sdk-scripts/script-tree-select/template.hbs"
    }
  });
});