define("sdk/components/collapsable-tile/header/component", ["exports", "sdk/components/collapsable-tile/header/template", "sdk/core/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'collapsable-tile-header',
    isExpanded: false,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (this.get('shouldFocusExpandButton')) {
        this.$('.btn-expand').focus();
        this.set('shouldFocusExpandButton', false);
      } else if (this.get('shouldFocusCompressButton')) {
        this.$('.btn-compress').focus();
        this.set('shouldFocusCompressButton', false);
      }
    },
    actions: {
      toggle: function toggle() {
        var onToggle = this.get('onToggle');
        onToggle && onToggle();
      },
      expand: function expand() {
        var _this = this;
        this.set('isExpanded', true);
        var onExpanded = this.get('onExpanded');
        onExpanded && onExpanded();
        Ember.run.schedule('afterRender', function () {
          _this.$('.btn-compress').focus();
        });
      },
      expandTileToFullScreen: function expandTileToFullScreen() {
        this.set('isExpanded', true);
        var onExpandedToFullScreen = this.get('onExpandedToFullScreen');
        onExpandedToFullScreen && onExpandedToFullScreen();
      },
      compress: function compress() {
        var _this2 = this;
        this.set('isExpanded', false);
        var onCompressed = this.get('onCompressed');
        onCompressed && onCompressed();
        Ember.run.schedule('afterRender', function () {
          _this2.$('.btn-expand') && _this2.$('.btn-expand').focus();
        });
      },
      /**
       * Sets the tile back to the slideout menu.
       * @method sendBackToStartingPosition
       * @public
       */
      sendBackToStartingPosition: function sendBackToStartingPosition() {
        var onSendBackToStartingPosition = this.get('onSendBackToStartingPosition');
        onSendBackToStartingPosition && onSendBackToStartingPosition();
      }
    }
  });
});