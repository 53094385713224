define("sdk/components/transcript-window/transcript-line/agent-coach/component", ["exports", "sdk/core/component", "sdk/components/transcript-window/transcript-line/agent-coach/template", "sdk/components/transcript-window/transcript-line/context-menu", "sdk/api/utils/truncate", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _component, _template, _contextMenu, _truncate2, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_contextMenu.default, _fileSaver.default, {
    layout: _template.default,
    componentName: 'transcript-line-agent-coach',
    classNames: ['customer-line'],
    attributeBindings: ['aria-label', 'tabindex'],
    tabindex: '0',
    'data-test-transcript-line': '',
    'data-test-agent-coach-transcript-line': '',
    classNameBindings: ['transcriptLine.isSelectedForAgentCoach:transcript-line-agent-coach--selected'],
    intl: Ember.inject.service(),
    enableContextMenu: true,
    enableContextMenuMasking: true,
    lineText: Ember.computed.alias('transcriptLine.messageHTML'),
    lineTextTruncated: Ember.computed('lineText', function () {
      var _truncate = (0, _truncate2.default)(this.get('lineText'), 1000),
        hasTruncatedText = _truncate.hasTruncatedText,
        truncatedText = _truncate.truncatedText;
      return hasTruncatedText ? "".concat(truncatedText, "...") : undefined;
    }),
    click: function click(event) {
      var targetElement = event && event.target;
      if (targetElement && targetElement.tagName === 'A') {
        if (this.get('transcriptLine.isFileUploadLink')) {
          var href = targetElement.getAttribute('href');
          this.get('api.adapter').getUploadedFiles(href);
          return false;
        }
      } else {
        var onClick = this.get('onClick');
        onClick && onClick(this.get('transcriptLine'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var messageContent = this.$().text().trim();
      this.set('aria-label', this.get('intl').t('agent-coach.fetch-recommendation', {
        customerMessage: messageContent
      }));
    },
    keyDown: function keyDown() {
      var _event = event,
        keyCode = _event.keyCode;
      if (keyCode === 13 || keyCode === 32) {
        // Space or Enter
        this.click();
      }
    },
    actions: {
      handleViewMore: function handleViewMore(event) {
        this.set('ui.previewTranscriptLine', this.get('transcriptLine'));
        event.stopPropagation();
      }
    }
  }).reopenClass({
    positionalParams: ['transcriptLine']
  });
});