define("sdk/api/models/transcript-line-historic", ["exports", "ember-uuid", "sdk/api/utils/message", "moment", "sdk/api/models/filtered-transcript-line"], function (_exports, _emberUuid, _message, _moment, _filteredTranscriptLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var storageProperties = ['category', 'conversationIncrement', 'engagementId', 'formattedTimestamp', 'isAgentMessage', 'isCustomerMessage', 'messageAttributesText', 'messageHTML', 'messageText', 'prefix', 'sender', 'source', 'timestamp', 'transcriptId', 'transcriptHistorySearchIndex', 'transcriptHistorySearchMatches', 'uuid'];
  var _default = _exports.default = _filteredTranscriptLine.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.uuid = this.get('uuid') || (0, _emberUuid.v4)();
    },
    getStorageProps: function getStorageProps() {
      return this.getProperties(storageProperties);
    },
    messageText: '',
    messageType: '',
    messageHTML: Ember.computed('messageText', 'maskedText', function () {
      var messageText = this.get('messageText');

      // TODO if deviceType === 'apple-business-chat' && messageText is data object
      var isAppleBusinessChatData = messageText && messageText.indexOf('interactiveData') > -1;
      if (isAppleBusinessChatData) {
        return (0, _message._parseAppleBusinessChat)(messageText, this);
      }
      return (0, _message.renderedHTML)(this);
    }),
    sender: Ember.computed('type', function () {
      var type = this.get('type');
      switch (type) {
        case 'agent':
        case 'customer':
          return type;
        case 'chatLine':
          return 'customer';
        case 'opener':
          return 'agent';
        default:
          return 'system';
      }
    }),
    category: Ember.computed(function () {
      if (['agent-internal', 'supervisor'].includes(this.sender)) {
        return 'internal';
      } else if (this.isAgentMessage) {
        return 'agent';
      } else if (this.isCustomerMessage) {
        return 'customer';
      } else if (this.type === 'transfer-conference-notes') {
        return 'transfer-conference-notes';
      } else {
        return 'system';
      }
    }),
    isAgentMessage: Ember.computed.equal('sender', 'agent'),
    isCustomerMessage: Ember.computed.equal('sender', 'customer'),
    isConversation: Ember.computed(function () {
      var sender = this.get('sender');
      return ['agent', 'agent-internal', 'customer', 'supervisor'].includes(sender);
    }),
    isFileUploadLink: Ember.computed('messageText', function () {
      return (0, _message.isFileUploadLink)(this.get('messageText'));
    }),
    isFromChatHistory: Ember.computed.or('isSourceRealtime', 'isSourceHistoric'),
    isSourceRealtime: Ember.computed.equal('source', 'realtime'),
    isSourceHistoric: Ember.computed.equal('source', 'historic'),
    formattedTimestamp: Ember.computed('timestamp', function () {
      var timestamp = typeof this.timestamp === 'string' ? Number(this.timestamp) : this.timestamp;
      return (0, _moment.default)(timestamp).format('HH:mm:ss');
    }),
    messageAttributesText: Ember.computed('messageAttributes', function () {
      var messageAttributes = this.get('messageAttributes');
      if (!messageAttributes) return;
      return Object.keys(messageAttributes).map(function (attrKey) {
        return "\t".concat(attrKey, ": ").concat(messageAttributes[attrKey]);
      }).join('\n');
    })
  });
});