define("sdk/components/transcript-window/component", ["exports", "sdk/core/component", "sdk/components/transcript-window/template", "sdk/api/utils/runloop", "ember-keyboard", "sdk/utils/speak", "sdk/mixins/component-instance-manager", "sdk/helpers/is-from-current-engagement"], function (_exports, _component, _template, _runloop, _emberKeyboard, _speak, _componentInstanceManager, _isFromCurrentEngagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_componentInstanceManager.default, _emberKeyboard.EKMixin, {
    layout: _template.default,
    intl: Ember.inject.service(),
    keyboardActivated: true,
    componentName: 'transcript-window',
    messages: Object.freeze([]),
    ui: Ember.computed.alias('engagement.ui'),
    isAgentCoachEnabled: Ember.computed.bool('engagement.agentCoach'),
    isNinaCoach: Ember.computed.reads('engagement.isNinaCoach'),
    previewMessages: Ember.computed.alias('engagement.previewMessages'),
    shouldRenderBottom: 0,
    /**
     * Only render the bubble component if this is an active transcript window.
     * Only update the visibility if we're already focused on this engagement and
     * the customer.isTyping property has been updated.
     * Displays the customer is typing bubble for 5 seconds then hides it.
     */
    toggleCustomerIsTypingBubble: Ember.observer('engagement.customer.isTyping', function () {
      var _this = this;
      var typingBubble = this.get('typingBubble');
      if (!typingBubble) return;
      var cachedCustomerIsTyping = this.get('cachedCustomerIsTyping');
      var customerIsTyping = this.get('engagement.customer.isTyping');
      var hideCustomerIsTypingBubble = customerIsTyping === undefined || cachedCustomerIsTyping === customerIsTyping;
      if (hideCustomerIsTypingBubble) return this.set('showTypingBubble', false);
      var timeout = this.get('changeBubbleVisibilityTimer');
      if (timeout) {
        clearTimeout(timeout);
      }
      this.set('showTypingBubble', true);
      this.set('cachedCustomerIsTyping', customerIsTyping);
      var interval = 5000 * 10;
      this.set('changeBubbleVisibilityTimer', setTimeout(function () {
        _this.set('showTypingBubble', false);
      }, interval));
    }),
    statusMessages: Ember.computed.alias('engagement.statusMessages'),
    customerIsTypingMessage: Ember.computed('statusMessages.customerIsTyping', function () {
      var message = this.get('statusMessages.customerIsTyping');
      return message ? message : this.get('intl').t('transcript-window.customer-is-typing');
    }),
    customerStopsTypingMessage: Ember.computed('statusMessages.customerStopsTyping', function () {
      var message = this.get('statusMessages.customerStopsTyping');
      return message ? message : this.get('intl').t('transcript-window.customer-stopped-typing');
    }),
    onAccessKeyPress: Ember.on((0, _emberKeyboard.keyDown)('shift+ctrl+KeyA'), function () {
      this._readNewSystemMessages();
    }),
    onAccessKeyPressCustomerMessages: Ember.on((0, _emberKeyboard.keyDown)('shift+ctrl+KeyZ'), function () {
      if (this.get('isNinaCoach')) {
        this._readNewMessages(0);
      }
    }),
    dataMessages: Ember.observer('engagement.messages.[]', function () {
      this._readNewMessages();
    }),
    /**
     * When the auto transfer modal pops up, acknowledging the modal window will cancel
     * the current reading of all messages in the live region. Restart the reading of new
     * messages if the auto transfer modal is acknowledged.
     */
    preventedAutoTransfer: Ember.observer('engagement.hasPreventedAutoTransfer', function () {
      this._readNewMessages(0);
    }),
    shouldShowTimestamp: Ember.computed.bool('engagement.settings.transcriptTimestamps'),
    init: function init() {
      this._super.apply(this, arguments);
      this.api.on('lastMessageStatusChange', this, this._debounceScrollToBottom);
      this.get('engagement').on('otherAgentAttributesSet', this, this._triggerScrollEvent);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this._engagementCache !== this.engagement) {
        this._engagementCache = this.engagement;

        // when customer sends message when current engagement is not in focus, initially scroll to bottom of transcript
        if (this.get('shouldScrollToBottom')) {
          this._debounceScrollToBottom();
        }
      }
      this.set('cachedCustomerIsTyping', !!this.get('engagement.customer.isTyping'));
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      if (this.get('isNinaCoach')) {
        this._speakReadMessages();
        this._speakNewChatInformation();
      } else {
        this.set('readNewMessagesTimeoutId', setTimeout(function () {
          _this2._readNewMessages();
        }, 3000));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('changeBubbleVisibilityTimer'));
      clearTimeout(this.get('readNewMessagesTimeoutId'));
      Ember.run.cancel(this._runDebounceScrollToBottom);
      Ember.run.cancel(this.get('debounceNewChatInfoId'));
      Ember.run.cancel(this.get('resetQueuedMessagesDebounceId'));
      this.api.off('lastMessageStatusChange', this, this._debounceScrollToBottom);
      this.get('engagement').off('otherAgentAttributesSet', this, this._triggerScrollEvent);
    },
    actions: {
      getTranscriptLineElement: function getTranscriptLineElement(transcriptLine, attributes) {
        if (!(0, _isFromCurrentEngagement.isFromCurrentEngagement)([transcriptLine, this.engagement])) return document.createElement('div');
        var isAgentCoachTranscriptLine = !transcriptLine.get('isEmptyAnswer') && this.get('isAgentCoachEnabled') && transcriptLine.get('isCustomerMessage') && !this.get('isNinaCoach');
        if (isAgentCoachTranscriptLine) return this._createAgentCoachTranscriptLineComponentElement(transcriptLine, attributes);
        return this._createTranscriptLineComponentElement(transcriptLine, attributes);
      },
      onAgentCoachTranscriptLineClick: function onAgentCoachTranscriptLineClick(transcriptLine) {
        this.get('engagement.agentCoach').selectTranscriptLine(transcriptLine);
      },
      focusListRender: function focusListRender() {
        this.element.querySelector('.mnstr').focus();
      },
      onMaskedTranscriptLine: function onMaskedTranscriptLine(transcriptLine) {
        this.get('api').trigger('agentMaskedText', this.get('engagement'));
        var _transcriptLine$getPr = transcriptLine.getProperties('maskedText', 'originalMessageText'),
          maskedText = _transcriptLine$getPr.maskedText,
          originalMessageText = _transcriptLine$getPr.originalMessageText;
        return this.get('api.adapter').maskText(this.get('engagement.id'), originalMessageText, maskedText);
      },
      /**
       * Destroy all transcript line component instances
       * before new list is rendered
       * @method onNewListRender
       */
      onNewListRender: function onNewListRender() {
        this.destroyAllComponentInstances();
      },
      onTranscriptLineCellsUpdated: function onTranscriptLineCellsUpdated() {
        this.onTranscriptLinesRendered && this.onTranscriptLinesRendered();
      },
      /**
       * Destroy transcript line component instance
       * after the component item has been derendered
       * @method destroyTranscriptLineComponentInstance
       */
      destroyTranscriptLineComponentInstance: function destroyTranscriptLineComponentInstance(componentElement) {
        this.destroyComponentInstanceByElement(componentElement);
      }
    },
    /**
     * Manually create an transcript-line/agent-coach component instance and return it's element
     * @method _createAgentCoachTranscriptLineComponentElement
     */
    _createAgentCoachTranscriptLineComponentElement: function _createAgentCoachTranscriptLineComponentElement(transcriptLine) {
      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var agentCoachTranscriptLineObject = Object.assign({
        transcriptLine: transcriptLine,
        enableContextMenuMasking: !this.get('engagement.settings.disableAgentMaskingAbility'),
        showTimestamp: this.get('engagement.settings.transcriptTimestamps'),
        onClick: this.actions.onAgentCoachTranscriptLineClick.bind(this),
        onMask: this.actions.onMaskedTranscriptLine.bind(this),
        ui: this.get('engagement.ui')
      }, attributes);
      return this.createComponentInstanceElement('transcript-window/transcript-line/agent-coach', agentCoachTranscriptLineObject);
    },
    /**
     * Manually create a transcript-line component instance and return it's element
     * @method _createTranscriptLineComponentElement
     */
    _createTranscriptLineComponentElement: function _createTranscriptLineComponentElement(transcriptLine) {
      var _this3 = this;
      var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var onRenderUpdateDebounce = function onRenderUpdateDebounce() {
        return (0, _runloop.debounce)(_this3, _this3._triggerScrollEvent, 100);
      };
      var transcriptLineObject = Object.assign({
        transcriptLine: transcriptLine,
        active: true,
        engagement: this.get('engagement'),
        onMask: this.actions.onMaskedTranscriptLine.bind(this),
        onRenderUpdate: onRenderUpdateDebounce
      }, attributes);
      return this.createComponentInstanceElement('transcript-window/transcript-line', transcriptLineObject);
    },
    /**
     * Increments scrollToBottomIncrement property to scroll to bottom of transcript
     * Debounce to prevent multiple didReceiveAttrs events in list-render component
     * @method _debounceScrollToBottom
     * @public
     * @param {Object} [engagement] engagement that received a new message change
     */
    _debounceScrollToBottom: function _debounceScrollToBottom(engagement) {
      if (engagement && engagement.get('id') !== this.get('engagement.id')) return;
      this._runDebounceScrollToBottom = (0, _runloop.debounce)(this, this.incrementProperty, 'scrollToBottomIncrement', 100);
    },
    _triggerScrollEvent: function _triggerScrollEvent() {
      this.element && this.element.querySelector('.mnstr').dispatchEvent(new Event('scroll'));
    },
    /**
     * Read all new messages that are not system and internal chat messages.
     * @method _readNewMessages
     * @private
     * @param {Number} [startIndex] index to start reading non-data messages
     */
    _readNewMessages: function _readNewMessages(startIndex) {
      var _this4 = this;
      var messages = this.getWithDefault('engagement.messages', []);
      var lastReadMessageIndex = this.getWithDefault('engagement.lastReadChatMessageIndex', 0);
      if (typeof startIndex === 'number') {
        lastReadMessageIndex = startIndex;
      }
      messages.slice(lastReadMessageIndex).forEach(function (message) {
        var isInternalMessage = message.get('isInternal');
        if (isInternalMessage) return;
        var isSystemMessage = message.get('isSystemMessage');
        if (isSystemMessage && !_this4.get('isNinaCoach')) {
          _this4.set('debounceNewChatInfoId', (0, _runloop.debounce)(_this4, _this4._speakNewChatInformation, 3000));
        } else if (!isSystemMessage) {
          _this4._batchA11ySpeakMessage(message);
        }
      });
      this.set('engagement.lastReadChatMessageIndex', messages.length);
    },
    _readNewSystemMessages: function _readNewSystemMessages() {
      var _this5 = this;
      var lastReadDataMessageIndex = this.get('engagement.lastReadDataMessageIndex') || 0;
      var dataMessages = this.getWithDefault('engagement.messages', []).filterBy('isSystemMessage');
      if (lastReadDataMessageIndex === dataMessages.length) {
        this._batchA11ySpeakMessage("".concat(this.get('intl').t('transcript-window.no-new-chat-information')));
      } else {
        dataMessages.slice(lastReadDataMessageIndex).forEach(function (message) {
          _this5._batchA11ySpeakMessage(message);
        });
        this.set('engagement.lastReadDataMessageIndex', dataMessages.length);
      }
    },
    /**
     * Format message and group messages together to ensure proper reading order
     * and to minimize the number of created HTML nodes. After a 1 sec debounce,
     * speak all the messages.
     * @method _batchA11ySpeakMessage
     * @private
     * @param {String|TranscriptLine} [message] message to queue
     * @param {String} [prepend] text that comes before the spoken message
     */
    _batchA11ySpeakMessage: function _batchA11ySpeakMessage(message) {
      var prepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var messageToBeSpoken = this.get('messageToBeSpoken') || '';
      var compiledMessage;
      if (typeof message === 'string') {
        compiledMessage = message;
      } else {
        var timestamp = this.get('shouldShowTimestamp') ? message.get('formattedTimestamp') : '';
        var _message$getPropertie = message.getProperties('prefix', 'messageText'),
          prefix = _message$getPropertie.prefix,
          messageText = _message$getPropertie.messageText;
        compiledMessage = [prepend, timestamp, prefix, messageText].join(', ');
      }
      this.set('messageToBeSpoken', "".concat(messageToBeSpoken, ", ").concat(compiledMessage));
      this.set('resetQueuedMessagesDebounceId', (0, _runloop.debounce)(this, this._speakMessage, 1000));
    },
    _speakMessage: function _speakMessage() {
      (0, _speak.default)(this.get('messageToBeSpoken'));
      this.set('messageToBeSpoken', '');
    },
    _speakReadMessages: function _speakReadMessages() {
      (0, _speak.default)('Press control plus shift plus Z to read messages.');
    },
    _speakNewChatInformation: function _speakNewChatInformation() {
      (0, _speak.default)("".concat(this.get('intl').t('transcript-window.new-chat-information')));
    }
  });
});