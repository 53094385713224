define("sdk/router", ["exports", "sdk/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.routerRootUrl
  });
  Router.map(function () {
    this.route('sdk');
    this.route('lockout');
    this.route('login');
    this.route('agent-group-filter');
    this.route('sso', function () {
      this.route('login');
      this.route('logout');
    });
    this.route('site', {
      path: '/'
    }, function () {
      this.route('engagements', function () {
        this.route('engagement', {
          path: '/:engagement_id'
        });
      });
      this.route('follow-up');
      this.route('nina-coach-training');
      this.route('scripts');
      this.route('summary');
      this.route('training');
      this.route('custom-scripts');
    });
  });
  var _default = _exports.default = Router;
});