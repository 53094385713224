define("sdk/components/quill-editor/component", ["exports", "sdk/api/utils/runloop", "sdk/core/component", "sdk/components/quill-editor/template", "sdk/api/utils/parse-html", "sdk/components/quill-editor/custom-clipboard", "quill", "sdk/utils/browser-type"], function (_exports, _runloop, _component, _template, _parseHtml, _customClipboard, _quill, _browserType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Components.QuillEditor
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    quillInstanceManager: Ember.inject.service(),
    name: 'quill',
    attributeBindings: ['data-focus-index', 'isTyping:data-test-typing'],
    'data-focus-index': 1,
    /**
     * Rate in milliseconds at which the content changed handler is debounced.
     * @property {Number} textChangeDebounceTime
     */
    textChangeDebounceTime: 10,
    classNames: ['quill'],
    'data-test-agent-input': 'plain',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _quill.default.register("modules/clipboard", _customClipboard.default, true);
      var editor = new _quill.default(this.element.querySelector('.quill-editor'), this.getQuillOptions());
      this.set('editor', editor);
      this.dangerouslyPasteHTML(this.get('value'));
      this._addEventListeners();
      if (this.get('isInternal')) {
        this.get('api').on('focusInternalChatInput', this, this.focusInput);
        this.get('quillInstanceManager').setInternalInstance(editor);
      } else {
        this.get('api').on('focusAgentInput', this, this.focusInput);
        this.get('quillInstanceManager').setEditorInstance(editor);
      }
    },
    keyPress: function keyPress(event) {
      //HTMLAI-1763: hack to fix  the problem with cursor position (caused by quill issue, see: https://github.com/quilljs/quill/issues/1463)
      if (_browserType.default.isEdgeOrIE && event.type === 'keypress') {
        var editor = this.get('editor');
        var rangeIndex = editor.selection.savedRange.index;

        //if editor has selected text, we don't call setSelection because it clears the selection before a user starts typing
        if (editor.getSelection().length > 0) return;
        editor.setSelection(rangeIndex, 0);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._removeEventListeners();
    },
    /**
     * Updates the editor's text when value has changed.
     */
    valueChanged: Ember.observer('value', function () {
      var value = this.get('value');
      var $editor = this.$('.ql-editor');
      var editor = this.get('editor');
      if ($editor.length && value && $editor.html() !== value) {
        editor.setText((0, _parseHtml.decodeHtmlEntities)((0, _parseHtml.replacePAndBrTagsWithLineBreaks)(value)));
      } else if ($editor.length && !value) {
        editor.setText('');
      }
    }),
    /**
     * Inserts text into quill editor
     * @method dangerouslyPasteHTML
     */
    dangerouslyPasteHTML: function dangerouslyPasteHTML() {
      var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var editor = this.get('editor');
      if (editor && text) {
        editor.clipboard.dangerouslyPasteHTML(text);
      }
    },
    getQuillOptions: function getQuillOptions() {
      var _ref = this.get('quillOptions') || {},
        _ref$toolbar = _ref.toolbar,
        toolbar = _ref$toolbar === void 0 ? false : _ref$toolbar,
        _ref$formats = _ref.formats,
        formats = _ref$formats === void 0 ? [] : _ref$formats;
      return {
        modules: {
          keyboard: {
            bindings: {
              tab: {
                key: 9,
                // prevent tab increasing indent size in editor
                handler: function handler() {
                  return false;
                }
              },
              enter: {
                key: 13,
                // enter key causes a carriage return in the editor
                // but we use it to send a message
                // (shift+enter is still allowed to go to the editor)
                handler: function handler() {
                  return false;
                }
              }
            }
          },
          toolbar: toolbar,
          clipboard: {
            matchVisual: false
          }
        },
        formats: formats,
        theme: 'snow'
      };
    },
    /**
     * Places focus at end of Quill editable div
     * @method focusInput
     */
    focusInput: function focusInput() {
      var editor = this.get('editor');
      if (!editor) return;

      // manually adding focus to editor to fix disappearing text bug in IE
      editor.focus();
      Ember.run.scheduleOnce('afterRender', this, this._placeFocusAtEndOfInput);
    },
    /**
     * Sets selection to end of editor text
     * @method _placeFocusAtEndOfInput
     */
    _placeFocusAtEndOfInput: function _placeFocusAtEndOfInput() {
      var editor = this.get('editor');
      var length = editor.getLength();
      setTimeout(function () {
        return editor.setSelection(length, length);
      }, 0);
    },
    /**
     * Adds event listeners to the editor.
     * @method _addEventListeners
     * @private
     */
    _addEventListeners: function _addEventListeners() {
      // no clean way to remove styling/images from drag and drop events
      // so we prevent any dragging/dropping of content into the editor
      this.$('.quill-editor').on('drop.quill-editor', function (e) {
        e.preventDefault();
        e.stopPropagation();
      });

      // quill does not update this attr the first time so we force it here
      Ember.$('.ql-picker-item').on('click.quill-editor', function (e) {
        var value = Ember.$(e.target).data('value');
        Ember.run(function () {
          // used to display current bg color
          Ember.$('.ql-picker-label').attr('data-value', value || null);
          // force color picker to hide upon selecting bg color
          Ember.$('.ql-background.ql-picker.ql-color-picker').removeClass('ql-expanded');
        });
      });

      // prevent buttons from removing text selection when choosing background color
      Ember.$('.ql-formats [role="button"]').on('mousedown.quill-editor', function (e) {
        Ember.run(function () {
          e.preventDefault();
        });
      });
      var editor = this.get('editor');
      var maxLength = this.get('maxLength');
      if (maxLength) {
        editor.on('text-change', function (delta, oldValue) {
          var oldValueInEditor = oldValue.ops.findBy('insert').insert;
          if (oldValueInEditor.length - 1 >= maxLength) {
            var ops = delta.ops;
            var updatedOps = [];
            if (ops && ops.findBy('insert')) {
              if (ops.length === 1) {
                // text is inserted at the beginning
                updatedOps = [{
                  delete: ops[0].insert.length
                }];
              } else {
                if (ops[1].insert) {
                  //if we type or try paste text not at the beginning, we need to delete unnecessary text
                  updatedOps = [ops[0], {
                    delete: ops[1].insert.length
                  }];
                } else {
                  //when we use shift+click_on_script to send script into input, we need to return old value in agentInput after using _sendToAgentInput
                  //oldValueInEditor = '123...123'
                  //ops[0].insert = '123...123 ScriptText'
                  updatedOps = [{
                    insert: oldValueInEditor.length
                  }];
                }
              }
              editor.updateContents({
                ops: updatedOps
              });
            }
          }
        });
      }
      editor.on('text-change', this._debounceTextChange.bind(this));

      // update active background color
      editor.on('selection-change', function () {
        var selection = editor.getSelection();
        if (!selection) return;
        var format = editor.getFormat(selection);
        if (!format || !format.background) return;
        var background = format.background;
        var backgroundToDisplay = typeof background === 'string' ? background : background[0];
        Ember.$('.ql-background .ql-picker-label').attr('data-value', backgroundToDisplay.toUpperCase());
      });
      this.$('.ql-editor').blur(function () {
        Ember.$(this).removeAttr('aria-label');
      });
    },
    _setStopTyping: function _setStopTyping() {
      this.set('isTyping', false);
    },
    _debounceTextChange: function _debounceTextChange() {
      this.set('isTyping', true);
      _runloop.debounce.apply(void 0, [this, this._onTextChange].concat(Array.prototype.slice.call(arguments), [this.get('textChangeDebounceTime')]));
      (0, _runloop.debounce)(this, this._setStopTyping, this.get('textChangeDebounceTime') + 10);
    },
    /**
    * Sets the value to the contents of the editor.
    */
    _onTextChange: function _onTextChange(delta, oldDelta, source) {
      var $editor = this.$('.ql-editor');
      if (!$editor) return;
      var maxLength = this.get('maxLength');

      //limit quill-editor length
      if (maxLength && this.editor.getLength() > maxLength) {
        this.editor.deleteText(maxLength, this.editor.getLength());
      }
      var contents = $editor.html();
      if (!contents) return;
      var onTextChange = this.get('onTextChange');
      onTextChange && onTextChange(contents, delta, oldDelta, source);
      this.set('value', contents);
    },
    /**
     * Removes event listeners from the editor.
     * @method _removeEventListeners
     * @private
     */
    _removeEventListeners: function _removeEventListeners() {
      this.$('.ql-editor').off('drop.quill-editor');
      Ember.$('.ql-picker-item, .quill-editor, .ql-formats [role="button"]').off();
      Ember.$('.ql-formats [role="button"]').off('mousedown.quill-editor');

      // remove event listeners from active-transcript-window/component.js when quill editor is destroyed
      Ember.$('.ql-picker-label, .ql-picker-options').off();
      this.$('.ql-editor').off();
      var editor = this.get('editor');
      editor.off('selection-change');
      editor.off('text-change');
      this.get('api').off('focusAgentInput', this, this.focusInput);
      this.get('api').off('focusInternalChatInput', this, this.focusInput);
    }
  });
});