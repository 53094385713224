define("sdk/components/video-chat/component", ["exports", "sdk/api/utils/media-devices"], function (_exports, _mediaDevices) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    devices: Ember.inject.service(),
    componentName: 'video-chat',
    classNames: 'video-chat',
    classNameBindings: ['isCompressed:compressed:expanded'],
    'data-test-video-chat': '',
    isFullScreen: Ember.computed.equal('engagement.ui.layout.name', 'full-screen-call'),
    isCompressed: Ember.computed.not('isFullScreen'),
    isIncomingCall: Ember.computed.reads('engagement.isIncomingCall'),
    selectedAudioOutputId: Ember.computed.reads('api.agent.selectedAudioDevice.deviceId'),
    isMicMuted: Ember.computed.alias('engagement.isMicMuted'),
    didVideoRoomCreationFail: Ember.computed.alias('engagement.didVideoRoomCreationFail'),
    showEndCallConfirmation: Ember.computed.not('engagement.videoCallHasEnded'),
    displayCustomerAvatar: Ember.computed('engagement.{isVoiceChat,settings.enabledCustomerCamera}', function () {
      return this.get('engagement.isVoiceChat') || !this.get('engagement.settings.enabledCustomerCamera');
    }),
    agentInitial: Ember.computed('engagement.settings.agentAlias', 'api.agent.userId', function () {
      var initial = this.get('engagement.settings.agentAlias.0') || this.get('api.agent.userId.0');
      return initial.toUpperCase();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('devicesSetup', this, this.displayAgentVideo);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('api.agent.selectedVideoDevice')) {
        this.displayAgentVideo();
      }
      var engagement = this.get('engagement');
      if (engagement.webRTC && engagement.webRTC.publisher) {
        engagement.webRTC.attachRemoteFeed(engagement.webRTC.publisher);
      }
      var onDidInsertElement = this.get('onDidInsertElement');
      onDidInsertElement && onDidInsertElement();
    },
    displayAgentVideo: function displayAgentVideo() {
      var _this = this;
      if (this.get('agentVideoStream')) (0, _mediaDevices.stopStream)(this.get('agentVideoStream'));
      this.get('devices').checkoutVideoDevice().then(function (stream) {
        return _this.set('agentVideoStream', stream);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _mediaDevices.stopStream)(this.get('agentVideoStream'));
      this.get('api').off('devicesSetup', this, this.displayAgentVideo);
    },
    actions: {
      acceptCall: function acceptCall() {
        var onAcceptCall = this.get('onAcceptCall');
        onAcceptCall && onAcceptCall();
      },
      declineCall: function declineCall() {
        if (this.get('agentVideoStream')) (0, _mediaDevices.stopStream)(this.get('agentVideoStream'));
        this.engagement.declineCall();
      },
      openDeviceSettingsModal: function openDeviceSettingsModal() {
        this.get('devices').setShowDeviceSettings(true);
      },
      toggleMic: function toggleMic() {
        this.engagement.toggleMic();
      },
      endCall: function endCall() {
        var endCall = this.get('endCall');
        endCall && endCall();
      },
      onEndCall: function onEndCall() {
        var onEndCall = this.get('onEndCall');
        onEndCall && onEndCall();
      }
    }
  });
});