define("sdk/components/popup-window/window-default/component", ["exports", "sdk/core/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    attributeBindings: ['data-focus-index'],
    'data-focus-index': 3
  });
});