define("sdk/plugins/last-survey/plugin", ["exports", "sdk/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _api.Plugin.extend({
    name: 'last-survey',
    display: 'customer',
    init: function init() {
      this.display = (this.data.display || this.display).split(',');
    },
    run: function run() {
      if (this.display.indexOf('tools') > -1) {
        this.ui.addPanel('tools', {
          title: 'Survey',
          data: this.data.survey,
          component: 'plugins/last-survey'
        });
      }
    }
  });
});