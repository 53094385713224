define("sdk/components/sdk-scripts/script/script-line/component", ["exports", "sdk/configuration/index", "sdk/components/sdk-scripts/script/script-line/template", "sdk/api/utils/runloop", "sdk/api/utils/parse-html", "sdk/api/utils/linkify", "sdk/core/component"], function (_exports, _index, _template, _runloop, _parseHtml, _linkify, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Clickable script
   *
   * @class Scripts.ScriptLine
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    attributeBindings: ['role'],
    componentName: 'script-line',
    classNameBindings: ['script.hasInput:has-input', 'scriptTypeClass'],
    intl: Ember.inject.service(),
    tabindex: Ember.computed('hasSection', 'filter', function () {
      return !this.get('hasSection') || this.get('filter') ? '0' : '-1';
    }),
    role: 'none',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('scriptTypeClass', "script-line--".concat(this.script.type));
      if (this.script.isHeader) {
        this.set('shouldSubmit', false);
      } else {
        this.set('shouldSubmit', !this.script.isHeader && this.isTraining ? this.script.hasInput : true);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setAriaLabel();
    },
    setAriaLabel: function setAriaLabel() {
      var isTraining = this.get('isTraining');
      var valueJSON = this.get('script.valueJSON');
      if (valueJSON) {
        var agentDisplayText = valueJSON.agentDisplayText,
          agentDisplayDescription = valueJSON.agentDisplayDescription,
          agentMessage = valueJSON.agentMessage;
        var ariaLabel = "".concat(isTraining ? 'Training script, ' : '') + "Rich Widget. " + "".concat(agentDisplayText ? agentDisplayText.endsWith('.') ? agentDisplayText : agentDisplayText + '.' : '', " ") + "".concat(agentDisplayDescription ? agentDisplayDescription.endsWith('.') ? agentDisplayDescription : agentDisplayDescription + '.' : '', " ") + "".concat(agentMessage ? 'Agent Message, ' + agentMessage : '');
        this.set('aria-label', ariaLabel);
      } else if (isTraining) {
        this.set('aria-label', "Training script, ".concat(this.$().text().replace(/"/g, '\\"')));
      }
    },
    /**
    * Rendered HTML of script value
    * @property displayedHTML
    * @type String
    */
    displayedHTML: Ember.computed('script.encodedValue', 'filter', function () {
      var displayedHTML = this.get('script.encodedValue');
      displayedHTML = (0, _linkify.linkify)(displayedHTML, {
        emptyTextPlaceholder: this.get('intl').t('link'),
        tagName: 'span'
      });

      // Agent-Pushed
      if (this.get('script.isAgentPushed')) {
        displayedHTML = (0, _parseHtml.walkElementNodes)(displayedHTML, function (elementNode) {
          if (elementNode.hasAttribute('data-automaton-id')) {
            elementNode.classList.add('agent-pushed');
          }
        });
      }

      // Input Script
      if (this.get('script.hasInput')) {
        this.get('script.inputProperties').forEach(function (inputProperty) {
          var placeholder = "<span class=\"input-script-placeholder\">".concat(inputProperty.placeholder, "</span>");
          displayedHTML = displayedHTML.replace(inputProperty.inlineScript, placeholder);
        });
      }

      // Filter / Search
      if (this.get('filter') && this.get('filter').trim()) {
        displayedHTML = (0, _parseHtml.searchAndReplaceNonHtml)(displayedHTML, this.get('filter'), '<span data-test-script-match class="match">$&</span>', {
          encoded: true
        });
      }
      return Ember.String.htmlSafe(displayedHTML);
    }),
    handleClick: function handleClick(event) {
      var _this = this;
      var onEvent = this.get('onEvent');
      var hasSingleClickSetting = this.get('api.agent.settings.clicking') === 'singleClick';
      if (hasSingleClickSetting) {
        return onEvent && onEvent(this.get('script'), event);
      }
      var isDoubleClick = !!this.get('singleClickEventTimer');
      if (isDoubleClick) {
        Ember.run.cancel(this.get('singleClickEventTimer'));
        this.set('singleClickEventTimer', null);
        event.customType = 'dblclick';
        return onEvent && onEvent(this.get('script'), event);
      }
      this.set('singleClickEventTimer', (0, _runloop.later)(function () {
        Ember.run.cancel(_this.get('singleClickEventTimer'));
        _this.set('singleClickEventTimer', null);
        return onEvent && onEvent(_this.get('script'), event);
      }, _index.default.DOUBLE_CLICK_THRESHOLD));
    },
    actions: {
      submitScript: function submitScript(event) {
        this.handleClick(event);
      },
      onPreviewRichWidget: function onPreviewRichWidget(event) {
        event.stopPropagation(); // prevent bubbling event up to parent script button
        this.onPreviewRichWidget && this.onPreviewRichWidget(this.script.richWidget);
      }
    }
  });
});