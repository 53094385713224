define("sdk/utils/user-agent-parser", ["exports", "ua-parser"], function (_exports, _uaParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * Parses navigator.userAgent into 5 possible properties:
   * - browser
   * - engine
   * - os
   * - device
   * - cpu
   * Properties that have values will be returned
   * @param {String} userAgent
   */
  function _default() {
    var userAgent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var parser = new _uaParser.default();
    parser.setUA(userAgent);
    var _parser$getResult = parser.getResult(),
      browser = _parser$getResult.browser,
      engine = _parser$getResult.engine,
      os = _parser$getResult.os,
      device = _parser$getResult.device,
      cpu = _parser$getResult.cpu;
    var browserName = browser.name,
      browserVersion = browser.version;
    var engineName = engine.name,
      engineVersion = engine.version;
    var osName = os.name,
      osVersion = os.version;
    var deviceName = device.name,
      deviceVersion = device.version;
    var cpuName = cpu.name,
      cpuVersion = cpu.version;
    var parsedUserAgent = {};
    if (browserName) {
      parsedUserAgent.browser = browserName + (browserVersion ? ' ' + browserVersion : '');
    }
    if (engineName) {
      parsedUserAgent.engine = engineName + (engineVersion ? ' ' + engineVersion : '');
    }
    if (osName) {
      parsedUserAgent.os = osName + (osVersion ? ' ' + osVersion : '');
    }
    if (deviceName) {
      parsedUserAgent.device = deviceName + (deviceVersion ? ' ' + deviceVersion : '');
    }
    if (cpuName) {
      parsedUserAgent.cpu = cpuName + (cpuVersion ? ' ' + cpuVersion : '');
    }
    return parsedUserAgent;
  }
});