define("sdk/components/sdk-scripts/hotkey-dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ziA/TG0Y",
    "block": "{\"symbols\":[\"hotkey\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"selected\",\"onchange\",\"onfocus\",\"class\",\"tagName\",\"searchEnabled\",\"renderInPlace\",\"initiallyOpened\"],[[22,[\"possibleHotkeys\"]],[22,[\"activeHotkey\"]],[26,\"action\",[[21,0,[]],\"sendScriptToInput\"],null],[26,\"action\",[[21,0,[]],\"onFocus\"],null],\"hotkey-dropdown\",\"div\",false,true,true]],{\"statements\":[[0,\"  \"],[1,[21,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/sdk-scripts/hotkey-dropdown/template.hbs"
    }
  });
});