define("sdk/components/sdk-scripts/hotkey-dropdown/component", ["exports", "sdk/api/utils/parse-html", "sdk/core/component", "sdk/components/sdk-scripts/hotkey-dropdown/template"], function (_exports, _parseHtml, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'hotkey-dropdown',
    activeHotkey: Ember.computed.alias('api.agent.activeHotkey'),
    allScripts: Ember.computed.alias('ui.selectedScriptTree.allScripts'),
    sendScriptIfMatch: Ember.observer('activeHotkey', function () {
      var _this = this;
      Ember.run.once(function () {
        var activeHotkey = _this.get('activeHotkey');
        if (!activeHotkey) return;
        var allScripts = _this.get('allScripts');
        var script = allScripts.find(function (script) {
          return script.get('hotKey') === activeHotkey;
        });
        if (script) {
          _this.send('sendScriptToInput', activeHotkey);
        }
      });
    }),
    focusHotkeyDropdown: Ember.observer('ui.selectHotkeyDropdownEvent', function () {
      var _this2 = this;
      Ember.run.once(function () {
        var event = _this2.get('ui.selectHotkeyDropdownEvent');
        if (!event) return;
        var trigger = '.hotkey-dropdown .ember-power-select-trigger';
        _this2.$(trigger).focus();
      });
    }),
    actions: {
      /**
       * Finds the script with the given hotkey
       * and appends its text to the agent input.
       * @method setActiveHotkey
       * @param {String} hotkey
       * @public
       */
      sendScriptToInput: function sendScriptToInput(hotkey) {
        var _this3 = this;
        var ui = this.get('ui');
        var engagement = this.get('engagement');
        var script = this.get('allScripts').find(function (script) {
          return script.get('hotKey') === hotkey;
        });
        ui.set('agentInputScript', {
          scriptText: script.get('scriptValue'),
          scriptType: 'system'
        });
        var newAgentInput = '';
        var isV3Active = this.get('api.agent.features.transcriptV3');
        var currentAgentInput = isV3Active ? engagement.get('input.value') : ui.get('agentInput');
        var agentInputIsEmpty = !currentAgentInput || currentAgentInput && currentAgentInput === '<p><br></p>';
        if (!agentInputIsEmpty) {
          newAgentInput = "".concat(currentAgentInput) + '\n';
        }
        newAgentInput += (0, _parseHtml.encodeCarets)(script.get('scriptValue'));
        ui.set('agentInput', newAgentInput);
        engagement.setInput({
          value: newAgentInput,
          inputValueUpdatedFromApi: true,
          scriptText: script.get('scriptValue'),
          scriptType: 'system'
        });
        this.get('api').trigger('focusAgentInput');
        Ember.run.schedule('actions', function () {
          // solves a timing expectation issue with ember-power-select
          // https://github.com/cibernox/ember-power-select/issues/667
          _this3.resetHotkey();
        });
      },
      /**
       * Highlights the first option when focused.
       * @method onFocus
       * @param {Object} options ember-power-select API
       * @public
       */
      onFocus: function onFocus(options) {
        options.actions.highlight(options.options[0]);
      }
    }
  });
});