define("sdk/api/adapters/htapi-adapter", ["exports", "sdk/api/adapters/api-adapter", "sdk/api/adapters/rtapi-adapter"], function (_exports, _apiAdapter, _rtapiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class HistoricTranscriptAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    basicAuth: true,
    name: 'HTAPI',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.HISTORIC_TRANSCRIPT_API;
    },
    /**
     * Retrieves a list of engagements from the API
     * filtered by customer id
     * @method getTranscriptListByCustomerId
     * @public
     * @param {String} filter the search filter
     * @param {String} siteId the site ID
     */
    getTranscriptListByFilter: function getTranscriptListByFilter(filter, siteId) {
      var query = {
        site: siteId,
        filter: filter,
        returnFields: 'engagementID,customerID,startDate,siteID',
        rows: 100
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        if (response.engagements) {
          response.engagements = response.engagements.map(function (e) {
            e.source = 'historic';
            e.startTime = e.startDate.timestamp;
            return Ember.Object.create(e);
          });
        }
        return response;
      });
    },
    /**
     * Retrieves an engagement transcript from the API by id
     * @method getEngagement
     * @public
     * @param {String} engagementId value is applied to api search filter
     * @param {Object} options additional optional params
     * @param {String} options.site the site ID
     */
    getEngagement: function getEngagement(engagementId) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var filterAttributes = options.filterAttributes,
        _options$site = options.site,
        site = _options$site === void 0 ? '' : _options$site;
      var query = {
        site: site,
        filter: "engagementID=\"".concat(engagementId, "\""),
        returnFields: 'transcript,agents'
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        var _response$engagements = response.engagements,
          engagements = _response$engagements === void 0 ? [] : _response$engagements;
        var _ref = engagements[0] || {},
          agents = _ref.agents,
          _ref$transcript = _ref.transcript,
          transcript = _ref$transcript === void 0 ? [] : _ref$transcript;
        return {
          agents: agents,
          messages: (0, _rtapiAdapter.normalizeMessages)(transcript, {
            agents: agents,
            currentAgentId: _this.get('api.agent.userId'),
            filterAttributes: filterAttributes,
            source: 'historic'
          })
        };
      });
    },
    getEngagements: function getEngagements() {
      var _this2 = this;
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var filter = options.filter,
        _options$filterAttrib = options.filterAttributes,
        filterAttributes = _options$filterAttrib === void 0 ? [] : _options$filterAttrib,
        siteId = options.siteId;
      var query = {
        site: siteId,
        filter: filter,
        returnFields: 'agents,engagementID,conversationID,customerID,startDate,siteID,transcript',
        rows: 100
      };
      return this.ajax({
        query: query
      }).then(function (response) {
        var _response$engagements2 = response.engagements,
          engagements = _response$engagements2 === void 0 ? [] : _response$engagements2;
        return engagements.map(function (_ref2) {
          var agents = _ref2.agents,
            conversationID = _ref2.conversationID,
            engagementID = _ref2.engagementID,
            startDate = _ref2.startDate,
            transcript = _ref2.transcript;
          return {
            agents: agents,
            conversationId: conversationID,
            id: engagementID,
            source: 'historic',
            startTime: Number(startDate.timestamp),
            messages: (0, _rtapiAdapter.normalizeMessages)(transcript, {
              agents: agents,
              currentAgentId: _this2.get('api.agent.userId'),
              filterAttributes: filterAttributes,
              source: 'historic',
              useStorageProps: true
            })
          };
        });
      });
    }
  });
});