define("sdk/utils/speak", ["exports", "dompurify"], function (_exports, _dompurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = speak;
  /**
   * Allows the screenreader to register text that needs to be read to the user.
   * @method speak
   * @param {String} text text to speak
   * @param {Object} options additional attributes
   * @param {String} options.ariaLiveValue [ariaLiveValue="polite"] aria live value
   * @param {String} options.role role attribute value
   */
  function speak(text) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      ariaLiveValue: 'polite'
    };
    if (!text) return;
    text = _dompurify.default.sanitize(text);
    var div = document.createElement('div');
    var role = options.role,
      ariaLiveValue = options.ariaLiveValue;
    // use role="alert" to announce text in FF, because aria-live one  does not work
    if (role) div.setAttribute('role', role);
    div.setAttribute('aria-live', ariaLiveValue);
    div.className = 'a11y-hidden';
    document.body.appendChild(div);

    // Node must be present in the DOM before text is added, otherwise the
    // screenreader won't know about the update.
    window.setTimeout(function () {
      div.innerHTML = text;

      // Remove the node after a specified time.
      window.setTimeout(function () {
        document.body.removeChild(div);
      }, 3000);
    }, 500);
  }
});