define("sdk/api/utils/truncate", ["exports", "sdk/api/utils/parse-html"], function (_exports, _parseHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = truncate;
  function truncate(text) {
    var maxLength = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
    var isTruncated = false;
    var missingTextLength = 0;
    var truncatedTextLength = 0;
    var truncatedText = (0, _parseHtml.walkNodes)(text, function (node) {
      var nodeType = node.nodeType,
        nodeValue = node.nodeValue,
        parentNode = node.parentNode,
        previousSibling = node.previousSibling,
        textContent = node.textContent;
      if (isTruncated) {
        // remove nodes after being truncated
        missingTextLength += textContent.length;
        if (nodeType === Node.TEXT_NODE) {
          var newTextNode = document.createTextNode('');
          parentNode.replaceChild(newTextNode, node);
          return newTextNode;
        } else if (nodeType === Node.ELEMENT_NODE) {
          parentNode.removeChild(node);
          return previousSibling;
        }
      } else if (nodeType === Node.TEXT_NODE) {
        if (nodeValue.length + truncatedTextLength >= maxLength) {
          var truncateLength = maxLength - truncatedTextLength;
          var _truncatedText = nodeValue.substring(0, truncateLength);
          missingTextLength += nodeValue.length - truncateLength;
          truncatedTextLength += _truncatedText.length;
          isTruncated = true;
          var _newTextNode = document.createTextNode(_truncatedText);
          parentNode.replaceChild(_newTextNode, node);
          return _newTextNode;
        } else {
          truncatedTextLength += nodeValue.length;
        }
      }
    });
    return {
      hasTruncatedText: isTruncated,
      textLength: truncatedTextLength + missingTextLength,
      truncatedText: truncatedText
    };
  }
});