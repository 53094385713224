define("sdk/components/rich-widget/component", ["exports", "sdk/core/component", "sdk/components/rich-widget/template", "richengine", "sdk/utils/clone"], function (_exports, _component, _template, _richengine, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'rich-widget',
    'data-test-rich-widget': true,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.element && this._richWidgetCache !== this.richWidget) {
        this._richWidgetCache = this.richWidget;
        this.renderRichWidget();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.renderRichWidget();
    },
    renderRichWidget: function renderRichWidget() {
      this.set('errorMessage', undefined);
      var widgetContainerNode = this.element.querySelector('.rich-widget__container');

      // remove all dom children before rendering rich content
      widgetContainerNode.querySelectorAll('*').forEach(function (node) {
        return node.parentNode && node.parentNode.removeChild(node);
      });
      try {
        _richengine.default.instance.renderRichContent((0, _clone.default)(this.richWidget), widgetContainerNode, "", false);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        this.set('errorMessage', this.intl.t('rich-widget.error-render'));
      }
    }
  });
});