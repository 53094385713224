define("sdk/services/engagement-layout-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),
    agent: Ember.computed.alias('api.agent'),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('api').on('engagementDidInitialize', this, this._setInitialLayout);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('api').off('engagementDidInitialize', this, this._setInitialLayout);
    },
    /**
     * Updates the agent's layoutSettings
     * @method saveLayout
     * @param {String} layoutName selected layout name
     */
    saveLayout: function saveLayout(layoutName, engagement) {
      var _this = this;
      var newLayoutSettings = {
        layout: layoutName,
        layoutCustom: this.get('agent.settings.layoutCustom')
      };
      return this.get('agent').updateLayoutSetting(newLayoutSettings).finally(function () {
        // reset current engagement layout
        if (engagement) {
          _this._resetLayout(engagement);
          _this.get('api.storage').persistEngagement(engagement);
        }
      });
    },
    /**
     * Create new custom layout based on engagement's layout settings
     * @method createCustomLayout
     * @param {Engagement} engagement
     */
    createCustomLayout: function createCustomLayout(engagement) {
      var prototype = engagement.get('ui.layout.name');
      return this._saveCustomLayout(engagement, prototype);
    },
    /**
     * Overwrites current agent custom layout with engagement's layout settings
     * @method updateCustomLayout
     * @param {Engagement} engagement
     */
    updateCustomLayout: function updateCustomLayout(engagement) {
      var layoutName = engagement.get('ui.layout.name');
      var prototype = layoutName === 'custom' ? this.get('agent.settings.layoutCustom.prototype') : layoutName;
      return this._saveCustomLayout(engagement, prototype);
    },
    _saveCustomLayout: function _saveCustomLayout(engagement, prototype) {
      engagement.set('ui.layout.name', 'custom');
      engagement.set('ui.layout.prototype', prototype);
      Ember.setProperties(this.get('agent.settings'), {
        layout: 'custom',
        layoutCustom: engagement.get('ui.layout')
      });
      return this.saveLayout('custom', engagement);
    },
    hasCustomLayout: function hasCustomLayout() {
      return !!this.get('agent.settings.layoutCustom.prototype');
    },
    /**
     * Compares engagement's layout with agent default layout
     * @method hasModifiedLayout
     * @param {Engagement} engagement
     */
    hasModifiedLayout: function hasModifiedLayout(engagement) {
      if (!engagement) return false;
      var _Ember$getProperties = Ember.getProperties(this.get('agent.settings'), ['layout', 'layoutCustom']),
        selectedLayoutName = _Ember$getProperties.layout,
        layoutCustom = _Ember$getProperties.layoutCustom;
      var engagementLayout = engagement.get('ui.layout');
      var isModified = Object.keys(engagementLayout).reduce(function (isModified, layoutProperty, index, engagementLayoutArray) {
        if (isModified) return true;
        if (layoutProperty === 'internalChatInputHeight') return false;
        if (layoutProperty === 'name') return engagementLayout.name !== selectedLayoutName;
        if (!layoutCustom) return true;
        if (engagementLayoutArray.length !== Object.keys(layoutCustom).length) return true;
        return engagementLayout[layoutProperty] !== layoutCustom[layoutProperty];
      }, false);
      return isModified;
    },
    /**
     * triggers resize events for each resizable component
     * @method onComponentResize
     * @param {String} componentName component's name
     * @param {Object} resizeOptions resizing option object (resizable-component)
     */
    onComponentResize: function onComponentResize() {
      var componentName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var resizeOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // ex: active-transcript => activeTranscriptResize
      var eventName = "".concat(Ember.String.camelize(componentName), "Resize");
      this.trigger(eventName, resizeOptions);
    },
    onComponentExpanded: function onComponentExpanded() {
      var componentName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var eventName = "".concat(Ember.String.camelize(componentName), "Expand");
      this.trigger(eventName);
    },
    /**
     * initialize engagement layout default settings
     * @method _setInitialLayout
     * @param {Engagement} engagement
     */
    _setInitialLayout: function _setInitialLayout(engagement) {
      var layoutName = this.get('agent.settings.layout');
      var layout = layoutName === 'custom' ? Object.assign({}, this.get('agent.settings.layoutCustom')) : {
        name: layoutName
      };
      Ember.set(engagement, 'ui.layout', layout);
    },
    _resetLayout: function _resetLayout(engagement) {
      var layoutName = this.get('agent.settings.layout');
      if (layoutName === 'custom') {
        Ember.set(engagement, 'ui.layout', Object.assign({}, this.get('agent.settings.layoutCustom')));
      } else {
        Ember.set(engagement, 'ui.layout', {
          name: layoutName
        });
      }
    }
  });
});