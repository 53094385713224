define("sdk/api/models/script-line", ["exports", "sdk/api/utils/encode", "sdk/api/utils/json", "sdk/api/classes/abc-widget", "sdk/api/classes/rich-widget"], function (_exports, _encode, _json, _abcWidget, _richWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * @class Object.Script
   */
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      if (this.isRichWidget) {
        this.richWidget = new _richWidget.default(this.get('valueJSON'));
      }
      if (this.isAbcWidget) {
        this.abcWidget = new _abcWidget.default(this.get('valueJSON'));
      }
    },
    encodedValue: Ember.computed('value', function () {
      return (0, _encode.encodeAgentText)(this.get('value'));
    }),
    /**
     * The script value/ text
     * @property {String} value
     */
    value: Ember.computed('scriptValue', function () {
      var value = this.get('scriptValue');
      // use html entities for script keywords so they are not rendered as an html tag
      // ex: <<AGENTNAME>> => &lt;&lt;AGENTNAME&gt;&gt;
      var valueEntities = value.replace(/<<([a-zA-Z]+)>>/g, '&lt;&lt;$1&gt;&gt;');
      return valueEntities;
    }),
    valueJSON: Ember.computed('value', function () {
      return (0, _json.getJSON)(this.get('value'));
    }),
    /**
     * Script type can be one of the following
     * - Heading
     * - Push New
     * - Text
     * @property {String} type
     */
    type: Ember.computed.alias('scriptType'),
    /**
     * @property {Boolean} isHeader
     */
    isHeader: Ember.computed.equal('type', 'header'),
    /**
     * @property {Boolean} isText
     */
    isText: Ember.computed.equal('type', 'text'),
    /**
     * @property {Boolean} isCustom
     */
    isCustom: Ember.computed.equal('type', 'custom'),
    /**
     * @property {Boolean} isAgentPushed
     */
    isAgentPushed: Ember.computed('isText', 'value', function () {
      if (!this.get('isText')) return false;
      return /data-automaton-id/.test(this.get('value'));
    }),
    /**
     * @property {Boolean} isCommand
     */
    isCommand: Ember.computed.equal('type', 'command'),
    isAbcWidget: Ember.computed.bool('valueJSON.interactiveData'),
    isRichWidget: Ember.computed.bool('valueJSON.richWidget'),
    /**
     * @property {Boolean} hasInput
     */
    hasInput: Ember.computed('value', 'command', function () {
      var regex = /{{\*{2}.*\*{2}}}/;
      var text = this.get('isCommand') ? this.get('command') : this.get('value');
      return regex.test(text);
    }),
    /**
     * Parses the input script into an array of objects containing
     * 3 properties: inlineScript, placeholder, maxlength
     * @method inputProperties
     * @return {Array} an array of objects containing inlineScript, placeholder, maxlength
     */
    inputProperties: Ember.computed(function () {
      if (!this.get('hasInput')) {
        throw new Error('Script must have inputs to get input properties');
      }
      var regex = /{{\*{2}(.*?)(\|{2}(\d+))?\*{2}}}/g;
      var text = this.get('isCommand') ? this.get('command') : this.get('value');
      var inlineValues = [];
      var tmpArr;
      while ((tmpArr = regex.exec(text)) !== null) {
        inlineValues.push(tmpArr);
      }
      return inlineValues.map(function (inputProperties) {
        var _inputProperties = _slicedToArray(inputProperties, 4),
          inlineScript = _inputProperties[0],
          placeholder = _inputProperties[1],
          hasMaxLength = _inputProperties[2],
          maxlength = _inputProperties[3]; // eslint-disable-line no-unused-vars

        return {
          inlineScript: inlineScript,
          placeholder: placeholder,
          maxlength: maxlength
        };
      });
    }),
    getAgentMessageText: function getAgentMessageText() {
      if (this.isAbcWidget) return this.valueJSON.agentMessage || '';
      if (this.isRichWidget) return this.richWidget.agentMessage;
      return this.value;
    }
  });
});