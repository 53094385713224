define("sdk/components/co-browse/component", ["exports", "sdk/core/component", "sdk/components/co-browse/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    cobrowse: Ember.inject.service(),
    componentName: 'co-browse',
    disabledColor: '#aaaaaa',
    classNameBindings: ['isActive::inactive'],
    engagement: Ember.computed.alias('data'),
    siteId: Ember.computed.alias('engagement.settings.siteId'),
    cobrowseProperties: Ember.computed.alias('engagement.cobrowse'),
    cobrowseActive: Ember.computed.alias('engagement.cobrowseAccepted'),
    isActive: Ember.computed.and('cobrowseActive', 'engagement.customer.connected'),
    isDisconnected: Ember.computed.not('iframe.connected'),
    highlightColor: Ember.computed.alias('cobrowseProperties.highlightColor'),
    isChatWindowVisible: Ember.computed.alias('cobrowseProperties.isChatWindowVisible'),
    isColorPickerActive: Ember.computed.alias('cobrowseProperties.isColorPickerActive'),
    /**
     * If cobrowse in "no-shared" mode - highlight configurable from portal, if cobrowse in "shared" mode - highlight is always enabled.
     */
    isHighlightDisabled: Ember.computed('cobrowseProperties.highlight', 'engagement.cobrowseSharedAccepted', function () {
      var cobrowseHighlight = this.get('cobrowseProperties.highlight');
      var cobrowseSharedAccepted = this.get('engagement.cobrowseSharedAccepted');
      return !(cobrowseHighlight || cobrowseSharedAccepted);
    }),
    activeObserver: Ember.observer('isActive', function () {
      if (this.get('isActive')) {
        this._showIframe();
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this._addWindowMessageListener();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('isActive')) {
        this._showIframe();
      }
    },
    _showIframe: function _showIframe() {
      var siteId = this.get('siteId');
      var iframe = this.get('cobrowse').getIframeBySiteId(siteId);
      this.set('iframe', iframe);
      this.get('cobrowse').set('activeIframe', iframe);
      this._sendCommand('showCobrowse');
    },
    actions: {
      setHighlightColor: function setHighlightColor(hexColor) {
        this.send('closeColorPicker');
        this._sendCommand('setColor', {
          color: getColorValue(hexColor)
        });
      },
      closeColorPicker: function closeColorPicker() {
        this.set('isColorPickerActive', false);
      },
      sendCommand: function sendCommand(command) {
        this._sendCommand(command);
      },
      /**
       * Execute cobrowse end by agent and send ETL event.
       * @return void
       */
      sendAgentEndCobrowse: function sendAgentEndCobrowse() {
        // execute cobrowse end
        this._sendCommand('endCobrowse');
      },
      toggleChatWindow: function toggleChatWindow() {
        this.toggleProperty('isChatWindowVisible');
        this.get('isChatWindowVisible') ? this._sendCommand('showChatWindow') : this._sendCommand('hideChatWindow');
      }
    },
    _sendCommand: function _sendCommand(command) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var options = Object.assign({
        command: command,
        engagementId: this.get('engagement.id')
      }, data);
      this.get('cobrowse.activeIframe').sendCommand(options);
    },
    /**
     * Adds an event listener to the messages event.
     * Used for maintaining the correct button state
     * within the cobrowse component.
     * @method _addWindowMessageListener
     * @private
     */
    _addWindowMessageListener: function _addWindowMessageListener() {
      this.oniFrameMessage = this._processIframeMessage.bind(this);
      Ember.$(window).on('message.cobrowseUI', this.oniFrameMessage);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(window).off('.cobrowseUI', this.oniFrameMessage);
      this.oniFrameMessage = null;
    },
    /**
     * Will update the Show/Hide Chat button
     * state depending on the event received.
     * @method _processIframeMessage
     * @private
     * @param {Object} messageEvent the cobrowse event
     */
    _processIframeMessage: function _processIframeMessage(messageEvent) {
      var _messageEvent$origina = messageEvent.originalEvent,
        origin = _messageEvent$origina.origin,
        data = _messageEvent$origina.data;

      // Only process messages from cobrowse iframe
      if (origin !== this.get('iframe.url')) return;
      var event = data.event;
      this._handleEvent(event);
    },
    _handleEvent: function _handleEvent(event) {
      var isChatWindowToggleMessage = ['chatWindowShown', 'chatWindowHidden'].includes(event);
      var isDisconnected = ['disconnected', 'connecting'].includes(event);
      if (isChatWindowToggleMessage) {
        this.set('isChatWindowVisible', event === 'chatWindowShown');
      } else if (event === 'getHighlight') {
        this.get('cobrowse').sendHighlightSetting(this.get('engagement'));
      } else if (isDisconnected) {
        this.set('iframe.connected', false);
      } else if (event === 'connected') {
        this.set('iframe.connected', true);
      }
    }
  });
  function getColorValue(hexColor) {
    return "0x".concat(hexColor.substring(1));
  }
});