define("sdk/components/menu-select/trigger/component", ["exports", "sdk/core/component", "sdk/components/menu-select/trigger/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'menu-select-trigger',
    attributeBindings: ['data-test-menu-select-trigger', 'role', 'aria-expanded', 'aria-haspopup', 'aria-controls', 'aria-labelledby', 'title'],
    tagName: 'button',
    role: 'button',
    'aria-expanded': Ember.computed('isExpanded', function () {
      return "".concat(this.get('isExpanded'));
    }),
    'aria-haspopup': 'listbox',
    'data-test-menu-select-trigger': '',
    keyDown: function keyDown() {
      var keyCode = event.keyCode;
      var isUpOrDownArrow = keyCode === 38 || keyCode === 40;
      if (isUpOrDownArrow && !this.get('isExpanded')) {
        this.click();
      }
    }
  });
});