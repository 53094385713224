define("sdk/components/custom-scripts/component", ["exports", "sdk/core/component", "sdk/components/custom-scripts/template", "sdk/utils/speak", "sdk/utils/grammar", "ember-uuid"], function (_exports, _component, _template, _speak, _grammar, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Allows the user to create, update, save, and delete
   * custom scripts.
   *
   * @class Components.CustomScripts
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    intl: Ember.inject.service(),
    componentName: 'custom-scripts',
    /**
     * This flag is false when there are unsaved changes
     * made to the editor. If false when attempting to close
     * the editor, display the confirmation modal.
     * @property {Boolean} pristeen
     * @public
     */
    pristeen: true,
    hasInvalidScripts: Ember.computed.notEmpty('invalidScriptNumbers'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (Ember.$(document.activeElement).hasClass('settings-menu__item')) {
        this.set('customScriptsLastFocusSelector', '#settings-menu');
      } else {
        this.set('customScriptsLastFocusSelector', '.scripts__edit-custom-scripts-button');
      }
      var customScriptLines = this.get('customScriptLines') || [];
      var scriptList = customScriptLines.map(function (script) {
        return Ember.Object.create({
          uuid: (0, _emberUuid.v4)(),
          text: script.scriptValue,
          type: script.type,
          nonEditable: script.nonEditable
        });
      });
      this.set('scriptList', scriptList);
    },
    actions: {
      /**
       * Adds a script to the script list.
       * Called when the user clicks the add button.
       * @method addScript
       */
      addScript: function addScript() {
        var scriptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'text';
        var scriptIndex = this.lastFocusedScriptLineIndex !== undefined ? this.lastFocusedScriptLineIndex + 1 : this.scriptList.length;
        this.get('scriptList').insertAt(scriptIndex, Ember.Object.create({
          uuid: (0, _emberUuid.v4)(),
          text: '',
          editMode: true,
          type: scriptType
        }));
        this.set('pristeen', false);
      },
      save: function save() {
        var hasValidScripts = this._validateScripts();
        if (hasValidScripts) {
          this.set('pristeen', true);
          this.saveToRemote();
        }
      },
      saveAndClose: function saveAndClose(closeModalIds) {
        var hasValidScripts = this._validateScripts();
        if (hasValidScripts) {
          this.saveToRemote();
          this._closeModal(closeModalIds);
        }
      },
      cancelFromConfirmationModal: function cancelFromConfirmationModal(closeModalIds) {
        this._cancel(closeModalIds);
      },
      cancelCustomScriptsModalClose: function cancelCustomScriptsModalClose() {
        this.set('showConfirmationModal', false);
        this.$('#custom-scripts-add-new-text-script').focus();
      },
      /**
       * If the editor has no unsaved changes (or is "pristeen"),
       * close the modal. Otherwise, display the confirmation
       * modal.
       * @method cancel
       * @param {Function} closeModalIds the method used to close the modal
       */
      cancel: function cancel(closeModalIds) {
        if (this.get('pristeen')) {
          this._cancel(closeModalIds);
        } else {
          this._validateScripts();
          this.set('showConfirmationModal', true);
        }
      },
      setDirty: function setDirty() {
        this.set('pristeen', false);
      },
      reorderItems: function reorderItems(itemModels) {
        this.set('scriptList', itemModels);
        this.set('pristeen', false);
      },
      onSortableItemKeyUp: function onSortableItemKeyUp(index, event) {
        var customScriptLine = this.scriptList.objectAt(index);
        var keyCode = event.keyCode;
        if (keyCode === 38) {
          // Up
          this.scriptList.removeObject(customScriptLine);
          var nextIndex = index === 0 ? this.scriptList.length : index - 1;
          this.scriptList.insertAt(nextIndex, customScriptLine);
          Ember.run.schedule('afterRender', function () {
            return document.querySelector(".sortable-item:nth-child(".concat(nextIndex + 1, ")")).focus();
          });
        } else if (keyCode === 40) {
          // Down
          this.scriptList.removeObject(customScriptLine);
          var _nextIndex = index === this.scriptList.length ? 0 : index + 1;
          this.scriptList.insertAt(_nextIndex, customScriptLine);
          Ember.run.schedule('afterRender', function () {
            return document.querySelector(".sortable-item:nth-child(".concat(_nextIndex + 1, ")")).focus();
          });
        }
      }
    },
    _cancel: function _cancel(closeModalIds) {
      var scriptList = this.get('scriptList');
      scriptList.setEach('delete', false);
      this._closeModal(closeModalIds);
    },
    _closeModal: function _closeModal(closeModalIds) {
      var _this = this;
      closeModalIds.split(' ').forEach(function (id) {
        _this.set(id, false);
      });
    },
    saveToRemote: function saveToRemote() {
      var scriptList = this.get('scriptList');
      scriptList = scriptList.filterBy('delete', false).reject(function (item) {
        return item.text.trim() == '';
      });
      this.set('scriptList', scriptList);
      var agent = this.get('api.agent');
      var scriptsToSave = scriptList.map(function (script) {
        return {
          type: script.type,
          scriptValue: script.text
        };
      });
      agent.setCustomScripts(scriptsToSave);
      (0, _speak.default)("".concat(this.get('intl').t('custom-scripts.saved')));
    },
    _scrollToFirstInvalidScript: function _scrollToFirstInvalidScript() {
      var firstInvalidScript = Ember.$('.marked-as-invalid')[0];
      firstInvalidScript.scrollIntoView();
    },
    _validateScripts: function _validateScripts() {
      var invalidScriptNumberArr = [];
      var scriptList = this.get('scriptList').filterBy('delete', false).rejectBy('text', '');
      scriptList.map(function (script, index) {
        if ((0, _grammar.hasControlCharacter)(script.text)) {
          invalidScriptNumberArr.push(index + 1);
        }
        script.set('needHighlight', (0, _grammar.hasControlCharacter)(script.text));
      });
      this.set('invalidScriptNumbers', invalidScriptNumberArr.join(','));
      if (this.get('hasInvalidScripts')) {
        Ember.run.schedule('afterRender', this, function () {
          this._scrollToFirstInvalidScript();
        });
      }
      scriptList.setEach('editMode', false);
      return !this.get('hasInvalidScripts');
    }
  });
});