define("sdk/api/adapters/ajax-queue-manager", ["exports", "sdk/utils/clone"], function (_exports, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.prioritize = prioritize;
  var _default = _exports.default = Ember.Object.extend({
    maxSlots: 6,
    length: Ember.computed.alias('_queuedDeferredRequests.length'),
    contents: Ember.computed('_queuedDeferredRequests.[]', function () {
      return [].concat(this._queuedDeferredRequests);
    }),
    /**
     * Prevents requests from being popped from queue
     * except for requests with "bypassBlock" = true
     * @property {Boolean} preventRequests
     */
    preventRequests: false,
    init: function init() {
      this._super.apply(this, arguments);
      this._queuedDeferredRequests = [];
      this._activeDeferredRequests = [];
      this.api.on('agentRelogin', this, this._updateRegisteredIds);
    },
    /**
     * Adds requests to the manager's queue returning
     * a deferred promise. If more than one request was
     * added, the deferred promise will be fulfilled
     * after all promises are fulfilled.
     * Example request:
     * {
     *   url: 'https://www.google.com',
     *   ajaxOptions: {
     *     bypassAuthCheck: true,
     *     method: 'post',
     *     data: {
     *       username: 'Agent A',
     *       output: 'json'
     *     }
     *   }
     * }
     * @method add
     * @public
     * @param {Object} request an ajax-request
     * @param {Object} options options for adding requests to the queue
     * @param {Boolean} options.unshift add the requests to the front of the queue
     */
    add: function add(request) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // preserve query data in case we halt
      if (request.ajaxOptions && request.ajaxOptions.query) {
        request.query = (0, _clone.default)(request.ajaxOptions.query);
      }
      request.promise.finally(function () {
        _this._activeDeferredRequests.removeObject(request);
      });
      if (options.unshift) {
        this._queuedDeferredRequests.unshiftObject(request);
      } else {
        this._queuedDeferredRequests.addObject(request);
      }
    },
    /**
     * Aborts active requests (pending)
     * @method abortActiveRequests
     */
    abortActiveRequests: function abortActiveRequests() {
      var activeDeferredRequests = this._activeDeferredRequests;
      activeDeferredRequests.forEach(function (request) {
        var xhr = request.getActiveXHR();
        xhr.manualAbort = true;
        xhr.abort && xhr.abort();
        request.reject({
          ignore: true
        });
      });
    },
    /**
     * Prevents outgoing requests from being sent
     * @method preventOutgoingRequests
     */
    preventOutgoingRequests: function preventOutgoingRequests() {
      this.set('preventRequests', true);
    },
    prioritize: function prioritize() {
      return true;
    } /* request, activeRequests */,
    /**
     * Turns prioritized queued requests into active requests
     * @method pop
     * @return {Array} prioritized requests
     */
    pop: function pop() {
      var _this2 = this;
      var prioritizedDeferredRequests = this._getPrioritizedDeferredRequests();
      prioritizedDeferredRequests.forEach(function (request) {
        _this2._queuedDeferredRequests.removeObject(request);
        _this2._activeDeferredRequests.addObject(request);
      });
      return prioritizedDeferredRequests;
    },
    /**
     * Get the next prioritized requests from the queued requests array
     * @method _getPrioritizedDeferredRequests
     * @return {Array} prioritized requests
     */
    _getPrioritizedDeferredRequests: function _getPrioritizedDeferredRequests() {
      var prioritizedDeferredRequests = [];
      var activeRequests = this._activeDeferredRequests;
      for (var i = 0; i < this._queuedDeferredRequests.length && activeRequests.length < this.maxSlots; i++) {
        var deferredRequest = this._queuedDeferredRequests[i];
        var shouldPrioritize = this.prioritize(deferredRequest, activeRequests);
        if (shouldPrioritize) {
          prioritizedDeferredRequests.push(deferredRequest);
          activeRequests.push(deferredRequest);
        }
      }
      return prioritizedDeferredRequests;
    },
    /**
     * Immediately aborts all pending AEAPI requests
     * and places them at the head of the queue.
     * Prevents any queued AEAPI requests from being sent
     * except for login, logout, and verifySession requests.
     * @method haltAEAPI
     * @public
     * @param {Object} [failedRequest] request which should skip being re-added to queue
     */
    haltAEAPI: function haltAEAPI(failedRequest) {
      var _this3 = this;
      this.get('api.router').close();
      this.set('_halted', true);
      this._activeDeferredRequests.forEach(function (deferredRequest) {
        var adapter = deferredRequest.adapter,
          query = deferredRequest.query,
          url = deferredRequest.url;
        if (adapter.name !== 'AEAPI') return;
        var xhr = deferredRequest.getActiveXHR();
        xhr.halted = true;
        xhr.abort();
        var status = xhr.status;
        var hasFailedStatus = status === 0 || status === 503 || status === 504;
        var isFailedRequestSameDeferredRequest = failedRequest && failedRequest.url === url && hasFailedStatus;
        var _deferredRequest$ajax = deferredRequest.ajaxOptions,
          data = _deferredRequest$ajax.data,
          method = _deferredRequest$ajax.method;
        var isGetMessagesRequest = method && method.toLowerCase() === 'get' && url.endsWith('messages');
        var shouldNotReAdd = isFailedRequestSameDeferredRequest || isGetMessagesRequest;
        if (shouldNotReAdd) return;
        if (data && typeof data === 'string') {
          // undo stringification from api-adapter
          deferredRequest.ajaxOptions.data = JSON.parse(data);
        }

        // undo query data deletion from api-adapter
        Object.assign(deferredRequest.ajaxOptions, {
          query: query
        });
        _this3.add(deferredRequest, {
          unshift: true
        });
      });
      this._activeDeferredRequests = this._activeDeferredRequests.rejectBy('adapter.name', 'AEAPI');
    },
    /**
     * Toggles off halting functionality.
     * @method continue
     * @public
     */
    continue: function _continue() {
      this.set('_halted', false);
      this.get('api.adapter')._processQueue();
      this.get('api.router').open();
    },
    /**
     * Updates registeredIds for all queued AEAPI requests.
     * @method updateRegisteredIds
     * @param {String} registeredId the new registeredId
     */
    _updateRegisteredIds: function _updateRegisteredIds(_ref) {
      var registeredId = _ref.registeredId;
      this._queuedDeferredRequests.forEach(function (deferredRequest) {
        var adapter = deferredRequest.adapter;
        if (adapter.name !== 'AEAPI') return;
        var _deferredRequest$ajax2 = deferredRequest.ajaxOptions,
          data = _deferredRequest$ajax2.data,
          query = _deferredRequest$ajax2.query;
        if (data && data.registeredId) {
          data.registeredId = registeredId;
        } else if (query && query.registeredId) {
          query.registeredId = registeredId;
        }
        if (deferredRequest.query && deferredRequest.query.registeredId) {
          deferredRequest.query.registeredId = registeredId;
        }
      });
    }
  });
  /**
   * Decides whether a request should be prioritized and popped from the queue
   * @method prioritize
   * @param {Object} request
   * @param {Array} activeRequests
   * @return {Boolean} true if should be prioritized
   */
  function prioritize(request, activeRequests) {
    var ajaxOptions = request.ajaxOptions,
      url = request.url;

    // when blocking requests, only prioritize requests with "bypassBlock"
    var _ajaxOptions$bypassBl = ajaxOptions.bypassBlock,
      bypassBlock = _ajaxOptions$bypassBl === void 0 ? false : _ajaxOptions$bypassBl,
      _ajaxOptions$bypassHa = ajaxOptions.bypassHalt,
      bypassHalt = _ajaxOptions$bypassHa === void 0 ? false : _ajaxOptions$bypassHa;
    if (this.preventRequests) return bypassBlock;
    if (this.get('_halted') && request.adapter.name === 'AEAPI') return bypassHalt;
    if (url.includes('/messages')) {
      var method = (ajaxOptions.method || '').toLowerCase();
      if (method === 'get') {
        var hasActiveGetMessages = activeRequests.find(function (_ref2) {
          var url = _ref2.url,
            ajaxOptions = _ref2.ajaxOptions;
          var method = (ajaxOptions.method || 'get').toLowerCase();
          return method === 'get' && url.includes('/messages');
        });
        return !hasActiveGetMessages;
      } else {
        var hasActivePostMessages = activeRequests.find(function (_ref3) {
          var url = _ref3.url,
            ajaxOptions = _ref3.ajaxOptions;
          var method = (ajaxOptions.method || 'get').toLowerCase();
          return method === 'post' && url.includes('/messages');
        });
        return !hasActivePostMessages;
      }
    }
    var highPriorityRoutes = ['acceptEngagement', 'acceptOwnership', 'agentStatus', 'autoTransferEngagement', 'changePassword', 'closeEngagement', 'conferenceEngagement', 'conferenceMode', 'conversation', 'denyEngagement', 'dispositions', 'escalateEngagement', 'joinEngagement', 'login', 'logout', 'messages', 'refuseOwnership', 'resolveConversation', 'summaryData', 'transferConferenceOptions', 'transferEngagement', 'transferOwnership', 'verifySession'];
    var isHighPriority = !!highPriorityRoutes.find(function (route) {
      return url.includes(route);
    });
    var maximumHighPriorities = this.maxSlots - 2;
    activeRequests = activeRequests.filter(function (_ref4) {
      var url = _ref4.url;
      return !url.includes('messages');
    });
    if (isHighPriority) {
      var availableSlots = this.maxSlots - activeRequests.length;
      var reservedMessagesConnections = 2;
      return availableSlots > reservedMessagesConnections;
    }
    var totalLowPriorities = activeRequests.filter(function (_ref5) {
      var url = _ref5.url;
      var isHighPriority = !!highPriorityRoutes.find(function (route) {
        return url.includes(route);
      });
      return !isHighPriority;
    }).length;
    var isIdenticalRequestPending = !!activeRequests.find(function (activeRequest) {
      var method = (Ember.get(ajaxOptions, 'method') || '').toLowerCase();
      var activeRequestMethod = (Ember.get(activeRequest.ajaxOptions, 'method') || '').toLowerCase();
      var hasIdenticalMethod = activeRequestMethod === method;
      var hasIdenticalURL = activeRequest.url === url;
      return hasIdenticalMethod && hasIdenticalURL;
    });
    if (isIdenticalRequestPending) return false;
    var maximumLowPriorities = maximumHighPriorities - 1;
    return totalLowPriorities < maximumLowPriorities;
  }
});