define("sdk/services/cobrowse/iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    isActive: Ember.computed(function () {
      return !window.runningTests;
    }),
    connected: true,
    isVisible: false,
    src: Ember.computed('activeEngagementId', function () {
      var agentId = this.get('agentId');
      var activeEngagementId = this.get('activeEngagementId');
      if (!activeEngagementId) return;
      var url = this.get('url');
      var locale = this.get('locale');
      var params = "#{\"engagementId\":\"".concat(activeEngagementId, "\",\"agentId\":\"").concat(agentId, "\",\"locale\":\"").concat(locale, "\"}");
      return "".concat(url, "/cobrowse/cobrowse.html").concat(params);
    }),
    id: Ember.computed(function () {
      return "cobrowse-".concat(this.get('index'));
    }),
    selector: Ember.computed('id', function () {
      return "#".concat(this.get('id'));
    }),
    /**
     * Commands
     * - setAgent({ agentId, engagementId })
     * - joinCobrowse({ engagementId })
     * - setColor({ color })
     * - highlight({ engagementId })
     * - unhighlight({ engagementId })
     * - reloadPage({ engagementId })
     * - hideChatWindow({ engagementId })
     * - showChatWindow({ engagementId })
     * - hideCobrowse({ engagementId })
     * - showCobrowse({ engagementId })
     * - endCobrowse({ engagementId })
     */
    sendCommand: function sendCommand(options) {
      var command = options.command,
        engagementId = options.engagementId;
      var prevEngagementId = this.get('activeEngagementId');
      this.set('activeEngagementId', engagementId);
      var $iframe = Ember.$(this.get('selector'));
      if (!$iframe || !$iframe.length) return;
      var contentWindow = $iframe[0].contentWindow;
      contentWindow.postMessage(options, '*');
      if (command === 'joinCobrowse' && prevEngagementId) {
        this.set('activeEngagementId', prevEngagementId);
      }
    }
  });
});