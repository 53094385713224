define("sdk/plugins/open-page/plugin", ["exports", "dompurify", "sdk/api", "sdk/utils/window"], function (_exports, _dompurify, _api, _window) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _api.Plugin.extend({
    name: 'open-page',
    runAt: 'transcript-click',
    init: function init() {
      this._super.apply(this, arguments);
      var params = this.pluginParams;
      Object.keys(params).forEach(function (key) {
        if (params[key] === undefined) {
          delete params[key];
        }
      });

      // Support aliases of `data`, `datapass`, and `dataPass` (to refer to values
      // that will be sent to the client's server via the GET or POST request)
      params.data = params.data || params.datapass || params.dataPass;
      if (this.runAt === 'immediate') return;
      this.loadMessageText();
    },
    loadMessageText: function loadMessageText() {
      var messageText = this.get('messageText');
      messageText = _dompurify.default.sanitize(messageText);
      var url = this.get('data.url') || this.pluginParams.url;
      var el = document.createElement('div');
      el.innerHTML = '<span data-plugin-message-text="">' + messageText + '</span>';
      var triggerEl = el.querySelector('[data-plugin-trigger]') || el.querySelector('[data-plugin-message-text]');
      triggerEl.setAttribute('data-plugin-trigger', this.name);
      if (url) {
        triggerEl.setAttribute('title', url);
      }
      messageText = el.innerHTML;
      this.setProperties({
        messageText: messageText
      });
    },
    run: function run() {
      if (this.window && !this.window.frame.closed) {
        return this.window.focus();
      }
      var params = this.pluginParams;
      params.name = this._engagement.id;
      this.window = _window.default.create(params);
      this.window.open();
      if (params.focusWhenChatActive && params.target === 'popup') {
        this._engagement.on('activate', this.window, this.window.focus);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this._engagement.off('activate', this.window, this.window.focus);
    }
  });
});