define("sdk/components/resizable-column/component", ["exports", "sdk/components/resizable-column/template", "sdk/core/component", "sdk/utils/keyboard-resize"], function (_exports, _template, _component, _keyboardResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    engagementLayoutManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    componentName: 'resizable-column',
    hasMetrics: false,
    classNames: ['layout-column', 'column'],
    classNameBindings: ['shouldColumnBeHidden:collapsed', 'hasExpandedTile'],
    minWidth: 260,
    shouldColumnBeHidden: Ember.computed('expandedTile', function () {
      var expandedTile = this.get('expandedTile');
      if (!expandedTile) return false;
      var tileNames = this.get('tileNames');
      return tileNames && !tileNames.includes(expandedTile);
    }),
    hasExpandedTile: Ember.computed('expandedTile', function () {
      var expandedTile = this.get('expandedTile');
      var tileNames = this.get('tileNames');
      if (!expandedTile) return false;
      return tileNames && tileNames.includes(expandedTile);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', function () {
        var _this$getProperties = _this.getProperties('minWidth', 'width'),
          minWidth = _this$getProperties.minWidth,
          width = _this$getProperties.width;
        _this.element.style.minWidth = minWidth === undefined ? '' : "".concat(minWidth, "px");
        _this.element.style.width = width === undefined ? '' : "".concat(width, "px");
        if (_this.get('hasExpandedTile')) {
          _this.element.style.maxWidth = '';
        } else {
          var _this$getColumnDetail = _this.getColumnDetails(),
            maxWidth = _this$getColumnDetail.maxWidth;
          _this.element.style.maxWidth = maxWidth ? "".concat(maxWidth, "px") : '';
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var elementHandle = this.element.querySelector(':scope > .ui-resizable-handle');
      elementHandle && elementHandle.removeEventListener('keydown', this.onHandleKeyDown);
      elementHandle && elementHandle.removeEventListener('keyup', this.onHandleKeyUp);
      var $column = this.$();
      if ($column.resizable('instance')) {
        $column.resizable('destroy');
      }
    },
    didRender: function didRender() {
      var _this2 = this;
      this._super.apply(this, arguments);
      var $column = this.$();
      if (this.get('hasMetrics') !== this.get('api.metrics.hasMetrics')) {
        this.set('hasMetrics', this.get('api.metrics.hasMetrics'));
        this.set('isRendered', false);
      }

      // prevent multiple instances of adding $.resizable
      if (this.get('isRendered')) return;
      this.set('isRendered', true);

      // every column but the last is flex-none whereas last column is flex-auto
      var _this$getColumnDetail2 = this.getColumnDetails(),
        isResizable = _this$getColumnDetail2.isResizable;
      if (isResizable) {
        var minWidth = this.get('minWidth');
        var self = this;
        $column.resizable({
          handles: 'e',
          grid: [10, 0],
          minWidth: minWidth,
          start: function start(event, ui) {
            self.sendAction('resizeEvent', true);
            var engagement = self.get('engagement');
            if (engagement && engagement.trigger && typeof engagement.trigger === 'function') {
              engagement.trigger('agentInteracted');
            }
            ui.originalElement.find('.ui-resizable-handle').addClass('dragging');
            var _self$getColumnDetail = self.getColumnDetails(),
              maxWidth = _self$getColumnDetail.maxWidth;
            self.$(this).resizable('option', 'maxWidth', maxWidth);
          },
          stop: function stop(event, ui) {
            _this2.set('width', $column.width());
            self.sendAction('resizeEvent', false, _this2.$(event.target));
            self.get('engagementLayoutManager').onComponentResize('resizable-column');
            ui.originalElement.find('.ui-resizable-handle').removeClass('dragging');
          }
        });
        var elementHandle = this.element.querySelector(':scope > .ui-resizable-handle');
        elementHandle.setAttribute('aria-label', this.get('intl').t('a11y.resize-vertical'));
        this._onHandleKeyDown = this._onHandleKeyDown || this.onHandleKeyDown.bind(this);
        elementHandle.removeEventListener('keydown', this._onHandleKeyDown);
        elementHandle.addEventListener('keydown', this._onHandleKeyDown);
        this._onHandleKeyUp = this._onHandleKeyUp || this.onHandleKeyUp.bind(this);
        elementHandle.removeEventListener('keyup', this._onHandleKeyUp);
        elementHandle.addEventListener('keyup', this._onHandleKeyUp);
      }
    },
    onHandleKeyDown: function onHandleKeyDown(event) {
      var _this$getColumnDetail3 = this.getColumnDetails(),
        maxWidth = _this$getColumnDetail3.maxWidth;
      var isArrowKey = ['ArrowLeft', 'ArrowRight'].includes(event.key);
      if (isArrowKey) {
        this.set('isKeyboardResizing', true);
        var onKeyboardColumnResize = this.get('onKeyboardColumnResize');
        onKeyboardColumnResize && onKeyboardColumnResize();
        if (event.key === 'ArrowLeft') {
          if (this.$().outerWidth() - _keyboardResize.default < this.minWidth) return;
          this.set('width', this.$().outerWidth() - _keyboardResize.default);
        } else if (event.key === 'ArrowRight') {
          if (this.$().outerWidth() + _keyboardResize.default > maxWidth) return;
          this.set('width', this.$().outerWidth() + _keyboardResize.default);
        }
      }
    },
    onHandleKeyUp: function onHandleKeyUp(event) {
      if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        this.set('isKeyboardResizing', false);
        this.get('engagementLayoutManager').onComponentResize('resizable-column');
      }
    },
    getColumnDetails: function getColumnDetails() {
      var $column = this.$();
      var $columnGroup = $column.parent();
      var $columns = $columnGroup.children('.nuance-resizable-column');
      var columnCount = $columns.length;
      var columnsWidth = $columnGroup.width();
      var index = $column.index('.nuance-resizable-column');
      var isResizable = index + 1 < columnCount;
      var maxWidth;
      if (isResizable) {
        if (columnCount === 2) {
          var siblingMinWidth = 375;
          maxWidth = columnsWidth - siblingMinWidth;
        } else if (columnCount > 2) {
          var thirdColumnMinWidth = 240;
          if (index === 0) {
            var column1Width = $columnGroup.children(':eq(1)').width();
            maxWidth = columnsWidth - thirdColumnMinWidth - column1Width;
          } else if (index === 1) {
            var column0Width = $columnGroup.children(':eq(0)').width();
            maxWidth = columnsWidth - thirdColumnMinWidth - column0Width;
          }
        }
      }
      return {
        isResizable: isResizable,
        maxWidth: maxWidth
      };
    }
  });
});