define("sdk/components/sdk-scripts/script-group-select/component", ["exports", "sdk/core/component", "sdk/components/sdk-scripts/script-group-select/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Script Group Dropdowns
   *
   * Displays a list of script groups and script folders
   * Script group folders may contain nested script groups in which
   * multiple dropdowns are rendered
   * Passes the selected script group up to the parent sdk-scripts component
   *
   * @class Scripts.ScriptGroupSelect
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'script-group-select',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('scriptGroupsOrFolders', []);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var scriptTree = this.get('scriptTree');
      var scriptGroupsOrFolders = this.get('selectedScriptGroupsOrFolders').copy();
      scriptGroupsOrFolders.forEach(function (scriptGroupOrFolder, index) {
        if (index === 0) {
          // scriptTree is used for first scriptGroup dropdown options
          _this._toDropdown(scriptGroupOrFolder, scriptTree);
        } else {
          // parent scriptFolder, scriptGroupsOrFolders[n-1], is used for dropdown options
          _this._toDropdown(scriptGroupOrFolder, scriptGroupsOrFolders[index - 1]);
        }
      });
      this.set('scriptGroupsOrFolders', scriptGroupsOrFolders);
    },
    actions: {
      /**
       * Sets the selected script group
       * Passes selected script group up to the parent, sdk-scripts
       * @method onSelected
       * @param {Number} index index of selected scriptGroupOrFolder
       * @param {scriptGroup} scriptGroupOrFolder selected scriptGroupOrFolder
       * @private
       */
      onSelected: function onSelected(index, scriptGroupOrFolder) {
        var _this2 = this;
        var tmp = this.get('selectedScriptGroupsOrFolders').copy();
        if (tmp.length === 1) {
          // Ex: [group] -> [newGroup]
          tmp.replace(index, 1, [scriptGroupOrFolder]);
        } else {
          // Ex: [folder, folder, group] -> [folder, newGroup]
          tmp = tmp.slice(0, index);
          tmp.pushObject(scriptGroupOrFolder);
        }

        // If a folder is selected, push default script group to array
        var isFolder = !!(scriptGroupOrFolder.get('groups') || scriptGroupOrFolder.get('folders'));
        if (isFolder) {
          if (scriptGroupOrFolder.get('groups.length')) {
            var defaultScriptGroup = scriptGroupOrFolder.get('groups.firstObject');
            tmp.pushObject(defaultScriptGroup);
          } else {
            tmp.pushObjects(this._getFirstScriptGroupPath(scriptGroupOrFolder.get('folders.firstObject')));
          }
        }

        // Because we set our selected options in didReceiveAttrs, ember power select will
        // throw errors if not in the next Ember run loop
        Ember.run(function () {
          var onScriptGroupSelected = _this2.get('onScriptGroupSelected');
          onScriptGroupSelected && onScriptGroupSelected(tmp);
        });
        this.$('[role="button"]').focus();
      }
    },
    /**
     * Assign dropdown properties to passed in scriptGroupOrFolder
     * @method _toDropdown
     * @param {scriptGroupOrFolder} scriptGroupOrFolder scriptGroupOrFolder to set dropdown properties on
     * @param {scriptGroupOrFolderOrTree} parent scriptGroupOrFolder's parent to retrieve options from
     * @private
     */
    _toDropdown: function _toDropdown(scriptGroupOrFolder, parent) {
      if (scriptGroupOrFolder.get('options')) return;
      var folders = parent.get('folders') || [];
      var groups = parent.get('groups') || [];
      var sortedByIndex = folders.concat(groups).sortBy('index');
      var options = sortedByIndex.without(undefined);
      scriptGroupOrFolder.setProperties({
        selected: scriptGroupOrFolder,
        options: options
      });
    },
    /**
     * Returns an array containing the folder objects which lead to the first
     * script group within the given folder. The first script group will always
     * be the last object in the array.
     * @method _getFirstScriptGroupPath
     * @param {Object} folder the folder object
     * @returns an array with the path of folder objects leading to and including the first script group
     * @private
     */
    _getFirstScriptGroupPath: function _getFirstScriptGroupPath(folder) {
      if (folder.get('groups.length')) return [folder, folder.get('groups.firstObject')];
      return [folder].concat(this._getFirstScriptGroupPath(folder.get('folders.firstObject')));
    }
  });
});