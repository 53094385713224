define("sdk/components/nina-coach/component", ["exports", "sdk/core/component", "sdk/components/nina-coach/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nina-coach',
    ui: Ember.computed.alias('engagement.ui'),
    connected: Ember.computed.alias('engagement.connected'),
    notConnected: Ember.computed.not('connected'),
    nina: Ember.computed.alias('engagement.nina'),
    selectedIntent: Ember.computed.alias('nina.selectedIntent'),
    suggestedIntent: Ember.computed.alias('nina.suggestedIntent'),
    isSendDisabled: Ember.computed.not('selectedIntent'),
    showCover: Ember.computed('isTraining', 'notConnected', function () {
      return !this.get('isTraining') && this.get('notConnected');
    }),
    actions: {
      /**
       * Sets the selected intent
       * @method setIntent
       * @param {Object} intent selected intent
       */
      setIntent: function setIntent(intent) {
        this.set('selectedIntent', intent);
      },
      /**
       * Ends Nina Coach engagement with NOOP escalation resolution
       * @method noOpportunity
       */
      noOpportunity: function noOpportunity() {
        return this.get('engagement').noOpportunityNina();
      },
      /**
      * Converts Nina Coach engagement to a normal chat engagement with LC escalation resolution
      * @method takeOver
      */
      takeOver: function takeOver() {
        return this.get('engagement').takeoverNina();
      },
      /**
       * Send selected intent
       * @method sendIntent
       */
      sendIntent: function sendIntent() {
        var selectedIntent = this.get('selectedIntent');
        return this.get('engagement').sendIntent(selectedIntent);
      }
    }
  }).reopenClass({
    positionalParams: ['engagement']
  });
});