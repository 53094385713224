define("sdk/components/link-to/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.LinkComponent.extend({
    attributeBindings: ['aria-controls', 'role', 'aria-disabled', 'aria-label', 'aria-selected'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('enableAriaSelected')) {
        this.set('aria-selected', Ember.computed('_active', function () {
          return "".concat(this.get('_active'));
        }));
      }
    }
  });
});