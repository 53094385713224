define("sdk/mixins/text-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * Save selection data whenever the user right-clicks on the component
     * used to set property "selection" every time user opens context-menu
     * @method contextMenu
     */
    contextMenu: function contextMenu() {
      this._super.apply(this, arguments);
      this._setSelection();
    },
    /**
     * Get information such as startIndex, endIndex, selectedText
     * when selecting text in a specified container
     * @method getSelectedData
     * @param containerSelector a selector for the parent element where the selection will occur
     */
    getSelectedData: function getSelectedData(containerSelector) {
      this._setSelection();
      var $container = this.$(containerSelector);
      var selection = this.get('selection');
      if (!selection || !$container.length) return {
        selectedText: ''
      };
      var containerText = $container.text();
      var selectionRange = selection.getRangeAt(0);
      var startContainer = selectionRange.startContainer,
        startOffset = selectionRange.startOffset,
        endContainer = selectionRange.endContainer,
        endOffset = selectionRange.endOffset;
      var selectedText = selectionRange.toString().trim();
      var selectedLength = selectedText.length;

      // offset of start/ end node relative to node's parent + node's parent(s) offset relative to container
      // Example: test <span>message</span>
      // if "test message" is selected, start offset = 0 and end offset = 11 (relative to parent span)
      var containerSelectionStartOffset = getOffsetInContainer($container, startContainer, startOffset) || 0;
      var containerSelectionEndOffset = getOffsetInContainer($container, endContainer, endOffset) || containerText.length;
      return {
        selectedText: selectedText,
        selectedLength: selectedLength,
        index: containerSelectionStartOffset,
        startOffset: containerSelectionStartOffset,
        endOffset: containerSelectionEndOffset
      };
    },
    /**
     * Sets window's current selection to property "selection"
     * notifies computed property listeners that new selection has been made
     * @method _setSelection
     * @private
     */
    _setSelection: function _setSelection() {
      this.set('selection', window.getSelection());
      // notify property for computed properties listening since
      // selection may not be invoked elsewhere (not rendered in template)
      this.notifyPropertyChange('selection');
    }
  });
  /**
   * get offset of node by combining existing offset with
   * offset of the node's parent(s) relative to container
   * @method getOffsetInContainer
   * @param $container jQuery object of container
   * @param node node to get offset of relative to container
   * @param offset initial offset (node.baseOffset or node.extentOffset)
   * @private
   */
  function getOffsetInContainer($container, node) {
    var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var containerTextLength = $container.text().length;

    // if node is no longer in selection container, default to max offset:containerTextLength
    var containerHasNode = hasNode($container, node);
    if (containerHasNode) {
      offset += getNodeIndex(node);
      // if node parent is not the container element i.e wrapped in a <span> tag,
      // add the parent node's offset to the node's offset
      return offset + indexOfNodeInContainer(node.parentNode, containerTextLength);
    }
    return null;
  }
  function indexOfNodeInContainer(node, containerTextLength) {
    var index = 0;
    // calculate node's parent index relative to container
    while (node.innerHTML.length < containerTextLength) {
      index += getNodeIndex(node);
      node = node.parentNode;
    }
    return index;
  }
  function getNodeIndex(node) {
    if (!node) return;
    var previousSibling = node.previousSibling;
    var index = 0;

    // iterate backwards calculating index position relative to parent
    while (previousSibling) {
      var _previousSibling = previousSibling,
        data = _previousSibling.data,
        innerText = _previousSibling.innerText,
        nodeType = _previousSibling.nodeType;
      if (nodeType === Node.ELEMENT_NODE) {
        index += innerText.length;
      } else if (nodeType === Node.TEXT_NODE) {
        index += data.length;
      }
      previousSibling = previousSibling.previousSibling;
    }
    return index;
  }
  function hasNode($container, node) {
    return !!$container.has(node).length;
  }
});