define("sdk/components/notification-pill/component", ["exports", "sdk/core/component", "sdk/components/notification-pill/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'notification-pill',
    tagName: 'button',
    classNameBindings: ['type'],
    type: 'error',
    'data-test-error-pill': ''
  });
});