define("sdk/api/models/engagement/agent-coach", ["exports", "sdk/utils/grammar", "sdk/utils/regex", "moment"], function (_exports, _grammar, _regex, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _exports.default = Ember.Object.extend({
    settings: Ember.computed.reads('engagement.settings'),
    cheetahRecommendationsUrl: Ember.computed.reads('settings.agentCoachUrl'),
    cheetahLoggingUrl: Ember.computed.reads('settings.agentCoachLoggingUrl'),
    customerMessages: Ember.computed.alias('engagement.customerMessages'),
    isAutoSelectEnabled: true,
    selectedTranscriptLine: Ember.computed.alias('engagement.ui.selectedAgentCoachTranscriptLine'),
    selectedTranscriptLineUUID: Ember.computed.reads('selectedTranscriptLine.uuid'),
    selectTranscriptLine: function selectTranscriptLine(transcriptLine) {
      var _this = this;
      if (!transcriptLine) return;
      this.set('isPendingRecommendationsResponse', true);
      this.set('selectedTranscriptLine', transcriptLine);
      this.get('engagement.messages').setEach('cheetahRecommendations', null);
      var isLastCustomerMessage = this.get('customerMessages.lastObject') === transcriptLine;
      var lastConversationTranscriptLine = this.get('engagement.messages').filterBy('isConversation').get('lastObject');
      this.set('isAutoSelectEnabled', isLastCustomerMessage);
      this.set('hasCheetahRecommendationsError', false);
      return this.get('api.cheetahAdapter').getRecommendations(this.get('cheetahRecommendationsUrl'), {
        siteId: this.get('settings.siteId'),
        businessUnitName: this.get('settings.businessUnitName'),
        agentGroupName: this.get('settings.agentGroupName'),
        engagementId: this.get('engagement.id'),
        dialog: this._getDialog(isLastCustomerMessage ? lastConversationTranscriptLine : transcriptLine),
        namedEntities: this.get('engagement.namedEntities')
      }).then(function (_ref) {
        var recommendations = _ref.recommendations,
          modelId = _ref.modelId;
        transcriptLine.set('cheetahRecommendations', recommendations);
        _this.set('modelId', modelId);
      }).catch(function (error) {
        _this.set('hasCheetahRecommendationsError', true);
        throw error;
      }).finally(function () {
        _this.set('isPendingRecommendationsResponse', false);
      });
    },
    /**
     * @method _getDialog
     * @param {TranscriptLine} transcriptLine
     * @param {Object} options
     * @param {Number} options.wordLimit maximum word limit (can round above)
     */
    _getDialog: function _getDialog(transcriptLine) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _options$wordLimit = options.wordLimit,
        wordLimit = _options$wordLimit === void 0 ? 200 : _options$wordLimit;
      var dialog = [];
      var lastTranscriptIndex = this.get('engagement.messages').indexOf(transcriptLine);
      var dialogTranscriptLines = this.get('engagement.messages').slice(0, lastTranscriptIndex + 1).filterBy('isConversation');
      var sequenceIndex = dialogTranscriptLines.length - 1;
      var wordCount = 0;
      while (wordCount < wordLimit && dialogTranscriptLines.length) {
        var _transcriptLine = dialogTranscriptLines.pop();
        var _transcriptLine$getPr = _transcriptLine.getProperties('isCustomerMessage', 'messageText', 'timestamp'),
          isCustomerMessage = _transcriptLine$getPr.isCustomerMessage,
          _transcriptLine$getPr2 = _transcriptLine$getPr.messageText,
          messageText = _transcriptLine$getPr2 === void 0 ? '' : _transcriptLine$getPr2,
          timestamp = _transcriptLine$getPr.timestamp;
        wordCount += (0, _grammar.getWordCount)(messageText);
        dialog.unshiftObject({
          speaker_id: isCustomerMessage ? 'USER' : 'AGENT',
          sequence: sequenceIndex,
          value: messageText,
          type: isCustomerMessage ? 'customerChatlineSent' : 'agentChatlineSent',
          timestamp: (0, _moment.default)(Number(timestamp)).format('YYYY-MM-DD hh:mm:ss')
        });
        sequenceIndex--;
      }
      return dialog;
    },
    /**
     * Selects last customer messages
     * @method selectLastCustomerMessage
     */
    selectLastCustomerMessage: function selectLastCustomerMessage() {
      var customerMessages = this.get('customerMessages');
      if (customerMessages.length) {
        this.selectTranscriptLine(customerMessages.get('lastObject'));
      }
    },
    /**
     * Select new customer message if the previous selected customer transcript line was the last customer message
     * Only occurs for active engagement
     * @method selectNewCustomerMessageIfLastWasPreviouslySelected
     * @param {Object} message
     * @param {TranscriptLine} transcriptLine
     */
    selectNewCustomerMessageIfLastWasPreviouslySelected: function selectNewCustomerMessageIfLastWasPreviouslySelected(message, transcriptLine) {
      var isActiveEngagement = this.get('api.agent.activeEngagementId') === this.get('engagement.id');
      if (!isActiveEngagement || !this.get('isAutoSelectEnabled')) return;
      var customerMessages = this.get('customerMessages');
      var selectedTranscriptLineIndex = customerMessages.indexOf(this.get('selectedTranscriptLine'));
      var wasLastCustomerMessage = selectedTranscriptLineIndex === customerMessages.length - 2; // 2nd to last
      if (selectedTranscriptLineIndex === -1 || wasLastCustomerMessage) {
        this.selectTranscriptLine(transcriptLine);
      }
    },
    /**
     * Adds recommendations that were shift+clicked to sent recommendations array
     * @method markRecommendationsAsSent
     */
    markRecommendationsAsSent: function markRecommendationsAsSent() {
      var recommendations = this.get('engagement.ui.agentInputRecommendations');
      if (recommendations.length === 0) return;
      var recommendationAnswerUuids = recommendations.mapBy('answerUuid');
      this.get('engagement.sentRecommendationUuids').pushObjects(recommendationAnswerUuids);
      this.set('engagement.lastSentRecommendationUuids', recommendationAnswerUuids);
      this.get('engagement.ui.agentInputRecommendations').clear();
    },
    /**
     * Auto-select last customer message if "isAutoSelectEnabled" is true (see deactivate)
     * @method activate
     */
    activate: function activate() {
      if (this.get('isAutoSelectEnabled')) {
        this.selectTranscriptLine(this.get('customerMessages.lastObject'));
      }
    },
    /**
     * When navigating away from engagement set "isAutoSelectEnabled" to true if last customer message was selected
     * @method deactivate
     */
    deactivate: function deactivate() {
      var isLastCustomerMessageSelected = this.get('customerMessages.lastObject') === this.get('selectedTranscriptLine');
      this.set('isAutoSelectEnabled', isLastCustomerMessageSelected);
    },
    didAgentEditRecommendations: function didAgentEditRecommendations(transcriptLine) {
      var _transcriptLine$__dat = transcriptLine.__data__,
        _transcriptLine$__dat2 = _transcriptLine$__dat === void 0 ? {} : _transcriptLine$__dat,
        _transcriptLine$__dat3 = _transcriptLine$__dat2.agentCoachLogging,
        _transcriptLine$__dat4 = _transcriptLine$__dat3 === void 0 ? {} : _transcriptLine$__dat3,
        _transcriptLine$__dat5 = _transcriptLine$__dat4.recommendations,
        recommendations = _transcriptLine$__dat5 === void 0 ? [] : _transcriptLine$__dat5;
      if (!recommendations.length) return false;
      var selectedRecommendations = recommendations.map(function (_ref2) {
        var answer = _ref2.answer;
        return (0, _regex.escapeRegex)(answer.value);
      });
      var agentMessages = transcriptLine.__data__.messageText.split('\n');
      var didAgentUpdateRecommendations = false;
      var _iterator = _createForOfIteratorHelper(selectedRecommendations),
        _step;
      try {
        var _loop = function _loop() {
          var selectedRecommendation = _step.value;
          var selectedRecommendationRegEx = selectedRecommendation.replace(_regex.namedEntityPlaceholderRegEx, '(.*)');
          var isMatchingWithOriginalRecommendation = agentMessages.find(function (agentMessage) {
            return agentMessage.match("^".concat(selectedRecommendationRegEx, "$"));
          });
          if (!isMatchingWithOriginalRecommendation) {
            didAgentUpdateRecommendations = true;
            return 1; // break
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          if (_loop()) break;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return didAgentUpdateRecommendations;
    },
    /**
     * Send an Agent Coach event log whenever the agent sends a message
     * https://confluence.labs.nuance.com/display/NDEP/Draft+Agent+Coach+2.0+Event+Payload+Requirements
     * @method sendAgentMessageEvent
     * @param {TranscriptLine} transcriptLine the transcript line just rendered by the sent agent message
     * @param {Object} message message object being sent to AEAPI
     */
    sendAgentMessageEvent: function sendAgentMessageEvent(transcriptLine) {
      var _this2 = this;
      var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var agentCoachLogging = transcriptLine.get('agentCoachLogging') || {};
      var filterAsIType = agentCoachLogging.filterAsIType;
      var selectedTranscriptLine = this.get('selectedTranscriptLine');
      var didEditRecommendations = this.didAgentEditRecommendations(transcriptLine);
      var seenRecommendedIds = [];
      if (this.get('engagement.scriptView') == 'agent-coach') {
        var _agentCoachLogging$se = agentCoachLogging.seenRecommendationUUIDs,
          seenRecommendationUUIDs = _agentCoachLogging$se === void 0 ? [] : _agentCoachLogging$se;
        seenRecommendedIds = seenRecommendationUUIDs.length ? seenRecommendationUUIDs : this.get('engagement.ui.agentCoachPreviouslySeenRecommendationUUIDs');
      }
      var cheetahRecommendations = selectedTranscriptLine.get('cheetahRecommendations') || [];
      var returnedRecommendationIDs = selectedTranscriptLine ? cheetahRecommendations.mapBy('answerUuid').slice(0, 10) : [];
      var seenFilteredRecommendationIds = filterAsIType ? agentCoachLogging.seenFilteredRecommendationUUIDs : [];
      var selectedRecommendations = agentCoachLogging.recommendations || [];
      var selectedRecommendationIDs = selectedRecommendations.mapBy('answerUuid').toArray();
      var selectedRecommendationCategories = selectedRecommendations.mapBy('category').toArray();
      var selectedRecommendationRanks = message.recommendationRanks ? message.recommendationRanks : this.get('engagement.ui.agentInputRecommendationRanks') || [];
      var selectedFilteredRecommendationRanks = [];
      if (filterAsIType && selectedRecommendationIDs.length && seenFilteredRecommendationIds.length) {
        selectedFilteredRecommendationRanks = selectedRecommendationIDs.map(function (selectedRecommendationID) {
          return seenFilteredRecommendationIds.indexOf(selectedRecommendationID) + 1;
        });
      }
      this.get('api.cheetahAdapter').sendEvent(this.get('cheetahLoggingUrl'), {
        eventName: 'chat.agentCoachMessageSent',
        // Required: String
        customerID: this.get('settings.clientId'),
        // Required: String
        engagementID: this.get('engagement.id'),
        // Required: String
        siteID: this.get('settings.siteId'),
        // Required: String
        businessUnitName: this.get('settings.businessUnitName'),
        // for error logging
        agentGroupName: this.get('settings.agentGroupName'),
        // for error logging
        agentMessage: transcriptLine.get('messageText'),
        // Required: String
        timestamp: Number(transcriptLine.get('timestamp')),
        // Required: Number
        didEditRecommendations: didEditRecommendations,
        // Optional: Boolean
        displayedRecommendationIDs: seenRecommendedIds.join(','),
        // Optional: String
        selectedRecommendationIDs: selectedRecommendationIDs.join(','),
        // Optional: String
        displayedFilteredRecommendationIDs: seenFilteredRecommendationIds.join(','),
        // Optional: String
        selectedFilteredRecommendationRanks: selectedFilteredRecommendationRanks.join(','),
        // Optional: String
        returnedRecommendationIDs: returnedRecommendationIDs.join(','),
        selectedRecommendationRanks: selectedRecommendationRanks.join(','),
        // Optional: String
        agentLastInputText: this.get('engagement.ui.agentCoachFilterText'),
        // Optional: String
        selectedSpeaker: selectedTranscriptLine ? selectedTranscriptLine.get('isCustomerMessage') ? 'customer' : 'agent' : '',
        // Required: String
        selectedSpeakerMessage: selectedTranscriptLine && selectedTranscriptLine.get('messageText'),
        // Required: String
        category: selectedRecommendationCategories.join(','),
        modelID: this.get('modelId') // Required: String
      }).finally(function () {
        _this2.api.trigger('cheetahEventLog', _this2);
      });
      this.selectLastCustomerMessage();
    }
  });
});