define("sdk/api/mixins/engagement/nina-coach", ["exports", "sdk/api/models/nina-coach-intent", "moment", "sdk/api/utils/nina-proxy-message-serializer"], function (_exports, _ninaCoachIntent, _moment, _ninaProxyMessageSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Mixin.create({
    isNinaCoach: Ember.computed.alias('settings.ninaCoachEngagement'),
    countdownTime: Ember.computed('api.currentTime', function () {
      var countdownEndTime = this.get('countdownEndTime');
      if (!countdownEndTime) return 0;
      var currentTime = this.get('api.currentTime');
      var countdownTimeDiff = (0, _moment.default)(countdownEndTime).diff(currentTime);
      var countdownTime = _moment.default.duration(countdownTimeDiff).asSeconds();
      return countdownTime <= 0 ? 0 : countdownTime;
    }),
    init: function init() {
      // recreate nina settings and intent objects on refresh/ reload
      var hasNinaSettings = this.get('nina');
      if (hasNinaSettings) this._restoreNinaObjects();
      this.on('newEngagement', this, this._onNinaCoachNewEngagement);
      this.on('transferred', this, this._onNinaCoachTransferred);
      this.on('initialMessagesLoaded', this, this._onNinaCoachInitialMessagesLoaded);

      //helps with the page refresh scenarios
      this._scheduleCountdownEnd();
      this._super.apply(this, arguments);
    },
    /**
     * Schedules to invoke the _onNinaCoachCountdownEnd when the countdown is 0
     * @method _scheduleCountdownEnd
     */
    _scheduleCountdownEnd: function _scheduleCountdownEnd() {
      var _this = this;
      if (!this.get('isNinaCoach') || !this.get('countdownEndTime') || !this.get('customer.connected')) return;
      this.set('countdownEndTimer', setTimeout(function () {
        return _this._onNinaCoachCountdownEnd();
      }, this.get('countdownTime') * 1000));
    },
    /**
     * Initialize Nina Coach on new engagement
     * @method _onNinaCoachNewEngagement
     */
    _onNinaCoachNewEngagement: function _onNinaCoachNewEngagement() {
      if (this.get('isNinaCoach')) this.initNinaCoach();
      this._scheduleCountdownEnd();
    },
    /**
     * When transferred, send "LC" resolution and close Nina Coach
     * @method _onNinaCoachTransferred
     */
    _onNinaCoachTransferred: function _onNinaCoachTransferred() {
      var _this2 = this;
      if (!this.get('isNinaCoach')) return;
      this.sendNinaCoachEscalationResolution('LC').then(function () {
        _this2._closeNinaCoach();
      });
    },
    /**
     * When countdown reaches 0, set agent status to "busy-auto" and close Nina Coach
     * @method _onNinaCoachCountdownEnd
     */
    _onNinaCoachCountdownEnd: function _onNinaCoachCountdownEnd() {
      var _this3 = this;
      if (!this.get('isNinaCoach') || !this.get('customer.connected')) return;

      // if the agent hasn't interacted and countdown ends,
      // set status to busy-auto
      if (!this.get('agentInteracted')) {
        this.get('api.agent').setBusyAuto();
      }
      this.sendNinaCoachEscalationResolution('TIMEOUT').then(function () {
        _this3._closeNinaCoach();
      });
    },
    /**
     * Highlight user answer after initial messages are loaded
     * @method _onNinaCoachInitialMessagesLoaded
     */
    _onNinaCoachInitialMessagesLoaded: function _onNinaCoachInitialMessagesLoaded() {
      // sometimes async messages are loaded at a later time
      // and the userText should be selected after all messages load
      this._markUserAnswer(this.get('nina.userAnswer'));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.clearCountdownEndTimer();
      this.off('newEngagement', this, this._onNinaCoachNewEngagement);
      this.off('transferred', this, this._onNinaCoachTransferred);
      this.off('initialMessagesLoaded', this, this._onNinaCoachInitialMessagesLoaded);
    },
    /**
     * Sets initial nina-related settings to "engagement.nina"
     * Sets countdown timer end time
     * Highlights/marks user answer in transcript with orange border
     * Retrieves a list of intents to be used in the intents panel
     * @method initNinaCoach
     */
    initNinaCoach: function initNinaCoach() {
      var _this4 = this;
      this._setInitialNinaSettings();
      var _this$get$getProperti = this.get('nina').getProperties(['ninaCoachMinimumAgentHandleTime', 'userAnswer']),
        ninaCoachMinimumAgentHandleTime = _this$get$getProperti.ninaCoachMinimumAgentHandleTime,
        userAnswer = _this$get$getProperti.userAnswer;
      this.set('countdownEndTime', (0, _moment.default)(this.get('api.currentTime')).add(ninaCoachMinimumAgentHandleTime, 'ms'));
      this._markUserAnswer(userAnswer);
      var requestOptions = this._generateRequestOptions();
      return this.get('api.adapter').ninaCoachContentMetaInfo(requestOptions).then(function (_ref) {
        var intents = _ref.intents,
          concepts = _ref.concepts;
        _this4.set('nina.intents', intents);
        _this4.set('nina.concepts', concepts);
        _this4._setActiveSuggestedIntent();
        _this4._setSuggestedIntents();
        _this4.get('api').trigger('ninaCoachIntentsLoaded', _this4, intents);
      });
    },
    /**
     * take ownership of nina coach engagement and take out of conference
     * @method takeoverNina
     */
    takeoverNina: function takeoverNina() {
      var _this5 = this;
      return this.sendNinaCoachEscalationResolution('LC').then(function () {
        return _this5.get('api.adapter').ninaCoachChatTakeOver({
          engagementId: _this5.get('id'),
          havaSessionId: _this5.get('nina.havaSessionId')
        }).then(function () {
          _this5.state.set({
            isConferenceMode: false,
            isNinaCoach: false,
            ownedByCurrentAgent: true,
            screeningMode: false
          });

          // reset engagement start time & remove countdown timer
          _this5.set('timeStarted', _this5.get('api.currentTime'));
          _this5.set('countdownEndTime', null);
          _this5._unmarkUserAnswer();
          _this5.api.trigger('ninaCoachTakeover', _this5);
          var hasDispositions = _this5.hasDispositions();
          _this5.set('ui.hasDispositions', hasDispositions);
          if (hasDispositions) return _this5.fetchDispositions();
        });
      });
    },
    /**
     * When agent clicks "No Opportunity" action button
     * @method noOpportunityNina
     */
    noOpportunityNina: function noOpportunityNina() {
      var _this6 = this;
      return this.sendNinaCoachEscalationResolution('NOOP').then(function () {
        _this6._closeNinaCoach();
      });
    },
    /**
    * When agent clicks "Send" action button
    * @method sendIntent
    * @param {String} intentId id of the selected intent
    */
    sendIntent: function sendIntent(intent) {
      var _this7 = this;
      var intentName = intent.name,
        intentId = intent.id;
      var selectedConcepts = intent.get('selectedConcepts');
      var options = {
        concepts: selectedConcepts,
        intent: intentName,
        intentId: intentId,
        intentText: intent.get('responseText')
      };
      var suggestedIntent = this.get('nina.suggestedIntent') || {};
      var activeIntent = suggestedIntent.name,
        activeIntentId = suggestedIntent.id,
        activeIntentText = suggestedIntent.answer;
      if (activeIntentId) {
        Object.assign(options, {
          activeIntent: activeIntent,
          activeIntentId: activeIntentId,
          activeIntentText: activeIntentText
        });
      }
      if (activeIntentId === intentId) {
        options.status = selectedConcepts.length ? 'proposedIntentAlternateConceptSelected' : 'proposedIntentSelected';
      } else {
        options.status = selectedConcepts.length ? 'alternateIntentAlternateConceptSelected' : 'alternateIntentSelected';
      }
      return this.sendNinaCoachEscalationResolution('NL', options).then(function () {
        _this7._closeNinaCoach();
      });
    },
    /**
     * @method sendNinaCoachEscalationResolution
     * @param {String} resolutionCode either LC, NL, TIMEOUT, or NOOP
     * @param {Object} options additional options to pass into request
     */
    sendNinaCoachEscalationResolution: function sendNinaCoachEscalationResolution(resolutionCode) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      options = Object.assign({
        resolutionCode: resolutionCode
      }, options);
      var escalationOptions = this._generateRequestOptions(options);
      return this.get('api.adapter').ninaCoachEscalationResolution(escalationOptions);
    },
    /**
     * Returns an object of required request options
     * Additional options can also be passed in to add to the default request options
     * @method _generateRequestOptions
     */
    _generateRequestOptions: function _generateRequestOptions() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      options.engagementId = this.get('id');
      var requestOptions = this.get('nina').getProperties(['contentVersion', 'havaSessionId', 'iid', 'sci']);
      return Object.assign(requestOptions, options);
    },
    /**
     * Add highlighting/mark to transcript line that triggered escalation
     * @method _markUserAnswer
     * @param {String} userAnswer customer message text that caused Nina Coach escalation
     */
    _markUserAnswer: function _markUserAnswer(userAnswer) {
      // find latest message that matches userAnswer
      var transcriptLines = this.get('messages').toArray().reverse();
      var transcriptLine = transcriptLines.findBy('messageText', userAnswer);
      if (transcriptLine) transcriptLine.set('ninaCoachUserAnswer', true);
    },
    /**
     * Remove highlighting/mark on transcript line when agent takesover engagement
     * @method _unmarkUserAnswer
     */
    _unmarkUserAnswer: function _unmarkUserAnswer() {
      var transcriptLines = this.get('messages').toArray();
      var transcriptLine = transcriptLines.findBy('ninaCoachUserAnswer');
      if (transcriptLine) transcriptLine.set('ninaCoachUserAnswer', null);
    },
    /**
     * Creates an initial selected intent and concepts (Nina's Active/ Suggested Intent)
     * @method _setActiveSuggestedIntent
     */
    _setActiveSuggestedIntent: function _setActiveSuggestedIntent() {
      var activeIntentName = this.get('nina.activeIntent');
      var activeIntentId = this.get('nina.activeIntentId');
      if (!activeIntentId) return;
      var cachedIntent = this.get('nina.intents').findBy('name', activeIntentName) || {};
      var suggestedIntent = _ninaCoachIntent.default.create({
        id: activeIntentId,
        answer: cachedIntent.answer,
        concepts: this.get('nina.suggestedConcepts'),
        alternateResponses: this.get('nina.branches'),
        name: this.get('nina.activeIntent'),
        type: 'Suggested',
        userQuestion: this.get('nina.activeUserQuestion')
      });
      this.set('nina.suggestedIntent', suggestedIntent);
      this.set('nina.selectedIntent', suggestedIntent);
    },
    /**
     * Visually mark suggested intents with a confidence level above 0
     * @method _setSuggestedIntents
     */
    _setSuggestedIntents: function _setSuggestedIntents() {
      var _this8 = this;
      var suggestedIntents = this.get('nina.suggestedIntents');
      if (suggestedIntents.length === 0) return;
      suggestedIntents.forEach(function (_ref2) {
        var id = _ref2.id,
          confidence = _ref2.confidence;
        var intent = _this8.get('nina.intents').findBy('id', id);
        if (!intent) return;
        intent.hasConfidenceLevel = true;
        intent.confidence = confidence;
      });
    },
    /**
     * Close Nina Coach engagement
     * @method _closeNinaCoach
     */
    _closeNinaCoach: function _closeNinaCoach() {
      var _this9 = this;
      return this.close({
        skipDispositions: true
      }).then(function () {
        // clear timer not to send escalation request secondly after timeout
        _this9.clearCountdownEndTimer();
      });
    },
    clearCountdownEndTimer: function clearCountdownEndTimer() {
      var countdownEndTimer = this.get('countdownEndTimer');
      countdownEndTimer && clearTimeout(countdownEndTimer);
      this.set('countdownEndTime', null);
    },
    /**
     * Move all nina-related settings to be in new "nina" property
     * @method _setInitialNinaSettings
     */
    _setInitialNinaSettings: function _setInitialNinaSettings() {
      var _this$get = this.get('settings'),
        havaSessionId = _this$get.havaSessionId,
        ninaCoachAllowTransfer = _this$get.ninaCoachAllowTransfer,
        ninaCoachMinimumAgentHandleTime = _this$get.ninaCoachMinimumAgentHandleTime,
        ninaCoachTakeOverEnabled = _this$get.ninaCoachTakeOverEnabled,
        _this$get$ninaCoachCo = _this$get.ninaCoachConversation,
        activeIntent = _this$get$ninaCoachCo.activeIntent,
        activeIntentId = _this$get$ninaCoachCo.activeIntentId,
        activeUserQuestion = _this$get$ninaCoachCo.activeUserQuestion,
        branches = _this$get$ninaCoachCo.branches,
        contentVersion = _this$get$ninaCoachCo.contentVersion,
        nBest = _this$get$ninaCoachCo.nBest,
        IID = _this$get$ninaCoachCo.IID,
        SCI = _this$get$ninaCoachCo.SCI,
        userAnswer = _this$get$ninaCoachCo.userAnswer;
      var suggestedIntents = this._extractSuggestedIntentsFromNBest(nBest);
      this.set('nina', Ember.Object.create({
        activeIntent: activeIntent,
        activeIntentId: activeIntentId,
        activeUserQuestion: activeUserQuestion,
        branches: branches,
        contentVersion: contentVersion.toString(),
        havaSessionId: havaSessionId,
        iid: IID,
        intents: null,
        nBest: nBest,
        ninaCoachAllowTransfer: ninaCoachAllowTransfer,
        ninaCoachMinimumAgentHandleTime: ninaCoachMinimumAgentHandleTime,
        ninaCoachTakeOverEnabled: ninaCoachTakeOverEnabled,
        sci: SCI,
        suggestedConcepts: getSuggestedConcepts(branches),
        suggestedIntents: suggestedIntents,
        userAnswer: userAnswer
      }));
    },
    /**
     * Recreate intent objects after page reload
     * @method _restoreNinaObjects
     */
    _restoreNinaObjects: function _restoreNinaObjects() {
      this.set('nina', Ember.Object.create(this.get('nina')));
      var intents = this.get('nina.intents').map(function (intent) {
        return _ninaCoachIntent.default.create(intent);
      });
      if (this.get('nina.activeIntentId')) {
        var suggestedIntent = _ninaCoachIntent.default.create(this.get('nina.suggestedIntent'));
        this.set('nina.suggestedIntent', suggestedIntent);
        this.set('nina.selectedIntent', suggestedIntent);
      } else {
        this.set('nina.selectedIntent', intents.findBy('id', this.get('nina.selectedIntent.id')));
      }
      this.set('nina.intents', intents);
    },
    /**
    * Normalize Nina Proxy messages and display as a message if the message isn't continuously repeated
    * Messages like ##NinaCoachPoll will be ignored if there are consecutive cases
    * @method processNinaProxyMessage
    * @param {Object} message
    */
    processNinaProxyMessage: function processNinaProxyMessage(message) {
      var ninaProxyMessage = (0, _ninaProxyMessageSerializer.default)(message);
      var maskedText = ninaProxyMessage.maskedText,
        messageText = ninaProxyMessage.messageText;
      var proxyMessageText = maskedText || messageText || '';

      // ignore Nina Proxy messages that start with "##" ex: "##NinaCoachPoll"
      if (proxyMessageText.startsWith('##')) return;
      var isNewCustomerMessage = ninaProxyMessage.messageType === 'chatLine' && !ninaProxyMessage.isTranscriptMessage;
      if (isNewCustomerMessage) this._triggerNewCustomerMessage(ninaProxyMessage);
      this._addMessage(ninaProxyMessage);
    },
    /**
     * map nBest intents by id, type, and confidence level
     *
     * "nBest": [{
     *  "concepts": [
     *    {
     *      "name": "Intent",
     *      "value": "4861823870076866426",
     *      "friendlyName": "INQUIRE_BAGGAGE_POLICY",
     *      "literal": "travel tips"
     *    }
     *  ],
     *  "confidence": 43
     * }]
     *
     * @method _extractSuggestedIntentsFromNBest
     * @return an array of suggested intents objects with the following properties:
     * - type: only "Intent" type exists at the moment
     * - id: id of the suggested intent
     * - confidence: Nina's confidence level of the suggestion
     */
    _extractSuggestedIntentsFromNBest: function _extractSuggestedIntentsFromNBest(nBest) {
      if (!nBest || nBest.length === 0) return [];
      var suggestedIntentsAndConcepts = nBest.map(function (_ref3) {
        var concepts = _ref3.concepts,
          confidence = _ref3.confidence;
        var _concepts = _slicedToArray(concepts, 1),
          _concepts$ = _concepts[0],
          name = _concepts$.name,
          value = _concepts$.value;
        return {
          type: name,
          id: value,
          confidence: confidence
        };
      });
      return suggestedIntentsAndConcepts.filterBy('type', 'Intent');
    }
  });
  /**
   * Normalizes concepts from the property "branches" in ninaContentMetaInfo response
   * @param {Array} branches
   */
  function getSuggestedConcepts(branches) {
    if (!(branches && branches.length)) return [];
    var suggestedConcepts = [];
    branches.forEach(function (branch) {
      var _branch$concepts = branch.concepts,
        concepts = _branch$concepts === void 0 ? [] : _branch$concepts;
      concepts.forEach(function (_ref4) {
        var name = _ref4.name,
          value = _ref4.value;
        if (value === 'any') return; // ignore read only concepts

        var values = value ? [value] : [];
        var cachedConcept = suggestedConcepts.findBy('name', name);
        if (cachedConcept) {
          cachedConcept.values = cachedConcept.values.concat(values);
        } else {
          suggestedConcepts.pushObject({
            name: name,
            values: values
          });
        }
      });
    });
    return suggestedConcepts;
  }
});