define("sdk/utils/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  function sum() {
    var numbers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return numbers.length && numbers.reduce(function (a, b) {
      return a + b;
    });
  }
});