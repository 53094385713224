define("sdk/components/sdk-scripts/script-section-toggles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cit+KYP4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[22,[\"showExpandAllButton\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[10,\"data-test-expand-all-script-sections\",\"\"],[3,\"action\",[[21,0,[]],\"expandAllSections\"]],[8],[1,[26,\"t\",[\"agent-settings.expand-all\"],null],false],[0,\" \"],[1,[26,\"fa-icon\",[\"caret-right\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[26,\"if\",[[26,\"and\",[[22,[\"showCollapseAllButton\"]],[22,[\"showExpandAllButton\"]]],null],\" | \"],null],false],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"showCollapseAllButton\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[10,\"data-test-collapse-all-script-sections\",\"\"],[3,\"action\",[[21,0,[]],\"collapseAllSections\"]],[8],[1,[26,\"t\",[\"agent-settings.collapse-all\"],null],false],[0,\" \"],[1,[26,\"fa-icon\",[\"caret-down\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/sdk-scripts/script-section-toggles/template.hbs"
    }
  });
});