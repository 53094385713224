define("sdk/api/mixins/engagement/auto-close", ["exports", "moment", "sdk/api/utils/format-dispositions"], function (_exports, _moment, _formatDispositions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class AIAPI.Engagement.AutoClose
   */
  var _default = _exports.default = Ember.Mixin.create({
    /**
     * Time to display warning/ preview messages before message is sent
     * @property {Number} MESSAGE_WARNING_TIME
     */
    MESSAGE_WARNING_TIME: 30 * 1000,
    // 30 seconds

    /**
     * True if engagement should auto-close itself once the engagement has ended
     * @property {Boolean} autoCloseChat
     */
    autoCloseChat: Ember.computed.alias('settings.autoCloseChat'),
    /**
     * if True, on agent message warn and close timers should be reset
     * @property {Boolean} allowAgentMessageResetTimer
     */
    allowAgentMessageResetTimer: Ember.computed.alias('settings.allowAgentMessageResetTimer'),
    /**
     * Time in seconds engagement will auto-close itself once the engagement has ended
     * @property {Number} autoCloseTimer
     */
    autoCloseTimer: Ember.computed.alias('settings.autoCloseTimer'),
    /**
    * Amount of minutes before auto-close warn message appears
    * @property {Number} autoCloseWarnMinutes
    */
    autoCloseWarnMinutes: Ember.computed.alias('settings.autocloseWarnMinutes'),
    /**
     * Warning message that is sent when auto-close warn timer expires
     * @property {Number} autoCloseWarnMessage
     */
    autoCloseWarnMessage: Ember.computed.alias('settings.autocloseWarnMessage'),
    /**
     * Amount of minutes before auto-close close message appears
     * @property {Number} autoCloseCloseMinutes
     */
    autoCloseCloseMinutes: Ember.computed.alias('settings.autocloseCloseMinutes'),
    /**
     * Close message that is sent when auto-close close timer expires
     * @property {Number} autoCloseCloseMessage
     */
    autoCloseCloseMessage: Ember.computed.alias('settings.autocloseCloseMessage'),
    /**
     * Whether auto-close has timer setting
     * autocloseCloseMinutes will always be available if auto close is enabled in Portal
     * autocloseCloseMessage, autocloseWarnMinutes, autocloseWarnMessage are all optional
     * @property {Boolean} hasAutoCloseTimer
     */
    hasAutoCloseTimer: Ember.computed.bool('autoCloseCloseMinutes'),
    /**
     * Whether auto close is enabled
     * @property {Boolean} isAutoCloseEnabled
     */
    isAutoCloseEnabled: Ember.computed('hasAutoCloseTimer', 'isConferenceMode', 'isOtherAgentConnected', 'cobrowseAccepted', function () {
      var isInConferenceWithOtherAgent = this.get('isConferenceMode') && this.get('isOtherAgentConnected');
      var isCobrowseAccepted = this.get('cobrowseAccepted');
      return this.get('hasAutoCloseTimer') && !isInConferenceWithOtherAgent && !isCobrowseAccepted;
    }),
    /**
     * Auto Close is disabled if...
     * - there are no settings for auto close timer
     * - agent is in conference with another agent
     * @property {Boolean} isAutoCloseDisabled
     */
    isAutoCloseDisabled: Ember.computed.not('isAutoCloseEnabled'),
    /**
     * Seconds since last message was sent
     * @property {Number} secondsSinceLastMessage
     */
    secondsSinceLastMessage: Ember.computed('api.currentTime', 'lastCustomerMessageTimestamp', 'lastAgentMessageSentTimestamp', function () {
      var currentTime = this.get('api.currentTime');
      var lastCustomerMessageTimestamp = this.get('lastCustomerMessageTimestamp');
      var lastAgentMessageTimestamp = this.get('lastAgentMessageSentTimestamp');
      var lastMessageTimestamp = this.get("allowAgentMessageResetTimer") && (0, _moment.default)(lastCustomerMessageTimestamp).isBefore(lastAgentMessageTimestamp) ? lastAgentMessageTimestamp : lastCustomerMessageTimestamp;
      var differenceInMilliseconds = currentTime.diff(lastMessageTimestamp);
      return Math.ceil(_moment.default.duration(differenceInMilliseconds).asSeconds());
    }),
    /**
     * Seconds before auto-close warning message is sent
     * @property {Number} secondsBeforeWarningMessageIsSent
     */
    secondsBeforeWarnMessageIsSent: Ember.computed('secondsSinceLastMessage', 'autoCloseWarnMinutes', function () {
      var autoCloseWarnMinutes = this.get('autoCloseWarnMinutes');
      if (!autoCloseWarnMinutes) return;
      return autoCloseWarnMinutes * 60 - this.get('secondsSinceLastMessage');
    }),
    /**
     * Seconds before auto-close close message is sent and the engagement is auto-closed
     * @property {Number} secondsBeforeCloseMessageIsSent
     */
    secondsBeforeCloseMessageIsSent: Ember.computed('secondsSinceLastMessage', 'autoCloseCloseMinutes', function () {
      var autoCloseCloseMinutes = this.get('autoCloseCloseMinutes');
      if (!autoCloseCloseMinutes) return;
      return autoCloseCloseMinutes * 60 - this.get('secondsSinceLastMessage');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('autoCloseTimer') && this.get('customerDisconnectTimestamp')) {
        this._startEngagementEndAutoClose();
      }

      // reinit auto-closer timers on page refresh
      if (this.get('isAutoCloseEnabled')) {
        this._initAutoClose();
        return;
      }
      this.on('newEngagement', this, this._onAutoCloseNewEngagement);
      this.on('agentDisconnected', this, this._onAutoCloseAgentDisconnected);
      this.on('customerDisconnect', this, this._onAutoCloseCustomerDisconnect);
      this.on('cobrowseEvent', this, this._onAutoCloseCobrowseEvent);
      this.api.on('agentLockout', this, this._onAutoCloseAgentLockout);
    },
    /**
     * when a new engagement is created and settings are loaded, initialize auto-close
     * @method _onAutoCloseNewEngagement
     */
    _onAutoCloseNewEngagement: function _onAutoCloseNewEngagement() {
      if (this.get('isAutoCloseDisabled')) return;
      this._initAutoClose();
    },
    /**
     * when a cobrowse event is fired stop/init auto-close
     * @method _onAutoCloseCobrowseEvent
     */
    _onAutoCloseCobrowseEvent: function _onAutoCloseCobrowseEvent(command) {
      if (!this.get('hasAutoCloseTimer')) return;
      if (command === 'startCobrowse') {
        this._stopAutoClose();
      } else if (this.get('isAutoCloseEnabled') && command === 'endCobrowse') {
        this.state.set({
          lastCustomerMessageTimestamp: this.get('api.currentTime')
        });
        this._initAutoClose();
      }
    },
    /**
     * when conferenced agent leaves, restart auto-close timers
     * @method _onAutoCloseAgentDisconnected
     */
    _onAutoCloseAgentDisconnected: function _onAutoCloseAgentDisconnected() {
      if (!this.get('hasAutoCloseTimer')) return;

      // reset customer message timestamp so engagement isn't auto-closed when other agent leaves
      this.state.set({
        lastCustomerMessageTimestamp: this.get('api.currentTime')
      });
      this._initAutoClose();
    },
    /**
     * Start engagement end auto-close if there are no dispositions
     * Prevent auto-close if customer leaves
     * @method _onAutoCloseCustomerDisconnect
     */
    _onAutoCloseCustomerDisconnect: function _onAutoCloseCustomerDisconnect() {
      var isAutoCloseChatEnabled = this.get('autoCloseChat');
      if (this.get('hasMessageQueue')) {
        this.get('delayedMessageQueue').forEach(function (deferredMessage) {
          deferredMessage.reject();
          clearTimeout(deferredMessage.timer);
        });
        this.state.set({
          delayedMessageQueue: []
        });
      }
      if (isAutoCloseChatEnabled && !this.hasDispositions()) {
        this._startEngagementEndAutoClose();
      }
      if (this.get('hasAutoCloseTimer')) {
        this._stopAutoClose();
      }
    },
    /**
     * Stop auto-close if agent is locked out
     * @method _onAutoCloseAgentLockout
     */
    _onAutoCloseAgentLockout: function _onAutoCloseAgentLockout() {
      if (this.get('hasAutoCloseTimer')) {
        this._stopAutoClose();
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('engagementEndAutoCloseTimer'));
      this._clearAutoCloseTimers();
      this._destroyInitAutoCloseTriggers();
      this.off('newEngagement', this, this._onAutoCloseNewEngagement);
      this.off('agentDisconnected', this, this._onAutoCloseAgentDisconnected);
      this.off('customerDisconnect', this, this._onAutoCloseCustomerDisconnect);
      this.off('cobrowseEvent', this, this._onAutoCloseCobrowseEvent);
      this.api.off('agentLockout', this, this._onAutoCloseAgentLockout);
    },
    _initAutoClose: function _initAutoClose() {
      this._destroyInitAutoCloseTriggers();
      this.on('newCustomerMessage', this, this._resetAutoCloseTimers);
      this.on('customerConnect', this, this._onCustomerConnect);
      this.on('transferred', this, this._stopAutoClose);
      this.on('agentConnected', this, this._stopAutoClose);
      this.on('agentMessage', this, this._onAgentMessageResetTimestamp);
      this._startAutoCloseTimers();
    },
    /**
     * Destroy trigger listeners that were created in _initAutoClose
     * @method _destroyInitAutoCloseTriggers
     */
    _destroyInitAutoCloseTriggers: function _destroyInitAutoCloseTriggers() {
      this.off('newCustomerMessage', this, this._resetAutoCloseTimers);
      this.off('customerConnect', this, this._onCustomerConnect);
      this.off('transferred', this, this._stopAutoClose);
      this.off('agentConnected', this, this._stopAutoClose);
      this.off('agentMessage', this, this._onAgentMessageResetTimestamp);
    },
    _onAgentMessageResetTimestamp: function _onAgentMessageResetTimestamp() {
      // reset agent message timestamp so all the countdowns are reset.
      var resetTimerOnAgentMessage = this.get('allowAgentMessageResetTimer');
      if (resetTimerOnAgentMessage) {
        this.state.set({
          lastAgentMessageSentTimestamp: this.get('api.currentTime')
        });
        this._resetAutoCloseTimers();
      }
    },
    /**
     * Configures timers and state if auto close functionality is enabled.
     * @method _startAutoCloseTimers
     * @private
     */
    _startAutoCloseTimers: function _startAutoCloseTimers() {
      this._clearAutoCloseTimers();
      var autoCloseWarnMinutes = this.get('autoCloseWarnMinutes');
      if (autoCloseWarnMinutes) {
        this._startAutoCloseWarnTimer();
      }
      var autoCloseCloseMinutes = this.get('autoCloseCloseMinutes');
      if (autoCloseCloseMinutes) {
        this._startAutoCloseCloseTimer();
      }
    },
    /**
    * Creates a timer to display the preview warning message 30s prior to the warning message being sent
    * @method _startAutoCloseWarnTimer
    */
    _startAutoCloseWarnTimer: function _startAutoCloseWarnTimer() {
      var _this = this;
      // show message 30s before timer expires
      var timeBeforePreviewIsDisplayed = this.get('secondsBeforeWarnMessageIsSent') * 1000 - this.get('MESSAGE_WARNING_TIME');
      var autoCloseWarnMessage = this.get('autoCloseWarnMessage');

      // Warn Preview Timer
      this.set('autoCloseWarnPreviewTimer', setTimeout(function () {
        if (!_this.get('sentAutoCloseWarnPreview')) {
          // Display Warn Preview Message
          _this.set('autoCloseCurrentPreviewWarn', autoCloseWarnMessage);
          _this._addPreviewMessage(autoCloseWarnMessage, {
            isAutoCloseWarnMessage: true
          });
          _this.set('sentAutoCloseWarnPreview', true);
          _this.trigger('sentAutoCloseWarnPreview'); // used in unit-test
        }

        if (_this.get('sentAutoCloseWarnMessage')) return;

        // Warn Send Timer
        _this.set('autoCloseWarnSendTimer', setTimeout(function () {
          // remove preview message and send warn message
          _this._removePreviewMessage(autoCloseWarnMessage);
          _this.set('autoCloseCurrentPreviewWarn');
          if (autoCloseWarnMessage) {
            return _this.send(autoCloseWarnMessage, {
              isAutoGenerated: true
            }).finally(function () {
              _this.set('sentAutoCloseWarnMessage', true);
              _this.trigger('sentAutoCloseWarnMessage'); // used in unit-test
            });
          }

          _this.set('sentAutoCloseWarnMessage', true);
          _this.trigger('sentAutoCloseWarnMessage'); // used in unit-test
        }, _this.get('secondsBeforeWarnMessageIsSent') * 1000));
      }, timeBeforePreviewIsDisplayed));
    },
    _onCustomerConnect: function _onCustomerConnect() {
      this.set.state({
        lastCustomerMessageTimestamp: this.get('api.currentTime')
      });
      this._resetAutoCloseTimers();
    },
    /**
    * Creates a timer to display the preview warning message 30s prior to the close message being sent.
    * Once auto-close time is up, the auto-close message will be sent and the engagement will be closed if there are no dispositions
    * @method _startAutoCloseCloseTimer
    */
    _startAutoCloseCloseTimer: function _startAutoCloseCloseTimer() {
      var _this2 = this;
      // show message 30s before timer expires
      var timeBeforePreviewIsDisplayed = this.get('secondsBeforeCloseMessageIsSent') * 1000 - this.get('MESSAGE_WARNING_TIME');
      var autoCloseCloseMessage = this.get('autoCloseCloseMessage');
      this.set('autoCloseClosePreviewTimer', setTimeout(function () {
        if (!_this2.get('sentAutoCloseClosePreview')) {
          // Display Close Preview Message
          _this2.set('autoCloseCurrentPreviewClose', autoCloseCloseMessage);
          _this2._addPreviewMessage(autoCloseCloseMessage, {
            isAutoCloseCloseMessage: true
          });
          _this2.set('sentAutoCloseClosePreview', true);
          _this2.trigger('sentAutoCloseClosePreview'); // used in unit-test
        }

        if (_this2.get('sentAutoCloseCloseMessage')) return;

        // Close Send Timer
        _this2.set('autoCloseCloseSendTimer', setTimeout(function () {
          // remove preview message
          _this2._removePreviewMessage(autoCloseCloseMessage);
          _this2.set('autoCloseCurrentPreviewClose');
          // send close message then close engagement
          if (autoCloseCloseMessage) {
            return _this2.send(autoCloseCloseMessage, {
              isAutoGenerated: true
            }).finally(function () {
              _this2.set('sentAutoCloseCloseMessage', true);
              _this2._autoClose();
              _this2.trigger('sentAutoCloseCloseMessage'); // used in unit-test
            });
          }

          // if no close message, just close engagement
          _this2.set('sentAutoCloseCloseMessage', true);
          _this2._autoClose();
          _this2.trigger('sentAutoCloseCloseMessage'); // used in unit-test
        }, _this2.get('secondsBeforeCloseMessageIsSent') * 1000));
      }, timeBeforePreviewIsDisplayed));
    },
    /**
     * When engagement has ended and the agent hasn't closed the engagement,
     * the engagement will auto-close itself if there are no dispositions
     * @method _startEngagementEndAutoClose
     */
    _startEngagementEndAutoClose: function _startEngagementEndAutoClose() {
      var _this3 = this;
      var customerDisconnectTimestamp = this.get('customerDisconnectTimestamp');
      var customerDisconnectTimeInMilliseconds = this.get('api.currentTime').diff(customerDisconnectTimestamp);
      var customerDisconnectTime = Math.ceil(_moment.default.duration(customerDisconnectTimeInMilliseconds).asSeconds());
      var autoCloseTimeout = this.get('autoCloseTimer') - customerDisconnectTime;
      this.set('engagementEndAutoCloseTimer', setTimeout(function () {
        // if engagement is already closed, no need to auto-close
        if (_this3.get('_closed')) return;
        _this3._autoClose();
      }, autoCloseTimeout * 1000));
    },
    /**
     * Auto closes the engagement if no dispositions are required.
     * Otherwise, sets the customer to a disconnected state so that the
     * agent can close/resolve the engagement. If a customer sends a message
     * in this state, the engagement will continue and the timers will reset.
     * @method _autoClose
     * @private
     */
    _autoClose: function _autoClose() {
      this._clearAutoCloseTimers();
      var dispositions = this._getAutoCloseDispositions();
      this.close({
        autoCloseEngagement: true,
        dispositions: dispositions
      });
    },
    /**
     * Use prefilled dispositions if they exist, otherwise
     * use default auto-close dispositions
     * @method _getAutoCloseDispositions
     */
    _getAutoCloseDispositions: function _getAutoCloseDispositions() {
      var dispositions;
      var hasDispositions = this.hasDispositions();
      if (hasDispositions) {
        if (this.get('dispositions.valid')) {
          dispositions = (0, _formatDispositions.default)(this);
          dispositions = addAutoCloseDispositionCategoryPrefix(dispositions);
        } else {
          var dispositionNotes = this.get('dispositions.notes');
          if (dispositionNotes) {
            dispositions = Object.assign(getDefaultAutoCloseDisposition(), {
              message: this.get('dispositions.notes')
            });
          } else {
            dispositions = getDefaultAutoCloseDisposition();
          }
        }
      }
      return dispositions;
    },
    /**
    * Resets the auto close timers and state on each
    * customer message.
    * @method _resetAutoCloseTimers
    * @private
    */
    _resetAutoCloseTimers: function _resetAutoCloseTimers() {
      this._stopAutoClose();
      this._startAutoCloseTimers();
    },
    /**
     * Resets auto close properties and removes auto-close preview messages
     * @method _stopAutoClose
     * @public
     */
    _stopAutoClose: function _stopAutoClose() {
      this._clearAutoCloseTimers();
      this.setProperties({
        sentAutoCloseWarnPreview: false,
        sentAutoCloseClosePreview: false,
        sentAutoCloseWarnMessage: false,
        sentAutoCloseCloseMessage: false
      });
      this._removePreviewMessage(this.get('autoCloseCurrentPreviewWarn'));
      this._removePreviewMessage(this.get('autoCloseCurrentPreviewClose'));
    },
    /**
     * Clear all auto-close timeouts
     * @method _clearAutoCloseTimers
     */
    _clearAutoCloseTimers: function _clearAutoCloseTimers() {
      clearTimeout(this.get('autoCloseWarnPreviewTimer'));
      clearTimeout(this.get('autoCloseClosePreviewTimer'));
      clearTimeout(this.get('autoCloseWarnSendTimer'));
      clearTimeout(this.get('autoCloseCloseSendTimer'));
    },
    /**
     * persist auto-close properties
     * @method persistObserver
     */
    autoClosePersistObserver: Ember.observer('lastCustomerMessageTimestamp', 'previewMessages.[]', 'sentAutoCloseWarnPreview', 'sentAutoCloseClosePreview', 'sentAutoCloseWarnMessage', 'sentAutoCloseCloseMessage', function () {
      this.get('api.storage').persistEngagement(this);
    })
  }); // add "Chat Auto-Closed_" prefix to each category name
  function addAutoCloseDispositionCategoryPrefix(dispositions) {
    dispositions.categoryReasons.forEach(function (categoryReason) {
      categoryReason.category.name = "Chat Auto-Closed_".concat(categoryReason.category.name);
    });
    return dispositions;
  }
  function getDefaultAutoCloseDisposition() {
    return {
      categoryReasons: [{
        category: {
          name: 'Chat Auto-Closed'
        },
        reasons: [{
          name: 'Customer Unresponsive'
        }]
      }]
    };
  }
});