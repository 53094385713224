define("sdk/components/sdk-scripts/script/input-script/component", ["exports", "sdk/components/sdk-scripts/script/input-script/template", "sdk/core/component", "sdk/api/models/script-line"], function (_exports, _template, _component, _scriptLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'input-script',
    /**
     * The parts that will be rendered in the component
     * Parts can include text or input object elements
     * @property renderedParts
     * @type Array
     * @private
     */

    /**
     * The input objects in rendered parts
     * @property inputs
     * @type Array
     * @private
     */

    /**
     * @property isSubmitDisabled
     * @type Boolean
     */
    isSubmitDisabled: Ember.computed('isTraining', 'inputs.@each.value', function () {
      return !!this.get('inputs').mapBy('value').filter(function (value) {
        return !value.trim();
      }).length || this.get('isTraining');
    }),
    /**
     * @property {Boolean} isCommandScript
     */
    isCommandScript: Ember.computed.alias('inputScript.isCommand'),
    init: function init() {
      var _this = this;
      (false && !(this.get('inputScript.hasInput')) && Ember.assert('script passed must be an input script', this.get('inputScript.hasInput')));
      this.set('renderedParts', []);
      this.set('inputs', []);
      this._generateRenderedTextParts();
      Ember.run.schedule('afterRender', function () {
        _this.$('input:first').focus();
      });
      this._super.apply(this, arguments);
    },
    keyDown: function keyDown(event) {
      this._super.apply(this, arguments);
      switch (event.keyCode) {
        case 27:
          {
            // ESC
            event.stopPropagation();
            this._cancelInput();
            break;
          }
      }
    },
    actions: {
      onInputKeyDown: function onInputKeyDown(event) {
        var ctrlKey = event.ctrlKey,
          key = event.key;
        if (key === 'Enter') {
          var isCtrlEnterToSubmit = this.get('api.agent.settings.ctrlEnterToSubmit');
          var shouldSubmit = isCtrlEnterToSubmit ? ctrlKey : true;
          if (shouldSubmit) {
            event.preventDefault();
            this.send('submit', event);
          }
        }
      },
      /**
       * Submit completed inline script with input values
       * @event submit
       */
      submit: function submit() {
        if (this.get('isSubmitDisabled')) return;
        var inputScript = this.get('inputScript');
        inputScript.set('isVisibleInputScriptWindow', false);
        var isCommandScript = this.get('isCommandScript');
        var scriptValue = isCommandScript ? inputScript.get('value') : this._compileScriptMessage();
        var newScriptLine = _scriptLine.default.create({
          scriptValue: scriptValue,
          scriptTreeId: inputScript.get('scriptTreeId'),
          type: inputScript.get('type')
        });
        if (inputScript.get('isCustom')) {
          newScriptLine.set('scriptType', 'custom');
        }
        if (isCommandScript) {
          newScriptLine.set('command', this._compileScriptCommand());
        }
        var onSubmit = this.get('submit');
        onSubmit && onSubmit(newScriptLine, event);
      },
      /**
       * Closes the inline script
       * @event cancel
       */
      cancel: function cancel() {
        this._cancelInput();
      },
      validate: function validate(input) {
        if (input.value === '') {
          input.set('error', 'Required field.');
        } else {
          input.set('error', null);
        }
      }
    },
    _cancelInput: function _cancelInput() {
      this.get('inputScript').set('isVisibleInputScriptWindow', false);
      this.get('onCancel')();
    },
    /**
     * Extracts text and inline input values in order
     * @method _generateRenderedTextParts
     * @private
     */
    _generateRenderedTextParts: function _generateRenderedTextParts() {
      var _this2 = this;
      var inputScript = this.get('inputScript');
      var isTextScript = inputScript.get('isText') || inputScript.get('isCustom');
      var remainingText = inputScript.get('value');
      var parts = [];
      var inputProperties = inputScript.get('inputProperties');
      if (this.get('isCommandScript')) {
        // push scriptValue to be label for command input
        parts.push(remainingText);
      }
      inputProperties.forEach(function (inputProperty, index) {
        var input = Ember.Object.create(Object.assign(inputProperty, {
          isInput: true,
          index: index,
          value: ''
        }));
        _this2.get('inputs').addObject(input);
        var inlineScript = inputProperty.inlineScript;

        // Iterate text for inlineScript match
        var inlineScriptIndex = remainingText.indexOf(inlineScript);

        // Push text before inlineScript match
        if (isTextScript) {
          parts.push(remainingText.substring(0, inlineScriptIndex));
        }

        // Push inlineScript object
        parts.push(input);

        // Set remaining text to all text after the inlineScript match
        if (isTextScript) {
          remainingText = remainingText.substring(inlineScriptIndex + inlineScript.length);
        }

        // push leftover text if last inlineScript
        if (isTextScript && index === inputProperties.length - 1) {
          // Remove trailing periods
          if (remainingText.trim() !== '.') {
            parts.push(remainingText);
          }
        }
      });
      this.set('renderedParts', parts);
    },
    /**
     * Creates script text with input values inserted
     * @method _compileScriptMessages
     * @return {String} script text with input values inserted
     * @private
     */
    _compileScriptMessage: function _compileScriptMessage() {
      return this.get('renderedParts').map(function (p) {
        return p.isInput ? p.value : p;
      }).join('');
    },
    /**
     * NOTE: commands only support only one input
     *
     * Replaces the {{** INPUT **}} in the command with the agent-entered input
     * @method _compileScriptCommand
     * @return {String} updated command with agent-entered input as parameter
     */
    _compileScriptCommand: function _compileScriptCommand() {
      var inputRegex = /{{\*{2}.*\*{2}}}/;
      var inputText = this.get('inputs').mapBy('value')[0];
      var command = this.get('inputScript.command');
      return command.replace(inputRegex, inputText);
    }
  });
});