define("sdk/components/popup-window/component", ["exports", "sdk/core/component", "ember-keyboard", "sdk/components/popup-window/template", "sdk/utils/is-element-visible", "sdk/utils/dom-helpers"], function (_exports, _component, _emberKeyboard, _template, _isElementVisible, _domHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    hasFooter: true,
    wormholeId: Ember.computed(function () {
      return "".concat(this.get('elementId'), "-wormhole");
    }),
    popupWindowId: Ember.computed(function () {
      return "".concat(this.get('elementId'), "-popup");
    }),
    onTabWindow: Ember.on((0, _emberKeyboard.keyDown)('Tab'), function (event) {
      this._windowKeydownListener(event);
    }),
    onShiftTabWindow: Ember.on((0, _emberKeyboard.keyDown)('shift+Tab'), function (event) {
      this._windowKeydownListener(event);
    }),
    onKeyEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.onClose();
    }),
    onAccessKeyPress: Ember.on((0, _emberKeyboard.keyDown)('shift+ctrl+KeyD'), function (event) {
      event.preventDefault();
      this._setInitialFocus();
    }),
    engagementFocusManager: Ember.inject.service(),
    windowSize: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('lastActiveElement', document.activeElement);
      this.set('keyboardActivated', true);
      this._setBackdrop();
      Ember.$('#ember-wormhole').append("<div id=\"".concat(this.get('wormholeId'), "\"></div>"));
      this.get('windowSize').on('resize', this, this._updatePosition);
      this.set('oldHeightWindow', Ember.$(window).height());
      this.set('oldWidthWindow', Ember.$(window).width());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.popup-window').draggable({
        handle: '.popup-window__header',
        containment: this.containment || 'window',
        stack: '.popup-window'
      });
      this.set('height', Ember.$("#".concat(this.get('popupWindowId'))).height());
      if (this.containment) {
        var containmentElement = document.querySelector(this.containment);
        var popupWindowElement = document.getElementById(this.popupWindowId);

        // set popup-window's "top" to be below containment element
        var initialTop = containmentElement.offsetTop + parseInt((0, _domHelpers.getStyleValue)(containmentElement, 'padding-top'));
        popupWindowElement.style.top = "".concat(initialTop, "px");
      }
      this._setInitialFocus();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$("#".concat(this.get('wormholeId'))).remove();
      this.get('windowSize').off('resize', this, this._updatePosition);
    },
    willRender: function willRender() {
      //initially dispositions window changes height after didRender, so first time it was expanded height should be updated
      var height = Ember.$("#".concat(this.get('popupWindowId'))).height();
      if (height !== this.get('height')) {
        this.set('height', height);
      }
    },
    didRender: function didRender() {
      var height = Ember.$("#".concat(this.get('popupWindowId'))).height();
      if (height !== this.get('height')) {
        this._updatePosition({
          heightChanged: this.get('height') - height
        });
        this.set('height', height);
      }
    },
    didDestroyElement: function didDestroyElement() {
      this._super.apply(this, arguments);
      var onHiddenFocusSelector = this.get('onHiddenFocusSelector');
      if (!onHiddenFocusSelector) {
        onHiddenFocusSelector = this.get('lastActiveElement');
      }
      Ember.$(onHiddenFocusSelector).focus();
    },
    _updatePosition: function _updatePosition() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var windowHeight = Ember.$(window).height();
      var windowWidth = Ember.$(window).width();
      var heightChanged = options.heightChanged;
      if (!(0, _isElementVisible.isElementInViewport)(Ember.$("#".concat(this.get('popupWindowId')))[0])) {
        var shiftTop;
        var shiftLeft = windowWidth - this.get('oldWidthWindow');
        if (heightChanged) {
          shiftTop = heightChanged;
        } else {
          shiftTop = windowHeight - this.get('oldHeightWindow');
        }
        var topPosition = parseInt(Ember.$("#".concat(this.get('popupWindowId'))).css('top'), 10);
        var leftPosition = parseInt(Ember.$("#".concat(this.get('popupWindowId'))).css('left'), 10);
        var position = {
          top: topPosition + shiftTop,
          left: leftPosition + shiftLeft
        };
        this._setPosition(position);
      }
      this.set('oldHeightWindow', windowHeight);
      this.set('oldWidthWindow', windowWidth);
    },
    _setPosition: function _setPosition(position) {
      var top = position.top,
        left = position.left;
      if (top < 0) top = 0;
      if (left < 0) left = 0;
      Ember.$("#".concat(this.get('popupWindowId'))).css({
        top: top,
        left: left
      });
    },
    _setInitialFocus: function _setInitialFocus() {
      var initialFocusSelector = this.get('initialFocusSelector');
      var popupWindowId = this.get('popupWindowId');
      var initialFocusElement = initialFocusSelector && document.querySelector("#".concat(popupWindowId, " ").concat(initialFocusSelector));
      this.get('engagementFocusManager').setInitialFocus(initialFocusElement || document.querySelector("#".concat(popupWindowId)));
    },
    actions: {
      onFocusIn: function onFocusIn() {
        var $nodes = this._getFocusableWindowNodes();
        if (event && event.target === $nodes[$nodes.length - 1]) {
          this.set('lastFocus', 'lastItem');
        } else if (event && event.target === $nodes[0]) {
          this.set('lastFocus', 'firstItem');
        } else {
          this.set('lastFocus', null);
        }
      },
      onKeyDown: function onKeyDown() {
        if (event.keyCode !== 9) return; // Tab key

        var isShiftKey = event.shiftKey;
        var lastFocus = this.get('lastFocus');
        var isLastItemFocus = !isShiftKey && lastFocus === 'lastItem';
        var isFirstItemFocus = isShiftKey && lastFocus === 'firstItem';
        if (isFirstItemFocus || isLastItemFocus) {
          var lastActiveElement = this.get('lastActiveElement');
          if ((lastActiveElement === window.document.body || window.getComputedStyle(lastActiveElement).visibility === 'hidden') && this.get('defaultLastActiveElement')) {
            this.set('lastActiveElement', this.get('defaultLastActiveElement'));
          }
          event.preventDefault();
          Ember.$(this.get('lastActiveElement')).focus();
        }
      }
    },
    /**
     * Manage the focus so focus moves on lastActiveElement while pressing tab+shift.
     * @method _windowKeydownListener
     * @private
     */
    _windowKeydownListener: function _windowKeydownListener(event) {
      var isBodyFocused = event.target === window.document.body;
      var isOuterPopupWindowFocused = event.target === this.$('.popup-window')[0];
      var isOuterFocused = isBodyFocused || isOuterPopupWindowFocused;
      if (event.shiftKey && isOuterFocused) {
        event.preventDefault();
        Ember.$(this.get('lastActiveElement')).focus();
      }
    },
    /**
     * Returns nodes that are part of the popup window tab order.
     * @method _getFocusableWindowNodes
     * @private
     */
    _getFocusableWindowNodes: function _getFocusableWindowNodes() {
      return this.getFocusableNodes("#".concat(this.get('wormholeId'), " .popup-window__content"), true);
    },
    /**
     * Counts the number of popup windows currently active and applies the appropriate
     * z-index to layer the window on top of each other.
     * @method _setBackdrop
     * @private
     */
    _setBackdrop: function _setBackdrop() {
      var numberOfWindows = Ember.$('.popup-window').length + 1;
      var windowZIndex = numberOfWindows * 1001;
      this.set('windowZIndex', Ember.String.htmlSafe("z-index: ".concat(windowZIndex)));
    }
  });
});