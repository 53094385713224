define("sdk/components/checkbox-select/component", ["exports", "sdk/core/component", "sdk/components/checkbox-select/template", "sdk/utils/speak"], function (_exports, _component, _template, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'checkbox-select',
    classNameBindings: ['checked'],
    checked: false,
    init: function init() {
      this._super.apply(this, arguments);
      var options = this.get('options');
      this.set('selectedOption', this.get('selected') || options.length ? options[0] : null);
    },
    actions: {
      toggleCheckbox: function toggleCheckbox(event, checked) {
        var selectedOption = this.get('selectedOption');
        this.set('checked', checked);
        if (checked) this.set('selected', selectedOption);
      },
      selectOption: function selectOption(option) {
        this.set('checked', true);
        this.set('selectedOption', option);
        this.set('selected', option);
        (0, _speak.default)("".concat(this.label, " checkbox checked"));
      }
    }
  });
});