define("sdk/utils/without", ["exports", "sdk/utils/clone"], function (_exports, _clone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = without;
  function without(obj) {
    if (!obj) return obj;
    var copy = (0, _clone.default)(obj);
    for (var _len = arguments.length, props = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      props[_key - 1] = arguments[_key];
    }
    props.forEach(function (name) {
      delete copy[name];
    });
    return copy;
  }
});