define("sdk/components/nested-dropdown/menu/nested-option/component", ["exports", "sdk/core/component", "sdk/components/nested-dropdown/menu/nested-option/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nested-dropdown-menu-nested-option',
    isMenuVisible: false,
    mouseEnter: function mouseEnter() {
      this.set('isMenuVisible', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isMenuVisible', false);
    }
  }).reopenClass({
    positionalParams: ['option']
  });
});