define("sdk/components/nested-dropdown/menu/option/component", ["exports", "sdk/core/component", "sdk/components/nested-dropdown/menu/option/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Script Group Menu - Item
   *
   * Clickable dropdown item
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'nested-dropdown-menu-option',
    /** Pass script group up to parent component */click: function click() {
      var onClick = this.get('onClick');
      onClick && onClick(this.get('option'));
    }
  }).reopenClass({
    positionalParams: ['option']
  });
});