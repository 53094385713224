define("sdk/api/credentials", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var id;
  var secret;
  id = Ember.get(NUANCE_ENV, 'identity.id');
  secret = Ember.get(NUANCE_ENV, 'identity.secret');
  var isProductionBuild = _emberGetConfig.default.environment === 'production';
  var isClientIdMissing = !id || id === '${initParam.clientId}';
  if (isClientIdMissing) {
    id = 'aeapiClientId';
    if (isProductionBuild) {
      throw new Error('Server failed to provide the necessary application client ID for authentication in the `dc-environments.js` file.');
    }
  }
  var _default = _exports.default = {
    id: id,
    secret: secret
  };
});