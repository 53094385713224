define("sdk/components/slideout-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/dmTvrqY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,\"eq\",[[22,[\"position\"]],\"right\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"slideout\"],[10,\"data-test-slideout\",\"\"],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"button\"],[10,\"class\",\"drawer\"],[11,\"aria-label\",[26,\"t\",[\"slideout.expand-details\"],null],null],[11,\"aria-expanded\",[27,[[20,\"expanded\"]]]],[10,\"data-test-drawer\",\"\"],[3,\"action\",[[21,0,[]],\"toggleExpand\"]],[8],[0,\"\\n  \"],[1,[26,\"react-MaterialIcon\",null,[[\"iconName\"],[\"ArrowDropDownOutlined\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"position\"]],\"left\"],null]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"slideout\"],[10,\"data-test-slideout\",\"\"],[8],[0,\"\\n    \"],[13,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/slideout-menu/template.hbs"
    }
  });
});