define("sdk/components/x-tabs/component", ["exports", "sdk/api/utils/runloop", "sdk/core/component", "sdk/components/x-tabs/template", "ember-keyboard", "sdk/utils/speak"], function (_exports, _runloop, _component, _template, _emberKeyboard, _speak) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_emberKeyboard.EKMixin, {
    layout: _template.default,
    componentName: 'x-tabs',
    engagementLayoutManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    windowSize: Ember.inject.service(),
    classNames: ['layout-fill'],
    classNameBindings: ['scrollButtonsActive'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.notifyPropertyChange('selectedTabIndex');
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var onRender = this.get('onRender');
      onRender && onRender();
      var selectedTabIndex = this.get('selectedTabIndex');
      if (selectedTabIndex === undefined) {
        var defaultSelectedTabIndex = this.get('tabs').findIndex(function (tab) {
          return tab && tab.data && tab.data.defaultSelected;
        });
        defaultSelectedTabIndex = defaultSelectedTabIndex === -1 ? 0 : defaultSelectedTabIndex;
        this._selectTab(defaultSelectedTabIndex);
      }
      (0, _runloop.debounce)(this, this._updateLeftRightButtonVisibility, 200);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      Ember.$(window).on("resize.x_tabs_".concat(this.get('elementId')), function () {
        Ember.run(function () {
          return Ember.run.scheduleOnce('afterRender', function () {
            _this._updateLastScrollDirectionAfterDelay();
          });
        });
      });
      this.notifyPropertyChange('lastScrollDirection');
      this.$('.tabs').on('scroll', function (event) {
        var $tabs = _this.$(event.target);
        _this.set('tabsScrollLeft', $tabs.scrollLeft());
      });
      this.engagementLayoutManager.on('resizableColumnResize', this, this._updateLeftRightButtonVisibility);
      this.windowSize.on('resize', this, this._updateLeftRightButtonVisibility);
      Ember.run.scheduleOnce('afterRender', this, this._updateLeftRightButtonVisibility);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      clearTimeout(this.get('lastScrollDirectionTimeout'));
      Ember.$(window).off("resize.x_tabs_".concat(this.get('elementId')));
      this.$('.tabs').off();
      this.engagementLayoutManager.off('resizableColumnResize', this, this._updateLeftRightButtonVisibility);
      this.windowSize.off('resize', this, this._updateLeftRightButtonVisibility);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.$('.tabs').scrollLeft(this.get('tabsScrollLeft') || 0);
      this.notifyPropertyChange('lastScrollDirection');
    },
    selectedTabData: Ember.computed('selectedTabIndex', function () {
      return this.get("tabs.".concat(this.get('selectedTabIndex')));
    }),
    updateLastScrollDirection: Ember.observer('tabs.[]', function () {
      var _this2 = this;
      Ember.run.schedule('afterRender', function () {
        _this2.notifyPropertyChange('lastScrollDirection');
      });
    }),
    scrollButtonsActive: Ember.computed.or('isActiveLeftScrollBtn', 'isActiveRightScrollBtn'),
    intlLocaleObserver: Ember.observer('intl.locale', function () {
      this._updateLastScrollDirectionAfterDelay();
    }),
    actions: {
      selectTab: function selectTab(index, tabElement) {
        if (index !== this.get('selectedTabIndex')) {
          this.onSelect && this.onSelect(index);
        }
        this._selectTab(index, tabElement);
      },
      scrollRight: function scrollRight() {
        var _this3 = this;
        if (!this.get('isActiveRightScrollBtn')) return;
        this.set('lastScrollDirection', 'right');
        Ember.run.schedule('afterRender', function () {
          var $tabs = _this3.$('.tabs');
          var tabsScrollLeft = $tabs.scrollLeft();
          var tabsWidth = $tabs.outerWidth();
          var visibilityEndsAt = tabsScrollLeft + tabsWidth;
          $tabs.find('.tab').each(function (index, tab) {
            var $tab = _this3.$(tab);
            var tabRightPosition = tabsScrollLeft + $tab.position().left + $tab.outerWidth();
            if (tabRightPosition > visibilityEndsAt) {
              var scrollLeft = tabRightPosition - tabsWidth + 25;
              $tabs.scrollLeft(scrollLeft);
              _this3.set('tabsScrollLeft', scrollLeft);
              return false; // jQuery break each method
            }
          });
        });
      },
      scrollLeft: function scrollLeft() {
        var _this4 = this;
        if (!this.get('isActiveLeftScrollBtn')) return;
        this.set('lastScrollDirection', 'left');
        Ember.run.schedule('afterRender', function () {
          var $tabs = _this4.$('.tabs');
          var tabsScrollLeft = $tabs.scrollLeft();
          var $scrollLeftButton = _this4.$('.scroll-btn.left');
          var scrollLeftButtonWidth = $scrollLeftButton.width();
          $tabs.find('.tab').each(function (index, tab) {
            var $tab = _this4.$(tab);
            var tabLeftPosition = $tab.position().left;
            var tabRightPosition = $tab.position().left + $tab.outerWidth();
            if (tabLeftPosition <= scrollLeftButtonWidth && tabRightPosition >= -15) {
              var scrollLeft = tabLeftPosition + tabsScrollLeft;
              $tabs.scrollLeft(scrollLeft);
              _this4.set('tabsScrollLeft', scrollLeft);
              return false; // jQuery break each method
            }
          });
        });
      }
    },
    focusIn: function focusIn() {
      var $tabs = this.$('[role="tab"]');
      var targetIndex = $tabs.index(event.target);
      if (targetIndex > -1) {
        (0, _speak.default)(this.get('intl').t('x-tabs.how-to-use'));
      }
      this.set('keyboardActivated', true);
    },
    focusOut: function focusOut() {
      this.set('keyboardActivated', false);
    },
    handleLeftArrowKey: Ember.on((0, _emberKeyboard.keyDown)('ArrowLeft'), function (event) {
      var $tabs = this.$('[role="tab"]');
      var targetIndex = $tabs.index(event.target);
      if (targetIndex === -1) return;
      event.preventDefault();
      if (targetIndex === 0) {
        $tabs.last().focus();
      } else {
        $tabs[targetIndex - 1].focus();
      }
    }),
    handleRightArrowKey: Ember.on((0, _emberKeyboard.keyDown)('ArrowRight'), function (event) {
      var $tabs = this.$('[role="tab"]');
      var targetIndex = $tabs.index(event.target);
      if (targetIndex === -1) return;
      event.preventDefault();
      if (targetIndex === $tabs.length - 1) {
        $tabs.first().focus();
      } else {
        $tabs[targetIndex + 1].focus();
      }
    }),
    isActiveLeftScrollBtn: Ember.computed('lastScrollDirection', 'tabsScrollLeft', function () {
      var scrollLeft = this.$('.tabs').scrollLeft();
      if (scrollLeft) return true;
    }),
    isActiveRightScrollBtn: Ember.computed('lastScrollDirection', 'tabsScrollLeft', function () {
      var $tabs = this.$('.tabs');
      var tabsWidth = $tabs.outerWidth();
      var $lastTab = $tabs.find('.tab:last-of-type');
      if (!$lastTab.length) return;
      var tabRightPosition = $lastTab.position().left + $lastTab.outerWidth();
      return tabRightPosition > tabsWidth;
    }),
    _selectTab: function _selectTab(index, tabElement) {
      var _this5 = this;
      this.set('selectedTabIndex', index);
      Ember.run.schedule('afterRender', function () {
        var $tabs = _this5.$('.tabs');
        var tabsWidth = $tabs.outerWidth();
        var $tab = _this5.$(tabElement);
        if (!$tab || !$tab.length) return;
        var tabLeftPosition = $tab.position().left;
        var tabRightPosition = $tab.position().left + $tab.outerWidth();
        if (tabLeftPosition < 0) {
          _this5.send('scrollLeft');
        } else if (tabRightPosition > tabsWidth) {
          _this5.send('scrollRight');
        }
      });
    },
    _updateLastScrollDirectionAfterDelay: function _updateLastScrollDirectionAfterDelay() {
      var _this6 = this;
      var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 500;
      clearTimeout(this.get('lastScrollDirectionTimeout'));
      this.set('lastScrollDirectionTimeout', setTimeout(function () {
        if (!_this6.get('isDestroyed') || !_this6.get('isDestroying')) {
          _this6.notifyPropertyChange('lastScrollDirection');
        }
      }, delay));
    },
    _updateLeftRightButtonVisibility: function _updateLeftRightButtonVisibility() {
      if (this.isDestroyed) return;
      var _this$element$querySe = this.element.querySelector('.tabs'),
        offsetWidth = _this$element$querySe.offsetWidth,
        scrollWidth = _this$element$querySe.scrollWidth;
      this.set('showScrollButtons', offsetWidth < scrollWidth);
    }
  });
});