define("sdk/api/models/script-group", ["exports", "sdk/api/models/script-section", "sdk/api/models/script-line"], function (_exports, _scriptSection, _scriptLine) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // used to map device type suppressions for scripts
  // to the device types we get for engagements
  var engagementDeviceMap = {
    PC: 'desktop',
    Phone: 'mobile',
    Tablet: 'tablet'
  };
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      var _this = this;
      this.scripts = this.scripts || [];
      this.sections = this.sections || [];
      if (this.errorMsg) {
        this._createErrorGroup();
        return;
      }

      // Keep `section` declared outside the forEach loop.
      var section;
      this.scriptGroup.forEach(function (script) {
        var scriptType = script.scriptType,
          scriptValue = script.scriptValue,
          command = script.command,
          hotKey = script.hotkey,
          nonEditable = script.nonEditable,
          keywords = script.keywords;
        var type = scriptType.toLowerCase();
        var devices;
        if (script.devices) {
          devices = script.devices.split(',').map(function (device) {
            return engagementDeviceMap[device];
          });
        }
        var supportedChannels = script.supportedChannels && script.supportedChannels.split(',');
        var scriptLine = _scriptLine.default.create({
          type: type,
          scriptValue: scriptValue,
          command: command,
          hotKey: hotKey,
          nonEditable: nonEditable,
          keywords: keywords,
          devices: devices,
          supportedChannels: supportedChannels,
          scriptTreeId: _this.scriptTreeId
        });
        _this.scripts.push(scriptLine);
        if (type === 'heading' || type === 'header') {
          section = _scriptSection.default.create();
          _this.sections.push(section);
          section.set('name', scriptValue);
          section.set('headerScript', scriptLine);
        } else {
          if (!section) {
            section = _scriptSection.default.create();
            _this.sections.push(section);
          }
          section.scripts.push(scriptLine);
        }
        section.set('needHighlight', false);
      });
    },
    _createErrorGroup: function _createErrorGroup() {
      this.scriptGroup = [];
      this.scripts = [];
      this.sections = [];
      var type = 'header';
      var scriptValue = 'Script Page without scripts (ID ' + this.id + ')';
      var scriptLine = _scriptLine.default.create({
        type: type,
        scriptValue: scriptValue,
        scriptTreeId: this.scriptTreeId
      });
      this.scriptGroup.push({
        scriptType: type,
        scriptValue: scriptValue
      });
      this.scripts.push(scriptLine);
      var section = _scriptSection.default.create();
      this.sections.push(section);
      section.set('name', scriptValue);
      section.set('needHighlight', true);
    }
  });
});