define("sdk/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lpbtuxxG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"nuance-home\",null,null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[22,[\"isChangingPassword\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"password-reset\",null,[[\"onSuccess\"],[[26,\"action\",[[21,0,[]],\"onChangedPassword\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[26,\"sdk-login-form\",null,[[\"authenticate\"],[[26,\"action\",[[21,0,[]],\"authenticate\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[22,[\"changedPassword\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"role\",\"alert\"],[10,\"class\",\"login__changed-password\"],[10,\"data-test-changed-password\",\"\"],[8],[0,\"\\n      \"],[1,[26,\"t\",[\"login-form.changed-password\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/login/template.hbs"
    }
  });
});