define("sdk/components/menu-select/component", ["exports", "sdk/core/component", "sdk/api/utils/runloop", "sdk/components/menu-select/template"], function (_exports, _component, _runloop, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'menu-select',
    attributeBindings: ['data-test-menu-select', 'title'],
    'data-test-menu-select': '',
    classNameBindings: ['horizontalPositionClass', 'isExpanded:options-visible', 'disabled'],
    horizontalPositionClass: Ember.computed('horizontalPosition', function () {
      if (this.get('horizontalPosition') === 'right') return 'horizontal-position-right';
      return 'horizontal-position-left';
    }),
    hasMenuArrow: true,
    isExpanded: false,
    keyDown: function keyDown() {
      var _this = this;
      var keyCode = event.keyCode;
      var isEscKey = keyCode === 27;
      var isTabKey = keyCode === 9;
      if (isEscKey) {
        this.set('isExpanded', false);
        this.$('button').focus();
      } else if (isTabKey) {
        // IE 11 will focus the body if the component is first destroyed
        (0, _runloop.later)(function () {
          _this.set('isExpanded', false);
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('hasOverlay')) {
        this.set('isExpanded', false);
      }
    },
    actions: {
      onOptionSelect: function onOptionSelect(option) {
        var _this2 = this;
        this.set('isExpanded', false);
        if (typeof this.get('onchange') === 'function') {
          this.get('onchange')(option);
        }
        (0, _runloop.later)(function () {
          _this2.$('button') && _this2.$('button').focus();
        });
      }
    }
  });
});