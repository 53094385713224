define("sdk/mixins/link-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  var _default = _exports.default = Ember.Mixin.create({
    currentLinkIndex: -1,
    enableLinkNavigation: false,
    didInsertElement: function didInsertElement() {
      this._super(arguments);
      this.set('enableLinkNavigation', this.messageLinks().length);
      var lineContent = this.$().text().trim();
      if (this.get('enableLinkNavigation')) {
        this.set('aria-label', this.get('intl').t('a11y.link-navigation-instruction', {
          content: lineContent
        }));
      } else {
        this.set('aria-label', lineContent);
      }
    },
    keyDown: function keyDown() {
      if (!this.get('enableLinkNavigation')) return;
      var _event = event,
        keyCode = _event.keyCode;
      var currentLinkIndex = this.get('currentLinkIndex');
      switch (keyCode) {
        case 37:
          {
            // Left
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusTo(currentLinkIndex - 1);
            break;
          }
        case 39:
          {
            // Right
            event.stopPropagation();
            event.preventDefault();
            this.moveFocusTo(currentLinkIndex + 1);
            break;
          }
      }
    },
    messageLinks: function messageLinks() {
      var links = _toConsumableArray(this.element.querySelectorAll('a.linkified'));
      return links;
    },
    moveFocusTo: function moveFocusTo(link) {
      var links = this.messageLinks();
      var linksLastIndex = links.length - 1;
      var currentLinkIndex = this.get('currentLinkIndex');
      var moveToLinkIndex = this.countLinkIndex(link, linksLastIndex);
      if (moveToLinkIndex === -1) {
        // focus message if edge link had focus
        this.setDefaultSettings();
        this.focusNode(this.element);
      } else {
        this.blurNode(links[currentLinkIndex]);
        this.focusNode(links[moveToLinkIndex]);
        this.set('currentLinkIndex', moveToLinkIndex);
      }
    },
    countLinkIndex: function countLinkIndex(linkIndex, linksLength) {
      var currentLinkIndex = this.get('currentLinkIndex');
      if (currentLinkIndex === -1) {
        // initially focus first/last link depending on pressed key
        if (linkIndex < 0) return linksLength;
        return 0;
      }
      if (linkIndex < 0 || linkIndex > linksLength) {
        // focus message because we reached end of the loop
        return -1;
      }
      return linkIndex;
    },
    focusIn: function focusIn() {
      var timeoutOnBlur = this.get('timeoutOnBlur');
      timeoutOnBlur && clearTimeout(timeoutOnBlur);
    },
    focusOut: function focusOut() {
      var timeoutOnBlur = setTimeout(this.setDefaultSettings.bind(this));
      this.set('timeoutOnBlur', timeoutOnBlur);
    },
    setDefaultSettings: function setDefaultSettings() {
      if (!this.get('enableLinkNavigation')) return;
      var currentLinkIndex = this.get('currentLinkIndex');
      var links = this.messageLinks();
      this.blurNode(links[currentLinkIndex]);
      this.set('currentLinkIndex', -1);
    },
    focusNode: function focusNode(domNode) {
      domNode.setAttribute('tabindex', '0');
      domNode.focus();
    },
    blurNode: function blurNode(domNode) {
      domNode && domNode.setAttribute('tabindex', '-1');
    }
  });
});