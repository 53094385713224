define("sdk/api/utils/standardize-error", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = standardizeTheError;
  /**
  * This dissects the error object to extract relevant information and constructs a new standardized error object
  * @method standardizeTheError
  * @public
  *
  * @param {Object} error - the error to process
  * @return {Object} standardError - the newly constructed object
  */
  function standardizeTheError(error) {
    var standardError = {
      level: 'error',
      //TODO: Need more requirements to set this value to either debug/error/info/warn
      time: (0, _moment.default)().format('h:mm:ss A')
    };
    if (error && error.isNetworkError) {
      var detail;
      var status = error.status;
      var xhr = error.xhr;
      if (error.payload) {
        if (status === undefined) {
          status = error.payload.status;
        }
        detail = error.payload.detail || error.payload.error_description;
      }
      if (status === undefined && xhr) {
        status = xhr.status;
      }
      if (typeof error.payload === 'string') {
        var errorTitle = error.payload.match(/<title>(.*)<\/title>/) || [];
        if (errorTitle && errorTitle.length) {
          errorTitle = errorTitle[1];
          var hasStatusLast = errorTitle.match(/http\sstatus\s+(\d+)\s*[–-]\s*(.+)/i);
          var hasStatusFirst = errorTitle.match(/(\d+)\s(.+)/i); // matches "500 Internal Server Error"

          if (hasStatusLast) {
            status = hasStatusLast[1];
            detail = hasStatusLast[2];
          } else if (hasStatusFirst) {
            status = hasStatusFirst[1];
            detail = hasStatusFirst[2];
          }
        }
      }
      standardError.title = 'Network Error Occurred';
      standardError.statusCode = typeof status === 'undefined' ? 'N/A' : status;
      if (detail) standardError.detail = detail;
      if (error.url) standardError.url = error.url;
    } else {
      standardError.errorType = error.type || 'general';
      standardError.title = standardError.errorType === 'warning' ? 'Warning' : 'Application Error Occurred';
      standardError.detail = error.message;
      if (error.stack) standardError.stack = standardError.detail = error.stack;
    }

    //Add an index so that this will help to uniquely identify a standard error object. Helps with categorization.
    var index = standardError.title;
    if (standardError.statusCode) index += '-' + standardError.statusCode;
    if (standardError.detail) index += '-' + standardError.detail;
    if (standardError.url) index += '-' + standardError.url;
    if (standardError.errorType) index += '-' + standardError.errorType;
    standardError.index = index;
    return standardError;
  }
});