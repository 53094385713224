define("sdk/utils/control-characters-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getControlCharactersList = getControlCharactersList;
  var controlCharacters = ['␀',
  //NUL
  '␁',
  //SOH
  '␂',
  //STX
  '␃',
  //ETX
  '␄',
  //EOT
  '␅',
  //ENQ
  '␆',
  //ACK
  '␇',
  //BEL
  '␈',
  //BS
  '␉',
  //HT
  '␊',
  //LF
  '␋',
  //VT
  '␌',
  //FF
  '␍',
  //CR
  '␎',
  //SO
  '␏',
  //SI
  '␐',
  //DLE
  '␑',
  //DC1
  '␒',
  //DC2
  '␓',
  //DC3
  '␔',
  //DC4
  '␕',
  //NAK
  '␖',
  //SYN
  '␗',
  //ETB
  '␘',
  //CAN
  '␙',
  //EM
  '␚',
  //SUB
  '␛',
  //ESC
  '␜',
  //FS
  '␝',
  //GS
  '␞',
  //RS
  '␟',
  //US
  '␠',
  //SP
  '␡' //DEL
  ];

  function getControlCharactersList() {
    return controlCharacters;
  }
});