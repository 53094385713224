define("sdk/components/floating-element/component", ["exports", "sdk/core/component", "sdk/components/floating-element/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'floating-element',
    attributeBindings: ['style'],
    classNameBindings: ['isHidden', 'isSlideoutExpanded', 'isActive', 'shouldIgnorePointerEvents:ignore-pointer-events'],
    isHidden: Ember.computed.not('isVisible'),
    isSlideoutExpanded: false,
    webToolManager: Ember.inject.service(),
    isWebToolsResizing: Ember.computed.reads('webToolManager.isResizing'),
    // observer property was updated
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.get('isVisible')) {
        Ember.run.schedule('afterRender', function () {
          _this.updateStyle();
        });
      }
    },
    updateStyle: function updateStyle() {
      this.set('isActive', false);
      var $placeholder = Ember.$("#".concat(this.get('placeholderId'), ":visible"));
      var shouldHide = $placeholder.height() < 15;
      if (!$placeholder.length || shouldHide) {
        this.set('style', 'display:none; position: absolute;'.htmlSafe());
        this.set('isSlideoutExpanded', false);
        return;
      } else {
        var $webToolsContainer = Ember.$('.web-tools-container');
        var slideoutIsHidden = Ember.$('.nuance-slideout-menu .slideout').css('visibility') !== 'visible';

        //checking if slideout-menu is expanded to update z-index of cobrowse floating-element, so that it was not covered by the slideout
        this.set('isSlideoutExpanded', !slideoutIsHidden);
        var isParentDraggableAndInvisible = $webToolsContainer.hasClass('draggable') && !$webToolsContainer.hasClass('dragged') && slideoutIsHidden;
        if (isParentDraggableAndInvisible) {
          this.set('style', 'display:none; position: absolute;'.htmlSafe());
          return;
        }
      }
      var styles = ["top: ".concat($placeholder.offset().top, "px"), "left: ".concat($placeholder.offset().left, "px"), "height: ".concat($placeholder.height(), "px"), "width: ".concat($placeholder.width(), "px")];
      this.set('isActive', true);
      this.set('style', styles.join(';').htmlSafe());
    }
  });
});