define("sdk/api/adapters/aeapi-adapter", ["exports", "moment", "sdk/api/adapters/api-adapter", "sdk/api/utils/nina-coach-intents-serializer", "sdk/api/utils/fetch-with-timeout", "sdk/api/utils/url"], function (_exports, _moment, _apiAdapter, _ninaCoachIntentsSerializer, _fetchWithTimeout, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class AIAPI.RESTAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.AGENT_ENGAGEMENT_API;
    },
    name: 'AEAPI',
    /**
     * Agent's user ID
     *
     * @property {String} userId
     * @private
     */
    userId: Ember.computed.alias('api.agent.userId'),
    agentId: Ember.computed.alias('userId'),
    /**
     * Session ID
     *
     * @property {String} registeredId
     * @private
     */
    registeredId: Ember.computed.alias('api.agent.registeredId'),
    /**
     * This REST call is to confirm that the engagement has been accepted
     * by an Agent Interface.
     *
     * @method acceptEngagement
     * @public
     * @param {String} engagementId
     * @return {Promise|Object}
     */
    acceptEngagement: function acceptEngagement(engagementId) {
      return this.ajax({
        url: 'acceptEngagement',
        method: 'POST',
        dataType: 'text',
        data: {
          engagementId: engagementId,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId'),
          engagementId: engagementId,
          output: 'json'
        }
      });
    },
    /**
     * Request to indicate the agent accepted the ownership transfer
     * request.This request must be sent from the agent to whom
     * ownership transfer is made. Transfer initiating agent will
     * be notified on success.
     *
     * @method acceptOwnership
     * @public
     *
     * @param {String} engagementId
     * @param {String} reason
     * @return {Promise|Object}
     */
    acceptOwnership: function acceptOwnership(engagementId, reason) {
      return this.ajax({
        url: 'acceptOwnership',
        method: 'post',
        data: {
          agentId: this.get('agentId'),
          engagementId: engagementId,
          reason: reason
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    getUploadedFiles: function getUploadedFiles(url) {
      return this.ajax({
        BASE_URL: url,
        method: 'get',
        dataType: 'arraybuffer'
      }).then(function (data) {
        var type = (0, _url.getMimeTypeFromArrayBuffer)(data, url.split('/').pop() || '');
        var file = new Blob([data], {
          type: type
        });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    },
    /**
     * Returns the list of active performance metrics for
     * the given settingIds.
     * @method getActivePerformanceMetrics
     * @public
     * @param {Array} settingIds list of one or more setting ids
     * @return {Promise|Object}
     */
    getActivePerformanceMetrics: function getActivePerformanceMetrics(settingIds) {
      settingIds = settingIds.join(',');
      return this.ajax({
        url: 'activePerformanceMetrics',
        method: 'get',
        query: {
          settingIds: settingIds,
          registeredId: this.get('api.agent.registeredId'),
          output: 'json'
        }
      }).then(function (data) {
        return data && data.metrics;
      });
    },
    /**
     * This REST call is to post the agent status
     *
     * @method agentStatus
     * @public
     *
     * @param {String} state
     * @param {String} reason
     *
     * @return {Promise|Object}
     */
    agentStatus: function agentStatus(state, reason) {
      return this.ajax({
        url: 'agentStatus',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          state: state,
          reason: reason
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Post an auto transfer request only to the server
     *
     * @method autoTransferEngagement
     * @public
     *
     * @param {Object} data
     * @param {String} data.agentGroupId
     * @param {String} data.agentId
     * @param {String} data.agentNotes
     * @param {String} data.businessUnitId
     * @param {String} data.engagmentId
     * @param {String} data.initAgentGroupName
     * @param {String} data.initBusinessUnitName
     *
     * @return {Promise|Object}
     */
    autoTransferEngagement: function autoTransferEngagement(data) {
      Ember.assign(data, {
        agentId: this.get('agentId')
      });
      return this.ajax({
        url: 'autoTransferEngagement',
        method: 'post',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This REST call is used to change agent password.
     *
     * @method changePassword
     * @public
     *
     * @param {String} oldPasword
     * @param {String} newPassword
     * @return {Promise|Object}
     */
    changePassword: function changePassword(oldPassword, newPassword) {
      return this.ajax({
        bypassAuthCheck: true,
        url: 'changePassword',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          oldPassword: oldPassword,
          newPassword: newPassword,
          confirmNewPassword: newPassword
        },
        query: {
          output: 'json'
        }
      }).catch(function (error) {
        throw new Error(error.payload.detail);
      });
    },
    /**
     * Requests that the given Engagement is closed by an agent.
     * Dispositions information (if it exists) is also passed as
     * part of this request.
     *
     * @method closeEngagement
     * @public
     *
     * @param {Object} data
     * @param {String} data.agentGroupId
     * @param {String} data.agentId
     * @param {Boolean} data.autoCloseEngagement
     * @param {Boolean} data.autoTransferFailed
     * @param {String} data.businessUnitId
     * @param {Boolean} data.conversationResolved
     * @param {Object} data.dispositions
     * @param {String} data.engagementId
     * @param {Boolean} data.followUp
     * @param {String} data.followUpAgent
     * @param {String} data.followUpNotes
     * @param {Boolean} data.ninaCoachTransfer
     * @param {Boolean} data.skipDispositions
     * @param {String} data.targetAgentId
     * @param {String} data.transferConferenceMode
     * @return {Promise|Object}
     */
    closeEngagement: function closeEngagement(data) {
      return this.ajax({
        url: 'closeEngagement',
        method: 'post',
        shouldReAdd: true,
        dataType: 'text',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          engagementId: data.engagementId,
          output: 'json'
        }
      }).catch(function (error) {
        // the application should swallow 422 errors returned by the server
        if (error && error.xhr && error.xhr.status === 422) {
          return;
        }
        throw error;
      });
    },
    /**
     * Requests that the specified engagement be conferenced with
     * the specified agentGroup or agent
     *
     * @method conferenceEngagement
     * @public
     *
     * @param {Object} data
     * @return {Promise|Object}
     */
    conferenceEngagement: function conferenceEngagement(data) {
      data = Ember.assign({
        agentId: this.get('agentId')
      }, data);
      return this.ajax({
        url: 'conferenceEngagement',
        method: 'post',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This endpoint is used to transition the Agent who is
     * participant of specified Engagement(and who currently
     * joined in screening mode) from screening mode to full
     * participant mode.
     *
     * @method conferenceMode
     * @public
     *
     * @param {String} engagementId
     * @param {Array|Object} agentAttributes
     * @return {Promise|Object}
     */
    conferenceMode: function conferenceMode(engagementId) {
      var agentAttributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      return this.ajax({
        url: 'conferenceMode',
        method: 'post',
        dataType: 'text',
        data: {
          engagementId: engagementId,
          agentAttributes: agentAttributes,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This REST call allows Agent Interface to deny an engagement.
     * After receiving an engagement in (GET) messages response,
     * if an Agent Interface is not ready to accept the engagement,
     * it should explicitly deny using this end point, otherwise at
     * service end it is considered as agent lost connection.
     *
     * @method denyEngagement
     * @public
     *
     * @param {String} engagementId
     * @return {Promise|Object}
     */
    denyEngagement: function denyEngagement(engagementId) {
      return this.ajax({
        url: 'denyEngagement',
        method: 'post',
        data: {
          engagementId: engagementId,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Retrieves the list of available dispositions for this engagement.
     * Dispositions are list of predefined reasons for Agent to Close an
     * engagement.
     *
     * @method dispositions
     * @public
     *
     * @param {String} siteId
     * @param {String} scriptTreeId
     *
     * @return {Promise|Object}
     */
    dispositions: function dispositions(siteId, scriptTreeId) {
      return this.ajax({
        url: 'dispositions',
        method: 'get',
        data: {
          siteId: siteId,
          scriptTreeId: scriptTreeId,
          registeredId: this.get('api.agent.registeredId'),
          output: 'json'
        }
      }).then(function (response) {
        return response.dispositions;
      });
    },
    /**
     * This end point allow to post the escalation notes of an
     * Agent for that Engagement.
     *
     * @method escalateEngagement
     * @public
     *
     * @param {String} engagementId
     * @param {String} escalationReason
     *
     * @return {Promise|Object}
     */
    escalateEngagement: function escalateEngagement(engagementId, escalationReason) {
      return this.ajax({
        url: 'escalateEngagement',
        method: 'post',
        dataType: 'text',
        data: {
          engagementId: engagementId,
          escalateReason: escalationReason,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Agent is able to request additional chats
     * Portal -> Workforce Mangagement -> Number of additional chats allowed
     *
     * @method extraChat
     * @public
     *
     * @param {Boolean} enabled
     *
     * @return {Promise|Object}
     */
    extraChat: function extraChat(enabled) {
      return this.ajax({
        url: 'extraChat',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          enabled: enabled
        },
        query: {
          registeredId: this.get('registeredId')
        }
      });
    },
    /**
     * Retrieves a list of all conversations the agent can follow up.
     * @method followUpConversations
     * @public
     *
     * @return {Promise|Object}
     */
    followUpConversations: function followUpConversations() {
      return this.ajax({
        url: 'followUpConversations',
        method: 'get',
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Used to initiate an engagement to be followed up.
     * @method followUpEngagement
     * @public
     * @param {Object} conversation the conversation to load
     * @param {String} conversation.conversationChannel the conversation channel
     * @param {String} conversation.conversationId the conversation ID
     * @param {String} conversation.customerId the customer ID
     * @param {Number} conversation.siteId the site ID
     * @param {Number} conversation.businessUnitId the business unit ID
     * @param {Number} conversation.agentGroupId the agent group ID
     * @param {String} conversation.launchPage the launch page
     * @param {String} conversation.businessRuleId the business rule ID
     * @param {String} conversation.chatTitle the chat title
     * @param {String} conversation.launchType the launch type
     *
     * @return {Promise|Object}
     */
    followUpEngagement: function followUpEngagement(conversation) {
      var conversationChannel = conversation.conversationChannel,
        conversationId = conversation.conversationId,
        customerId = conversation.customerId,
        siteId = conversation.siteId,
        businessUnitId = conversation.businessUnitId,
        agentGroupId = conversation.agentGroupId,
        launchPage = conversation.launchPage,
        businessRuleId = conversation.businessRuleId,
        chatTitle = conversation.chatTitle,
        launchType = conversation.launchType,
        scriptTreeId = conversation.scriptTreeId;
      return this.ajax({
        url: 'followUpEngagement',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          conversationChannel: conversationChannel,
          conversationId: conversationId,
          customerId: customerId,
          siteId: siteId,
          businessUnitId: businessUnitId,
          agentGroupId: agentGroupId,
          launchPage: launchPage,
          businessRuleId: businessRuleId,
          chatTitle: chatTitle,
          launchType: launchType,
          scriptTreeId: scriptTreeId
        },
        query: {
          registeredId: this.get('registeredId')
        }
      });
    },
    fullScriptTree: function fullScriptTree() {
      return this.ajax({
        url: 'fullScriptTree',
        method: 'get',
        ignoreHalt: true,
        query: {
          registeredId: this.get('registeredId')
        }
      }).then(function (response) {
        var allTrees = [];

        // AEAPI always returns an array containing only one object.
        response.scriptTreesOfSite.forEach(function (siteTrees) {
          var siteId = siteTrees.siteId,
            _siteTrees$scriptTree = siteTrees.scriptTrees,
            scriptTrees = _siteTrees$scriptTree === void 0 ? [] : _siteTrees$scriptTree;
          scriptTrees.forEach(function (tree) {
            tree.siteId = siteId;
            allTrees.push(tree);
          });
        });
        return allTrees;
      });
    },
    /**
     * This end point is used to fire the disposition
     * event for a specified engagement.
     *
     * @method startDisposition
     * @public
     *
     * @param {String} engagementId
     *
     * @return {Promise|Object}
     */
    startDisposition: function startDisposition(engagementId) {
      return this.ajax({
        url: 'event/startDisposition',
        method: 'post',
        dataType: 'text',
        data: {
          engagementId: engagementId,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This endpoint is used to enter into engagement. This action
     * is possible for cases when agent has engagements and lost
     * his connection. After restore connection agent can send this
     * request to establish connection with engagements again. Don't
     * use this method to accept for new engagement requests. Use
     * /acceptEngagement instead.
     *
     * @method joinEngagement
     * @public
     *
     * @param {String} engagementId
     * @param {String} status
     * @param {String} messageText
     *
     * @return {Promise|Object}
     */
    joinEngagement: function joinEngagement(engagementId, status, messageText) {
      return this.ajax({
        url: 'joinEngagement',
        method: 'post',
        data: {
          engagementId: engagementId,
          status: status,
          messageText: messageText,
          agentId: this.get('agentId')
        },
        query: {
          registeredId: this.get('registeredId')
        }
      });
    },
    /**
     * This REST call authenticates an agent and adds them to
     * the list of available agents to receive new engagements.
     * In response it returns the agent ID, status and agent
     * specific settings information.
     *
     * @method login
     * @public
     *
     * @param {String} username - The agent's username
     * @param {Object} options - options for relogging
     * @param {Boolean} options.reLogin - required for entering relogin flow
     * @param {String} options.reason - the last agent status reason
     * @param {String} options.state - the last agent status state
     *
     * @return {Promise|Object} settings
     */
    login: function login(username) {
      var _this = this;
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var reLogin = options.reLogin,
        reason = options.reason,
        state = options.state,
        agentGroupIds = options.agentGroupIds;
      var getMessagesDelta;
      if (reLogin) {
        getMessagesDelta = this._getTimeSinceLastGetMessages();
      }
      return this.ajax({
        url: 'login',
        method: 'post',
        bypassHalt: true,
        data: {
          username: username,
          reLogin: reLogin,
          reason: reason,
          state: state,
          agentGroupIds: agentGroupIds
        },
        query: {
          getMessagesDelta: getMessagesDelta,
          output: 'json'
        }
      }).then(function (data) {
        data.isAuthenticated = true;
        return data;
      }).catch(function (error) {
        _this._onLoginFailure(error, reLogin);
      });
    },
    /**
     * Request to logout specified agent.
     *
     * @method logout
     * @param {Object} options
     * @param {Boolean} options.auto true when agent is automatically logged off (auto-logout)
     * @public
     *
     * @return {Promise|Object}
     */
    logout: function logout() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var auto = options.auto;
      return this.ajax({
        url: 'logout',
        method: 'post',
        dataType: 'text',
        bypassAuthCheck: !!auto,
        bypassBlock: true,
        bypassHalt: true,
        data: {
          agentId: this.get('userId'),
          auto: auto
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      }).catch(function () {});
    },
    /**
     * Masks text from original message
     * @method maskText
     * @public
     *
     * @param {Number} engagementId - Engagement Id of message
     * @param {String} originalText  - original message text (before mask)
     * @param {String} maskedChatLine  - text that will be masked
     */
    maskText: function maskText(engagementId, originalText, maskedChatLine) {
      return this.ajax({
        url: 'maskText',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          engagementId: engagementId,
          previousMaskedChatLine: originalText,
          maskedChatLine: maskedChatLine
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * The customScript endpoint is used to retrieve the list
     * of custom scripts created by the given agent.
     * @method getCustomScripts
     * @public
     *
     * @returns {Promise|Object}
     */
    getCustomScripts: function getCustomScripts() {
      return this.ajax({
        url: 'customScript',
        ignoreHalt: true,
        data: {
          output: 'json'
        }
      });
    },
    /**
     * The customScript endpoint is also used to create a list
     * of custom scripts under the given agent.
     * @method setCustomScripts
     * @param {Object} customScripts
     * @public
     *
     * @returns {Promise|Object}
     */
    setCustomScripts: function setCustomScripts(customScripts) {
      return this.ajax({
        url: 'customScript',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          customScripts: {
            scripts: customScripts
          },
          output: 'json'
        }
      });
    },
    /**
     * How many milliseconds until we decide to abort
     * the outgoing GET /messages request and try to reconnect.
     * @property {Number} MAX_GET_MESSAGES_TIMEOUT
     * @public
     */
    MAX_GET_MESSAGES_TIMEOUT: 40 * 1000,
    // 40 seconds
    /**
     * The getMessage endpoint is used to retrieve status updates for each
     * active engagement and to collect pending customer messages. The agent
     * interface client can configure their requests to either pend on the
     * server and wait for new messages, or the interface can configure their
     * requests to return immediately and simply poll this endpoint periodically
     * for updates.
     *
     * @method getMessages
     * @public
     *
     * @param {Boolean} [instantResponse=false]
     * @returns {Promise|Object}
     */
    getMessages: function getMessages() {
      var instantResponse = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set('lastGetMessagesTimestamp', this.get('api.currentTime'));

      /**
       * when running playwright, we sometimes fast forward the browser time
       * the following line will ensure that the request is not cancelled
       * when we fast forward.
       */
      var timeout = window.runningPlaywright ? undefined : this.MAX_GET_MESSAGES_TIMEOUT;
      return this.ajax({
        url: 'messages',
        timeout: timeout,
        data: {
          registeredId: this.get('registeredId'),
          output: 'json',
          instantResponse: instantResponse
        }
      });
    },
    /**
     * This REST call used to send a message to the specified active engagement.
     *
     * @method sendMessage
     * @public
     *
     * @param {Object} data
     * @param {String} data.engagementId
     * @param {String} [data.chatLineReceiverType]
     * @param {String} [data.command]
     * @param {String} [data.messageText]
     * @param {String} [data.messageType]
     * @param {String} [data.scriptTreeId]
     * @param {String} [data.scriptType]
     * @param {String} [data.state]
     *
     * @return {Promise|Object}
     */
    sendMessage: function sendMessage(data) {
      data = Ember.assign({
        agentId: this.get('agentId')
      }, data);
      (false && !(!!data.engagementId) && Ember.assert('engagementId is a required property', !!data.engagementId));
      return this.ajax({
        url: 'messages',
        method: 'post',
        dataType: 'text',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      }).then(function (response) {
        try {
          return JSON.parse(response);
        } catch (error) {
          return response;
        }
      });
    },
    /**
     * Retrieves agent's custom settings
     *
     * @method getCustomSettings
     * @public
     *
     * @return {Promise|Object}
     */
    getCustomSettings: function getCustomSettings() {
      return this.ajax({
        url: 'customSettings',
        data: {
          output: 'json',
          includeLayout: true
        }
      });
    },
    /**
     * Sets the following agent custom settings:
     * - "clicking": STRING [singleClick | doubleClick],
     * - "fontSize": INTEGER [0: small, 1: medium, 2: large],
     * - "language": STRING,
     * - "loginState": STRING [loginAsBusy | loginAsAvailable],
     * - "playSounds": BOOLEAN,
     * - "showAlerts": BOOLEAN,
     * - "layoutSettings": STRING (stringified object)
     *    {
     *      schema: "<VERSION_NUMBER>",
     *      settings: {
     *        layout: [chat-left | chat-center | chat-right | low-resolution]
     *      }
     *    }
     *
     * @method setCustomSettings
     * @public
     *
     * @param {Object} data
     * @return {Promise|Object}
     */
    setCustomSettings: function setCustomSettings(data) {
      data = Ember.assign({
        agentId: this.get('agentId')
      }, data);
      return this.ajax({
        url: 'customSettings',
        method: 'post',
        dataType: 'text',
        data: data
      });
    },
    /**
     * Allows agent to resend or skip a failed SMS message
     * @method smsCommand
     * @param {Object} data
     * @param {String} data.command RESEND | SKIP
     * @param {String} data.engagementId
     * @param {String} data.lineId
     * @return {Promise|Object}
     */
    smsCommand: function smsCommand(data) {
      data = Ember.assign({
        agentId: this.get('agentId')
      }, data);
      return this.ajax({
        url: 'smsCommand',
        method: 'post',
        dataType: 'text',
        data: data,
        query: {
          registeredId: this.get('registeredId')
        }
      });
    },
    /**
     * Request to indicate that agent refused the ownership transfer
     * request. This request must be sent from the agent to whom
     * ownership transfer Request is sent (i.e agent 2). Transfer
     * initiating agent will be notified on successful rejection
     *
     * @method refuseOwnership
     * @public
     *
     * @param {String} engagementId
     * @param {String} reason
     * @return {Promise|Object}
     */
    refuseOwnership: function refuseOwnership(engagementId, reason) {
      return this.ajax({
        url: 'refuseOwnership',
        method: 'post',
        data: {
          engagementId: engagementId,
          reason: reason,
          agentId: this.get('agentId')
        },
        dataType: 'text',
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Get entire chat transcript(s)
     *
     * @method requestEntireTranscripts
     * @public
     *
     * @param {Array|String} engagementIds
     * @return {Promise|Object}
     */
    requestEntireTranscripts: function requestEntireTranscripts(engagementIds) {
      return this.ajax({
        url: 'requestEntireTranscripts',
        method: 'get',
        data: {
          chatIds: [].concat(engagementIds).join(','),
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Requests that the given Engagement is resolved by an agent.
     * Dispositions information (if it exists) is also passed as
     * part of this request.
     *
     * @method resolveConversation
     * @public
     *
     * @param {Object} data
     * @param {String} data.engagementId
     * @param {Object} data.dispositions
     * @param {String} data.transferConferenceMode
     * @param {Boolean} data.autoCloseEngagement // not currently used
     * @param {Boolean} data.autoTransferFailed
     * @return {Promise|Object}
     */
    resolveConversation: function resolveConversation(data) {
      return this.ajax({
        url: 'resolveConversation',
        method: 'post',
        dataType: 'json',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Retrieves the scripts associated with the specified
     * Script Group ID from the server.
     *
     * @method scriptGroup
     * @public
     *
     * @param {String} scriptGroupId
     * @return {Promise|Object}
     */
    scriptGroup: function scriptGroup(scriptGroupId) {
      return this.ajax({
        url: 'scriptGroup',
        method: 'get',
        data: {
          scriptGroupId: scriptGroupId,
          output: 'json'
        }
      });
      // .then( response => {
      //   return response.scriptGroup;
      // });
    },
    /**
     * Retrieves the script tree associated with a specific site Id from the Server.
     *
     * @method scriptTree
     * @public
     *
     * @param {String} siteId
     *
     * @return {Promise|Object}
     */
    scriptTree: function scriptTree(siteId) {
      return this.ajax({
        url: 'scriptTree',
        method: 'get',
        data: {
          siteId: siteId,
          registeredId: this.get('api.agent.registeredId'),
          output: 'json'
        }
      });
      // .then(response => {
      //   return response.scriptTreesOfSite[0].scriptTrees;
      // });
    },
    /**
     * Sends an email (recipient based on Portal settings) when an illegal word is about to be sent
     * @method sendIllegalWordNotification
     *
     * @param {String} engagementId id of engagement
     * @param agentGroupSettingId id of engagement
     * @param businessUnitSettingId id of engagement
     * @param {Array} siteId siteId of engagement
     * @param {String} illegalWord illegal word contained in illegal word list
     * @param {String} transcriptLine whole sentence typed containing the illegal word
     */
    sendIllegalWordNotification: function sendIllegalWordNotification(_ref) {
      var engagementId = _ref.engagementId,
        siteId = _ref.siteId,
        businessUnitSettingId = _ref.businessUnitSettingId,
        agentGroupSettingId = _ref.agentGroupSettingId,
        illegalWord = _ref.illegalWord,
        transcriptLine = _ref.transcriptLine;
      return this.ajax({
        url: 'sendIllegalWordNotification',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          engagementId: engagementId,
          settingIds: [0, Number(siteId), agentGroupSettingId ? Number(agentGroupSettingId) : Number(businessUnitSettingId)],
          illegalWord: illegalWord,
          transcriptLine: transcriptLine
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
    * Retrieves a list of settings from the Server.
    * If nothing is passed as an argument, all settings will be returned.
    *
    * @method settings
    * @public
    *
    * @param {Array} settingTypes
    *
    * @return {Promise|Object}
    */
    settings: function settings(settingTypes) {
      if (!settingTypes) {
        settingTypes = 'all';
      } else if (Array.isArray(settingTypes)) {
        settingTypes = settingTypes.join(',');
      }
      return this.ajax({
        url: 'settings',
        method: 'get',
        data: {
          settingTypes: settingTypes,
          registeredId: this.get('api.agent.registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This endpoint is used to get Agent's summary data about total logged
     * in agents, engagements in queue and available slots to get engagement
     *
     * @method summaryData
     * @public
     *
     * @return {Promise|Object}
     */
    summaryData: function summaryData() {
      return this.ajax({
        url: 'summaryData',
        method: 'get',
        data: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
    * This endpoint is used to get Agent's summary data about total logged
    * in agents, engagements in queue and available slots to get engagement
    * using Fetch API.
    * This is a temporary workaround to handle the side effects
    * caused by chrome's new feature that throttles JS Timers.
    * The reason 'Fetch API' is used here is because when ember-ajax is being
    * throttled by chrome, it cannot immediately send out another request.
    * @method summaryDataFetch
    * @public
    *
    * @return {Promise|Object}
    */
    summaryDataViaFetch: function summaryDataViaFetch() {
      if (window.runningTests) return Ember.RSVP.resolve();
      try {
        var headers = {
          accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: this.get('api.authAdapter.authorizationHeader')
        };
        var options = {
          contentType: 'application/json',
          crossDomain: true,
          headers: headers,
          timeout: this.AJAX_DEFAULT_TIMEOUT,
          xhrFields: {
            withCredentials: false
          }
        };
        var registeredId = this.get('registeredId');
        if (!registeredId) return Promise.resolve();
        var params = "registeredId=".concat(registeredId, "&output=json");
        return (0, _fetchWithTimeout.default)("".concat(this.BASE_URL, "summaryData?").concat(params), options).then(function (response) {
          return response.json();
        });
      } catch (e) {
        return Promise.resolve();
      }
    },
    /**
     * Retrieves the available options for transfer and
     * conference for the specified engagement
     *
     * @method transferConferenceOptions
     * @public
     *
     * @param {String} options.engagementId
     * @param {Boolean} options.ninaCoachEngagement retrieve transfer options if agent is not owner of engagement
     *
     * @return {Promise|Object}
     */
    transferConferenceOptions: function transferConferenceOptions(_ref2) {
      var engagementId = _ref2.engagementId,
        ninaCoachEngagement = _ref2.ninaCoachEngagement;
      return this.ajax({
        url: 'transferConferenceOptions',
        method: 'get',
        data: {
          engagementId: engagementId,
          ninaCoachEngagement: ninaCoachEngagement,
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      }).then(function (response) {
        return {
          businessUnits: response.businessUnit
        };
      });
    },
    /**
     * Post a transfer request only to the server
     *
     * @method transferEngagement
     * @public
     *
     * @param {Object} data
     * @param {String} data.engagementId
     * @param {String} data.agentGroupId
     * @param {String} data.agentGroupName
     * @param {Array|Object} data.agentAttributes
     * @param {String} data.agentNotes
     * @param {String} data.businessUnitId
     * @param {String} data.businessUnitName
     * @param {Boolean} data.prioritizeRequest
     * @param {String} data.targetAgentId
     * @param {String} data.initAgentGroupName
     * @param {String} data.initBusinessUnitName
     * @return {Promise|Object}
     */
    transferEngagement: function transferEngagement(data) {
      Ember.assign(data, {
        agentId: this.get('agentId')
      });
      return this.ajax({
        url: 'transferEngagement',
        method: 'post',
        data: data,
        query: {
          engagementId: data.engagementId,
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Request to transfer engagement ownership to another Agent
     *
     * @method transferOwnership
     * @public
     *
     * @param {Object} data
     * @param {String} data.engagementId
     * @param {String} data.targetAgentId
     * @return {Promise|Object}
     */
    transferOwnership: function transferOwnership(data) {
      Ember.assign(data, {
        agentId: this.get('agentId')
      });
      return this.ajax({
        url: 'transferOwnership',
        method: 'post',
        data: data,
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * This endpoint is used to verify that agent still has correct
     * session in the system. If agent get 401 error code he need to
     * send /login request again to create new correct session.
     *
     * @method verifySession
     * @public
     *
     * @param {String} agentId
     * @param {String} registeredId
     *
     * @return {Promise|Object}
     */
    verifySession: function verifySession(agentId, registeredId) {
      return this.ajax({
        bypassAuthCheck: true,
        url: 'verifySession',
        method: 'post',
        dataType: 'text',
        bypassHalt: true,
        data: {
          agentId: agentId
        },
        query: {
          registeredId: registeredId
        }
      });
    },
    /**
     * Gain ownership of the chat from nina
     *
     * @method ninaCoachChatTakeOver
     * @public
     *
     * @param {String} engagementId
     * @param {String} havaSessionId Unique Id generated by ChatRouter for current chat escalation
     */
    ninaCoachChatTakeOver: function ninaCoachChatTakeOver(_ref3) {
      var engagementId = _ref3.engagementId,
        havaSessionId = _ref3.havaSessionId;
      return this.ajax({
        url: 'ninaCoachChatTakeOver',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          engagementId: engagementId,
          havaSessionId: havaSessionId
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Retieve list of all available intents and concepts
     *
     * @method ninaCoachContentMetaInfo
     * @public
     *
     * @param {String} automatonId used to retrieve intents for Nina Coach training tab
     * @param {String} contentVersion Used by remote nina server
     * @param {String} engagementId
     * @param {String} havaSessionId Unique Id generated by ChatRouter for current chat escalation
     * @param {String} sci Used by remote nina server
     *
     */
    ninaCoachContentMetaInfo: function ninaCoachContentMetaInfo(_ref4) {
      var automatonId = _ref4.automatonId,
        contentVersion = _ref4.contentVersion,
        engagementId = _ref4.engagementId,
        havaSessionId = _ref4.havaSessionId,
        sci = _ref4.sci;
      return this.ajax({
        url: 'ninaCoachContentMetaInfo',
        method: 'get',
        data: {
          automatonId: automatonId,
          contentVersion: contentVersion,
          engagementId: engagementId,
          havaSessionId: havaSessionId,
          sci: sci
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      }).then(_ninaCoachIntentsSerializer.getNormalizedIntents);
    },
    /**
     * Notify nina server of escalation reason/resolution
     *
     * @method ninaCoachEscalationResolution
     * @public
     *
     * @param {Array} concepts array of concept objects with properties "name" and "values"
     * @param {String} engagementId
     * @param {String} activeIntent
     * @param {String} activeIntentId
     * @param {String} activeIntentText
     * @param {String} havaSessionId unique Id generated by ChatRouter for current chat escalation
     * @param {String} intent selected intent's answer/value
     * @param {String} intentId
     * @param {String} intentText
     * @param {String} iid uniquely identifies the user, used by remote nina server
     * @param {String} sci virtual agent site id that uniquely identifies the current dialog, used by remote nina server
     * @param {String} resolutionCode one of the following resolution codes:
     * - NL: Valid response from agent
     * - ERR: Error result
     * - TIMEOUT: Escalation timed out waiting for Agent response
     * - NOOP: No opportunity
     * - INC: Incomprehension
     * - LC: Livechat takeover
     * @param {String} status
     * - proposedIntentSelected: Nina's suggested intent is used
     * - proposedIntentAlternateConceptSelected: Nina's suggested intent is used and a different concept is selected
     * - alternateIntentSelected: a different intent than Nina's suggested
     * - alternateIntentAlternateConceptSelected: a different intent and concept than Nina's suggested
     */
    ninaCoachEscalationResolution: function ninaCoachEscalationResolution() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.ajax({
        url: 'ninaCoachEscalationResolution',
        method: 'post',
        dataType: 'text',
        data: {
          agentId: this.get('agentId'),
          activeIntent: options.activeIntent,
          activeIntentId: options.activeIntentId,
          activeIntentText: options.activeIntentText,
          concepts: options.concepts,
          engagementId: options.engagementId,
          havaSessionId: options.havaSessionId,
          iid: options.iid,
          intent: options.intent,
          intentId: options.intentId,
          intentText: options.intentText,
          resolutionCode: options.resolutionCode,
          sci: options.sci,
          status: options.status
        },
        query: {
          registeredId: this.get('registeredId'),
          output: 'json'
        }
      });
    },
    /**
     * Returns time since last get messages request in milliseconds.
     * @method _getTimeSinceLastGetMessages
     * @private
     * @return {Number|undefined} the number of millis
     */
    _getTimeSinceLastGetMessages: function _getTimeSinceLastGetMessages() {
      var lastGetMessagesTime = this.get('lastGetMessagesTimestamp');
      var getMessagesDelta;
      if (lastGetMessagesTime) {
        var currentTime = this.get('api.currentTime');
        var timeElapsed = (0, _moment.default)(currentTime).diff(lastGetMessagesTime);
        getMessagesDelta = _moment.default.duration(timeElapsed).asSeconds();

        // api.currentTime might be a few milliseconds behind Date.now()
        getMessagesDelta = getMessagesDelta < 0 ? 0 : getMessagesDelta;
      }
      return getMessagesDelta;
    },
    /**
    * Get all agent groups
    *
    * @method getAgentGroups
    * @public
    *
    * @return {Promise|Object}
    */
    getAgentGroups: function getAgentGroups() {
      return this.ajax({
        url: 'agentGroups',
        method: 'get'
      });
    },
    /**
     * To write the agent logs to the server
     * @method agentLogging
     * @public
     *
     * @param {Object} data
     * @return {Promise|Object}
     */
    agentLogging: function agentLogging(data) {
      return this.ajax({
        url: 'logging/agent',
        method: 'post',
        data: data
      });
    },
    /**
     * Post engagement context data to server
     * @method postEngagementContext
     * @public
     *
     * @param {String} engagementId
     * @param {Object} data engagement context data
     */
    postEngagementContext: function postEngagementContext(engagementId, data) {
      return this.ajax({
        url: 'engagementContext',
        method: 'post',
        data: data,
        headers: {
          'Content-Type': 'text/plain'
        },
        query: {
          engagementId: engagementId,
          registeredId: this.get('registeredId')
        }
      });
    },
    /**
     * Post agents dyanamic attribute data to server
     * @method postDynamicAttribute
     * @public
     *
     * @param {Object} data agents dynamic attribute data
     */
    postDynamicAttribute: function postDynamicAttribute(data) {
      return this.ajax({
        data: data,
        dataType: 'text',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'post',
        query: {
          registeredId: this.get('registeredId')
        },
        url: 'dynamicAttribute'
      });
    },
    /**
     * Intercepts the error message and updates the error object
     *  with custom error message and error service.
     * @method _onLoginFailure
     *
     * @param {Object} error the error object
     * @param {Boolean} reLogin the boolean
     * @return {Object} the updated error object
     */
    _onLoginFailure: function _onLoginFailure(error, reLogin) {
      var message = error.message,
        payload = error.payload;
      var isFromAgentRegistry = payload && payload.detail && (payload.detail.includes('agent registry') || payload.detail.includes('A message time-out has occured'));
      var isAdvancedAgent = payload && payload.detail && payload.detail.includes('have too many BUs or AGs');
      if (message === 'The ajax operation was aborted') {
        error.message = 'Application error';
        error.service = 'AEAPI';
      } else if (message === 'Request was rejected due to server error') {
        error.message = 'Application error';
        error.service = isFromAgentRegistry ? 'Agent Registry' : 'Chat Router';
      } else if (message === 'Ajax authorization failed' && payload && payload.detail === 'Internal Server Error') {
        error.message = 'Application error';
        error.service = 'Agent Registry';
      } else {
        error.message = error.payload.error_description;
      }

      // auth was successful but AEAPI was unsuccessful
      // so no need to save the token
      if (!reLogin && !isAdvancedAgent) {
        this.get('api.authAdapter').clearAuthStorage();
      }
      throw error;
    }
  });
});