define("sdk/api/utils/transcript-serializer", ["exports", "moment", "sdk/api/models/transcript-line-historic", "sdk/api/utils/message", "sdk/api/utils/date"], function (_exports, _moment, _transcriptLineHistoric, _message, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPrefixAndMessageText = getPrefixAndMessageText;
  _exports.normalizeMessages = normalizeMessages;
  function normalizeMessages(response) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var messages = Ember.get(response, 'engagements.firstObject.transcript') || [];
    var isTranscriptV3 = options.isTranscriptV3,
      source = options.source,
      useStorageProps = options.useStorageProps;
    return messages.map(function (line) {
      var engagementId;
      var messageText = line.content || line.systemInfo || line.datapass || line.pageMarker;
      var messageType;
      var prefix;
      var sender;
      var timestamp = line.timestamp;
      var hasTransferOrConferenceNotes = line.content && line.type && (0, _message.isTransferOrConferenceNotes)(line.content, line.type);
      if (line.senderId === '_opener_') {
        messageType = 'opener';
        prefix = line.senderName;
      } else if (hasTransferOrConferenceNotes) {
        messageType = 'transfer-conference-notes';
      } else if (line.type === 'AGENT_INTERNAL_MESSAGE') {
        prefix = line.senderName || line.senderId;
        sender = 'agent-internal';
      } else if (line.type === 'AGENT_MESSAGE') {
        messageType = 'agent';
        prefix = line.senderName || line.senderId;
        sender = 'agent';
      } else if (line.type === 'CLIENT_MESSAGE') {
        messageType = 'customer';
        prefix = line.senderName;
        sender = 'customer';
      } else if (line.type === 'MARKER_PAGE') {
        prefix = 'Page Marker';
        messageType = 'system';
      } else if (line.type === 'chat.clickstream' && line.pageMarker && line.pageURL && !line.datapass) {
        prefix = line.content ? 'System' : 'Page Marker';
        messageType = 'system';
      } else if ((line.type === 'CLICKSTREAM' || line.type === 'chat.clickstream') && messageText.includes('Start Keyword:')) {
        var _getPrefixAndMessageT = getPrefixAndMessageText(messageText);
        prefix = _getPrefixAndMessageT.prefix;
        messageText = _getPrefixAndMessageT.messageText;
        messageType = 'start-keyword';
      } else if ((line.type === 'CLICKSTREAM' || line.type === 'chat.clickstream') && messageText.includes(':')) {
        var _getPrefixAndMessageT2 = getPrefixAndMessageText(messageText);
        prefix = _getPrefixAndMessageT2.prefix;
        messageText = _getPrefixAndMessageT2.messageText;
        messageType = 'system';
        sender = 'system';
      } else if (line.type === 'UTILITY_MESSAGE') {
        prefix = line.senderId;
        sender = 'supervisor';
      } else if (line.senderType === 'TRANSCRIPT_SUB_HEADER' && messageText.includes(':')) {
        engagementId = messageText;
        if (isTranscriptV3) {
          prefix = null;
          messageText = (0, _date.getNiceDateTime)(timestamp);
          messageType = 'transcript-banner';
        } else {
          var _getPrefixAndMessageT3 = getPrefixAndMessageText(messageText);
          prefix = _getPrefixAndMessageT3.prefix;
          messageText = _getPrefixAndMessageT3.messageText;
          messageText = "".concat((0, _moment.default)(line.timestamp).format('ddd MMM Do YYYY, h:mm a'), "<span class=\"vertical-separator\"></span>Engagement ID ").concat(messageText);
          messageType = 'ChatID';
        }
      } else if (line.senderName) {
        if (line.senderName === 'supervisor') {
          messageType = 'system';
          prefix = line.senderAlias || line.senderId || '';
        } else if (['agent', 'customer', 'system', 'opener'].includes(line.senderName)) {
          messageType = line.senderName;
          prefix = line.senderAlias || line.senderId || line.senderName || '';
        } else if (messageText) {
          messageType = 'system';
          prefix = line.type;
        }
      } else if (messageText) {
        messageType = 'system';
        prefix = line.type;
      }
      var message = {
        engagementId: engagementId,
        messageType: messageType,
        messageText: messageText,
        prefix: prefix,
        sender: sender,
        source: source,
        timestamp: timestamp
      };
      if (prefix === 'chat.agentFollowUp') {
        message.sender = line.senderId;
      } else if (prefix === 'SAML.Datapass') {
        message.isSAMLDatapass = true;
      }
      var historicTranscriptLine = _transcriptLineHistoric.default.create(message);
      if (useStorageProps) return historicTranscriptLine.getStorageProps();
      return historicTranscriptLine;
    });
  }
  function getPrefixAndMessageText(text) {
    var indexOfFirstColon = text.indexOf(':');
    var prefix = text.slice(0, indexOfFirstColon).trim();
    var messageText = text.slice(indexOfFirstColon + 1).trim();
    return {
      prefix: prefix,
      messageText: messageText
    };
  }
});