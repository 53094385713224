define("sdk/api/models/business-rule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    id: Ember.computed.alias('engagement.settings.businessRuleId'),
    name: Ember.computed.alias('engagement.settings.chatTitle'),
    launchPage: Ember.computed.alias('engagement.settings.launchPage'),
    launchType: Ember.computed.alias('engagement.settings.launchType'),
    toJSON: function toJSON() {
      var copy = Ember.assign({}, this);
      delete copy.engagement;
      return copy;
    }
  });
});