define("sdk/components/password-reset/component", ["exports", "sdk/core/component", "sdk/components/password-reset/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class Components.PasswordReset
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'password-reset',
    tagName: 'form',
    intl: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('*:input[type!=hidden]:first').focus();
    },
    submit: function submit(event) {
      var _this = this;
      event.preventDefault();
      this.set('errorMessage', null);
      this.set('isChangingPassword', true);
      var previousUsername = sessionStorage.getItem('previousUsername');
      var username = (this.get('api.agent.userId') || this.get('username') || '').trim();
      var currentPassword = (this.get('currentPassword') || '').trim();
      var newPassword = (this.get('newPassword1') || '').trim();
      var newPasswordConfirmed = (this.get('newPassword2') || '').trim();
      var errorMessage;
      var field;
      if (!username) {
        errorMessage = this.get('intl').t('password-reset.username-is-a-required-field');
        field = 'username';
        this._focusNode('#pw-reset-username');
      } else if (username !== previousUsername) {
        errorMessage = this.get('intl').t('login-form.bad-credentials');
        field = 'username';
        this._focusNode('#pw-reset-username');
      } else if (!currentPassword) {
        errorMessage = this.get('intl').t('password-reset.please-provide-your-current-password');
        field = 'current-password';
        this._focusNode('#pw-reset-current-pw');
      } else if (!newPassword) {
        errorMessage = this.get('intl').t('password-reset.please-provide-a-new-password');
        field = 'new-password';
        this._focusNode('#pw-reset-new-pw');
      } else if (!newPasswordConfirmed) {
        errorMessage = this.get('intl').t('password-reset.please-confirm-your-new-password');
        field = 'new-password-confirmed';
        this._focusNode('#pw-reset-confirm-pw');
      } else if (newPassword.length < 12) {
        errorMessage = this.get('intl').t('password-reset.minimum-password-length');
        field = 'new-password';
        this._focusNode('#pw-reset-new-pw');
      } else if (newPassword !== newPasswordConfirmed) {
        errorMessage = this.get('intl').t('password-reset.your-passwords-must-match');
        field = 'new-password-confirmed';
        this._focusNode('#pw-reset-new-pw');
      }
      if (errorMessage) {
        this.set('isChangingPassword', false);
        var onError = this.get('onError');
        onError && onError({
          message: errorMessage,
          field: field
        });
        Ember.run.next(this, function () {
          return _this.set('errorMessage', errorMessage);
        });
        return;
      }
      var data = {
        username: username,
        currentPassword: currentPassword,
        newPassword: newPassword
      };
      if (this.get('attrs.onSubmit')) {
        var onSubmit = this.get('onSubmit');
        onSubmit && onSubmit(data);
        this.set('isChangingPassword', false);
      } else {
        this.get('api.agent').changePassword(currentPassword, newPassword).then(function () {
          var onSuccess = _this.get('onSuccess');
          onSuccess && onSuccess();
        }).catch(function (error) {
          _this.set('errorMessage', error.message);
        }).finally(function () {
          _this.set('isChangingPassword', false);
        });
      }
    },
    _focusNode: function _focusNode(selector) {
      this.$(selector).focus();
    }
  });
});