define("sdk/site/engagements/engagement/route", ["exports", "ember-keyboard", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _emberKeyboard, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_emberKeyboard.EKMixin, _authenticatedRouteMixin.default, {
    api: Ember.inject.service(),
    engagementFocusManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    ui: Ember.computed.alias('controller.engagement.ui'),
    resetLocalHotkey: Ember.on('init', Ember.observer('api.agent.activeHotkey', function () {
      var _this = this;
      Ember.run.once(function () {
        var activeHotkey = _this.get('api.agent.activeHotkey');
        if (!activeHotkey) {
          _this.set('hotkey', []);
        }
      });
    })),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('hotkey', []);
      this.set('keyboardActivated', true);
    },
    /**
     * Return focus to agent input when ESC key is pressed
     * @method onKeyEsc
     */
    onKeyEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.get('engagementFocusManager').focusAgentInput();
    }),
    /**
     * Toggle focus between recommendations and agent input
     * NOTE: recommendations only exist in Agent Coach engagements
     * @method onKeyCtrlSpace
     */
    onKeyCtrlSpace: Ember.on((0, _emberKeyboard.keyUp)('ctrl+Space'), function () {
      var engagementFocusManager = this.get('engagementFocusManager');
      if (engagementFocusManager.hasFocus('.agent-coach__recommendations button')) {
        engagementFocusManager.focusAgentInput();
      } else {
        engagementFocusManager.focusFirstRecommendation();
      }
      event.preventDefault();
    }),
    /**
     * Detects if user is holding down ctrl in an attempt
     * to enter a hotkey. Sends an action to update the
     * activeHotkey on the agent and display the hotkey
     * dropdown.
     * @param {Object} event
     * @public
     */
    enterHotkey: Ember.on((0, _emberKeyboard.keyDown)(), function (event) {
      this.activateHotkey(event);
    }),
    activateHotkey: function activateHotkey(event) {
      var isEngagementPath = this.get('router.currentRouteName') === 'site.engagements.engagement';
      if (!isEngagementPath) return;
      var keyCode = event.keyCode,
        ctrlKey = event.ctrlKey,
        altKey = event.altKey;
      var isNumpadKey = keyCode >= 96 && keyCode <= 105;
      var isNumber = keyCode >= 48 && keyCode <= 57 || isNumpadKey;
      var isArrowKey = keyCode >= 37 && keyCode <= 40;

      //detect Alt Gr modifier on key press for IE
      if (ctrlKey && altKey) return;
      if (isNumber && ctrlKey) {
        event.preventDefault();
        event.stopPropagation();
        var hotkey = this.get('hotkey');
        var char = String.fromCharCode(isNumpadKey ? keyCode - 48 : keyCode);
        hotkey.pushObject(char);
        var hotkeyCode = hotkey.join('');
        this.send('updateHotkey', hotkeyCode);
      } else if (isArrowKey && !ctrlKey) {
        this.send('focusHotkeyDropdown', event);
      }
    },
    actions: {
      handleKeyDown: function handleKeyDown(event) {
        this.activateHotkey(event);
      },
      /**
       * Displays the hotkey dropdown and updates
       * the activeHotkey on the agent. Also starts
       * a timer to reset the hotkey.
       * @method updateHotkey
       * @param {String} hotKey
       * @public
       */
      updateHotkey: function updateHotkey(hotKey) {
        var engagement = this.controller.get('engagement');
        engagement.set('ui.shouldShowHotkeys', true);
        this.set('api.agent.activeHotkey', hotKey);
      },
      /**
       * Hides the dropdown and clears the activeHotkey
       * on the agent.
       * @method resetHotkey
       * @public
       */
      resetHotkey: function resetHotkey() {
        var engagement = this.controller.get('engagement');
        engagement.set('ui.shouldShowHotkeys', false);
        this.set('api.agent.activeHotkey', '');
      },
      /**
       * Called when the user is focusing the agent input
       * and hits an arrow key, signaling the application to
       * switch focus to the hotkey dropdown. Doesn't do anything
       * unless the hotkey dropdown is already being displayed.
       * @method focusHotkeyDropdown
       * @param {Object} event
       * @public
       */
      focusHotkeyDropdown: function focusHotkeyDropdown(event) {
        this.set('ui.selectHotkeyDropdownEvent', event);
      },
      willTransition: function willTransition() {
        var engagement = this.get('controller.engagement');
        engagement.agentCoach && engagement.agentCoach.deactivate();
        this.get('api').trigger('engagementDeactivate', engagement);
        this.get('api.agent').logChatFocusLost(engagement);
      },
      didTransition: function didTransition() {
        var engagement = this.get('controller.engagement');
        this.get('api.agent').logChatFocusGained(engagement);
      }
    },
    model: function model(params) {
      var engagement = this.get('api.agent.engagements').findBy('id', params.engagement_id);
      if (!engagement) {
        return this.transitionTo('site.summary');
      }
      return engagement;
    },
    afterModel: function afterModel(engagement) {
      var _this2 = this;
      engagement.trigger('activate');
      engagement.agentCoach && engagement.agentCoach.activate();
      this.get('api').trigger('engagementActivate', engagement);
      this.set('api.agent.activeEngagementId', engagement.id);
      Ember.run.schedule('afterRender', this, function () {
        return _this2.get('engagementFocusManager').setInitialFocus();
      });
    }
  });
});