define("sdk/api/models/script-section", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.id = this.id || (0, _emberUuid.v4)();
      this.scripts = [];
    }
  });
});