define("sdk/api/utils/format-dispositions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatDispositions;
  /**
  * Takes an engagement and if it has dispositions,
  * reformats the dispositions so that they are suitable
  * for the AEAPI.
  * @method formatDispositions
  * @private
  *
  * @param {Object} engagement
  *
  * @return {Object} dispositions
  */
  function formatDispositions(engagement) {
    var dispositions;
    if (engagement.dispositions && engagement.dispositions.get('selectedItems.length')) {
      dispositions = {
        message: engagement.dispositions.get('notes'),
        categoryReasons: []
      };
      var categories = engagement.dispositions.get('selected');
      categories.forEach(function (cat) {
        var category = {
          id: cat.value,
          name: cat.label
        };
        var reasons = cat.reasons.map(function (reason) {
          return {
            id: reason.value,
            name: reason.label
          };
        });
        dispositions.categoryReasons.push({
          category: category,
          reasons: reasons
        });
      });
    }
    return dispositions;
  }
});