define("sdk/site/training/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1vfkKigj",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"page--training\"],[10,\"role\",\"tabpanel\"],[10,\"aria-labelledby\",\"tab-training\"],[10,\"data-test-training-page\",\"\"],[8],[0,\"\\n\\n  \"],[6,\"h2\"],[10,\"class\",\"page__title\"],[10,\"data-test-training-page-title\",\"\"],[8],[1,[26,\"t\",[\"training\"],null],false],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"page__subtitle\"],[8],[0,\"\\n    \"],[1,[26,\"t\",[\"sdk-scripts.training-keyboard-1\"],null],false],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"keyboard-key\"],[8],[0,\"Tab\"],[9],[0,\"\\n    \"],[1,[26,\"t\",[\"sdk-scripts.training-keyboard-2\"],null],false],[0,\"\\n    \"],[6,\"span\"],[10,\"class\",\"keyboard-key\"],[8],[0,\"Up\"],[9],[0,\" & \"],[6,\"span\"],[10,\"class\",\"keyboard-key\"],[8],[0,\"Down\"],[9],[0,\"\\n    \"],[1,[26,\"t\",[\"sdk-scripts.training-keyboard-3\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"page__body\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"api\",\"agent\",\"features\",\"scriptsV3\"]]],null,{\"statements\":[[0,\"    \"],[1,[20,\"react-ScriptsPanel\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[26,\"sdk-scripts\",null,[[\"ui\",\"isTraining\",\"openCustomScriptsEditor\",\"scriptView\"],[[22,[\"trainingUI\"]],true,[26,\"action\",[[21,0,[]],[22,[\"send\"]],\"openCustomScriptsEditor\"],null],[22,[\"trainingUI\",\"scriptView\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"trainingUI\",\"previewRichWidget\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"rich-widget-preview-popup\",null,[[\"containment\",\"richWidget\",\"hasActionFooter\"],[\".page--training\",[22,[\"trainingUI\",\"previewRichWidget\"]],[22,[\"trainingUI\",\"previewRichWidget\",\"ui\",\"hasActionFooter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/site/training/template.hbs"
    }
  });
});