define("sdk/components/menu-select/options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9oAWPBfu",
    "block": "{\"symbols\":[\"option\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[22,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[6,\"li\"],[11,\"id\",[26,\"concat\",[[22,[\"elementId\"]],\"-option-\",[21,2,[]]],null],null],[11,\"class\",[26,\"concat\",[\"menu-select-option\",[26,\"if\",[[26,\"eq\",[[22,[\"navigatedIndex\"]],[21,2,[]]],null],\" current\"],null]],null],null],[10,\"role\",\"option\"],[11,\"aria-selected\",[27,[[26,\"eq\",[[21,1,[]],[22,[\"selected\"]]],null]]]],[11,\"aria-label\",[26,\"if\",[[22,[\"ariaLabelPrefix\"]],[26,\"concat\",[[22,[\"ariaLabelPrefix\"]],\" \",[21,1,[\"label\"]]],null]],null],null],[11,\"data-test-menu-select-option\",[26,\"or\",[[21,1,[\"engagementID\"]],[21,2,[]]],null],null],[11,\"title\",[27,[[26,\"if\",[[26,\"eq\",[[21,1,[]],\"Summary\"],null],[26,\"t\",[\"header.summary\"],null],[26,\"or\",[[21,1,[\"label\"]],[21,1,[]]],null]],null]]]],[3,\"action\",[[21,0,[]],\"setCurrentIndex\",[21,2,[]]],[[\"on\"],[\"mouseenter\"]]],[3,\"action\",[[21,0,[]],\"onOptionSelected\",[21,1,[]]],[[\"on\"],[\"click\"]]],[8],[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[21,1,[]],\"Summary\"],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"t\",[\"header.summary\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[13,3,[[21,1,[]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/menu-select/options/template.hbs"
    }
  });
});