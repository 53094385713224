define("sdk/api/adapters/illegal-words-adapter", ["exports", "sdk/api/adapters/api-adapter", "sdk/api/utils/illegal-words-serializer"], function (_exports, _apiAdapter, _illegalWordsSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class IllegalWordsAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    name: 'ILLEGAL-WORDS',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.ILLEGAL_WORDS_API;
    },
    getList: function getList() {
      return this.ajax({
        query: {
          output: 'json'
        }
      }).then(_illegalWordsSerializer.normalizeIllegalWords);
    }
  });
});