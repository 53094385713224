define("sdk/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    isChangingPassword: false,
    authenticate: function authenticate() {
      var _this = this;
      var credentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var username = credentials.username,
        password = credentials.password;
      this.get('api').trigger('loggingIn');

      // in some cases agent is logged off and become not authenticated but access_token is still present in storage.
      // Clear storage to prevent errors because invalidating session requires to be authenticated
      this.get('api.authAdapter').clearAuthStorage();

      // attempt to log in if no active session exists
      return this.get('api.authAdapter').authenticate({
        username: username,
        password: password
      }).then(function (authResponse) {
        return _this.get('session').authenticate('authenticator:tc', {
          username: username,
          authResponse: authResponse
        });
      }).catch(function (error) {
        if (error.isAdvancedAgentConfigurationRequired) {
          _this.transitionToRoute('agent-group-filter');
          return;
        }
        var errorTitle = Ember.get(error, 'payload.error');
        if (errorTitle === 'account_expired') {
          _this.set('isChangingPassword', true);
          _this.set('api.agent.userId', username);
          sessionStorage.setItem('previousUsername', username);
        } else {
          throw error;
        }
      });
    },
    actions: {
      authenticate: function authenticate(username, password) {
        this.set('changedPassword', false);
        return this.authenticate({
          username: username,
          password: password
        });
      },
      onChangedPassword: function onChangedPassword() {
        this.set('changedPassword', true);
        this.set('isChangingPassword', false);
        sessionStorage.removeItem('previousUsername');
      }
    }
  });
});