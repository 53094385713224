define("sdk/api/index", ["exports", "sdk/api/adapters/ajax-queue-manager", "sdk/api/adapters/aeapi-adapter", "sdk/api/agent", "sdk/api/router", "sdk/api/storage", "sdk/api/adapters/auth-adapter", "sdk/api/adapters/cheetah-adapter", "sdk/api/adapters/dcapi-adapter", "sdk/api/adapters/metrics-api-adapter", "sdk/api/adapters/htapi-adapter", "sdk/api/adapters/rtapi-adapter", "sdk/api/adapters/async-adapter", "sdk/api/adapters/illegal-words-adapter", "sdk/api/adapters/web-rtc-adapter", "sdk/api/illegal-words", "sdk/api/metrics", "moment", "sdk/api/plugins/plugin-manager", "sdk/api/logging", "sdk/api/local-storage", "sdk/api/state-manager", "sdk/api/plugins/plugin"], function (_exports, _ajaxQueueManager, _aeapiAdapter, _agent, _router, _storage, _authAdapter, _cheetahAdapter, _dcapiAdapter, _metricsApiAdapter, _htapiAdapter, _rtapiAdapter, _asyncAdapter, _illegalWordsAdapter, _webRtcAdapter, _illegalWords, _metrics, _moment, _pluginManager, _logging, _localStorage, _stateManager, _plugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Plugin", {
    enumerable: true,
    get: function get() {
      return _plugin.default;
    }
  });
  _exports.default = void 0;
  /**
   * @module AIAPI
   */
  var _default = _exports.default = Ember.Object.extend(Ember.Evented, {
    init: function init() {
      this._setUrls();
      var injection = {
        api: this
      };
      this.ajaxQueueManager = _ajaxQueueManager.default.create({
        prioritize: _ajaxQueueManager.prioritize,
        api: this
      });
      this.storage = _storage.default.create(injection);
      this.stateManager = new _stateManager.default({
        storage: this.storage
      });
      this.agent = _agent.default.create(injection);
      this.adapter = _aeapiAdapter.default.create(injection);
      this.router = _router.default.create(injection);
      this.cheetahAdapter = _cheetahAdapter.default.create(injection);
      this.dcapiAdapter = _dcapiAdapter.default.create(injection);
      this.metricsAdapter = _metricsApiAdapter.default.create(injection);
      this.HTAPIAdapter = _htapiAdapter.default.create(injection);
      this.RTAPIAdapter = _rtapiAdapter.default.create(injection);
      this.asyncAdapter = _asyncAdapter.default.create(injection);
      this.authAdapter = _authAdapter.default.create(injection);
      this.illegalWordsAdapter = _illegalWordsAdapter.default.create(injection);
      this.webRTCAdapter = _webRtcAdapter.default.create(injection);
      this.illegalWords = _illegalWords.default.create(injection);
      this.metrics = _metrics.default.create(injection);
      this.plugins = _pluginManager.default.create(injection);
      this.logging = _logging.default.create(injection);
      this.localStorage = _localStorage.default.create(injection);
      this.currentTimeState = this.stateManager.createItem({
        context: this,
        key: 'CURRENT_TIME'
      });
      this.settings = Ember.Object.create();
      this._updateCurrentTime();
      if (window.name.match('nuance-toolbox')) {
        this.loadDemoApp();
      }
    },
    /**
     * Aborts and prevents ajax requests (i.e metrics, aeapi) from being sent
     * Closes messages router to stop polling GET /messages
     * @method abortAndBlockAllRequests
     */
    abortAndBlockAllRequests: function abortAndBlockAllRequests() {
      this.ajaxQueueManager.abortActiveRequests();
      this.ajaxQueueManager.preventOutgoingRequests();
    },
    _setUrls: function _setUrls() {
      var urls = Ember.get(window, 'NUANCE_ENV.URLS') || {};
      var defaultDomain = Ember.get(window, 'EmberENV.defaultDomain') || 'touchcommerce.com';
      setDefaultUrl('AUTHENTICATION_API', "https://auth.".concat(defaultDomain, "/oauth-server/oauth/"));
      setDefaultUrl('AGENT_ENGAGEMENT_API', "https://api.".concat(defaultDomain, "/engagementAPI/v2/agent/"));
      setDefaultUrl('HISTORIC_TRANSCRIPT_API', "https://api.".concat(defaultDomain, "/v3/transcript/historic"));
      setDefaultUrl('REALTIME_TRANSCRIPT_API', "https://api.".concat(defaultDomain, "/v3/transcript/realtime"));
      setDefaultUrl('REALTIME_METRICS_API', "https://api.".concat(defaultDomain, "/v3/metric/realtime"));
      setDefaultUrl('ILLEGAL_WORDS_API', "https://api.".concat(defaultDomain, "/illegalwords/set"));
      setDefaultUrl('ASYNC_TRANSCRIPT_API', "https://api.".concat(defaultDomain, "/v3/transcript/"));
      setDefaultUrl('DATA_COLLECTION_API', 'https://tagserverv3.inq.com/tagserver/v1/dataCollection/');
      setDefaultUrl('WEB_RTC_API', 'https://bart-access-01.eastus.cloudapp.azure.com/access/api/v1/');
      function setDefaultUrl(key, defaultUrl) {
        var regex = /^http/;
        var isUrl = !!(urls[key] || '').match(regex);
        urls[key] = isUrl ? urls[key] : defaultUrl;
      }
      this.urls = urls;
    },
    /**
     * will update api propery currentTime. this method
     * will call itself every second
     * @method _updateCurrentTime
     * @private
     */
    _updateCurrentTime: function _updateCurrentTime() {
      var now = (0, _moment.default)();
      var previousTime = this.get('currentTime');
      var currentTime = (0, _moment.default)().startOf('second');
      var nextSecond = (0, _moment.default)(now).add(1, 'seconds').startOf('second');
      var msToNextSecond = (0, _moment.default)(nextSecond).diff(now);
      this.set('currentTime', currentTime);
      this.currentTimeState.setDirect({
        currentTimeISO: currentTime.toISOString()
      });
      this.secondTimer = setTimeout(this._updateCurrentTime.bind(this), msToNextSecond);
      var jsStackMilliseconds = currentTime.diff(previousTime);
      if (jsStackMilliseconds > 60 * 1000) {
        this.get('logging').sendLog('warn', {
          event_group: 'application',
          event: 'js-execution-60s-delay',
          delay_duration_in_ms: jsStackMilliseconds
        });
      }
    },
    unload: function unload() {
      this.set('unloaded', true);
      this.get('router').close();
      clearTimeout(this.secondTimer);
      this.agent.destroy();
      this.metrics.destroy();
    },
    loadDemoApp: function loadDemoApp() {
      var configs = JSON.parse(decodeURIComponent(window.name));
      this.agent.setProperties({
        username: configs.username,
        password: configs.password
      });
      document.body.appendChild(document.createElement('script')).src = configs['toolbox-url'];
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      clearTimeout(this.secondTimer);
    }
  });
});