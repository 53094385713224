define("sdk/utils/array/batch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.ArrayProxy.extend({
    BATCH_SIZE: 5,
    _currentBatchItemIndex: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('batchItems', []);
    },
    /**
     * Creates a new batch of batchItems (maximum size = BATCH_SIZE)
     * @method createBatch
     */
    createBatch: function createBatch() {
      var initialBatchedItems = this._getBatch(0);
      this.set('_currentBatchItemIndex', initialBatchedItems.length);
      this.set('batchItems', initialBatchedItems);
      return initialBatchedItems;
    },
    addNextBatch: function addNextBatch() {
      var currentBatchItemIndex = this.get('_currentBatchItemIndex');
      var nextBatch = this._getBatch(currentBatchItemIndex);
      this.set('_currentBatchItemIndex', currentBatchItemIndex + nextBatch.length);
      this.get('batchItems').pushObjects(nextBatch);
      return nextBatch;
    },
    _getBatch: function _getBatch() {
      var batchStartIndex = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return this.get('content').slice(batchStartIndex, batchStartIndex + this.get('BATCH_SIZE'));
    }
  });
});