define("sdk/api/models/transcript-line", ["exports", "moment", "sdk/api/utils/message", "ember-uuid", "sdk/api/utils/parse-html", "sdk/api/models/filtered-transcript-line", "sdk/api/utils/json"], function (_exports, _moment, _message, _emberUuid, _parseHtml, _filteredTranscriptLine, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember;
  var storageProperties = ['activeTranscriptSearchIndex', 'activeTranscriptSearchMatches', 'category', 'conversationIncrement', 'engagementId', 'formattedTimestamp', 'hasFailedToSend', 'hidePrefix', 'isACIFPrechatDatapass', 'isAbcWidget', 'isAgentJoinsEvent', 'isAgentMessage', 'isAutoCloseCloseMessage', 'isAutoCloseWarnMessage', 'isConferenceAgentMessage', 'isCustomerMessage', 'isDetailMessage', 'isInternal', 'isInternalSystem', 'isInterrupted', 'isSelectedForAgentCoach', 'isTimeDelayed', 'isXformDatapass', 'lineId', 'messageAttributesText', 'messageDataJSON', 'messageHTML', 'messageText', 'messageTextJSON', 'prefix', 'sender', 'smsStatusMessageParts', 'source', 'timestamp', 'transcriptId', 'transcriptHistorySearchIndex', 'transcriptHistorySearchMatches', 'uuid', 'webRTCEvent'];
  var _default = _exports.default = _filteredTranscriptLine.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.engagementId = this.get('engagementId') || this.src('engagementId') || this.get('engagement.id');
      this.uuid = this.uuid || (0, _emberUuid.v4)();
      this.timestamp = this.timestamp || this.src('timestamp') || Date.now();
      this.__data__ = this.__data__ || {};
    },
    category: Ember.computed(function () {
      var messageType = this.get('type') || this.get('__data__.type');
      if (messageType === 'transcript-banner') {
        return 'transcript-banner';
      } else if (messageType === 'transfer-conference-notes') {
        return 'transfer-conference-notes';
      } else if (this.isInternalConversation || ['agent-internal', 'supervisor'].includes(this.sender)) {
        return 'internal';
      } else if (this.isAgentMessage || this.isConferenceAgentMessage) {
        return 'agent';
      } else if (this.isCustomerMessage) {
        return 'customer';
      } else {
        return 'system';
      }
    }),
    src: function src(key) {
      return this.get("__data__.".concat(key));
    },
    type: Ember.computed.alias('__data__.messageType'),
    name: Ember.computed.alias('__data__.name'),
    source: Ember.computed.alias('__data__.source'),
    state: Ember.computed.alias('__data__.state'),
    senderId: Ember.computed.alias('__data__.senderId'),
    /**
     * Returns the properties listed in storageProperties from this instance.
     * @method getStorageProps
     * @return {Object} object with properties
     */
    getStorageProps: function getStorageProps() {
      return this.getProperties(storageProperties);
    },
    /**
     * observes the items listed in storageProperties and updates the messages
     * @method updateMessagesObserver
     */
    updateMessagesObserver: (_Ember = Ember).observer.apply(_Ember, storageProperties.concat([function () {
      if (!this.engagement.isDestroyed) this.engagement.computeMessages();
    }])),
    isAgentJoinsEvent: Ember.computed('type', 'state', function () {
      return this.get('type') === 'agentJoinsChat' || this.get('state') === 'agentConnected';
    }),
    agentCoachLogging: Ember.computed.alias('__data__.agentCoachLogging'),
    recommendationRanks: Ember.computed.alias('__data__.recommendationRanks'),
    /**
     * Unaltered original messageText
     * @property {String} originalMessageText
     */
    originalMessageText: Ember.computed.alias('__data__.messageText'),
    /**
     * Reformat messageText to readable text
     * e.g removing unneeded "(internal only) " from internal messages
     * @property {String} messageText
     */
    messageText: Ember.computed(function () {
      var type = this.get('type');
      var text = this.get('originalMessageText') || this.src('text');
      if (type === 'customerData' || type === 'stateChange' && !text) {
        text = this.src('data');
      }
      return text || '';
    }),
    messageTextJSON: Ember.computed('messageText', function () {
      return (0, _json.getJSON)(this.get('messageText'));
    }),
    maskedText: Ember.computed('__data__.{maskedText,masked}', function () {
      return this.src('maskedText') || this.src('masked');
    }),
    messageData: Ember.computed.reads('__data__.messageData'),
    messageDataJSON: Ember.computed('messageData', function () {
      return (0, _json.getJSON)(this.get('messageData'));
    }),
    widgetLabel: Ember.computed('messageDataJSON', function () {
      var messageDataJSON = this.get('messageDataJSON');
      return this.get('isRichWidget') ? messageDataJSON.richWidget.widgetLabel : messageDataJSON.agentDisplayText;
    }),
    messageHTML: Ember.computed('messageText', 'maskedText', function () {
      var messageText = this.get('messageText');

      // TODO if deviceType === 'apple-business-chat' && messageText is data object
      var isAppleBusinessChatData = messageText && messageText.indexOf('interactiveData') > -1;
      if (isAppleBusinessChatData) {
        return (0, _message._parseAppleBusinessChat)(messageText, this);
      }
      return (0, _message.renderedHTML)(this);
    }),
    sender: Ember.computed(function () {
      var sender = this.src('sender');
      var member = this.src('member');
      if (member === 'agent') {
        return 'conference-agent';
      } else if (member === 'supervisor') {
        return 'supervisor';
      }
      if (sender) return sender;
      var type = this.get('type');
      if (type === 'chatLine') {
        return 'customer';
      } else if (type === 'opener') {
        return 'agent';
      } else if (type === 'agent' || type === 'customer') {
        return type;
      } else {
        return 'system';
      }
    }),
    isACIFPrechatDatapass: Ember.computed('__data__.messageText', function () {
      return this.get('messageText').includes('!-- Data Pass --');
    }),
    isAgentMessage: Ember.computed.equal('sender', 'agent'),
    isChatLine: Ember.computed.equal('type', 'chatLine'),
    isConversation: Ember.computed(function () {
      var sender = this.get('sender');
      return ['agent', 'agent-internal', 'conference-agent', 'customer', 'supervisor'].includes(sender);
    }),
    isCustomerMessage: Ember.computed.equal('sender', 'customer'),
    isCustomerData: Ember.computed.equal('type', 'customerData'),
    isDetailMessage: Ember.computed.and('isNotStateStatus', 'isNotStandardMessage'),
    isNotDetailMessage: Ember.computed.not('isDetailMessage'),
    isNotStandardMessage: Ember.computed.or('isStateChange', 'isNote', 'isCustomerData'),
    isNotStateStatus: Ember.computed.not('isStateStatus'),
    isEmptyAnswer: Ember.computed.equal('messageText', '#EmptyAnswer'),
    isFileUploadLink: Ember.computed('messageText', function () {
      return (0, _message.isFileUploadLink)(this.get('messageText'));
    }),
    isFromAgentInput: Ember.computed.equal('__data__.source', 'agent-input'),
    isInternal: Ember.computed.or('isInternalConversation', 'isInternalSystem'),
    isInternalConversation: Ember.computed.equal('__data__.chatLineReceiverType', 'internal'),
    isInternalSystem: Ember.computed('__data__.state', function () {
      var internalVisibleStates = ['Agent joins chat', 'Conference', 'agentConnected', 'agentExitedFromEngagement', 'agentLostConnection', 'agentReconnected', 'supervisorConnected', 'supervisorExitedFromEngagement', 'supervisorLostConnection'];
      return internalVisibleStates.includes(this.src('state'));
    }),
    isNinaMessage: Ember.computed.equal('senderId', 'virtualAssistant.nina'),
    isNote: Ember.computed.equal('type', 'note'),
    isSAMLDatapass: Ember.computed.bool('__data__.isSAMLDatapass'),
    isSelectedForAgentCoach: Ember.computed('engagement.agentCoach.{selectedTranscriptLine,selectedTranscriptLineUUID}', function () {
      return this.get('engagement.agentCoach.selectedTranscriptLineUUID') === this.get('uuid');
    }),
    isScriptCommand: Ember.computed(function () {
      return this.get('type') === 'script' && this.src('scriptType') === 'command';
    }),
    isStateChange: Ember.computed.equal('type', 'stateChange'),
    isStatusMessage: Ember.computed.or('isCobrowseEvent', 'isDvv', 'isStateStatus'),
    isSystemMessage: Ember.computed('sender', function () {
      if (this.get('isConversation')) return false;
      if (this.get('sender') === 'system') return true;
      var type = this.get('type');
      if (['stateChange', 'plugin', 'customerData'].includes(type)) return false;
      return true;
    }),
    isXformDatapass: Ember.computed(function () {
      return this.get('type') === 'xform' || this.get('name') === 'Form';
    }),
    isSecure: Ember.computed.or('isACIFPrechatDatapass', 'isXformDatapass', 'isCustomerData'),
    // Conferenced Agent Messages
    isConferenceAgentMessage: Ember.computed.equal('__data__.member', 'agent'),
    // Cobrowse
    isCobrowse: Ember.computed.equal('type', 'cobrowse'),
    isCobrowseEvent: Ember.computed.and('isCobrowse', '__data__.event'),
    // Chat History (Historic/ Realtime)
    isFromChatHistory: Ember.computed.or('isSourceRealtime', 'isSourceHistoric'),
    isSourceRealtime: Ember.computed.equal('source', 'realtime'),
    isSourceHistoric: Ember.computed.equal('source', 'historic'),
    // Widgets
    isAbcWidget: Ember.computed.bool('messageTextJSON.interactiveData'),
    isRichWidget: Ember.computed.bool('messageDataJSON.richWidget'),
    // State Statuses
    isStateCustomerClosedEngagement: Ember.computed.equal('state', 'customerClosedEngagement'),
    isStateCustomerLostConnection: Ember.computed.equal('state', 'customerLostConnection'),
    isStateCustomerMinimizedChatWindow: Ember.computed.equal('state', 'customerMinimizedChatWindow'),
    isStateCustomerReconnected: Ember.computed.equal('state', 'customerReconnected'),
    isStateCustomerRestoredChatWindow: Ember.computed.equal('state', 'customerRestoredChatWindow'),
    isStateMovedToPersistent: Ember.computed.equal('state', 'movedToPersistent'),
    isStateStartedMoveToPersistent: Ember.computed.equal('state', 'startedMoveToPersistent'),
    isStateStatus: Ember.computed.or('isStateCustomerClosedEngagement', 'isStateCustomerLostConnection', 'isStateCustomerMinimizedChatWindow', 'isStateCustomerRestoredChatWindow', 'isStateStartedMoveToPersistent', 'isStateMovedToPersistent', 'isStateCustomerReconnected'),
    isDvv: Ember.computed.equal('type', 'dvv'),
    formattedTimestamp: Ember.computed('timestamp', function () {
      var timestamp = this.timestamp;
      var isTimestampString = typeof timestamp === "string";
      timestamp = isTimestampString && (0, _moment.default)(timestamp).isValid() ? (0, _moment.default)(timestamp) : Number(timestamp);
      return (0, _moment.default)(timestamp).format("HH:mm:ss");
    }),
    prefix: Ember.computed('engagement.settings.agentAlias', function () {
      var type = this.get('type');
      if (type === 'dvv') return 'DVV';
      var senderName = this.src('senderName');
      if (senderName) return senderName;
      var prefix = this.src('prefix');
      if (prefix === 'chat.agentFollowUp') {
        prefix = "".concat(this.src('sender'), "'s Follow Up Notes");
      }
      if (prefix) return prefix;
      var sender = this.src('sender');
      var member = this.src('member');
      var alias = this.src('agentAlias');
      if (this.get('isScriptCommand')) {
        return 'Command';
      } else if (this.get('isSAMLDatapass')) {
        return 'SAML Datapass';
      } else if (sender === 'agent' || type === 'opener') {
        return this.get('engagement.settings.agentAlias') || this.src('name') || this.get('agentId') || 'Agent';
      } else if (member === 'agent' || member === 'supervisor') {
        prefix = this.src('userId') || 'Member';
        return alias ? "".concat(alias, " (").concat(prefix, ")") : prefix;
      } else if (type === 'chatLine') {
        prefix = this.get('engagement.customer.name');
        return prefix ? (0, _parseHtml.encodeEntities)(prefix) : prefix;
      } else if (type === 'customerData') {
        prefix = this.src('name');
      } else if (type === 'stateChange') {
        prefix = this.src('name');
      }

      // some message types like conference, note, transfer, or virtual assistant messages do not use a prefix
      var typesWithNoPrefix = ['agentJoinsChat', 'conference', 'customerData', 'note', 'transfer'];
      if (this.get('engagement.api.agent.features.transcriptV3')) {
        typesWithNoPrefix.push('cobrowse', 'stateChange', 'system');
      }
      var useDefaultPrefix = !typesWithNoPrefix.includes(type);
      if (useDefaultPrefix) {
        prefix = prefix || type || sender;
      }
      if (this.get('type') === 'transfer-conference-notes') prefix = null;
      var aliases = {
        url: 'URL'
      };
      if (prefix && aliases[prefix]) {
        return aliases[prefix];
      }
      return prefix ? Ember.String.capitalize(prefix) : null;
    }),
    richWidgetDatapass: Ember.computed.reads('messageDataJSON.datapass.displayToAgent'),
    smsStatusMessageParts: Ember.computed('smsStatus', function () {
      return (0, _message.getSMSStatusMessageParts)(this.get('smsStatus'));
    }),
    webRTCEvent: Ember.computed('messageDataJSON', function () {
      var messageDataJSON = this.get('messageDataJSON');
      return messageDataJSON && messageDataJSON.webRTCEvent && messageDataJSON.webRTCEvent.name;
    }),
    agentAttributes: Ember.computed('engagement.otherAgentsAttributes', 'isAgentJoinsEvent', 'senderId', function () {
      if (!this.get('isAgentJoinsEvent') || !this.get('engagement.otherAgentsAttributes')) return;
      return this.get('engagement.otherAgentsAttributes').findBy('agentId', this.get('senderId'));
    }),
    messageAttributes: Ember.computed.reads('agentAttributes'),
    messageAttributesText: Ember.computed('messageAttributes', function () {
      var messageAttributes = this.get('messageAttributes');
      if (!messageAttributes) return;
      return Object.keys(messageAttributes).map(function (attrKey) {
        return "\t".concat(attrKey, ": ").concat(messageAttributes[attrKey]);
      }).join('\n');
    }),
    toJSON: function toJSON() {
      var copy = Ember.assign({}, this);
      delete copy.cheetahRecommendations;
      delete copy.engagement;
      delete copy.plugin;
      return copy;
    }
  });
});