define("sdk/api/utils/parse-saml-datapass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAgentSAMLText;
  _exports.getFormattedSAMLDatapass = getFormattedSAMLDatapass;
  _exports.getSAMLFields = getSAMLFields;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function getSAMLFields() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var hasWhitelist = data.includes('NUANCE_DISPLAY_TO_AGENT_WHITELIST');
    if (!hasWhitelist) return [];
    var fieldValues = {};
    if (data.startsWith('SAML.Datapass: ')) {
      data = data.slice(15); // remove "SAML.Datapass: " from data (found after transfer/ conference)
    }

    data.split('\n').map(function (item) {
      return item.trim();
    }).filter(function (item) {
      return !!item;
    }) // remove null / empty items
    .forEach(function (item) {
      var _item$match = item.match(/([^:]+):(.*)/),
        _item$match2 = _slicedToArray(_item$match, 3),
        match = _item$match2[0],
        key = _item$match2[1],
        value = _item$match2[2]; // eslint-disable-line no-unused-vars
      fieldValues[key] = value;
    });
    var fieldValueKeys = Object.keys(fieldValues).map(function (field) {
      return {
        key: field,
        niceKey: field.replace(/_/g, ' '),
        // replace underscore with space to handle word breaks
        upperCasedKey: field.toUpperCase(),
        value: fieldValues[field]
      };
    });
    var NUANCE_DISPLAY_TO_AGENT_WHITELIST = fieldValues.NUANCE_DISPLAY_TO_AGENT_WHITELIST;
    if (!NUANCE_DISPLAY_TO_AGENT_WHITELIST) return fieldValueKeys;
    var upperCasedWhitelistFields = NUANCE_DISPLAY_TO_AGENT_WHITELIST.split(',').map(function (field) {
      return field && field.toUpperCase();
    });
    return upperCasedWhitelistFields.map(function (upperCasedWhitelistField) {
      return fieldValueKeys.findBy('upperCasedKey', upperCasedWhitelistField);
    }).without(undefined);
  }
  function getAgentSAMLText() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var matchedSAMLFields = getSAMLFields(data);
    if (matchedSAMLFields.length === 0) return data;
    return matchedSAMLFields.map(function (_ref) {
      var key = _ref.key,
        value = _ref.value;
      var fieldNameHtml = "<span class=\"saml-field-name\">".concat(key, ":</span>");
      var fieldValueHtml = "<span class=\"saml-field-value\">".concat(value, "</span>");
      return '<div class="saml-field">' + (value ? "".concat(fieldNameHtml, " ").concat(fieldValueHtml) : "".concat(fieldNameHtml)) + '</div>';
    }).join('');
  }
  function getFormattedSAMLDatapass() {
    var datapass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var startIndex = 0;
    var endIndex = datapass.length;

    // remove "SAML.Datapass" prefix from transfer transcript message
    var prefixIndex = datapass.indexOf('SAML.Datapass: ');
    if (prefixIndex > -1) {
      startIndex = 14;
    }

    // remove whitelist from datapass
    var whitelistIndex = datapass.indexOf('NUANCE_DISPLAY_TO_AGENT_WHITELIST');
    if (whitelistIndex > -1) {
      endIndex = whitelistIndex;
    }
    return datapass.substring(startIndex, endIndex).trim();
  }
});