define("sdk/api/utils/parse-html", ["exports", "sdk/utils/regex", "sdk/api/utils/html-tags", "dompurify"], function (_exports, _regex, _htmlTags, _dompurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decodeEntities = decodeEntities;
  _exports.decodeHtmlEntities = decodeHtmlEntities;
  _exports.encodeCarets = encodeCarets;
  _exports.encodeEntities = encodeEntities;
  _exports.encodeHtml = encodeHtml;
  _exports.encodeHtmlTags = encodeHtmlTags;
  _exports.formatTextToCountLength = formatTextToCountLength;
  _exports.removeHtmlTags = removeHtmlTags;
  _exports.replacePAndBrTagsWithLineBreaks = replacePAndBrTagsWithLineBreaks;
  _exports.restoreHtmlTags = restoreHtmlTags;
  _exports.searchAndReplace = searchAndReplace;
  _exports.searchAndReplaceNonHtml = searchAndReplaceNonHtml;
  _exports.searchAndReplaceNonHtmlByEachWord = searchAndReplaceNonHtmlByEachWord;
  _exports.stashHtmlTags = stashHtmlTags;
  _exports.swapEntityEncoding = swapEntityEncoding;
  _exports.toPlainText = toPlainText;
  _exports.walkElementNodes = walkElementNodes;
  _exports.walkNodes = walkNodes;
  _exports.walkTextNodes = walkTextNodes;
  var parser = new DOMParser();
  var DECODED_ENTITY_MAP = {
    '&': '&amp;',
    '¢': '&cent;',
    '€': '&euro;',
    '>': '&gt;',
    '<': '&lt;'
  };
  var DECODED_ENTITIES = Object.keys(DECODED_ENTITY_MAP);
  var ENCODED_ENTITY_MAP = {
    '&amp;': '&',
    '&cent;': '¢',
    '&euro;': '€',
    '&gt;': '>',
    '&lt;': '<',
    '&quot;': '"'
  };
  var ENCODED_ENTITIES = Object.keys(ENCODED_ENTITY_MAP);
  function isEncodedEntity() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return ENCODED_ENTITIES.includes(text);
  }
  function getHtmlTagsRegex() {
    var tags = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var tagRegexString = tags.length ? '(?:' + tags.join('|') + ')' : '\\w+';
    return new RegExp("<\\/?".concat(tagRegexString, "[^>]*>"), 'g');
  }

  /**
   * Removes html tags from passed in text
   * @method removeHtmlTags
   * @param {String} text string with or without html
   * @return {String} the string without html tags
   */
  function removeHtmlTags(text) {
    return text.replace(getHtmlTagsRegex(), '');
  }

  /**
   * Searches text ignoring any html and replaces any word matches with replaceRegex
   * @method searchAndReplaceNonHtmlByEachWord
   * @param {String} text string with or without html
   * @param {String} searchText text contains words to be searched and replaced by replaceRegex
   * @param {Regex} replaceRegex regex to be executed on match
   * @return {String} the full string with replaced matching text
   */
  function searchAndReplaceNonHtmlByEachWord(text) {
    var searchText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var replaceRegex = arguments.length > 2 ? arguments[2] : undefined;
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var _options$maxWholeWord = options.maxWholeWordMatchCharLength,
      maxWholeWordMatchCharLength = _options$maxWholeWord === void 0 ? 0 : _options$maxWholeWord;
    var encodedSearchText = encodeEntities(searchText);

    // sort words by largest first
    var searchWords = encodedSearchText.trim().split(/\s+/).sort(function (wordA, wordB) {
      return wordB.length - wordA.length;
    });
    return searchWords.reduce(function (accumulator, searchWord) {
      var searchOptions = {};
      if (searchWord.length <= maxWholeWordMatchCharLength) {
        searchOptions.wholeWordMatch = true;
      }
      return searchAndReplaceNonHtml(accumulator, searchWord, replaceRegex, searchOptions);
    }, text);
  }

  /**
   * Searches text ignoring any html and replaces all matches found
   * @method searchAndReplaceNonHtml
   * @param {String} text string with or without html
   * @param {String} searchString string to search for
   * @param {Regex} replaceRegex regex to be executed on match
   * @return {String} the full string with replaced matching text
   */
  function searchAndReplaceNonHtml(text, searchString, replaceRegex) {
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    if (!text) text = '';
    var stashedHtml = stashHtmlTags(text);
    if (options.includeDetails) {
      var _searchAndReplace = searchAndReplace(stashedHtml, searchString, replaceRegex, options),
        replacedText = _searchAndReplace.replacedText,
        replaceCount = _searchAndReplace.replaceCount;
      return {
        originalText: text,
        replacedText: restoreHtmlTags(replacedText, text),
        replaceCount: replaceCount
      };
    } else {
      var matchedStashedHtml = searchAndReplace(stashedHtml, searchString, replaceRegex, options);
      return restoreHtmlTags(matchedStashedHtml, text);
    }
  }

  /**
   * Searches text and replaces all matches found
   * @method searchAndReplace
   * @param {String} text string with or without html
   * @param {String} searchString string search for
   * @param {Regex} replaceRegex regex to be executed on match
   * @return {String} text with replaced matches
   */
  function searchAndReplace() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var searchString = arguments.length > 1 ? arguments[1] : undefined;
    var replaceRegex = arguments.length > 2 ? arguments[2] : undefined;
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    var _options$encoded = options.encoded,
      encoded = _options$encoded === void 0 ? false : _options$encoded,
      _options$includeDetai = options.includeDetails,
      includeDetails = _options$includeDetai === void 0 ? false : _options$includeDetai,
      _options$wholeWordMat = options.wholeWordMatch,
      wholeWordMatch = _options$wholeWordMat === void 0 ? false : _options$wholeWordMat;
    var escapedString = (0, _regex.escapeRegex)(searchString);
    var searchRegex = wholeWordMatch ? new RegExp("({{\\d+,\\d+}})|(?:^|[\\s.?!,;:[\\]()\"])(".concat(escapedString, ")(?:$|[\\s.?!,;:[\\]()\"])"), 'gi') : new RegExp("({{\\d+,\\d+}})|(".concat(escapedString, ")"), 'gi');
    if (encoded) {
      text = decodeEntities(text);
      replaceRegex = encodeCarets(replaceRegex);
    }
    var replaceCount = 0;
    var matchedText = text.replace(searchRegex, function (match, c1, c2) {
      var delimeterRegex = /{{\d+,\d+}}/g;
      var shouldIgnore = delimeterRegex.test(match);
      if (shouldIgnore) return match;
      replaceCount++;
      return match.replace(c2, replaceRegex);
    });
    var replacedText = encoded ? swapEntityEncoding(matchedText) : matchedText;
    return includeDetails ? {
      originalText: text,
      replacedText: replacedText,
      replaceCount: replaceCount
    } : replacedText;
  }

  /**
   * Decodes &, <, and > HTML entities from
   * a given string.
   * @function decodeHtmlEntities
   * @param {String} text string with or without html entities
   * @return {String} text with &, <, and > decoded
   */
  function decodeHtmlEntities(text) {
    return text.replace(/&amp;(amp;)?/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  }
  function encodeHtmlTags() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _options$whitelist = options.whitelist,
      whitelist = _options$whitelist === void 0 ? [] : _options$whitelist,
      _options$tags = options.tags,
      tags = _options$tags === void 0 ? [] : _options$tags;
    var htmlTagsRegex = getHtmlTagsRegex(tags);
    return text.replace(htmlTagsRegex, function (match) {
      var tagNameMatch = match.match(/(?:^<|<\/)(\w+)/);
      var tagName = tagNameMatch ? tagNameMatch[1] : undefined;
      if (whitelist.includes(tagName)) return match;
      return encodeCarets(match);
    });
  }

  /**
   * Replaces html tags with a special delimeter so text that contains html can
   * be used as plain text
   * @method stashHtmlTags
   * @param {String} text string with or without html tags
   * @return {String} text with all html spans replaced by a delimeter
   */
  function stashHtmlTags(text) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var whitelist = options.whitelist;
    return text.replace(getHtmlTagsRegex(whitelist), function (match, index) {
      return "{{".concat(index, ",").concat(index + match.length, "}}");
    });
  }

  /**
   * Restores original html tags into stashedHtml text (returned from stashHtmlTags)
   * @method restoreHtmlTags
   * @param {String} stashedHtml text returned from stashHtmlTags
   * @param {String} originalHtml text with html (value passed into stashHtmlTags)
   * @return {String} text with html restored
   */
  function restoreHtmlTags(stashedHtml, originalHtml) {
    var delimeterRegex = /{{(\d+),(\d+)}}/g;
    return stashedHtml.replace(delimeterRegex, function (match, tagIndexStart, tagIndexEnd) {
      return originalHtml.substring(tagIndexStart, tagIndexEnd);
    });
  }

  /**
   * Encodes characters "<", ">", "&" that are not part of an html tag
   * @method encodeHtml
   * @param {String} string text to encode
   * @param {Array} options.allowedTags list of allowed html tags
   */

  function encodeHtml() {
    var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _options$allowedTags = options.allowedTags,
      allowedTags = _options$allowedTags === void 0 ? _htmlTags.default : _options$allowedTags;
    if (!allowedTags.length) throw new Error('allowedTags cannot be empty');
    var regex = new RegExp("<(?!\\/?(".concat(allowedTags.join('|'), ")\\W)"), 'gi');
    var replacedHtml = string.replace(regex, '&lt;');
    return walkTextNodes(replacedHtml);
  }
  function toPlainText() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    text = text.replace(/<\/?(p|div|br)\/?>/g, '\n'); // replace block tags with newlines
    text = text.replace(/\n{2}/g, '\n'); // replace redundant newlines
    text = encodeHtml(text, {
      allowedTags: _htmlTags.default
    });
    text = _dompurify.default.sanitize(text);
    return Ember.$("<p>".concat(text, "</p>")).text().trim();
  }
  function decodeEntities() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var whitelist = options.whitelist;
    var mapKeys = whitelist || ENCODED_ENTITIES;
    var entityRegex = new RegExp("".concat(mapKeys.join('|')), 'g');
    return text.replace(entityRegex, function (match) {
      return ENCODED_ENTITY_MAP[match];
    });
  }
  function encodeEntities(string) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var whitelist = options.whitelist;
    whitelist = whitelist || DECODED_ENTITIES;

    // Regex containing both encoded entities (&amp;, &lt;, &gt;) and decoded entities (&, <, >)
    var entityRegex = new RegExp("".concat(ENCODED_ENTITIES.join('|'), "|").concat(whitelist.join('|')), 'g');
    return ('' + string).replace(entityRegex, function (match) {
      // If match is already encoded, no need to encode again
      if (isEncodedEntity(match)) return match;
      return DECODED_ENTITY_MAP[match];
    });
  }

  /**
   * Swaps decoded entities with encoded entities and encoded entities with decoded entities
   * @method swapEntityEncoding
   * @param {String} text
   */
  function swapEntityEncoding() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var whitelist = ENCODED_ENTITIES.concat(DECODED_ENTITIES);
    var entities = Object.assign(DECODED_ENTITY_MAP, ENCODED_ENTITY_MAP);
    var entityRegex = new RegExp("(".concat(whitelist.join('|'), ")"), 'g');
    return text.replace(entityRegex, function (match) {
      return entities[match];
    });
  }

  // Escape a string for HTML interpolation
  function encodeCarets(string) {
    return encodeEntities(string, {
      whitelist: ['<', '>']
    });
  }
  function replacePAndBrTagsWithLineBreaks(text) {
    // workaround for FF/IE where quill doesn't modify its starting content
    var brTagRegexp = /<p><br\s*\/?><\/p>/g;
    text = text.replace(brTagRegexp, '<p></p>');
    text = text.replace(/(<\/p><p>)|(<p><\/p>)/g, '\n').replace(/<\/?(p)\/?>/g, ''); // replace block tags with newlines
    return text;
  }
  function formatTextToCountLength(text) {
    text = replacePAndBrTagsWithLineBreaks(text);
    text = text.replace(/<\/?(p|div|br)\/?>/g, '\n'); // replace block tags with newlines
    return Ember.$("<p>".concat(text, "</p>")).text();
  }
  function walkTextNodes() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var textNodeFunction = arguments.length > 1 ? arguments[1] : undefined;
    return walkNodes(text, textNodeFunction, NodeFilter.SHOW_TEXT);
  }
  function walkElementNodes() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var elementNodeFunction = arguments.length > 1 ? arguments[1] : undefined;
    return walkNodes(text, elementNodeFunction, NodeFilter.SHOW_ELEMENT);
  }
  function walkNodes() {
    var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    var nodeFunction = arguments.length > 1 ? arguments[1] : undefined;
    var whatToShow = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : NodeFilter.SHOW_ALL;
    // <html>, <body>, <head> tags are moved outside of parsedHtml.body when parsed
    // stash these tags as text nodes to preserve order
    var stashedText = stashHtmlTags(text, {
      whitelist: ['html', 'head', 'body']
    });
    var parsedHtml = parser.parseFromString(stashedText, 'text/html');
    var treeWalker = parsedHtml.createTreeWalker(parsedHtml, whatToShow, function () {
      return NodeFilter.FILTER_ACCEPT;
    }, false);
    var node = treeWalker.nextNode();
    while (node) {
      var newNode = nodeFunction && nodeFunction(node);
      // if a node is replaced in existing tree, treeWalker's currentNode needs to be updated
      if (newNode) treeWalker.currentNode = newNode;
      node = treeWalker.nextNode();
    }
    var domInnerHtml = parsedHtml.body && parsedHtml.body.innerHTML || '';
    return restoreHtmlTags(domInnerHtml, text);
  }
});