define("sdk/components/static-transcript-window/search/component", ["exports", "sdk/core/component", "sdk/components/static-transcript-window/search/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'static-transcript-window-search',
    actions: {
      onKeyUp: function onKeyUp(filter, event) {
        if (filter && event.key === 'Enter') {
          this.send(event.shiftKey ? 'prev' : 'next');
        }
      },
      next: function next() {
        var filter = (Ember.get(this, 'filter') || '').trim();
        if (!filter) return;
        var numberOfMatches = this.get('numberOfMatches');
        if (!numberOfMatches) return;
        if (this.get('activeMatch') + 1 <= numberOfMatches) {
          this.incrementProperty('activeMatch');
        } else {
          this.set('activeMatch', 1);
        }
        this.selectActiveMatch();
      },
      prev: function prev() {
        var numberOfMatches = this.get('numberOfMatches');
        var newActiveMatchIndex = this.get('activeMatch') - 1;
        if (!numberOfMatches) return;
        if (newActiveMatchIndex === 0) {
          this.set('activeMatch', numberOfMatches);
        } else if (numberOfMatches >= newActiveMatchIndex) {
          this.decrementProperty('activeMatch');
        }
        this.selectActiveMatch();
      },
      clearFilter: function clearFilter() {
        var _this = this;
        this.set('filter', null);
        Ember.run.scheduleOnce('afterRender', function () {
          _this.$('input').focus();
        });
      }
    }
  });
});