define("sdk/components/x-tabs/tab/component", ["exports", "sdk/core/component", "sdk/components/x-tabs/tab/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'tab',
    role: 'tab',
    classNames: ['tab'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['aria-selected', 'role', 'tabindex'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (!this.get('hasTabIndex')) {
        this.set('tabindex', false);
      }
    },
    isActive: Ember.computed('selectedTabIndex', 'index', function () {
      return this.get('selectedTabIndex') === this.get('index');
    }),
    tabindex: Ember.computed('isActive', function () {
      return this.get('isActive') ? '0' : '-1';
    }),
    'aria-selected': Ember.computed('isActive', function () {
      return "".concat(this.get('isActive'));
    }),
    click: function click(event) {
      var onSelect = this.get('onSelect');
      onSelect && onSelect(this.get('index'), event.target);
    }
  });
});