define("sdk/utils/array/batch-search", ["exports", "sdk/utils/array/batch", "fuse"], function (_exports, _batch, _fuse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _batch.default.extend({
    // Demo: https://fusejs.io/
    FUSE_SEARCH_OPTIONS: Object.freeze({
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.1,
      distance: 1000,
      maxPatternLength: 32,
      minMatchCharLength: 3
    }),
    createFilteredBatch: function createFilteredBatch() {
      var filterText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (filterText === '') return this.createBatch();
      this.set('_currentBatchItemIndex', 0);
      var filteredBatchItems = this.addNextFilteredBatch(filterText);
      this.set('batchItems', filteredBatchItems);
      return filteredBatchItems;
    },
    addNextFilteredBatch: function addNextFilteredBatch() {
      var filterText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      if (filterText === '') return this.addNextBatch();
      var currentBatchItemIndex = this.get('_currentBatchItemIndex');
      var contentCount = this.get('content.length') - 1;
      var filteredBatchedItems = [];
      while (currentBatchItemIndex <= contentCount && filteredBatchedItems.length < this.get('BATCH_SIZE')) {
        var batchToSearch = this._getBatch(currentBatchItemIndex);
        if (batchToSearch.length > 0) {
          var searchBatchedItems = this._searchBatch(batchToSearch, filterText);
          filteredBatchedItems.pushObjects(searchBatchedItems);
          currentBatchItemIndex += batchToSearch.length;
        }
      }
      this.set('_currentBatchItemIndex', currentBatchItemIndex);
      this.get('batchItems').pushObjects(filteredBatchedItems);
      return filteredBatchedItems;
    },
    filterCurrentBatch: function filterCurrentBatch() {
      var filterText = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var BATCH_SIZE = this.get('BATCH_SIZE');
      var batchItemIndex = 0;
      var batchItems = this.get('batchItems');
      var batchItemsCount = batchItems.length - 1;
      var filteredRenderedBatchedItems = [];
      while (batchItemIndex <= batchItemsCount && filteredRenderedBatchedItems.length < BATCH_SIZE) {
        var batchToSearch = batchItems.slice(batchItemIndex, batchItemIndex + BATCH_SIZE);
        if (batchToSearch.length >= 0) {
          if (filterText === '') {
            filteredRenderedBatchedItems.pushObjects(batchToSearch);
          } else {
            var searchBatchedItems = this._searchBatch(batchToSearch, filterText);
            filteredRenderedBatchedItems.pushObjects(searchBatchedItems);
          }
          batchItemIndex += batchToSearch.length;
        }
      }
      var currentBatchItemIndex = this.get('content').indexOf(filteredRenderedBatchedItems.get('lastObject')) + 1;
      this.set('_currentBatchItemIndex', currentBatchItemIndex);
      var filteredBatchedItems = filteredRenderedBatchedItems;
      if (filteredRenderedBatchedItems.length < BATCH_SIZE) {
        filteredBatchedItems.pushObjects(this.addNextFilteredBatch(filterText));
      }
      this.set('batchItems', filteredBatchedItems);
      return filteredBatchedItems;
    },
    _searchBatch: function _searchBatch() {
      var batchItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var searchText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var fuse = new _fuse.default(batchItems, this.get('FUSE_SEARCH_OPTIONS'));
      return fuse.search(searchText).mapBy('item');
    }
  });
});