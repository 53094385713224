define("sdk/components/static-transcript-window/history/selected-item/component", ["exports", "sdk/core/component", "sdk/components/static-transcript-window/history/selected-item/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'static-history-selected-item'
  });
});