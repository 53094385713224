define("sdk/components/escalation-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1PQ7TooI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"label\"],[10,\"for\",\"escalate-form-description\"],[10,\"data-test-description\",\"\"],[8],[1,[26,\"t\",[\"escalation-form.description\"],null],false],[9],[0,\"\\n\"],[1,[26,\"text-field-multiline\",null,[[\"id\",\"data-test-notes\",\"value\"],[\"escalate-form-description\",\"\",[22,[\"notes\"]]]]],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"button-container\"],[8],[0,\"\\n\\n  \"],[6,\"button\"],[10,\"class\",\"cancel-button\"],[10,\"data-test-cancel\",\"\"],[3,\"action\",[[21,0,[]],\"cancel\"]],[8],[1,[26,\"t\",[\"escalation-form.cancel\"],null],false],[9],[0,\"\\n  \"],[6,\"button\"],[10,\"class\",\"submit-button\"],[11,\"disabled\",[22,[\"engagement\",\"isLocked\"]],null],[10,\"data-test-submit\",\"\"],[3,\"action\",[[21,0,[]],\"submit\"]],[8],[1,[26,\"t\",[\"escalation-form.submit\"],null],false],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/escalation-form/template.hbs"
    }
  });
});