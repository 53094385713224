define("sdk/components/agent-settings/component", ["exports", "sdk/components/agent-settings/template", "sdk/core/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    engagementLayoutManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    enableAudioAlerts: Ember.computed.alias('api.settings.enableAudibleAlert'),
    enableVisualAlerts: Ember.computed('api.settings.visualIndicatorFlashing', function () {
      return this.get('api.settings.visualIndicatorFlashing') !== 'NONE';
    }),
    isLoginAsBusyEnabled: Ember.computed.equal('api.settings.loginStatus', 'custom'),
    showSettingsObserver: Ember.observer('showSettings', function () {
      if (!this.get('showSettings')) return;
      this._clearLayoutSaveMessages();
      this.set('hasModifiedLayout', this.get('engagementLayoutManager').hasModifiedLayout(this.get('currentEngagement')));
    }),
    /**
     * Refreshes the selected language and selected font size with their
     * newly translated strings upon changing the application's locale.
     */
    refreshDropdownsWithTranslations: Ember.observer('intl.locale', function () {
      var _this = this;
      Ember.run.scheduleOnce('afterRender', function () {
        var languages = _this.get('languages');
        var fontSizes = _this.get('fontSizes');
        var layouts = _this.get('layouts');
        var newLanguage = languages.findBy('value', _this.get('selectedLanguage.value'));
        _this.set('selectedLanguage', newLanguage);
        var newFontSize = fontSizes.findBy('value', _this.get('selectedFontSize.value'));
        _this.set('selectedFontSize', newFontSize);
        var newLayout = layouts.findBy('value', _this.get('selectedLayout.value'));
        _this.set('selectedLayout', newLayout);
      });
    }),
    layouts: Ember.computed('intl.locale', 'hasCustomLayout', function () {
      var layouts = [{
        label: this.get('intl').t('agent-settings.layouts.chat-left'),
        value: 'chat-left'
      }, {
        label: this.get('intl').t('agent-settings.layouts.chat-center'),
        value: 'chat-center'
      }, {
        label: this.get('intl').t('agent-settings.layouts.chat-right'),
        value: 'chat-right'
      }, {
        label: this.get('intl').t('agent-settings.layouts.chat-left-two-columns'),
        value: 'chat-left-two-columns'
      }];
      if (this.get('hasCustomLayout')) {
        layouts.pushObject({
          label: this.get('intl').t('agent-settings.layouts.custom'),
          value: 'custom'
        });
      }
      return layouts;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var _this$get = this.get('api.agent.settings'),
        language = _this$get.language,
        fontSize = _this$get.fontSize;
      var selectedLanguage = this.get('languages').findBy('value', language);
      var selectedFontSize = this.get('fontSizes').findBy('value', fontSize);
      this.set('hasCustomLayout', this.get('engagementLayoutManager').hasCustomLayout());
      var layoutName = this.get('api.agent.settings.layout');
      var selectedLayout = this.get('layouts').findBy('value', layoutName);
      this.setProperties({
        selectedLanguage: selectedLanguage,
        selectedFontSize: selectedFontSize,
        selectedLayout: selectedLayout
      });
    },
    actions: {
      updateSettingsProperty: function updateSettingsProperty(key, selected) {
        if (key === 'language') {
          this._setSelectedLanguage(selected);
        } else if (key === 'fontSize') {
          this.get('api').trigger('fontSizeChange', selected);
          this.set('selectedFontSize', selected);
        }
        this.get('api.agent').updateSetting(key, selected);
      },
      /**
       * @method updateLayoutSettings
       * @param {Object} selectedLayout
       * @param {String} selectedLayout.label string displayed as option in layout dropdown
       * @param {String} selectedLayout.value hyphenized string of the layout label
       */
      updateLayoutSettings: function updateLayoutSettings(selectedLayout) {
        var _this2 = this;
        var layoutName = selectedLayout.value;
        this._setSelectedLayout(layoutName);
        this._clearLayoutSaveMessages();
        this.set('hasModifiedLayout', false);
        return this.get('engagementLayoutManager').saveLayout(layoutName, this.get('currentEngagement')).then(function () {
          _this2.set('initialFocusSelector', '.layout-dropdown button');
        });
      },
      updateHideChatSetting: function updateHideChatSetting(hideChat) {
        return this.get('api.agent').updateLayoutSetting('hideDetailsByDefault', hideChat);
      },
      updateCtrlEnterToSubmitSetting: function updateCtrlEnterToSubmitSetting(ctrlEnterToSubmit) {
        return this.get('api.agent').updateLayoutSetting('ctrlEnterToSubmit', ctrlEnterToSubmit);
      },
      updateScriptHeadingsSetting: function updateScriptHeadingsSetting(collapseScriptHeadings) {
        return this.get('api.agent').updateLayoutSetting('collapseScriptHeadingsByDefault', collapseScriptHeadings);
      },
      updateFreezeChatTabPositionSetting: function updateFreezeChatTabPositionSetting(freezeChatTabPosition) {
        if (!freezeChatTabPosition) {
          // clearing positionedEngagementIds when user disables setting
          // if agent re-enables setting it will be from clean state
          this.get('api.agent.ui.positionedEngagementIds').clear();
        }
        this.get('api.agent').updateLayoutSetting('freezeChatTabPositionByDefault', freezeChatTabPosition);
      },
      createCustomLayout: function createCustomLayout() {
        var _this3 = this;
        this.set('hasCustomLayout', true);
        this._setSelectedLayout('custom');
        return this.get('engagementLayoutManager').createCustomLayout(this.get('currentEngagement')).then(function () {
          _this3.set('savedNewCustomLayout', true);
        });
      },
      updateCustomLayout: function updateCustomLayout() {
        var _this4 = this;
        this._setSelectedLayout('custom');
        return this.get('engagementLayoutManager').updateCustomLayout(this.get('currentEngagement')).then(function () {
          _this4.set('savedUpdatedCustomLayout', true);
        });
      }
    },
    _setSelectedLanguage: function _setSelectedLanguage(selected) {
      this.set('selectedLanguage', selected);
      this.set('intl.locale', selected.intlValue);
      this.get('onUpdateLangAttribute')(selected.intlValue);

      // addresses screen reader issue where the reader reads
      // irrelevant text when the language changes
      this.$('#my-settings-modal').attr('lang', selected.intlValue);
      var layoutName = this.get('api.agent.settings.layout');
      var selectedLayout = this.get('layouts').findBy('value', layoutName);
      this.set('selectedLayout', selectedLayout);
    },
    _setSelectedLayout: function _setSelectedLayout(layoutName) {
      this.set('selectedLayout', this.get('layouts').findBy('value', layoutName));
    },
    _clearLayoutSaveMessages: function _clearLayoutSaveMessages() {
      this.set('initialFocusSelector', null);
      this.set('savedNewCustomLayout', false);
      this.set('savedUpdatedCustomLayout', false);
    }
  });
});