define("sdk/components/menu-select/trigger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gRy2tfrn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,[\"selectedItemComponent\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[22,[\"selectedItemComponent\"]]],[[\"option\"],[[26,\"readonly\",[[22,[\"selected\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",\"menu-select-selected-option\"],[10,\"data-test-menu-selected-option\",\"\"],[8],[0,\"\\n\"],[4,\"if\",[[26,\"eq\",[[22,[\"selected\"]],\"Summary\"],null]],null,{\"statements\":[[0,\"      \"],[1,[26,\"t\",[\"header.summary\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[13,1,[[22,[\"selected\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[22,[\"hasMenuArrow\"]]],null,{\"statements\":[[0,\"  \"],[6,\"span\"],[10,\"class\",\"menu-select-status-icon\"],[10,\"data-test-menu-select-status-icon\",\"\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/menu-select/trigger/template.hbs"
    }
  });
});