define("sdk/api/adapters/dcapi-adapter", ["exports", "sdk/api/adapters/api-adapter"], function (_exports, _apiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class DCAPIAdapter
   * @public
   */
  var _default = _exports.default = _apiAdapter.default.extend({
    name: 'DCAPI',
    init: function init() {
      this._super.apply(this, arguments);
      this.BASE_URL = this.api.urls.DATA_COLLECTION_API;
    },
    /**
     * @method sendEventLog
     * @param {Object} options
     * @param {Object} queryParams
     * @param {String} queryParams.domain - Values: agent, async, automaton, chat, conversation, customer, engagement, queue, supervisor
     * @param {String} queryParams.event - the event to send to the server
     * @public
     */
    sendEventLog: function sendEventLog(options, queryParams) {
      return this.ajax({
        url: 'logEvent',
        method: 'post',
        data: options,
        query: queryParams
      });
    }
  });
});