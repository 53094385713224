define("sdk/components/child-modal/modal-header/component", ["exports", "sdk/core/component", "sdk/components/child-modal/modal-header/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _template.default,
    componentName: 'modal-header',
    attributeBindings: ['data-focus-index'],
    'data-focus-index': 2,
    actions: {
      onClickCloseButton: function onClickCloseButton() {
        this.get('onClose')();
      }
    }
  });
});