define("sdk/components/nuance-home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t0L52eYW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[26,\"react-WarningBanner\",null,[[\"displayBanner\",\"warning\",\"class\"],[[22,[\"isIE\"]],[26,\"to-string\",[[26,\"t\",[\"header.ie-warning\"],null]],null],\"home__header\"]]],false],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"home__body\"],[8],[0,\"\\n\\n  \"],[6,\"img\"],[10,\"class\",\"home__nuance-image\"],[10,\"src\",\"assets/images/logo.png\"],[10,\"alt\",\"Nuance logo\"],[8],[9],[0,\"\\n\\n  \"],[6,\"h1\"],[10,\"class\",\"home__title\"],[10,\"data-test-login-title\",\"\"],[8],[0,\"Agent Desktop\"],[9],[0,\"\\n\"],[4,\"if\",[[22,[\"isDevelopmentEnvironment\"]]],null,{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"home__development-banner\"],[10,\"data-test-developer-env-banner\",\"\"],[8],[0,\"Development Environment\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"home__content\"],[10,\"role\",\"main\"],[10,\"data-test-login-content\",\"\"],[8],[0,\"\\n\\n    \"],[13,1],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"home__footer\"],[8],[0,\"\\n\\n  \"],[1,[26,\"react-VersionBadge\",null,[[\"requireEnvConfig\"],[true]]],false],[0,\"\\n\\n  \"],[6,\"div\"],[8],[0,\"© \"],[1,[20,\"currentYear\"],false],[0,\" Nuance Communications, Inc. All rights reserved.\"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/components/nuance-home/template.hbs"
    }
  });
});