define("sdk/components/transcript-window-internal/component", ["exports", "sdk/components/transcript-window/component", "sdk/api/utils/runloop"], function (_exports, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.api.on('messageAdded', this, this._debounceScrollToBottom);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this._runDebounceScrollToBottom);
      this.api.off('messageAdded', this, this._debounceScrollToBottom);
    },
    _debounceScrollToBottom: function _debounceScrollToBottom(engagement, _, line) {
      if (engagement && engagement.get('id') !== this.get('engagement.id')) return;
      var shouldScrollToBottom = !line || line.isInternalSystem || line.isInternal;
      if (!shouldScrollToBottom) {
        this.set('scrollToBottomIncrement', 0);
        return;
      }
      this._runDebounceScrollToBottom = (0, _runloop.debounce)(this, this.incrementProperty, 'scrollToBottomIncrement', 100);
    },
    /**
     * Read only the new internal messages if internal chat is not minimized.
     * @method _readNewMessages
     * @private
     */
    _readNewMessages: function _readNewMessages() {
      var _this = this;
      if (this.get('isMinimized')) return;
      var messages = this.getWithDefault('messages', []);
      var lastReadInternalChatMessageIndex = this.getWithDefault('engagement.lastReadInternalChatMessageIndex', 0);
      messages.slice(lastReadInternalChatMessageIndex).forEach(function (message) {
        _this._batchA11ySpeakMessage(message, 'Internal');
      });
      this.set('engagement.lastReadInternalChatMessageIndex', messages.length);
    },
    /**
     * Internal chat should not read system messages. All system messages are
     * duplicated in the active-transcript-window.
     * @method _readNewSystemMessages
     * @private
     */
    _readNewSystemMessages: function _readNewSystemMessages() {},
    actions: {
      focusAgentInput: function focusAgentInput() {
        this.get('api').trigger('focusInternalChatInput');
      }
    }
  });
});