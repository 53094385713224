define("sdk/components/device-settings/component", ["exports", "sdk/components/device-settings/template", "sdk/core/component", "sdk/api/utils/media-devices", "sdk/utils/user-agent-parser"], function (_exports, _template, _component, _mediaDevices, _userAgentParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    devices: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    devicesAudio: Ember.computed.alias('api.agent.devicesAudio'),
    devicesMic: Ember.computed.alias('api.agent.devicesMic'),
    devicesVideo: Ember.computed.alias('api.agent.devicesVideo'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var _parseUserAgent = (0, _userAgentParser.default)(navigator.userAgent),
        browser = _parseUserAgent.browser;
      this.set('isFirefox', browser.includes('Firefox'));
      this.updateVideoStream();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      ['Audio', 'Mic', 'Video'].forEach(function (key) {
        if (_this.get('devices' + key)) {
          var selectedByDefault = _this.get('api.agent.selected' + key + 'Device');
          var selectedDeviceLabel = selectedByDefault && selectedByDefault.label;
          var selectedDevice = selectedDeviceLabel && _this.get('devices' + key).findBy('label', selectedDeviceLabel);
          _this.set('api.agent.selected' + key + 'Device', selectedDevice);
        }
      });
      this.set('deviceSettingsLastFocusSelector', document.activeElement && document.activeElement.classList.contains('device-settings-icon') ? '.device-settings-icon' : '#settings-menu');
    },
    updateVideoStream: function updateVideoStream() {
      var _this2 = this;
      this.get('devices').checkoutVideoDevice().then(function (stream) {
        if (_this2.get('isClosing')) {
          (0, _mediaDevices.stopStream)(stream);
        }
        _this2.setVideoStream(stream);
        _this2.get('api').trigger('devicesSetup');
      }).catch(function () {
        _this2.setVideoStream(null);
        console.log(_this2.get('intl').t('device-settings.video-not-available')); // eslint-disable-line no-console
      });
    },
    setVideoStream: function setVideoStream(value) {
      if (this.get('isDestroying')) return;
      this.set('videoStream', value);
    },
    actions: {
      updateDeviceSettingsProperty: function updateDeviceSettingsProperty(key, selectedDevice) {
        if (!selectedDevice) return;
        this.get('api.localStorage').setItem(key, selectedDevice.deviceId);
        var currentEngagement = this.get('currentEngagement');
        if (key === 'audioDeviceId') {
          this.set('api.agent.selectedAudioDevice', selectedDevice);
        } else if (key === 'micDeviceId') {
          this.set('api.agent.selectedMicDevice', selectedDevice);
          this._renegotiateOffer(currentEngagement, selectedDevice);
        } else if (key === 'videoDeviceId') {
          this.set('api.agent.selectedVideoDevice', selectedDevice);
          this.updateVideoStream();
          if (currentEngagement && currentEngagement.isVideoChat) {
            this._renegotiateOffer(currentEngagement, selectedDevice);
          }
        }
      },
      close: function close() {
        this.set('isClosing', true);
        var stream = this.get('videoStream');
        (0, _mediaDevices.stopStream)(stream);
        this.onClose();
      }
    },
    _renegotiateOffer: function _renegotiateOffer(currentEngagement, selectedDevice) {
      if (currentEngagement && currentEngagement.webRTC) {
        var media = {
          video: {
            deviceId: {
              exact: selectedDevice.kind === 'videoinput' ? selectedDevice.deviceId : this.get('api.agent.selectedVideoDevice.deviceId')
            }
          },
          replaceVideo: selectedDevice.kind === 'videoinput' ? true : false,
          audio: {
            deviceId: {
              exact: selectedDevice.kind === 'audioinput' ? selectedDevice.deviceId : this.get('api.agent.selectedMicDevice.deviceId')
            }
          },
          replaceAudio: selectedDevice.kind === 'audioinput' ? true : false,
          data: true,
          update: true
        };
        currentEngagement.webRTC.renegotiateOffer(media);
      }
    }
  });
});