define("sdk/components/transcript-window/transcript-line/context-menu", ["exports", "ember-context-menu", "sdk/mixins/text-selection", "sdk/api/utils/copy-to-clipboard", "sdk/utils/mask-text"], function (_exports, _emberContextMenu, _textSelection, _copyToClipboard, _maskText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create(_emberContextMenu.default, _textSelection.default, {
    intl: Ember.inject.service(),
    contextItems: Ember.computed('selection', function () {
      var _this = this;
      if (!this.get('enableContextMenu')) return;
      var _this$getSelectedData = this.getSelectedData('.line-text'),
        selectedText = _this$getSelectedData.selectedText,
        index = _this$getSelectedData.index,
        selectedLength = _this$getSelectedData.selectedLength;
      var contextItemsArray = [{
        label: this.get('intl').t('active-engagement.copy'),
        action: function action() {
          (0, _copyToClipboard.default)(selectedText);
          _this.get('api').trigger('focusAgentInput');
        }
      }];
      if (this.get('enableContextMenuMasking')) {
        var transcriptLine = this.get('transcriptLine');
        contextItemsArray.unshiftObject({
          label: this.get('intl').t('active-engagement.mask'),
          action: function action() {
            var previousMaskedText = transcriptLine.get('maskedText') || transcriptLine.get('messageText');

            // remove "(internal only) " from original text since we remove "(internal only) " in renderedHTML()
            var hasInternalOnly = previousMaskedText.startsWith('(internal only) ');
            if (hasInternalOnly) {
              previousMaskedText = previousMaskedText.replace('(internal only) ', '');
            }
            var maskedText = (0, _maskText.default)({
              index: index,
              selectedLength: selectedLength,
              previousMaskedText: previousMaskedText
            });
            if (hasInternalOnly) {
              maskedText = "(internal only) ".concat(maskedText);
            }
            transcriptLine.set('maskedText', maskedText);
            var onMask = _this.get('onMask');
            onMask && onMask(transcriptLine);
          },
          disabled: function disabled() {
            return !_this._hasSelectedText(selectedText);
          }
        });
      }
      return contextItemsArray;
    }),
    _hasSelectedText: function _hasSelectedText(selectedText) {
      if (!selectedText.length) return false;
      return this.get('transcriptLine.messageText').includes(selectedText);
    }
  });
});