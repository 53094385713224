define("sdk/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "x6nbKL2D",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[6,\"div\"],[10,\"id\",\"application\"],[11,\"class\",[27,[\"font-size-\",[22,[\"api\",\"agent\",\"settings\",\"fontSize\"]]]]],[3,\"action\",[[21,0,[]],\"handleApplicationClick\"],[[\"allowedKeys\",\"preventDefault\"],[\"any\",false]]],[8],[0,\"\\n  \"],[1,[20,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"model\",\"alertMessage\"]]],null,{\"statements\":[[4,\"child-modal\",null,[[\"data-test-alert-modal\",\"modalAriaDescribedBy\",\"modalClass\",\"modalRole\",\"isBackDropCloseEnabled\",\"isKeyboardEnabled\",\"hasCloseButton\",\"onClose\",\"onHiddenFocusSelector\"],[\"\",\"alert-message-modal-body\",\"alert-modal\",\"alertdialog\",true,true,true,[26,\"action\",[[21,0,[]],[22,[\"set\"]],\"model.alertMessage\",false],null],\"#settings-menu\"]],{\"statements\":[[0,\"    \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[1,[26,\"t\",[\"alert\"],null],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"section\"],[10,\"id\",\"alert-message-modal-body\"],[10,\"data-test-alert-message\",\"\"],[8],[0,\"\\n        \"],[1,[22,[\"model\",\"alertMessage\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[10,\"data-test-alert-ok\",\"\"],[3,\"action\",[[21,0,[]],[22,[\"set\"]],\"model.alertMessage\",false]],[8],[0,\"\\n        \"],[1,[26,\"t\",[\"app-error-modal.ok\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[6,\"div\"],[10,\"id\",\"ember-wormhole\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sdk/templates/application.hbs"
    }
  });
});